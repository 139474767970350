import React from 'react'
import { useTheme } from '@mui/material/styles'

interface Props {
  color?: string
  width?: string
}
function Svg({ color, width }: Props) {
  const theme = useTheme()
  return (
    <div style={{ width, display: 'flex' }}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14 25.5V18H6.5"
          stroke={color ?? theme.palette.text.secondary}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5 27L14 18"
          stroke={color ?? theme.palette.text.secondary}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20 24H26C26.2652 24 26.5196 23.8946 26.7071 23.7071C26.8946 23.5196 27 23.2652 27 23V6C27 5.73478 26.8946 5.48043 26.7071 5.29289C26.5196 5.10536 26.2652 5 26 5H9C8.73478 5 8.48043 5.10536 8.29289 5.29289C8.10536 5.48043 8 5.73478 8 6V12"
          stroke={color ?? theme.palette.text.secondary}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  )
}

Svg.defaultProps = {
  color: null,
  width: null,
}

export default Svg
