import React, { useContext, useEffect, useRef, useState } from 'react'
import { styled, Theme } from '@mui/material/styles'
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom'
import { getAuth } from 'lib/localStorage'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Toolbar from '@mui/material/Toolbar'
import ROUTES from 'lib/routes'
import { DASHBOARD_ROUTES } from 'lib/navigation'
import { useQuery } from '@apollo/client'
import { Severity, withSnackbar } from 'components/providers/SnackbarHOC'
import AppDrawer from 'components/drawer/AppDrawer'
import { drawerWidth } from 'components/drawer/constants'
import { getActiveWorkspaceId, saveActiveWorkspaceId } from 'lib/sessionStorage'
import MobileDrawer from 'components/drawer/MobileDrawer'
// import NotificationDialog from 'components/notifications/NotificationModal'
// import ChatDialog from 'components/communication/ChatModal'
import BottomNavigation from 'components/BottomNavigation'
// import InboxComponent from 'components/communication/InboxComponent'
import { PROFILE } from 'gql/user'
import { ProfileData } from 'types/profile'
import { useWorkspaces } from 'hooks/graphql/workspaces'
import NotificationBanner from './NotificationBanner'
import SubscriptionBanner from './SubscriptionBanner'
import SubscriptionReminderBanner from './SubscriptionReminderBanner'
import { CelebrationContext } from 'components/hoc/CelebrationHoc'
import { purchaseSuccessfulVar } from 'lib/reactiveVars'
import PurchaseSuccessfulModal, {
  PurchaseSuccessfulModalMethods,
} from './PurchaseSuccessfulModal'

// import { USER_CONVERSATIONS } from 'gql/communication'
// import { ConversationData } from 'types/communication'

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.default,
}))

const Content = styled('main', {
  shouldForwardProp: (prop) => prop !== 'hasSelectedWorkspace',
})<{
  theme?: Theme
  hasSelectedWorkspace: boolean
}>(({ theme, hasSelectedWorkspace }) => ({
  flexGrow: 1,

  // the following properties are aligned with the size of the sidebar
  marginLeft: theme.spacing(9),
  ...(!hasSelectedWorkspace && { maxWidth: '1400px', alignSelf: 'center' }),
  [theme.breakpoints.up('md')]: {
    marginLeft: `${hasSelectedWorkspace ? drawerWidth : drawerWidth * 0.6}px`,
  },
  [theme.breakpoints.down('md')]: {
    marginLeft: 0,
  },
}))
const GridContent = styled(Content)(({ theme, hasSelectedWorkspace }) => ({
  [theme.breakpoints.up('md')]: {
    marginRight: `${hasSelectedWorkspace ? drawerWidth : drawerWidth * 0.6}px`,
  },
  [theme.breakpoints.down('md')]: {
    marginRight: 0,
  },
}))

interface Props {
  showSnackbar?: (message: string, severity: Severity) => void
}
function Dashboard({ showSnackbar }: Props) {
  const theme = useTheme()
  const auth = getAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const workspaceId = getActiveWorkspaceId()
  const isBigScreen = useMediaQuery(theme.breakpoints.up('md'))
  const path = location.pathname
  const pathWorkspaceId = path.split('/')[2]
  const { data: workspaces, loading } = useWorkspaces()
  const { data } = useQuery<ProfileData>(PROFILE)
  const celebration = useContext(CelebrationContext)
  const wasPurchaseSuccessful = purchaseSuccessfulVar()
  const modalHandlerRef = useRef<PurchaseSuccessfulModalMethods>(null)

  useEffect(() => {
    if (wasPurchaseSuccessful) {
      celebration.showConfetti()
      modalHandlerRef.current?.openModal()
      // reset the successful flag
      purchaseSuccessfulVar(false)
    }
  }, [wasPurchaseSuccessful])

  // const { data: chatData, loading: chatLoading } =
  //   useQuery<ConversationData>(USER_CONVERSATIONS)
  const [notificationsOpen, setNotificationsOpen] = useState(false)
  const toggleNotifications = () => setNotificationsOpen(!notificationsOpen)
  // const [chatOpen, setChatOpen] = useState(false)
  // const toggleChat = () => setChatOpen(!chatOpen)
  const user = data?.profile
  // const conversations = chatData?.userConversationList || []

  useEffect(() => {
    // no session stored
    if (!auth?.userId) {
      navigate(ROUTES.INDEX, { replace: true })
    }
  }, [auth, loading])

  // set selected workspaceId in case its set in the pathname
  if (
    pathWorkspaceId &&
    pathWorkspaceId !== workspaceId &&
    workspaces.some((item) => item.id === pathWorkspaceId)
  ) {
    saveActiveWorkspaceId(pathWorkspaceId)
  } else if (!pathWorkspaceId && workspaceId) {
    // there is nothing in the pathname, but there is a workspaceId in the session
    // redirect to the dashboard of the workspace
    navigate(`${ROUTES.DASHBOARD}/${workspaceId}`)
  }

  // const isLoading = workspacesLoading || loading // || chatLoading
  const Wrapper = workspaceId ? Content : GridContent
  return (
    <Container>
      {/* {user && <InboxComponent user={user} />} */}

      {isBigScreen ? (
        <AppDrawer
          workspaces={workspaces}
          // onIconClick={toggleNotifications}
          // onChatClick={toggleChat}
          conversations={[]}
          // conversations={conversations}
        />
      ) : (
        <MobileDrawer
          workspaces={workspaces}
          // onIconClick={toggleNotifications}
          // onChatClick={toggleChat}
          conversations={[]}
        />
      )}
      <Toolbar />
      <Wrapper hasSelectedWorkspace={!!workspaceId}>
        <NotificationBanner profile={user} showSnackbar={showSnackbar} />
        <SubscriptionBanner profile={user} />
        <SubscriptionReminderBanner profile={user} />
        <Routes>
          {DASHBOARD_ROUTES.map((item) => {
            const Element = item.component
            return (
              <Route
                key={`route_${item.route}`}
                path={item.route}
                element={<Element />}
              />
            )
          })}
        </Routes>
      </Wrapper>
      {!isBigScreen && workspaceId && (
        <BottomNavigation onNotificationClick={toggleNotifications} />
      )}
      {/* <NotificationDialog
        open={notificationsOpen}
        onClose={toggleNotifications}
      /> */}
      {/* <ChatDialog
        open={chatOpen}
        onClose={toggleChat}
        conversations={[]}
        // conversations={conversations}
      /> */}
      <PurchaseSuccessfulModal ref={modalHandlerRef} />
    </Container>
  )
}

export default withSnackbar(Dashboard)

Dashboard.defaultProps = {
  showSnackbar: undefined,
}
