import { IntlShape } from 'react-intl'
import Logger from 'utils/sentry'
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  OnApproveActions,
  OnApproveData,
  PayPalButtonsComponentOptions,
} from '@paypal/paypal-js/types/components/buttons'
import { Severity } from 'components/providers/SnackbarHOC'
import { apiEndpoint } from '../api'
import { getAuth } from '../localStorage'

interface PaymentError {
  name: string
  message: string
}

function isValidForm(formData: CheckoutData) {
  const { countryCode, subscriptionPlanId, organizationId } = formData
  return !!organizationId && !!countryCode && !!subscriptionPlanId
}
export const isPaymentError = (
  response: { id: string } | PaymentError
): response is PaymentError => Object.keys(response).includes('message')

export interface CheckoutData {
  organizationId?: string
  countryCode?: string
  subscriptionPlanId?: string
  // in case the user is extending an existing subscription
  subscriptionId?: string
}
const paymentConfig = (
  subscriptionData: CheckoutData,
  intl: IntlShape,
  onComplete: () => void,
  showSnackbar?: (message: string, severity: Severity) => void
): PayPalButtonsComponentOptions => ({
  style: { layout: 'horizontal' },

  // eslint-disable-next-line consistent-return
  async createOrder(): Promise<string> {
    try {
      // check data validity
      if (!isValidForm(subscriptionData)) {
        showSnackbar?.(
          intl.formatMessage({ id: 'label.invalidData' }),
          Severity.ERROR
        )
        return ''
      }

      const auth = getAuth()
      const response = await fetch(`${apiEndpoint}/subscriptions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth?.token}`,
        },
        // use the "body" param to optionally pass additional order information
        // like product ids and quantities
        body: JSON.stringify(subscriptionData),
      })

      const responseJson: { id: string } | PaymentError = await response.json()

      if (!isPaymentError(responseJson) && responseJson.id) {
        return responseJson?.id
      }
      // response not successful, throw an error
      if (isPaymentError(responseJson)) {
        showSnackbar?.(responseJson.message, Severity.ERROR)
      }
    } catch (error) {
      Logger.logException(error)
      showSnackbar?.(
        intl.formatMessage({ id: 'label.paymentFailed' }),
        Severity.ERROR
      )
    }
    return ''
  },
  // eslint-disable-next-line consistent-return
  async onApprove(
    data: OnApproveData,
    actions: OnApproveActions
  ): Promise<void> {
    try {
      console.log('data.orderID', data.orderID)
      const auth = getAuth()
      const response = await fetch(
        `${apiEndpoint}/subscriptions/${data.orderID}/capture`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      )

      const orderData = await response.json()
      // Three cases to handle:
      //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
      //   (2) Other non-recoverable errors -> Show a failure message
      //   (3) Successful transaction -> Show confirmation or thank you message

      const errorDetail = orderData?.details?.[0]
      if (errorDetail?.issue === 'INSTRUMENT_DECLINED') {
        // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
        // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
        return actions.restart()
        // eslint-disable-next-line no-else-return
      } else if (errorDetail) {
        // (2) Other non-recoverable errors -> Show a failure message
        // throw new Error(`${errorDetail.description} (${orderData.debug_id})`)
        Logger.logException(errorDetail)
        showSnackbar?.(
          intl.formatMessage({ id: 'label.paymentFailed' }),
          Severity.ERROR
        )
      } else if (!orderData.purchase_units) {
        throw new Error(JSON.stringify(orderData))
      } else {
        // (3) Successful transaction
        onComplete?.()
      }
    } catch (error) {
      Logger.logException(error)
      showSnackbar?.(
        intl.formatMessage({ id: 'label.paymentFailed' }),
        Severity.ERROR
      )
    }
  },
})

export default paymentConfig
