import { gql } from '@apollo/client'

export const SUBSCRIPTION_PLANS = gql`
  query SUBSCRIPTION_PLANS($payload: SubscriptionPlansPayload!) {
    subscriptionPlans(payload: $payload) {
      total
      list {
        id
        type
        title
        titleDE
        description
        descriptionDE
        duration
        extraDuration
        active
        defaultPlan
        applicableTo
        limitations
        createdAt
        updatedAt
        deletedAt
        pricing {
          value
          currency
          countryCode
          tax
          refundDeadline
        }
      }
    }
  }
`

export const CHECKOUT_DETAILS = gql`
  query CHECKOUT_DETAILS($payload: CheckoutDetailsInput!) {
    checkoutDetails(payload: $payload) {
      organizations {
        id
        type
        company {
          name
          description
        }
        owner {
          id
          firstName
          lastName
        }
      }
      subscriptionPlan {
        id
        titleDE
        title
        description
        descriptionDE
        duration
        extraDuration
        active
        defaultPlan
        applicableTo
        limitations
        createdAt
        updatedAt
        pricing {
          value
          currency
          countryCode
          tax
          refundDeadline
        }
      }
    }
  }
`

export const CHECKOUT_UPGRADE_DETAILS = gql`
  query CHECKOUT_UPGRADE_DETAILS($payload: CheckoutUpgradeInput!) {
    checkoutUpgradeDetails(payload: $payload) {
      items {
        productId
        productReference
        productDescription
        invoiceReference
        fromDate
        toDate
        price {
          value
          currency
          totalAmount
        }
      }
      pricing {
        value
        currency
        countryCode
        tax
        totalAmount
      }
      subscriptionPlan {
        id
        titleDE
        title
        description
        descriptionDE
        duration
        extraDuration
        active
        defaultPlan
        applicableTo
        limitations
        createdAt
        updatedAt
      }
      relatedPlans {
        id
        titleDE
        title
        description
        descriptionDE
        duration
        extraDuration
      }
    }
  }
`

export const SUBSCRIPTION_BY_ID = gql`
  query SUBSCRIPTION_BY_ID($id: ID!) {
    subscriptionById(id: $id) {
      id
      subscriptionPlanId
      organizationId
      startDate
      endDate
      status
      createdAt
      updatedAt
    }
  }
`
export const SUBSCRIPTION_PLAN_BY_ID = gql`
  query SUBSCRIPTION_PLAN_BY_ID($id: ID!) {
    subscriptionPlanById(id: $id) {
      id
      type
      title
      titleDE
      description
      descriptionDE
      duration
      extraDuration
      active
      defaultPlan
      applicableTo
      limitations
      createdAt
      updatedAt
      deletedAt
      pricing {
        value
        currency
        countryCode
        tax
        refundDeadline
      }
    }
  }
`
