import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import useMediaQuery from '@mui/material/useMediaQuery'
import { styled, useTheme } from '@mui/material/styles'
import {
  Button,
  SimpleButton,
  ModalTitle,
  ModalTransition,
  RowSpaced,
} from 'components/shared'
import Dialog from '@mui/material/Dialog'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { FileType } from 'types/archive'
// import ListItemButton from '@mui/material/ListItemButton'
// import ListItemIcon from '@mui/material/ListItemIcon'
// import ListItemText from '@mui/material/ListItemText'
// import PCIcon from '@mui/icons-material/ComputerSharp'
// import DriveIcon from '@mui/icons-material/AddToDriveSharp'
import { FileEntry } from 'types/shared'
import DropZone from 'components/dropzone/Dropzone'
import { ErrorType } from 'hooks/api/fileUpload'

const Container = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  padding: '10px 20px',
}))

const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  marginTop: '10px',
  [theme.breakpoints.up('md')]: {
    minWidth: '1000px',
    minHeight: '400px',
  },
}))

// const LeftWrapper = styled('div')(({ theme }) => ({
//   display: 'flex',
//   flex: 2,
//   flexDirection: 'column',
//   justifyContent: 'flex-start',
//   [theme.breakpoints.down('sm')]: {
//     flex: 1,
//     minWidth: 0,
//   },
// }))

const RightWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 6,
  flexDirection: 'column',
  // marginRight: '20px',
}))

// const ListWrapper = styled('div')(({ theme }) => ({
//   display: 'flex',
//   flexDirection: 'column',
// }))

const ButtonWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: '10px',
}))

interface ContentProps {
  open: boolean
  fileType: FileType
  toggleModal: () => void
  onSubmit: (files: FileEntry[]) => void
  errors?: ErrorType[] // upload errors
}

/**
 * @deprecated Functionality migrated to use the UploadHandler
 */
function UploadModal({
  open,
  toggleModal,
  onSubmit,
  fileType,
  errors,
}: ContentProps) {
  const theme = useTheme()
  const intl = useIntl()
  const [files, setFiles] = useState<FileEntry[]>()
  const handleOnsubmit = () => files && onSubmit(files)
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'))

  const closeModal = () => {
    toggleModal()
    setFiles(undefined)
  }
  return (
    <Dialog
      open={open}
      onClose={closeModal}
      TransitionComponent={ModalTransition}
      maxWidth="lg"
      fullScreen={isMobileScreen}
    >
      <Container>
        <RowSpaced>
          <ModalTitle>{intl.formatMessage({ id: 'label.archive' })}</ModalTitle>
          {isMobileScreen && (
            <IconButton onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          )}
        </RowSpaced>
        <Content>
          {/* <LeftWrapper>
          <ListWrapper>
            <ListItemButton>
              <ListItemIcon>
                <PCIcon />
              </ListItemIcon>
              {!isMobileScreen && (
                <ListItemText
                  primary={intl.formatMessage({ id: 'label.computer' })}
                />
              )}
            </ListItemButton>
            <ListItemButton>
              <ListItemIcon>
                <DriveIcon />
              </ListItemIcon>
              {!isMobileScreen && (
                <ListItemText
                  primary={intl.formatMessage({ id: 'label.googleDrive' })}
                />
              )}
            </ListItemButton>
          </ListWrapper>
        </LeftWrapper> */}
          <RightWrapper>
            <DropZone
              files={files ?? []}
              setFiles={setFiles}
              fileType={fileType}
              errors={errors}
            />
            <ButtonWrapper>
              {!isMobileScreen && (
                <SimpleButton
                  variant="outlined"
                  color="primary"
                  onClick={closeModal}
                >
                  {intl.formatMessage({ id: 'label.cancel' })}
                </SimpleButton>
              )}
              <Box ml={1} />
              <Button
                onClick={handleOnsubmit}
                variant="contained"
                disabled={(files?.length ?? 0) === 0}
              >
                {intl.formatMessage({ id: 'label.upload' })}
              </Button>
            </ButtonWrapper>
          </RightWrapper>
        </Content>
      </Container>
    </Dialog>
  )
}

UploadModal.defaultProps = {
  errors: null,
}
export default UploadModal
