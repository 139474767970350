import React from 'react'
import { IntlShape } from 'react-intl'
import { Item, ItemListing, ItemExtended } from 'types/item'
import { isPositiveNumber, isValidText } from 'utils/validators'
import { currencies, defaultCurrency } from 'lib/constants'
import { InputType, Type } from 'components/input/types'
import { getDate, todayStartOfDay, yesterdayStartOfDay } from 'utils/datetime'
import { Category } from 'types/category'
import { JsonObject, MemberModels } from 'types/shared'
import BookIcon from 'assets/svg/Book'
import { SuggestionSection } from 'components/recordDetails/SuggestionsSection'
import { Workspace } from 'types/workspace'
import { ContentData } from 'components/recordDetails/ContentSection'
import { ServiceProviderExtended } from 'types/serviceProvider'
import { FileType } from 'types/archive'
import { OrderExtended, OrderModels } from 'types/order'
import ArchiveIcon from 'assets/svg/Archive'
import HouseSimpleIcon from 'assets/svg/HouseSimple'
import CardholderIcon from 'assets/svg/Cardholder'
import { getCurrencyLabel } from 'utils/monetary'
import { Theme } from '@mui/material/styles'
import { GroupExtended } from 'types/group'
import { NavigateFunction } from 'react-router-dom'
import ROUTES from 'lib/routes'
import { UserAssign } from 'components/user'
import { ContentSection } from 'components/recordDetails/SidebarCard'
import { UserType } from 'components/user/memberSelect/UserBadge'
import { eventsExtraInputs, eventsInputs } from 'utils/input-forms'
import { Contract } from 'types/contracts'
import { getLocaleTitle } from 'utils/content'
import AddIcon from '@mui/icons-material/Add'
import { classifyOrders } from './utils'

export enum Section {
  general = 'general',
  eventDocuments = 'eventDocuments',
  manufacture = 'manufacture',
  manufactureDocuments = 'manufactureDocuments',
  events = 'events',
  saleListing = 'saleListing',
  // transferItem = 'transferItem',

  // transaction actions = orders
  purchase = 'purchase',
  sale = 'sale',
  orderItemRef = 'orderItemRef',
  orderDocuments = 'orderDocuments',
}

export const editingSections = (intl: IntlShape): SuggestionSection[] => [
  {
    id: Section.general,
    title: intl.formatMessage({ id: 'label.general' }),
    description: '',
    cta: '',
  },
  {
    id: Section.manufacture,
    title: intl.formatMessage({ id: 'label.manufactureInfo' }),
    description: intl.formatMessage({ id: 'label.productDescription' }),
    cta: intl.formatMessage({ id: 'label.add' }),
  },
  {
    id: Section.events,
    title: intl.formatMessage({ id: 'label.events' }),
    description: intl.formatMessage({ id: 'label.eventsDescription' }),
    cta: intl.formatMessage({ id: 'label.add' }),
  },
  {
    id: Section.saleListing,
    title: intl.formatMessage({ id: 'label.saleListing' }),
    description: intl.formatMessage({ id: 'label.saleInfoQuestion' }),
    cta: intl.formatMessage({ id: 'label.add' }),
  },
  // {
  //   id: Section.transferItem,
  //   title: intl.formatMessage({ id: 'label.transferItem' }),
  //   description: '',
  //   cta: '',
  // },
  {
    id: Section.eventDocuments,
    title: intl.formatMessage({ id: 'label.addDocuments' }),
    description: '',
    cta: '',
  },
  {
    id: Section.manufactureDocuments,
    title: intl.formatMessage({ id: 'label.addDocuments' }),
    description: '',
    cta: '',
  },
  {
    id: Section.purchase,
    title: intl.formatMessage({ id: 'label.purchase' }),
    description: '',
    cta: '',
  },
  {
    id: Section.sale,
    title: intl.formatMessage({ id: 'label.sale' }),
    description: '',
    cta: '',
  },
  {
    id: Section.orderDocuments,
    title: intl.formatMessage({ id: 'label.orderDocuments' }),
    description: '',
    cta: '',
  },
  {
    id: Section.orderItemRef,
    title: intl.formatMessage({ id: 'label.item' }),
    description: '',
    cta: '',
  },
]

export const getSectionWithId = (id: string, intl: IntlShape) => {
  return editingSections(intl).find((section) => section.id === id)
}

// order inputs purchase & sale
const orderInputs = (
  section: Section,
  providers?: ServiceProviderExtended[]
): InputType[] => [
  {
    key: section === Section.sale ? 'buyerId' : 'sellerId',
    label: section === Section.sale ? 'label.buyer' : 'label.purchasePlace',
    infoLabel: 'label.inputAutoCreationProvider',
    type: Type.AUTOCOMPLETE,
    autoComplete: '',
    autoFocus: false,
    freeSolo: true,
    margin: 'dense',
    fullWidth: true,
    options:
      providers?.map((item) => ({
        value: item.id,
        label: item.title,
      })) || [],
  },
  {
    key: 'orderId',
    label: 'label.orderId',
    type: Type.TEXT,
    autoComplete: '',
    fullWidth: true,
    margin: 'dense',
  },
  {
    key: 'date',
    label: 'label.date',
    type: Type.DATE,
    autoComplete: '',
    fullWidth: true,
    margin: 'dense',
    disableFuture: true,
    suggestions: [
      {
        label: 'label.today',
        value: todayStartOfDay(),
      },
      {
        label: 'label.yesterday',
        value: yesterdayStartOfDay(),
      },
    ],
  },
  // {
  //   key: 'value',
  //   unitKey: 'currency',
  //   label: 'label.value',
  //   type: Type.PRICE,
  //   autoComplete: '',
  //   margin: 'dense',
  //   fullWidth: true,
  //   options: currencies,
  //   validators: [
  //     {
  //       method: isPositiveNumber,
  //       label: 'label.positiveNumberRequired',
  //     },
  //   ],
  // },
  {
    key: 'notes',
    label: 'label.notes',
    type: Type.TEXT,
    autoComplete: '',
    fullWidth: true,
    multiline: true,
    margin: 'dense',
    validators: [],
  },
]

// suggestions: [
//   {
//     dependentField: 'date',
//     label: 'label.oneYearFromPurchaseDate',
//     addedValue: 1,
//   },
//   {
//     dependentField: 'date',
//     label: 'label.twoYearFromPurchaseDate',
//     addedValue: 2,
//   },
//   {
//     dependentField: 'date',
//     label: 'label.sixMonthsFromPurchaseDate',
//     addedValue: 0.5,
//   },
// ],

const orderItemRefInputs = (): InputType[] => [
  {
    key: 'quantity',
    label: 'label.quantity',
    type: Type.NUMBER,
    autoComplete: '',
    margin: 'dense',
    fullWidth: true,
    options: currencies,
    validators: [
      {
        method: isPositiveNumber,
        label: 'label.positiveNumberRequired',
      },
    ],
  },
  {
    key: 'productLink',
    label: 'label.productLink',
    type: Type.TEXT,
    autoComplete: '',
    fullWidth: true,
    margin: 'dense',
    validators: [],
  },
  {
    key: 'value',
    unitKey: 'currency',
    label: 'label.value',
    type: Type.PRICE,
    autoComplete: '',
    margin: 'dense',
    fullWidth: true,
    options: currencies,
    validators: [
      {
        method: isPositiveNumber,
        label: 'label.positiveNumberRequired',
      },
    ],
  },
]

const docUploadArchiveFiles = (fileType: FileType): InputType[] => [
  {
    key: 'archive',
    label: 'label.addFiles',
    type: Type.DROPZONE,
    margin: 'dense',
    fullWidth: true,
    validators: [],
    fileType,
  },
]

// const transferInputs = (workspaces: Workspace[]): InputType[] => [
//   {
//     key: 'workspaceId',
//     label: 'label.board',
//     type: Type.SINGLE_SELECT,
//     autoComplete: '',
//     autoFocus: false,
//     margin: 'dense',
//     fullWidth: true,
//     options:
//       workspaces.map((item) => ({
//         value: item.id,
//         label: item.title,
//       })) || [],
//     validators: [
//       {
//         method: isValidText,
//         label: 'label.required',
//       },
//     ],
//   },
// ]

const salesInputs: InputType[] = [
  {
    key: 'place',
    label: 'label.place',
    type: Type.TEXT,
    autoComplete: '',
    fullWidth: true,
    margin: 'dense',
    validators: [
      {
        method: isValidText,
        label: 'label.required',
      },
    ],
  },
  {
    key: 'link',
    label: 'label.link',
    type: Type.TEXT,
    autoComplete: '',
    fullWidth: true,
    margin: 'dense',
    validators: [],
  },
  {
    key: 'price',
    unitKey: 'currency',
    label: 'label.price',
    type: Type.PRICE,
    autoComplete: '',
    margin: 'dense',
    fullWidth: true,
    options: currencies,
    validators: [
      {
        method: isPositiveNumber,
        label: 'label.positiveNumberRequired',
      },
    ],
  },
]

const manufactureInputs = (
  providers?: ServiceProviderExtended[]
): InputType[] => [
  {
    key: 'manufactureId',
    label: 'label.manufacture',
    infoLabel: 'label.inputAutoCreationProvider',
    type: Type.AUTOCOMPLETE,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    freeSolo: true,
    options:
      providers?.map((item) => ({
        value: item.id,
        label: item.title,
      })) || [],
    validators: [
      {
        method: isValidText,
        label: 'label.required',
      },
    ],
  },
  {
    key: 'model',
    label: 'label.model',
    type: Type.TEXT,
    autoComplete: '',
    fullWidth: true,
    margin: 'dense',
    validators: [],
  },
  {
    key: 'date',
    label: 'label.manufactureDate',
    type: Type.DATE,
    autoComplete: '',
    fullWidth: true,
    margin: 'dense',
    validators: [],
    disableFuture: true,
  },
  {
    key: 'productUrl',
    label: 'label.productUrl',
    type: Type.TEXT,
    autoComplete: '',
    fullWidth: true,
    margin: 'dense',
    validators: [],
  },
]

const manufactureExtraInputs: InputType[] = [
  {
    key: 'description',
    label: 'label.description',
    type: Type.TEXT,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    multiline: true,
    validators: [],
  },
  {
    key: 'technicalData',
    label: 'label.technicalData',
    type: Type.TEXT,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    multiline: true,
    validators: [],
  },
]

const generalSectionInputs = (
  intl: IntlShape,
  categories: Category[]
): InputType[] => [
  {
    key: 'title',
    label: 'label.title',
    type: Type.TEXT,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    validators: [
      {
        method: isValidText,
        label: 'label.required',
      },
    ],
  },

  {
    key: 'description',
    label: 'label.description',
    type: Type.TEXT,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    multiline: true,
    validators: [],
  },
  {
    key: 'categoryId',
    label: 'label.category',
    type: Type.AUTOCOMPLETE,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    options:
      categories.map((item) => ({
        value: item.id,
        label: getLocaleTitle(intl, item),
      })) || [],
    validators: [],
  },
]

const orderInitialState = (
  item: ItemExtended,
  sectionId: Section,
  recordId?: string
) => {
  const record = item.orders?.find((rec) => rec.id === recordId)

  const extraState =
    sectionId === Section.sale
      ? { buyerId: record?.buyerId || null }
      : { sellerId: record?.sellerId || null }
  return {
    date: record?.date ? new Date(record?.date) : null,
    // value: record?.amount?.price?.toString() || null,
    // currency: purchase?.currency || undefined,
    // productLink: record?.productLink || '',
    notes: record?.notes || '',
    orderId: record?.orderId || '',
    ...extraState,
  }
}

const orderItemRefInitialState = (
  item: ItemExtended,
  recordId?: string,
  itemRefId?: string
) => {
  const record = item.orders?.find((rec) => rec.id === recordId)
  const itemRef = record?.items?.find((rec) => rec.id === itemRefId)
  return {
    quantity: itemRef?.quantity?.toString(),
    productLink: itemRef?.productLink || '',
    value: itemRef?.amount?.value?.toString(),
    currency: itemRef?.amount?.currency ?? defaultCurrency,
  }
}

const saleInfoInitialState = (item: ItemExtended, saleInfoId?: string) => {
  const saleInfo = item.itemListings?.find((sale) => sale.id === saleInfoId)
  return {
    place: saleInfo?.place || '',
    link: saleInfo?.link || '',
    price: saleInfo?.price?.toString() || null,
    currency: saleInfo?.currency || defaultCurrency,
  }
}

const manufactureInitialState = (item: ItemExtended) => ({
  model: item.manufacture?.model || '',
  date: item.manufacture?.date ? new Date(item.manufacture?.date) : null,
  productUrl: item.manufacture?.productUrl || '',
  description: item.manufacture?.description || '',
  technicalData: item.manufacture?.technicalData || '',
  manufactureId: item.manufacture?.manufactureId || '',
})

const manufactureDocumentsInitialState = (item: ItemExtended) => ({
  manufactureId: item.manufacture?.manufactureId || '',
})

const eventInitialState = (item: ItemExtended, eventId?: string) => {
  const record = item.events?.find((event) => event.id === eventId)
  return {
    providerId: record?.providerId || null,
    contractId: record?.contractId || null,
    date: record?.date ? new Date(record?.date) : null,
    title: record?.title || '',
    description: record?.description || '',
    price: record?.amount?.value?.toString() || null,
    currency: record?.amount?.currency || defaultCurrency,
  }
}

const generalInitialState = (item: Item) => ({
  title: item.title || '',
  description: item.description || '',
  categoryId: item.categoryId,
})

// const transferInitialState = () => ({
//   workspaceId: '',
// })

export const getEditInputs = (
  intl: IntlShape,
  editId: string,
  categories?: Category[],
  orgWorkspaces?: Workspace[],
  providers?: ServiceProviderExtended[],
  contracts?: Contract[]
) => {
  switch (editId) {
    case Section.general:
      return categories ? generalSectionInputs(intl, categories) : []
    case Section.sale:
    case Section.purchase:
      return orderInputs(editId, providers)
    case Section.manufacture:
      return manufactureInputs(providers)
    case Section.events:
      return eventsInputs(providers, contracts)
    case Section.saleListing:
      return salesInputs
    // case Section.transferItem:
    //   return orgWorkspaces ? transferInputs(orgWorkspaces) : []
    case Section.eventDocuments:
    case Section.orderDocuments:
    case Section.manufactureDocuments:
      return docUploadArchiveFiles(FileType.document)
    case Section.orderItemRef:
      return orderItemRefInputs()
    default:
      return []
  }
}

export const getExtraInputs = (editId: string) => {
  switch (editId) {
    case Section.manufacture:
      return manufactureExtraInputs
    case Section.events:
      return eventsExtraInputs
    default:
      return []
  }
}

export const getInitialState = (
  item: Item,
  sectionId: Section,
  recordId?: string, // id of the sub-entity being edited, e.g. item sales info
  extra?: {
    refId: string // reference
  }
) => {
  switch (sectionId) {
    case Section.general:
      return generalInitialState(item)
    case Section.sale:
    case Section.purchase:
      return orderInitialState(item, sectionId, recordId)
    case Section.orderItemRef:
      return orderItemRefInitialState(item, recordId, extra?.refId)
    case Section.manufacture:
      return manufactureInitialState(item)
    case Section.manufactureDocuments:
      return manufactureDocumentsInitialState(item)
    case Section.events:
      return eventInitialState(item, recordId)
    case Section.saleListing:
      return saleInfoInitialState(item, recordId)
    // case Section.transferItem:
    //   return transferInitialState()
    case Section.eventDocuments:
    case Section.orderDocuments:
      return { archive: undefined }
    default:
      return {}
  }
}

export function getOrderContent(
  record: OrderExtended,
  intl: IntlShape,
  workspaceId: string, // active workspace ID
  skipTransactionParties?: boolean
) {
  const soldByYou = record.seller?.id === workspaceId
  const boughtByYou = record.buyer?.id === workspaceId
  return [
    ...(skipTransactionParties
      ? []
      : [
          {
            label: intl.formatMessage({ id: 'label.seller' }),
            value:
              (soldByYou
                ? intl.formatMessage({ id: 'label.soldByYou' })
                : record.seller?.title) ?? '-',
            deletedAt: record?.seller?.deletedAt,
            to:
              !soldByYou &&
              record.sellerModel === OrderModels.SERVICE_PROVIDER &&
              record.seller?.id
                ? ROUTES.DASHBOARD_SERVICE_PROVIDER_DETAILS_ROUTE(
                    workspaceId,
                    record.seller?.id
                  )
                : undefined,
          },
          {
            label: intl.formatMessage({ id: 'label.buyer' }),
            value:
              (boughtByYou
                ? intl.formatMessage({ id: 'label.boughtByYou' })
                : record.buyer?.title) ?? '-',
            deletedAt: record?.buyer?.deletedAt,
            to:
              !boughtByYou &&
              record.buyerModel === OrderModels.SERVICE_PROVIDER &&
              record.buyer?.id
                ? ROUTES.DASHBOARD_SERVICE_PROVIDER_DETAILS_ROUTE(
                    workspaceId,
                    record.buyer?.id
                  )
                : undefined,
          },
        ]),
    {
      label: intl.formatMessage({ id: 'label.orderId' }),
      value: record.orderId ?? '-',
    },
    {
      label: intl.formatMessage({ id: 'label.date' }),
      value: record.date ? getDate(record.date) : '-',
    },
    {
      label: intl.formatMessage({ id: 'label.notes' }),
      value: record.notes ?? '-',
    },
  ]
}

export const getSaleInfoContent = (sale: ItemListing, intl: IntlShape) => {
  const currency = getCurrencyLabel(sale.currency)
  const data: ContentData[] = [
    {
      label: intl.formatMessage({ id: 'label.place' }),
      value: sale.place ?? '-',
    },
    {
      label: intl.formatMessage({ id: 'label.link' }),
      value: sale.link?.trim() ?? '-',
      isLink: !!sale.link?.trim(),
    },
    {
      label: intl.formatMessage({ id: 'label.price' }),
      value: sale.price ? `${sale.price} ${currency}` : '-',
    },
  ]

  return data
}

export const getManufactureContent = (
  workspaceId: string,
  item: ItemExtended,
  intl: IntlShape
) => {
  const { manufacture } = item
  const data: ContentData[] = [
    {
      label: intl.formatMessage({ id: 'label.manufacture' }),
      value: manufacture?.providerData?.title ?? '-',
      to: manufacture?.providerData?.id
        ? ROUTES.DASHBOARD_SERVICE_PROVIDER_DETAILS_ROUTE(
            workspaceId,
            manufacture?.providerData?.id
          )
        : undefined,
    },
    {
      label: intl.formatMessage({ id: 'label.model' }),
      value: manufacture?.model ?? '-',
    },
    {
      label: intl.formatMessage({ id: 'label.manufactureDate' }),
      value: manufacture?.date ? getDate(manufacture?.date) : '-',
    },
    {
      label: intl.formatMessage({ id: 'label.productUrl' }),
      value: manufacture?.productUrl || '-',
      isLink: true,
    },
  ]

  if (manufacture?.description) {
    data.push({
      label: intl.formatMessage({ id: 'label.description' }),
      value: manufacture.description,
    })
  }
  if (manufacture?.technicalData) {
    // technical description
    data.push({
      label: intl.formatMessage({ id: 'label.technicalData' }),
      value: manufacture.technicalData,
    })
  }

  return data
}

export const payloadBuilder = (
  formData: JsonObject,
  recordId: string,
  sectionId?: string
) => {
  let payloadData = formData
  if (sectionId)
    switch (sectionId) {
      case Section.general:
        payloadData = formData
        break
      default:
        payloadData = { [sectionId]: formData } // 1:1 mapping of section to payload
    }

  const payload = {
    id: recordId,
    data: payloadData,
  }
  return payload
}

export const orderPayloadBuilder = (
  formData: JsonObject,
  sectionId: Section,
  order?: OrderExtended
) => {
  // set the buyer/seller to provider
  // todo: this is a temporary fix as of this version we do not link boards as the sellers/buyers
  if (
    sectionId === Section.sale &&
    formData.buyerId &&
    formData.buyerId !== order?.buyerId
  ) {
    return {
      ...formData,
      buyerModel: OrderModels.SERVICE_PROVIDER,
    }
  }

  if (
    sectionId === Section.purchase &&
    formData.sellerId &&
    formData.sellerId !== order?.sellerId
  ) {
    return {
      ...formData,
      sellerModel: OrderModels.SERVICE_PROVIDER,
    }
  }

  return formData
}

const chipIconStyle = { paddingLeft: '4px', marginRight: '-8px' }
export const getDetailsChips = (
  intl: IntlShape,
  data: {
    record: ItemExtended
    groups: GroupExtended[]
    workspaceId: string
    members: UserType[]
  },
  theme: Theme,
  methods: {
    handleOnEditWithId: (sectionId: Section, recordId?: string) => void
    navigate: NavigateFunction
    toggleNestSelect?: () => void
    toggleContainerSelect?: () => void
    toggleGroupSelect?: () => void
    createMemberRef: (memberId: string, memberModel: MemberModels) => void
    deleteMemberRef: (refId: string) => void
  }
): ContentSection[] => {
  const { record, groups, members } = data
  return [
    {
      title: intl.formatMessage({ id: 'label.category' }),
      chips: record.category
        ? [
            {
              label: getLocaleTitle(intl, record.category),
              icon: (
                <BookIcon
                  color={theme.palette.text.primary}
                  width="20px"
                  style={chipIconStyle}
                />
              ),
            },
          ]
        : undefined,
      action: {
        ariaLabel: 'edit category',
        icon: () => <AddIcon color="disabled" />,
        onPress: () => methods.handleOnEditWithId(Section.general),
      },
    },
    {
      title: intl.formatMessage({ id: 'label.nests' }),
      chips: record.nests?.map((nest) => ({
        label: nest?.title,
        value: nest?.id,
        icon: (
          <HouseSimpleIcon
            color={theme.palette.text.primary}
            width="22px"
            style={chipIconStyle}
          />
        ),
        onPress: (nestId: string) => {
          methods.navigate(
            ROUTES.DASHBOARD_NEST_DETAILS_ROUTE(data.workspaceId, nestId)
          )
        },
      })),
      action: {
        ariaLabel: 'edit spaces',
        icon: () => <AddIcon color="disabled" />,
        onPress: () => methods.toggleNestSelect?.(),
      },
    },
    {
      title: intl.formatMessage({ id: 'label.containers' }),
      chips: record.containers?.map((container) => ({
        label: container?.title,
        value: container?.id,
        icon: (
          <ArchiveIcon
            color={theme.palette.text.primary}
            width="22px"
            style={chipIconStyle}
          />
        ),
        onPress: (containerId: string) => {
          methods.navigate(
            ROUTES.DASHBOARD_CONTAINER_DETAILS_ROUTE(
              data.workspaceId,
              containerId
            )
          )
        },
      })),
      action: {
        ariaLabel: 'edit containers',
        icon: () => <AddIcon color="disabled" />,
        onPress: () => methods.toggleContainerSelect?.(),
      },
    },
    {
      title: intl.formatMessage({ id: 'label.groups' }),
      chips: groups.map((group) => ({
        label: group.title,
        value: group.id,
        icon: (
          <CardholderIcon
            color={theme.palette.text.primary}
            width="22px"
            style={chipIconStyle}
          />
        ),
        onPress: (groupId: string) => {
          methods.navigate(
            ROUTES.DASHBOARD_GROUP_DETAILS_ROUTE(data.workspaceId, groupId)
          )
        },
      })),
      action: {
        ariaLabel: 'edit groups',
        icon: () => <AddIcon color="disabled" />,
        onPress: () => methods.toggleGroupSelect?.(),
      },
    },
    {
      title: intl.formatMessage({ id: 'label.members' }),
      component: (
        <UserAssign
          users={record?.members ?? []}
          members={members}
          createMemberRef={methods.createMemberRef}
          deleteMemberRef={methods.deleteMemberRef}
        />
      ),
    },
  ]
}

interface Methods {
  addSale: () => void
  addPurchase: () => void
  addListing: () => void
  addEvent: () => void
}
export const relationsContent = (
  intl: IntlShape,
  data: {
    record?: ItemExtended
    workspaceId: string
  },
  methods: Methods
): ContentSection[] => {
  const { workspaceId, record } = data
  const classifiedOrders = classifyOrders(workspaceId, record?.orders)

  return [
    {
      title: intl.formatMessage({ id: 'label.events' }),
      description: intl.formatMessage(
        { id: 'label.eventsCount' },
        { number: record?.events?.length ?? 0 }
      ),
      action: {
        ariaLabel: 'related events',
        icon: () => <AddIcon color="disabled" />,
        onPress: methods.addEvent,
      },
    },
    {
      title: intl.formatMessage({ id: 'label.sales' }),
      description: intl.formatMessage(
        { id: 'label.itemSalesCount' },
        { number: classifiedOrders.sales.length }
      ),
      action: {
        ariaLabel: 'related sales',
        icon: () => <AddIcon color="disabled" />,
        onPress: methods.addSale,
      },
    },
    {
      title: intl.formatMessage({ id: 'label.purchases' }),
      description: intl.formatMessage(
        { id: 'label.itemPurchasesCount' },
        { number: classifiedOrders.purchases.length }
      ),
      action: {
        ariaLabel: 'related purchase',
        icon: () => <AddIcon color="disabled" />,
        onPress: methods.addPurchase,
      },
    },
    {
      title: intl.formatMessage({ id: 'label.salesListing' }),
      description: intl.formatMessage(
        { id: 'label.relatedSaleListingCount' },
        { number: record?.itemListings?.length ?? 0 }
      ),
      action: {
        ariaLabel: 'related sales listing',
        icon: () => <AddIcon color="disabled" />,
        onPress: methods.addListing,
      },
    },
  ]
}
