import React, { PropsWithChildren } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Theme, alpha, useTheme } from '@mui/material/styles'
import { styled } from '@mui/material/styles'
import MuiDrawer from '@mui/material/Drawer'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Box from '@mui/material/Box'
import { drawerWidth } from './drawer/constants'
import { RowSpaced } from './shared'

const DrawerComp = styled(MuiDrawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    padding: '10px 0',
    backgroundColor: theme.palette.background.default,
    marginTop: theme.mixins.toolbar.height || theme.mixins.toolbar.minHeight,
    width: drawerWidth,
    maxHeight: `calc(100% - ${
      theme.mixins.toolbar.height || theme.mixins.toolbar.minHeight
    }px)`,
    boxSizing: 'border-box',
    boxShadow: '0px 1px 8px 0px #00000014',
  },
}))
const Puller = styled(Box)(({ theme }) => ({
  width: '22vw',
  height: '4px',
  backgroundColor: theme.palette.text.disabled,
  borderRadius: '5px',
}))

const Header = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'drawerBleeding',
})<{
  theme?: Theme
  drawerBleeding: number
}>(({ theme, drawerBleeding }) => ({
  backgroundColor: theme.palette.background.default,
  position: 'absolute',
  borderTopLeftRadius: 20,
  borderTopRightRadius: 20,
  visibility: 'visible',
  top: -drawerBleeding,
  right: 0,
  left: 0,
}))

const Content = styled(Box)(({ theme }) => ({
  paddingBottom: '20px',
  height: '100%',
  overflow: 'auto',
}))

const PullerWrapper = styled(RowSpaced)(({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  margin: '11px 0 22px 0',
}))

const MobileDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  '& .MuiPaper-root': {
    overflow: 'visible',
    // maxHeight: `calc(60vh - ${drawerBleeding}px)`,
  },
}))

const drawerBleeding = 36
interface Props extends PropsWithChildren<unknown> {
  open: boolean
  toggleVisibility: () => void
  anchor?: 'left' | 'top' | 'right' | 'bottom'
  disableSwipeToOpen?: boolean
  swipeAreaWidth?: number
}
function DrawerHoc({
  children,
  open,
  toggleVisibility,
  anchor,
  disableSwipeToOpen,
  swipeAreaWidth,
}: Props) {
  const theme = useTheme()
  const isBigScreen = useMediaQuery(theme.breakpoints.up('md'))
  const content = isBigScreen ? (
    <DrawerComp variant="persistent" anchor="left" open={open}>
      {children}
    </DrawerComp>
  ) : (
    <MobileDrawer
      style={{ flex: 1 }}
      onOpen={toggleVisibility}
      onClose={toggleVisibility}
      open={open}
      anchor={anchor}
      swipeAreaWidth={swipeAreaWidth ?? drawerBleeding}
      disableSwipeToOpen={disableSwipeToOpen}
      slotProps={{
        backdrop: {
          style: {
            backdropFilter: 'blur(3px)',
            backgroundColor: alpha(theme.palette.action.active, 0.2),
          },
        },
      }}
    >
      {open && (
        <Header drawerBleeding={swipeAreaWidth ?? drawerBleeding}>
          <PullerWrapper>
            <Puller />
          </PullerWrapper>
        </Header>
      )}
      <Content>{children}</Content>
    </MobileDrawer>
  )

  return content
}

DrawerHoc.defaultProps = {
  disableSwipeToOpen: true,
  anchor: 'bottom',
  swipeAreaWidth: undefined,
}

export default DrawerHoc
