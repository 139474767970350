export enum Events {
  languageChange = 'language-change',
}

function subscribe(eventName: Events, listener: () => void) {
  document.addEventListener(eventName, listener)
}

function unsubscribe(eventName: Events, listener?: () => void) {
  document.removeEventListener(eventName, listener ? listener : () => {})
}

function publish(eventName: Events, data?: string) {
  const event = new CustomEvent(
    eventName,
    !!data ? { detail: data } : undefined
  )
  document.dispatchEvent(event)
}

export { publish, subscribe, unsubscribe }
