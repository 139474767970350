import React, { PropsWithChildren } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { lighten, styled, useTheme } from '@mui/material/styles'
import ReactMarkdown from 'react-markdown'
import { H3, H5, ParagraphText } from './typography'
import { FontFamily } from 'assets'

const SpanText = styled(H3)<{ fontSize: number }>(({ theme, fontSize }) => ({
  display: 'inline-block',
  ':after': {
    margin: `-${Math.round(fontSize / 5)}px -${Math.round(fontSize / 5)}px 0 -${Math.round(fontSize / 6) - 3}px`,
    content: '""',
    background: lighten(theme.palette.primary.main, 0.7),
    display: 'block',
    borderRadius: `${fontSize}px`,
    height: `${Math.round(fontSize / 4)}px`,
  },
}))
function RenderMarkdown({ markdown }: { markdown: string }) {
  const theme = useTheme()
  const isBigScreen = useMediaQuery(theme.breakpoints.up('md'))
  const P = ({ children }: PropsWithChildren<unknown>) => (
    <React.Fragment>
      <ParagraphText color={lighten(theme.palette.text.primary, 0.3)}>
        {children}
      </ParagraphText>
    </React.Fragment>
  )
  const Strong = ({ children }: PropsWithChildren<unknown>) => (
    <React.Fragment>
      <H5 fontFamily={FontFamily.NoirProMedium} style={{ opacity: 1 }}>
        {children}
      </H5>
    </React.Fragment>
  )

  const StyledH3 = ({ children }: PropsWithChildren<unknown>) => (
    <React.Fragment>
      <br />
      <br />
      <SpanText fontSize={isBigScreen ? 20 : 16}>
        {children?.toString()}
      </SpanText>
      <br />
    </React.Fragment>
  )

  const Li = ({ children }: PropsWithChildren<unknown>) => (
    <React.Fragment>
      <ParagraphText color={lighten(theme.palette.text.primary, 0.3)}>
        • {children}
      </ParagraphText>
    </React.Fragment>
  )

  return (
    <ReactMarkdown
      className="reactMarkDown"
      components={{
        p: P,
        strong: Strong,
        h3: StyledH3,
        li: Li,
      }}
    >
      {markdown}
    </ReactMarkdown>
  )
}

export default RenderMarkdown
