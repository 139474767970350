import { isValidOption, isValidText } from 'utils/validators'
import { InputType, Type } from 'components/input/types'
import { NestType } from 'types/nest'
import { IntlShape } from 'react-intl'
import { getLocaleTitle } from 'utils/content'

export const step1Inputs = (
  intl: IntlShape,
  nestTypes: NestType[]
): {
  questions: InputType[]
  label: string
} => ({
  label: 'label.general',
  questions: [
    {
      key: 'title',
      label: 'label.title',
      type: Type.TEXT,
      autoComplete: '',
      autoFocus: false,
      margin: 'dense',
      fullWidth: true,
      validators: [
        {
          method: isValidText,
          label: 'label.required',
        },
      ],
    },

    {
      key: 'description',
      label: 'label.description',
      type: Type.TEXT,
      autoComplete: '',
      autoFocus: false,
      margin: 'dense',
      fullWidth: true,
      multiline: true,
      validators: [],
    },

    {
      key: 'spaceType',
      label: 'label.type',
      type: Type.AUTOCOMPLETE,
      autoComplete: '',
      margin: 'normal',
      fullWidth: true,
      options: nestTypes.map((input) => ({
        value: input.id,
        label: getLocaleTitle(intl, input),
      })),
      validators: [
        {
          method: isValidOption,
          label: 'label.required',
        },
      ],
    },
  ],
})

export const step2Inputs = (
  nestTypes: NestType[],
  spaceType: string // selected type ID from step 1
): {
  questions: InputType[]
  label: string
} => ({
  label: 'label.details',
  questions: [
    {
      key: 'features',
      label: 'label.features',
      type: Type.MULTI_SELECT,
      autoComplete: '',
      autoFocus: false,
      margin: 'dense',
      fullWidth: true,
      useTranslation: true,
      options:
        nestTypes
          .find((input) => input.id === spaceType)
          ?.featuresOptions.map((input) => ({
            value: input,
            label: input.toLocaleLowerCase(),
          })) || [],
      validators: [],
    },
    {
      key: 'securityFeatures',
      label: 'label.securityFeatures',
      type: Type.MULTI_SELECT,
      autoComplete: '',
      autoFocus: false,
      margin: 'dense',
      fullWidth: true,
      useTranslation: true,
      options:
        nestTypes
          .find((input) => input.id === spaceType)
          ?.securityFeaturesOptions?.map((input) => ({
            value: input,
            label: input.toLocaleLowerCase(),
          })) || [],
      validators: [],
    },
    {
      key: 'spaceUsage',
      label: 'label.spaceUsage',
      type: Type.MULTI_SELECT,
      autoComplete: '',
      autoFocus: false,
      margin: 'dense',
      fullWidth: true,
      useTranslation: true,
      options:
        nestTypes
          .find((input) => input.id === spaceType)
          ?.spaceUsageOptions?.map((input) => ({
            value: input,
            label: input.toLocaleLowerCase(),
          })) || [],
      validators: [],
    },
    {
      key: 'elevation',
      label: 'label.elevation',
      type: Type.SINGLE_SELECT,
      autoComplete: '',
      autoFocus: false,
      margin: 'dense',
      fullWidth: true,
      useTranslation: true,
      options:
        nestTypes
          .find((input) => input.id === spaceType)
          ?.elevationOptions?.map((input) => ({
            value: input,
            label: input.toLocaleLowerCase(),
          })) || [],
      validators: [],
    },
  ],
})
