import React from 'react'
import { useTheme } from '@mui/material/styles'
import { styled, Theme } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import { RowSpaced, Row, Column } from 'components/shared'
import { BodyText, BodyTextLight, H3, H6 } from 'components/typography'
import Grid from '@mui/material/Grid'
import { isValidUrl } from 'utils/validators'
import Button from '@mui/material/Button'
import { FontFamily } from 'assets'
import { Link } from 'react-router-dom'
import { Action, SectionEntry } from './SectionRender'
import { DangerSectionEntry } from './DangerSectionRender'
import { useMediaQuery } from '@mui/material'

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '16px',
  backgroundColor: theme.palette.background.default,
  borderRadius: '5px',
  marginBottom: '32px',
}))
export const DangerContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  padding: '16px',
  borderRadius: '5px',
  marginBottom: '32px',
}))

const SectionLabel = styled(BodyText)(({ theme }) => ({
  order: 0,
  marginBottom: '8px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  lineClamp: 1,
  WebkitBoxOrient: 'vertical',
}))

const SectionLink = styled('a')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontFamily: FontFamily.OpenSans,
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '19px',
  order: 0,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  lineClamp: 1,
  WebkitBoxOrient: 'vertical',
}))

const ContentGrid = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'extraSpacing' && prop !== 'visible',
})<{
  theme?: Theme
  visible: boolean
  extraSpacing?: boolean
}>(({ theme, visible, extraSpacing }) => ({
  ...(extraSpacing && { margin: '12px 0' }),
  ...(!visible && { display: 'none' }),
  '& .MuiGrid-item': {
    display: 'inline-grid',
    alignItems: 'flex-start',
  },
}))

const SectionContainer = styled(Column, {
  shouldForwardProp: (prop) => prop !== 'hasBorder',
})<{
  theme?: Theme
  hasBorder: boolean
  clickable?: boolean
}>(({ theme, hasBorder, clickable }) => ({
  padding: '10px 0',
  ...(hasBorder && {
    borderBottom: `1px solid ${theme.palette.text.disabled}`,
  }),
  ...(clickable && {
    cursor: 'pointer',
  }),
}))

const SectionTitle = styled(H3)(({ theme }) => ({
  padding: '10px 0',
  flex: 3,
}))

export const HeaderWrapper = styled(RowSpaced, {
  shouldForwardProp: (prop) => prop !== 'visible',
})<{
  theme?: Theme
  visible?: boolean
}>(({ theme, visible }) => ({
  borderBottom: `1px solid ${theme.palette.text.disabled}`,
  paddingBottom: '24px',
  ...(!visible && { display: 'none' }),
}))

interface ActionRenderProps {
  actions?: Action[]
  size?: number
}

export const ActionRender = ({ actions, size }: ActionRenderProps) => {
  if (!actions) return <React.Fragment />
  const iconStyle = size ? { width: size, height: size } : {}
  const iconProps = size ? { size: size - 6 } : {}
  return (
    <Row
      flexWrap="nowrap"
      style={{ flex: 1, justifyContent: 'flex-end', alignItems: 'flex-end' }}
    >
      {actions?.map((action, index) => {
        const Icon = action.icon ?? React.Fragment
        return (
          <IconButton
            onClick={action.onPress}
            style={iconStyle}
            key={action.ariaLabel}
            aria-label={action.ariaLabel}
          >
            <Icon {...iconProps} />
          </IconButton>
        )
      })}
    </Row>
  )
}

interface EntriesContentProps {
  entries?: SectionEntry[]
}
export function EntriesContent({ entries }: EntriesContentProps) {
  const theme = useTheme()
  const isBigScreen = useMediaQuery(theme.breakpoints.up('md'))

  if (!entries || entries.length === 0) return null

  return (
    <Column>
      {entries?.map((entry, index) => {
        return (
          <SectionContainer
            clickable={!!entry.onClick}
            onClick={entry.onClick}
            key={`${entry.title}-${Math.random()}`}
            hasBorder={index < entries.length - 1}
          >
            <RowSpaced flexWrap="nowrap">
              <SectionTitle>{entry.title}</SectionTitle>
              <ActionRender actions={entry.actions} size={30} />
            </RowSpaced>
            <RowSpaced>
              {entry.description ? (
                <BodyTextLight
                  style={{
                    flex: 1,
                    marginRight: isBigScreen ? '40px' : '10px',
                  }}
                  numberOfLines={1}
                  color={theme.palette.text.secondary}
                >
                  {entry.description}
                </BodyTextLight>
              ) : (
                <div />
              )}
              {entry?.headlineContent}
            </RowSpaced>

            {/* Content */}
            <ContentDataGrid entry={entry} />

            {/* Content of the section can be passed through content. */}
            {entry.content ?? null}
          </SectionContainer>
        )
      })}
    </Column>
  )
}

export function ContentDataGrid({ entry }: { entry: SectionEntry }) {
  const theme = useTheme()
  return (
    <ContentGrid
      container
      extraSpacing={!!entry.description}
      visible={!!entry.data && entry.data.length > 0}
    >
      {entry.data?.map(
        ({ label, value, deletedAt, isLink, visible, to, style }) => {
          // if the flag is not provided then the content is visible by default
          if (typeof visible === 'boolean' && !visible) return null
          const Wrapper = to ? Link : React.Fragment
          const wrapperProps = to ? { to } : {}
          const ValueComp =
            isLink && isValidUrl(value) ? SectionLink : BodyTextLight
          return (
            <React.Fragment key={label.toString()}>
              <Grid item xs={4}>
                {typeof label === 'string' ? (
                  <SectionLabel>{label}</SectionLabel>
                ) : (
                  label
                )}
              </Grid>
              <Grid item xs={8}>
                {/* @ts-ignore */}
                <Wrapper {...wrapperProps}>
                  <ValueComp
                    href={value}
                    target="_blank"
                    style={{
                      ...(style ?? {}),
                      ...(deletedAt && {
                        textDecoration: 'line-through',
                        color: theme.palette.error.main,
                      }),
                      ...(to ? { display: 'flex' } : {}),
                    }}
                  >
                    {value}
                  </ValueComp>
                </Wrapper>
              </Grid>
            </React.Fragment>
          )
        }
      )}
    </ContentGrid>
  )
}

EntriesContent.defaultProps = {
  entries: null,
}
ActionRender.defaultProps = {
  actions: null,
  size: null,
}

const DangerButton = styled(Button)(({ theme }) => ({
  borderRadius: '3px',
  borderColor: theme.palette.shared.red,
  marginTop: '8px',
}))
const DangerIconWrapper = styled('div')(({ theme }) => ({
  marginRight: '6px',
  alignSelf: 'center',
}))

const DangerTitle = styled(SectionTitle)(({ theme }) => ({
  marginBottom: '20px',
}))

export function DangerContent({ entries }: { entries?: DangerSectionEntry[] }) {
  const theme = useTheme()
  if (!entries || entries.length === 0) return null

  return (
    <Column>
      {entries?.map((entry, index) => (
        <SectionContainer
          key={entry.title}
          hasBorder={index < entries.length - 1}
        >
          <DangerTitle>{entry.title}</DangerTitle>

          {/* Content */}
          <ContentGrid container visible spacing={2}>
            {entry.actions?.map(({ label, infoLabel, onPress, icon }) => {
              const Icon = icon ?? React.Fragment
              return (
                <Grid item xs={12} sm={12} md={6} key={label}>
                  <Column>
                    <BodyText>{infoLabel}</BodyText>
                    <Row>
                      <DangerButton
                        type="submit"
                        variant="outlined"
                        color="error"
                        onClick={onPress}
                      >
                        <Row>
                          <DangerIconWrapper>
                            <Icon />
                          </DangerIconWrapper>
                          <H6
                            fontFamily={FontFamily.Blinker}
                            color={theme.palette.shared.red}
                          >
                            {label}
                          </H6>
                        </Row>
                      </DangerButton>
                    </Row>
                  </Column>
                </Grid>
              )
            })}
          </ContentGrid>
        </SectionContainer>
      ))}
    </Column>
  )
}

DangerContent.defaultProps = {
  entries: null,
}
