import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Card from '@mui/material/Card'
import { styled } from '@mui/material/styles'
import { useIntl } from 'react-intl'
import ROUTES from 'lib/routes'
import { NestExtended } from 'types/nest'
import { FileType } from 'types/archive'
import appConfig from 'config/app'
import { cardBoxShadow, cardBoxShadowHover } from 'assets/styles/theme'
import placeholder from 'assets/images/placeholderNest.png'
import { CardTitle, ContentText } from './cards'
import { Row, RowSpaced } from './shared'
import UserBadge from './user/memberSelect/UserBadge'

const ContainerCard = styled(Card)({
  position: 'relative',
  padding: '0px',
  borderRadius: '4px',
  boxShadow: cardBoxShadow,
  ':hover': {
    cursor: 'pointer',
    boxShadow: cardBoxShadowHover,
  },
})

const Content = styled('div')({
  display: 'flex',
  flex: 1,
  justifyContent: 'flex-end',
  flexDirection: 'column',
  pointerEvents: 'none',
  padding: '8px 12px',
})

const Image = styled('img')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '184px',
  objectFit: 'cover',
}))

interface Props {
  nest: NestExtended
  // onPress optional callback to handle click, otherwise redirect to nest details
  onPress?: (nest: NestExtended) => void
}
function NestCard({ nest, onPress }: Props) {
  // const [pinned, setPinned] = React.useState(false)
  const navigate = useNavigate()
  const intl = useIntl()
  // nest cards must always be shown under the workspace route
  const params = useParams() as { workspaceId: string }
  const handleClick = () =>
    typeof onPress === 'function'
      ? onPress(nest)
      : navigate(
          ROUTES.DASHBOARD_NEST_DETAILS_ROUTE(params.workspaceId, nest.id)
        )

  const coverMedia = nest.archive?.find((i) => i.type === FileType.image)
  const cover = coverMedia?.media?.medium
    ? `${appConfig.media}${coverMedia?.media?.medium}`
    : placeholder

  const cardContent = []
  nest.itemCount &&
    cardContent.push(
      intl.formatMessage({ id: 'label.countItems' }, { number: nest.itemCount })
    )
  nest.containerCount &&
    cardContent.push(
      intl.formatMessage(
        { id: 'label.countContainers' },
        { number: nest.containerCount }
      )
    )
  return (
    <ContainerCard onClick={handleClick}>
      <Image src={cover} alt={nest.title} />
      <Content>
        <CardTitle>{nest.title}</CardTitle>
        <ContentText>
          {cardContent.join(' · ') ||
            intl.formatMessage({ id: 'label.emptySpace' })}
        </ContentText>
        <RowSpaced>
          <div />
          <Row>
            {nest.members?.map((member) => (
              <UserBadge
                key={member.id}
                user={member}
                size={24}
                navigate={navigate}
              />
            ))}
          </Row>
        </RowSpaced>
      </Content>
    </ContainerCard>
  )
}

NestCard.defaultProps = {
  onPress: undefined,
}

export default NestCard
