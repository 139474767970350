import {
  format,
  compareAsc,
  differenceInCalendarMonths,
  differenceInDays,
} from 'date-fns'

export enum DateFormat {
  date = 'dd/MM/yyyy',
  time = 'HH:mm:ss',
  datetime = 'dd/MM/yyyy HH:mm:ss',
}

export const getDateTime = (value: string | Date) =>
  format(new Date(value), DateFormat.datetime) || '-'

export const getDate = (value: string | Date) =>
  format(new Date(value), DateFormat.date) || '-'

export const getTime = (value: string | Date) =>
  format(new Date(value), DateFormat.time) || '-'

export const formatDate = (value: string | Date, dateFormat: DateFormat) =>
  format(new Date(value), dateFormat) || '-'

function getStartOfDay(date: Date) {
  const newDate = new Date(date)
  newDate.setHours(0, 0, 0, 0)
  return newDate
}

export function todayStartOfDay() {
  return getStartOfDay(new Date())
}

export function yesterdayStartOfDay() {
  const yesterday = new Date()
  yesterday.setDate(yesterday.getDate() - 1)
  return getStartOfDay(yesterday)
}

/**
 * Method to check if a provided date is a date in the past.
 */
export function isPastDate(date: Date) {
  return compareAsc(new Date(), new Date(date)) > 0
}

/**
 * Method to check if a provided date is a date in the future.
 */
export function isFutureDate(date: Date) {
  return compareAsc(new Date(), new Date(date)) < 0
}

/**
 * Method to calculate the difference in months between two dates.
 * @param startDate
 * @param endDate
 * @returns
 */
export function intervalInMonths(startDate: Date, endDate: Date) {
  return Math.abs(
    differenceInCalendarMonths(new Date(startDate), new Date(endDate))
  )
}

/**
 * Method to calculate the difference in days between two dates.
 * @param startDate
 * @param endDate
 * @returns
 */
export function intervalInDays(startDate: Date, endDate: Date) {
  return Math.abs(differenceInDays(new Date(startDate), new Date(endDate)))
}

/**
 * Method to sort any number of provided dates.
 * Order: 01/01/2000 - 01/01/2002 - 01/01/2003
 * @param dates
 * @returns
 */
export function sortFromEarliest(...dates: Date[]): Date[] {
  return dates.sort((a, b) => compareAsc(new Date(a), new Date(b)))
}
