import { useMutation, useQuery } from '@apollo/client'
import { Severity } from 'components/providers/SnackbarHOC'
import {
  ADD_WORKSPACE_MEMBER,
  REMOVE_WORKSPACE_MEMBER,
  UPDATE_WORKSPACE_MEMBER,
  WORKSPACE_MEMBERS,
} from 'gql/workspace'
import { IntlShape } from 'react-intl'
import {
  AddWorkspaceMemberData,
  RemoveWorkspaceMemberData,
  RemoveWorkspaceMemberInput,
  UpdateWorkspaceMemberData,
  UpdateWorkspaceMemberInput,
  WorkspaceMemberInput,
  WorkspaceMembersData,
} from 'types/workspace'

export function useData(workspaceId: string) {
  const { data, loading: membersLoading } = useQuery<WorkspaceMembersData>(
    WORKSPACE_MEMBERS,
    { variables: { workspaceId } }
  )

  const members = data?.workspaceMembers.members || []
  const owners = data?.workspaceMembers.owners || []
  const canEdit = data?.workspaceMembers.canEdit
  const loading = membersLoading
  return { loading, data: { members, owners, canEdit } }
}

export function useMutations(
  payload: {
    workspaceId: string
  },
  methods: {
    showSnackbar?: (message: string, severity: Severity) => void
    hideEditingModal: () => void
  },
  intl: IntlShape
) {
  const { showSnackbar, hideEditingModal } = methods
  const refetchQueries = [
    {
      query: WORKSPACE_MEMBERS,
      variables: { workspaceId: payload.workspaceId },
    },
  ]

  const [addWorkspaceMember, { loading: addMemberLoading }] = useMutation<
    AddWorkspaceMemberData,
    WorkspaceMemberInput
  >(ADD_WORKSPACE_MEMBER, {
    onCompleted: () => {
      showSnackbar?.(
        intl.formatMessage({ id: 'label.memberAdded' }),
        Severity.SUCCESS
      )
      hideEditingModal()
    },
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries,
  })

  const [updateWorkspaceMember, { loading: updating }] = useMutation<
    UpdateWorkspaceMemberData,
    UpdateWorkspaceMemberInput
  >(UPDATE_WORKSPACE_MEMBER, {
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    onCompleted: hideEditingModal,
    refetchQueries,
  })
  const [deleteMember, { loading: deleting }] = useMutation<
    RemoveWorkspaceMemberData,
    RemoveWorkspaceMemberInput
  >(REMOVE_WORKSPACE_MEMBER, {
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries,
  })

  const loading = addMemberLoading || updating || deleting

  return {
    loading,
    mutations: {
      addWorkspaceMember,
      updateWorkspaceMember,
      deleteMember,
    },
  }
}
