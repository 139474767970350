import {
  JsonObject,
  WorkspaceAccessRoles,
  WorkspaceAccessRolesBase,
} from 'types/shared'
import { isValidEmail, isValidOption, isValidText } from 'utils/validators'
import { InputType, Type } from 'components/input/types'
import { IntlShape } from 'react-intl'
import { SuggestionSection } from 'components/recordDetails/SuggestionsSection'
import { WorkspaceMember } from 'types/user'

export enum Section {
  general = 'general',
  avatar = 'avatar',
}

type FormType = Partial<WorkspaceMember & { isWorkspaceMember: boolean }>
const isEmailRequired = <T = string>(text?: T, formData?: FormType): boolean =>
  formData?.isWorkspaceMember
    ? typeof text === 'string' && (text?.trim?.().length ?? 0) > 0
    : true

export const generalSectionInputs = (
  intl: IntlShape,
  addingNewMember: boolean,
  editingRecord?: WorkspaceMember
): InputType[] => [
  {
    key: 'firstName',
    label: 'label.firstName',
    type: Type.TEXT,
    autoComplete: 'given-name',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    validators: [
      {
        method: isValidText,
        label: 'label.required',
      },
    ],
  },
  {
    key: 'lastName',
    label: 'label.lastName',
    type: Type.TEXT,
    autoComplete: 'family-name',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    validators: [],
  },
  {
    key: 'email',
    label: 'label.emailAddress',
    type: Type.EMAIL,
    autoComplete: 'email',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    disabled:
      !addingNewMember && editingRecord?.role !== WorkspaceAccessRoles.contact,
    validators: [
      {
        method: isValidEmail,
        label: 'label.invalidEmail',
      },
      {
        method: isEmailRequired,
        label: 'label.required',
      },
    ],
  },
  {
    key: 'isWorkspaceMember',
    label: addingNewMember ? 'label.inviteToBoard' : 'label.boardMember',
    type: Type.CHECKBOX,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    validators: [],
    disabled: !addingNewMember,
    infoLabel: addingNewMember ? 'label.boardInvitationExplain' : undefined,
    // show only for new member creation and only if the member is invited to have board access
    visible: (data: FormType) =>
      (addingNewMember && !!data?.email) || !!data.isWorkspaceMember,
  },
  {
    key: 'role',
    label: 'label.role',
    type: Type.SINGLE_SELECT,
    visible: (data: FormType) => !!data?.email && !!data?.isWorkspaceMember,
    options: Object.values(WorkspaceAccessRolesBase).map((role) => ({
      value: role,
      label: intl.formatMessage({
        id: `role.${role}`,
        defaultMessage: role.toLocaleLowerCase(),
      }),
    })),
    margin: 'dense',
    fullWidth: true,
    validators: [
      {
        method: isValidOption,
        label: 'label.required',
      },
    ],
  },
]

const profilePhotoInputs: InputType[] = [
  {
    key: 'avatarId',
    label: 'label.addProfilePicture',
    type: Type.DROPZONE,
    margin: 'dense',
    fullWidth: true,
    validators: [],
  },
]

const editingSections = (intl: IntlShape): SuggestionSection[] => [
  {
    id: Section.general,
    title: intl.formatMessage({ id: 'label.addMemberContact' }),
    description: '',
    cta: '',
  },
  {
    id: Section.avatar,
    title: intl.formatMessage({ id: 'label.selectImage' }),
    description: '',
    cta: '',
  },
]

export const getSectionWithId = (id: Section, intl: IntlShape) => {
  return editingSections(intl).find((section) => section.id === id)
}

export const generalInitialState = (record?: WorkspaceMember) => ({
  firstName: record?.firstName || '',
  lastName: record?.lastName || '',
  role: record?.role || null,
  email: record?.email || null,
  isWorkspaceMember: record?.role
    ? record?.role !== WorkspaceAccessRoles.contact
    : false,
  // birthday: record.birthday ? new Date(record.birthday) : null,
  // gender: record.gender || '',
})

export const getEditInputs = (
  intl: IntlShape,
  editId: string,
  addingNewMember: boolean,
  editingRecord?: WorkspaceMember
) => {
  switch (editId) {
    case Section.general:
      return generalSectionInputs(intl, addingNewMember, editingRecord)
    case Section.avatar:
      return profilePhotoInputs
    default:
      return []
  }
}

export const getInitialState = (
  record?: WorkspaceMember,
  sectionId?: string
) => {
  switch (sectionId) {
    case Section.general:
      return generalInitialState(record)
    case Section.avatar:
      return { avatarId: undefined }
    default:
      return {}
  }
}

export const getExtraInputs = (editId: string) => {
  return []
}

export const payloadBuilder = (
  formData: JsonObject,
  recordId: string,
  sectionId?: string
) => {
  let payloadData: JsonObject = {}
  if (sectionId)
    switch (sectionId) {
      case Section.general:
        payloadData = {
          firstName: formData.firstName,
          lastName: formData.lastName,
          role: formData.role,
          email: formData.email,
          // birthday: formData.birthday,
          // gender: formData.gender,
        }
        break
      case Section.avatar:
        payloadData = {
          avatarId: formData.avatarId,
        }
        break
      default:
        payloadData = formData
    }

  const payload = {
    id: recordId,
    data: payloadData,
  }
  return payload
}
