import { ServiceProviderExtended } from 'types/serviceProvider'
import { InputType, Type } from 'components/input/types'
import { currencies } from 'lib/constants'
import { isValidText } from 'utils/validators'
import { Contract } from 'types/contracts'
import { UserRequestType } from 'types/userRequest'

export const eventsInputs = (
  providers?: ServiceProviderExtended[],
  contracts?: Contract[]
): InputType[] => {
  const providerField: InputType[] = providers
    ? [
        {
          key: 'providerId',
          label: 'label.serviceProvider',
          infoLabel: 'label.inputAutoCreationProvider',
          type: Type.AUTOCOMPLETE,
          autoComplete: '',
          autoFocus: false,
          margin: 'dense',
          freeSolo: true,
          fullWidth: true,
          options:
            providers?.map((item) => ({
              value: item.id,
              label: item.title,
            })) || [],
          validators: [],
        },
      ]
    : []

  const contractField: InputType[] = contracts
    ? [
        {
          key: 'contractId',
          label: 'label.contract',
          type: Type.AUTOCOMPLETE,
          autoComplete: '',
          autoFocus: false,
          margin: 'dense',
          fullWidth: true,
          options:
            contracts?.map((item) => ({
              value: item.id,
              label: item.title,
            })) || [],
          validators: [],
        },
      ]
    : []
  return [
    {
      key: 'title',
      label: 'label.title',
      type: Type.TEXT,
      autoComplete: '',
      fullWidth: true,
      margin: 'dense',
      validators: [
        {
          method: isValidText,
          label: 'label.required',
        },
      ],
    },
    ...providerField,
    {
      key: 'date',
      label: 'label.eventDate',
      type: Type.DATE,
      autoComplete: '',
      fullWidth: true,
      margin: 'dense',
      validators: [],
    },
    ...contractField,
    {
      key: 'price',
      unitKey: 'currency',
      label: 'label.price',
      type: Type.PRICE,
      autoComplete: '',
      margin: 'dense',
      fullWidth: true,
      options: currencies,
      validators: [],
    },
    {
      key: 'description',
      label: 'label.description',
      type: Type.TEXT,
      autoComplete: '',
      autoFocus: false,
      margin: 'dense',
      fullWidth: true,
      multiline: true,
      validators: [],
    },
  ]
}

export const eventsExtraInputs: InputType[] = []

export const userRequestInputs = (
  type: UserRequestType,
  infoLabel?: string
): InputType[] => {
  return [
    {
      key: 'message',
      label: `label.userRequest.${type}`,
      type: Type.TEXT,
      autoComplete: '',
      autoFocus: false,
      margin: 'dense',
      fullWidth: true,
      multiline: true,
      infoLabel,
      validators: [
        {
          method: isValidText,
          label: 'label.required',
        },
      ],
    },
  ]
}
