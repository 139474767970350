import german from 'assets/lang/de.json'
import english from 'assets/lang/en.json'
import { getLanguage } from 'lib/localStorage'

/**
 * Method to determine if the german language must be shown.
 * @param locale string of the language code,
 * Different variants of the german language might be used for different countries. de-AU
 * @returns
 */
export function isGermanLocale(locale: string) {
  return locale.startsWith('de')
}

export function getLanguageConfig() {
  const locale = getOptimalLanguage()
  let messages
  if (isGermanLocale(locale)) {
    messages = german
  } else {
    messages = english
  }

  return { messages, locale }
}

/**
 * Method to get the optimal language of the app.
 * 1. Check if the user has set a language.
 * 2. Get the browser language config.
 * 3. Default return "en".
 * @returns
 */
export function getOptimalLanguage() {
  return getLanguage() || navigator.language || 'en' // get system locality
}
