import { JsonObject } from '../types/shared'

export const sessionStorageKeys = {
  activeWorkspaceId: 'activeWorkspaceId',
}

export function save(key: string, data: JsonObject) {
  sessionStorage.setItem(key, JSON.stringify(data))
}

export function load(key: string) {
  return JSON.parse(sessionStorage.getItem(key) || '{}')
}

export function remove(key: string) {
  sessionStorage.removeItem(key)
}

export function clearStorage() {
  sessionStorage.clear()
}

export function saveActiveWorkspaceId(workspaceId: string | null) {
  sessionStorage.setItem(
    sessionStorageKeys.activeWorkspaceId,
    JSON.stringify({ workspaceId })
  )
}

export function clearSession() {
  remove(sessionStorage.session)
}

export function getActiveWorkspaceId(): string | null {
  try {
    return load(sessionStorageKeys.activeWorkspaceId).workspaceId
  } catch {
    return null
  }
}
