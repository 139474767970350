import { useWorkspaces } from 'hooks/graphql/workspaces'

export function useData(workspaceId: string) {
  const { data: workspaces, loading: wsLoading } = useWorkspaces()
  const activeWs = workspaces.find((ws) => ws.id === workspaceId)
  const orgWorkspaces =
    workspaces.filter((w) => w.organizationId === activeWs?.organizationId) ||
    []

  return { loading: wsLoading, data: { orgWorkspaces } }
}
