import React from 'react'
import { styled } from '@mui/material/styles'
import { Row, RowSpaced } from 'components/shared'
import AddIcon from '@mui/icons-material/Add'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  SectionLabel,
  SectionLink,
  SectionTitleWrapper,
} from 'components/pages'
import Grid from '@mui/material/Grid'
import { isValidUrl } from 'utils/validators'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import { ArchiveFile } from 'types/archive'
import DocumentGrid from 'components/DocumentGrid'
import { BodyTextLight, H2 } from 'components/typography'
import Pencil from 'assets/svg/Pencil'
import { Link } from 'react-router-dom'

const ItemContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
}))

const ItemWrapper = styled('div')(({ theme }) => ({
  marginBottom: '30px',
  backgroundColor: theme.palette.background.default,
  padding: '16px',
  borderRadius: '5px',
}))

const Content = styled('div')(({ theme }) => ({
  marginTop: '10px',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
}))

const MatrixGridItem = styled(Grid)(({ theme }) => ({}))

const ContainerCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexGrow: 1,
  flexDirection: 'column',
  boxShadow: '0px 1px 8px 0px #00000014',
  flexWrap: 'nowrap',
  padding: '16px',
  borderRadius: '4px',
}))

const CardContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  overflow: 'hidden',
}))

export interface ContentData {
  label: string
  value: string
  to?: string // in app link to navigate to in case its provided
  isLink?: boolean
  visible?: boolean
  deletedAt?: Date | null // deletion date in case the entry has been deleted
  style?: React.CSSProperties
}

function isContentData(data: ContentType): data is ContentData[] {
  return (data as ContentData[])[0]?.label !== undefined
}

type ContentType =
  | ContentData[]
  | {
      recordId: string
      data?: ContentData[]
      children?: React.ReactNode // custom node
      actions?: Action[]
      archive?: ArchiveFile[]
    }[]

export interface DataSection {
  title?: string
  content?: ContentType
}

interface ActionBarProps {
  onEdit?: () => void
  onDelete?: () => void
  onAdd?: () => void
  actions?: Action[]
}
function ActionBar({ onEdit, onDelete, onAdd, actions = [] }: ActionBarProps) {
  return (
    <Row>
      {actions?.map(({ Icon, key, onClick }) => (
        <IconButton key={key} onClick={onClick} size="small">
          {Icon}
        </IconButton>
      ))}
      {actions?.length > 0 && <Divider orientation="vertical" flexItem />}
      {onAdd && (
        <IconButton onClick={onAdd} size="small">
          <AddIcon color="primary" fontSize="small" />
        </IconButton>
      )}
      {onEdit && (
        <IconButton onClick={onEdit} size="small">
          <Pencil size="25px" />
        </IconButton>
      )}
      {onDelete && (
        <IconButton onClick={onDelete} size="small">
          <DeleteIcon color="error" fontSize="small" />
        </IconButton>
      )}
    </Row>
  )
}
ActionBar.defaultProps = {
  onDelete: null,
  onAdd: null,
  onEdit: null,
  actions: [],
}
interface Action {
  key: string
  onClick: () => void
  Icon: JSX.Element
}

interface Props {
  section: DataSection
  children?: React.ReactNode
  onEdit?: (section: DataSection, recordId?: string) => void
  onDelete?: (recordId?: string) => void
  onAdd?: () => void
  actions?: Action[]
}

/**
 * @deprecated When possible check @see {@link ../content/fragments.tsx|EntriesContent}
 * @param param0
 * @returns
 */
function ContentSection({
  section,
  onEdit,
  onDelete,
  onAdd,
  children,
  actions,
}: Props) {
  const handleCtaClick = () => onEdit?.(section)
  const isMatrix = section.content && !isContentData(section.content)
  return (
    <ItemWrapper>
      <SectionTitleWrapper>
        <ItemContent>{section.title && <H2>{section.title}</H2>}</ItemContent>
        <ActionBar
          onEdit={isMatrix ? undefined : handleCtaClick}
          onDelete={isMatrix ? undefined : onDelete}
          onAdd={onAdd}
          actions={isMatrix ? undefined : actions}
        />
      </SectionTitleWrapper>
      <Content>
        {section.content && (
          <React.Fragment>
            {isContentData(section.content) ? (
              <Grid container>
                {section.content?.map(({ label, value, isLink, to, style }) => {
                  const Wrapper = to ? Link : React.Fragment
                  const wrapperProps = to ? { to } : {}
                  const ValueComp =
                    isLink && isValidUrl(value) ? SectionLink : BodyTextLight
                  return (
                    <React.Fragment key={label}>
                      <Grid item xs={4}>
                        <SectionLabel>{label}</SectionLabel>
                      </Grid>
                      <Grid item xs={8}>
                        {/* @ts-ignore */}
                        <Wrapper {...wrapperProps}>
                          <ValueComp style={style} href={value} target="_blank">
                            {value}
                          </ValueComp>
                        </Wrapper>
                      </Grid>
                    </React.Fragment>
                  )
                })}
              </Grid>
            ) : (
              <Grid container spacing={2} mt={1}>
                {section.content?.map(
                  ({
                    recordId,
                    data,
                    children: nodeChildren,
                    actions: contentActions,
                    archive,
                  }) => {
                    const handleEdit = () => onEdit?.(section, recordId)
                    const handleDelete = () => onDelete?.(recordId)
                    return (
                      <MatrixGridItem
                        item
                        xs={12}
                        // xl={6}
                        key={JSON.stringify(data)}
                      >
                        <ContainerCard>
                          <RowSpaced
                            style={{
                              alignItems: 'flex-start',
                              flexWrap: 'nowrap',
                            }}
                          >
                            <CardContent>
                              <Grid container>
                                {data?.map(({ label, value, isLink }) => {
                                  const ValueComp =
                                    isLink && isValidUrl(value)
                                      ? SectionLink
                                      : BodyTextLight
                                  return (
                                    <React.Fragment key={label}>
                                      <Grid item xs={4}>
                                        <SectionLabel>{label}</SectionLabel>
                                      </Grid>
                                      <Grid xs={8}>
                                        <ValueComp href={value} target="_blank">
                                          {value}
                                        </ValueComp>
                                        {nodeChildren}
                                      </Grid>
                                    </React.Fragment>
                                  )
                                })}
                              </Grid>
                            </CardContent>
                            <ActionBar
                              onEdit={onEdit && handleEdit}
                              onDelete={onDelete && handleDelete}
                              actions={contentActions}
                            />
                          </RowSpaced>
                          <Row>
                            {archive && archive.length > 0 && (
                              <DocumentGrid
                                documents={archive}
                                elementSize={100}
                              />
                            )}
                          </Row>
                        </ContainerCard>
                      </MatrixGridItem>
                    )
                  }
                )}
              </Grid>
            )}
          </React.Fragment>
        )}
        {children}
      </Content>
    </ItemWrapper>
  )
}

ContentSection.defaultProps = {
  onDelete: null,
  onAdd: null,
  onEdit: null,
  children: null,
  actions: [],
}

export default ContentSection
