import { ArchiveFile } from './archive'
import { Item } from './item'
import { MonetaryAmount, Status } from './shared'

export enum OrderModels {
  SERVICE_PROVIDER = 'service-provider',
  WORKSPACE = 'workspace',
}

export interface OrderItemRefBase {
  itemId: string
  quantity?: number
  serialNumbers?: string[]
  productLink?: string
  amount?: MonetaryAmount
}

export interface OrderItemRef extends OrderItemRefBase {
  id: string // OrderItemRef id
}

export interface OrderItemRefExtended extends OrderItemRef {
  item: Item
}
export interface Order {
  id: string
  orderId?: string
  notes?: string
  date?: Date
  sellerId?: string
  archiveIds?: string[]
  sellerModel: OrderModels
  buyerId?: string
  buyerModel: OrderModels
  createdAt: Date
  updatedAt: Date
  items: OrderItemRef[]
}

interface TransactionType {
  id: string
  title: string
  description: string
  deletedAt?: Date | null
}

export interface OrderExtended extends Order {
  archive?: ArchiveFile[]
  seller?: TransactionType
  buyer?: TransactionType
  items: OrderItemRefExtended[]
}

export interface OrderExtendedItems extends Order {
  items: OrderItemRefExtended[]
}
export interface UpdateOrderRecordInput {
  payload: {
    id: string
    data: {
      orderId?: string
      notes?: string
      date?: Date
      archiveIds?: string[]
      sellerId?: string | null
      sellerModel?: OrderModels
      buyerId?: string | null
      buyerModel?: OrderModels
    }
  }
}

export interface UpdateOrderInputData {
  updateOrderRecord: Order
}

export interface UpdateOrderItemRefRecordInput {
  payload: {
    refId: string
    orderId: string
    data: {
      quantity?: number
      serialNumbers?: string[]
      productLink?: string
      amount?: MonetaryAmount
    }
  }
}

export interface UpdateOrderItemRefInputData {
  updateOrderItemRef: Order
}

export interface DeleteOrderRecordInput {
  id: string
}

export interface DeleteOrderRecordData {
  deleteOrderRecord: Status
}

export interface CreateOrderRecordData {
  createOrderRecord: Order
}

export interface CreateOrderRecordInput {
  payload: {
    orderId?: string
    notes?: string
    date?: Date
    archiveIds?: string[]
    items: OrderItemRefBase[]
    sellerId?: string
    sellerModel?: OrderModels
    buyerId?: string
    buyerModel?: OrderModels
  }
}
