import { gql } from '@apollo/client'

export const WORKSPACE_ITEMS = gql`
  query WORKSPACE_ITEMS($payload: ItemsAggregateInput!) {
    workspaceItems(payload: $payload) {
      total
      list {
        id
        title
        categoryId
        workspaceId
        description
        coverId
        category {
          id
          title
          titleDE
          slug
        }
        createdAt
        updatedAt
        deletedAt
        archive {
          id
          type
          createdAt
          updatedAt
          media {
            thumbnail
            medium
            full
          }
        }
        members {
          ... on WorkspaceMemberExtended {
            id
            refId
            userId
            email
            workspaceId
            firstName
            lastName
            avatarId
            avatar {
              avatar
            }
            createdAt
            updatedAt
          }

          ... on OrgMembershipExtended {
            id
            refId
            userId
            email
            organizationId
            createdAt
            updatedAt
            user {
              id
              firstName
              lastName
              avatar {
                avatar
              }
            }
          }
        }
      }
    }
  }
`

export const ITEM_BY_ID = gql`
  query ITEM_BY_ID($itemId: ID!) {
    itemById(itemId: $itemId) {
      id
      title
      categoryId
      workspaceId
      description
      coverId
      category {
        id
        title
        titleDE
        slug
      }
      contracts {
        id
        title
        description
        startDate
        endDate
        contractNumber
      }
      containers {
        id
        title
        description
      }
      nests {
        id
        title
        description
      }
      extras {
        id
        itemId
        customValues
        bestBefore
        licensePlate
        displayResolution
        refreshRate
        operatingSystem
        color
        weight {
          value
          unit
        }
        length {
          value
          unit
        }
        width {
          value
          unit
        }
        height {
          value
          unit
        }
      }

      events {
        id
        itemId
        providerId
        contractId
        date
        description
        title
        archiveIds
        createdAt
        updatedAt
        amount {
          value
          currency
        }
        providerData {
          id
          title
          description
          link
          deletedAt
        }
        contract {
          id
          title
          description
          contractNumber
          deletedAt
        }
        archive {
          id
          type
          title
          documentType
          path
          createdAt
          updatedAt
          media {
            thumbnail
            medium
            full
          }
        }
      }
      manufacture {
        id
        manufactureId
        date
        model
        productUrl
        description
        technicalData
        createdAt
        updatedAt
        archiveIds
        archive {
          id
          type
          title
          documentType
          path
          createdAt
          updatedAt
          media {
            thumbnail
            medium
            full
          }
        }
        providerData {
          id
          title
          description
          link
        }
      }
      itemListings {
        id
        place
        link
        price
        currency
        updatedAt
        createdAt
      }

      orders {
        id
        orderId
        notes
        date
        sellerId
        sellerModel
        buyerId
        archiveIds
        buyerModel
        createdAt
        updatedAt
        archive {
          id
          type
          title
          documentType
          path
          createdAt
          updatedAt
          media {
            thumbnail
            medium
            full
          }
        }
        items {
          id
          itemId
          quantity
          serialNumbers
          productLink
          amount {
            value
            currency
          }
          item {
            id
            title
            deletedAt
          }
        }
        seller {
          id
          title
          description
          deletedAt
        }
        buyer {
          id
          title
          description
          deletedAt
        }
      }

      members {
        ... on WorkspaceMemberExtended {
          id
          refId
          userId
          email
          workspaceId
          firstName
          lastName
          avatarId
          avatar {
            avatar
          }
          createdAt
          updatedAt
        }

        ... on OrgMembershipExtended {
          id
          refId
          userId
          email
          organizationId
          createdAt
          updatedAt
          user {
            id
            firstName
            lastName
            avatar {
              avatar
            }
          }
        }
      }
      createdAt
      updatedAt
      deletedAt
      archive {
        id
        type
        title
        documentType
        path
        createdAt
        updatedAt
        media {
          thumbnail
          medium
          full
        }
      }
    }
  }
`

export const CREATE_ITEM = gql`
  mutation CREATE_ITEM($payload: CreateItemInput!) {
    createItem(payload: $payload) {
      id
    }
  }
`

export const UPDATE_CONTAINER_ITEM_REFERENCE = gql`
  mutation UPDATE_CONTAINER_ITEM_REFERENCE(
    $payload: UpdateContainerReferencesInput!
  ) {
    updateContainerItemReferences(payload: $payload) {
      status
    }
  }
`
export const UPDATE_ITEM_CONTAINERS_REFERENCE = gql`
  mutation UPDATE_ITEM_CONTAINERS_REFERENCE(
    $payload: UpdateItemContainersReferencesInput!
  ) {
    updateItemContainersReferences(payload: $payload) {
      status
    }
  }
`

export const UPDATE_NEST_ITEM_REFERENCE = gql`
  mutation UPDATE_NEST_ITEM_REFERENCE($payload: UpdateNestReferencesInput!) {
    updateNestItemReferences(payload: $payload) {
      status
    }
  }
`
export const UPDATE_ITEM_NESTS_REFERENCE = gql`
  mutation UPDATE_ITEM_NESTS_REFERENCE(
    $payload: UpdateItemNestsReferencesInput!
  ) {
    updateItemNestsReferences(payload: $payload) {
      status
    }
  }
`

export const UPDATE_ITEM_GROUPS_REFERENCE = gql`
  mutation UPDATE_ITEM_GROUPS_REFERENCE(
    $payload: UpdateItemGroupsReferencesInput!
  ) {
    updateItemGroupsReferences(payload: $payload) {
      status
    }
  }
`

export const UPDATE_GROUP_ITEM_REFERENCE = gql`
  mutation UPDATE_GROUP_ITEM_REFERENCE($payload: UpdateGroupReferencesInput!) {
    updateGroupItemReferences(payload: $payload) {
      status
    }
  }
`

export const ADD_ITEM_TO_GROUP = gql`
  mutation ADD_ITEM_TO_GROUP($payload: GroupItemInput!) {
    addItemToGroup(payload: $payload) {
      status
    }
  }
`
export const ADD_ITEM_TO_NEST = gql`
  mutation ADD_ITEM_TO_NEST($payload: NestItemInput!) {
    addItemToNest(payload: $payload) {
      status
    }
  }
`

export const ADD_ITEM_TO_CONTAINER = gql`
  mutation ADD_ITEM_TO_CONTAINER($payload: ContainerItemInput!) {
    addItemToContainer(payload: $payload) {
      status
    }
  }
`

export const UPDATE_ITEM = gql`
  mutation UPDATE_ITEM($payload: UpdateItemInput!) {
    updateItem(payload: $payload) {
      id
    }
  }
`

export const DELETE_ITEMS = gql`
  mutation DELETE_ITEMS($itemIds: [ID]!) {
    deleteItems(itemIds: $itemIds) {
      status
    }
  }
`

export const ADD_ITEM_LISTING = gql`
  mutation ADD_ITEM_LISTING($payload: ItemListingInput!) {
    createItemListing(payload: $payload) {
      id
    }
  }
`

export const UPDATE_ITEM_LISTING = gql`
  mutation UPDATE_ITEM_LISTING($payload: UpdateItemListingInput!) {
    updateItemListing(payload: $payload) {
      id
    }
  }
`

export const DELETE_ITEM_LISTING = gql`
  mutation DELETE_ITEM_LISTING($id: ID!) {
    deleteItemListing(id: $id) {
      status
    }
  }
`

export const TRANSFER_ITEMS = gql`
  mutation TRANSFER_ITEMS($payload: TransferItemsInput!) {
    transferItems(payload: $payload) {
      status
    }
  }
`

export const ADD_ITEM_MANUFACTURE = gql`
  mutation ADD_ITEM_MANUFACTURE($payload: ItemManufactureInput!) {
    createItemManufactureRecord(payload: $payload) {
      id
    }
  }
`

export const UPDATE_ITEM_MANUFACTURE = gql`
  mutation UPDATE_ITEM_MANUFACTURE($payload: UpdateItemManufactureInput!) {
    updateItemManufactureRecord(payload: $payload) {
      id
    }
  }
`

export const UPDATE_ORDER_RECORD = gql`
  mutation UPDATE_ORDER_RECORD($payload: UpdateOrderInput!) {
    updateOrderRecord(payload: $payload) {
      id
    }
  }
`

export const UPDATE_ORDER_ITEM_REF_RECORD = gql`
  mutation UPDATE_ORDER_ITEM_REF_RECORD($payload: UpdateOrderItemRefInput!) {
    updateOrderItemRef(payload: $payload) {
      id
    }
  }
`

export const DELETE_ORDER_RECORD = gql`
  mutation DELETE_ORDER_RECORD($id: ID!) {
    deleteOrderRecord(id: $id) {
      status
    }
  }
`

export const CREATE_ORDER_RECORD = gql`
  mutation CREATE_ORDER_RECORD($payload: OrderInput!) {
    createOrderRecord(payload: $payload) {
      id
    }
  }
`

export const ADD_ITEM_EXTRAS = gql`
  mutation ADD_ITEM_EXTRAS($payload: CreateItemExtrasInput!) {
    createItemExtraRecord(payload: $payload) {
      id
    }
  }
`

export const UPDATE_ITEM_EXTRAS = gql`
  mutation UPDATE_ITEM_EXTRAS($payload: UpdateItemExtraInput!) {
    updateItemExtraRecord(payload: $payload) {
      id
    }
  }
`
