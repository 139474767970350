import { DocumentType } from 'types/archive'

/**
 * Generic json object type.
 * Note! Does not work with nested array Array<Array>
 */
export interface JsonObject {
  [key: string]:
    | string
    | number
    | bigint
    | Array<string | number | bigint | boolean | JsonObject | null | undefined>
    | boolean
    | JsonObject
    | null
    | Date
    | undefined
}

export interface Session {
  userId: string
  token: string
  expiresAt: string
}

export interface MonetaryAmount {
  value: number
  currency: Currencies
}

export interface Measurement<Unit> {
  value: number
  unit: Unit
}

export interface Geolocation {
  latitude: number
  longitude: number
}

export interface Address {
  id: string
  houseNumber: string
  street: string
  city: string
  zipCode: number
  countryCode: string
  geolocation?: Geolocation
}

export interface Status {
  status: 'success' | 'failed'
}

export enum WorkspaceAccessRoles {
  owner = 'OWNER',
  manager = 'MANAGER',
  member = 'MEMBER',
  // contact can not be members of the workspace and can not access it
  contact = 'CONTACT',
}

export enum WorkspaceAccessRolesBase {
  owner = 'OWNER',
  manager = 'MANAGER',
  member = 'MEMBER',
}

export enum ConfirmationStatus {
  pending = 'PENDING',
  confirmed = 'CONFIRMED',
  // rejected = 'REJECTED',
}

export interface Action {
  label: string
  onClick: () => void
  variant?: 'contained' | 'outlined'
  color?: 'primary' | 'error' | 'inherit'
}

export interface FileEntry {
  file: File
  // fileType: string
  title?: string
  documentType?: DocumentType
}

export enum PublicationStatus {
  DRAFT = 'DRAFT',
  PUBLIC = 'PUBLIC',
  INTERNAL = 'INTERNAL', // used for internal services
}

export enum MemberModels {
  WORKSPACE_MEMBER = 'workspace-member',
  ORGANIZATION_MEMBER = 'organization-member',
}

export enum Currencies {
  EUR = 'EUR',
  USD = 'USD',
  GBP = 'GBP', // British Pound
  CZK = 'CZK', // Czech Koruna
}
