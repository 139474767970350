import React from 'react'
import { useIntl } from 'react-intl'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'
import { BodyTextLight } from 'components/typography'
import { FontFamily } from 'assets'
import { Button } from 'components/shared'
import {
  gdprCompliant,
  madeInGermany,
  moneyBackGuarantee,
  secureConnection,
  welcome,
} from 'assets/images/landing'
import { Link } from 'react-router-dom'
import ROUTES from 'lib/routes'
import StyledText from 'components/StyledIntlText'

const SectionGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  zIndex: 1,
}))

const ContentDiv = styled('div')(({ theme }) => ({
  padding: '0px 35px 0 35px',
}))

const WelcomeUIContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  margin: '40px 0 30px 0',
  [theme.breakpoints.down('sm')]: {
    margin: '20px 0 30px 0',
  },
}))

const Row = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
}))

const Image = styled('img')(({ theme }) => ({
  width: 'min(550px, 95%)',
  height: 'min(460px, 95%)',
  objectFit: 'contain',
}))

const TrustBadge = styled('img')(({ theme }) => ({
  width: '80px',
  height: '80px',
  objectFit: 'contain',
  [theme.breakpoints.down('md')]: {
    width: '50px',
    height: '50px',
  },
}))

const badges = [
  {
    key: 'made-in-germany',
    logo: madeInGermany,
  },
  {
    key: 'gdpr-compliant',
    logo: gdprCompliant,
  },
  {
    key: 'money-back-guarantee',
    logo: moneyBackGuarantee,
  },
  {
    key: 'secure-connection',
    logo: secureConnection,
  },
]

function WelcomeUI() {
  const theme = useTheme()
  const intl = useIntl()
  const isBigScreen = useMediaQuery(theme.breakpoints.up('md'))
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('sm'))
  const titleSize = isBigScreen ? 38 : isMediumScreen ? 32 : 28

  return (
    <WelcomeUIContainer>
      <SectionGrid container maxWidth="lg">
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          order={{ xs: 3, sm: 2 }}
          alignItems="center"
        >
          <ContentDiv style={{ maxWidth: 'min(420px, 100%)' }}>
            <br />
            <StyledText
              fontFamily={FontFamily.NoirProMedium}
              text="label.landing.title1"
              fontSize={titleSize}
              color={theme.palette.primary.main}
              lineHeight="48px"
            />
            <StyledText
              fontFamily={FontFamily.NoirProMedium}
              text="label.landing.title2"
              fontSize={titleSize}
              lineHeight="48px"
            />
            <br />
            <br />
            <Button
              component={Link}
              to={ROUTES.REGISTER}
              style={{
                backgroundColor: theme.palette.shared.red,
                minWidth: 'min(400px, 100%)',
              }}
            >
              {intl.formatMessage({ id: 'label.landing.startNow' })}
            </Button>
            <br />
            <br />
            <BodyTextLight style={{ opacity: '0.5' }}>
              {intl.formatMessage({ id: 'label.landing.headline.promotion' })}
            </BodyTextLight>
            <br />
            <br />
            <Row style={{ justifyContent: 'space-between' }}>
              {badges.map((item) => (
                <TrustBadge key={item.key} src={item.logo} alt={item.key} />
              ))}
            </Row>
          </ContentDiv>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          order={{ xs: 2, sm: 3 }}
          justifyContent="center"
          padding={1}
        >
          <Image src={welcome} alt="Itemzz headline" />
        </Grid>
      </SectionGrid>
    </WelcomeUIContainer>
  )
}
export default WelcomeUI
