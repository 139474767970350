import React, { useState, useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import { uniqBy } from 'lodash'
import { Container, Content, GridWrapper } from 'components/shared'
import { DataGridComp } from 'components/table/datagrid'
import { WORKSPACE_NESTS, UPDATE_NEST } from 'gql/nest'
import ListingHeadline from 'components/pages/ListingHeadline'
import { useQuery, useMutation } from '@apollo/client'
import {
  NestExtended,
  UpdateNestInput,
  WorkspaceNestsData,
  UpdateNestData,
} from 'types/nest'
import LoadingIndicator from 'components/LoadingIndicator'
import NestCard from 'components/NestCard'
import ListingActions from 'components/pages/ListingActions'
import ROUTES from 'lib/routes'
import { useScrollPercentage } from 'utils/hooks'
import { GridRowId } from '@mui/x-data-grid'
import GridSelectionModal from 'components/GridSelectionModal'
import { Severity, withSnackbar } from 'components/providers/SnackbarHOC'
import { columns } from './fragments'
import EmptyList from 'components/EmptyList'

interface Props {
  showSnackbar?: (message: string, severity: Severity) => void
}
function Nests({ showSnackbar }: Props) {
  const params = useParams() as { workspaceId: string }
  const [scrollPercent] = useScrollPercentage()
  const [total, setTotal] = React.useState(0)
  const [page, setPage] = React.useState(0)
  const [pageSize, setPageSize] = React.useState(25)
  const [filters, setFilters] = React.useState<{ search: string }>()
  const [nests, setNests] = React.useState<NestExtended[]>([])
  const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([])
  const [mode, setMode] = useState<'grid' | 'list'>('grid')
  const payload = {
    workspaceId: params.workspaceId,
    limit: pageSize,
    page,
    filters,
  }
  const { loading } = useQuery<WorkspaceNestsData>(WORKSPACE_NESTS, {
    variables: { payload },
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    onCompleted: ({ workspaceNests }) => {
      mode === 'grid' && total === workspaceNests.total
        ? setNests(uniqBy([...nests, ...workspaceNests.list], 'id'))
        : setNests(workspaceNests.list)
      setTotal(workspaceNests.total)
    },
  })
  useEffect(() => {
    if (scrollPercent > 90 && !loading && nests.length < total) {
      setPage(page + 1)
    }
  }, [scrollPercent])

  const refetchListing = {
    query: WORKSPACE_NESTS,
    variables: {
      payload: { workspaceId: params.workspaceId, limit: 25, page: 0 },
    },
  }
  const [updateNest, { loading: updateLoading }] = useMutation<
    UpdateNestData,
    UpdateNestInput
  >(UPDATE_NEST, {
    refetchQueries: [refetchListing],
  })

  const onPageChange = useCallback((_page: number) => setPage(_page), [setPage])
  const onPageSizeChange = useCallback(
    (_pageSize: number) => setPageSize(_pageSize),
    [setPageSize]
  )

  const toGridView = () => {
    if (mode === 'grid') return
    setMode('grid')
    if (page > 0) {
      setNests([])
      setPage(0)
    }
  }
  const toListView = () => {
    if (mode === 'list') return
    setMode('list')
    if (page > 0) {
      setNests([])
      setPage(0)
    }
  }
  const selectAllRecords = () =>
    setSelectionModel(nests.map((record) => record.id))
  const handleOnSearch = (text: string) => {
    setFilters({ ...filters, search: text })
    setPage(0) // reset the pagination page
  }
  const handleClearFilters = () => {
    setFilters(undefined)
    setPage(0) // reset the pagination page
  }
  const clearSelection = () => setSelectionModel([])
  const selectedAll = selectionModel.length === nests.length
  const isLoading = loading || updateLoading
  const renderActions = () => (
    <ListingActions
      mode={mode}
      toListView={toListView}
      toGridView={toGridView}
      onSearch={handleOnSearch}
      onResetSearch={handleClearFilters}
    />
  )
  return (
    <Container>
      <LoadingIndicator visible={isLoading} />
      <Content>
        <ListingHeadline
          title="label.spaces"
          total={total}
          renderActions={renderActions}
          cta={{
            label: 'label.createNewNest',
            route: ROUTES.DASHBOARD_CREATE_NEST_ROUTE(params.workspaceId),
          }}
        />
        {mode === 'list' ? (
          <GridWrapper>
            <DataGridComp
              initialState={{ pagination: { page, pageSize } }}
              onPageChange={onPageChange}
              onPageSizeChange={onPageSizeChange}
              rows={nests}
              columns={columns(updateNest)}
              // checkboxSelection
              disableSelectionOnClick
              rowsPerPageOptions={[25, 50, 100]}
              rowCount={total || 0}
              experimentalFeatures={{ newEditingApi: true }}
              paginationMode="server"
              // onSelectionModelChange={setSelectionModel}
              selectionModel={selectionModel}
            />
            {selectionModel.length > 0 && (
              <GridSelectionModal
                selectedRows={selectionModel}
                actions={[
                  {
                    label: selectedAll
                      ? 'label.clearSelection'
                      : 'label.selectAll',
                    onClick: selectedAll ? clearSelection : selectAllRecords,
                    variant: 'outlined',
                  },
                  // {
                  //   label: 'label.delete',
                  //   onClick: () =>
                  //     showSnackbar?.(
                  //       'TODO: not implemented!',
                  //       Severity.WARNING
                  //     ),
                  //   color: 'error',
                  // },
                ]}
              />
            )}
          </GridWrapper>
        ) : (
          <Grid container spacing={2} marginTop={0.1}>
            {!isLoading && nests.length === 0 && (
              <Grid item xs={12}>
                <EmptyList />
              </Grid>
            )}
            {nests?.map((nest) => (
              <Grid item xs={12} sm={6} md={4} xl={3} key={nest.id}>
                <NestCard nest={nest} />
              </Grid>
            ))}
          </Grid>
        )}
      </Content>
    </Container>
  )
}

Nests.defaultProps = {
  showSnackbar: undefined,
}

export default withSnackbar(Nests)
