import { useMutation } from '@apollo/client'
import { CONFIRM_WORKSPACE_INVITATION } from 'gql/email'
import { useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  ConfirmWorkspaceInvitationData,
  ConfirmWorkspaceInvitationInput,
} from 'types/email'
import { Severity, withSnackbar } from 'components/providers/SnackbarHOC'
import { GROUPED_WORKSPACES } from 'gql/workspace'
import ROUTES from 'lib/routes'
import { saveActiveWorkspaceId } from 'lib/sessionStorage'
import { useIntl } from 'react-intl'

interface Props {
  showSnackbar?: (message: string, severity: Severity) => void
}
function InventoryHelper({ showSnackbar }: Props) {
  const location = useLocation()
  const intl = useIntl()
  const navigate = useNavigate()
  const params = useParams() as { workspaceId: string; token?: string }

  const [confirmWorkspaceInvitation] = useMutation<
    ConfirmWorkspaceInvitationData,
    ConfirmWorkspaceInvitationInput
  >(CONFIRM_WORKSPACE_INVITATION, {
    onError: (error) => {
      showSnackbar?.(error.message, Severity.ERROR)
      navigate(ROUTES.INDEX)
    },
    onCompleted: () => {
      // set active workspaceId
      // show success message
      saveActiveWorkspaceId(params.workspaceId)
      showSnackbar?.(
        intl.formatMessage({ id: 'label.invitationAccepted' }),
        Severity.SUCCESS
      )
    },
    refetchQueries: [{ query: GROUPED_WORKSPACES }],
  })

  useEffect(() => {
    //   email invitation for the workspace
    if (
      params.token &&
      location.pathname.includes(`${params.workspaceId}/invitation/`)
    ) {
      confirmWorkspaceInvitation({ variables: { token: params.token } })
    }
  }, [])

  return null
}

export default withSnackbar(InventoryHelper)

InventoryHelper.defaultProps = {
  showSnackbar: undefined,
}
