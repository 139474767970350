import React from 'react'
import { Theme, useTheme } from '@mui/material/styles'
import { useIntl } from 'react-intl'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import MailOutlinedIcon from '@mui/icons-material/MailOutlined'
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk'
import FacebookIcon from '@mui/icons-material/Facebook'
import TwitterIcon from '@mui/icons-material/Twitter'
import InstagramIcon from '@mui/icons-material/Instagram'
import Container from '@mui/material/Container'
import Link from '@mui/material/Link'
import { Link as RouterLink } from 'react-router-dom'
import ROUTES from 'lib/routes'
import { logoFull } from 'assets/images'

const linkStyle = (theme: Theme): React.CSSProperties => ({
  display: 'flex',
  fontSize: '16px',
  fontWeight: 400,
  letterSpacing: '0em',
  textAlign: 'left',
  color: theme.palette.text.primary,
  userSelect: 'none',
  fontFamily: 'Open Sans',
  textDecoration: 'none',
  marginBottom: '13px',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    fontSize: '15px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    marginBottom: '10px',
  },
})

const Text = styled('p')(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 400,
  letterSpacing: '0em',
  textAlign: 'left',
  color: theme.palette.text.primary,
  userSelect: 'none',
  fontFamily: 'Open Sans',
  textDecoration: 'none',
  [theme.breakpoints.down('md')]: {
    fontSize: '15px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
  },
}))

const Title = styled('p')(({ theme }) => ({
  fontSize: '35px',
  fontWeight: 400,
  letterSpacing: '0em',
  textAlign: 'left',
  color: theme.palette.text.primary,
  userSelect: 'none',
  fontFamily: 'NoirPro',
  marginBottom: '40px',
  [theme.breakpoints.down('md')]: {
    fontSize: '30px',
    marginBottom: '35px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '25px',
    marginBottom: '30px',
  },
}))

const ImageBrand = styled('img')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  width: '80%',
  height: 'auto',
  [theme.breakpoints.down('sm')]: {
    width: '60%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '50%',
  },
}))

const ContainerBox = styled(Box)(({ theme }) => ({
  borderRadius: '100px 100px 0 0',
  [theme.breakpoints.down('md')]: {
    borderRadius: '60px 60px 0 0',
  },
  [theme.breakpoints.down('sm')]: {
    borderRadius: '30px 30px 0 0',
  },
}))

const Row = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'row',
})

const Footer = styled('footer')(({ theme }) => ({
  width: '100%',
  marginTop: '60px',
}))

function BrandingSection() {
  const intl = useIntl()
  return (
    <div>
      <ImageBrand src={logoFull} alt="Itemzz" />
      <Title>{intl.formatMessage({ id: 'footer.title' })}</Title>
      <Text>{intl.formatMessage({ id: 'footer.socialMedia' })}</Text>
      <Row>
        <FacebookIcon color="primary" style={{ marginRight: '6px' }} />
        <InstagramIcon color="primary" style={{ marginRight: '6px' }} />
        <TwitterIcon color="primary" style={{ marginRight: '6px' }} />
      </Row>
    </div>
  )
}

function AppFooter() {
  const intl = useIntl()
  const theme = useTheme()
  return (
    <Footer>
      <ContainerBox
        px={{ xs: 3, sm: 10 }}
        py={{ xs: 5, sm: 10 }}
        bgcolor={theme.palette.shared.lightRed}
        color="primary"
      >
        <Container maxWidth="lg">
          <Grid container spacing={5}>
            <Grid item xs={12} sm={6} md={3}>
              <BrandingSection />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Title>{intl.formatMessage({ id: 'footer.information' })}</Title>

              <Box>
                <Link
                  component={RouterLink}
                  to={ROUTES.ABOUT_US}
                  style={linkStyle(theme)}
                >
                  {intl.formatMessage({ id: 'label.aboutUs' })}
                </Link>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Title>{intl.formatMessage({ id: 'footer.helpfulLinks' })}</Title>

              <Box>
                <Link component={RouterLink} to="/" style={linkStyle(theme)}>
                  {intl.formatMessage({ id: 'label.helpfulLinks' })}
                </Link>
              </Box>
              <Box>
                <Link component={RouterLink} to="/" style={linkStyle(theme)}>
                  {intl.formatMessage({ id: 'label.helpfulLinks' })}
                </Link>
              </Box>
              <Box>
                <Link component={RouterLink} to="/" style={linkStyle(theme)}>
                  {intl.formatMessage({ id: 'label.helpfulLinks' })}
                </Link>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Title>{intl.formatMessage({ id: 'footer.contact' })}</Title>
              <Box>
                <Link component={RouterLink} to="/" style={linkStyle(theme)}>
                  <PhoneInTalkIcon color="primary" /> +49 176 --- 9999
                </Link>
              </Box>
              <Box>
                <Link component={RouterLink} to="/" style={linkStyle(theme)}>
                  <MailOutlinedIcon color="primary" /> info@itemzz.com
                </Link>
              </Box>

              <Title>{intl.formatMessage({ id: 'footer.legal' })}</Title>
              <Box>
                <Link
                  component={RouterLink}
                  to={ROUTES.IMPRINT}
                  style={linkStyle(theme)}
                >
                  {intl.formatMessage({ id: 'label.imprint' })}
                </Link>
              </Box>
              <Box>
                <Link
                  component={RouterLink}
                  to={ROUTES.PRIVACY}
                  style={linkStyle(theme)}
                >
                  {intl.formatMessage({ id: 'label.privacyPolicy' })}
                </Link>
              </Box>
              <Box>
                <Link
                  component={RouterLink}
                  to={ROUTES.TOS}
                  style={linkStyle(theme)}
                >
                  {intl.formatMessage({ id: 'label.tos' })}
                </Link>
              </Box>
              <Box>
                <Link
                  component={RouterLink}
                  to={ROUTES.WITHDRAWAL}
                  style={linkStyle(theme)}
                >
                  {intl.formatMessage({ id: 'label.rightsOfWithdrawal' })}
                </Link>
              </Box>
            </Grid>
          </Grid>
          <Box pt={{ xs: 3, sm: 6 }}>
            <Text style={{ textAlign: 'center' }}>
              {intl.formatMessage({ id: 'label.productByItemsnest' })}
            </Text>
          </Box>
        </Container>
      </ContainerBox>
    </Footer>
  )
}

export default AppFooter
