import React from 'react'
import { useIntl } from 'react-intl'
import { Button } from 'components/shared'
import MobileStepper from '@mui/material/MobileStepper'
import { FileEntry } from 'types/shared'
import InputRender, { formHasErrors } from './InputRender'
import { InputType, FormDataType } from './types'
import {
  Form,
  FormBackButton,
  InputWrapper,
  Stepper,
  StepLabel,
  Step,
} from './fragments'

interface Props {
  steps: {
    questions: InputType[]
    label: string
    optionalStep?: boolean
  }[]
  data: FormDataType
  setData: (data: FormDataType) => void
  showErrors: boolean
  onSubmit: (data: FormDataType) => void
  setFiles?: React.Dispatch<React.SetStateAction<FileEntry[] | undefined>>
  files?: FileEntry[]
}
function InputStepper({
  setFiles,
  steps,
  data,
  setData,
  showErrors,
  files,
  onSubmit,
}: Props) {
  const intl = useIntl()
  const [activeStep, setActiveStep] = React.useState(0)
  const [skipped, setSkipped] = React.useState(new Set<number>())

  // const isStepOptional = (step: number) => {
  //   return steps[step].optionalStep
  // }

  const isStepSkipped = (step: number) => {
    return skipped.has(step)
  }

  // const handleNext = () => {
  //   if (activeStep === inputs.length - 1) onSubmit(data)
  //   else setActiveStep((prevActiveStep) => prevActiveStep + 1)
  // }

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      onSubmit(data)
      return
    }
    let newSkipped = skipped
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStep)
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped(newSkipped)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  // const handleSkip = () => {
  //   if (!isStepOptional(activeStep)) {
  //     // You probably want to guard against something like this,
  //     // it should never occur unless someone's actively trying to break something.
  //     throw new Error("You can't skip a step that isn't optional.")
  //   }

  //   setActiveStep((prevActiveStep) => prevActiveStep + 1)
  //   setSkipped((prevSkipped) => {
  //     const newSkipped = new Set(prevSkipped.values())
  //     newSkipped.add(activeStep)
  //     return newSkipped
  //   })
  // }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    handleNext()
  }

  const activeInputs = steps[activeStep].questions
  const stepLabels = steps.map((step, index) => step.label)
  const nextButtonLabel =
    activeStep === steps.length - 1 ? 'label.save' : 'label.next'

  return (
    <Form onSubmit={handleSubmit}>
      <Stepper activeStep={activeStep} alternativeLabel connector={null}>
        {stepLabels.map((label, index) => {
          const stepProps: { completed?: boolean } = {}
          const labelProps: { optional?: React.ReactNode } = {}
          // if (isStepOptional(index)) {
          //   labelProps.optional = (
          //     <Typography variant="caption">
          //       {intl.formatMessage({ id: 'label.optional' })}
          //     </Typography>
          //   )
          // }
          if (isStepSkipped(index)) {
            stepProps.completed = false
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>
                {intl.formatMessage({ id: label })}
              </StepLabel>
            </Step>
          )
        })}
      </Stepper>
      <InputWrapper>
        {activeInputs.map((input) => {
          return (
            <InputRender
              key={input.key}
              data={data}
              input={input}
              inputs={activeInputs}
              setData={setData}
              showErrors={showErrors}
              setFiles={setFiles}
              files={files}
            />
          )
        })}
      </InputWrapper>

      <MobileStepper
        variant="dots"
        steps={steps.length}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            color="primary"
            variant="contained"
            form={`form-step-${activeStep}`}
            onClick={handleNext}
            type="submit"
            disabled={formHasErrors(activeInputs, data)}
          >
            {intl.formatMessage({ id: nextButtonLabel })}
          </Button>
        }
        backButton={
          <FormBackButton onClick={handleBack} disabled={activeStep === 0}>
            {intl.formatMessage({ id: 'label.back' })}
          </FormBackButton>
        }
      />
    </Form>
  )
}

InputStepper.defaultProps = {
  setFiles: null,
  files: [],
}
export default InputStepper
