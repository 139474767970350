import React from 'react'
import { IntlShape } from 'react-intl'
import { isValidText } from 'utils/validators'
import { FormDataType, InputType, Type } from 'components/input/types'
import { JsonObject, MemberModels } from 'types/shared'
import { SuggestionSection } from 'components/recordDetails/SuggestionsSection'
import { NestExtended, NestType } from 'types/nest'
import { ContainerExtended } from 'types/container'
import AddIcon from '@mui/icons-material/Add'
import { UserAssign } from 'components/user'
import { UserType } from 'components/user/memberSelect/UserBadge'
import { getLocaleTitle } from 'utils/content'

export enum Section {
  general = 'general',
  features = 'features',
  securityFeatures = 'securityFeatures',
  spaceOptions = 'spaceOptions',
  nestItems = 'nestItems',
  nestContainers = 'nestContainers',
  nestType = 'nestType',
}

const editingSections = (
  intl: IntlShape,
  actions: {
    toggleItemSelect?: () => void
    toggleBoxSelect?: () => void
  }
): SuggestionSection[] => [
  {
    id: Section.general,
    title: intl.formatMessage({ id: 'label.general' }),
    description: '',
    cta: '',
  },
  {
    id: Section.nestType,
    title: intl.formatMessage({ id: 'label.type' }),
    description: '',
    cta: '',
  },
  {
    id: Section.features,
    title: intl.formatMessage({ id: 'label.features' }),
    description: intl.formatMessage({ id: 'label.missingFeaturesDescription' }),
    cta: intl.formatMessage({ id: 'label.add' }),
  },
  {
    id: Section.securityFeatures,
    title: intl.formatMessage({ id: 'label.securityFeatures' }),
    description: intl.formatMessage({
      id: 'label.missingSecurityFeaturesDescription',
    }),
    cta: intl.formatMessage({ id: 'label.add' }),
  },
  {
    id: Section.spaceOptions,
    title: intl.formatMessage({ id: 'label.spaceOptions' }),
    description: intl.formatMessage({
      id: 'label.missingSpaceOptionsDescription',
    }),
    cta: intl.formatMessage({ id: 'label.add' }),
  },
  {
    id: Section.nestItems,
    title: intl.formatMessage({ id: 'label.spaceItems' }),
    description: intl.formatMessage({
      id: 'label.missingSpaceItemsDescription',
    }),
    cta: intl.formatMessage({ id: 'label.add' }),
    onClick: actions.toggleItemSelect,
  },
  {
    id: Section.nestContainers,
    title: intl.formatMessage({ id: 'label.spaceContainers' }),
    description: intl.formatMessage({
      id: 'label.missingSpaceContainersDescription',
    }),
    cta: intl.formatMessage({ id: 'label.add' }),
    onClick: actions.toggleBoxSelect,
  },
]

export const getSectionWithId = (id: string, intl: IntlShape) => {
  return editingSections(intl, {}).find((section) => section.id === id)
}

const nestTypeInput = (intl: IntlShape, nestTypes: NestType[]): InputType[] => [
  {
    key: 'spaceType',
    label: 'label.type',
    type: Type.AUTOCOMPLETE,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    options:
      nestTypes.map((item) => ({
        value: item.id,
        label: getLocaleTitle(intl, item),
      })) || [],
    validators: [],
  },
]

const generalSectionInputs = (
  intl: IntlShape,
  nestTypes: NestType[]
): InputType[] => [
  {
    key: 'title',
    label: 'label.title',
    type: Type.TEXT,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    validators: [
      {
        method: isValidText,
        label: 'label.required',
      },
    ],
  },

  {
    key: 'description',
    label: 'label.description',
    type: Type.TEXT,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    multiline: true,
    validators: [],
  },
  ...nestTypeInput(intl, nestTypes),
]

const featuresSectionInputs = (nestType: NestType): InputType[] => [
  {
    key: 'features',
    label: 'label.features',
    type: Type.MULTI_SELECT,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    useTranslation: true,
    options:
      nestType.featuresOptions?.map((input) => ({
        value: input,
        label: input.toLocaleLowerCase(),
      })) || [],
    validators: [],
  },
]

const securityFeaturesSectionInputs = (nestType: NestType): InputType[] => [
  {
    key: 'securityFeatures',
    label: 'label.securityFeatures',
    type: Type.MULTI_SELECT,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    useTranslation: true,
    options:
      nestType.securityFeaturesOptions?.map((input) => ({
        value: input,
        label: input.toLocaleLowerCase(),
      })) || [],
    validators: [],
  },
]

const spaceOptionsSectionInputs = (nestType: NestType): InputType[] => [
  {
    key: 'spaceUsage',
    label: 'label.spaceUsage',
    type: Type.MULTI_SELECT,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    useTranslation: true,
    options:
      nestType.spaceUsageOptions?.map((input) => ({
        value: input,
        label: input.toLocaleLowerCase(),
      })) || [],
    validators: [],
  },
  {
    key: 'elevation',
    label: 'label.elevation',
    type: Type.SINGLE_SELECT,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    useTranslation: true,
    options:
      nestType.elevationOptions?.map((input) => ({
        value: input,
        label: input.toLocaleLowerCase(),
      })) || [],
    validators: [],
  },
]

const generalInitialState = (record: NestExtended): FormDataType => ({
  title: record.title || '',
  description: record.description || '',
  spaceType: record.spaceOptions.spaceType || '',
})

export const getEditInputs = (
  intl: IntlShape,
  editId: string,
  record: NestExtended,
  nestTypes: NestType[]
) => {
  const nestType = nestTypes.find(
    (item) => item.id === record.spaceOptions.spaceType
  )
  switch (editId) {
    case Section.general:
      return nestTypes ? generalSectionInputs(intl, nestTypes) : []
    case Section.nestType:
      return nestTypes ? nestTypeInput(intl, nestTypes) : []
    case Section.features:
      return nestType ? featuresSectionInputs(nestType) : []
    case Section.securityFeatures:
      return nestType ? securityFeaturesSectionInputs(nestType) : []
    case Section.spaceOptions:
      return nestType ? spaceOptionsSectionInputs(nestType) : []
    default:
      return []
  }
}

export const getExtraInputs = (editId: string) => {
  return []
}

export const getInitialState = (
  record: NestExtended,
  sectionId: string,
  recordId?: string // id of the sub-entity being edited, e.g. item sales info
): FormDataType => {
  switch (sectionId) {
    case Section.general:
      return generalInitialState(record)
    case Section.nestType:
      return {
        spaceType: record.spaceOptions.spaceType || '',
      }
    case Section.features:
      return { features: (record.spaceOptions.features || []) as string[] }
    case Section.securityFeatures:
      return {
        securityFeatures: (record.spaceOptions.securityFeatures ||
          []) as string[],
      }
    case Section.spaceOptions:
      return {
        spaceUsage: (record.spaceOptions.spaceUsage || []) as string[],
        elevation: record.spaceOptions.elevation || '',
      }
    default:
      return {}
  }
}

export const payloadBuilder = (
  record: NestExtended,
  formData: JsonObject,
  recordId: string,
  sectionId?: string
) => {
  let payloadData: JsonObject = {}
  if (sectionId)
    switch (sectionId) {
      case Section.general:
        payloadData = {
          title: formData.title,
          description: formData.description,
          spaceOptions: {
            ...record.spaceOptions,
            spaceType: formData.spaceType ? formData.spaceType : null,
          },
        }
        break
      case Section.nestType:
        payloadData = {
          spaceOptions: {
            ...record.spaceOptions,
            spaceType: formData.spaceType ? formData.spaceType : null,
          },
        }
        break
      case Section.features:
      case Section.securityFeatures:
        payloadData = {
          spaceOptions: {
            ...record.spaceOptions,
            [sectionId]: formData[sectionId],
          },
        }
        break
      case Section.spaceOptions:
        payloadData = {
          spaceOptions: {
            ...record.spaceOptions,
            spaceUsage: formData.spaceUsage,
            elevation: formData.elevation,
          },
        }
        break
      // default:
      //   payloadData = { [sectionId]: formData } // 1:1 mapping of section to payload
    }

  // @ts-ignore
  // eslint-disable-next-line
  delete payloadData.spaceOptions?.__typename

  const payload = {
    id: recordId,
    data: payloadData,
  }
  return payload
}

interface Methods {
  toggleItemSelect: () => void
  toggleBoxSelect: () => void
  createMemberRef: (memberId: string, memberModel: MemberModels) => void
  deleteMemberRef: (refId: string) => void
}
export const relationsContent = (
  intl: IntlShape,
  data: {
    record?: NestExtended
    containers?: ContainerExtended[]
    members: UserType[]
  },
  methods: Methods
) => [
  {
    title: intl.formatMessage({ id: 'label.items' }),
    description: intl.formatMessage(
      { id: 'label.relatedItemsCount' },
      { number: data?.record?.itemCount }
    ),
    action: {
      ariaLabel: 'related items',
      icon: () => <AddIcon color="disabled" />,
      onPress: methods.toggleItemSelect,
    },
  },
  {
    title: intl.formatMessage({ id: 'label.containers' }),
    description: data?.containers
      ? intl.formatMessage(
          { id: 'label.relatedContainerCount' },
          { number: data?.containers.length }
        )
      : '',
    action: {
      ariaLabel: 'related boxes',
      icon: () => <AddIcon color="disabled" />,
      onPress: methods.toggleBoxSelect,
    },
  },
  {
    title: intl.formatMessage({ id: 'label.members' }),
    component: (
      <UserAssign
        users={data.record?.members ?? []}
        members={data.members}
        createMemberRef={methods.createMemberRef}
        deleteMemberRef={methods.deleteMemberRef}
      />
    ),
  },
]
