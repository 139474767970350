import React, { forwardRef, useImperativeHandle, useState } from 'react'
import {
  NestSelectDrawerMultiple,
  GroupSelectDrawer,
  ContainerSelectDrawerMultiple,
} from 'components/selectorDrawers'
import {
  ItemExtended,
  UpdateItemContainersReferencesData,
  UpdateItemGroupsReferencesData,
  UpdateItemNestsReferencesData,
} from 'types/item'
import { GroupExtended } from 'types/group'
import { useMutation } from '@apollo/client'
import {
  ITEM_BY_ID,
  UPDATE_ITEM_CONTAINERS_REFERENCE,
  UPDATE_ITEM_GROUPS_REFERENCE,
  UPDATE_ITEM_NESTS_REFERENCE,
} from 'gql/item'
import { ITEM_GROUPS } from 'gql/group'

interface Props {
  record?: ItemExtended
  groups: GroupExtended[]
}

export interface ModalHandleMethods {
  toggleNestSelect: () => void
  toggleBoxSelect: () => void
  toggleGroupSelect: () => void
}

const ModalHandler = forwardRef(
  (props: Props, ref: React.ForwardedRef<ModalHandleMethods>) => {
    const { record, groups } = props
    const [showGroupDrawer, setShowGroupDrawer] = useState(false)
    const [showBoxSelectDrawer, setShowBoxSelectDrawer] = useState(false)
    const [showNestSelectDrawer, setShowNestSelectDrawer] = useState(false)
    const toggleBoxSelect = () => setShowBoxSelectDrawer(!showBoxSelectDrawer)
    const toggleNestSelect = () =>
      setShowNestSelectDrawer(!showNestSelectDrawer)
    const toggleGroupSelect = () => setShowGroupDrawer(!showGroupDrawer)

    useImperativeHandle(ref, () => ({
      toggleNestSelect,
      toggleBoxSelect,
      toggleGroupSelect,
    }))

    const [updateItemContainersReferences] =
      useMutation<UpdateItemContainersReferencesData>(
        UPDATE_ITEM_CONTAINERS_REFERENCE,
        {
          onCompleted: toggleBoxSelect,
          refetchQueries: [
            { query: ITEM_BY_ID, variables: { itemId: record?.id } },
          ],
        }
      )

    const [updateItemNestsReferences] =
      useMutation<UpdateItemNestsReferencesData>(UPDATE_ITEM_NESTS_REFERENCE, {
        onCompleted: toggleNestSelect,
        refetchQueries: [
          { query: ITEM_BY_ID, variables: { itemId: record?.id } },
        ],
      })

    const [updateItemGroupsRefs] = useMutation<UpdateItemGroupsReferencesData>(
      UPDATE_ITEM_GROUPS_REFERENCE,
      {
        onCompleted: toggleGroupSelect,
        refetchQueries: [
          { query: ITEM_GROUPS, variables: { itemId: record?.id } },
        ],
      }
    )

    const handleOnItemSelect = (groupIds: string[]) => {
      const payload = { itemId: record?.id, groupIds }
      updateItemGroupsRefs({ variables: { payload } })
    }
    const handleOnNestSelect = (nestIds: string[]) => {
      const payload = { itemId: record?.id, nestIds }
      updateItemNestsReferences({ variables: { payload } })
    }
    const handleOnContainerSelect = (containerIds: string[]) => {
      const payload = { itemId: record?.id, containerIds }
      updateItemContainersReferences({ variables: { payload } })
    }
    if (!record) return null

    return (
      <React.Fragment>
        <GroupSelectDrawer
          open={showGroupDrawer}
          toggleDrawer={toggleGroupSelect}
          preSelectedRecords={groups}
          onSubmit={handleOnItemSelect}
        />

        <NestSelectDrawerMultiple
          open={showNestSelectDrawer}
          toggleDrawer={toggleNestSelect}
          preSelectedRecords={record.nests ?? []}
          onSubmit={handleOnNestSelect}
        />

        <ContainerSelectDrawerMultiple
          open={showBoxSelectDrawer}
          toggleDrawer={toggleBoxSelect}
          preSelectedRecords={record.containers ?? []}
          onSubmit={handleOnContainerSelect}
        />
      </React.Fragment>
    )
  }
)

ModalHandler.defaultProps = {
  record: undefined,
}
export default ModalHandler
