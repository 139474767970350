import { currencies } from 'lib/constants'

export function getMonetaryValue(amount?: {
  value?: number
  currency?: string
}) {
  const currency = getCurrencyLabel(amount?.currency)
  return typeof amount?.value === 'number'
    ? `${amount?.value.toFixed(2)} ${currency}`
    : '-'
}

export function getCurrencyLabel(currency?: string) {
  return currencies?.find((i) => i.value === currency)?.label || ''
}

export function calculateVat(value: number, vat: number) {
  return (value * vat) / 100
}

export function calculateTotalWithVat(amount: number, rate: number): number {
  return amount + calculateVat(amount, rate)
}
