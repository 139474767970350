import { DocumentType, FileType } from 'types/archive'
import appConfig from 'config/app'
import { getAuth } from './localStorage'

export const apiEndpoint = `${appConfig.api}/v1`

interface UploadBody {
  workspaceId?: string
  type: FileType
  isPublic?: boolean
  title?: string
  documentType?: DocumentType
}

export interface ApiError {
  message: string
  statusCode: string
  status?: string
}

// image upload
export function uploadMedia(file: File, body: UploadBody, boxCover?: boolean) {
  const formData = new FormData()
  formData.append('media', file)
  formData.append('type', body.type)
  body.title && formData.append('title', body.title)
  body.documentType && formData.append('documentType', body.documentType)
  body.workspaceId && formData.append('workspaceId', body.workspaceId)
  // eslint-disable-next-line no-prototype-builtins
  if (body.hasOwnProperty('isPublic'))
    formData.append('isPublic', body.isPublic ? 'true' : 'false')

  const auth = getAuth()
  return fetch(`${apiEndpoint}/media?boxCover=${boxCover ? 'true' : 'false'}`, {
    method: 'POST',
    body: formData,
    headers: {
      Authorization: `Bearer ${auth?.token}`,
    },
  })
}

export function uploadDocument(file: File, body: UploadBody) {
  const formData = new FormData()
  formData.append('document', file)
  formData.append('type', body.type)
  body.title && formData.append('title', body.title)
  body.workspaceId && formData.append('workspaceId', body.workspaceId)
  body.documentType && formData.append('documentType', body.documentType)
  // eslint-disable-next-line no-prototype-builtins
  if (body.hasOwnProperty('isPublic'))
    formData.append('isPublic', body.isPublic ? 'true' : 'false')

  const auth = getAuth()
  return fetch(`${apiEndpoint}/document`, {
    method: 'POST',
    body: formData,
    headers: {
      Authorization: `Bearer ${auth?.token}`,
    },
  })
}

export function rotateLeft(archiveId: string) {
  const auth = getAuth()
  return fetch(`${apiEndpoint}/rotate-left/${archiveId}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${auth?.token}`,
    },
  })
}
