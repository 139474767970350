import { isValidText } from 'utils/validators'
import { InputType, Type } from 'components/input/types'
import { WorkspaceSection } from 'types/workspace'
import { IntlShape } from 'react-intl'

export const inputs: InputType[] = [
  {
    key: 'title',
    label: 'label.title',
    type: Type.TEXT,
    autoComplete: '',
    autoFocus: true,
    fullWidth: true,
    margin: 'none',
    validators: [
      {
        method: isValidText,
        label: 'label.required',
      },
    ],
  },
  {
    key: 'description',
    label: 'label.description',
    type: Type.TEXT,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    multiline: true,
    validators: [],
  },
]

/**
 * Method to get the information regarding a dashboard section status retarding its subscription plan.
 * @param section
 * @param userId Logged in user id.
 */
export function getSubscriptionStatusCheck(
  intl: IntlShape,
  section: WorkspaceSection,
  userId: string
) {
  const active = section?.subscriptions?.length > 0
  const isOwner = section.ownerId === userId
  // label to show in case not active
  const actionLabel = isOwner
    ? intl.formatMessage({
        id: 'label.subscription.noActiveSubscriptionOwnerMessage',
      })
    : intl.formatMessage(
        { id: 'label.subscription.noActiveSubscriptionContactAdmin' },
        { owner: section.title }
      )

  return { active, isOwner, actionLabel: active ? '' : actionLabel }
}
