import { ApiError, uploadDocument, uploadMedia } from 'lib/api'
import { FileType } from 'types/archive'
import { FileEntry } from 'types/shared'

export interface ErrorType {
  index: number
  error: ApiError
}

interface Callbacks {
  onCompleted?: (data: string[], hasErrors: boolean) => void
  onError?: (error: ErrorType[]) => void
}
export function useFileUpload() {
  /**
   * Handle archive file uploads to server.
   * @param files Array of files to upload
   * @param type File type of the archive files
   * @param workspaceId Related workspace id, used as "owner" workspace of the file.
   * @param skipBoardMapping Optional flag to skip workspace mapping of the file.
   * @returns
   */
  async function uploadFiles(
    files: FileEntry[],
    type: FileType,
    workspaceId: string,
    callbacks?: Callbacks,
    skipBoardMapping?: boolean
  ) {
    if (!files || files.length === 0) return
    const results = await Promise.all(
      files.map(async (object) => {
        const isImageFile = object.file.type.includes('image')
        const uploadFunction = isImageFile ? uploadMedia : uploadDocument
        return uploadFunction(object.file, {
          type,
          title: object.title,
          documentType: object.documentType,
          ...(!skipBoardMapping && { workspaceId }),
        })
          .then((result) => result.json())
          .catch((error) => error)
      })
    )

    const errors: ErrorType[] = []
    const uploadedFileIds = results
      .filter((result, index: number) => {
        const wasSuccessful = result.status === 'success'
        if (!wasSuccessful) {
          errors.push({ error: result, index })
        }
        return wasSuccessful
      })
      // eslint-disable-next-line no-underscore-dangle
      .map((result) => result.data._id)

    // callback response
    callbacks?.onCompleted?.(uploadedFileIds, errors.length > 0)
    callbacks?.onError?.(errors)
  }

  function uploadDocuments(
    files: FileEntry[],
    workspaceId: string,
    callbacks?: Callbacks
  ) {
    return uploadFiles(files, FileType.document, workspaceId, callbacks)
  }

  function uploadImages(
    files: FileEntry[],
    workspaceId: string,
    callbacks?: Callbacks
  ) {
    return uploadFiles(files, FileType.image, workspaceId, callbacks)
  }

  function uploadAvatars(
    files: FileEntry[],
    workspaceId: string,
    callbacks?: Callbacks,
    skipBoardMapping?: boolean
  ) {
    return uploadFiles(
      files,
      FileType.avatar,
      workspaceId,
      callbacks,
      skipBoardMapping
    )
  }

  return { uploadFiles, uploadDocuments, uploadImages, uploadAvatars }
}
