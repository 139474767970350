import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import { useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Container, Content, Row } from 'components/shared'
import { Severity, withSnackbar } from 'components/providers/SnackbarHOC'
import InputStepper from 'components/input/InputStepper'
import { uploadDocument, uploadMedia } from 'lib/api'
import LoadingIndicator from 'components/LoadingIndicator'
import { FormDataType } from 'components/input/types'
import { H1 } from 'components/typography'
import { FileEntry } from 'types/shared'
import { FileType } from 'types/archive'
import { step1Inputs } from './constants'
import { useMutations } from './hooks'

const Wrapper = styled('form')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
}))

interface Props {
  showSnackbar?: (message: string, severity: Severity) => void
}
function Page(props: Props) {
  const { showSnackbar } = props
  const intl = useIntl()
  const params = useParams() as { workspaceId: string; providerId?: string }
  const [files, setFiles] = React.useState<FileEntry[]>()
  // const [showErrors, setShowErrors] = useState(false)
  const [data, setData] = useState<FormDataType>({
    title: '',
    contractNumber: '',
    customerNumber: '',
    startDate: '',
    archiveIds: [],
  })

  const {
    loading,
    mutations: { createContract },
  } = useMutations(params.workspaceId, {
    showSnackbar,
  })

  const onUpload = async (_files?: FileEntry[]) => {
    if (!_files) return []
    const results = await Promise.all(
      _files.map(async (object) => {
        const isImageFile = object.file.type.includes('image')
        const uploadFunction = isImageFile ? uploadMedia : uploadDocument
        return uploadFunction(object.file, {
          workspaceId: params.workspaceId,
          type: FileType.document,
          title: object.title,
        })
          .then((result) => result.json())
          .catch((error) => error)
      })
    )
    const uploadedFileIds = results
      .filter((result) => result.status === 'success')
      // eslint-disable-next-line no-underscore-dangle
      .map((result) => result.data._id)

    const failedUploads = results.filter((result) => result.status === 'error')
    failedUploads.length > 0 &&
      showSnackbar?.(
        `File upload failed, (occurrences: ${failedUploads.length}).
        ${failedUploads[0].message}
      `,
        Severity.ERROR
      )

    return uploadedFileIds
  }

  const handleOnSubmit = async () => {
    const archiveIds = await onUpload(files)
    const payload = {
      archiveIds,
      contractNumber: data.contractNumber as string,
      customer: {
        number: (data.customerNumber as string) || undefined,
      },
      startDate: data.startDate
        ? new Date(data.startDate as string)
        : undefined,
      workspaceId: params.workspaceId,
      title: data.title as string,
      providerId: params.providerId,
    }
    createContract({ variables: { payload } })
  }

  return (
    <Container>
      <LoadingIndicator visible={loading} />
      <Content>
        <Row>
          <H1>{intl.formatMessage({ id: 'label.createNewContract' })}</H1>
        </Row>
        <Wrapper>
          <InputStepper
            steps={[step1Inputs]}
            data={data}
            setData={setData}
            showErrors={false}
            onSubmit={handleOnSubmit}
            setFiles={setFiles}
            files={files}
          />
        </Wrapper>
      </Content>
    </Container>
  )
}

Page.defaultProps = {
  showSnackbar: undefined,
}
export default withSnackbar(Page)
