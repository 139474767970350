import React from 'react'
import { useTheme } from '@mui/material/styles'

interface Props {
  color?: string
  width?: string
}
function Svg({ color, width }: Props) {
  const theme = useTheme()
  return (
    <div style={{ width, display: 'flex' }}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.0249 13.9999C7.0249 12.8213 7.25704 11.6542 7.70808 10.5653C8.15911 9.47642 8.82021 8.48702 9.65361 7.65362C10.487 6.82021 11.4764 6.15912 12.5653 5.70808C13.6542 5.25705 14.8213 5.0249 15.9999 5.0249C17.1785 5.0249 18.3456 5.25705 19.4345 5.70808C20.5234 6.15912 21.5128 6.82021 22.3462 7.65362C23.1796 8.48702 23.8407 9.47642 24.2917 10.5653C24.7427 11.6542 24.9749 12.8213 24.9749 13.9999V13.9999C24.9749 18.4749 25.9124 21.0749 26.7374 22.4999C26.825 22.6517 26.8712 22.8238 26.8714 22.999C26.8715 23.1743 26.8256 23.3465 26.7383 23.4984C26.6509 23.6503 26.5252 23.7766 26.3737 23.8646C26.2221 23.9526 26.0501 23.9993 25.8749 23.9999H6.1249C5.94966 23.9993 5.77766 23.9526 5.62613 23.8646C5.4746 23.7766 5.34886 23.6503 5.26151 23.4984C5.17416 23.3465 5.12826 23.1743 5.12842 22.999C5.12857 22.8238 5.17478 22.6517 5.2624 22.4999C6.0874 21.0749 7.0249 18.4749 7.0249 13.9999Z"
          stroke={color ?? theme.palette.text.secondary}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 24V25C12 26.0609 12.4214 27.0783 13.1716 27.8284C13.9217 28.5786 14.9391 29 16 29C17.0609 29 18.0783 28.5786 18.8284 27.8284C19.5786 27.0783 20 26.0609 20 25V24"
          stroke={color ?? theme.palette.text.secondary}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.9248 3C24.9609 4.28526 26.6041 6.10584 27.6748 8.2625"
          stroke={color ?? theme.palette.text.secondary}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.3252 8.2625C5.39585 6.10584 7.03913 4.28526 9.0752 3"
          stroke={color ?? theme.palette.text.secondary}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  )
}

Svg.defaultProps = {
  color: null,
  width: null,
}

export default Svg
