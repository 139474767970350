import React from 'react'
// import { styled } from '@mui/material/styles'
// import { useParams, useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Container, Content, Row } from 'components/shared'
import { H1 } from 'components/typography'
import {
  // Severity,
  withSnackbar,
} from 'components/providers/SnackbarHOC'
// import { useMutation, useQuery } from '@apollo/client'
// import { CategoriesData } from 'types/category'
// import { CATEGORIES } from 'gql/category'
// import ROUTES from 'lib/routes'
// import LoadingIndicator from 'components/LoadingIndicator'
// import { CREATE_ITEM, WORKSPACE_ITEMS } from 'gql/item'
// import { CreateItemData, CreateItemInput } from 'types/item'
// import { FormData } from 'components/input/types'
// import BarcodeScannerComponent from 'react-qr-barcode-scanner'
// import {
//   defaultCurrency,
//   defaultSizeUnit,
//   defaultWeightUnit,
// } from 'lib/constants'

// const Wrapper = styled('form')(({ theme }) => ({
//   display: 'flex',
//   flex: 1,
//   justifyContent: 'center',
//   alignItems: 'center',
// }))

interface Props {
  // showSnackbar: Function
}
function CreateItem(props: Props) {
  // const { showSnackbar } = props
  const intl = useIntl()
  // const params = useParams() as { workspaceId: string }
  // const navigate = useNavigate()
  // const [showErrors, setShowErrors] = useState(false)
  // const [data, setData] = useState<FormData>({
  //   title: '',
  //   description: '',
  //   categoryId: '',
  //   length: '',
  //   width: '',
  //   height: '',
  //   lengthUnit: defaultSizeUnit, // default to meters, valid for length, width and height
  //   widthUnit: defaultSizeUnit, // default to meters, valid for length, width and height
  //   heightUnit: defaultSizeUnit, // default to meters, valid for length, width and height
  //   weight: '',
  //   weightUnit: defaultWeightUnit, // default to kg, valid for weight
  //   price: '',
  //   currency: defaultCurrency, // default currency euro
  //   purchasePlace: '',
  //   purchaseShopLink: '',
  //   purchaseDate: null,
  //   bestBefore: null,
  // })

  // const { data: categoriesData, loading: categoriesLoading } =
  //   useQuery<CategoriesData>(CATEGORIES)
  // const categories = categoriesData?.categories
  // const [ean, setEan] = useState<string>()

  // const wsItemsPayload = { workspaceId: params.workspaceId, limit: 25, page: 0 }
  // const [createItem, { loading: createLoading }] = useMutation<
  //   CreateItemData,
  //   CreateItemInput
  // >(CREATE_ITEM, {
  //   onCompleted: (response) =>
  //     history.replace(
  //       ROUTES.DASHBOARD_ITEM_DETAILS_ROUTE(
  //         params.workspaceId,
  //         response.createItem.id
  //       )
  //     ),
  //   onError: (error) => showSnackbar(error.message, Severity.ERROR),
  //   refetchQueries: [
  //     { query: WORKSPACE_ITEMS, variables: { payload: wsItemsPayload } },
  //   ],
  // })

  // const handleOnSubmit = () => {
  //   // const weight = data.weight
  //   //   ? {
  //   //       value: data.weight ? Number(data.weight) : undefined,
  //   //       unit: (data.weightUnit as string) || undefined,
  //   //     }
  //   //   : undefined

  //   // const size =
  //   //   data.length || data.width || data.height
  //   //     ? {
  //   //         length: data.length ? Number(data.length) : undefined,
  //   //         width: data.width ? Number(data.width) : undefined,
  //   //         height: data.height ? Number(data.height) : undefined,
  //   //         unit: (data.sizeUnit as string) || undefined,
  //   //       }
  //   //     : undefined

  //   // const length = data.length
  //   //   ? {
  //   //       value: data.length ? Number(data.length) : undefined,
  //   //       unit: (data.lengthUnit as string) || undefined,
  //   //     }
  //   //   : undefined

  //   // const width = data.width
  //   //   ? {
  //   //       value: data.width ? Number(data.width) : undefined,
  //   //       unit: (data.widthUnit as string) || undefined,
  //   //     }
  //   //   : undefined

  //   // const height = data.height
  //   //   ? {
  //   //       value: data.height ? Number(data.height) : undefined,
  //   //       unit: (data.heightUnit as string) || undefined,
  //   //     }
  //   //   : undefined

  //   const payload = {
  //     title: data.title as string,
  //     description: (data.description as string) || undefined,
  //     categoryId: (data.categoryId as string) || undefined,
  //     workspaceId: params.workspaceId,

  //     // purchase: {
  //     //   place: (data.purchasePlace as string) || undefined,
  //     //   shopLink: (data.purchaseShopLink as string) || undefined,
  //     //   date: data.purchaseDate
  //     //     ? new Date(data.purchaseDate as string)
  //     //     : undefined,
  //     //   currency: (data.currency as string) || defaultCurrency,
  //     //   price: Number(data.price) || undefined,
  //     // },
  //     // technicalSpecs: {
  //     //   length,
  //     //   width,
  //     //   height,
  //     //   weight,
  //     // },
  //     // additional: {
  //     //   bestBefore: data.bestBefore
  //     //     ? new Date(data.bestBefore as string)
  //     //     : undefined,
  //     // },
  //   }
  //   createItem({ variables: { payload } })
  // }

  // const isLoading = categoriesLoading || createLoading
  return (
    <Container>
      {/* <LoadingIndicator visible={isLoading} /> */}
      <Content>
        <Row>
          <H1>{intl.formatMessage({ id: 'label.createItem' })}</H1>
        </Row>
        {/* <Wrapper> */}
        {/* {!ean && (
            <BarcodeScannerComponent
              width={500}
              height={500}
              onUpdate={(err, result) => {
                // @ts-expect-error
                if (result) setEan(result?.text)
              }}
            />
          )} */}
        {/* <p>{ean}</p> */}
        {/* </Wrapper> */}
      </Content>
    </Container>
  )
}

// @ts-ignore
export default withSnackbar(CreateItem)
