import React, { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { Severity, withSnackbar } from 'components/providers/SnackbarHOC'
import { useIntl } from 'react-intl'
import ROUTES from 'lib/routes'
import { Container, Content, Row } from 'components/shared'
import { FormDataType } from 'components/input/types'
import InputStepper from 'components/input/InputStepper'
import { useQuery, useMutation } from '@apollo/client'
import { CreateNestData, CreateNestInput, NestTypeData } from 'types/nest'
import { NEST_TYPES, CREATE_NEST, WORKSPACE_NESTS } from 'gql/nest'
import LoadingIndicator from 'components/LoadingIndicator'
import { H1 } from 'components/typography'
import { step1Inputs, step2Inputs } from './constants'

const Wrapper = styled('form')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
}))

interface Props {
  showSnackbar?: (message: string, severity: Severity) => void
}
function CreateNest(props: Props) {
  const { showSnackbar } = props
  const params = useParams() as { workspaceId: string }
  const navigate = useNavigate()
  const intl = useIntl()
  // const [showErrors, setShowErrors] = useState(false)
  const [data, setData] = useState<FormDataType>({
    title: '',
    description: '',
    spaceType: '',
    features: [],
    securityFeatures: [],
    spaceUsage: [],
    elevation: '',
  })
  const { data: options, loading: optionsLoading } =
    useQuery<NestTypeData>(NEST_TYPES)

  const [createNest, { loading: createLoading }] = useMutation<
    CreateNestData,
    CreateNestInput
  >(CREATE_NEST, {
    onCompleted: (response) =>
      navigate(
        ROUTES.DASHBOARD_NEST_DETAILS_ROUTE(
          params.workspaceId,
          response.createNest.id
        ),
        { replace: true }
      ),
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: [
      {
        query: WORKSPACE_NESTS,
        variables: {
          payload: { workspaceId: params.workspaceId, limit: 25, page: 0 },
        },
      },
    ],
  })

  const handleOnSubmit = () => {
    const payload = {
      title: data.title as string,
      description: data.description as string,
      workspaceId: params.workspaceId,
      spaceOptions: {
        spaceType: data.spaceType as string,
        features: data.features as string[],
        securityFeatures: data.securityFeatures as string[],
        spaceUsage: data.spaceUsage as string[],
        elevation: (data.elevation as string) || undefined,
      },
    }

    createNest({ variables: { payload } })
  }

  const nestTypes = options?.nestTypes.list ?? []
  const inputs1 = step1Inputs(intl, nestTypes)
  const inputs2 = step2Inputs(nestTypes, data.spaceType as string)
  const isLoading = optionsLoading || createLoading
  return (
    <Container>
      <LoadingIndicator visible={isLoading} />
      <Content>
        <Row>
          <H1>{intl.formatMessage({ id: 'label.createNest' })}</H1>
        </Row>
        <Wrapper>
          <InputStepper
            steps={[inputs1, inputs2]}
            data={data}
            setData={setData}
            // showErrors={showErrors}
            showErrors={false} // step transition is disabled while invalid data is entered
            onSubmit={handleOnSubmit}
          />
        </Wrapper>
      </Content>
    </Container>
  )
}

CreateNest.defaultProps = {
  showSnackbar: undefined,
}

export default withSnackbar(CreateNest)
