import { sortBy } from 'lodash'

interface SortBase {
  updatedAt: Date
}

function sortDate<T extends SortBase>(records: T, dateField?: string) {
  // @ts-expect-error dateField is not in type
  const date = records[dateField ?? 'updatedAt'] || records.updatedAt // Get the provided date field or use updatedAt
  return new Date(date)
}
export function sortByDate<T extends SortBase>(
  records: T[],
  dateField?: string
) {
  return sortBy(records, (obj) => sortDate(obj, dateField)).reverse()
}
