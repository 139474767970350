import { IntlShape } from 'react-intl'
import { isValidText } from 'utils/validators'
import { InputType, Type } from 'components/input/types'
import { JsonObject, PublicationStatus } from 'types/shared'
import { SuggestionSection } from 'components/recordDetails/SuggestionsSection'
import { ServiceType } from 'types/serviceProvider'
import { ServiceExtended } from 'types/service'
import { NestType, SpaceOptions } from 'types/nest'
import { Category } from 'types/category'
import { getLocaleTitle } from 'utils/content'

export enum Section {
  general = 'general',
  archiveIds = 'archiveIds', // no section
  applicable = 'applicable',
}
const hasApplicableData = (record: ServiceExtended) =>
  record.applicable.categories.length > 0 ||
  record.applicable.features.length > 0 ||
  record.applicable.nestTypes.length > 0 ||
  record.applicable.securityFeatures.length > 0

export const missingDataHints = (
  intl: IntlShape,
  record: ServiceExtended
): SuggestionSection[] => {
  const sections = editingSections(intl)
  // filter out sections that have  been updated
  return sections
    .filter((section) => section.id !== Section.general)
    .filter((section) =>
      hasApplicableData(record) ? section.id !== Section.applicable : true
    )
}

export const editingSections = (intl: IntlShape): SuggestionSection[] => [
  {
    id: Section.general,
    title: intl.formatMessage({ id: 'label.general' }),
    description: '',
    cta: '',
  },
  {
    id: Section.applicable,
    title: intl.formatMessage({ id: 'label.appliesTo' }),
    description: intl.formatMessage({ id: 'label.appliesToInfo' }),
    cta: intl.formatMessage({ id: 'label.add' }),
  },
]

export const getSectionWithId = (id: string, intl: IntlShape) => {
  return editingSections(intl).find((section) => section.id === id)
}

const generalSectionInputs = (
  intl: IntlShape,
  types?: ServiceType[]
): InputType[] => [
  {
    key: 'title',
    label: 'label.title',
    type: Type.TEXT,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    validators: [
      {
        method: isValidText,
        label: 'label.required',
      },
    ],
  },

  {
    key: 'description',
    label: 'label.description',
    type: Type.TEXT,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    multiline: true,
    validators: [],
  },
  {
    key: 'typeId',
    label: 'label.type',
    type: Type.AUTOCOMPLETE,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    options:
      types?.map((item) => ({
        value: item.id,
        label: getLocaleTitle(intl, item),
      })) || [],
    validators: [],
  },
  {
    key: 'status',
    label: 'label.status',
    type: Type.SINGLE_SELECT,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    options:
      Object.values(PublicationStatus).map((input) => ({
        value: input,
        label: input,
      })) || [],
    validators: [],
  },
]
const applicableSectionInputs = (
  intl: IntlShape,
  categories: Category[],
  nestTypes: NestType[],
  spaceOptions?: SpaceOptions
): InputType[] => [
  {
    key: 'categories',
    label: 'label.applicableCategory',
    type: Type.MULTI_SELECT,
    autoComplete: '',
    options: categories.map((type) => ({
      value: type.id,
      label: type.title,
    })),
    margin: 'dense',
    fullWidth: true,
    validators: [],
  },
  {
    key: 'nestTypes',
    label: 'label.applicableNestType',
    type: Type.MULTI_SELECT,
    autoComplete: '',
    options: nestTypes.map((type) => ({
      value: type.id,
      label: getLocaleTitle(intl, type),
    })),
    margin: 'dense',
    fullWidth: true,
    validators: [],
  },

  {
    key: 'features',
    label: 'label.applicableFeatures',
    type: Type.MULTI_SELECT,
    autoComplete: '',
    autoFocus: false,
    useTranslation: true,
    margin: 'dense',
    fullWidth: true,
    options:
      spaceOptions?.featuresOptions.map((input) => ({
        value: input,
        label: input.toLocaleLowerCase(),
      })) || [],
    validators: [],
  },

  {
    key: 'securityFeatures',
    label: 'label.applicableSecurityFeatures',
    type: Type.MULTI_SELECT,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    useTranslation: true,
    fullWidth: true,
    options:
      spaceOptions?.securityFeaturesOptions.map((input) => ({
        value: input,
        label: input.toLocaleLowerCase(),
      })) || [],
    validators: [],
  },
]

const generalInitialState = (record: ServiceExtended) => ({
  title: record.title || '',
  description: record.description || '',
  typeId: record.typeId,
  status: record?.status || PublicationStatus.DRAFT,
})

const applicableInitialState = (record: ServiceExtended) => ({
  nestTypes: record.applicable.nestTypes.map((i) => i.id) || [],
  categories: record.applicable.categories.map((i) => i.id) || [],
  features: record.applicable.features || [],
  securityFeatures: record.applicable.securityFeatures || [],
})

export const getEditInputs = (
  intl: IntlShape,
  editId: string,
  types: ServiceType[],
  categories: Category[],
  nestTypes: NestType[],
  spaceOptions?: SpaceOptions
) => {
  switch (editId) {
    case Section.general:
      return generalSectionInputs(intl, types)
    case Section.applicable:
      return applicableSectionInputs(intl, categories, nestTypes, spaceOptions)
    default:
      return []
  }
}

export const getExtraInputs = (editId: string) => {
  switch (editId) {
    default:
      return []
  }
}

export const getInitialState = (
  record: ServiceExtended,
  sectionId: string,
  recordId?: string // id of the sub-entity being edited, e.g. item sales info
) => {
  switch (sectionId) {
    case Section.general:
      return generalInitialState(record)
    case Section.applicable:
      return applicableInitialState(record)
    default:
      return {}
  }
}

export const payloadBuilder = (
  formData: JsonObject,
  recordId: string,
  sectionId?: string
) => {
  let payloadData = formData
  if (sectionId)
    switch (sectionId) {
      case Section.general:
      case Section.archiveIds:
        payloadData = formData
        break

      default:
        payloadData = { [sectionId]: formData } // 1:1 mapping of section to payload
    }

  const payload = {
    id: recordId,
    data: payloadData,
  }
  return payload
}
