import { isValidEmail, isValidText } from 'utils/validators'
import { InputType, Type } from 'components/input/types'
import { NavigateFunction } from 'react-router-dom'
import ROUTES from 'lib/routes'

export const inputs = (navigate: NavigateFunction): InputType[] => [
  {
    key: 'email',
    label: 'label.emailAddress',
    type: Type.EMAIL,
    autoComplete: 'email',
    autoFocus: true,
    fullWidth: true,
    margin: 'none',
    validators: [
      {
        method: isValidText,
        label: 'label.required',
      },
      {
        method: isValidEmail,
        label: 'label.invalidEmail',
      },
    ],
  },
  {
    key: 'password',
    label: 'label.password',
    type: Type.PASSWORD,
    autoComplete: 'current-password',
    autoFocus: false,
    fullWidth: true,
    margin: 'normal',
    validators: [
      {
        method: isValidText,
        label: 'label.required',
      },
    ],
    action: {
      label: 'label.forgotPassword',
      onClick: () => navigate(ROUTES.PASSWORD_RESET),
    },
  },
]
