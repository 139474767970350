import React from 'react'
import { GridColDef } from '@mui/x-data-grid'
import { TitleCell } from 'components/table/datagrid'
import { IntlShape } from 'react-intl'
import { BodyText } from 'components/typography'
import AutoGraphIcon from '@mui/icons-material/AutoGraph'
import {
  Archive,
  Book,
  Cardholder,
  CellSignalHigh,
  CircleWavy,
  HouseSimple,
  Users,
  ClipboardText,
} from 'assets/svg'
import ROUTES from 'lib/routes'
import {
  StatsEntryProviders,
  StatsMembersEntry,
  WorkspaceStats,
} from 'types/workspace'
import { getMonetaryValue } from 'utils/monetary'
import theme from 'assets/styles/theme'

export const columns = (
  intl: IntlShape,
  updateItem: Function
): GridColDef[] => [
  {
    field: 'id',
    headerName: '#',
    width: 0,
    sortable: false,
    headerAlign: 'center',
    hide: true,
  },
  {
    field: 'title',
    headerName: intl.formatMessage({ id: 'label.title' }),
    width: 250,
    headerAlign: 'center',
    renderCell: (row) => <TitleCell rowData={row} entity="item" />,
    hideable: false,
  },
  {
    field: 'description',
    headerName: intl.formatMessage({ id: 'label.description' }),
    width: 350,
    headerAlign: 'center',
    renderCell: (row) => <BodyText>{row.value}</BodyText>,
    hideable: false,
  },
]

export const inventoryCards = (
  intl: IntlShape,
  workspaceId: string,
  stats?: WorkspaceStats
) => [
  {
    label: intl.formatMessage({ id: 'label.items' }),
    Icon: CircleWavy,
    count: stats?.items.total ?? 0,
    to: ROUTES.DASHBOARD_ITEMS_ROUTE(workspaceId),
  },
  {
    label: intl.formatMessage({ id: 'label.groups' }),
    Icon: Cardholder,
    count: stats?.groups.total ?? 0,
    to: ROUTES.DASHBOARD_GROUPS_ROUTE(workspaceId),
  },
  {
    label: intl.formatMessage({ id: 'label.nests' }),
    Icon: HouseSimple,
    count: stats?.nests.total ?? 0,
    to: ROUTES.DASHBOARD_NESTS_ROUTE(workspaceId),
  },
  {
    label: intl.formatMessage({ id: 'label.containers' }),
    Icon: Archive,
    count: stats?.containers.total ?? 0,
    to: ROUTES.DASHBOARD_CONTAINERS_ROUTE(workspaceId),
  },
  {
    label: intl.formatMessage({ id: 'label.contracts' }),
    Icon: Book,
    count: stats?.contracts.total ?? 0,
    to: ROUTES.DASHBOARD_CONTRACTS_ROUTE(workspaceId),
  },
  {
    label: intl.formatMessage({ id: 'label.serviceProviders' }),
    Icon: CellSignalHigh,
    count: stats?.serviceProviders.total ?? 0,
    description: getProviderLabel(intl, stats?.serviceProviders),
    to: ROUTES.DASHBOARD_SERVICE_PROVIDERS_ROUTE(workspaceId),
  },
  {
    label: intl.formatMessage({ id: 'label.members' }),
    Icon: Users,
    count: stats?.members.total ?? 0,
    description: getMemberLabel(intl, stats?.members),
    to: ROUTES.DASHBOARD_MEMBERS_ROUTE(workspaceId),
  },
  {
    label: intl.formatMessage({ id: 'label.events' }),
    Icon: ClipboardText,
    count: stats?.events.total ?? 0,
    to: '',
  },
  {
    label: intl.formatMessage({ id: 'label.inventory' }),
    Icon: () => (
      <AutoGraphIcon style={{ color: theme.palette.text.secondary }} />
    ),
    description:
      (stats?.finances.sales.length ?? 0) > 0
        ? `• ${intl.formatMessage({ id: 'label.sales' })} ${stats?.finances.sales.map((stat) => `${getMonetaryValue(stat)}`).join(' + ')}`
        : '',
    description2:
      (stats?.finances.purchases.length ?? 0) > 0
        ? `• ${intl.formatMessage({ id: 'label.purchases' })} ${stats?.finances.purchases.map((stat) => `${getMonetaryValue(stat)}`).join(' + ')}`
        : '',
    to: ROUTES.DASHBOARD_ITEMS_ROUTE(workspaceId),
  },
]

function getProviderLabel(
  intl: IntlShape,
  stats?: StatsEntryProviders
): string {
  let label = ''
  if (!stats) return label

  if (stats.totalPrivate > 0)
    label = label.concat(
      `• ${stats.totalPrivate} ${intl.formatMessage({ id: 'label.private' })}`
    )
  if (stats.totalPublic > 0)
    label = label.concat(
      ` • ${stats.totalPublic} ${intl.formatMessage({ id: 'label.public' })}`
    )
  return label
}

function getMemberLabel(intl: IntlShape, stats?: StatsMembersEntry): string {
  let label = ''
  if (!stats) return label

  if (stats.members > 0)
    label = label.concat(
      `• ${stats.members} ${intl.formatMessage({ id: 'label.members' })}`
    )
  if (stats.contacts > 0)
    label = label.concat(
      ` • ${stats.contacts} ${intl.formatMessage({ id: 'label.contacts' })}`
    )
  return label
}
