import { isValidText } from 'utils/validators'
import { InputType, Type } from 'components/input/types'
import { Category } from 'types/category'
import { IntlShape } from 'react-intl'
import { getLocaleTitle } from 'utils/content'

export const step1Inputs = (
  intl: IntlShape,
  categories: Category[]
): {
  questions: InputType[]
  label: string
} => ({
  label: 'label.createItem',
  questions: [
    {
      key: 'title',
      label: 'label.title',
      type: Type.TEXT,
      autoComplete: '',
      autoFocus: false,
      margin: 'dense',
      fullWidth: true,
      validators: [
        {
          method: isValidText,
          label: 'label.required',
        },
      ],
    },

    {
      key: 'description',
      label: 'label.description',
      type: Type.TEXT,
      autoComplete: '',
      autoFocus: false,
      margin: 'dense',
      fullWidth: true,
      multiline: true,
      validators: [],
    },
    {
      key: 'categoryId',
      label: 'label.category',
      type: Type.AUTOCOMPLETE,
      autoComplete: '',
      autoFocus: false,
      margin: 'dense',
      fullWidth: true,
      options:
        categories.map((item) => ({
          value: item.id,
          label: getLocaleTitle(intl, item),
        })) || [],
      validators: [],
    },
  ],
})
