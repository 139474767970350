import React, { useEffect, useState } from 'react'
import { Container, Content } from 'components/shared'
import { H1 } from 'components/typography'
import { useIntl } from 'react-intl'
import MarkdownContent from 'components/MarkdownContent'
import MuiHeader from 'pages/landing/MuiHeader'
import { Toolbar } from '@mui/material'
import { useLocation } from 'react-router-dom'
import ROUTES from 'lib/routes'
import content from './content/tos/10-04-2024.md'

function Page() {
  const intl = useIntl()
  const location = useLocation()
  const [markdown, setMarkdown] = useState<string>('')
  useEffect(() => {
    fetch(content)
      .then((res) => res.text())
      .then(setMarkdown)
  }, [])

  return (
    <Container>
      {!location.pathname.includes(ROUTES.DASHBOARD) && (
        <React.Fragment>
          <MuiHeader />
          <Toolbar />
        </React.Fragment>
      )}
      <Content>
        <H1>{intl.formatMessage({ id: 'label.tos' })}</H1>
        <MarkdownContent markdown={markdown} />
      </Content>
    </Container>
  )
}

export default Page
