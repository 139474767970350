import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Card from '@mui/material/Card'
import { Theme, styled } from '@mui/material/styles'
import ROUTES from 'lib/routes'
import { formatLink } from 'utils/link'
import { cardBoxShadow, cardBoxShadowHover } from 'assets/styles/theme'
import { ContractExtended } from 'types/contracts'
import { useIntl } from 'react-intl'
import { Column, Row, RowSpaced } from './shared'
import { CardTitle, ContentText, StatusChip } from './cards'
import UserBadge from './user/memberSelect/UserBadge'
import { PlaceholderBadge } from './ServiceProviderCard'
import { statusColor } from 'pages/dashboard/contracts/show/constants'

const ContainerCard = styled(Card)({
  padding: '30px 20px 12px 20px',
  gap: '8px',
  borderRadius: '5px',
  position: 'relative',
  boxShadow: cardBoxShadow,
  ':hover': {
    cursor: 'pointer',
    boxShadow: cardBoxShadowHover,
  },
})

const Image = styled('img')<{ size?: number; theme?: Theme }>(
  ({ theme, size = 60 }) => ({
    display: 'flex',
    width: '60px',
    height: '60px',
    objectFit: 'contain',
    borderRadius: size / 2,
    backgroundColor: theme.palette.shared.lightBlue,
    padding: '2px',
  })
)

const Content = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  width: '100%',
})
const CategoryWrapper = styled(Row)(({ theme }) => ({
  marginTop: '4px',
}))

interface Props {
  record: ContractExtended
}
function ContractCard({ record }: Props) {
  const navigate = useNavigate()
  const intl = useIntl()
  // item cards must always be shown under the workspace route
  const params = useParams() as { workspaceId: string }
  const [favError, setFavError] = React.useState(false)
  const handleClick = () =>
    navigate(
      ROUTES.DASHBOARD_CONTRACT_DETAILS_ROUTE(params.workspaceId, record.id)
    )

  const favLink = record.provider?.link
    ? `${formatLink(record.provider?.link)}/favicon.ico`
    : undefined
  const onLoadError = () => setFavError(true)
  const showLink = !favError && favLink
  return (
    <ContainerCard onClick={handleClick}>
      <Column
        sx={{
          flexWrap: 'noWrap',
          alignItems: 'center',
        }}
      >
        {showLink ? (
          <Image src={favLink} onError={onLoadError} />
        ) : (
          <PlaceholderBadge
            placeholder={record.title.substring(0, 1).toUpperCase()}
          />
        )}
      </Column>
      {record.status && (
        <StatusChip
          style={{ position: 'absolute', top: 8, left: 4 }}
          label={intl.formatMessage({
            id: `contract.status.${record.status}`,
            defaultMessage: record.status,
          })}
          color={statusColor(record?.status)}
        />
      )}
      <Row style={{ position: 'absolute', top: 4, right: 4 }}>
        {record.members?.map((member) => (
          <UserBadge
            key={member.id}
            user={member}
            size={24}
            navigate={navigate}
          />
        ))}
      </Row>

      <Content>
        <RowSpaced>
          <CardTitle sx={{ marginTop: '8px' }} numberOfLines={1}>
            {record.title}
          </CardTitle>
        </RowSpaced>
        <CategoryWrapper>
          <ContentText numberOfLines={1}>
            {record.description?.trim() || '-'}
          </ContentText>
        </CategoryWrapper>
      </Content>
    </ContainerCard>
  )
}

ContractCard.defaultProps = {}
export default ContractCard
