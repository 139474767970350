import { gql } from '@apollo/client'

export const CATEGORIES = gql`
  query CATEGORIES {
    categories {
      id
      slug
      title
      description
      titleDE
      descriptionDE
      parentId
      createdAt
      updatedAt
    }
  }
`

