import { useLazyQuery } from '@apollo/client'
import { USER_REQUESTS } from 'gql/userRequest'
import { useEffect } from 'react'
import { UserRequestsData, UserRequestsInput } from 'types/userRequest'

export function useUserRequests(payload: UserRequestsInput['payload']) {
  useEffect(() => {
    // fetch data only when the payload is acceptable
    if (!!payload.referenceModel && !payload.referenceId) {
      return
    }
    fetchData()
  }, [payload?.referenceId])

  const [fetchData, { data, loading }] = useLazyQuery<
    UserRequestsData,
    UserRequestsInput
  >(USER_REQUESTS, {
    variables: { payload },
  })

  return { loading, data: data?.userRequests ?? [] }
}
