import { gql } from '@apollo/client'

export const CREATE_USER_REQUEST = gql`
  mutation CREATE_USER_REQUEST($payload: CreateUserRequestInput!) {
    createUserRequest(payload: $payload) {
      id
    }
  }
`

export const USER_REQUESTS = gql`
  query USER_REQUESTS($payload: UserRequestInput!) {
    userRequests(payload: $payload) {
      id
      type
      status
      message
      referenceId
      referenceModel
      referenceNumber
      replies {
        id
        userId
        message
        date
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
`
