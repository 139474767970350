// this values must match the ones in the server
export const currencies = [
  {
    value: 'EUR',
    label: '€',
  },
  {
    value: 'USD',
    label: '$',
  },
  {
    value: 'GBP',
    label: '£',
  },
  {
    value: 'CZK',
    label: 'Kč',
  },
]

// MM = "MM", // Millimeter
// CM = "CM", // Centimeter
// M = "M", // Meter
// KM = "KM", // Kilometer
// IN = "IN", // Inch
// FT = "FT", // Foot
// MI = "MI", // Mile
export const sizeUnits = [
  {
    value: 'MM',
    label: 'mm',
  },
  {
    value: 'CM',
    label: 'cm',
  },
  {
    value: 'M',
    label: 'm',
  },
  {
    value: 'KM',
    label: 'km',
  },
  {
    value: 'IN',
    label: 'in',
  },
  {
    value: 'FT',
    label: 'ft',
  },
  {
    value: 'MI',
    label: 'mi',
  },
]

// KG = "KG", // Kilogram
// G = "G", // Gram
// LB = "LB", // Pound
// OZ = "OZ", // Ounce
export const weightUnits = [
  {
    value: 'KG',
    label: 'kg',
  },
  {
    value: 'G',
    label: 'g',
  },
  {
    value: 'LB',
    label: 'lb',
  },
  {
    value: 'OZ',
    label: 'oz',
  },
]

export const DATE_FORMAT = 'dd/MM/yyyy'
export const defaultCurrency = 'EUR'
// length, width, height
export const defaultSizeUnit = 'M'
export const defaultWeightUnit = 'KG'

export const iOS =
  typeof navigator !== 'undefined' &&
  /iPad|iPhone|iPod/.test(navigator.userAgent)

export enum MeasurementSystem {
  METRIC = 'METRIC',
  IMPERIAL = 'IMPERIAL',
}
