import React, { forwardRef } from 'react'
import { IntlShape, useIntl } from 'react-intl'
import SectionRender, { SectionEntry } from 'components/content/SectionRender'
import { Theme, useTheme } from '@mui/material/styles'
import {
  SubscriptionExtended,
  SubscriptionPlanType,
  SubscriptionStatus,
} from 'types/subscription'
import { Button, Collapse } from '@mui/material'
import { Link } from 'react-router-dom'
import ROUTES from 'lib/routes'
import { Row } from 'components/shared'
import { getLocaleTitle } from 'utils/content'
import { KeyboardArrowDown } from '@mui/icons-material'
import { getDate, isPastDate } from 'utils/datetime'
import { isEligibleForRefund } from 'utils/subscription'
import { Organization } from 'types/organization'
import BillingTable from './BillingTable'

export function renderSubscriptionItem(
  intl: IntlShape,
  theme: Theme,
  subscription: SubscriptionExtended,
  organization: Organization,
  methods: {
    onRefund?: (subscriptionId: string) => void
  }
) {
  const description = `${getLocaleTitle(intl, subscription.subscriptionPlan)}`
  const hasExpired =
    isPastDate(subscription.endDate) ||
    subscription.status === SubscriptionStatus.EXPIRED
  const status = hasExpired ? 'EXPIRED' : subscription.status

  return {
    description,
    headlineContent:
      subscription.status === SubscriptionStatus.ACTIVE &&
      (subscription.subscriptionPlan.type !== SubscriptionPlanType.FREE ? (
        <Row>
          {isEligibleForRefund(subscription, organization.country) && (
            <Button
              style={{ color: theme.palette.shared.red }}
              onClick={() => methods.onRefund?.(subscription.id)}
            >
              {intl.formatMessage({
                id: 'label.userRequest.subscriptionRequestRefund',
              })}
            </Button>
          )}

          {subscription.subscriptionPlan.type !==
            SubscriptionPlanType.PREMIUM &&
            !hasExpired && (
              <Button
                style={{ color: theme.palette.shared.red }}
                component={Link}
                to={`${ROUTES.DASHBOARD}${ROUTES.PRICING_ROUTE(
                  subscription.id
                )}`}
              >
                {intl.formatMessage({ id: 'label.upgrade' })}
              </Button>
            )}

          <Button
            component={Link}
            to={`${ROUTES.DASHBOARD}${ROUTES.PRICING_EXTEND_ROUTE(
              subscription.id
            )}`}
          >
            {intl.formatMessage({ id: 'label.extendSubscription' })}
          </Button>
        </Row>
      ) : (
        <Row>
          <Button
            component={Link}
            to={`${ROUTES.DASHBOARD}${ROUTES.PRICING_ROUTE()}`}
          >
            {intl.formatMessage({ id: 'label.pricing' })}
          </Button>
        </Row>
      )),
    data: [
      {
        label: intl.formatMessage({ id: 'label.status' }),
        value: intl.formatMessage({
          id: `subscription.status.${status}`,
          defaultMessage: status,
        }),
        style: {
          backgroundColor: hasExpired
            ? theme.palette.shared.lightRed
            : theme.palette.shared.lightBlue,
          flex: 0,
          width: '120px',
          textAlign: 'center',
          borderRadius: '100px',
          fontWeight: 'bold',
          fontSize: '14px',
          color:
            subscription.status === SubscriptionStatus.CANCELED
              ? theme.palette.shared.red
              : theme.palette.text.primary,
        },
      },
      {
        label: intl.formatMessage({ id: 'label.startDate' }),
        value: getDate(subscription.startDate),
      },
      {
        label: intl.formatMessage({ id: 'label.endDate' }),
        value: getDate(subscription.endDate),
      },
    ],
    content: <BillingTable subscription={subscription} />,
  } as SectionEntry
}

interface CollapsedSubscriptionsProps {
  subscriptions: SubscriptionExtended[]
  organization: Organization
}
interface CollapseUiMethods {
  toggleUi: () => void
}

export function CollapsedSubscriptions({
  subscriptions,
  organization,
}: CollapsedSubscriptionsProps) {
  const intl = useIntl()
  const ref = React.useRef<CollapseUiMethods>(null)
  if (!subscriptions || subscriptions.length === 0) return null
  return (
    <SectionRender
      header={intl.formatMessage({ id: 'label.subscriptionHistory' })}
      headerActions={[
        {
          ariaLabel: 'address edit',
          icon: (props) => <KeyboardArrowDown {...props} />,
          onPress: () => ref.current?.toggleUi(),
        },
      ]}
    >
      <CollapseUi
        ref={ref}
        organization={organization}
        subscriptions={subscriptions}
      />
    </SectionRender>
  )
}

const CollapseUi = forwardRef(
  (
    { subscriptions, organization }: CollapsedSubscriptionsProps,
    ref: React.ForwardedRef<CollapseUiMethods>
  ) => {
    const intl = useIntl()
    const theme = useTheme()
    const [open, setOpen] = React.useState(false)
    const toggleUi = () => setOpen(!open)
    React.useImperativeHandle(ref, () => ({
      toggleUi,
    }))
    return (
      <Collapse in={open} timeout="auto" unmountOnExit>
        <SectionRender
          entries={subscriptions.map((subscription) =>
            renderSubscriptionItem(intl, theme, subscription, organization, {})
          )}
        />
      </Collapse>
    )
  }
)
