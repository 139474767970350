import React from 'react'
import { DangerContainer, DangerContent } from './fragments'

interface Action {
  ariaLabel: string
  icon: React.ElementType
  onPress: () => void
  label: string
  infoLabel: string
}

export interface DangerSectionEntry {
  title?: string
  actions?: Action[]
}
interface Props {
  entries?: DangerSectionEntry[]
}
function Section({ entries }: Props) {
  return (
    <DangerContainer>
      <DangerContent entries={entries} />
    </DangerContainer>
  )
}

Section.defaultProps = {
  entries: [],
}

export default Section
