import React from 'react'
import { styled, useTheme } from '@mui/material/styles'

const Wrapper = styled('div')(({ theme }) => ({}))

interface Props {
  size?: number
  percent?: number // 100% filled by default
}
function Logo({ size, percent = 100 }: Props) {
  const theme = useTheme()
  return (
    <Wrapper style={{ stroke: 'url(#blue-gradient)' }}>
      <svg
        width={size}
        height={size}
        viewBox="0 0 417 406"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <linearGradient id="gradient" gradientTransform="rotate(90)">
            <stop offset="0%" stopColor="#C7C7C7" />
            <stop offset={`${100 - percent}%`} stopColor="#C7C7C7" />
            <stop
              offset={`${100 - percent}%`}
              stopColor={theme.palette.primary.main}
            />
            <stop offset="100%" stopColor={theme.palette.primary.main} />
          </linearGradient>
        </defs>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M264.922 186.568V401.819C264.922 404.426 262.13 406.081 259.842 404.831L199.989 372.131C198.497 371.316 197.826 369.531 198.412 367.934C220.587 307.474 242.735 247.046 264.922 186.568V186.568ZM63.195 162.196H257.527C259.912 162.196 261.572 164.57 260.752 166.81L206.581 314.896C205.705 317.291 202.615 317.929 200.862 316.076L60.7008 167.991C58.6288 165.802 60.1808 162.196 63.195 162.196ZM37.1743 58.4751V84.4413C37.1743 86.8961 35.184 88.8864 32.7295 88.8864H4.45404C0.402037 88.8864 -1.53696 83.9081 1.44729 81.1674L29.7225 55.2011C32.5728 52.5839 37.1743 54.6056 37.1743 58.4751ZM51.202 43.0453H75.8818C76.846 43.0453 77.7655 43.4506 78.4163 44.1621L144.223 116.133C145.453 117.478 145.418 119.549 144.144 120.852L115.315 150.331C114.669 150.992 113.784 151.364 112.859 151.364H51.202C49.3053 151.364 47.768 149.826 47.768 147.929C47.772 114.093 47.768 80.2999 47.7678 46.4801C47.7678 44.5834 49.3053 43.0453 51.202 43.0453ZM264.922 16.3841V147.93C264.922 149.827 263.385 151.364 261.49 151.364H137.558C134.515 151.364 132.976 147.701 135.105 145.528C177.238 102.547 217.875 58.1071 258.977 14.0399C261.102 11.7606 264.922 13.2671 264.922 16.3841ZM279.062 0.00184897L413.167 4.3606C416.207 4.45935 417.627 8.1716 415.427 10.2746L281.322 138.532C279.14 140.622 275.515 139.073 275.515 136.051V3.4341C275.515 1.49385 277.122 -0.061151 279.062 0.00184897Z"
          fill="url('#gradient')"
        />
      </svg>
    </Wrapper>
  )
}

Logo.defaultProps = {
  size: 40,
  percent: 100,
}

export default Logo
