import React from 'react'
import { Navigate } from 'react-router-dom'
import Toolbar from '@mui/material/Toolbar'
import AppFooter from 'components/AppFooter'
import { getAuth } from 'lib/localStorage'
import ROUTES from 'lib/routes'
import { styled } from '@mui/material/styles'
import AppHeader from './MuiHeader'
import { Container } from './fragments/fragments'
import WelcomeUI from './fragments/WelcomeUI'
import HowItWorks from './fragments/HowItWorks'
import { getContent } from './content'
import { useIntl } from 'react-intl'
import Section from './fragments/Section'
import CtaBanner from './fragments/CtaBanner'

const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  alignItems: 'center',
}))

interface Props {
  // showSnackbar: Function
}

function Landing(props: Props) {
  const auth = getAuth()
  const intl = useIntl()

  // no session stored
  if (auth?.userId) {
    return <Navigate to={ROUTES.DASHBOARD} replace />
  }

  return (
    <Container>
      <AppHeader />
      <Toolbar />
      <Content>
        <WelcomeUI />
        <HowItWorks />
        {getContent(intl).map((section, index) => (
          <Section
            section={section}
            key={section.cardTitle}
            index={index + 1}
          />
        ))}
        <CtaBanner />
        <AppFooter />
      </Content>
    </Container>
  )
}

export default Landing
