import React from 'react'
import { useIntl } from 'react-intl'
import { useTheme } from '@mui/material/styles'
import { Column, Row, RowSpaced } from 'components/shared'
import SidebarCard from 'components/recordDetails/SidebarCard'
import { Divider } from '@mui/material'
import { BodyText, CaptionText, H3 } from 'components/typography'
import { useQuery } from '@apollo/client'
import { CheckoutUpgradeDetailsData } from 'types/subscription'
import DateRangeSharpIcon from '@mui/icons-material/DateRangeSharp'
import appConfig from 'config/app'
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js'
import StyledText from 'components/StyledIntlText'
import { getDurationLabel } from 'pages/pricing/constants'
import { getLocaleTitle } from 'utils/content'
import paymentConfig from 'lib/payment/upgrade'
import { getCurrencyLabel, getMonetaryValue } from 'utils/monetary'
import { FontFamily } from 'assets'
import { CountriesData } from 'types/static'
import { COUNTRIES } from 'gql/static'
import { Severity } from 'components/providers/SnackbarHOC'
import { OrganizationDataQuery } from 'types/organization'
import { ORGANIZATION_DATA } from 'gql/organization'
import ROUTES from 'lib/routes'
import { useNavigate } from 'react-router-dom'
import { getActiveWorkspaceId } from 'lib/sessionStorage'
import { useSubscriptionStatus } from 'hooks/graphql/workspaces'
import { purchaseSuccessfulVar } from 'lib/reactiveVars'

interface Props {
  checkoutData: CheckoutUpgradeDetailsData['checkoutUpgradeDetails']
  subscriptionId: string
  subscriptionPlanId: string
  showSnackbar?: (message: string, severity: Severity) => void
}
function OrderSummary({
  showSnackbar,
  checkoutData,
  subscriptionId,
  subscriptionPlanId,
}: Props) {
  const { data: countriesData } = useQuery<CountriesData>(COUNTRIES)
  // this payment is always done inside a dashboard.
  const workspaceId = getActiveWorkspaceId()
  const theme = useTheme()
  const intl = useIntl()
  const navigate = useNavigate()
  const { refetch: refetchSubscriptionStatus } =
    useSubscriptionStatus(workspaceId)
  const subscriptionPlan = checkoutData?.subscriptionPlan
  const pricing = checkoutData?.pricing
  const items = checkoutData?.items
  const refundItems = items?.filter((item) => !!item?.invoiceReference)
  const purchaseItems = items?.filter((item) => !item?.invoiceReference)
  const refundAmount = refundItems?.reduce(
    (sum, item) => item.price.value + sum,
    0
  )
  const purchaseAmount = purchaseItems?.reduce(
    (sum, item) => item.price.value + sum,
    0
  )

  const { refetch } = useQuery<OrganizationDataQuery>(ORGANIZATION_DATA, {
    variables: { workspaceId },
  })

  const onSuccessfulPurchase = () => {
    refetch()
    refetchSubscriptionStatus?.()
    purchaseSuccessfulVar(true)
    navigate(`${ROUTES.DASHBOARD}${ROUTES.DASHBOARD_ACCOUNT_PREFERENCES}`)
  }

  return (
    <SidebarCard
      title=""
      content={[
        {
          component: (
            <Column>
              <RowSpaced>
                <H3>
                  {intl.formatMessage({ id: 'label.checkoutOrderSummary' })}
                </H3>
              </RowSpaced>
            </Column>
          ),
        },
        { component: <Divider style={{ flex: 1 }} /> },
        {
          component: (
            <Row>
              <DateRangeSharpIcon sx={{ mr: 1 }} color="info" />
              <BodyText>
                {subscriptionPlan?.duration
                  ? `${getDurationLabel(
                      intl,
                      subscriptionPlan?.duration
                    )} ${intl
                      .formatMessage({ id: 'label.subscription' })
                      .toLowerCase()}`
                  : '-'}
              </BodyText>
            </Row>
          ),
        },
        { component: <Divider style={{ flex: 1 }} /> },
        {
          component: (
            <Column>
              <RowSpaced>
                <CaptionText>
                  {intl.formatMessage({ id: 'label.refundAmount' })}
                </CaptionText>
                <BodyText fontFamily={FontFamily.NoirProMedium}>
                  {refundAmount}{' '}
                  {getCurrencyLabel(refundItems?.[0].price.currency)}
                </BodyText>
              </RowSpaced>
              <RowSpaced>
                <CaptionText>
                  {getLocaleTitle(intl, subscriptionPlan)}
                </CaptionText>
                <BodyText fontFamily={FontFamily.NoirProMedium}>
                  {purchaseAmount}{' '}
                  {getCurrencyLabel(purchaseItems?.[0].price.currency)}
                </BodyText>
              </RowSpaced>
              {subscriptionPlan.extraDuration ? (
                <RowSpaced>
                  <CaptionText color={theme.palette.primary.main}>
                    {intl.formatMessage(
                      { id: 'label.xExtraMonths' },
                      { number: subscriptionPlan.extraDuration }
                    )}
                  </CaptionText>
                  <BodyText fontFamily={FontFamily.NoirProMedium}>
                    {getMonetaryValue({
                      ...subscriptionPlan?.pricing,
                      value: 0,
                    })}
                  </BodyText>
                </RowSpaced>
              ) : (
                <React.Fragment />
              )}
            </Column>
          ),
        },
        { component: <Divider style={{ flex: 1 }} /> },
        {
          component: (
            <Column>
              <RowSpaced>
                <CaptionText>
                  {intl.formatMessage({ id: 'label.upgradePrice' })}
                </CaptionText>
                <BodyText fontFamily={FontFamily.NoirProMedium}>
                  {pricing.value}{' '}
                  {getCurrencyLabel(refundItems?.[0].price.currency)}
                </BodyText>
              </RowSpaced>
              <RowSpaced sx={{ mt: '4px' }}>
                <StyledText
                  text="label.vatTaxCalculation"
                  fontSize={12}
                  fontFamily={FontFamily.OpenSans}
                  injectedValues={{
                    number: pricing.tax.toString(),
                    country:
                      countriesData?.countries[pricing.countryCode] ?? '',
                  }}
                />
                <BodyText fontFamily={FontFamily.NoirProMedium}>
                  {getMonetaryValue({
                    currency: pricing.currency,
                    value: pricing.totalAmount - pricing.value,
                  })}
                </BodyText>
              </RowSpaced>
            </Column>
          ),
        },
        { component: <Divider style={{ flex: 1 }} /> },
        {
          component: (
            <Column>
              {subscriptionPlan && (
                <RowSpaced>
                  <H3>{intl.formatMessage({ id: 'label.total' })}</H3>
                  <H3>
                    {pricing.totalAmount} {getCurrencyLabel(pricing.currency)}
                  </H3>
                </RowSpaced>
              )}
            </Column>
          ),
        },
        { component: <Divider style={{ flex: 1 }} /> },
        {
          title: intl.formatMessage({
            id: 'label.checkoutSelectPaymentMethod',
          }),

          component: (
            <Column style={{ marginTop: '10px' }}>
              <PayPalScriptProvider
                options={{
                  clientId: appConfig.paymentClientId,
                  currency: pricing.currency,
                  components: 'buttons',
                }}
              >
                <PayPalButtons
                  fundingSource="paypal"
                  {...paymentConfig(
                    { subscriptionId, subscriptionPlanId },
                    intl,
                    onSuccessfulPurchase,
                    showSnackbar
                  )}
                />
                <PayPalButtons
                  fundingSource="sepa"
                  {...paymentConfig(
                    { subscriptionId, subscriptionPlanId },
                    intl,
                    onSuccessfulPurchase,
                    showSnackbar
                  )}
                />
                <PayPalButtons
                  fundingSource="card"
                  {...paymentConfig(
                    { subscriptionId, subscriptionPlanId },
                    intl,
                    onSuccessfulPurchase,
                    showSnackbar
                  )}
                />
              </PayPalScriptProvider>
            </Column>
          ),
        },
      ]}
    />
  )
}

OrderSummary.defaultProps = {
  showSnackbar: null,
}
export default OrderSummary
