import * as React from 'react'
import { useIntl } from 'react-intl'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import MuiBottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import SettingsIcon from '@mui/icons-material/Settings'
import MuiIconButton from '@mui/material/IconButton'
import MuiAppBar from '@mui/material/AppBar'
import { SvgIconTypeMap } from '@mui/material/SvgIcon'
import { getActiveWorkspaceId } from 'lib/sessionStorage'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import ROUTES from 'lib/routes'
import {
  Archive,
  Book,
  Cardholder,
  CircleWavy,
  ClipboardText,
  HouseSimple,
} from 'assets/svg'
import SwipeDrawerHoc from './SwipeDrawerHoc'
import { Column, Row } from './shared'
import { CaptionText } from './typography'

const IconButton = styled(MuiIconButton)(({ theme }) => ({
  padding: '8.5px 10px',
  backgroundColor: theme.palette.shared.lightGray,
  borderRadius: '5px',
  margin: '10px 15px',
  minWidth: '65px',
  minHeight: '65px',
}))

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  top: 'auto',
  bottom: 0,
}))

const NavWrapper = styled(MuiBottomNavigation)(({ theme }) => ({
  background: theme.palette.background.default,
}))

interface IconButtonCreateProps {
  label: string
  to: string
  onPress: () => void
  Icon:
    | (OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
        muiName: string
      })
    | unknown
}
function IconButtonCreate({ Icon, label, to, onPress }: IconButtonCreateProps) {
  const navigate = useNavigate()
  const handleOnPress = () => {
    onPress()
    navigate(to)
  }
  return (
    <IconButton onClick={handleOnPress}>
      <Column style={{ alignItems: 'center' }}>
        {/* @ts-expect-error icon type */}
        <Icon width="26px" />
        <CaptionText>{label}</CaptionText>
      </Column>
    </IconButton>
  )
}

interface Props {
  onNotificationClick: () => void
}
function BottomNavigation({ onNotificationClick }: Props) {
  const navigate = useNavigate()
  const intl = useIntl()
  const workspaceId = getActiveWorkspaceId()
  const [open, setOpen] = React.useState(false)
  const [value, setValue] = React.useState('dashboard')
  const toggleVisibility = () => setOpen(!open)
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
    switch (newValue) {
      case 'dashboard':
        navigate(`${ROUTES.DASHBOARD}/${workspaceId ?? ''}`)
        break
      case 'inventory':
        workspaceId && navigate(ROUTES.DASHBOARD_OVERVIEW_ROUTE(workspaceId))
        break
      case 'notifications':
        onNotificationClick()
        break
      case 'settings':
        workspaceId && navigate(ROUTES.DASHBOARD_SETTINGS_ROUTE(workspaceId))
        break
      case 'create':
        toggleVisibility()
        break
    }
  }

  if (!workspaceId) return null
  return (
    <AppBar position="fixed">
      <NavWrapper value={value} onChange={handleChange}>
        {/* <BottomNavigationAction value="dashboard" icon={<DashboardIcon />} /> */}
        <BottomNavigationAction
          value="inventory"
          icon={<ClipboardText width="26px" />}
        />
        <BottomNavigationAction
          value="create"
          icon={<AddCircleOutlineIcon />}
        />
        {/* <BottomNavigationAction
          value="notifications"
          icon={<NotificationsIcon />}
        /> */}
        <BottomNavigationAction value="settings" icon={<SettingsIcon />} />
        {/* <BottomNavigationAction value="more" icon={<MoreVertRoundedIcon />} /> */}
      </NavWrapper>
      <SwipeDrawerHoc open={open} toggleVisibility={toggleVisibility}>
        <Row>
          <IconButtonCreate
            onPress={toggleVisibility}
            Icon={CircleWavy}
            label={intl.formatMessage({ id: 'label.item' })}
            to={ROUTES.DASHBOARD_CREATE_ITEM_ROUTE(workspaceId)}
          />
          <IconButtonCreate
            onPress={toggleVisibility}
            Icon={Cardholder}
            label={intl.formatMessage({ id: 'label.group' })}
            to={ROUTES.DASHBOARD_CREATE_GROUP_ROUTE(workspaceId)}
          />
          <IconButtonCreate
            onPress={toggleVisibility}
            Icon={HouseSimple}
            label={intl.formatMessage({ id: 'label.nest' })}
            to={ROUTES.DASHBOARD_CREATE_NEST_ROUTE(workspaceId)}
          />
          <IconButtonCreate
            onPress={toggleVisibility}
            Icon={Archive}
            label={intl.formatMessage({ id: 'label.container' })}
            to={ROUTES.DASHBOARD_CREATE_CONTAINER_ROUTE(workspaceId)}
          />
          <IconButtonCreate
            onPress={toggleVisibility}
            Icon={Book}
            label={intl.formatMessage({ id: 'label.contract' })}
            to={ROUTES.DASHBOARD_CREATE_CONTRACT_ROUTE(workspaceId)}
          />
        </Row>
      </SwipeDrawerHoc>
    </AppBar>
  )
}

export default BottomNavigation
