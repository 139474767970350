import React, { CSSProperties } from 'react'
import { GridColDef } from '@mui/x-data-grid'
import { TitleCell } from 'components/table/datagrid'
import { IntlShape } from 'react-intl'
import { BodyText } from 'components/typography'
import { Link } from 'react-router-dom'
import ROUTES from 'lib/routes'
import theme from 'assets/styles/theme'
import { getMonetaryValue } from 'utils/monetary'
import { getDate } from 'utils/datetime'

const cellStyle: CSSProperties = {
  textAlign: 'center',
  flex: 1,
  textDecorationLine: 'none',
}
const linkStyle: CSSProperties = {
  color: theme.palette.info.main,
}

export const columns = (intl: IntlShape, workspaceId: string): GridColDef[] => [
  {
    field: 'id',
    headerName: '#',
    width: 0,
    sortable: false,
    headerAlign: 'center',
    hide: true,
  },
  {
    field: 'title',
    headerName: intl.formatMessage({ id: 'label.title' }),
    width: 120,
    headerAlign: 'left',
    renderCell: (row) => <TitleCell rowData={row} entity="contract" />,
    hideable: false,
  },
  {
    field: 'provider.title',
    headerName: intl.formatMessage({ id: 'label.serviceProvider' }),
    width: 150,
    headerAlign: 'center',
    renderCell: ({ row }) => (
      <Link
        style={cellStyle}
        to={ROUTES.DASHBOARD_SERVICE_PROVIDER_DETAILS_ROUTE(
          workspaceId,
          row?.provider?.id
        )}
      >
        <BodyText style={linkStyle}>{row?.provider?.title ?? ''}</BodyText>
      </Link>
    ),
  },
  {
    field: 'status',
    headerName: intl.formatMessage({ id: 'label.status' }),
    width: 100,
    headerAlign: 'center',
    renderCell: ({ row }) => (
      <BodyText style={cellStyle}>
        {intl.formatMessage({
          id: `contract.status.${row.status}`,
          defaultMessage: row.status ?? '-',
        }) ?? ''}
      </BodyText>
    ),
  },
  {
    field: 'payment.type',
    headerName: intl.formatMessage({ id: 'label.type' }),
    width: 150,
    headerAlign: 'center',
    renderCell: ({ row }) => (
      <BodyText style={cellStyle}>
        {intl.formatMessage({
          id: `contract.paymentType.${row?.payment?.type}`,
          defaultMessage: row?.payment?.type ?? '-',
        })}
      </BodyText>
    ),
  },
  {
    field: 'payment.method',
    headerName: intl.formatMessage({ id: 'label.paymentMethod' }),
    width: 150,
    headerAlign: 'center',
    renderCell: ({ row }) => (
      <BodyText style={cellStyle}>
        {intl.formatMessage({
          id: `contract.payment.${row?.payment?.method}`,
          defaultMessage: row?.payment?.method ?? '-',
        })}
      </BodyText>
    ),
  },
  {
    field: 'payment.frequency',
    headerName: intl.formatMessage({ id: 'label.frequency' }),
    width: 150,
    headerAlign: 'center',
    renderCell: ({ row }) => (
      <BodyText style={cellStyle}>
        {intl.formatMessage({
          id: `contract.paymentFrequency.${row?.payment?.frequency}`,
          defaultMessage: row?.payment?.frequency ?? '-',
        })}
      </BodyText>
    ),
  },
  {
    field: 'payment.value',
    headerName: intl.formatMessage({ id: 'label.value' }),
    width: 100,
    headerAlign: 'center',
    renderCell: ({ row }) => (
      <BodyText style={cellStyle}>
        {getMonetaryValue(row?.payment?.amount)}
      </BodyText>
    ),
  },
  {
    field: 'payment.cancelation.date',
    headerName: intl.formatMessage({ id: 'label.cancelationDate' }),
    width: 180,
    headerAlign: 'center',
    renderCell: ({ row }) => (
      <BodyText style={cellStyle}>
        {row?.cancelation?.date ? getDate(row?.cancelation?.date) : '-'}
      </BodyText>
    ),
  },
]
