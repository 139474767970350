import React, { useState, useCallback, useRef } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useTheme } from '@mui/material/styles'
import { Severity, withSnackbar } from 'components/providers/SnackbarHOC'
import { Container, Content } from 'components/shared'
import LoadingIndicator from 'components/LoadingIndicator'
import { FileType } from 'types/archive'
import FormModal from 'components/recordDetails/FormModal'
import { SuggestionSection } from 'components/recordDetails/SuggestionsSection'
import { ErrorType, useFileUpload } from 'hooks/api/fileUpload'
import ROUTES from 'lib/routes'
import Tabs from 'components/Tabs'
import UploadHandler, {
  UploadHandlerMethods,
} from 'components/dropzone/UploadHandler'
import SidebarCard from 'components/recordDetails/SidebarCard'
import {
  FileEntry,
  JsonObject,
  MemberModels,
  WorkspaceAccessRoles,
} from 'types/shared'
import {
  ConfirmationData,
  withConfirmationModal,
} from 'components/hoc/ConfirmationModalHoc'
import { useSubscriptionStatus } from 'hooks/graphql/workspaces'
import { getFullName } from 'components/user/memberSelect/utils'
import { useMediaQuery } from '@mui/material'
import { isOrganizationMember } from 'types/user'
import {
  PageGrid,
  PageGridLeft,
  PageGridRight,
  PageHeader,
  StickySection,
} from 'components/pages'
import {
  Section,
  getDetailsChips,
  getEditInputs,
  getExtraInputs,
  getInitialState,
  getSectionWithId,
  hasContent,
  payloadBuilder,
  profileSidebarContent,
} from './constants'
import Headline from './Headline'
import { useData, useMutations } from './hooks'
import contentTabs from './tabs'

interface Props {
  showSnackbar?: (message: string, severity: Severity) => void
  requestConfirmation?: (data: ConfirmationData) => void
}
function BoxDetails({ showSnackbar, requestConfirmation }: Props) {
  const intl = useIntl()
  const theme = useTheme()
  const navigate = useNavigate()
  const { uploadAvatars, uploadDocuments } = useFileUpload()
  const params = useParams() as {
    workspaceId: string
    memberId: string
    memberModel: MemberModels
  }
  const { active } = useSubscriptionStatus(params.workspaceId)
  // const [editingId, setEditingId] = useState<string>()
  const [editing, setEditing] = useState<SuggestionSection>()
  const hideEditingModal = useCallback(() => setEditing(undefined), [])
  const uploadHandlerRef = useRef<UploadHandlerMethods>(null)
  const [fileUploadErrors, setFileUploadErrors] = useState<ErrorType[]>()
  const isBigScreen = useMediaQuery(theme.breakpoints.up('lg'))

  const toggleDelete = () =>
    requestConfirmation?.({
      title: intl.formatMessage({ id: 'label.deletingMemberTitle' }),
      description: intl.formatMessage({
        id: 'label.deletingMemberDescription',
      }),
      actions: [
        {
          label: intl.formatMessage({ id: 'label.cancel' }),
        },
        {
          label: intl.formatMessage({ id: 'label.delete' }),
          color: 'error',
          onClick: handleDeleteMember,
        },
      ],
    })

  const {
    loading,
    data: { record, profile },
  } = useData(params.memberId, params.memberModel, params.workspaceId)

  const {
    loading: mutating,
    mutations: { deleteMember, updateWorkspaceMember, addFileToMember },
  } = useMutations({ ...params, navigate }, { showSnackbar, hideEditingModal })

  const handleOnEditWithId = (sectionId: Section, recordId?: string) => {
    const section = getSectionWithId(sectionId, intl)
    // setEditingId(recordId)
    setEditing(section)
  }

  const showDocUploadModal = useCallback(() => {
    uploadHandlerRef.current?.openUploader(FileType.document)
  }, [])

  const onUpload = async (files: FileEntry[], fileType: FileType) => {
    if (!record?.id) return
    uploadDocuments(files, params.workspaceId, {
      onCompleted: (archiveIds, hasErrors) => {
        archiveIds.forEach((archiveId) => {
          const payload = {
            archiveId,
            workspaceId: params.workspaceId,
            memberId: record.id,
            memberModel: params.memberModel,
          }
          addFileToMember({ variables: { payload } })
        })

        !hasErrors && uploadHandlerRef.current?.closeUploader()
        showSnackbar?.(
          intl.formatMessage({ id: 'label.uploadSuccessful' }),
          Severity.SUCCESS
        )
      },
      onError: (errors) => {
        setFileUploadErrors(errors)
        uploadHandlerRef.current?.stopLoader()
      },
    })
  }

  const handleRecordUpdate = (formData: JsonObject, sectionId?: string) => {
    if (!record) return
    const payload = payloadBuilder(formData, record.id, sectionId)
    updateWorkspaceMember({ variables: { payload } })
  }
  const convertFromMemberToContact = () => {
    if (
      record &&
      !isOrganizationMember(record) &&
      record.role !== WorkspaceAccessRoles.contact
    ) {
      const payload = {
        id: record.id,
        data: { role: WorkspaceAccessRoles.contact },
      }
      updateWorkspaceMember({ variables: { payload } })
    }
  }
  const handleOnUpdate = async (
    formData: JsonObject,
    sectionId?: string,
    files?: FileEntry[]
  ) => {
    const dropzoneKey =
      inputs.find((input) => input.type === 'dropzone')?.key ?? ''
    // ws cover
    const cover = files?.[0]
    if (cover) {
      await uploadAvatars([cover], params.workspaceId, {
        onCompleted: (archiveIds) => {
          handleRecordUpdate(
            { ...formData, [dropzoneKey]: archiveIds[0] },
            sectionId
          )
        },
      })
    } else {
      handleRecordUpdate(formData, sectionId)
    }
  }

  const handleDeleteMember = () => {
    const payload = { membershipId: params.memberId }
    deleteMember({ variables: { payload } })
  }

  const tabs = contentTabs(
    intl,
    {
      record,
      workspaceId: params.workspaceId,
      memberModel: params.memberModel,
    },
    { toggleDelete, showDocUploadModal }
  )
  const isLinkedToProfile = record?.user?.id === profile?.id
  const isLoading = loading || mutating
  const inputs =
    record && !isOrganizationMember(record)
      ? getEditInputs(editing?.id ?? '', intl, record)
      : []
  const extraInputs = getExtraInputs(editing?.id ?? '')
  const initialState =
    record && editing && !isOrganizationMember(record)
      ? getInitialState(record, editing.id)
      : {}

  const sidebarContent = record
    ? profileSidebarContent(
        intl,
        navigate,
        record,
        isLinkedToProfile,
        handleOnEditWithId,
        convertFromMemberToContact,
        requestConfirmation
      )
    : []
  const sidebar = (
    <React.Fragment>
      {sidebarContent.length > 0 && (
        <SidebarCard
          title={intl.formatMessage({ id: 'label.general' })}
          content={sidebarContent}
        />
      )}
      <SidebarCard
        title={intl.formatMessage({ id: 'label.details' })}
        content={getDetailsChips(intl, theme, {
          record,
        })}
      />
    </React.Fragment>
  )

  return (
    <Container detailPage>
      <LoadingIndicator visible={isLoading} />
      {record && (
        <PageHeader
          pageTitle={intl.formatMessage({ id: 'label.members' })}
          recordTitle={getFullName(record) ?? ''}
          breadcrumbLink={ROUTES.DASHBOARD_MEMBERS_ROUTE(params.workspaceId)}
        />
      )}
      <Content detailPage>
        <PageGrid container spacing={6}>
          <PageGridLeft item xs={12} sm={12} md={12} lg={7} xl={8}>
            {record && (
              <Headline record={record} onEditSection={handleOnEditWithId} />
            )}
            {!isBigScreen && sidebar}
            {hasContent(record) && tabs.length > 0 && (
              <Tabs tabs={tabs} locked={!active} />
            )}
          </PageGridLeft>

          {isBigScreen && (
            <PageGridRight item xs={12} sm={12} md={12} lg={5} xl={4}>
              <StickySection>{sidebar}</StickySection>
            </PageGridRight>
          )}
        </PageGrid>
      </Content>
      <FormModal
        toggleModal={hideEditingModal}
        inputs={inputs}
        extraInputs={extraInputs}
        section={editing}
        initialState={initialState}
        onSubmit={handleOnUpdate}
        singleFileSelect
      />
      <UploadHandler
        ref={uploadHandlerRef}
        onSubmit={onUpload}
        errors={fileUploadErrors}
      />
    </Container>
  )
}

BoxDetails.defaultProps = {
  showSnackbar: undefined,
  requestConfirmation: undefined,
}
export default withConfirmationModal(withSnackbar(BoxDetails))
