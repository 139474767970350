import React from 'react'
import { IntlShape } from 'react-intl'
import { isPositiveNumber, isValidText } from 'utils/validators'
import { FormDataType, InputType, Type } from 'components/input/types'
import { JsonObject, MemberModels } from 'types/shared'
import { SuggestionSection } from 'components/recordDetails/SuggestionsSection'
import { FileType } from 'types/archive'
import {
  CancelationNotice,
  ContractExtended,
  ContractStatus,
  PaymentFrequency,
  PaymentMethods,
  PaymentType,
  RenewalFrequency,
} from 'types/contracts'
import { ContentData } from 'components/recordDetails/ContentSection'
import { getDate } from 'utils/datetime'
import { currencies, defaultCurrency } from 'lib/constants'
import { omit } from 'lodash'
import AddIcon from '@mui/icons-material/Add'
import { getMonetaryValue } from 'utils/monetary'
import { ServiceProviderExtended } from 'types/serviceProvider'
import { UserAssign } from 'components/user'
import { UserType } from 'components/user/memberSelect/UserBadge'
import { eventsExtraInputs, eventsInputs } from 'utils/input-forms'

export enum Section {
  general = 'general',
  cancelation = 'cancelation',
  renewal = 'renewal',
  customer = 'customer',
  account = 'account',
  payment = 'payment',
  provider = 'provider',

  events = 'events',
  eventDocuments = 'eventDocuments',
}

export const editingSections = (intl: IntlShape): SuggestionSection[] => [
  {
    id: Section.general,
    title: intl.formatMessage({ id: 'label.general' }),
    description: '',
    cta: '',
  },
  {
    id: Section.cancelation,
    title: intl.formatMessage({ id: 'label.cancelation' }),
    description: '',
    cta: '',
  },
  {
    id: Section.renewal,
    title: intl.formatMessage({ id: 'label.renewal' }),
    description: '',
    cta: '',
  },
  {
    id: Section.customer,
    title: intl.formatMessage({ id: 'label.customer' }),
    description: '',
    cta: '',
  },
  {
    id: Section.account,
    title: intl.formatMessage({ id: 'label.accountInformation' }),
    description: '',
    cta: '',
  },
  {
    id: Section.payment,
    title: intl.formatMessage({ id: 'label.paymentInfo' }),
    description: '',
    cta: '',
  },
  {
    id: Section.provider,
    title: intl.formatMessage({ id: 'label.serviceProvider' }),
    description: '',
    cta: '',
  },
  {
    id: Section.events,
    title: intl.formatMessage({ id: 'label.events' }),
    description: '',
    cta: '',
  },
  {
    id: Section.eventDocuments,
    title: intl.formatMessage({ id: 'label.addDocuments' }),
    description: '',
    cta: '',
  },
]

export const getSectionWithId = (id: string, intl: IntlShape) => {
  return editingSections(intl).find((section) => section.id === id)
}

const generalSectionInputs = (intl: IntlShape): InputType[] => [
  {
    key: 'title',
    label: 'label.title',
    type: Type.TEXT,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    validators: [
      {
        method: isValidText,
        label: 'label.required',
      },
    ],
  },
  {
    key: 'status',
    label: 'label.status',
    type: Type.SINGLE_SELECT,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    options:
      Object.values(ContractStatus).map((status) => ({
        value: status,
        label: intl.formatMessage({
          id: `contract.status.${status}`,
          defaultMessage: status,
        }),
      })) || [],
    validators: [
      {
        method: isValidText,
        label: 'label.required',
      },
    ],
  },
  {
    key: 'contractNumber',
    label: 'label.contractNumber',
    type: Type.TEXT,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
  },

  {
    key: 'startDate',
    label: 'label.startDate',
    type: Type.DATE,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
  },
  {
    key: 'description',
    label: 'label.description',
    type: Type.TEXT,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    multiline: true,
  },
]

const cancelationSectionInputs = (): InputType[] => [
  {
    key: 'link',
    label: 'label.link',
    type: Type.TEXT,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
  },
  {
    key: 'date',
    label: 'label.date',
    type: Type.DATE,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
  },
  {
    key: 'notes',
    label: 'label.notes',
    type: Type.TEXT,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    multiline: true,
  },
]

const renewalSectionInputs = (intl: IntlShape): InputType[] => [
  {
    key: 'autoRenewal',
    label: 'label.autoRenewal',
    type: Type.CHECKBOX,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
  },
  {
    key: 'frequency',
    label: 'label.frequency',
    type: Type.SINGLE_SELECT,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    options:
      Object.values(RenewalFrequency).map((item) => ({
        value: item,
        label: intl.formatMessage({
          id: `contract.renewalFrequency.${item}`,
          defaultMessage: item,
        }),
      })) || [],
  },
  {
    key: 'noticePeriod',
    label: 'label.noticePeriod',
    type: Type.SINGLE_SELECT,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    options:
      Object.values(CancelationNotice).map((item) => ({
        value: item,
        label: intl.formatMessage({
          id: `contract.noticePeriod.${item}`,
          defaultMessage: item,
        }),
      })) || [],
  },
]

const customerSectionInputs = (): InputType[] => [
  {
    key: 'name',
    label: 'label.name',
    type: Type.TEXT,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
  },
  {
    key: 'number',
    label: 'label.number',
    type: Type.TEXT,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
  },
]
const accountSectionInputs = (): InputType[] => [
  {
    key: 'email',
    label: 'label.email',
    type: Type.TEXT,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
  },
  {
    key: 'username',
    label: 'label.username',
    type: Type.TEXT,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
  },
  {
    key: 'passwordHint',
    label: 'label.passwordHint',
    type: Type.TEXT,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
  },
]

const paymentSectionInputs = (intl: IntlShape): InputType[] => [
  {
    key: 'type',
    label: 'label.type',
    type: Type.SINGLE_SELECT,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    options:
      Object.values(PaymentType).map((item) => ({
        value: item,
        label: intl.formatMessage({
          id: `contract.paymentType.${item}`,
          defaultMessage: item,
        }),
      })) || [],
  },
  {
    key: 'method',
    label: 'label.paymentMethod',
    type: Type.SINGLE_SELECT,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    options:
      Object.values(PaymentMethods).map((item) => ({
        value: item,
        label: intl.formatMessage({
          id: `contract.payment.${item}`,
          defaultMessage: item,
        }),
      })) || [],
  },
  {
    key: 'hasMandate',
    label: 'label.hasMandate',
    type: Type.CHECKBOX,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
  },
  {
    key: 'frequency',
    label: 'label.frequency',
    type: Type.SINGLE_SELECT,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    options:
      Object.values(PaymentFrequency).map((item) => ({
        value: item,
        label: intl.formatMessage({
          id: `contract.paymentFrequency.${item}`,
          defaultMessage: item,
        }),
      })) || [],
  },
  {
    key: 'value',
    unitKey: 'currency',
    label: 'label.value',
    type: Type.PRICE,
    autoComplete: '',
    margin: 'dense',
    fullWidth: true,
    options: currencies,
    validators: [
      {
        method: isPositiveNumber,
        label: 'label.positiveNumberRequired',
      },
    ],
  },
]

const providerInputs = (providers?: ServiceProviderExtended[]): InputType[] => [
  {
    key: 'providerId',
    label: 'label.serviceProvider',
    infoLabel: 'label.inputAutoCreationProvider',
    type: Type.AUTOCOMPLETE,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    freeSolo: true,
    options:
      providers?.map((item) => ({
        value: item.id,
        label: item.title,
      })) || [],
    validators: [
      {
        method: isValidText,
        label: 'label.required',
      },
    ],
  },
]

const eventArchiveFiles = (fileType: FileType): InputType[] => [
  {
    key: 'archive',
    label: 'label.addFiles',
    type: Type.DROPZONE,
    margin: 'dense',
    fullWidth: true,
    validators: [],
    fileType,
  },
]

const generalInitialState = (record: ContractExtended) => ({
  title: record.title || '',
  description: record.description || '',
  contractNumber: record.contractNumber || '',
  startDate: record.startDate ? new Date(record.startDate) : undefined,
  status: record.status || undefined,
})

const cancelationInitialState = (record: ContractExtended) => ({
  link: record?.cancelation?.link || '',
  notes: record?.cancelation?.notes || '',
  date: record?.cancelation?.date
    ? new Date(record?.cancelation?.date)
    : undefined,
})

const renewalInitialState = (record: ContractExtended) => ({
  autoRenewal: record?.renewal?.autoRenewal,
  frequency: record?.renewal?.frequency,
  noticePeriod: record?.renewal?.noticePeriod,
})

const customerInitialState = (record: ContractExtended) => ({
  number: record?.customer?.number,
  name: record?.customer?.name,
})
const accountInitialState = (record: ContractExtended) => ({
  email: record?.account?.email,
  username: record?.account?.username,
  passwordHint: record?.account?.passwordHint,
})
const paymentInitialState = (record: ContractExtended) => ({
  type: record?.payment?.type ?? PaymentType.EXPENSE,
  method: record?.payment?.method,
  hasMandate: record?.payment?.hasMandate,
  frequency: record?.payment?.frequency,
  value: record?.payment?.amount?.value?.toString() ?? '0',
  currency: record?.payment?.amount?.currency ?? defaultCurrency,
})

const providerInitialState = (record: ContractExtended) => ({
  providerId: record?.providerId || '',
})

const eventInitialState = (contract: ContractExtended, eventId?: string) => {
  const record = contract.events?.find((event) => event.id === eventId)
  return {
    providerId: record?.providerId || null,
    date: record?.date ? new Date(record?.date) : null,
    title: record?.title || '',
    description: record?.description || '',
    price: record?.amount?.value?.toString() || null,
    currency: record?.amount?.currency || defaultCurrency,
  }
}

export const getEditInputs = (
  editId: string,
  intl: IntlShape,
  providers?: ServiceProviderExtended[]
) => {
  switch (editId) {
    case Section.general:
      return generalSectionInputs(intl)
    case Section.cancelation:
      return cancelationSectionInputs()
    case Section.renewal:
      return renewalSectionInputs(intl)
    case Section.customer:
      return customerSectionInputs()
    case Section.account:
      return accountSectionInputs()
    case Section.payment:
      return paymentSectionInputs(intl)
    case Section.provider:
      return providerInputs(providers)
    case Section.events:
      return eventsInputs(providers)
    case Section.eventDocuments:
      return eventArchiveFiles(FileType.document)
    default:
      return []
  }
}

export const getExtraInputs = (editId: string) => {
  switch (editId) {
    case Section.events:
      return eventsExtraInputs
    default:
      return []
  }
}

export const getInitialState = (
  record: ContractExtended,
  sectionId: string,
  recordId?: string // id of the sub-entity being edited, e.g. item sales info
): FormDataType => {
  switch (sectionId) {
    case Section.general:
      return generalInitialState(record)
    case Section.cancelation:
      return cancelationInitialState(record)
    case Section.renewal:
      return renewalInitialState(record)
    case Section.customer:
      return customerInitialState(record)
    case Section.account:
      return accountInitialState(record)
    case Section.payment:
      return paymentInitialState(record)
    case Section.provider:
      return providerInitialState(record)
    case Section.events:
      return eventInitialState(record, recordId)
    case Section.eventDocuments:
      return { archive: undefined }
    default:
      return {}
  }
}

export const payloadBuilder = (
  formData: JsonObject,
  recordId: string,
  sectionId?: string
) => {
  let payloadData = formData
  if (sectionId)
    switch (sectionId) {
      case Section.provider:
      case Section.general:
        payloadData = formData
        break

      case Section.payment:
        payloadData = {
          [sectionId]: {
            ...omit(formData ?? {}, ['value', 'currency']),
            ...(formData?.amount?.hasOwnProperty('value')
              ? {
                  amount: {
                    value: Number(formData.value),
                    currency: formData.currency,
                  },
                }
              : {}),
          },
        }
        break

      default:
        payloadData = { [sectionId]: formData } // 1:1 mapping of section to payload
    }

  const payload = {
    id: recordId,
    data: payloadData,
  }
  return payload
}

export function statusColor(status?: ContractStatus) {
  switch (status) {
    case ContractStatus.ACTIVE:
      return 'primary'
    case ContractStatus.CANCELED:
      return 'error'
    case ContractStatus.PAUSED:
      return 'warning'
    case ContractStatus.COMPLETED:
      return 'success'
    default:
      return 'default'
  }
}

export const getCancelationContent = (
  record: ContractExtended,
  intl: IntlShape
) => {
  const data: ContentData[] = []
  const { cancelation } = record

  data.push({
    label: intl.formatMessage({ id: 'label.link' }),
    value: cancelation?.link ?? '-',
    isLink: true,
  })

  data.push({
    label: intl.formatMessage({ id: 'label.date' }),
    value: cancelation?.date ? getDate(cancelation.date) : '-',
  })
  data.push({
    label: intl.formatMessage({ id: 'label.notes' }),
    value: cancelation?.notes ?? '-',
    isLink: true,
  })

  return data
}

export const getRenewalContent = (
  record: ContractExtended,
  intl: IntlShape
) => {
  const data: ContentData[] = []
  const { renewal } = record

  data.push({
    label: intl.formatMessage({ id: 'label.autoRenewal' }),
    value:
      typeof renewal?.autoRenewal === 'boolean'
        ? intl.formatMessage({
            id: `label.${renewal?.autoRenewal ? 'yes' : 'no'}`, // is based on the translation keys
          })
        : '-',
    isLink: true,
  })

  data.push({
    label: intl.formatMessage({ id: 'label.frequency' }),
    value: renewal?.frequency
      ? intl.formatMessage({
          id: `contract.renewalFrequency.${renewal?.frequency}`,
          defaultMessage: renewal?.frequency,
        })
      : '-',
  })
  data.push({
    label: intl.formatMessage({ id: 'label.noticePeriod' }),
    value: renewal?.noticePeriod
      ? intl.formatMessage({
          id: `contract.noticePeriod.${renewal?.noticePeriod}`,
          defaultMessage: renewal?.noticePeriod,
        })
      : '-',
    isLink: true,
  })

  return data
}

export const getCustomerContent = (
  record: ContractExtended,
  intl: IntlShape
) => {
  const data: ContentData[] = []
  const { customer } = record

  data.push({
    label: intl.formatMessage({ id: 'label.name' }),
    value: customer?.name ?? '-',
    isLink: true,
  })

  data.push({
    label: intl.formatMessage({ id: 'label.number' }),
    value: customer?.number ?? '-',
  })

  return data
}
export const getAccountContent = (
  record: ContractExtended,
  intl: IntlShape
) => {
  const data: ContentData[] = []
  const { account } = record

  data.push({
    label: intl.formatMessage({ id: 'label.email' }),
    value: account?.email ?? '-',
    isLink: true,
  })

  data.push({
    label: intl.formatMessage({ id: 'label.username' }),
    value: account?.username ?? '-',
  })

  data.push({
    label: intl.formatMessage({ id: 'label.passwordHint' }),
    value: account?.passwordHint ?? '-',
  })

  return data
}

export const getPaymentContent = (
  record: ContractExtended,
  intl: IntlShape
) => {
  const { payment } = record
  const data: ContentData[] = [
    {
      label: intl.formatMessage({ id: 'label.type' }),
      value: payment?.type
        ? intl.formatMessage({
            id: `contract.paymentType.${payment?.type}`,
            defaultMessage: payment?.type,
          })
        : '-',
    },
    {
      label: intl.formatMessage({ id: 'label.paymentMethod' }),
      value: payment?.method
        ? intl.formatMessage({
            id: `contract.payment.${payment?.method}`,
            defaultMessage: payment?.method,
          })
        : '-',
      isLink: true,
    },
    {
      label: intl.formatMessage({ id: 'label.hasMandate' }),
      value:
        typeof payment?.hasMandate === 'boolean'
          ? intl.formatMessage({
              id: `label.${payment?.hasMandate ? 'yes' : 'no'}`, // is based on the translation keys
            })
          : '-',
      isLink: true,
    },
    {
      label: intl.formatMessage({ id: 'label.frequency' }),
      value: payment?.frequency
        ? intl.formatMessage({
            id: `contract.paymentFrequency.${payment?.frequency}`,
            defaultMessage: payment?.frequency,
          })
        : '-',
    },
    {
      label: intl.formatMessage({ id: 'label.amount' }),
      value: getMonetaryValue(payment?.amount),
    },
  ]

  return data
}

interface Methods {
  toggleItemSelect: () => void
  toggleNestSelect: () => void
  addEvent: () => void
  createMemberRef: (memberId: string, memberModel: MemberModels) => void
  deleteMemberRef: (refId: string) => void
}
export const relationsContent = (
  intl: IntlShape,
  record: ContractExtended,
  members: UserType[],
  methods: Methods
) => [
  {
    title: intl.formatMessage({ id: 'label.items' }),
    description: record?.items
      ? intl.formatMessage(
          { id: 'label.relatedItemsCount' },
          { number: record?.items.length }
        )
      : '',
    action: {
      ariaLabel: 'related items',
      icon: () => <AddIcon color="disabled" />,
      onPress: methods.toggleItemSelect,
    },
  },
  {
    title: intl.formatMessage({ id: 'label.nests' }),
    description: record?.nests
      ? intl.formatMessage(
          { id: 'label.relatedNestsCount' },
          { number: record?.nests.length }
        )
      : '',
    action: {
      ariaLabel: 'related nests',
      icon: () => <AddIcon color="disabled" />,
      onPress: methods.toggleNestSelect,
    },
  },
  {
    title: intl.formatMessage({ id: 'label.events' }),
    description: intl.formatMessage(
      { id: 'label.eventsCount' },
      { number: record?.events?.length ?? 0 }
    ),
    action: {
      ariaLabel: 'related events',
      icon: () => <AddIcon color="disabled" />,
      onPress: methods.addEvent,
    },
  },
  {
    title: intl.formatMessage({ id: 'label.members' }),
    component: (
      <UserAssign
        users={record?.members ?? []}
        members={members}
        createMemberRef={methods.createMemberRef}
        deleteMemberRef={methods.deleteMemberRef}
      />
    ),
  },
]
