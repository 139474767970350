import { gql } from '@apollo/client'

export const WORKSPACE_INVENTORY = gql`
  query WORKSPACE_INVENTORY($payload: InventoryInput!) {
    workspaceInventory(payload: $payload) {
      items {
        total
        list {
          id
          title
          description
        }
      }
      groups {
        total
        list {
          id
          title
          description
          workspaceId
          createdAt
          updatedAt
          itemCount
          coverImage {
            full
            medium
            thumbnail
          }
        }
      }
    }
  }
`
