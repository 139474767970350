import * as React from 'react'
import { useTheme } from '@mui/material/styles'
import { useNavigate, useLocation } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'
import { useIntl } from 'react-intl'
import useMediaQuery from '@mui/material/useMediaQuery'
import ROUTES from 'lib/routes'
import { Workspace } from 'types/workspace'
import { clearSession, getLanguage } from 'lib/localStorage'
import Toolbar from '@mui/material/Toolbar'
import { getActiveWorkspaceId, saveActiveWorkspaceId } from 'lib/sessionStorage'
import {
  ListSection,
  List,
  UL,
  TextItem,
  ListIconWrapper,
  ListSubheader,
  ListItemButton,
} from './fragments'
import { getMenuItems } from './constants'
import AccountEntry from './AccountEntry'
import WorkspaceSelection from './WorkspaceSelection'

interface Props {
  workspaces: Workspace[] | null
  isMobile: boolean
  toggleDrawer?: () => void
}
function DrawerContent(props: Props) {
  const { workspaces, isMobile, toggleDrawer } = props
  const intl = useIntl()
  const navigate = useNavigate()
  const location = useLocation()
  const client = useApolloClient()
  const language = getLanguage()

  const [selectedKey, setSelectedKey] = React.useState<string>()
  const workspaceId = getActiveWorkspaceId()
  const theme = useTheme()
  const focusedWorkspace = workspaceId
    ? workspaces?.find((workspace) => workspace.id === workspaceId) ?? null
    : null
  const isBigScreen = useMediaQuery(theme.breakpoints.up('md'))
  const items = React.useMemo(
    () => getMenuItems(intl, focusedWorkspace, isMobile),
    // eslint-disable-next-line
    [focusedWorkspace, isMobile, language]
  )
  // const onMouseEnter = () => setCollapsibleOpen(true)
  // const onMouseLeave = () => setCollapsibleOpen(false)
  // const toCreateWorkspace = () => {
  //   saveActiveWorkspaceId(null) // remove selected workspace
  // }
  // const onSelectWorkspace = (workspace: Workspace) => {
  //   saveActiveWorkspaceId(workspace.id)
  //   history.replace(`${ROUTES.DASHBOARD}/${workspace.id}`)
  // }
  const handleSignOut = () => {
    clearSession()
    saveActiveWorkspaceId(null)
    client.clearStore()
    navigate(ROUTES.INDEX, { replace: true })
  }

  return (
    <List subheader={<li />}>
      {isBigScreen && <Toolbar />}
      {items.map((item, index) => (
        <ListSection
          key={`section-${item.section}`}
          sectionIndex={index}
          isMobile={isMobile}
        >
          <UL>
            <ListSubheader>{item.section}</ListSubheader>
            {item.entries.map((entry, entryIndex) => {
              if (!entry) return null
              const { key, icon: Icon } = entry
              const isSubItem = !!entry.parent // has parent key -> is a sub item

              // NOTE: inventory sub menus hidden functionality
              // if (
              //   entry.parent &&
              //   !location.pathname.includes(entry.parent) // parents pathname must match the key
              // ) {
              //   return null
              // }

              const handleOnClick = () => {
                setSelectedKey(key)
                if (entry.route === 'logout') {
                  handleSignOut()
                } else if (entry.route) {
                  navigate(entry.route)
                }
                // hide drawer for mobile view
                toggleDrawer?.()
              }

              if (key === 'workspace-selection')
                return (
                  <WorkspaceSelection
                    key={`${item.section}-${key}`}
                    toggleDrawer={toggleDrawer}
                    withSpacing
                  />
                )

              const selected = selectedKey
                ? selectedKey === key
                : location.pathname === entry.route //  default try to determine the active route.

              return (
                <ListItemButton
                  selected={selected}
                  key={`${item.section}-${key}`}
                  onClick={handleOnClick}
                  sx={{
                    pl: isSubItem ? 4 : 2, // added for subitems
                  }}
                >
                  {key === 'account' ? (
                    <AccountEntry />
                  ) : (
                    <>
                      <ListIconWrapper selected={selected}>
                        {/* @ts-expect-error icon type */}
                        <Icon width="26px" />
                      </ListIconWrapper>
                      <TextItem primary={entry.title} selected={selected} />
                    </>
                  )}
                </ListItemButton>
              )
            })}
          </UL>
        </ListSection>
      ))}
    </List>
  )
}

DrawerContent.defaultProps = {
  toggleDrawer: null,
}
export default DrawerContent
