import React, { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import AddIcon from '@mui/icons-material/AddSharp'
import { FileType } from 'types/archive'
import { uploadMedia } from 'lib/api'
import { useIntl } from 'react-intl'
import { Severity, withSnackbar } from 'components/providers/SnackbarHOC'
import { Button, Container, Content } from 'components/shared'
import LoadingIndicator from 'components/LoadingIndicator'
import { FormDataType } from 'components/input/types'
import { FileEntry, JsonObject, WorkspaceAccessRoles } from 'types/shared'
import FormModal from 'components/recordDetails/FormModal'
import { SuggestionSection } from 'components/recordDetails/SuggestionsSection'
import { WorkspaceMember } from 'types/user'
import { H1 } from 'components/typography'
import {
  ConfirmationData,
  withConfirmationModal,
} from 'components/hoc/ConfirmationModalHoc'
import { MemberCard, OrganizationMemberCard } from 'components/user'
import {
  Section,
  getEditInputs,
  getExtraInputs,
  getInitialState,
  getSectionWithId,
  payloadBuilder,
} from './constants'
import { useData, useMutations } from './hooks'

interface ContentRecords {
  members: WorkspaceMember[]
  contacts: WorkspaceMember[]
}
interface Props {
  showSnackbar?: (message: string, severity: Severity) => void
  requestConfirmation?: (data: ConfirmationData) => void
}

function WorkspaceMembers(props: Props) {
  const intl = useIntl()
  const { showSnackbar, requestConfirmation } = props
  const params = useParams() as { workspaceId: string }
  const [editingId, setEditingId] = useState<string>()
  const [editing, setEditing] = useState<SuggestionSection>()
  const hideEditingModal = useCallback(() => setEditing(undefined), [])

  const {
    loading,
    data: { members, owners, canEdit },
  } = useData(params.workspaceId)

  const {
    loading: mutating,
    mutations: { addWorkspaceMember, updateWorkspaceMember, deleteMember },
  } = useMutations(
    { workspaceId: params.workspaceId },
    {
      showSnackbar,
      hideEditingModal,
    },
    intl
  )

  const handleOnEditWithId = (sectionId: Section, recordId?: string) => {
    const section = getSectionWithId(sectionId, intl)
    setEditingId(recordId)
    setEditing(section)
  }

  const handleRecordUpdate = (formData: JsonObject, sectionId?: string) => {
    if (editingId) {
      // edit record
      const payload = payloadBuilder(formData, editingId, sectionId)
      updateWorkspaceMember({ variables: { payload } })
    } else {
      // create new record
      const payload = {
        email: formData.email as string,
        firstName: formData.firstName as string,
        lastName: formData.lastName as string,
        role: formData.isWorkspaceMember
          ? (formData.role as WorkspaceAccessRoles)
          : WorkspaceAccessRoles.contact,
        workspaceId: params.workspaceId,
      }
      addWorkspaceMember({ variables: { payload } })
    }
  }

  const uploadAvatar = async (avatar: FileEntry): Promise<string> => {
    const result = await uploadMedia(avatar.file, { type: FileType.avatar })
      .then((response) => response.json())
      .catch((error) => showSnackbar?.(error.message, Severity.ERROR))

    // eslint-disable-next-line no-underscore-dangle
    const archiveId = result?.data?._id
    return archiveId
  }
  const handleOnUpdate = (
    formData: JsonObject,
    sectionId?: string,
    files?: FileEntry[]
  ) => {
    const dropzoneKey =
      inputs.find((input) => input.type === 'dropzone')?.key ?? ''
    // ws cover
    const cover = files?.[0]
    if (cover) {
      uploadAvatar(cover).then((archiveId) =>
        handleRecordUpdate({ ...formData, [dropzoneKey]: archiveId }, sectionId)
      )
    } else {
      handleRecordUpdate(formData, sectionId)
    }
  }
  const handleRemove = (membershipId: string) => {
    if (!membershipId) return
    const payload = { membershipId }
    deleteMember({ variables: { payload } })
  }
  const toggleDelete = (membershipId: string) =>
    requestConfirmation?.({
      title: intl.formatMessage({ id: 'label.deletingMemberTitle' }),
      description: intl.formatMessage({
        id: 'label.deletingMemberDescription',
      }),
      actions: [
        {
          label: intl.formatMessage({ id: 'label.cancel' }),
        },
        {
          label: intl.formatMessage({ id: 'label.delete' }),
          color: 'error',
          onClick: () => handleRemove(membershipId),
        },
      ],
    })

  const content = members.reduce(
    (acc: ContentRecords, record: WorkspaceMember) => {
      if (record.role === WorkspaceAccessRoles.contact)
        return { ...acc, contacts: [...acc.contacts, record] }
      return { ...acc, members: [...acc.members, record] }
    },
    { contacts: [], members: [] }
  )

  const isLoading = loading || mutating
  const focusedMember = members.find((i) => i.id === editingId)
  // @ts-expect-error
  const initialState: Partial<WorkspaceMember> = editing
    ? getInitialState(focusedMember, editing.id)
    : {}
  const inputs =
    getEditInputs(intl, editing?.id ?? '', !editingId, focusedMember) ?? []
  const extraInputs = getExtraInputs(editing?.id ?? '')

  return (
    <Container>
      <LoadingIndicator visible={isLoading} />
      <Content>
        <Grid container mb={4} alignItems="center">
          <Grid item xs={12} mb={2}>
            <H1>{intl.formatMessage({ id: 'label.accountOwners' })}</H1>
          </Grid>
        </Grid>
        <Grid container alignItems="column" spacing={2} mb={4}>
          {owners.map((member) => (
            <Grid item key={member.id} xs={12} sm={6} md={4}>
              <OrganizationMemberCard member={member} />
            </Grid>
          ))}
        </Grid>
        <Grid container mb={4} alignItems="center">
          <Grid item xs={6} mb={2}>
            <H1>{intl.formatMessage({ id: 'label.members' })}</H1>
          </Grid>
          {canEdit && (
            <Grid item xs={6} md={12} justifyContent="flex-end">
              <Button onClick={() => handleOnEditWithId(Section.general)}>
                <AddIcon color="secondary" />
                {intl.formatMessage({ id: 'label.addMemberContact' })}
              </Button>
            </Grid>
          )}
        </Grid>
        <Grid container alignItems="column" spacing={2} mb={4}>
          {content.members.map((member) => (
            <Grid item key={member.id} xs={12} sm={6} md={4}>
              <MemberCard
                canEdit={canEdit}
                member={member}
                actions={{
                  onEdit: () => handleOnEditWithId(Section.general, member.id),
                  onDelete: () => toggleDelete(member.id),
                }}
                onAvatarChange={() =>
                  handleOnEditWithId(Section.avatar, member.id)
                }
              />
            </Grid>
          ))}
        </Grid>
        {content.contacts.length > 0 && (
          <Grid container mb={4} alignItems="center">
            <Grid item xs={12} mb={2}>
              <H1>{intl.formatMessage({ id: 'label.contacts' })}</H1>
            </Grid>
            <Grid container alignItems="column" spacing={2} mb={4}>
              {content.contacts.map((member) => (
                <Grid item key={member.id} xs={12} sm={6} md={4}>
                  <MemberCard
                    canEdit={canEdit}
                    member={member}
                    actions={{
                      onEdit: () =>
                        handleOnEditWithId(Section.general, member.id),
                      onDelete: () => toggleDelete(member.id),
                    }}
                    onAvatarChange={() =>
                      handleOnEditWithId(Section.avatar, member.id)
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
      </Content>
      <FormModal
        toggleModal={hideEditingModal}
        inputs={inputs}
        extraInputs={extraInputs}
        section={editing}
        initialState={initialState as FormDataType}
        onSubmit={handleOnUpdate}
        singleFileSelect
      />
    </Container>
  )
}
WorkspaceMembers.defaultProps = {
  showSnackbar: undefined,
  requestConfirmation: undefined,
}

export default withConfirmationModal(withSnackbar(WorkspaceMembers))
