import React, { useState, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useQuery } from '@apollo/client'
import { WORKSPACE_ITEMS } from 'gql/item'
import Masonry from '@mui/lab/Masonry'
import { min, max, uniqBy } from 'lodash'
import { Severity, withSnackbar } from 'components/providers/SnackbarHOC'
import { ItemExtended, WorkspaceItemsData } from 'types/item'
import ROUTES from 'lib/routes'
import LoadingIndicator from 'components/LoadingIndicator'
import { Container, Content, GridWrapper } from 'components/shared'
import ListingActions from 'components/pages/ListingActions'
import { DataGridComp } from 'components/table/datagrid'
import ItemCard from 'components/ItemCard'
import { useScrollPercentage } from 'utils/hooks'
import { GridRowId } from '@mui/x-data-grid'
import { JsonObject } from 'types/shared'
import GridSelectionModal from 'components/GridSelectionModal'
import FormModal from 'components/recordDetails/FormModal'
import ListingHeadline from 'components/pages/ListingHeadline'
import { SuggestionSection } from 'components/recordDetails/SuggestionsSection'
import {
  ConfirmationData,
  withConfirmationModal,
} from 'components/hoc/ConfirmationModalHoc'
import EmptyList from 'components/EmptyList'
import { Grid } from '@mui/material'
import { columns } from './fragments'
import {
  getInitialState,
  getEditInputs,
  // getSectionWithId,
  Section,
  getExtraInputs,
} from './constants'
import { useMutations, useData } from './hooks'

interface Props {
  showSnackbar?: (message: string, severity: Severity) => void
  requestConfirmation?: (data: ConfirmationData) => void
}
function Items({ showSnackbar, requestConfirmation }: Props) {
  const intl = useIntl()
  const params = useParams() as { workspaceId: string }
  const [scrollPercent] = useScrollPercentage()
  const [editing, setEditing] = useState<SuggestionSection>()
  const hideEditingModal = useCallback(() => setEditing(undefined), [])
  const [page, setPage] = React.useState(0)
  const [pageSize, setPageSize] = React.useState(25)
  const [filters, setFilters] = React.useState<{ search: string }>()
  const [items, setItems] = React.useState<ItemExtended[]>([])
  const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([])
  const [total, setTotal] = React.useState(0)
  const [mode, setMode] = React.useState<'grid' | 'list'>('grid')
  const payload = {
    workspaceId: params.workspaceId,
    limit: pageSize,
    page,
    filters,
  }
  const { loading: recordsLoading } = useQuery<WorkspaceItemsData>(
    WORKSPACE_ITEMS,
    {
      variables: { payload },
      onCompleted: ({ workspaceItems }) => {
        mode === 'grid' && total === workspaceItems.total
          ? setItems(uniqBy([...items, ...workspaceItems.list], 'id'))
          : setItems(workspaceItems.list)
        setTotal(workspaceItems.total)
      },
      onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    }
  )

  const {
    data: { orgWorkspaces },
    loading,
  } = useData(params.workspaceId)
  const {
    loading: mutating,
    mutations: { deleteItems, updateItem, transferItems },
  } = useMutations(payload, {
    showSnackbar,
    hideEditingModal,
  })

  const onPageChange = useCallback((_page: number) => setPage(_page), [setPage])
  const onPageSizeChange = useCallback(
    (_pageSize: number) => setPageSize(_pageSize),
    [setPageSize]
  )

  React.useEffect(() => {
    if (
      mode === 'grid' &&
      scrollPercent > 90 &&
      !recordsLoading &&
      items.length < total
    ) {
      setPage(page + 1)
    }
  }, [scrollPercent])

  const toGridView = () => {
    if (mode === 'grid') return
    setMode('grid')
    if (page > 0) {
      setItems([])
      setPage(0)
    }
  }
  const toListView = () => {
    if (mode === 'list') return
    setMode('list')
    if (page > 0) {
      setItems([])
      setPage(0)
    }
  }

  const selectAllRecords = () => setSelectionModel(items.map((item) => item.id))
  const clearSelection = () => setSelectionModel([])
  const handleDelete = () =>
    requestConfirmation?.({
      title: intl.formatMessage({ id: 'label.deletingItems' }),
      description: intl.formatMessage({
        id: 'label.deletingItemConfirmation',
      }),
      actions: [
        {
          label: intl.formatMessage({ id: 'label.cancel' }),
        },
        {
          label: intl.formatMessage({ id: 'label.delete' }),
          color: 'error',
          onClick: () =>
            deleteItems({ variables: { itemIds: selectionModel as string[] } }),
        },
      ],
    })

  // const handleOnEditWithId = (sectionId: string, recordId?: string) => {
  //   const section = getSectionWithId(sectionId, intl)
  //   setEditing(section)
  // }

  const handleOnUpdate = (formData: JsonObject, sectionId?: string) => {
    if (sectionId === Section.transferRecords) {
      const transferPayload = {
        itemIds: selectionModel as string[],
        workspaceId: formData.workspaceId as string,
      }
      transferItems({ variables: { payload: transferPayload } })
    }
  }

  const handleOnSearch = (text: string) => {
    setFilters({ ...filters, search: text })
    setPage(0) // reset the pagination page
  }
  const handleClearFilters = () => {
    setFilters(undefined)
    setPage(0) // reset the pagination page
  }
  const isLoading = mutating || loading || recordsLoading
  const selectedAll = selectionModel.length === items.length
  const inputs = getEditInputs(editing?.id ?? '', orgWorkspaces)
  const extraInputs = getExtraInputs(editing?.id ?? '')
  const initialState = editing ? getInitialState(editing.id) : {}

  const renderActions = () => (
    <ListingActions
      mode={mode}
      toListView={toListView}
      toGridView={toGridView}
      onSearch={handleOnSearch}
      onResetSearch={handleClearFilters}
    />
  )
  return (
    <Container>
      <LoadingIndicator visible={isLoading} />
      <Content>
        <ListingHeadline
          title="label.items"
          total={total}
          renderActions={renderActions}
          cta={{
            label: 'label.createNewItem',
            route: ROUTES.DASHBOARD_CREATE_ITEM_ROUTE(params.workspaceId),
          }}
        />
        <div style={{ flex: 1, display: 'flex' }}>
          {mode === 'list' ? (
            <GridWrapper>
              <DataGridComp
                initialState={{ pagination: { page, pageSize } }}
                onPageChange={onPageChange}
                onPageSizeChange={onPageSizeChange}
                rows={items}
                columns={columns(intl, updateItem)}
                checkboxSelection
                disableSelectionOnClick
                rowsPerPageOptions={[25, 50, 100]}
                rowCount={total || 0}
                experimentalFeatures={{ newEditingApi: true }}
                paginationMode="server"
                onSelectionModelChange={setSelectionModel}
                selectionModel={selectionModel}
              />
              {selectionModel.length > 0 && (
                <GridSelectionModal
                  selectedRows={selectionModel}
                  actions={[
                    {
                      label: selectedAll
                        ? 'label.clearSelection'
                        : 'label.selectAll',
                      onClick: selectedAll ? clearSelection : selectAllRecords,
                      variant: 'outlined',
                    },
                    {
                      label: 'label.delete',
                      onClick: handleDelete,
                      color: 'error',
                    },
                    // {
                    //   label: 'label.transfer',
                    //   onClick: () =>
                    //     handleOnEditWithId(Section.transferRecords),
                    //   color: 'error',
                    // },
                  ]}
                />
              )}
            </GridWrapper>
          ) : (
            <React.Fragment>
              {!isLoading && items.length === 0 && (
                <Grid container spacing={2} marginTop={0.1}>
                  <Grid item xs={12}>
                    <EmptyList />
                  </Grid>
                </Grid>
              )}
              <GridWrapper>
                <Masonry
                  spacing={2}
                  columns={{
                    xs: 2,
                    sm: min([3, max([items?.length, 2])]) as number,
                    lg: min([4, max([items?.length, 2])]) as number,
                    xl: min([5, max([items?.length, 2])]) as number,
                  }}
                >
                  {items.map((item) => (
                    <ItemCard item={item} key={item.id} />
                  ))}
                </Masonry>
              </GridWrapper>
            </React.Fragment>
          )}
        </div>
      </Content>
      <FormModal
        toggleModal={hideEditingModal}
        inputs={inputs}
        extraInputs={extraInputs}
        section={editing}
        initialState={initialState}
        onSubmit={handleOnUpdate}
      />
    </Container>
  )
}

Items.defaultProps = {
  showSnackbar: undefined,
  requestConfirmation: undefined,
}
export default withConfirmationModal(withSnackbar(Items))
