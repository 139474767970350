import React from 'react'
import { useTheme } from '@mui/material/styles'
import { useNavigate, useParams } from 'react-router-dom'
import Card from '@mui/material/Card'
import { ItemExtended } from 'types/item'
import { styled } from '@mui/material/styles'
import ROUTES from 'lib/routes'
import { FileType } from 'types/archive'
import appConfig from 'config/app'
import { useQuery } from '@apollo/client'
import { ITEM_GROUPS } from 'gql/group'
import { ItemGroupsData } from 'types/group'
// import { currencies } from 'lib/constants'
import GroupsIcon from '@mui/icons-material/CategoryOutlined'
import { cardBoxShadow, cardBoxShadowHover } from 'assets/styles/theme'
import { Row, RowSpaced } from './shared'
import {
  CardTitle,
  // PinIcon, PinOutlinedIcon, StatusChip
} from './cards'
import { BodyTextLight, CaptionText } from './typography'
import UserBadge from './user/memberSelect/UserBadge'

const ContainerCard = styled(Card)({
  padding: '12px',
  gap: '8px',
  borderRadius: '4px',
  boxShadow: cardBoxShadow,
  ':hover': {
    cursor: 'pointer',
    boxShadow: cardBoxShadowHover,
  },
})

const Content = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
})

const Image = styled('img')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '130px',
  objectFit: 'cover',
  marginBottom: '8px',
  borderRadius: '3px',
}))

const CardSubtitle = styled(BodyTextLight)(({ theme }) => ({
  color: theme.palette.text.secondary,
  lineHeight: '150%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  lineClamp: 2,
  WebkitBoxOrient: 'vertical',
  marginTop: '4px',
}))

// const PriceText = styled('span')(({ theme }) => ({
//   color: theme.palette.text.secondary,
//   fontFamily: 'Open Sans',
//   fontSize: '12px',
//   lineHeight: '150%',
//   fontWeight: 600,
//   marginTop: '4px',
// }))

const ChipWrapper = styled(Row)(({ theme }) => ({
  padding: '2px 5px',
  gap: '4px',
  borderRadius: '4px',
  backgroundColor: theme.palette.shared.lightGray,
}))

const GroupIcon = styled(GroupsIcon)(({ theme }) => ({
  width: '20px',
  height: '20px',
  color: theme.palette.text.secondary,
}))

function GroupChip({ label, hideIcon }: { label: string; hideIcon?: boolean }) {
  const theme = useTheme()
  const wrapperStyle = hideIcon ? { paddingTop: '4px', marginLeft: '4px' } : {}
  return (
    <ChipWrapper style={wrapperStyle}>
      {!hideIcon && <GroupIcon />}
      <CaptionText color={theme.palette.text.secondary}>{label}</CaptionText>
    </ChipWrapper>
  )
}
GroupChip.defaultProps = {
  hideIcon: false,
}

interface Props {
  item: ItemExtended
  // onPress optional callback to handle click, otherwise redirect to item details
  onPress?: (item: ItemExtended) => void
}
function ItemCard({ item, onPress }: Props) {
  const navigate = useNavigate()
  // item cards must always be shown under the workspace route
  const params = useParams() as { workspaceId: string }
  const { data } = useQuery<ItemGroupsData>(ITEM_GROUPS, {
    variables: { itemId: item.id },
  })
  const groups = data?.itemGroups.list ?? []
  // const [pinned, setPinned] = React.useState(false)
  const handleClick = () =>
    typeof onPress === 'function'
      ? onPress(item)
      : navigate(
          ROUTES.DASHBOARD_ITEM_DETAILS_ROUTE(params.workspaceId, item.id)
        )

  const images = item.archive?.filter((i) => i.type === FileType.image)
  const coverMedia =
    images?.find((rec) => rec.id === item.coverId) ?? images?.[0]
  const cover = coverMedia?.media?.thumbnail
    ? `${appConfig.media}${coverMedia?.media?.thumbnail}`
    : null

  return (
    <ContainerCard onClick={handleClick}>
      {cover && <Image src={cover} alt={item.title} />}
      <Content>
        <CardTitle>{item.title}</CardTitle>
        <CardSubtitle>{item.description}</CardSubtitle>

        <RowSpaced sx={{ marginTop: '8px' }}>
          <Row>
            {groups?.length > 0 && <GroupChip label={groups[0].title} />}
            {groups?.length > 1 && (
              <GroupChip hideIcon label={`+${groups.length - 1}`} />
            )}
          </Row>

          <Row>
            {item.members?.map((member) => (
              <UserBadge
                key={member.id}
                user={member}
                size={24}
                navigate={navigate}
              />
            ))}
          </Row>
        </RowSpaced>
      </Content>
    </ContainerCard>
  )
}

ItemCard.defaultProps = {
  onPress: undefined,
}

export default ItemCard
