import React from 'react'
import { useIntl } from 'react-intl'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import { H1, H6 } from 'components/typography'
import Pencil from 'assets/svg/Pencil'
import CameraIcon from '@mui/icons-material/CameraAlt'
import { Column, Row, RowSpaced } from 'components/shared'
import {
  OrganizationMemberDetails,
  WorkspaceMemberDetails,
  isOrganizationMember,
} from 'types/user'
import {
  getAvatar,
  getFullName,
  getMemberEmail,
} from 'components/user/memberSelect/utils'
import { StatusChip } from 'components/cards'
import { Section } from './constants'

const Avatar = styled('img')(({ theme }) => ({
  height: '120px',
  width: '120px',
  overflow: 'hidden',
  borderRadius: '60px',
  objectFit: 'cover',
  border: `2px solid ${theme.palette.primary.main}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
}))

const AvatarWrapper = styled('div')(({ theme }) => ({
  ':hover svg': {
    visibility: 'visible',
  },
}))
const PhotoChange = styled(CameraIcon)(({ theme }) => ({
  position: 'absolute',
  color: theme.palette.primary.main,
  top: 45,
  left: 45,
  opacity: 0.8,
  visibility: 'hidden',
}))

const Container = styled(Row)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: '18px',
  borderRadius: '5px',
  marginBottom: '16px',
}))

interface Props {
  record: OrganizationMemberDetails | WorkspaceMemberDetails
  onEditSection: (sectionId: Section) => void
}
function Headline({ record, onEditSection }: Props) {
  const intl = useIntl()
  const editable = !isOrganizationMember(record) && record?.canEdit
  const onEdit = () => onEditSection(Section.general)
  const onAvatarChange = () => (editable ? onEditSection(Section.avatar) : null)

  const avatar = getAvatar(record)
  const email = getMemberEmail(record)
  return (
    <Container>
      <Grid container mb={4} spacing={1}>
        <Grid item md={4}>
          <Column style={{ position: 'relative' }}>
            <AvatarWrapper onClick={onAvatarChange}>
              <Avatar src={avatar} />
              {editable && <PhotoChange fontSize="large" />}
            </AvatarWrapper>
          </Column>
        </Grid>
        <Grid item md={8}>
          <RowSpaced>
            {record.role && (
              <StatusChip
                color="primary"
                label={intl.formatMessage({
                  id: `role.${record.role}`,
                  defaultMessage: record.role,
                })}
              />
            )}
            {editable && (
              <IconButton onClick={onEdit}>
                <Pencil />
              </IconButton>
            )}
          </RowSpaced>
          <H1>{getFullName(record)}</H1>
          {email && <H6>{email}</H6>}
        </Grid>
      </Grid>
    </Container>
  )
}

export default Headline
