import { gql } from '@apollo/client'

export const ROTATE_IMAGE = gql`
  mutation ROTATE_IMAGE($payload: ImageRotateInput!) {
    rotateImage(payload: $payload) {
      status
    }
  }
`

export const DELETE_ARCHIVE = gql`
  mutation DELETE_ARCHIVE($archiveId: ID!) {
    deleteArchive(archiveId: $archiveId) {
      status
    }
  }
`
