import { useNavigate } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import {
  CREATE_SERVICE_PROVIDER,
  SERVICE_PROVIDERS_LIST,
  SERVICE_PROVIDER_CATEGORIES,
} from 'gql/serviceProvider'
import {
  CreateServiceProviderData,
  CreateServiceProviderInput,
  ServiceProviderCategoriesData,
  ServiceProvidersListData,
} from 'types/serviceProvider'
import ROUTES from 'lib/routes'
import { Severity } from 'components/providers/SnackbarHOC'

export function useData(workspaceId: string) {
  const { loading: providersLoading, data: serviceProviderList } =
    useQuery<ServiceProvidersListData>(SERVICE_PROVIDERS_LIST, {
      variables: { payload: { workspaceId } },
    })

  const { data: categories, loading: categoriesLoading } =
    useQuery<ServiceProviderCategoriesData>(SERVICE_PROVIDER_CATEGORIES)

  const loading = providersLoading || categoriesLoading
  const data = {
    serviceProviders: serviceProviderList?.serviceProviders,
    categories: categories?.providerCategories ?? [],
  }

  return { loading, data }
}

export function useMutations(
  workspaceId: string,
  methods: {
    showSnackbar?: (message: string, severity: Severity) => void
  }
) {
  const navigate = useNavigate()
  const { showSnackbar } = methods

  const [createServiceProvider, { loading: createLoading }] = useMutation<
    CreateServiceProviderData,
    CreateServiceProviderInput
  >(CREATE_SERVICE_PROVIDER, {
    onCompleted: (response) =>
      navigate(
        ROUTES.DASHBOARD_SERVICE_PROVIDER_DETAILS_ROUTE(
          workspaceId,
          response.createServiceProvider.id
        ),
        { replace: true }
      ),
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: [
      {
        query: SERVICE_PROVIDERS_LIST,
        variables: { payload: { workspaceId } },
      },
    ],
  })
  const loading = createLoading

  return {
    loading,
    mutations: {
      createServiceProvider,
    },
  }
}
