import React from 'react'
import { Row } from 'components/shared'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { MemberModels } from 'types/shared'
import { Autocomplete, Collapse } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import UserBadge, { UserType } from './UserBadge'
import Avatar from '../Avatar'
import { getAvatar, getFullName, getMemberModel, getPlaceholder } from './utils'
import {
  AutocompleteTextField,
  IconButton,
  OptionLabel,
  OptionWrapper,
} from './fragments'

interface Props {
  // linked user to the entity
  users: UserType[]
  // workspace members
  members: UserType[]
  createMemberRef: (memberId: string, memberModel: MemberModels) => void
  deleteMemberRef: (refId: string) => void
}

interface Option {
  label: string
  avatar?: string
  id?: string
  placeholder: string
}

function UserAssign({
  users,
  members,
  createMemberRef,
  deleteMemberRef,
}: Props) {
  const navigate = useNavigate()
  const intl = useIntl()
  const inputRef = React.useRef<HTMLInputElement>()
  const containerRef = React.useRef(null)
  const [adding, setAdding] = React.useState(false)
  const toggleMemberSelect = () => {
    // next iteration is open
    adding === false && inputRef?.current?.focus()
    setAdding(!adding)
  }
  const Icon = adding ? CloseIcon : AddIcon
  const options: Option[] = members
    .filter((member) => !users.find((i) => i.id === member.id))
    .map((member) => ({
      label: getFullName(member),
      avatar: getAvatar(member),
      placeholder: getPlaceholder(member),
      id: member.id,
    }))

  const handleOnAutocompleteChange = (
    _: React.SyntheticEvent,
    value: Option | null
  ) => {
    const user = members.find((u) => u.id === value?.id)
    if (!value?.id || !user) return
    const memberModel = getMemberModel(user)
    createMemberRef(value.id, memberModel)
    toggleMemberSelect()
  }

  const renderUser = (user: UserType) => (
    <UserBadge
      key={user.id}
      deleteMemberRef={deleteMemberRef}
      user={user}
      navigate={navigate}
    />
  )
  return (
    <Row ref={containerRef}>
      {users?.map(renderUser)}

      <Collapse orientation="horizontal" in={adding} collapsedSize={0}>
        <Autocomplete
          options={options}
          openOnFocus
          onChange={handleOnAutocompleteChange}
          renderOption={(props, option) => (
            <OptionWrapper {...props}>
              <Avatar src={option.avatar} placeholder={option.placeholder} />
              <OptionLabel>{option.label}</OptionLabel>
            </OptionWrapper>
          )}
          renderInput={(params) => (
            <AutocompleteTextField
              {...params}
              inputRef={inputRef}
              label={intl.formatMessage({ id: 'label.members' })}
            />
          )}
        />
      </Collapse>
      <IconButton
        disableSpacing={users.length === 0 && !adding}
        size="small"
        onClick={toggleMemberSelect}
      >
        <Icon color="disabled" />
      </IconButton>
    </Row>
  )
}

export default UserAssign
