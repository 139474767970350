import React, { useEffect, useState } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { styled, useTheme } from '@mui/material/styles'
import {
  Button,
  ModalTitle,
  ModalTransition,
  RowSpaced,
} from 'components/shared'
import Dialog from '@mui/material/Dialog'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import DialogContent from '@mui/material/DialogContent'
import { FileEntry, JsonObject } from 'types/shared'
import { useIntl } from 'react-intl'
import InputRender, { formHasErrors } from 'components/input/InputRender'
import { FormDataType, InputType } from 'components/input/types'
import { SuggestionSection } from 'components/recordDetails/SuggestionsSection'
import { isEqual } from 'lodash'
import { Severity, withSnackbar } from 'components/providers/SnackbarHOC'

interface UploadProps {
  toggleModal: () => void
  inputs: InputType[]
  extraInputs?: InputType[]
  section?: SuggestionSection
  initialState: FormDataType
  singleFileSelect?: boolean /* used to select a single file from the dropzone inputs */
  showSnackbar?: (message: string, severity: Severity) => void
  onSubmit: (
    formData: JsonObject,
    sectionId?: string,
    files?: FileEntry[] // files to upload (if any). Use only one dropzone input per form.
  ) => void
}
const Form = styled('form')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  minHeight: '60vh',
  marginTop: '20px',
  [theme.breakpoints.up('sm')]: {
    width: '400px',
  },
}))

const ButtonWrapper = styled('div')(({ theme }) => ({
  marginTop: '20px',
}))

const ActionLink = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
  cursor: 'pointer',
  fontSize: '13px',
  fontFamily: 'NoirPro',
  margin: '20px 0',
  alignSelf: 'flex-end',
}))

function FormModal({
  inputs,
  extraInputs,
  section,
  initialState,
  toggleModal,
  onSubmit,
  singleFileSelect,
  showSnackbar,
}: UploadProps) {
  const intl = useIntl()
  const theme = useTheme()
  const hasExtraInputs = extraInputs && extraInputs.length > 0
  const hasExtraData = hasExtraInputs
    ? extraInputs?.some(
        (input) => initialState[input.key] && initialState[input.key] !== ''
      )
    : false
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [showErrors, setShowErrors] = useState(false)
  const [data, setData] = useState<FormDataType>(initialState)
  const [files, setFiles] = useState<FileEntry[]>()
  const [showExtra, setShowExtra] = useState(hasExtraData)
  const toggleExtraFields = () => setShowExtra(!showExtra)
  useEffect(() => setData(initialState), [initialState])
  useEffect(() => setShowExtra(hasExtraData), [hasExtraData])
  // dropzone adds multiple files, so we only use the last one
  useEffect(() => {
    if (singleFileSelect && files && files?.length > 1) {
      setFiles([files[files.length - 1]])
    }
  }, [files, singleFileSelect])

  const handleOnsubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (formHasErrors(inputs, data)) {
      setShowErrors(true)
      return
    }

    const result: JsonObject = { ...data }
    // transform numeric values to number
    const numericInputs = inputs
      .filter((i) => i.type === 'unit-select' || i.type === 'price')
      .map((i) => i.key)

    numericInputs.forEach((key) => {
      result[key] = Number(result[key])
    })
    onSubmit(result, section?.id, files)
    // reset all the files from modal
    setFiles([])
  }

  const handleOnClose = () => {
    if (!isEqual(data, initialState)) {
      showSnackbar?.(
        intl.formatMessage({ id: 'label.saveChanges' }),
        Severity.WARNING
      )
      return
    }
    handleOnCloseForced()
  }

  const handleOnCloseForced = () => {
    toggleModal()
    setFiles(undefined)
  }
  // Must render the inputs only if there are keys on the data state otherwise the initial render does not get the default values
  const hasData = Object.keys(data).length > 0
  return (
    <Dialog
      open={!!section}
      onClose={handleOnClose}
      TransitionComponent={ModalTransition}
      maxWidth="lg"
      keepMounted={false}
      fullScreen={isMobileScreen}
    >
      <DialogContent sx={{ backgroundColor: theme.palette.background.default }}>
        <RowSpaced>
          <ModalTitle>{section?.title}</ModalTitle>
          <IconButton onClick={handleOnCloseForced}>
            <CloseIcon />
          </IconButton>
        </RowSpaced>
        {hasData && (
          <Form noValidate onSubmit={handleOnsubmit}>
            <div>
              {inputs.map((input) => (
                <InputRender
                  key={input.key}
                  data={data}
                  input={input}
                  inputs={inputs}
                  setData={setData}
                  showErrors={showErrors}
                  files={files}
                  setFiles={setFiles}
                />
              ))}
              {showExtra &&
                extraInputs?.map((input) => (
                  <InputRender
                    key={input.key}
                    data={data}
                    input={input}
                    inputs={inputs}
                    setData={setData}
                    showErrors={showErrors}
                  />
                ))}
              {hasExtraInputs && !showExtra && (
                <ActionLink onClick={toggleExtraFields}>
                  {intl.formatMessage({ id: 'label.extraInfo' })}
                </ActionLink>
              )}
            </div>

            <ButtonWrapper>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                {intl.formatMessage({ id: 'label.save' })}
              </Button>
            </ButtonWrapper>
          </Form>
        )}
      </DialogContent>
    </Dialog>
  )
}

FormModal.defaultProps = {
  section: undefined,
  extraInputs: [],
  singleFileSelect: false,
  showSnackbar: undefined,
}

export default withSnackbar(FormModal)
