/**
 * Registered font families int he app.
 * Must match registered fonts from #src/index.css
 */
enum Fonts {
  NoirPro = 'NoirPro',
  NoirProMedium = 'NoirPro-Medium',
  OpenSans = 'Open Sans',
  OpenSansMedium = 'Open Sans Medium',
  Blinker = 'Blinker',
  BlinkerBold = 'Blinker-Bold',
  GochiHand = 'GochiHand-Regular',
}

export default Fonts
