import { IntlShape } from 'react-intl'
import { screenshots } from 'assets/images/landing'
import * as icons from 'assets/images/landing/icons'
import {
  managementSection,
  inventorySection,
  commandControlSection,
} from 'assets/images/landing'

export const getContent = (intl: IntlShape): Section[] => [
  {
    title: 'label.landing.inventory.title',
    color: '#72D4C8',
    image: inventorySection,
    coverImage: screenshots,
    cardTitle: intl.formatMessage({ id: 'label.inventory' }),
    cardDescription: intl.formatMessage({
      id: 'label.landing.inventory.cardDescription',
    }),
    promotionText: intl.formatMessage({
      id: 'label.landing.inventory.promotionText',
    }),
    points: [
      intl.formatMessage({ id: 'label.landing.inventory.point1' }),
      intl.formatMessage({ id: 'label.landing.inventory.point2' }),
      intl.formatMessage({ id: 'label.landing.inventory.point3' }),
      intl.formatMessage({ id: 'label.landing.inventory.point4' }),
      intl.formatMessage({ id: 'label.landing.inventory.point5' }),
    ],
    features: [
      {
        title: intl.formatMessage({ id: 'label.landing.inventory.feature1' }),
        icon: icons.assetsBelongings,
      },
      {
        title: intl.formatMessage({ id: 'label.landing.inventory.feature2' }),
        icon: icons.homeElectronics,
      },
      {
        title: intl.formatMessage({ id: 'label.landing.inventory.feature3' }),
        icon: icons.roomsSpaces,
      },
      {
        title: intl.formatMessage({ id: 'label.landing.inventory.feature4' }),
        icon: icons.boxesContainers,
      },
      {
        title: intl.formatMessage({ id: 'label.landing.inventory.feature5' }),
        icon: icons.familyMembers,
      },
      {
        title: intl.formatMessage({ id: 'label.landing.inventory.feature6' }),
        icon: icons.contractsDocuments,
      },
      {
        title: intl.formatMessage({ id: 'label.landing.inventory.feature7' }),
        icon: icons.pets,
      },
      {
        title: intl.formatMessage({ id: 'label.landing.inventory.feature8' }),
        icon: icons.brandsServiceProviders,
      },
    ],
  },
  {
    title: 'label.landing.dailyOps.title',
    color: '#8EC6FA',
    image: managementSection,
    coverImage: screenshots,
    cardTitle: intl.formatMessage({ id: 'label.landing.dailyOps.cardTitle' }),
    cardDescription: intl.formatMessage({
      id: 'label.landing.dailyOps.cardDescription',
    }),
    promotionText: intl.formatMessage({
      id: 'label.landing.dailyOps.promotionText',
    }),
    points: [
      intl.formatMessage({ id: 'label.landing.dailyOps.point1' }),
      intl.formatMessage({ id: 'label.landing.dailyOps.point2' }),
      intl.formatMessage({ id: 'label.landing.dailyOps.point3' }),
      intl.formatMessage({ id: 'label.landing.dailyOps.point4' }),
    ],
    features: [
      {
        title: intl.formatMessage({ id: 'label.landing.dailyOps.feature1' }),
        icon: icons.eventsTrackerPlanner,
      },
      {
        title: intl.formatMessage({ id: 'label.landing.dailyOps.feature2' }),
        icon: icons.storageTracker,
      },
      {
        title: intl.formatMessage({ id: 'label.landing.dailyOps.feature3' }),
        icon: icons.invoicesReceipts,
      },
      {
        title: intl.formatMessage({ id: 'label.landing.dailyOps.feature4' }),
        icon: icons.linkingMapping,
      },
      {
        title: intl.formatMessage({ id: 'label.landing.dailyOps.feature5' }),
        icon: icons.digitalizePaperComms,
      },
      {
        title: intl.formatMessage({ id: 'label.landing.dailyOps.feature6' }),
        icon: icons.scanUpload,
      },
      {
        title: intl.formatMessage({ id: 'label.landing.dailyOps.feature7' }),
        icon: icons.payments,
      },
      {
        title: intl.formatMessage({ id: 'label.landing.dailyOps.feature8' }),
        icon: icons.checksRepair,
      },
    ],
  },
  {
    title: 'label.landing.commandControl.title',
    color: '#FF8787',
    image: commandControlSection,
    coverImage: screenshots,
    cardTitle: intl.formatMessage({
      id: 'label.landing.commandControl.cardTitle',
    }),
    cardDescription: intl.formatMessage({
      id: 'label.landing.commandControl.cardDescription',
    }),
    promotionText: intl.formatMessage({
      id: 'label.landing.commandControl.promotionText',
    }),
    points: [
      intl.formatMessage({ id: 'label.landing.commandControl.point1' }),
      intl.formatMessage({ id: 'label.landing.commandControl.point2' }),
      intl.formatMessage({ id: 'label.landing.commandControl.point3' }),
      intl.formatMessage({ id: 'label.landing.commandControl.point4' }),
    ],
    features: [
      {
        title: intl.formatMessage({
          id: 'label.landing.commandControl.feature1',
        }),
        icon: icons.dashboardStats,
      },
      {
        title: intl.formatMessage({
          id: 'label.landing.commandControl.feature2',
        }),
        icon: icons.easyFindAnything,
      },
      {
        title: intl.formatMessage({
          id: 'label.landing.commandControl.feature3',
        }),
        icon: icons.accessOnTheGo,
      },
      {
        title: intl.formatMessage({
          id: 'label.landing.commandControl.feature4',
        }),
        icon: icons.budgetControl,
      },
      {
        title: intl.formatMessage({
          id: 'label.landing.commandControl.feature5',
        }),
        icon: icons.shareCollaborate,
      },
      {
        title: intl.formatMessage({
          id: 'label.landing.commandControl.feature6',
        }),
        icon: icons.serviceProviders,
      },
      {
        title: intl.formatMessage({
          id: 'label.landing.commandControl.feature7',
        }),
        icon: icons.accessControl,
      },
      {
        title: intl.formatMessage({
          id: 'label.landing.commandControl.feature8',
        }),
        icon: icons.tasksToDos,
      },
    ],
  },
]

export interface Section {
  title: string
  color: string
  image: string
  coverImage: string
  promotionText: string
  cardTitle: string
  cardDescription: string
  points: string[]
  features: {
    title: string
    icon: string
  }[]
}
