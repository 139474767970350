import { styled, lighten, Theme, CSSObject } from '@mui/material/styles'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import MuiDrawer from '@mui/material/Drawer'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import MuiListSubheader from '@mui/material/ListSubheader'
import MuiList from '@mui/material/List'
import MuiItemButton from '@mui/material/ListItemButton'
import Toolbar from '@mui/material/Toolbar'
import { FontFamily } from 'assets'
import { drawerWidth } from './constants'

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  backgroundColor: theme.palette.background.paper,
  elevation: 0,
  boxShadow: 'none',
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

export const HeaderSeparator = styled(Toolbar)(({ theme }) => ({
  borderBottom: '1px solid #E0E0E0',
  display: 'flex',
  position: 'absolute',
  right: '30px',
  left: '30px',
  width: 'calc(100% - 60px)',
  [theme.breakpoints.up('md')]: {
    width: `90%`,
  },
}))

export const MobileToolbar = styled(Toolbar)(({ theme }) => ({
  ...(theme.mixins.toolbar as CSSObject),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: theme.palette.background.default,
}))

export const List = styled(MuiList)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
}))

export const ListSubheader = styled(MuiListSubheader)(({ theme }) => ({
  fontFamily: 'NoirPro-Medium',
  fontSize: '14px',
  backgroundColor: 'inherit',
  lineHeight: '120%',
  color: theme.palette.action.active,
  margin: '5px 0',
  [theme.breakpoints.down('sm')]: {
    fontSize: '10px',
  },
}))

export const ListItemButton = styled(MuiItemButton)(({ theme }) => ({
  margin: '0 20px',
  borderRadius: '3px',
  '&.Mui-selected': {
    backgroundColor: theme.palette.action.active,
  },
  '&:hover': {
    backgroundColor: lighten(theme.palette.action.active, 0.7),
  },
}))

const openedMixin = (
  theme: Theme,
  hasSelectedWorkspace: boolean
): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  border: 0,
  boxShadow: hasSelectedWorkspace ? '0px 4px 20px 0px #0000000D' : 'none',
  backgroundColor: theme.palette.background.default,
})

const closedMixin = (
  theme: Theme,
  hasSelectedWorkspace: boolean
): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  border: 0,
  boxShadow: hasSelectedWorkspace ? '0px 4px 20px 0px #0000000D' : 'none',
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
})

// export const MobileDrawerComp = styled(MuiDrawer)(({ theme }) => ({
//   width: drawerWidth,
//   flexShrink: 0,
//   '& .MuiDrawer-paper': {
//     backgroundColor: theme.palette.background.default,
//     marginTop: theme.mixins.toolbar.height || theme.mixins.toolbar.minHeight,
//     width: drawerWidth,
//     maxHeight: `calc(100% - ${
//       theme.mixins.toolbar.height || theme.mixins.toolbar.minHeight
//     }px)`,
//     boxSizing: 'border-box',
//     boxShadow: '0px 4px 50px 0px #00000040',
//   },
// }))

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) =>
    prop !== 'open' && prop !== 'hasSelectedWorkspace',
})<{ hasSelectedWorkspace: boolean }>(
  ({ theme, open, hasSelectedWorkspace }) => ({
    width: drawerWidth,
    flexShrink: 0,
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme, hasSelectedWorkspace),
      '& .MuiDrawer-paper': openedMixin(theme, hasSelectedWorkspace),
    }),
    ...(!open && {
      ...closedMixin(theme, hasSelectedWorkspace),
      '& .MuiDrawer-paper': closedMixin(theme, hasSelectedWorkspace),
    }),
  })
)

export const ListSection = styled('li', {
  shouldForwardProp: (prop) => prop !== 'sectionIndex' && prop !== 'isMobile',
})<{
  sectionIndex: number
  isMobile: boolean
}>(({ theme, sectionIndex, isMobile }) => ({
  backgroundColor: 'inherit',
  ...(sectionIndex === 0 && {
    flexDirection: 'row',
    display: 'flex',
    height: 'auto',
    ...(isMobile && {
      marginTop: '20px',
    }),
  }),

  ...(!isMobile && sectionIndex === 2
    ? {
        marginTop: 'auto',
        borderTop: `1px solid ${lighten(theme.palette.divider, 0.5)}`,
      }
    : {}),
  ...(isMobile && {
    display: 'flex',
    flexDirection: 'column',
  }),
}))

export const UL = styled('ul')(({ theme }) => ({
  backgroundColor: 'inherit',
  padding: 0,
}))

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '0 8px',
  // necessary for content to be below app bar
  ...(theme.mixins.toolbar as CSSObject),
}))

export const LogoImg = styled('img')(({ theme }) => ({
  height: '44px',
  objectFit: 'contain',
}))

export const ListIconWrapper = styled(ListItemIcon)<{
  theme?: Theme
  selected: boolean
}>(({ theme, selected }) => ({
  minWidth: '40px',
  color: selected
    ? theme.palette.background.paper
    : theme.palette.text.secondary,
}))

export const TextItem = styled(ListItemText)<{
  theme?: Theme
  selected?: boolean
}>(({ theme, selected }) => ({
  '& .MuiListItemText-primary': {
    fontFamily: FontFamily.NoirPro,
    fontSize: '16px',
    lineHeight: '97%',
    color: selected
      ? theme.palette.background.paper
      : theme.palette.text.secondary,
    [theme.breakpoints.down('sm')]: {
      fontFamily: 'NoirPro',
      fontSize: '13px',
      lineHeight: '97%',
    },
  },
}))

export const WorkspacesContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '10px 0',
  alignItems: 'center',
}))

export const ListContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 5,
}))
