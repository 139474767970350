import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Card from '@mui/material/Card'
import { Theme, styled } from '@mui/material/styles'
import ROUTES from 'lib/routes'
import { useIntl } from 'react-intl'
import { ServiceProviderExtended } from 'types/serviceProvider'
import { formatLink } from 'utils/link'
import { cardBoxShadow, cardBoxShadowHover } from 'assets/styles/theme'
import { getDate } from 'utils/datetime'
import { Column, Row, RowSpaced } from './shared'
import { CardTitle, StatusChip, ContentText } from './cards'
import { H2 } from './typography'

const ContainerCard = styled(Card)({
  padding: '12px',
  gap: '8px',
  borderRadius: '5px',
  position: 'relative',
  boxShadow: cardBoxShadow,
  ':hover': {
    cursor: 'pointer',
    boxShadow: cardBoxShadowHover,
  },
})

const Content = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  width: '100%',
})

const Image = styled('img')<{ size?: number; theme?: Theme }>(
  ({ theme, size = 60 }) => ({
    display: 'flex',
    width: '60px',
    height: '60px',
    objectFit: 'contain',
    borderRadius: size / 2,
    backgroundColor: theme.palette.shared.lightBlue,
    padding: '2px',
  })
)

const PlaceholderWrapper = styled('div')<{ size?: number; theme?: Theme }>(
  ({ theme, size = 60 }) => ({
    justifyContent: 'center',
    alignItems: 'center',
    width: size,
    height: size,
    borderRadius: size / 2,
    display: 'flex',
    backgroundColor: theme.palette.shared.lightBlue,
    padding: '2px',
  })
)

const CategoryWrapper = styled(Row)(({ theme }) => ({
  marginTop: '4px',
}))

interface Props {
  record: ServiceProviderExtended
  showChip?: boolean
}
function ServiceProviderCard({ record, showChip }: Props) {
  const navigate = useNavigate()
  const intl = useIntl()
  // item cards must always be shown under the workspace route
  const params = useParams() as { workspaceId: string }
  const [favError, setFavError] = React.useState(false)
  const handleClick = () =>
    navigate(
      ROUTES.DASHBOARD_SERVICE_PROVIDER_DETAILS_ROUTE(
        params.workspaceId,
        record.id
      )
    )

  const onLoadError = () => setFavError(true)
  return (
    <ContainerCard onClick={handleClick}>
      <Column
        sx={{
          flexWrap: 'noWrap',
          alignItems: 'center',
        }}
      >
        {!favError && record.link ? (
          <Image
            src={`${formatLink(record.link)}/favicon.ico`}
            onError={onLoadError}
          />
        ) : (
          <PlaceholderBadge
            placeholder={record.title.substring(0, 1).toUpperCase()}
          />
        )}
        <Row style={{ position: 'absolute', top: 4, right: 4 }}>
          {record.deletedAt && (
            <StatusChip
              style={{ fontSize: '10px' }}
              color="error"
              label={intl.formatMessage(
                { id: 'label.deletedAtDate' },
                { date: getDate(record.deletedAt) }
              )}
            />
          )}
          {showChip && record.public && (
            <StatusChip
              style={{ fontSize: '10px', marginLeft: '2px' }}
              label={intl.formatMessage({
                id: record.public ? 'label.public' : 'label.private',
              })}
              color={record.public ? 'info' : 'default'}
            />
          )}
        </Row>
        <Content>
          <RowSpaced>
            <CardTitle sx={{ marginTop: '8px' }} numberOfLines={1}>
              {record.title}
            </CardTitle>
          </RowSpaced>
          <CategoryWrapper>
            <ContentText numberOfLines={1}>
              {record?.category?.title ??
                intl.formatMessage({ id: 'label.unspecifiedIndustry' })}
            </ContentText>
          </CategoryWrapper>
        </Content>
      </Column>
    </ContainerCard>
  )
}
ServiceProviderCard.defaultProps = {
  showChip: false,
}

export function PlaceholderBadge({
  placeholder,
  size,
}: {
  placeholder: string
  size?: number
}) {
  const fontSize = size ? { fontSize: size * 0.55 } : {}
  return (
    <PlaceholderWrapper size={size}>
      <H2 style={{ userSelect: 'none', ...fontSize }}>{placeholder}</H2>
    </PlaceholderWrapper>
  )
}

export default ServiceProviderCard
