import React from 'react'
import { styled, useTheme } from '@mui/material/styles'
import { BodyText, BodyTextLight, CaptionText, H3 } from 'components/typography'
import { Column, Row, RowSpaced } from 'components/shared'
import IconButtonMui from '@mui/material/IconButton'
import Pencil from 'assets/svg/Pencil'
import { FontFamily } from 'assets'
import MuiChip from '@mui/material/Chip'

const Container = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: '5px',
  padding: '16px 16px 35px 16px',
  marginBottom: '26px',
}))

const TitleWrapper = styled(RowSpaced)(({ theme }) => ({
  marginBottom: '16px',
}))

const IconButton = styled(IconButtonMui)(({ theme }) => ({
  width: '30px',
  height: '30px',
}))

const Description = styled(CaptionText)(({ theme }) => ({
  marginRight: '4px',
}))

const ChipsWrapper = styled(Row)(({ theme }) => ({
  margin: '4px 0',
}))

const ContentWrapper = styled(Column, {
  shouldForwardProp: (prop) => prop !== 'hideSpacing',
})<{ hideSpacing?: boolean }>(({ hideSpacing }) => ({
  ...(!hideSpacing && { marginTop: '16px' }),
}))

const Chip = styled(MuiChip)(({ theme }) => ({
  borderRadius: '4px',
  backgroundColor: theme.palette.shared.lightGray,
  color: theme.palette.text.primary,
  marginRight: '4px',
  fontFamily: FontFamily.OpenSansMedium,
  fontWeight: 600,
}))

interface ChipType {
  value?: string
  label: string
  icon?: React.ReactElement
  onPress?: (value: string) => void
}

interface Action {
  ariaLabel: string
  icon: React.ElementType
  onPress: () => void
}
export interface ContentSection {
  action?: Action
  title?: string
  description?: string
  chips?: ChipType[]
  component?: React.ReactElement
  // onPress data item
  onPress?: (value: string) => void
}
interface Props {
  title: string
  children?: React.ReactNode
  onEdit?: () => void
  placeholder?: string // used when no content is present
  content?: ContentSection[]
  containerStyle?: React.CSSProperties
}

function SidebarCard({
  title,
  children,
  onEdit,
  placeholder,
  content,
  containerStyle,
}: Props) {
  const theme = useTheme()
  const hasContent = !!children || (content?.length ?? 0) > 0
  return (
    <Container style={containerStyle}>
      <TitleWrapper>
        <H3>{title}</H3>
        {onEdit && (
          <IconButton onClick={onEdit}>
            <Pencil />
          </IconButton>
        )}
      </TitleWrapper>

      {children}
      {!hasContent && (
        <BodyTextLight color={theme.palette.text.secondary}>
          {placeholder}
        </BodyTextLight>
      )}

      <Column>
        {content?.map((section, index) => {
          const { icon, onPress, ariaLabel } = section.action ?? {}
          const { chips, component } = section
          const Icon = icon ?? React.Fragment
          return (
            <ContentWrapper
              key={`${section.title}-${section.description}`}
              hideSpacing={index === 0}
            >
              <BodyText fontFamily={FontFamily.NoirPro}>
                {section.title}
              </BodyText>
              <Row>
                {section.description && (
                  <Description>{section.description}</Description>
                )}

                {chips && chips.length > 0 && (
                  <ChipsWrapper>
                    {chips.map((chip) => {
                      const handleOnClick = () =>
                        chip.onPress?.(chip.value ?? '')
                      return (
                        <Chip
                          onClick={chip.onPress ? handleOnClick : undefined}
                          color="primary"
                          key={chip.value ?? chip.label}
                          label={chip.label}
                          icon={chip.icon}
                        />
                      )
                    })}
                  </ChipsWrapper>
                )}
                {component}
                {section.action && (
                  <IconButton
                    onClick={onPress}
                    key={ariaLabel}
                    aria-label={ariaLabel}
                  >
                    <Icon size={24} />
                  </IconButton>
                )}
              </Row>
            </ContentWrapper>
          )
        })}
      </Column>
    </Container>
  )
}

SidebarCard.defaultProps = {
  children: null,
  onEdit: null,
  placeholder: '',
  content: null,
  containerStyle: {},
}

export default SidebarCard
