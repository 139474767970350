import React, { useState, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import appConfig from 'config/app'
import { FileEntry, JsonObject } from 'types/shared'
import LoadingIndicator from 'components/LoadingIndicator'
import FormModal from 'components/recordDetails/FormModal'
import ContentSection from 'components/recordDetails/ContentSection'
import { Severity, withSnackbar } from 'components/providers/SnackbarHOC'
import PageLinksCard from 'components/pages/PageLinksCard'
import { Box } from '@mui/material'
import { saveActiveWorkspaceId } from 'lib/sessionStorage'
import {
  ConfirmationData,
  withConfirmationModal,
} from 'components/hoc/ConfirmationModalHoc'
import { USER_REQUESTS } from 'gql/userRequest'
import { SuggestionSection } from 'components/recordDetails/SuggestionsSection'
import DangerSectionRender from 'components/content/DangerSectionRender'
import { Container, Content } from 'components/shared'
import TrashIcon from 'assets/svg/Trash'
import SidebarCard from 'components/recordDetails/SidebarCard'
import UserReportCard from 'components/pages/UserReportCard'
import { canEditWorkspace } from 'utils/workspace'
import { FileType } from 'types/archive'
import ROUTES from 'lib/routes'
import { uploadMedia } from 'lib/api'
import { UserRequestReferenceModel } from 'types/userRequest'
import { useUserRequests } from 'hooks/graphql/userRequest'
import UserRequestsSection from 'components/content/UserRequestsSection'
import {
  PageGrid,
  PageGridLeft,
  PageGridRight,
  PageHeader,
  StickySection,
} from 'components/pages'
import { CoverImage, DangerLabel, SectionContainer } from './fragments'
import {
  getInitialState,
  getEditInputs,
  getSectionWithId,
  Section,
  getExtraInputs,
  payloadBuilder,
  getWorkspaceContent,
  settingsContent,
} from './constants'
import { useMutations, useData } from './hooks'

interface WorkspaceSettingsProps {
  showSnackbar?: (message: string, severity: Severity) => void
  requestConfirmation?: (data: ConfirmationData) => void
}

function WorkspaceSettings({
  showSnackbar,
  requestConfirmation,
}: WorkspaceSettingsProps) {
  const intl = useIntl()
  const params = useParams() as { workspaceId: string }
  const [editing, setEditing] = useState<SuggestionSection>()
  // optional id of the sub-record to edit, example (saleInfoId)
  // const [editingId, setEditingId] = useState<string>()
  const hideEditingModal = useCallback(() => setEditing(undefined), [])
  const navigate = useNavigate()

  const { data: userRequests, loading: userRequestsLoading } = useUserRequests({
    referenceId: params.workspaceId,
    referenceModel: UserRequestReferenceModel.WORKSPACE,
  })
  const {
    loading: loadingData,
    data: { workspace, roles },
  } = useData(params.workspaceId)
  const {
    loading: mutating,
    mutations: {
      archiveWorkspace,
      unarchiveWorkspace,
      updateWorkspace,
      leaveWorkspace,
    },
  } = useMutations(params.workspaceId, {
    showSnackbar,
    hideEditingModal,
    onLeftWorkspace: () => {
      saveActiveWorkspaceId(null)
      navigate(ROUTES.DASHBOARD, { replace: true })
    },
  })

  const handleOnEditWithId = (sectionId: string, recordId?: string) => {
    const section = getSectionWithId(sectionId, intl)
    // setEditingId(recordId)
    setEditing(section)
  }
  const handleRecordUpdate = (formData: JsonObject, sectionId?: string) => {
    const payload = payloadBuilder(formData, params.workspaceId, sectionId)
    updateWorkspace({ variables: { payload } })
  }

  const uploadCover = async (cover: FileEntry): Promise<string> => {
    const result = await uploadMedia(
      cover.file,
      { workspaceId: params.workspaceId, type: FileType.image },
      true
    )
      .then((response) => response.json())
      .catch((error) => showSnackbar?.(error.message, Severity.ERROR))

    // eslint-disable-next-line no-underscore-dangle
    const archiveId = result?.data?._id
    return archiveId
  }

  const handleOnUpdate = (
    formData: JsonObject,
    sectionId?: string,
    files?: FileEntry[]
  ) => {
    const dropzoneKey =
      inputs.find((input) => input.type === 'dropzone')?.key ?? ''
    // ws cover
    const cover = files?.[0]
    if (cover) {
      uploadCover(cover).then((coverId) =>
        handleRecordUpdate({ ...formData, [dropzoneKey]: coverId }, sectionId)
      )
    } else {
      handleRecordUpdate(formData, sectionId)
    }
  }
  const toggleArchiveStatus = () => {
    const variables = { workspaceId: params.workspaceId }
    if (workspace?.archived) {
      unarchiveWorkspace({ variables })
    } else {
      archiveWorkspace({ variables })
    }
  }

  const handleLeaveBoard = () =>
    requestConfirmation?.({
      title: intl.formatMessage({ id: 'label.leavingBoard' }),
      description: intl.formatMessage({ id: 'label.leavingBoardWarning' }),
      actions: [
        { label: intl.formatMessage({ id: 'label.cancel' }) },
        {
          label: intl.formatMessage({ id: 'label.leave' }),
          color: 'error',
          onClick: () =>
            roles?.workspaceMembership?.id
              ? leaveWorkspace({
                  variables: { membershipId: roles?.workspaceMembership.id },
                })
              : null,
        },
      ],
    })

  const referenceData = workspace
    ? {
        referenceId: workspace?.id,
        referenceModel: UserRequestReferenceModel.WORKSPACE,
      }
    : undefined
  const inputs = getEditInputs(editing?.id ?? '')
  const extraInputs = getExtraInputs(editing?.id ?? '')
  const initialState =
    workspace && editing ? getInitialState(workspace, editing.id) : {}
  const isLoading = loadingData || mutating || userRequestsLoading
  return (
    <Container detailPage>
      <Content detailPage>
        <LoadingIndicator visible={isLoading} />
        <PageGrid container spacing={6}>
          <PageGridLeft item xs={12} sm={12} md={12} lg={7} xl={8}>
            {workspace && (
              <PageHeader
                pageTitle={intl.formatMessage({ id: 'label.settings' })}
                recordTitle={workspace?.title ?? ''}
                breadcrumbLink=""
              />
            )}

            {workspace && (
              <SectionContainer style={{ marginBottom: '20px' }}>
                {workspace?.coverImage?.medium && (
                  <CoverImage
                    src={`${appConfig.media}${workspace.coverImage.medium}`}
                  />
                )}
                {workspace.archived && (
                  <Box m="0 0 20px 0">
                    <DangerLabel>
                      {intl.formatMessage({ id: 'label.boardIsArchived' })}
                    </DangerLabel>
                  </Box>
                )}
                <ContentSection
                  section={{
                    title: intl.formatMessage({ id: 'label.general' }),
                    content: getWorkspaceContent(workspace, intl),
                  }}
                  onEdit={() => handleOnEditWithId(Section.general)}
                />
              </SectionContainer>
            )}

            <UserRequestsSection userRequests={userRequests} />

            {roles && canEditWorkspace(roles) && (
              <DangerSectionRender
                entries={[
                  {
                    title: intl.formatMessage({ id: 'label.dangerZone' }),
                    actions: [
                      {
                        ariaLabel: 'archive board',
                        icon: (props) => <TrashIcon {...props} />,
                        onPress: toggleArchiveStatus,
                        label: intl.formatMessage({
                          id: workspace?.archived
                            ? 'label.restoreBoard'
                            : 'label.archiveBoard',
                        }),
                        infoLabel: intl.formatMessage({
                          id: workspace?.archived
                            ? 'label.archivedBoard'
                            : 'label.activeBoard',
                        }),
                      },
                    ],
                  },
                ]}
              />
            )}
          </PageGridLeft>
          <PageGridRight item xs={12} sm={12} md={12} lg={5} xl={4}>
            <StickySection>
              <PageLinksCard />
              <UserReportCard
                refetchQueries={[
                  {
                    query: USER_REQUESTS,
                    variables: { payload: referenceData },
                  },
                ]}
                showSnackbar={showSnackbar}
                referenceData={referenceData}
              />
              {roles?.workspaceMembership?.id && (
                <SidebarCard
                  title={intl.formatMessage({
                    id: 'label.action',
                  })}
                  content={settingsContent(intl, {
                    leaveBoard: handleLeaveBoard,
                  })}
                />
              )}
            </StickySection>
          </PageGridRight>
        </PageGrid>
      </Content>
      <FormModal
        toggleModal={hideEditingModal}
        inputs={inputs}
        extraInputs={extraInputs}
        section={editing}
        initialState={initialState}
        onSubmit={handleOnUpdate}
        singleFileSelect
      />
    </Container>
  )
}

WorkspaceSettings.defaultProps = {
  showSnackbar: undefined,
  requestConfirmation: undefined,
}
export default withConfirmationModal(withSnackbar(WorkspaceSettings))
