import { useMutation } from '@apollo/client'
import { Severity } from 'components/providers/SnackbarHOC'
import {
  DELETE_ITEMS,
  TRANSFER_ITEMS,
  UPDATE_ITEM,
  WORKSPACE_ITEMS,
} from 'gql/item'
import { useWorkspaces } from 'hooks/graphql/workspaces'
import {
  DeleteItemsData,
  DeleteItemsInput,
  TransferItemsData,
  TransferItemsInput,
  UpdateItemData,
  UpdateItemInput,
} from 'types/item'

export function useData(workspaceId: string) {
  const { data: workspaces, loading: wsLoading } = useWorkspaces()
  const activeWs = workspaces.find((ws) => ws.id === workspaceId)
  const orgWorkspaces =
    workspaces.filter((w) => w.organizationId === activeWs?.organizationId) ||
    []

  return { loading: wsLoading, data: { orgWorkspaces } }
}

export function useMutations(
  payload: {
    workspaceId: string
    limit: number
    page: number
  },
  methods: {
    showSnackbar?: (message: string, severity: Severity) => void
    hideEditingModal: () => void
  }
) {
  const { showSnackbar, hideEditingModal } = methods
  const [updateItem, { loading: updateLoading }] = useMutation<
    UpdateItemData,
    UpdateItemInput
  >(UPDATE_ITEM, {
    refetchQueries: [{ query: WORKSPACE_ITEMS, variables: { payload } }],
  })

  const [deleteItems, { loading: deleting }] = useMutation<
    DeleteItemsData,
    DeleteItemsInput
  >(DELETE_ITEMS, {
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: [{ query: WORKSPACE_ITEMS, variables: { payload } }],
  })

  const [transferItems, { loading: transferLoading }] = useMutation<
    TransferItemsData,
    TransferItemsInput
  >(TRANSFER_ITEMS, {
    onCompleted: hideEditingModal,
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: [{ query: WORKSPACE_ITEMS, variables: { payload } }],
  })

  const loading = deleting || updateLoading || transferLoading

  return {
    loading,
    mutations: {
      updateItem,
      deleteItems,
      transferItems,
    },
  }
}
