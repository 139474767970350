import React from 'react'
import { IntlShape } from 'react-intl'
import { Severity } from 'components/providers/SnackbarHOC'
import { TabSection } from 'components/Tabs'
import SectionRender from 'components/content/SectionRender'
import Pencil from 'assets/svg/Pencil'
import { OrganizationExtended, OrganizationType } from 'types/organization'
import { Countries } from 'types/static'
import { Theme } from '@mui/material/styles'
import { currencies } from 'lib/constants'
import { orderBy } from 'lodash'
import { SubscriptionStatus } from 'types/subscription'
import { Column } from 'components/shared'
import UserRequestsSection from 'components/content/UserRequestsSection'
import { UserRequest } from 'types/userRequest'
import { Section } from './constants'
import BoardsSection from './BoardsSection'
import { CollapsedSubscriptions, renderSubscriptionItem } from './fragments'

const tabs = (
  intl: IntlShape,
  theme: Theme,
  record: OrganizationExtended | undefined,
  countries: Countries,
  userRequests: UserRequest[],
  methods: {
    handleOnEditWithId: (sectionId: Section, recordId?: string) => void
    showSnackbar?: (message: string, severity: Severity) => void
  }
): TabSection[] => {
  const { handleOnEditWithId } = methods

  const subscriptions = orderBy(
    record?.subscriptions ?? [],
    [(obj) => new Date(obj.createdAt)],
    ['desc']
  )
  return [
    {
      key: 'account-plan',
      label: 'label.accountInformation',
      visible: true,
      content: (
        <SectionRender
          header={intl.formatMessage({ id: 'label.accountInformation' })}
          entries={[
            {
              title: intl.formatMessage({ id: 'label.account' }),
              data: [
                {
                  label: intl.formatMessage({ id: 'label.type' }),
                  value: intl.formatMessage({
                    id: `organization.type.${record?.type}`,
                    defaultMessage: '-',
                  }),
                },
                {
                  label: intl.formatMessage({ id: 'label.referenceNumber' }),
                  value: record?.referenceNumber ?? '',
                },
              ],
            },
            {
              title: intl.formatMessage({ id: 'label.defaultPreferences' }),
              actions: [
                {
                  ariaLabel: 'preferences edit',
                  icon: (props) => <Pencil {...props} />,
                  onPress: () => handleOnEditWithId(Section.preferences),
                },
              ],
              data: [
                {
                  label: intl.formatMessage({ id: 'label.currency' }),
                  value:
                    currencies.find(
                      (i) => i.value === record?.defaultOptions?.currency
                    )?.label ?? '-',
                },
                {
                  label: intl.formatMessage({
                    id: 'label.measurementSystem',
                  }),
                  value: intl.formatMessage({
                    id: `measurement.type.${record?.defaultOptions?.measurementSystem}`,
                    defaultMessage:
                      record?.defaultOptions?.measurementSystem ?? '-',
                  }),
                },
              ],
            },
          ]}
        />
      ),
    },
    {
      key: 'company-info',
      label: 'label.company',
      visible: record?.type === OrganizationType.business,
      content: (
        <SectionRender
          header={intl.formatMessage({ id: 'label.company' })}
          headerActions={[
            {
              ariaLabel: 'company edit',
              icon: (props) => <Pencil {...props} />,
              onPress: () => handleOnEditWithId(Section.company),
            },
          ]}
          entries={[
            {
              data: [
                {
                  label: intl.formatMessage({ id: 'label.name' }),
                  value: record?.company?.name ?? '-',
                },
                {
                  label: intl.formatMessage({ id: 'label.description' }),
                  value: record?.company?.description ?? '-',
                },
              ],
            },
          ]}
        />
      ),
    },
    {
      key: 'subscription',
      label: 'label.subscription',
      visible: subscriptions.length > 0 && !!record,
      content: (
        <Column>
          <SectionRender
            header={intl.formatMessage({ id: 'label.activeSubscriptions' })}
            entries={
              record
                ? subscriptions
                    .filter(
                      (subscription) =>
                        subscription.status === SubscriptionStatus.ACTIVE
                    )
                    .map((subscription) =>
                      renderSubscriptionItem(
                        intl,
                        theme,
                        subscription,
                        record,
                        {
                          onRefund: (subscriptionId: string) =>
                            handleOnEditWithId(
                              Section.userRequest,
                              subscriptionId
                            ),
                        }
                      )
                    )
                : []
            }
          />
          {record && (
            <CollapsedSubscriptions
              organization={record}
              subscriptions={subscriptions.filter(
                (subscription) =>
                  subscription.status !== SubscriptionStatus.ACTIVE
              )}
            />
          )}
        </Column>
      ),
    },
    // TODO: to be revisited and must add multiple addresses.
    // {
    //   key: 'address',
    //   label: 'label.address',
    //   visible: true,
    //   content: (
    //     <SectionRender
    //       header={intl.formatMessage({ id: 'label.address' })}
    //       headerActions={[
    //         {
    //           ariaLabel: 'address edit',
    //           icon: (props) => <Pencil {...props} />,
    //           onPress: () =>
    //             handleOnEditWithId(Section.address, record?.addressId),
    //         },
    //       ]}
    //       entries={[
    //         {
    //           data: [
    //             {
    //               label: `${record?.address?.street ?? ''} ${
    //                 record?.address?.houseNumber ?? ''
    //               }`,
    //               value: '',
    //             },
    //             {
    //               label: `${record?.address?.zipCode ?? ''} ${
    //                 record?.address?.city ?? ''
    //               }`,
    //               value: '',
    //             },
    //             {
    //               label: `${
    //                 record?.address?.countryCode
    //                   ? countries[record.address.countryCode]
    //                   : ''
    //               }`,
    //               value: '',
    //             },
    //           ],
    //         },
    //       ]}
    //     />
    //   ),
    // },
    {
      key: 'user-requests',
      label: 'label.userRequests',
      visible: userRequests.length > 0,
      content: <UserRequestsSection userRequests={userRequests} />,
    },
    {
      key: 'workspaces list',
      label: 'label.boards',
      visible: true,
      content: (
        <SectionRender
          header={intl.formatMessage({ id: 'label.boards' })}
          entries={[
            {
              content: (
                <React.Fragment>
                  {record?.id ? (
                    <BoardsSection organizationId={record?.id} />
                  ) : null}
                </React.Fragment>
              ),
            },
          ]}
        />
      ),
    },
  ].filter((section) => section.visible)
}

export default tabs
