import React from 'react'
import { Section } from '../content'
import { SectionContainer } from './fragments'
import { styled } from '@mui/material/styles'
import StyledText from 'components/StyledIntlText'
import { FontFamily } from 'assets'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Card, Grid } from '@mui/material'
import { cardBoxShadow, cardBoxShadowHover } from 'assets/styles/theme'
import { BodyTextLight, CaptionText, H2, H3 } from 'components/typography'
import { Column, Row } from 'components/shared'

const ContainerCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  borderRadius: '30px',
  padding: '40px 70px',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  boxShadow: cardBoxShadow,
  ':hover': {
    cursor: 'pointer',
    boxShadow: cardBoxShadowHover,
  },
  [theme.breakpoints.down('md')]: {
    minWidth: '200px',
    padding: '10px 20px',
  },
}))

const Image = styled('img')(({ theme }) => ({
  width: 'min(230px, 100%)',
  height: '140px',
  objectFit: 'contain',
  borderRadius: '800px',
}))

const CoverImage = styled('img')(({ theme }) => ({
  objectFit: 'contain',
  height: '480px',
}))
const FeatureImage = styled('img')(({ theme }) => ({
  width: '40px',
  height: '40px',
  objectFit: 'contain',
  marginRight: '2px',
}))

const PointsWrapper = styled('div')(({ theme }) => ({
  marginTop: '20px',
  flexDirection: 'column',
  display: 'flex',
  paddingLeft: '8px',
}))

const PromotionText = styled(H2)(({ theme }) => ({
  position: 'absolute',
  top: '20px',
  left: '60px',
  textAlign: 'center',
  maxWidth: '150px',
  fontFamily: FontFamily.GochiHand,
  color: theme.palette.background.default,
}))

const PromotionWrapper = styled(Column)(({ theme }) => ({
  width: '50%',
  position: 'relative',
  padding: '40px 30px',
  height: '100%',
}))

const FeatureWrapper = styled(Row)(({ theme }) => ({
  backgroundColor: theme.palette.shared.lightGray,
  padding: '20px 15px',
  borderRadius: '20px',
  flexWrap: 'nowrap',
  minHeight: '50px',
}))

const FeaturesContainer = styled(Column)(({ theme }) => ({
  width: 'calc(50% - 60px)',
  padding: '20px 30px',
  justifyContent: 'center',
  alignSelf: 'center',
  height: '100%',
  [theme.breakpoints.down('md')]: {
    padding: '10px',
  },
}))

interface Props {
  section: Section
  index: number
}
function SectionUi({ section, index }: Props) {
  const theme = useTheme()
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'))
  const isBigScreen = useMediaQuery(theme.breakpoints.up('md'))
  const isBigMediumScreen = useMediaQuery(theme.breakpoints.up('sm'))
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const isDownMediumScreen = useMediaQuery(theme.breakpoints.down('md'))
  const titleSize = isBigScreen ? 28 : 22
  return (
    <SectionContainer>
      <StyledText
        fontFamily={FontFamily.NoirProMedium}
        text={section.title}
        fontSize={titleSize}
      />
      <Grid
        container
        mt={isBigMediumScreen ? '30px' : '16px'}
        spacing={isBigMediumScreen ? 2 : 1}
      >
        <Grid
          item
          lg={4}
          md={6}
          sm={12}
          xs={12}
          style={{ display: 'flex', flex: 1 }}
        >
          <ContainerCard>
            <Image src={section.image} />
            <Column>
              <H3
                style={{
                  margin: '10px 0 20px 0',
                  fontFamily: FontFamily.NoirProMedium,
                }}
              >
                {index}. {section.cardTitle}
              </H3>
              <BodyTextLight opacity=".5">
                {section.cardDescription}
              </BodyTextLight>
              <CardContent section={section} />
            </Column>
          </ContainerCard>
        </Grid>
        {!isSmallScreen && (
          <Grid
            item
            lg={8}
            md={6}
            sm={12}
            xs={12}
            style={isDownMediumScreen ? { display: 'flex', flex: 1 } : {}}
          >
            <ContainerCard style={{ padding: 0 }}>
              <Row style={{ width: '100%' }}>
                <FeaturesContainer>
                  <FeaturesGrid features={section.features} />
                </FeaturesContainer>
                {isLargeScreen && (
                  <PromotionWrapper style={{ backgroundColor: section.color }}>
                    <CoverImage
                      src={section.coverImage}
                      alt={section.cardTitle}
                    />
                    <PromotionText>{section.promotionText}</PromotionText>
                  </PromotionWrapper>
                )}
              </Row>
            </ContainerCard>
          </Grid>
        )}
      </Grid>
    </SectionContainer>
  )
}

function CardContent({ section }: { section: Section }) {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  return isSmallScreen ? (
    <FeaturesGrid features={section.features} />
  ) : (
    <PointsWrapper>
      {section.points.map((point) => (
        <BodyTextLight
          opacity=".5"
          key={point}
          style={{ fontSize: '13px', lineHeight: '28px' }}
        >
          • {point}
        </BodyTextLight>
      ))}
    </PointsWrapper>
  )
}

function FeaturesGrid({ features }: { features: Section['features'] }) {
  return (
    <Grid container>
      {features.map((feature) => (
        <Grid item key={feature.title} xs={6} style={{ padding: '10px' }}>
          <FeatureWrapper>
            <FeatureImage alt={feature.title} src={feature.icon} />
            <CaptionText fontFamily={FontFamily.NoirProMedium}>
              {feature.title}
            </CaptionText>
          </FeatureWrapper>
        </Grid>
      ))}
    </Grid>
  )
}

export default SectionUi
