import { IntlShape } from 'react-intl'
import ROUTES from 'lib/routes'
import SettingsIcon from '@mui/icons-material/SettingsOutlined'
import DashboardIcon from '@mui/icons-material/WindowOutlined'
import ContractsIcon from '@mui/icons-material/InsertDriveFileOutlined'
import { Workspace } from 'types/workspace'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { SvgIconTypeMap } from '@mui/material/SvgIcon'
import {
  Archive,
  Cardholder,
  CellSignalHigh,
  CircleWavy,
  ClipboardText,
  Gear,
  HouseSimple,
  Users,
} from 'assets/svg'

export const drawerWidth = 240

export interface MenuEntry {
  key: string
  title: string
  icon:
    | (OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
        muiName?: string
      })
    | unknown
  route: string
  parent?: string
}
interface MenuSection {
  section: string
  entries: MenuEntry[]
}
export const getMenuItems = (
  intl: IntlShape,
  workspace: Workspace | null,
  isMobile: boolean
): MenuSection[] => {
  const menuItems = []

  // isMobile &&
  if (workspace) {
    menuItems.push({
      section: intl.formatMessage({ id: 'label.board' }),
      entries: [
        {
          key: 'workspace-selection',
          title: '-',
          icon: DashboardIcon,
          route: ROUTES.DASHBOARD,
        },
      ],
    })
  }

  // dashboard section
  menuItems.push(
    workspace
      ? {
          section: ' ',
          entries: [
            // {
            //   key: 'dashboard',
            //   title: intl.formatMessage({ id: 'label.overview' }),
            //   icon: DashboardIcon,
            //   route: ROUTES.DASHBOARD_OVERVIEW_ROUTE(workspace.id),
            // },
            {
              key: 'inventory',
              title: intl.formatMessage({ id: 'label.overview' }),
              icon: ClipboardText,
              route: ROUTES.DASHBOARD_OVERVIEW_ROUTE(workspace.id),
            },
            {
              key: 'items',
              title: intl.formatMessage({ id: 'label.items' }),
              icon: CircleWavy,
              route: ROUTES.DASHBOARD_ITEMS_ROUTE(workspace.id),
              parent: 'inventory', // key of parent
            },
            {
              key: 'containers',
              title: intl.formatMessage({ id: 'label.containers' }),
              icon: Archive,
              route: ROUTES.DASHBOARD_CONTAINERS_ROUTE(workspace.id),
              parent: 'inventory', // key of parent
            },
            {
              key: 'groups',
              title: intl.formatMessage({ id: 'label.groups' }),
              icon: Cardholder,
              route: ROUTES.DASHBOARD_GROUPS_ROUTE(workspace.id),
              parent: 'inventory', // key of parent
            },
            {
              key: 'nests',
              title: intl.formatMessage({ id: 'label.spaces' }),
              icon: HouseSimple,
              route: ROUTES.DASHBOARD_NESTS_ROUTE(workspace.id),
              parent: 'inventory', // key of parent
            },

            {
              key: 'contracts',
              title: intl.formatMessage({ id: 'label.contracts' }),
              icon: ContractsIcon,
              route: ROUTES.DASHBOARD_CONTRACTS_ROUTE(workspace.id),
              parent: 'inventory', // key of parent
            },
            {
              key: 'service-providers',
              title: intl.formatMessage({ id: 'label.serviceProviders' }),
              icon: CellSignalHigh,
              route: ROUTES.DASHBOARD_SERVICE_PROVIDERS_ROUTE(workspace.id),
            },
            {
              key: 'members',
              title: intl.formatMessage({ id: 'label.members' }),
              icon: Users,
              route: ROUTES.DASHBOARD_MEMBERS_ROUTE(workspace.id),
            },
            // {
            //   key: 'archive',
            //   title: intl.formatMessage({ id: 'label.docs_media' }),
            //   icon: FileSharp,
            //   route: ROUTES.DASHBOARD_ARCHIVE_ROUTE(workspace.id),
            // },
            {
              key: 'settings',
              title: intl.formatMessage({ id: 'label.settings' }),
              icon: SettingsIcon,
              route: ROUTES.DASHBOARD_SETTINGS_ROUTE(workspace.id),
            },
          ],
        }
      : {
          section: intl.formatMessage({ id: 'label.general' }),
          entries: [
            {
              key: 'workspace-list',
              title: intl.formatMessage({ id: 'label.workspaces' }),
              icon: Gear,
              route: ROUTES.DASHBOARD,
            },
          ],
        }
  )

  // management section only visible for desktop users
  // if (!isMobile)
  //   menuItems.push({
  //     section: intl.formatMessage({ id: 'label.management' }),
  //     entries: [
  //       {
  //         key: 'account-preferences',
  //         title: intl.formatMessage({ id: 'label.accountPreferences' }),
  //         icon: ManageAccountsIcon,
  //         route: ROUTES.DASHBOARD_ACCOUNT_PREFERENCES,
  //       },
  //       {
  //         key: 'account',
  //         title: intl.formatMessage({ id: 'label.account' }),
  //         icon: PersonSharp,
  //         route: ROUTES.DASHBOARD_PROFILE,
  //       },

  //       {
  //         key: 'logout',
  //         title: intl.formatMessage({ id: 'label.logout' }),
  //         icon: LogoutIcon,
  //         route: 'logout', // custom action for logout route
  //       },
  //     ],
  //   })
  return menuItems
}
