import React from 'react'
import { useIntl } from 'react-intl'
import { useTheme } from '@mui/material/styles'
import { Column, Row, RowSpaced } from 'components/shared'
import SidebarCard from 'components/recordDetails/SidebarCard'
import { Divider } from '@mui/material'
import { BodyText, CaptionText, H3 } from 'components/typography'
import { useQuery } from '@apollo/client'
import { CheckoutDetailsData } from 'types/subscription'
import DateRangeSharpIcon from '@mui/icons-material/DateRangeSharp'
import StyledText from 'components/StyledIntlText'
import { getDurationLabel } from 'pages/pricing/constants'
import { getLocaleTitle } from 'utils/content'
import {
  calculateTotalWithVat,
  calculateVat,
  getMonetaryValue,
} from 'utils/monetary'
import { FontFamily } from 'assets'
import { CountriesData } from 'types/static'
import { COUNTRIES } from 'gql/static'

function OrderSummary({
  subscriptionPlan,
}: {
  subscriptionPlan?: CheckoutDetailsData['checkoutDetails']['subscriptionPlan']
}) {
  const { data: countriesData } = useQuery<CountriesData>(COUNTRIES)
  const theme = useTheme()
  const intl = useIntl()
  return (
    <SidebarCard
      title={intl.formatMessage({ id: 'label.checkoutOrderSummary' })}
      content={[
        { component: <Divider style={{ flex: 1 }} /> },
        {
          component: (
            <Row>
              <DateRangeSharpIcon sx={{ mr: 1 }} color="info" />
              <BodyText>
                {subscriptionPlan?.duration
                  ? `${getDurationLabel(
                      intl,
                      subscriptionPlan?.duration
                    )} ${intl
                      .formatMessage({ id: 'label.subscription' })
                      .toLowerCase()}`
                  : '-'}
              </BodyText>
            </Row>
          ),
        },
        { component: <Divider style={{ flex: 1 }} /> },
        {
          component: (
            <React.Fragment>
              {subscriptionPlan && (
                <Column>
                  <RowSpaced>
                    <CaptionText>
                      {getLocaleTitle(intl, subscriptionPlan)}
                    </CaptionText>
                    <BodyText fontFamily={FontFamily.NoirProMedium}>
                      {getMonetaryValue(subscriptionPlan?.pricing)}
                    </BodyText>
                  </RowSpaced>
                  {subscriptionPlan.extraDuration ? (
                    <RowSpaced>
                      <CaptionText color={theme.palette.primary.main}>
                        {intl.formatMessage(
                          { id: 'label.xExtraMonths' },
                          { number: subscriptionPlan.extraDuration }
                        )}
                      </CaptionText>
                      <BodyText fontFamily={FontFamily.NoirProMedium}>
                        {getMonetaryValue({
                          ...subscriptionPlan?.pricing,
                          value: 0,
                        })}
                      </BodyText>
                    </RowSpaced>
                  ) : (
                    <React.Fragment />
                  )}
                  <RowSpaced sx={{ mt: '4px' }}>
                    <StyledText
                      text="label.vatTaxCalculation"
                      fontSize={12}
                      fontFamily={FontFamily.OpenSans}
                      injectedValues={{
                        number: subscriptionPlan.pricing.tax.toString(),
                        country:
                          countriesData?.countries[
                            subscriptionPlan?.pricing.countryCode
                          ] ?? '',
                      }}
                    />
                    <BodyText fontFamily={FontFamily.NoirProMedium}>
                      {getMonetaryValue({
                        currency: subscriptionPlan?.pricing.currency,
                        value: calculateVat(
                          subscriptionPlan?.pricing.value,
                          subscriptionPlan.pricing.tax
                        ),
                      })}
                    </BodyText>
                  </RowSpaced>
                </Column>
              )}
            </React.Fragment>
          ),
        },
        { component: <Divider style={{ flex: 1 }} /> },
        {
          component: (
            <Column>
              {subscriptionPlan && (
                <RowSpaced>
                  <H3>{intl.formatMessage({ id: 'label.total' })}</H3>
                  <H3>
                    {getMonetaryValue({
                      currency: subscriptionPlan?.pricing.currency,
                      value: calculateTotalWithVat(
                        subscriptionPlan?.pricing.value,
                        subscriptionPlan.pricing.tax
                      ),
                    })}
                  </H3>
                </RowSpaced>
              )}
            </Column>
          ),
        },
      ]}
    />
  )
}

OrderSummary.defaultProps = {
  subscriptionPlan: undefined,
}

export default OrderSummary
