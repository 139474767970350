import * as React from 'react'
import { styled } from '@mui/material/styles'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Typography from '@mui/material/Typography'
import { CardActionArea, Grid } from '@mui/material'
import { WorkspaceType } from 'types/workspace'
import {
  cardBoxShadow,
  cardBoxShadowHover,
  cardGradient,
} from 'assets/styles/theme'
import appConfig from 'config/app'
import placeholder from 'assets/images/workspacePlaceholder.jpg'
import { getLocaleDescription, getLocaleTitle } from 'utils/content'
import { useIntl } from 'react-intl'

const CardWrapper = styled(Card)(({ theme }) => ({
  position: 'relative',
  padding: '0px',
  borderRadius: '5px',
  boxShadow: cardBoxShadow,
  ':hover': {
    cursor: 'pointer',
    boxShadow: cardBoxShadowHover,
  },
}))

const SelectedCardWrapper = styled(CardWrapper)(({ theme }) => ({
  // border: `1.5px solid ${theme.palette.primary.main}`,
  opacity: 0.95,
}))

const Media = styled('img')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '250px',
  objectFit: 'cover',
}))

const Content = styled(CardContent)({
  display: 'flex',
  flex: 1,
  justifyContent: 'flex-end',
  flexDirection: 'column',
  position: 'absolute',
  bottom: '0',
  width: '100%',
  pointerEvents: 'none',
})

const Title = styled(Typography)(({ theme }) => ({
  fontFamily: 'NoirPro',
  fontSize: '28px',
  fontWeight: 700,
  letterSpacing: '0em',
  textAlign: 'left',
}))

const Subtitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '19px',
  letterSpacing: '0em',
  textAlign: 'left',
  color: theme.palette.text.secondary,
}))

const Overlay = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '0px',
  left: '0px',
  width: '100%',
  height: '100%',
  background: cardGradient,
  // ':hover': {
  //   background: activeCardGradient,
  // },
}))

const CheckCircle = styled(CheckCircleIcon)(({ theme }) => ({
  color: theme.palette.success.main,
  position: 'absolute',
  top: 5,
  right: 5,
  backgroundColor: theme.palette.background.paper,
  borderRadius: '50%',
}))

const HeaderGrid = styled(Grid)(({ theme }) => ({
  right: '10px',
  top: '10px',
  position: 'absolute',
  ':hover': {
    display: 'flex',
  },
}))

interface Props {
  workspaceType: WorkspaceType
  selected?: boolean
  onClick?: (workspaceType: WorkspaceType) => void
}
function WorkspaceCard({ workspaceType, selected, onClick }: Props) {
  const intl = useIntl()
  const [isShown, setIsShown] = React.useState(false)
  const handleClick = () => onClick?.(workspaceType)
  const cover = workspaceType.coverImage?.medium
    ? `${appConfig.media}${workspaceType.coverImage.medium}`
    : placeholder

  const Container = selected ? SelectedCardWrapper : CardWrapper

  return (
    <Container onClick={handleClick}>
      <CardActionArea>
        <Media src={cover} alt="board cover" />
        <Overlay
          onMouseEnter={() => setIsShown(true)}
          onMouseLeave={() => setIsShown(false)}
        />
        <HeaderGrid
          container
          spacing={2}
          sx={isShown ? { display: 'flex' } : {}}
        >
          <Grid item ml={1}>
            {selected && <CheckCircle />}
          </Grid>
        </HeaderGrid>
        <Content>
          <Title variant="h5">{getLocaleTitle(intl, workspaceType)}</Title>
          <Subtitle variant="body2">
            {getLocaleDescription(intl, workspaceType)}
          </Subtitle>
        </Content>
      </CardActionArea>
    </Container>
  )
}

WorkspaceCard.defaultProps = {
  onClick: undefined,
  selected: false,
}
export default WorkspaceCard
