import { DocumentNode, QueryOptions, useMutation } from '@apollo/client'
import { Severity } from 'components/providers/SnackbarHOC'
import { CREATE_EVENT, DELETE_EVENT, UPDATE_EVENT } from 'gql/event'
import {
  CreateEventData,
  CreateEventInput,
  DeleteEventData,
  DeleteEventInput,
  UpdateEventData,
  UpdateEventInput,
} from 'types/event'

export function useMutations(
  methods: {
    showSnackbar?: (message: string, severity: Severity) => void
    onCompleted: () => void
  },
  refetchQueries?: Array<string | DocumentNode | QueryOptions>
) {
  const { showSnackbar, onCompleted } = methods
  const [updateEvent, { loading: updateEventLoading }] = useMutation<
    UpdateEventData,
    UpdateEventInput
  >(UPDATE_EVENT, {
    onCompleted,
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries,
  })

  const [addEventRecord, { loading: addEventLoading }] = useMutation<
    CreateEventData,
    CreateEventInput
  >(CREATE_EVENT, {
    onCompleted,
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries,
  })

  const [deleteEvent, { loading: deletingEvent }] = useMutation<
    DeleteEventData,
    DeleteEventInput
  >(DELETE_EVENT, {
    onCompleted,
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries,
  })

  const loading = updateEventLoading || addEventLoading || deletingEvent
  return {
    loading,
    mutations: {
      updateEvent,
      addEventRecord,
      deleteEvent,
    },
  }
}
