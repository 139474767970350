const colors = {
  appPrimary: '#38C6BD',
  textPrimary: '#000000',
  textSecondary: '#767676',
  white: '#FEFFFE',
  black: '#000000',
  yellow: 'FFC007',
}

export default colors
