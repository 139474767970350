import { isValidEmail, isValidText } from 'utils/validators'
import { InputType, Type } from 'components/input/types'
import { NavigateFunction } from 'react-router-dom'

export const inputs = (navigate: NavigateFunction): InputType[] => [
  {
    key: 'email',
    label: 'label.emailAddress',
    type: Type.EMAIL,
    autoComplete: 'email',
    autoFocus: true,
    fullWidth: true,
    margin: 'normal',
    validators: [
      {
        method: isValidText,
        label: 'label.required',
      },
      {
        method: isValidEmail,
        label: 'label.invalidEmail',
      },
    ],
  },
]
