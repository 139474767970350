import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate, useLocation } from 'react-router-dom'
import Toolbar from '@mui/material/Toolbar'
import StyledText from 'components/StyledIntlText'
import ROUTES from 'lib/routes'
import { useLazyQuery } from '@apollo/client'
import { Button, Row } from 'components/shared'
import LoadingIndicator from 'components/LoadingIndicator'
import { saveAuth } from 'lib/localStorage'
import { LOGIN } from 'gql/auth'
import { LoginData, LoginInput } from 'types/auth'
import { Severity, withSnackbar } from 'components/providers/SnackbarHOC'
import InputRender, { formHasErrors } from 'components/input/InputRender'
import { FormDataType } from 'components/input/types'
import { inputs } from './constants'
import MuiHeader from 'pages/landing/MuiHeader'
import { Box } from '@mui/material'
import { itemsnestFull } from 'assets/images'
import { BodyTextLight } from 'components/typography'
import { Container, Form, LinkButton, RightGrid, ScreenGrid } from '../fragments'
import QuotesGrid from '../QuotesGrid'

interface LoginProps {
  showSnackbar?: (message: string, severity: Severity) => void
}

function LoginScreen(props: LoginProps) {
  const { showSnackbar } = props
  const intl = useIntl()
  const location = useLocation()
  const navigate = useNavigate()
  const isDashboard = location.pathname.includes(ROUTES.DASHBOARD)
  const [showErrors, setShowErrors] = useState(false)
  const [data, setData] = useState<FormDataType>({ email: '', password: '' })

  const [loginUser, { loading }] = useLazyQuery<LoginData, LoginInput>(LOGIN, {
    onCompleted: (response) => {
      if (response && response.login) {
        saveAuth(response.login)
        navigate(ROUTES.DASHBOARD, { replace: true })
      }
    },
    onError: (error) => {
      showSnackbar?.(error.message, Severity.ERROR)
    },
  })

  const formInputs = inputs(navigate)
  const handleOnsubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (formHasErrors(formInputs, data)) {
      setShowErrors(true)
      return
    }
    const payload = {
      email: data.email as string,
      password: data.password as string,
    }
    loginUser({ variables: { payload } })
  }

  return (
    <Container>
      {!isDashboard && (
        <React.Fragment>
          <MuiHeader />
          <Toolbar />
        </React.Fragment>
      )}
      <LoadingIndicator visible={loading} />
      <ScreenGrid container>
        <QuotesGrid />
        <RightGrid item md={6} xs={12}>
          <Form noValidate onSubmit={handleOnsubmit}>
            <StyledText text="label.login.welcome" fontSize={30} />
            <Box height="15px" />
            {formInputs.map((input) => (
              <InputRender
                key={input.key}
                data={data}
                input={input}
                inputs={formInputs}
                setData={setData}
                showErrors={showErrors}
              />
            ))}
            <br />
            <Button type="submit" fullWidth variant="contained" color="primary">
              {intl.formatMessage({ id: 'label.signIn' })}
            </Button>
            <LinkButton to={ROUTES.REGISTER}>
              {intl.formatMessage({ id: 'login.dontHaveAccount' })}
            </LinkButton>
          </Form>

          <Row style={{ marginTop: '50px' }}>
            <BodyTextLight opacity=".5" style={{ marginRight: '8px' }}>
              {intl.formatMessage({ id: 'label.poweredBy' })}
            </BodyTextLight>
            <img alt="Itemsnest logo" src={itemsnestFull} />
          </Row>
        </RightGrid>
      </ScreenGrid>
    </Container>
  )
}

LoginScreen.defaultProps = {
  showSnackbar: undefined,
}
export default withSnackbar(LoginScreen)
