import { Address } from './shared'
import { SubscriptionExtended } from './subscription'
import { Workspace } from './workspace'

export enum OrganizationType {
  personal = 'PERSONAL', // for individual users
  family = 'FAMILY', // for families
  business = 'BUSINESS', // for companies
  unspecified = 'UNSPECIFIED', // for not-selected organizations
}

export enum OrganizationRoles {
  owner = 'OWNER', // only 1 owner per organization (the creator of the organization)
  admin = 'ADMIN',
  member = 'MEMBER',
}

export interface Organization {
  id: string
  type: OrganizationType
  referenceNumber: string
  addressId?: string
  address?: Address
  country: string
  company?: {
    name: string
    description?: string
  }
  defaultOptions: {
    currency: string
    measurementSystem: 'METRIC' | 'IMPERIAL'
  }
}

export interface CreateOrganizationData {
  createOrganization: Organization
}

export interface CreateOrganizationInput {
  payload: {
    type: OrganizationType
  }
}

export interface OrganizationDataQuery {
  organizationData: OrganizationExtended
}

export interface OrganizationWorkspacesData {
  organizationWorkspaces: Workspace[]
}

export interface UpdateOrganizationInput {
  payload: {
    id: string
    data: {
      addressId?: string
      company?: {
        name: string
      }
      defaultOptions?: {
        currency?: string
        measurementSystem?: string
      }
    }
  }
}

export interface UpdateOrganizationData {
  updateOrganization: Organization
}

export interface OrganizationExtended extends Organization {
  subscriptions: SubscriptionExtended[]
}
