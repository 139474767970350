import React, { useState } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { styled, useTheme } from '@mui/material/styles'
import { CATEGORIES } from 'gql/category'
import { Severity, withSnackbar } from 'components/providers/SnackbarHOC'
import { CategoriesData } from 'types/category'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import {
  ADD_ITEM_TO_CONTAINER,
  ADD_ITEM_TO_GROUP,
  ADD_ITEM_TO_NEST,
  CREATE_ITEM,
} from 'gql/item'
import { useFileUpload } from 'hooks/api/fileUpload'
import {
  AddItemToContainerData,
  AddItemToGroupData,
  AddItemToNestData,
  CreateItemData,
  CreateItemInput,
} from 'types/item'
import { AddFileToItemData, FileType } from 'types/archive'
import { GROUP_ITEMS } from 'gql/group'
import DropZone from 'components/dropzone/Dropzone'
import { useIntl } from 'react-intl'
import { useQuery, useMutation } from '@apollo/client'
import { step1Inputs } from 'pages/dashboard/items/create/constants'
import Box from '@mui/material/Box'
import { ADD_FILE_TO_ITEM } from 'gql/archive'
import { FileEntry } from 'types/shared'
import InputRender, { formHasErrors } from 'components/input/InputRender'
import { FormDataType } from 'components/input/types'
import {
  ModalTitle,
  RowSpaced,
  Row,
  Button,
  SimpleButton,
} from 'components/shared'
import { NEST_BY_ID } from 'gql/nest'
import { CONTAINER_BY_ID } from 'gql/container'
import { Content, Drawer } from './components'

const Form = styled('form')(({ theme }) => ({
  justifyContent: 'center',
  alignSelf: 'center',
  alignItems: 'center',
  padding: '20px',
  width: '80%',
}))

const Header = styled(RowSpaced)(({ theme }) => ({
  padding: '10px 20px',
}))

const ButtonWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  marginTop: theme.spacing(4),
}))

interface Action {
  label: string
  onClick: () => void
}

interface Props {
  showSnackbar?: (message: string, severity: Severity) => void
  open: boolean
  workspaceId: string
  nestId?: string
  containerId?: string
  groupId?: string
  toggleDrawer: () => void
  onComplete?: () => void
  actions?: Action[]
}

function ItemCreateDrawer(props: Props) {
  const {
    open,
    toggleDrawer,
    workspaceId,
    nestId,
    containerId,
    groupId,
    showSnackbar,
    onComplete,
    actions,
  } = props
  const intl = useIntl()
  const theme = useTheme()
  const [files, setFiles] = useState<FileEntry[]>()
  const [showErrors, setShowErrors] = useState(false)
  const [data, setData] = useState<FormDataType>({
    title: '',
    description: '',
    categoryId: '',
  })

  const resetState = () => {
    setFiles([])
    setData({ title: '', description: '', categoryId: '' })
  }
  const { uploadImages } = useFileUpload()

  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const handleOnClose = () => {
    toggleDrawer()
    setFiles(undefined)
  }
  const { data: categoriesData } = useQuery<CategoriesData>(CATEGORIES)
  const categories = categoriesData?.categories
  const [addFileToItem] = useMutation<AddFileToItemData>(ADD_FILE_TO_ITEM)

  const onUpload = async (itemId: string, images: FileEntry[]) => {
    uploadImages(images, workspaceId, {
      onCompleted: (archiveIds) => {
        archiveIds.forEach((archiveId) => {
          const payload = { itemId, archiveId }
          addFileToItem({ variables: { payload } })
        })
        resetState()
        onComplete?.()
      },
    })
  }

  const [addItemToGroup] = useMutation<AddItemToGroupData>(ADD_ITEM_TO_GROUP, {
    refetchQueries: [{ query: GROUP_ITEMS, variables: { groupId } }],
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
  })
  const [addItemToNest] = useMutation<AddItemToNestData>(ADD_ITEM_TO_NEST, {
    refetchQueries: [{ query: NEST_BY_ID, variables: { nestId } }],
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
  })
  const [addItemToContainer] = useMutation<AddItemToContainerData>(
    ADD_ITEM_TO_CONTAINER,
    {
      refetchQueries: [{ query: CONTAINER_BY_ID, variables: { containerId } }],
      onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    }
  )

  const [createItem] = useMutation<CreateItemData, CreateItemInput>(
    CREATE_ITEM,
    {
      onCompleted: (response) => {
        const item = response.createItem
        const itemId = item.id
        if (groupId) {
          const payload = { groupId, itemId }
          addItemToGroup({ variables: { payload } })
        } else if (nestId) {
          const payload = { nestId, itemId }
          addItemToNest({ variables: { payload } })
        } else if (containerId) {
          const payload = { containerId, itemId }
          addItemToContainer({ variables: { payload } })
        }

        // then check file upload
        if (!files || files.length === 0) {
          resetState()
          onComplete?.()
        } else {
          onUpload(itemId, files)
        }
      },
      onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    }
  )

  const inputs = step1Inputs(intl, categories ?? []).questions
  const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (formHasErrors(inputs, data)) {
      setShowErrors(true)
      return
    }
    const payload = {
      title: data.title as string,
      description: (data.description as string) || undefined,
      categoryId: (data.categoryId as string) || undefined,
      workspaceId,
    }
    createItem({ variables: { payload } })
  }
  return (
    <Drawer anchor="right" open={open} onClose={handleOnClose}>
      <Content>
        <Header>
          <Row>
            <ModalTitle>
              {intl.formatMessage({ id: 'label.createNewItem' })}
            </ModalTitle>
            {actions?.map((action) => (
              <SimpleButton key={action.label}>{action.label}</SimpleButton>
            ))}
          </Row>
          {isMobileScreen && (
            <IconButton onClick={handleOnClose}>
              <CloseIcon />
            </IconButton>
          )}
        </Header>

        <Form noValidate onSubmit={handleOnSubmit}>
          {inputs.map((input) => {
            return (
              <InputRender
                key={input.key}
                data={data}
                input={input}
                inputs={inputs}
                setData={setData}
                showErrors={showErrors}
              />
            )
          })}
          <Box m={2}>
            <DropZone
              files={files ?? []}
              setFiles={setFiles}
              showThumbnails
              dropzoneTitle={intl.formatMessage({ id: 'label.selectImages' })}
              fileType={FileType.image}
              clipboardEnabled={false}
            />
          </Box>
          <ButtonWrapper>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={formHasErrors(inputs, data)}
            >
              {intl.formatMessage({ id: 'label.createItem' })}
            </Button>
          </ButtonWrapper>
        </Form>
      </Content>
    </Drawer>
  )
}

ItemCreateDrawer.defaultProps = {
  nestId: null,
  containerId: null,
  onComplete: null,
  actions: null,
  showSnackbar: undefined,
  groupId: null,
}

// @ts-ignore
export default withSnackbar(ItemCreateDrawer)
