import React from 'react'
import { styled } from '@mui/material/styles'
import { Column } from './shared'
import emptyList from 'assets/images/emptyList.svg'
import { H5 } from './typography'
import { useIntl } from 'react-intl'

const EmptyListImage = styled('img')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  objectFit: 'contain',
  height: '120px',
}))

const Container = styled(Column)(({ theme }) => ({
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
}))

interface Props {
  label?: string
}

function EmptyList({ label }: Props) {
  const intl = useIntl()

  return (
    <Container>
      <EmptyListImage src={emptyList} />
      <H5 style={{ marginTop: '4px' }}>
        {label ?? intl.formatMessage({ id: 'label.noRecords' })}
      </H5>
    </Container>
  )
}

EmptyList.defaultProps = {
  label: undefined,
}

export default EmptyList
