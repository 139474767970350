import { useState, useEffect } from 'react'

export function useScrollPercentage() {
  const [scrollPercent, setPercentage] = useState(0)

  useEffect(() => {
    const onScroll = () => {
      const h = document.documentElement
      const b = document.body
      const st = 'scrollTop'
      const sh = 'scrollHeight'

      const percent = Math.floor(
        ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100
      )

      setPercentage(percent)
    }
    // clean up code
    window.removeEventListener('scroll', onScroll)
    window.addEventListener('scroll', onScroll, { passive: true })
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  return [scrollPercent]
}
