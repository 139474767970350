import React from 'react'
import { styled } from '@mui/material/styles'
import appConfig from 'config/app'
import { Group } from 'types/group'
import { IntlShape, useIntl } from 'react-intl'
import { StatusChip } from 'components/cards'
import { getDate } from 'utils/datetime'
import ContentSection from 'components/recordDetails/ContentSection'
import { Section } from './constants'

const CoverImage = styled('img')(({ theme }) => ({
  width: '100%',
  height: '200px',
  objectFit: 'cover',
  [theme.breakpoints.down('md')]: {
    height: '150px',
  },
}))

const SectionContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: '5px',
  marginTop: '16px',
  overflow: 'hidden',
}))

const getContent = (record: Group, intl: IntlShape) => {
  const data: { label: string; value: string }[] = []
  data.push({
    label: intl.formatMessage({ id: 'label.title' }),
    value: record.title,
  })
  record.description &&
    data.push({
      label: intl.formatMessage({ id: 'label.description' }),
      value: record.description,
    })
  return data
}

interface Props {
  group: Group
  onEdit: (section: Section) => void
}
export function GroupDetailsHeadline({ group, onEdit }: Props) {
  const handleEdit = () => onEdit(Section.general)
  const intl = useIntl()

  return (
    <SectionContainer>
      {group?.coverImage?.full && (
        <CoverImage src={`${appConfig.media}${group?.coverImage?.full}`} />
      )}
      {group.deletedAt && (
        <StatusChip
          style={{ marginLeft: '10px' }}
          color="error"
          label={intl.formatMessage(
            { id: 'label.deletedAtDate' },
            { date: getDate(group.deletedAt) }
          )}
        />
      )}
      <ContentSection
        section={{
          title: intl.formatMessage({ id: 'label.general' }),
          content: getContent(group, intl),
        }}
        onEdit={handleEdit}
      />
    </SectionContainer>
  )
}
