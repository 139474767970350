import React from 'react'
import { PageDescription } from 'components/pages'
import { styled, useTheme } from '@mui/material/styles'
import { PageSubTitle } from 'components/pages'
import { useIntl } from 'react-intl'
import image from 'assets/images/maintenance.svg'
import { H1 } from 'components/typography'

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  minHeight: '100vh',
  justifyContent: 'center',
  alignItems: 'center',
}))
const Content = styled('div')(({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  maxWidth: '600px',
}))

const Img = styled('img')(({ theme }) => ({
  height: '100px',
  objectFit: 'contain',
  marginBottom: '30px',
}))

function UnderMaintenance() {
  const intl = useIntl()
  const theme = useTheme()

  const textStyle = {
    textAlign: 'center',
    color: theme.palette.text.primary,
  }

  return (
    <Root>
      <Content>
        <Img src={image} />
        <H1 sx={textStyle}>
          {intl.formatMessage({ id: 'label.maintenance.title' })}
        </H1>
        <PageSubTitle sx={textStyle}>
          {intl.formatMessage({ id: 'label.maintenance.subtitle' })}
        </PageSubTitle>
        <PageDescription sx={textStyle}>
          {intl.formatMessage({ id: 'label.maintenance.content' })}
        </PageDescription>
      </Content>
    </Root>
  )
}
export default UnderMaintenance
