import { makeVar } from '@apollo/client'

// eslint-disable-next-line
export const chatSessionVar: any = makeVar(null)
export const purchaseSuccessfulVar = makeVar<boolean>(false)

const resetReactiveVars = () => {
  chatSessionVar(null)
  purchaseSuccessfulVar(false)
}

// Description: This snippet is used to setup the reactive variables.
export const initStore = () => {
  resetReactiveVars()
}
