import * as React from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import { IntlShape, useIntl } from 'react-intl'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { getCurrencyLabel } from 'utils/monetary'
import { H4 } from 'components/typography'
import { FontFamily } from 'assets'
import {
  SubscriptionExtended,
  SubscriptionOrderExtended,
} from 'types/subscription'
import { getDate } from 'utils/datetime'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.shared.darkGray,
    color: theme.palette.text.primary,
    fontFamily: FontFamily.NoirProMedium,
  },
  padding: 8,
}))

function createData(order: SubscriptionOrderExtended, intl: IntlShape) {
  return {
    id: order.id,
    orderNumber: order.orderNumber,
    date: getDate(order.date),
    invoices: order.invoices.length ?? 0,
    history: order.invoices,
  }
}

interface Props {
  subscription: SubscriptionExtended
}
function BillingTable({ subscription }: Props) {
  const intl = useIntl()
  const [open, setOpen] = React.useState(false)
  const toggleRow = () => setOpen(!open)
  const head = [
    {
      key: 'orderNumber',
      label: intl.formatMessage({ id: 'label.orderNumber' }),
    },
    { key: 'date', label: intl.formatMessage({ id: 'label.date' }) },
    {
      key: 'invoices',
      label: intl.formatMessage({ id: 'label.numberOfInvoices' }),
    },
  ]

  const rows = subscription.orders.map((order) => createData(order, intl))

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead onClick={toggleRow}>
          <TableRow>
            <StyledTableCell />
            {head.map((item, index) => (
              <StyledTableCell
                key={item.key}
                align={index === head.length - 1 ? 'right' : 'center'}
              >
                {item.label}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.orderNumber} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

interface RowProps {
  row: ReturnType<typeof createData>
}

function Row({ row }: RowProps) {
  const intl = useIntl()
  const [open, setOpen] = React.useState(false)
  const toggleRow = () => setOpen(!open)

  const head = [
    {
      key: 'invoiceNumber',
      label: intl.formatMessage({ id: 'label.invoiceNumber' }),
    },
    { key: 'date', label: intl.formatMessage({ id: 'label.date' }) },
    { key: 'price', label: intl.formatMessage({ id: 'label.price' }) },
    { key: 'status', label: intl.formatMessage({ id: 'label.status' }) },
  ]

  const rows = row.history

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <StyledTableCell>
          <IconButton aria-label="expand row" size="small" onClick={toggleRow}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>

        <StyledTableCell align="left">{row.orderNumber}</StyledTableCell>
        <StyledTableCell align="center">{row.date}</StyledTableCell>
        <StyledTableCell align="right">{row.invoices}</StyledTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <H4>{intl.formatMessage({ id: 'label.invoices' })}</H4>
              <Table size="small" aria-label="invoices">
                <TableBody>
                  {/* Invoices table */}
                  <Table aria-label="collapsible table">
                    <TableHead onClick={toggleRow}>
                      <TableRow>
                        {head.map((item, index) => (
                          <StyledTableCell
                            key={item.key}
                            align={
                              index === head.length - 1 ? 'right' : 'center'
                            }
                          >
                            {item.label}
                          </StyledTableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((invoiceRow) => (
                        <TableRow
                          key={invoiceRow.id}
                          sx={{ '& > *': { borderBottom: 'unset' } }}
                        >
                          <StyledTableCell align="center">
                            {invoiceRow.invoiceNumber}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {getDate(invoiceRow.date)}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {invoiceRow.pricing.totalAmount}{' '}
                            {getCurrencyLabel(invoiceRow.pricing.currency)}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {intl.formatMessage({
                              id: `subscription.invoiceStatus.${invoiceRow.status}`,
                              defaultMessage: invoiceRow.status,
                            })}
                          </StyledTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </StyledTableCell>
      </TableRow>
    </React.Fragment>
  )
}

export default BillingTable
