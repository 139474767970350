import React from 'react'
import { Link } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Row, RowSpaced } from './shared'
import { BodyText, H6 } from './typography'

export const PageGrid = styled(Grid)(({ theme }) => ({}))
export const PageGridLeft = styled(Grid)(({ theme }) => ({}))
export const PageGridRight = styled(Grid)(({ theme }) => ({}))

export const StickySection = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    position: 'sticky',
    top: `calc(2 * ${
      theme.mixins.toolbar.height || theme.mixins.toolbar.minHeight
    }px)`,
  },
}))

export const PageSubTitle = styled('h2')(({ theme }) => ({
  fontFamily: 'NoirPro',
  fontWeight: 500,
  fontSize: '28px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '18px',
    lineHeight: '20px',
  },
}))

export const PageDescription = styled('span')(({ theme }) => ({
  fontFamily: 'Open Sans',
  fontSize: '14px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 3,
  lineClamp: 3,
  WebkitBoxOrient: 'vertical',
}))

export const SectionTitleWrapper = styled(RowSpaced)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.text.disabled}`,
  marginBottom: '12px',
  paddingBottom: '12px',
}))

export const SimpleGridCardContent = styled(Grid)(({ theme }) => ({
  padding: '20px',
}))

export const SectionLabel = styled(BodyText)(({ theme }) => ({
  order: 0,
  marginBottom: '8px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  lineClamp: 1,
  WebkitBoxOrient: 'vertical',
}))

export const DangerSectionLabel = styled(SectionLabel)(({ theme }) => ({
  color: theme.palette.text.secondary,
}))

export const SectionLink = styled('a')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '19px',
  order: 0,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  lineClamp: 1,
  WebkitBoxOrient: 'vertical',
}))

const HeaderLabel = styled(H6)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  lineHeight: '97.5%',
  order: 0,
}))

const HeaderRecordLabel = styled(HeaderLabel)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 400,
  color: theme.palette.text.secondary,
}))

const HeaderIcon = styled(ArrowForwardIosIcon)(({ theme }) => ({
  fontSize: '14px',
  margin: '0 px',
}))

const Breadcrumb = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.text.primary,
  padding: '5px 0',
}))

interface PageHeaderProps {
  pageTitle: string
  recordTitle: string
  breadcrumbLink: string
}

export function PageHeader({
  pageTitle,
  recordTitle,
  breadcrumbLink,
}: PageHeaderProps) {
  return (
    <Row sx={{ margin: '28px 0 38px 0' }}>
      <HeaderLabel>
        <Breadcrumb to={breadcrumbLink}>{pageTitle}</Breadcrumb>
      </HeaderLabel>
      <HeaderRecordLabel>
        <HeaderIcon />
        {recordTitle}
      </HeaderRecordLabel>
    </Row>
  )
}

// --------------------------------------SECTION CONTENT--------------------------------
