import React from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { CONFIRM_EMAIL } from 'gql/auth'
import { Link, useParams } from 'react-router-dom'
import { ConfirmEmailData, ConfirmEmailInput } from 'types/auth'
import LoadingIndicator from 'components/LoadingIndicator'
import { Container, Content } from 'components/shared'
import AppHeader from 'pages/landing/MuiHeader'
import Toolbar from '@mui/material/Toolbar'
import { Severity, withSnackbar } from 'components/providers/SnackbarHOC'
import { BodyText, H4 } from 'components/typography'
import { useIntl } from 'react-intl'
import AppFooter from 'components/AppFooter'
import { styled } from '@mui/material/styles'
import { PROFILE } from 'gql/user'
import { ProfileData } from 'types/profile'
import ROUTES from 'lib/routes'

const MessageWrapper = styled('div')(({ theme }) => ({
  maxWidth: '60vw',
  paddingTop: '100px',
}))
interface Props {
  showSnackbar?: (message: string, severity: Severity) => void
}
function ConfirmEmail({ showSnackbar }: Props) {
  const intl = useIntl()
  const { token } = useParams() as { token: string }
  const { data: profile } = useQuery<ProfileData>(PROFILE)
  const [confirmEmail, { data, loading }] = useMutation<
    ConfirmEmailData,
    ConfirmEmailInput
  >(CONFIRM_EMAIL, {
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: [{ query: PROFILE }],
  })

  React.useEffect(() => {
    if (token) confirmEmail({ variables: { token } })
  }, [token]) // run once

  const confirmed = data?.confirmEmail?.status === 'success'
  let message = null
  if (!loading) {
    if (confirmed) message = 'label.emailConfirmationSuccessful'
    else message = 'label.emailConfirmationFailed'
  }

  const loggedId = !!profile?.profile.id
  const link = loggedId ? ROUTES.DASHBOARD : ROUTES.LOGIN
  const text = loggedId ? 'label.backToDashboard' : 'label.backToLogin'

  return (
    <Container>
      <AppHeader />
      <Toolbar />
      <LoadingIndicator visible={loading} />
      <Content>
        <MessageWrapper>
          {message && <H4>{intl.formatMessage({ id: message })}</H4>}
          <br />
          <Link to={link} style={{ textDecoration: 'none' }}>
            <BodyText>{intl.formatMessage({ id: text })}</BodyText>
          </Link>
        </MessageWrapper>
      </Content>
      <AppFooter />
    </Container>
  )
}

export default withSnackbar(ConfirmEmail)

ConfirmEmail.defaultProps = {
  showSnackbar: undefined,
}
