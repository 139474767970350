import { boxezzFull, butlerzzFull, logoFull } from 'assets/images'

export const aboutUsContent = {
  title: 'label.about.products.title',
  products: [
    {
      color: '#00B09B',
      cta: 'label.about.products.itemzz.cta',
      points: [
        "label.about.products.itemzz.point1",
        "label.about.products.itemzz.point2",
        "label.about.products.itemzz.point3",
        "label.about.products.itemzz.point4",
      ],
      chip: 'label.beta',
      logo: logoFull,
      disabled: false,
    },
    {
      color: '#129EF0',
      cta: 'label.about.products.boxezz.cta',
      chip: 'label.comingSoon',
      logo: boxezzFull,
      disabled: true,
      points: [
        "label.about.products.boxezz.point1",
        "label.about.products.boxezz.point2",
      ],
    },
    {
      color: '#7D4FFF',
      cta: 'label.about.products.butlerzz.cta',
      chip: 'label.comingSoon',
      logo: butlerzzFull,
      disabled: true,
      points: [
        "label.about.products.butlerzz.point1",
        "label.about.products.butlerzz.point2",
      ],
    },
  ],
}
