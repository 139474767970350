import React from 'react'
import { useIntl } from 'react-intl'
import {
  Grid,
  Chip,
  Autocomplete,
  FormHelperText,
  SelectChangeEvent,
  AutocompleteInputChangeReason,
  ButtonBase,
  Box,
  InputAdornment,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useMediaQuery } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import Checkbox from '@mui/material/Checkbox'
import DropZone from 'components/dropzone/Dropzone'
import { FileEntry } from 'types/shared'
import { DATE_FORMAT } from 'lib/constants'
import { FileType } from 'types/archive'
import { H5, H6 } from 'components/typography'
import {
  defaultPriceValue,
  processPriceInput,
  getOptionValue,
  getAutocompleteValue,
  getHelperText,
  getErrorLabel,
  getUnitErrorLabel,
} from './utils'
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  MultiSelectBox,
  UnitFormControl,
  UnitTextField,
  FormGroup,
  FormControlLabel,
  DateSuggestionsSection,
  FieldColumn,
  LabelWrapper,
  CountryFlag,
  DatePicker,
} from './fragments'
import {
  InputType,
  FormDataType,
  isString,
  isDate,
  Type,
  Option,
} from './types'

export const formHasErrors = (
  inputs: InputType[],
  data: FormDataType
): boolean => {
  const inputsCast = inputs.filter((item) =>
    // eslint-disable-next-line no-prototype-builtins
    item.hasOwnProperty('visible') ? item.visible?.(data) : true
  )
  let hasErrors = false
  inputsCast.forEach((input) => {
    const { validators, unitValidators } = input
    validators?.forEach((validator) => {
      if (
        !validator?.method(data[input.key]?.toString(), data, input.options)
      ) {
        hasErrors = true
      }
    })

    unitValidators?.forEach((validator) => {
      if (!validator?.method(data[input.unitKey ?? ''], data, input.options)) {
        hasErrors = true
      }
    })
  })
  return hasErrors
}

interface Props {
  input: InputType
  inputs: InputType[]
  data: FormDataType
  files?: FileEntry[]
  setFiles?: React.Dispatch<React.SetStateAction<FileEntry[] | undefined>>
  setData: (data: FormDataType) => void
  showErrors: boolean
  handleInputFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
}

function InputRender(props: Props) {
  const {
    inputs,
    input,
    data,
    showErrors,
    setData,
    handleInputFocus,
    files,
    setFiles,
  } = props
  const theme = useTheme()
  const intl = useIntl()
  const isBigScreen = useMediaQuery(theme.breakpoints.up('sm'))
  const helperText = getHelperText(input, inputs, intl, data, showErrors)
  const unitHelperText =
    showErrors &&
    !!getUnitErrorLabel(inputs, data, input.key) &&
    intl.formatMessage({
      id: getUnitErrorLabel(inputs, data, input.key),
    })

  // eslint-disable-next-line no-prototype-builtins
  if (input.hasOwnProperty('visible') && !input.visible?.(data))
    return <React.Fragment />

  const handleOnInputChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setData({ ...data, [event.target.name]: event.target.value })
  const handleOnPriceInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let price = '0.00'
    try {
      price = processPriceInput(event.target.value)
    } catch (e) {
      // do nothing, not a number
      return
    }
    setData({ ...data, [event.target.name]: price })
  }
  const handleOnAutocompleteChange = (
    _: React.SyntheticEvent,
    selection:
      | string
      | {
          value: string
          label: string
        }
      | null,
    reason: string
  ) => {
    const value = getOptionValue(selection)
    if (isString(value) || reason === 'clear') {
      setData({ ...data, [input.key]: value ?? null })
    }
  }

  const handleOnAutocompleteInputChange = (
    _event: React.SyntheticEvent,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    if ((reason === 'input' && isString(value)) || reason === 'clear') {
      setData({ ...data, [input.key]: value ?? null })
    }
  }

  const handleOnTimeChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setData({ ...data, [input.key]: event.target.value })
  const handleOnSelectChange = (event: SelectChangeEvent) =>
    setData({ ...data, [input.key]: event.target.value })
  const handleOnUnitSelectChange = (event: SelectChangeEvent) =>
    setData({ ...data, [input.unitKey ?? '']: event.target.value })
  const handleOnDateChange = (date: string[] | null) => {
    setData({ ...data, [input.key]: date as string[] })
  }
  const handleOnSuggestionChange = (date: Date) =>
    setData({ ...data, [input.key]: date })
  const handleOnCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setData({ ...data, [input.key]: event.target.checked })
  const handleOnMultiSelectChange = (event: SelectChangeEvent) =>
    setData({
      ...data,
      [input.key]:
        typeof value === 'string'
          ? event.target.value.split(',')
          : event.target.value,
    })

  const value = data[input.key]
  const unitValue = input.unitKey ? data[input.unitKey] : undefined

  switch (input.type) {
    case Type.SINGLE_SELECT:
      if (input.options?.length === 0) return null
      return (
        <FormControl
          fullWidth={input.fullWidth}
          id={input.key}
          key={input.key}
          margin={input.margin}
          variant="outlined"
          disabled={input.disabled}
        >
          <FieldColumn>
            <H5>{intl.formatMessage({ id: input.label })}</H5>
            <Select
              value={isString(value) ? value : undefined}
              // label={intl.formatMessage({ id: input.label })}
              // @ts-ignore
              onChange={handleOnSelectChange}
            >
              {input.options?.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {input.useTranslation
                    ? intl.formatMessage({
                        id: `input.${input.key}.${option.value}`,
                        defaultMessage: option.label,
                      })
                    : option.label}
                </MenuItem>
              ))}
            </Select>
          </FieldColumn>
          {helperText && (
            <FormHelperText error={showErrors}>{helperText}</FormHelperText>
          )}
        </FormControl>
      )
    case Type.MULTI_SELECT:
      if (input.options?.length === 0) return null
      return (
        <FormControl
          fullWidth={input.fullWidth}
          id={input.key}
          key={input.key}
          margin={input.margin}
          variant="outlined"
        >
          <FieldColumn>
            <H5>{intl.formatMessage({ id: input.label })}</H5>
            <Select
              multiple
              value={value as string}
              // @ts-ignore
              onChange={handleOnMultiSelectChange}
              renderValue={(selected) => (
                <MultiSelectBox>
                  {(selected as string[]).map((item) => (
                    <Chip
                      key={item}
                      color="primary"
                      label={
                        input.useTranslation
                          ? intl.formatMessage({
                              id: `input.${input.key}.${item}`,
                              defaultMessage: item,
                            })
                          : input.options?.find(
                              (option) => option.value === item
                            )?.label
                      }
                    />
                  ))}
                </MultiSelectBox>
              )}
            >
              {input.options?.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {input.useTranslation
                    ? intl.formatMessage({
                        id: `input.${input.key}.${option.value}`,
                        defaultMessage: option.label,
                      })
                    : option.label}
                </MenuItem>
              ))}
            </Select>
          </FieldColumn>
          {helperText && (
            <FormHelperText error={showErrors}>{helperText}</FormHelperText>
          )}
        </FormControl>
      )
    case Type.CHECKBOX:
      return (
        <FormGroup>
          <FormControlLabel
            disabled={input.disabled}
            control={
              <Checkbox
                checked={value as boolean}
                onChange={handleOnCheckboxChange}
              />
            }
            label={
              <H5 disabled={input.disabled}>
                {intl.formatMessage({ id: input.label })}
              </H5>
            }
          />
          {helperText && (
            <FormHelperText error={showErrors}>{helperText}</FormHelperText>
          )}
        </FormGroup>
      )
    case Type.DATE:
      return (
        <React.Fragment>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <FieldColumn>
              <H5>{intl.formatMessage({ id: input.label })}</H5>
              <DatePicker
                value={isDate(value) ? value : null}
                format={DATE_FORMAT}
                // @ts-expect-error
                onChange={handleOnDateChange}
                disableFuture={input.disableFuture}
                disablePast={input.disablePast}
                slotProps={{
                  textField: {
                    fullWidth: input.fullWidth,
                    margin: input.margin,
                    variant: 'outlined',
                    error:
                      showErrors && !!getErrorLabel(inputs, data, input.key),
                    helperText: helperText,
                  },
                }}
                maxDate={input.maxDate}
                minDate={input.minDate}
              />
            </FieldColumn>
          </LocalizationProvider>
          <DateSuggestionsSection
            suggestions={input.suggestions}
            data={data}
            visible={!data[input.key]}
            onSubmit={handleOnSuggestionChange}
          />
        </React.Fragment>
      )
    case Type.TIME:
      return (
        <TextField
          id={input.key}
          label={intl.formatMessage({
            id: input.label,
            defaultMessage: input.label,
          })}
          type="time"
          onChange={handleOnTimeChange}
          fullWidth={isBigScreen ? input.fullWidth : true}
          margin={input.margin}
          InputLabelProps={{ shrink: true }}
          inputProps={{ step: 300 }} // 5 min
          value={isString(value) ? value : undefined}
          error={showErrors && !!getErrorLabel(inputs, data, input.key)}
          helperText={helperText}
          variant="filled"
        />
      )
    case Type.UNIT_SELECT: // text field with unit dropdown
      if (input.options?.length === 0) return null
      return (
        <FieldColumn>
          <H5>{intl.formatMessage({ id: input.label })}</H5>
          <Grid
            container
            spacing={0}
            alignItems="flex-start"
            direction="row"
            key={input.key}
          >
            <Grid item xs={8}>
              <UnitTextField
                id={input.key}
                fullWidth={isBigScreen ? input.fullWidth : true}
                margin={input.margin}
                name={input.key}
                multiline={input.multiline}
                type="number"
                autoComplete={input.autoComplete}
                value={isString(value) ? value : undefined}
                onChange={handleOnInputChange}
                error={showErrors && !!getErrorLabel(inputs, data, input.key)}
                helperText={helperText}
                variant="outlined"
                disabled={input.disabled}
              />
            </Grid>
            {unitValue !== undefined && (
              <Grid item xs={4}>
                <UnitFormControl
                  fullWidth={input.fullWidth}
                  id={input.unitKey}
                  margin={input.margin}
                  variant="outlined"
                  hiddenLabel
                  disabled={input.disabled}
                  error={!!unitHelperText}
                >
                  <Select
                    value={isString(unitValue) ? unitValue : undefined}
                    // @ts-ignore
                    onChange={handleOnUnitSelectChange}
                  >
                    {input.options?.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {unitHelperText && (
                    <FormHelperText>{unitHelperText}</FormHelperText>
                  )}
                </UnitFormControl>
              </Grid>
            )}
          </Grid>
        </FieldColumn>
      )
    case Type.PRICE: // similar to unit-select input with price template _,__$
      if (input.options?.length === 0) return null
      return (
        <FieldColumn>
          <H5>{intl.formatMessage({ id: input.label })}</H5>
          <Grid container spacing={0} direction="row" key={input.key}>
            <Grid item xs={10}>
              <UnitTextField
                id={input.key}
                fullWidth={isBigScreen ? input.fullWidth : true}
                margin={input.margin}
                name={input.key}
                multiline={input.multiline}
                autoComplete={input.autoComplete}
                value={isString(value) ? defaultPriceValue(value) : '0.00'}
                onChange={handleOnPriceInputChange}
                error={showErrors && !!getErrorLabel(inputs, data, input.key)}
                helperText={helperText}
                variant="outlined"
                disabled={input.disabled}
              />
            </Grid>
            {unitValue !== undefined && (
              <Grid item xs={2}>
                <UnitFormControl
                  fullWidth={input.fullWidth}
                  id={input.unitKey}
                  margin={input.margin}
                  variant="outlined"
                  hiddenLabel
                  disabled={input.disabled}
                  error={!!unitHelperText}
                >
                  <Select
                    value={isString(unitValue) ? unitValue : undefined}
                    // @ts-ignore
                    onChange={handleOnUnitSelectChange}
                  >
                    {input.options?.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {unitHelperText && (
                    <FormHelperText>{unitHelperText}</FormHelperText>
                  )}
                </UnitFormControl>
              </Grid>
            )}
          </Grid>
        </FieldColumn>
      )
    case Type.AUTOCOMPLETE:
    case Type.COUNTRY:
      return (
        <FieldColumn>
          <H5>
            {intl.formatMessage({
              id: input.label,
              defaultMessage: input.label,
            })}
          </H5>
          <Autocomplete
            key={input.key}
            id={input.key}
            fullWidth={isBigScreen ? input.fullWidth : true}
            disablePortal
            onChange={handleOnAutocompleteChange}
            onInputChange={
              input.freeSolo ? handleOnAutocompleteInputChange : undefined
            }
            options={input.options ?? []}
            freeSolo={input.freeSolo}
            noOptionsText={intl.formatMessage({
              id: input.noOptionsText ?? 'label.noOptions',
            })}
            getOptionDisabled={(option: Option) => !!option?.disabled}
            value={getAutocompleteValue(input, value)}
            // @ts-ignore
            renderInput={(params) => (
              <TextField
                {...params}
                id={input.key}
                name={input.key}
                error={showErrors && !!getErrorLabel(inputs, data, input.key)}
                helperText={helperText}
                variant="outlined"
                // onFocus={handleInputFocus}
                margin={input.margin}
              />
            )}
            // Overrides for country picker
            {...(input.type === Type.COUNTRY
              ? {
                  renderOption: (_props, option: Option) => (
                    <Box
                      component="li"
                      sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                      {..._props}
                    >
                      <CountryFlag
                        loading="lazy"
                        src={`https://flagcdn.com/w20/${option.value.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option.value.toLowerCase()}.png 2x`}
                        alt=""
                      />
                      {option.label}
                    </Box>
                  ),
                  renderInput: (params) => (
                    <TextField
                      {...params}
                      id={input.key}
                      name={input.key}
                      error={
                        showErrors && !!getErrorLabel(inputs, data, input.key)
                      }
                      helperText={helperText}
                      autoComplete="new-password" // disable autocomplete and autofill
                      variant="outlined"
                      margin={input.margin}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            {data?.[input.key] && (
                              <CountryFlag
                                loading="lazy"
                                src={`https://flagcdn.com/w20/${data?.[
                                  input.key
                                ]
                                  ?.toString()
                                  ?.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${data?.[
                                  input.key
                                ]
                                  ?.toString()
                                  ?.toLowerCase()}.png 2x`}
                                alt=""
                              />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  ),
                }
              : {})}
          />
        </FieldColumn>
      )
    case Type.DROPZONE:
      if (!setFiles) return null
      return (
        <FieldColumn>
          <DropZone
            files={files ?? []}
            setFiles={setFiles}
            showThumbnails
            dropzoneTitle={intl.formatMessage({
              id: input.label,
              defaultMessage: input.label,
            })}
            fileType={input.fileType || FileType.image}
            clipboardEnabled={false}
          />
        </FieldColumn>
      )

    default:
      return (
        <FieldColumn>
          <LabelWrapper>
            <H5>
              {intl.formatMessage({
                id: input.label,
                defaultMessage: input.label,
              })}
            </H5>
            {input.action ? (
              <ButtonBase
                onClick={input.action.onClick}
                disabled={input.disabled}
              >
                <H6 color={theme.palette.primary.main}>
                  {intl.formatMessage({
                    id: input.action.label,
                    defaultMessage: input.action.label,
                  })}
                </H6>
              </ButtonBase>
            ) : null}
          </LabelWrapper>
          <TextField
            key={input.key}
            id={input.key}
            fullWidth={isBigScreen ? input.fullWidth : true}
            margin={input.margin}
            name={input.key}
            multiline={input.multiline}
            type={input.type}
            autoComplete={input.autoComplete}
            value={isString(value) ? value : undefined}
            onChange={handleOnInputChange}
            error={showErrors && !!getErrorLabel(inputs, data, input.key)}
            helperText={helperText}
            variant="outlined"
            onFocus={handleInputFocus}
            disabled={input.disabled}
          />
        </FieldColumn>
      )
  }
}
InputRender.defaultProps = {
  files: [],
  setFiles: null,
  handleInputFocus: null,
}
export default InputRender
