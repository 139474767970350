import React, { useState, useCallback } from 'react'
import Typography from '@mui/material/Typography'
import { Severity, withSnackbar } from 'components/providers/SnackbarHOC'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import { PROFILE, UPDATE_PROFILE } from 'gql/user'
import appConfig from 'config/app'
import {
  ProfileData,
  UpdateProfileData,
  UpdateProfileInput,
} from 'types/profile'
import { useFileUpload } from 'hooks/api/fileUpload'
import { format } from 'date-fns'
import { useIntl } from 'react-intl'
import IconButton from '@mui/material/IconButton'
import LoadingIndicator from 'components/LoadingIndicator'
import { Container, Content, Row } from 'components/shared'
import {
  PageGrid,
  PageGridLeft,
  PageGridRight,
  PageHeader,
  StickySection,
} from 'components/pages'
import { Pencil, Trash as TrashIcon } from 'assets/svg'
import {
  clearSession,
  getActiveWorkspaceId,
  saveActiveWorkspaceId,
} from 'lib/sessionStorage'
import { useUserRequests } from 'hooks/graphql/userRequest'
import DangerSectionRender from 'components/content/DangerSectionRender'
import { styled } from '@mui/material/styles'
import PageLinksCard from 'components/pages/PageLinksCard'
import UserReportCard from 'components/pages/UserReportCard'
import FormModal from 'components/recordDetails/FormModal'
import { SuggestionSection } from 'components/recordDetails/SuggestionsSection'
import { FileEntry, JsonObject } from 'types/shared'
import { H3 } from 'components/typography'
import { DELETE_ACCOUNT } from 'gql/auth'
import { DeleteAccountData } from 'types/auth'
import {
  ConfirmationData,
  withConfirmationModal,
} from 'components/hoc/ConfirmationModalHoc'
import ROUTES from 'lib/routes'
import { useNavigate } from 'react-router-dom'
import { UserRequestReferenceModel } from 'types/userRequest'
import UserRequestsSection from 'components/content/UserRequestsSection'
import { USER_REQUESTS } from 'gql/userRequest'
import { ProfileAvatar } from './fragments'
import {
  getEditInputs,
  getExtraInputs,
  getInitialState,
  getSectionWithId,
  payloadBuilder,
  Section,
} from './constants'

const SectionContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: '5px',
  margin: '16px 0 20px 0',
}))
const SimpleGridCardContent = styled('div')(({ theme }) => ({
  padding: '20px',
}))

interface Props {
  showSnackbar?: (message: string, severity: Severity) => void
  requestConfirmation?: (data: ConfirmationData) => void
}
function Profile({ showSnackbar, requestConfirmation }: Props) {
  const intl = useIntl()
  const client = useApolloClient()
  const navigate = useNavigate()
  const { data, loading } = useQuery<ProfileData>(PROFILE)
  const workspaceId = getActiveWorkspaceId()
  const [editingId, setEditingId] = useState<string>()
  const [editing, setEditing] = useState<SuggestionSection>()
  const hideEditingModal = useCallback(() => setEditing(undefined), [])
  const { uploadAvatars } = useFileUpload()
  const handleRecordUpdate = (formData: JsonObject, sectionId?: string) => {
    const payload = payloadBuilder(formData, sectionId)
    updateProfile({ variables: { payload } })
  }

  const handleSignOut = () => {
    clearSession()
    saveActiveWorkspaceId(null)
    client.clearStore()
    navigate(ROUTES.INDEX, { replace: true })
  }

  const handleOnUpdate = async (
    formData: JsonObject,
    sectionId?: string,
    files?: FileEntry[]
  ) => {
    const dropzoneKey =
      inputs.find((input) => input.type === 'dropzone')?.key ?? ''
    // ws cover
    const cover = files?.[0]
    if (cover) {
      await uploadAvatars(
        [cover],
        workspaceId ?? '',
        {
          onCompleted: (archiveIds) => {
            handleRecordUpdate(
              { ...formData, [dropzoneKey]: archiveIds[0] },
              sectionId
            )
          },
          onError: (errors) => {
            if (errors.length > 0)
              showSnackbar?.(
                intl.formatMessage({ id: 'label.avatarUploadFailed' }),
                Severity.ERROR
              )
          },
        },
        true // skipBoardMapping
      )
    } else {
      handleRecordUpdate(formData, sectionId)
    }
  }

  const { data: userRequests, loading: userRequestsLoading } = useUserRequests(
    {}
  )
  const [deleteAccount] = useMutation<DeleteAccountData>(DELETE_ACCOUNT, {
    onCompleted: handleSignOut,
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: [{ query: PROFILE }],
  })
  const [updateProfile, { loading: updateLoading }] = useMutation<
    UpdateProfileData,
    UpdateProfileInput
  >(UPDATE_PROFILE, {
    onCompleted: hideEditingModal,
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: [{ query: PROFILE }],
  })

  const handleOnEditWithId = (sectionId: string, recordId?: string) => {
    const section = getSectionWithId(sectionId, intl)
    setEditingId(recordId)
    setEditing(section)
  }

  const handleAccountDeletion = () =>
    requestConfirmation?.({
      title: intl.formatMessage({ id: 'label.accountDeletion' }),
      description: intl.formatMessage({
        id: 'label.accountDeletionWarning',
      }),
      actions: [
        {
          label: intl.formatMessage({ id: 'label.cancel' }),
        },
        {
          label: intl.formatMessage({ id: 'label.delete' }),
          color: 'error',
          onClick: deleteAccount,
        },
      ],
    })

  const profile = data?.profile
  const avatar = profile?.avatar?.avatar
    ? `${appConfig.media}${profile?.avatar?.avatar}`
    : ''
  const isLoading = loading || updateLoading || userRequestsLoading
  const inputs = profile ? getEditInputs(editing?.id ?? '') : []
  const extraInputs = getExtraInputs(editing?.id ?? '')
  const initialState =
    profile && editing ? getInitialState(profile, editing.id, editingId) : {}
  return (
    <Container detailPage>
      <Content detailPage>
        <LoadingIndicator visible={isLoading} />
        <PageGrid container spacing={6}>
          <PageGridLeft item xs={12} sm={12} md={12} lg={7} xl={8}>
            {profile && (
              <PageHeader
                pageTitle={intl.formatMessage({ id: 'label.profile' })}
                recordTitle={`${profile?.firstName ?? ''} ${
                  profile?.lastName ?? ''
                }`}
                breadcrumbLink=""
              />
            )}

            <SectionContainer
              sx={{
                mt: 8,
                position: 'relative',
                overflow: 'visible',
              }}
            >
              <ProfileAvatar
                src={avatar}
                onClick={() => handleOnEditWithId(Section.avatar)}
              />
              <SimpleGridCardContent>
                <Row>
                  <H3>{intl.formatMessage({ id: 'label.profile' })}</H3>
                  <IconButton
                    onClick={() => handleOnEditWithId(Section.general)}
                  >
                    <Pencil size="24px" />
                  </IconButton>
                </Row>
                <Typography paragraph>
                  {profile?.firstName} {profile?.lastName}
                </Typography>
                <Typography paragraph>{profile?.email}</Typography>
                {profile?.gender && (
                  <Typography paragraph>
                    {intl.formatMessage({
                      id: `input.gender.${profile?.gender}`,
                      defaultMessage: profile?.gender,
                    })}
                  </Typography>
                )}
                {profile?.birthday && (
                  <Typography paragraph>
                    {profile?.birthday
                      ? format(new Date(profile?.birthday), 'dd/MM/yyyy')
                      : '-'}
                  </Typography>
                )}
              </SimpleGridCardContent>
            </SectionContainer>

            <UserRequestsSection userRequests={userRequests} />

            <DangerSectionRender
              entries={[
                {
                  title: intl.formatMessage({ id: 'label.dangerZone' }),
                  actions: [
                    {
                      ariaLabel: 'delete account',
                      icon: (props) => <TrashIcon {...props} />,
                      onPress: handleAccountDeletion,
                      label: intl.formatMessage({
                        id: 'label.deleteAccount',
                      }),
                      infoLabel: intl.formatMessage({
                        id: 'label.deletingAccountText',
                      }),
                    },
                  ],
                },
              ]}
            />
          </PageGridLeft>

          <PageGridRight item xs={12} sm={12} md={12} lg={5} xl={4}>
            <StickySection>
              <PageLinksCard />
              <UserReportCard
                refetchQueries={[
                  {
                    query: USER_REQUESTS,
                    variables: { payload: {} },
                  },
                ]}
                showSnackbar={showSnackbar}
                referenceData={
                  workspaceId
                    ? {
                        referenceId: workspaceId,
                        referenceModel: UserRequestReferenceModel.WORKSPACE,
                      }
                    : undefined
                }
              />
            </StickySection>
          </PageGridRight>
        </PageGrid>
      </Content>
      <FormModal
        toggleModal={hideEditingModal}
        inputs={inputs}
        extraInputs={extraInputs}
        section={editing}
        initialState={initialState}
        onSubmit={handleOnUpdate}
        singleFileSelect
      />
    </Container>
  )
}

Profile.defaultProps = {
  showSnackbar: undefined,
  requestConfirmation: undefined,
}
export default withConfirmationModal(withSnackbar(Profile))
