export enum Environment {
  development = 'development',
  test = 'test',
  production = 'production',
}

const host = process.env.REACT_APP_HOST
const paymentClientId = process.env.REACT_APP_PAYPAL_CLIENT_ID ?? ''
// const mapsKey = process.env.REACT_APP_MAPS_KEY ?? '-'
// const chatAppId = process.env.REACT_APP_CHAT_APP_ID ?? '-'
const sentryDns = process.env.REACT_APP_SENTRY_DSN ?? ''
const environment: Environment = (process.env.REACT_APP_ENVIRONMENT ??
  Environment.development) as Environment

const config = {
  graphql: `${host}/graphql`,
  api: `${host}/api`,
  media: `${host}/uploads`,
  documents: `${host}/documents`,
  documentPreview: `${host}/api/v1/preview`,
  // mapsKey,
  // chatAppId,
  sentryDns,
  environment,
  paymentClientId,
}
export default config
