import { gql } from '@apollo/client'

export const PROFILE = gql`
  query PROFILE {
    profile {
      id
      firstName
      lastName
      email
      birthday
      gender
      confirmed
      createdAt
      updatedAt
      avatar {
        avatar
      }
    }
  }
`

export const UPDATE_PROFILE = gql`
  mutation UPDATE_PROFILE($payload: UpdateUserInput!) {
    updateProfile(payload: $payload) {
      status
    }
  }
`

export const WORKSPACE_ROLE = gql`
  query WORKSPACE_ROLE($workspaceId: ID!) {
    workspaceRole(workspaceId: $workspaceId) {
      organizationRole
      workspaceRole
      workspaceMembership {
        id
        userId
        email
        workspaceId
        firstName
        lastName
        role
        status
      }
    }
  }
`

export const LEAVE_WORKSPACE = gql`
  mutation LEAVE_WORKSPACE($membershipId: ID!) {
    leaveWorkspace(membershipId: $membershipId) {
      status
    }
  }
`
