import { createTheme } from '@mui/material/styles'

export const cardGradient =
  'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 91.54%);'
// 'linear-gradient(180deg, rgba(0, 0, 0, 0) 37.29%, rgba(0, 0, 0, 0.6) 100%)'
// export const activeCardGradient =
//   'linear-gradient(180deg, rgba(0, 0, 0, 0.3) 3.12%, rgba(0, 0, 0, 0) 37.29%, rgba(0, 0, 0, 0.6) 100%)'

export const cardBoxShadow = '0px 0px 5px 0px #00000026'
export const cardBoxShadowHover = '0px 0px 15px 0px #00000026'

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#00B09B',
      contrastText: '#FEFFFE',
    },
    secondary: {
      main: '#F9F9F9',
      contrastText: '#707070',
    },
    background: {
      default: '#FEFFFE',
      paper: '#FEFFFE',
    },
    text: {
      primary: '#0B3954',
      secondary: '#757F89',
      disabled: '#CDD2D8',
    },
    action: {
      active: '#0B3954',
      // hover: '#757F89',
    },
    error: {
      main: '#E49999',
      light: '#F9EBEB',
      contrastText: '#FEFFFE',
    },
    shared: {
      bgGray: '#FAFAFA',
      lightGray: '#F6F6F6',
      darkGray: '#EBEBEB',
      red: '#FF6663',
      lightRed: '#FFE8E8',
      lightGreen: '#EAFFFC',
      yellow: '#E0FF4F',
      lightBlue: '#DCF2FF',
    },
  },
  typography: {
    button: {
      textTransform: 'none',
      fontFamily: 'NoirPro',
    },
  },
})

interface SharedColors {
  bgGray: string
  lightGray: string
  darkGray: string
  red: string
  lightRed: string
  lightGreen: string
  lightBlue: string
  yellow: string
}
declare module '@mui/material/styles' {
  interface Palette {
    shared: SharedColors
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    shared?: SharedColors
  }
}

export default theme
