import {
  DocumentNode,
  QueryOptions,
  useMutation,
  useQuery,
} from '@apollo/client'
import { Severity } from 'components/providers/SnackbarHOC'
import { LEAVE_WORKSPACE, WORKSPACE_ROLE } from 'gql/user'
import {
  ARCHIVE_WORKSPACE,
  GROUPED_WORKSPACES,
  UNARCHIVE_WORKSPACE,
  UPDATE_WORKSPACE,
  WORKSPACE_BY_ID,
} from 'gql/workspace'
import {
  LeaveWorkspaceData,
  LeaveWorkspaceInput,
  WorkspaceRoleData,
  WorkspaceRoleInput,
} from 'types/user'
import {
  ArchiveWorkspaceData,
  ArchiveWorkspaceInput,
  UnarchiveWorkspaceData,
  UpdateWorkspaceData,
  UpdateWorkspaceInput,
  WorkspaceByIdData,
} from 'types/workspace'

export function useData(workspaceId: string) {
  const { data, loading } = useQuery<WorkspaceByIdData>(WORKSPACE_BY_ID, {
    variables: { workspaceId },
  })

  const { data: wsRole, loading: rolesLoading } = useQuery<
    WorkspaceRoleData,
    WorkspaceRoleInput
  >(WORKSPACE_ROLE, { variables: { workspaceId } })

  const roles = wsRole?.workspaceRole
  const isLoading = rolesLoading || loading
  const workspace = data?.workspaceById
  return { loading: isLoading, data: { workspace, roles } }
}

export function useWsMutations(
  workspaceId: string,
  methods: {
    refetchQueries?: Array<string | DocumentNode | QueryOptions>
    awaitRefetchQueries?: boolean
    showSnackbar?: (message: string, severity: Severity) => void
  }
) {
  const { showSnackbar, refetchQueries } = methods
  const onArchiveRefetch = [
    { query: GROUPED_WORKSPACES },
    { query: WORKSPACE_BY_ID, variables: { workspaceId } },
    ...(refetchQueries || []),
  ]

  const [archiveWorkspace, { loading: archiveLoading }] = useMutation<
    ArchiveWorkspaceData,
    ArchiveWorkspaceInput
  >(ARCHIVE_WORKSPACE, {
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: onArchiveRefetch,
  })

  const [unarchiveWorkspace, { loading: unArchiveLoading }] = useMutation<
    UnarchiveWorkspaceData,
    ArchiveWorkspaceInput // same as above
  >(UNARCHIVE_WORKSPACE, {
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: onArchiveRefetch,
  })
  const loading = archiveLoading || unArchiveLoading
  return {
    loading,
    mutations: {
      archiveWorkspace,
      unarchiveWorkspace,
    },
  }
}

export function useMutations(
  workspaceId: string,
  methods: {
    showSnackbar?: (message: string, severity: Severity) => void
    hideEditingModal: () => void
    onLeftWorkspace: () => void
  }
) {
  const { showSnackbar, hideEditingModal, onLeftWorkspace } = methods

  const {
    mutations: { archiveWorkspace, unarchiveWorkspace },
    loading: wsArchiveLoading,
  } = useWsMutations(workspaceId, {
    showSnackbar,
  })
  const [updateWorkspace, { loading: updateLoading }] = useMutation<
    UpdateWorkspaceData,
    UpdateWorkspaceInput
  >(UPDATE_WORKSPACE, {
    onCompleted: hideEditingModal,
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: [{ query: GROUPED_WORKSPACES }],
  })

  const [leaveWorkspace, { loading: leaving }] = useMutation<
    LeaveWorkspaceData,
    LeaveWorkspaceInput
  >(LEAVE_WORKSPACE, {
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: [{ query: GROUPED_WORKSPACES }],
    onCompleted: onLeftWorkspace,
  })

  const loading = wsArchiveLoading || updateLoading || leaving
  return {
    loading,
    mutations: {
      archiveWorkspace,
      unarchiveWorkspace,
      updateWorkspace,
      leaveWorkspace,
    },
  }
}
