import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import ROUTES from 'lib/routes'
import { Box, Toolbar } from '@mui/material'
import { useLazyQuery } from '@apollo/client'
import { Button, Row } from 'components/shared'
import LoadingIndicator from 'components/LoadingIndicator'
import { Severity, withSnackbar } from 'components/providers/SnackbarHOC'
import InputRender, { formHasErrors } from 'components/input/InputRender'
import { FormDataType } from 'components/input/types'
import { REQUEST_PASSWORD_RESET } from 'gql/email'
import { BodyTextLight, H5 } from 'components/typography'
import {
  RequestPasswordResetData,
  RequestPasswordResetInput,
} from 'types/email'
import { inputs } from './constants'
import {
  Container,
  LinkButton,
  RightGrid,
  Form,
  ScreenGrid,
  SuccessMessageWrapper,
} from '../fragments'
import MuiHeader from 'pages/landing/MuiHeader'
import QuotesGrid from '../QuotesGrid'
import StyledText from 'components/StyledIntlText'
import { itemsnestFull } from 'assets/images'

interface LoginProps {
  showSnackbar?: (message: string, severity: Severity) => void
}

function Page(props: LoginProps) {
  const { showSnackbar } = props
  const intl = useIntl()
  const navigate = useNavigate()
  const location = useLocation()
  const isDashboard = location.pathname.includes(ROUTES.DASHBOARD)
  const [showErrors, setShowErrors] = useState(false)
  const [data, setData] = useState<FormDataType>({ email: '' })
  const [success, setSuccess] = useState(false)

  const [requestPasswordReset, { loading }] = useLazyQuery<
    RequestPasswordResetData,
    RequestPasswordResetInput
  >(REQUEST_PASSWORD_RESET, {
    onCompleted: (response) => {
      if (response.requestPasswordReset.status === 'success') {
        showSnackbar?.(
          intl.formatMessage({ id: 'label.emailSent' }),
          Severity.SUCCESS
        )
        setSuccess(true)
      }
    },
    onError: (error) => {
      showSnackbar?.(error.message, Severity.ERROR)
    },
  })

  const formInputs = inputs(navigate)
  const handleOnsubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (formHasErrors(formInputs, data)) {
      setShowErrors(true)
      return
    }
    const variables = { email: data.email as string }
    requestPasswordReset({ variables })
  }

  return (
    <Container>
      {!isDashboard && (
        <React.Fragment>
          <MuiHeader />
          <Toolbar />
        </React.Fragment>
      )}
      <LoadingIndicator visible={loading} />
      <ScreenGrid container>
        <QuotesGrid />
        <RightGrid item md={6} xs={12}>
          <Box height="15px" />
          {success ? (
            <SuccessMessageWrapper>
              <StyledText text="label.forgotPasswordUnderline" fontSize={30} />
              <BodyTextLight opacity=".5" style={{ marginTop: '20px' }}>
                {intl.formatMessage({
                  id: 'label.resetPasswordDescriptionSent',
                })}
              </BodyTextLight>
              <br />
              <LinkButton to={ROUTES.LOGIN}>
                <H5>{intl.formatMessage({ id: 'label.toLogin' })}</H5>
              </LinkButton>
            </SuccessMessageWrapper>
          ) : (
            <React.Fragment>
              <Form noValidate onSubmit={handleOnsubmit}>
                <StyledText
                  text="label.forgotPasswordUnderline"
                  fontSize={30}
                />
                <br />
                <br />
                <BodyTextLight opacity=".5">
                  {intl.formatMessage({ id: 'label.resetPasswordDescription' })}
                </BodyTextLight>
                {formInputs.map((input) => {
                  return (
                    <InputRender
                      key={input.key}
                      data={data}
                      input={input}
                      inputs={formInputs}
                      setData={setData}
                      showErrors={showErrors}
                    />
                  )
                })}
                <br />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  {intl.formatMessage({ id: 'label.resetPassword' })}
                </Button>
              </Form>
            </React.Fragment>
          )}

          <Row style={{ marginTop: '50px' }}>
            <BodyTextLight opacity=".5" style={{ marginRight: '8px' }}>
              {intl.formatMessage({ id: 'label.poweredBy' })}
            </BodyTextLight>
            <img alt="Itemsnest logo" src={itemsnestFull} />
          </Row>
        </RightGrid>
      </ScreenGrid>
    </Container>
  )
}

Page.defaultProps = {
  showSnackbar: undefined,
}
export default withSnackbar(Page)
