import { InputType, Type } from 'components/input/types'
import { FileType } from 'types/archive'
import { isValidText } from 'utils/validators'

export const step1Inputs: {
  questions: InputType[]
  label: string
} = {
  label: 'label.createItem',
  questions: [
    {
      key: 'title',
      label: 'label.title',
      type: Type.TEXT,
      autoComplete: '',
      autoFocus: false,
      margin: 'dense',
      fullWidth: true,
      validators: [
        {
          method: isValidText,
          label: 'label.required',
        },
      ],
    },
    {
      key: 'contractNumber',
      label: 'label.contractNumber',
      type: Type.TEXT,
      autoComplete: '',
      autoFocus: false,
      margin: 'dense',
      fullWidth: true,
      validators: [],
    },
    {
      key: 'customerNumber',
      label: 'label.customerNumber',
      type: Type.TEXT,
      autoComplete: '',
      autoFocus: false,
      margin: 'dense',
      fullWidth: true,
      validators: [],
    },
    {
      key: 'startDate',
      label: 'label.startDate',
      type: Type.DATE,
      autoComplete: '',
      autoFocus: false,
      margin: 'dense',
      fullWidth: true,
      multiline: true,
      validators: [],
    },
    {
      key: 'archiveIds',
      label: 'label.documents',
      type: Type.DROPZONE,
      margin: 'dense',
      fullWidth: true,
      validators: [],
      fileType: FileType.document,
    },
  ],
}
