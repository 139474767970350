import React from 'react'
import { EventExtended } from 'types/event'
import { IntlShape } from 'react-intl'
import DocumentIcon from '@mui/icons-material/NoteAdd'
import { Severity } from 'components/providers/SnackbarHOC'
import DocumentGrid from 'components/DocumentGrid'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import { TabSection } from 'components/Tabs'
// import TransferIcon from 'assets/svg/ArrowSquareIn'
import { NavigateFunction } from 'react-router-dom'
import SectionRender, {
  Action,
  SectionEntry,
} from 'components/content/SectionRender'
import LinkIcon from '@mui/icons-material/Link'
import Box from '@mui/material/Box'
import Pencil from 'assets/svg/Pencil'
import { ITEM_BY_ID } from 'gql/item'
import { ArchiveFile, FileType } from 'types/archive'
import { CollapsibleTable } from 'components/table'
import { sortByDate } from 'utils/sort'
import { Contract } from 'types/contracts'
import TrashIcon from 'assets/svg/Trash'
import { getContractContent, getEventContent } from 'utils/content'
import { ItemExtended, ItemListing } from 'types/item'
import ROUTES from 'lib/routes'
import VisibilityIcon from '@mui/icons-material/Visibility';
import DangerSectionRender from 'components/content/DangerSectionRender'
import {
  getManufactureContent,
  getOrderContent,
  getSaleInfoContent,
  Section,
} from './constants'
import ExtraInfoSection from './ExtraInfoSection'
import { classifyOrders } from './utils'

const tabs = (
  intl: IntlShape,
  record: ItemExtended | undefined,
  workspaceId: string,
  navigate: NavigateFunction,
  methods: {
    handleOnEditWithId: (sectionId: Section, recordId?: string) => void
    handleOnDeleteEvent: (recordId: string) => void
    handleOnDeleteSalesListing: (recordId: string) => void
    handleOnDeleteOrder: (recordId: string) => void
    showSnackbar?: (message: string, severity: Severity) => void
    showDocUploadModal: () => void
    toggleDelete: () => void
    onEditOrderItemRef: (orderId: string, refId: string) => void
    showEventDetails?: (event: EventExtended) => void
    hideEventDetails?: () => void
  }
): TabSection[] => {
  const {
    showSnackbar,
    handleOnEditWithId,
    handleOnDeleteEvent,
    handleOnDeleteSalesListing,
    showDocUploadModal,
    handleOnDeleteOrder,
    toggleDelete,
    onEditOrderItemRef,
    showEventDetails,
    hideEventDetails,
  } = methods
  const documents =
    record?.archive?.filter((i) => i.type === FileType.document) ?? []

  const orders = sortByDate(record?.orders ?? [], 'date')
  const events = sortByDate(record?.events ?? [], 'date')
  const itemListings = sortByDate(record?.itemListings ?? [])
  const classifiedOrders = classifyOrders(workspaceId, orders)
  const manufactureContent = record
    ? getManufactureContent(workspaceId, record, intl)
    : []

  const manufactureHeaderActions: Action[] = [
    {
      ariaLabel: 'update manufacture info',
      icon: (props) => <Pencil {...props} />,
      onPress: () =>
        handleOnEditWithId(Section.manufacture, record?.manufacture?.id),
    },
  ]

  // document upload only if there is a manufacture record created
  if (record?.manufacture?.id) {
    manufactureHeaderActions.splice(0, 0, {
      ariaLabel: 'add manufacture document',
      onPress: () =>
        handleOnEditWithId(
          Section.manufactureDocuments,
          record?.manufacture?.id
        ),
      icon: () => <DocumentIcon color="primary" fontSize="small" />,
    })
  }

  return [
    {
      key: 'manufacture-info',
      label: 'label.manufactureInfo',
      visible: true,
      content: (
        <SectionRender
          header={intl.formatMessage({ id: 'label.manufactureInfo' })}
          headerActions={manufactureHeaderActions}
          entries={[
            {
              data: manufactureContent,
              content: (
                <React.Fragment>
                  {record?.manufacture?.archive ? (
                    <React.Fragment>
                      <Box height={10} />
                      <DocumentGrid
                        documents={sortByDate<ArchiveFile>(
                          record.manufacture.archive
                        )}
                        elementSize={100}
                        refetchQueries={[
                          {
                            query: ITEM_BY_ID,
                            variables: { itemId: record?.id },
                          },
                        ]}
                      />
                    </React.Fragment>
                  ) : null}
                </React.Fragment>
              ),
            },
          ]}
        />
      ),
    },
    {
      key: 'purchases',
      label: 'label.purchases',
      visible: true,
      content: (
        <SectionRender
          header={intl.formatMessage({ id: 'label.purchases' })}
          headerActions={[
            {
              ariaLabel: 'add purchase',
              icon: () => <AddIcon color="disabled" />,
              onPress: () => handleOnEditWithId(Section.purchase),
            },
          ]}
          entries={
            classifiedOrders.purchases.length > 0
              ? classifiedOrders.purchases.map((order) => ({
                  actions: [
                    {
                      ariaLabel: 'add purchase document',
                      onPress: () =>
                        handleOnEditWithId(Section.orderDocuments, order.id),
                      icon: () => (
                        <DocumentIcon color="primary" fontSize="small" />
                      ),
                    },
                    {
                      ariaLabel: 'edit purchase',
                      icon: (props) => <Pencil {...props} />,
                      onPress: () =>
                        handleOnEditWithId(Section.purchase, order.id),
                    },
                    {
                      ariaLabel: 'delete purchase',
                      onPress: () => handleOnDeleteOrder(order.id),
                      icon: () => <DeleteIcon color="error" fontSize="small" />,
                    },
                  ],
                  data: getOrderContent(order, intl, workspaceId),
                  content: (
                    <React.Fragment>
                      <Box height={10} />
                      <CollapsibleTable
                        order={order}
                        workspaceId={workspaceId}
                        onEditItem={onEditOrderItemRef}
                      />
                      {order.archive ? (
                        <React.Fragment>
                          <Box height={10} />
                          <DocumentGrid
                            documents={sortByDate<ArchiveFile>(order.archive)}
                            elementSize={100}
                            refetchQueries={[
                              {
                                query: ITEM_BY_ID,
                                variables: { itemId: record?.id },
                              },
                            ]}
                          />
                        </React.Fragment>
                      ) : null}
                    </React.Fragment>
                  ),
                }))
              : [{ description: intl.formatMessage({ id: 'label.noData' }) }]
          }
        />
      ),
    },
    {
      key: 'sales',
      label: 'label.sales',
      visible: classifiedOrders.sales.length > 0,
      content: (
        <SectionRender
          header={intl.formatMessage({ id: 'label.sales' })}
          headerActions={[
            {
              ariaLabel: 'add sale',
              icon: () => <AddIcon color="disabled" />,
              onPress: () => handleOnEditWithId(Section.sale),
            },
          ]}
          entries={classifiedOrders.sales.map((order) => ({
            actions: [
              {
                ariaLabel: 'add sale document',
                onPress: () =>
                  handleOnEditWithId(Section.orderDocuments, order.id),
                icon: () => <DocumentIcon color="primary" fontSize="small" />,
              },
              {
                ariaLabel: 'edit sale',
                icon: (props) => <Pencil {...props} />,
                onPress: () => handleOnEditWithId(Section.sale, order.id),
              },
              {
                ariaLabel: 'delete sale',
                onPress: () => handleOnDeleteOrder(order.id),
                icon: () => <DeleteIcon color="error" fontSize="small" />,
              },
            ],
            data: getOrderContent(order, intl, workspaceId),
            content: (
              <React.Fragment>
                <Box height={10} />
                <CollapsibleTable
                  onEditItem={onEditOrderItemRef}
                  order={order}
                  workspaceId={workspaceId}
                />
                {order.archive ? (
                  <React.Fragment>
                    <Box height={10} />
                    <DocumentGrid
                      documents={sortByDate<ArchiveFile>(order.archive)}
                      elementSize={100}
                      refetchQueries={[
                        {
                          query: ITEM_BY_ID,
                          variables: { itemId: record?.id },
                        },
                      ]}
                    />
                  </React.Fragment>
                ) : null}
              </React.Fragment>
            ),
          }))}
        />
      ),
    },
    {
      key: 'extra-info',
      label: 'label.extraInfo',
      visible: true,
      content: (
        <SectionRender>
          <ExtraInfoSection record={record} showSnackbar={showSnackbar} />
        </SectionRender>
      ),
    },
    {
      key: 'item-events',
      label: 'label.events',
      visible: true,
      content: (
        <SectionRender
          header={intl.formatMessage({ id: 'label.events' })}
          headerActions={[
            {
              ariaLabel: 'add event',
              icon: () => <AddIcon color="disabled" />,
              onPress: () => handleOnEditWithId(Section.events),
            },
          ]}
          entries={
            events.length > 0
              ? events.map(
                  (event: EventExtended) =>
                    ({
                      title: event.title,
                      onClick: () => showEventDetails?.(event),
                      description: event.description,
                      data: getEventContent(workspaceId, event, intl),
                      actions: [
                        {
                          ariaLabel: 'see event details',
                          onPress: (
                            e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
                          ) => {
                            showEventDetails?.(event)
                            e?.stopPropagation()
                            e?.preventDefault()
                          },
                          icon: () => (
                            <VisibilityIcon color="disabled" fontSize="small" />
                          ),
                        },
                        {
                          ariaLabel: 'add event document',
                          onPress: (
                            e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
                          ) => {
                            hideEventDetails?.()
                            handleOnEditWithId(Section.eventDocuments, event.id)
                            e?.stopPropagation()
                            e?.preventDefault()
                          },
                          icon: () => (
                            <DocumentIcon color="primary" fontSize="small" />
                          ),
                        },
                        {
                          ariaLabel: 'edit event',
                          onPress: (
                            e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
                          ) => {
                            hideEventDetails?.()
                            handleOnEditWithId(Section.events, event.id)
                            e?.stopPropagation()
                            e?.preventDefault()
                          },
                          //  @ts-ignore
                          icon: (props) => <Pencil {...props} />,
                        },
                        {
                          ariaLabel: 'delete event',
                          onPress: (
                            e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
                          ) => {
                            hideEventDetails?.()
                            handleOnDeleteEvent(event.id)
                            e?.stopPropagation()
                            e?.preventDefault()
                          },
                          icon: () => (
                            <DeleteIcon color="error" fontSize="small" />
                          ),
                        },
                      ],
                    }) as SectionEntry
                )
              : [{ description: intl.formatMessage({ id: 'label.noData' }) }]
          }
        />
      ),
    },
    {
      key: 'item-contracts',
      label: 'label.contracts',
      visible: (record?.contracts?.length ?? 0) > 0,
      content: (
        <SectionRender
          header={intl.formatMessage({ id: 'label.contracts' })}
          entries={record?.contracts?.map((contract: Contract) => ({
            title: contract.title,
            description: contract.description,
            data: getContractContent(contract, intl),
            actions: [
              {
                ariaLabel: 'link to contract',
                onPress: () =>
                  navigate(
                    ROUTES.DASHBOARD_CONTRACT_DETAILS_ROUTE(
                      workspaceId,
                      contract.id
                    )
                  ),
                icon: () => <LinkIcon color="primary" fontSize="small" />,
              },
            ],
          }))}
        />
      ),
    },
    {
      key: 'item-sale-listings',
      label: 'label.salesListing',
      visible: itemListings.length > 0,
      content: (
        <SectionRender
          header={intl.formatMessage({ id: 'label.salesListing' })}
          headerActions={[
            {
              ariaLabel: 'add listing',
              icon: () => <AddIcon color="disabled" />,
              onPress: () => handleOnEditWithId(Section.saleListing),
            },
          ]}
          entries={itemListings.map((listing: ItemListing) => ({
            data: getSaleInfoContent(listing, intl),
            actions: [
              {
                ariaLabel: 'edit listing',
                onPress: () =>
                  handleOnEditWithId(Section.saleListing, listing.id),
                icon: (props) => <Pencil {...props} />,
              },
              {
                ariaLabel: 'delete listing',
                onPress: () => handleOnDeleteSalesListing(listing.id),
                icon: () => <DeleteIcon color="error" fontSize="small" />,
              },
            ],
          }))}
        />
      ),
    },
    {
      key: 'item-documents',
      label: 'label.documents',
      visible: true,
      content: (
        <SectionRender
          header={intl.formatMessage(
            {
              id:
                documents.length > 0
                  ? 'label.documentsCount'
                  : 'label.documents',
            },
            { count: documents.length ?? 0 }
          )}
          headerActions={[
            {
              ariaLabel: 'add document',
              icon: (props) => <AddIcon color="disabled" />,
              onPress: showDocUploadModal,
            },
          ]}
          entries={[
            {
              description:
                documents.length === 0
                  ? intl.formatMessage({ id: 'label.noDocuments' })
                  : undefined,
            },
          ]}
        >
          {documents.length > 0 && (
            <DocumentGrid
              documents={sortByDate<ArchiveFile>(documents)}
              refetchQueries={[
                { query: ITEM_BY_ID, variables: { itemId: record?.id } },
              ]}
            />
          )}
        </SectionRender>
      ),
    },
    {
      key: 'danger-zone',
      label: 'label.dangerZone',
      visible: !!record && !record.deletedAt,
      content: record ? (
        <DangerSectionRender
          entries={[
            {
              title: intl.formatMessage({ id: 'label.dangerZone' }),
              actions: [
                // {
                //   ariaLabel: 'transfer item',
                //   icon: (props) => <TransferIcon {...props} />,
                //   onPress: () => handleOnEditWithId(Section.transferItem),
                //   label: intl.formatMessage({ id: 'label.transfer' }),
                //   infoLabel: intl.formatMessage({ id: 'label.transferItem' }),
                // },
                {
                  ariaLabel: 'delete item',
                  icon: (props) => <TrashIcon {...props} />,
                  onPress: toggleDelete,
                  label: intl.formatMessage({ id: 'label.delete' }),
                  infoLabel: intl.formatMessage({ id: 'label.deleteItem' }),
                },
              ],
            },
          ]}
        />
      ) : null,
    },
  ].filter((section) => section.visible)
}

export default tabs
