import { useMutation, useQuery } from '@apollo/client'
import { Severity } from 'components/providers/SnackbarHOC'
import {
  CREATE_GROUP,
  CREATE_GROUP_FROM_TYPES,
  GROUP_TYPES,
  GROUP_TYPES_COUNTER,
  WORKSPACE_GROUPS,
} from 'gql/group'
import { useWorkspaces } from 'hooks/graphql/workspaces'
import ROUTES from 'lib/routes'
import { useNavigate } from 'react-router-dom'
import {
  CreateGroupData,
  CreateGroupFromTypesData,
  CreateGroupFromTypesInput,
  CreateGroupInput,
  GroupTypeCounterData,
  GroupTypeData,
} from 'types/group'

export function useData(workspaceId: string) {
  const { data: workspaces, loading: wsLoading } = useWorkspaces()
  const activeWs = workspaces.find((w) => w.id === workspaceId)
  const { data: groupData, loading: loadingTypes } = useQuery<GroupTypeData>(
    GROUP_TYPES,
    {
      variables: { organizationId: activeWs?.organizationId },
      skip: !activeWs?.organizationId,
    }
  )
  const { data: groupCounter, loading: counterLoading } =
    useQuery<GroupTypeCounterData>(GROUP_TYPES_COUNTER, {
      variables: { workspaceId },
      skip: !workspaceId,
    })
  const types = groupData?.groupTypes.list ?? []
  const counter = groupCounter?.groupTypesCounter ?? {}
  const loading = wsLoading || loadingTypes || counterLoading
  return { loading, data: { types, counter } }
}

export function useMutations(
  payload: {
    workspaceId: string
  },
  methods: {
    showSnackbar?: (message: string, severity: Severity) => void
  }
) {
  const navigate = useNavigate()
  const { showSnackbar } = methods
  const refetchGroups = [
    {
      query: WORKSPACE_GROUPS,
      variables: {
        payload: { workspaceId: payload.workspaceId, limit: 25, page: 0 },
      },
    },
  ]
  const [createGroupFromTypes] = useMutation<
    CreateGroupFromTypesData,
    CreateGroupFromTypesInput
  >(CREATE_GROUP_FROM_TYPES, {
    onCompleted: () =>
      navigate(ROUTES.DASHBOARD_GROUPS_ROUTE(payload.workspaceId), {
        replace: true,
      }),
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: [
      ...refetchGroups,
      {
        query: GROUP_TYPES_COUNTER,
        variables: { workspaceId: payload.workspaceId },
      },
    ],
  })

  const [createGroup, { loading: createLoading }] = useMutation<
    CreateGroupData,
    CreateGroupInput
  >(CREATE_GROUP, {
    onCompleted: (response) =>
      navigate(
        ROUTES.DASHBOARD_GROUP_DETAILS_ROUTE(
          payload.workspaceId,
          response.createGroup.id
        ),
        { replace: true }
      ),
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: refetchGroups,
  })

  const loading = createLoading
  return {
    loading,
    mutations: {
      createGroup,
      createGroupFromTypes,
    },
  }
}
