import { DocumentNode, QueryOptions, useMutation } from '@apollo/client'
import { Severity } from 'components/providers/SnackbarHOC'
import { CREATE_ADDRESS, UPDATE_ADDRESS } from 'gql/address'
import {
  CreateAddressData,
  AddressInput,
  UpdateAddressData,
  UpdateAddressInput,
} from 'types/address'
import { Address } from 'types/shared'

export function useMutations(
  methods: {
    showSnackbar?: (message: string, severity: Severity) => void
    onCompleted: (response: {
      createAddress?: Address
      updateAddress?: Address
    }) => void
  },
  refetchQueries?: Array<string | DocumentNode | QueryOptions>
) {
  const { showSnackbar, onCompleted } = methods
  const [createAddress, { loading: createLoading }] = useMutation<
    CreateAddressData,
    { payload: AddressInput }
  >(CREATE_ADDRESS, {
    onCompleted,
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries,
  })

  const [updateAddress, { loading: updateLoading }] = useMutation<
    UpdateAddressData,
    UpdateAddressInput
  >(UPDATE_ADDRESS, {
    onCompleted,
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries,
  })

  const loading = createLoading || updateLoading
  return {
    loading,
    mutations: { createAddress, updateAddress },
  }
}
