import { styled, lighten } from '@mui/material/styles'
import Card from '@mui/material/Card'
import { cardBoxShadow, cardBoxShadowHover } from 'assets/styles/theme'
import ImageOutlined from '@mui/icons-material/ImageOutlined'
import AccountIcon from '@mui/icons-material/AccountCircleOutlined'
import Box from '@mui/material/Box'
import { Link } from 'react-router-dom'

const avatarSize = '64px'
export const Container = styled(Card)(({ theme }) => ({
  gap: '4',
  flexDirection: 'row',
  display: 'flex',
  flex: 1,
  padding: '12px',
  //   height: '110px',
  position: 'relative',
  borderRadius: '4px',
  backgroundColor: theme.palette.background.default,
  boxShadow: cardBoxShadow,
  ':hover': {
    cursor: 'pointer',
    boxShadow: cardBoxShadowHover,
  },
}))

export const Content = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  marginLeft: '8px',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
}))

export const AvatarWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  justifyContent: 'center',
  display: 'flex',
  height: avatarSize,
  ':hover svg': {
    visibility: 'visible',
  },
}))

export const Image = styled('img')(({ theme }) => ({
  height: avatarSize,
  width: avatarSize,
  objectFit: 'cover',
  borderRadius: '200px',
}))

export const PhotoChange = styled(ImageOutlined)(({ theme }) => ({
  position: 'absolute',
  color: theme.palette.primary.main,
  opacity: 0.8,
  visibility: 'hidden',
  alignSelf: 'center',
}))

export const AvatarIcon = styled(AccountIcon)(({ theme }) => ({
  height: avatarSize,
  width: avatarSize,
  objectFit: 'cover',
  borderRadius: '200px',
  color: lighten(theme.palette.action.active, 0.3),
  backgroundColor: theme.palette.shared.lightGray,
}))

export const LinkButton = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
}))
