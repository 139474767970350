import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { styled } from '@mui/material/styles'
import { useParams } from 'react-router-dom'
import { Severity, withSnackbar } from 'components/providers/SnackbarHOC'
import LoadingIndicator from 'components/LoadingIndicator'
import GridSelectionModal from 'components/GridSelectionModal'
import Grid from '@mui/material/Grid'
import CreateGroupCard from 'components/CreateGroupCard'
import InputRender, { formHasErrors } from 'components/input/InputRender'
import { FormDataType } from 'components/input/types'
import {
  Container,
  Content,
  Button,
  SectionCTA,
  RowSpaced,
  SectionWrapper,
} from 'components/shared'
import { GroupType } from 'types/group'
import { H1, H3 } from 'components/typography'
import { isGermanLocale } from 'utils/language'
import { inputs } from './constants'
import { useData, useMutations } from './hooks'

const Form = styled('form')(({ theme }) => ({
  justifyContent: 'center',
  alignSelf: 'center',
  alignItems: 'center',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  marginBottom: '80px',
  maxWidth: '600px',
  width: '100%',
  minHeight: '60vh',
}))

const ButtonWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

const ActionBarWrapper = styled('div')(({ theme }) => ({
  bottom: 40,
  display: 'flex',
  position: 'sticky',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    bottom: 20,
  },
}))

interface Props {
  showSnackbar?: (message: string, severity: Severity) => void
}
function CreateGroup(props: Props) {
  const { showSnackbar } = props
  const intl = useIntl()
  const params = useParams() as { workspaceId: string }
  const [showErrors, setShowErrors] = useState(false)
  const [creationFlow, setCreationFlow] = useState<'custom' | 'board'>('board')
  const [selectedBoards, setSelectedBoards] = useState<string[]>([])
  const [data, setData] = useState<FormDataType>({
    title: '',
    description: '',
  })
  const toggleCreationFlow = () =>
    setCreationFlow(creationFlow === 'custom' ? 'board' : 'custom')

  const {
    loading,
    data: { types, counter },
  } = useData(params.workspaceId)

  const {
    loading: mutating,
    mutations: { createGroup, createGroupFromTypes },
  } = useMutations({ workspaceId: params.workspaceId }, { showSnackbar })

  const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (formHasErrors(inputs, data)) {
      setShowErrors(true)
      return
    }
    const payload = {
      title: data.title as string,
      description: (data.description as string) || undefined,
      workspaceId: params.workspaceId,
    }
    createGroup({ variables: { payload } })
  }

  const onTypeSelected = (type: GroupType) => {
    const selected = selectedBoards.includes(type.id)
    if (selected)
      setSelectedBoards(selectedBoards.filter((id) => id !== type.id))
    else setSelectedBoards([...selectedBoards, type.id])
  }

  const clearSelection = () => setSelectedBoards([])
  const createFromTypes = () => {
    const payload = {
      workspaceId: params.workspaceId,
      groupTypes: selectedBoards,
      languageCode: isGermanLocale(intl.locale) ? 'DE' : 'EN',
    }
    createGroupFromTypes({ variables: { payload } })
  }

  const featuredTypes = types
    .filter((t) => t.featured)
    .sort((a, b) => (a.priority ?? 0) - (b.priority ?? 0))
  const otherTypes = types
    .filter((t) => !t.featured)
    .sort((a, b) => (a.priority ?? 0) - (b.priority ?? 0))

  const isLoading = mutating || loading
  const toggleLabel =
    creationFlow === 'custom' ? 'label.selectGroup' : 'label.customGroup'
  return (
    <Container>
      <LoadingIndicator visible={isLoading} />
      <Content>
        <RowSpaced>
          <H1>{intl.formatMessage({ id: 'label.createGroup' })}</H1>
          <SectionCTA color="primary" onClick={toggleCreationFlow}>
            {intl.formatMessage({ id: toggleLabel })}
          </SectionCTA>
        </RowSpaced>

        {creationFlow === 'board' && featuredTypes.length > 0 && (
          <SectionWrapper>
            <H3>{intl.formatMessage({ id: 'label.featured' })}</H3>
            <br />
            <Grid container spacing={2}>
              {featuredTypes.map((type) => (
                <Grid item xs={12} sm={6} md={4} key={type.id}>
                  <CreateGroupCard
                    groupType={type}
                    selected={selectedBoards.includes(type.id)}
                    onClick={onTypeSelected}
                    count={counter[type.id]}
                  />
                </Grid>
              ))}
            </Grid>
          </SectionWrapper>
        )}

        {creationFlow === 'board' && otherTypes.length > 0 && (
          <SectionWrapper>
            <H3>{intl.formatMessage({ id: 'label.others' })}</H3>
            <br />
            <Grid container spacing={2}>
              {otherTypes.map((type) => (
                <Grid item xs={12} sm={6} md={4} key={type.id}>
                  <CreateGroupCard
                    groupType={type}
                    selected={selectedBoards.includes(type.id)}
                    onClick={onTypeSelected}
                    count={counter[type.id]}
                  />
                </Grid>
              ))}
            </Grid>
          </SectionWrapper>
        )}

        {creationFlow === 'board' && selectedBoards.length > 0 && (
          <ActionBarWrapper>
            <GridSelectionModal
              selectedRows={selectedBoards}
              actions={[
                {
                  label: 'label.clearSelection',
                  onClick: clearSelection,
                  variant: 'outlined',
                },
                {
                  label: 'label.save',
                  onClick: createFromTypes,
                  color: 'primary',
                },
              ]}
            />
          </ActionBarWrapper>
        )}

        {creationFlow === 'custom' && (
          <Form noValidate onSubmit={handleOnSubmit}>
            {inputs.map((input) => {
              return (
                <InputRender
                  key={input.key}
                  data={data}
                  input={input}
                  inputs={inputs}
                  setData={setData}
                  showErrors={showErrors}
                />
              )
            })}

            <ButtonWrapper>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={formHasErrors(inputs, data)}
              >
                {intl.formatMessage({ id: 'label.createGroup' })}
              </Button>
            </ButtonWrapper>
          </Form>
        )}
      </Content>
    </Container>
  )
}

CreateGroup.defaultProps = {
  showSnackbar: undefined,
}
export default withSnackbar(CreateGroup)
