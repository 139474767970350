import { gql } from '@apollo/client'

export const WORKSPACE_NESTS = gql`
  query WORKSPACE_NESTS($payload: NestAggregateInput!) {
    workspaceNests(payload: $payload) {
      total
      list {
        id
        title
        description
        workspaceId
        itemCount
        containerCount
        spaceOptions {
          spaceType
          elevation
          features
          securityFeatures
          spaceUsage
        }
        archive {
          id
          type
          media {
            thumbnail
            medium
            full
          }
        }
        members {
          ... on WorkspaceMemberExtended {
            id
            refId
            userId
            email
            workspaceId
            firstName
            lastName
            avatarId
            avatar {
              avatar
            }
            createdAt
            updatedAt
          }

          ... on OrgMembershipExtended {
            id
            refId
            userId
            email
            organizationId
            createdAt
            updatedAt
            user {
              id
              firstName
              lastName
              avatar {
                avatar
              }
            }
          }
        }
      }
    }
  }
`

export const NEST_BY_ID = gql`
  query NEST_BY_ID($nestId: ID!) {
    nestById(nestId: $nestId) {
      id
      title
      description
      workspaceId
      itemCount
      containerCount
      createdAt
      updatedAt
      deletedAt
      spaceOptions {
        spaceType
        elevation
        features
        securityFeatures
        spaceUsage
      }
      archive {
        id
        workspaceId
        type
        path
        media {
          thumbnail
          medium
          full
        }
      }
      items {
        id
        title
        categoryId
        workspaceId
        description
        createdAt
        updatedAt
        deletedAt
        archive {
          id
          type
          media {
            thumbnail
            medium
            full
          }
        }
      }
      members {
        ... on WorkspaceMemberExtended {
          id
          refId
          userId
          email
          workspaceId
          firstName
          lastName
          avatarId
          avatar {
            avatar
          }
          createdAt
          updatedAt
        }

        ... on OrgMembershipExtended {
          id
          refId
          userId
          email
          organizationId
          createdAt
          updatedAt
          user {
            id
            firstName
            lastName
            avatar {
              avatar
            }
          }
        }
      }
    }
  }
`

export const CREATE_NEST = gql`
  mutation CREATE_NEST($payload: CreateNestInput!) {
    createNest(payload: $payload) {
      id
    }
  }
`

export const UPDATE_NEST = gql`
  mutation UPDATE_NEST($payload: UpdateNestInput!) {
    updateNest(payload: $payload) {
      id
    }
  }
`

export const NEST_TYPES = gql`
  query NEST_TYPES {
    nestTypes {
      total
      list {
        id
        title
        description
        titleDE
        descriptionDE
        featuresOptions
        securityFeaturesOptions
        spaceUsageOptions
        elevationOptions
        createdAt
        updatedAt
      }
    }
  }
`

export const SPACE_AVAILABLE_OPTIONS = gql`
  query SPACE_AVAILABLE_OPTIONS {
    spaceAvailableOptions {
      featuresOptions
      securityFeaturesOptions
      spaceUsageOptions
      elevationOptions
    }
  }
`

export const NEST_CONTAINERS = gql`
  query NEST_CONTAINERS($nestId: ID!) {
    nestContainers(nestId: $nestId) {
      id
      title
      workspaceId
      description
      createdAt
      updatedAt
      archive {
        id
        type
        media {
          thumbnail
          medium
          full
        }
      }
    }
  }
`

export const DELETE_NESTS = gql`
  mutation DELETE_NESTS($nestIds: [ID]!) {
    deleteNests(nestIds: $nestIds) {
      status
    }
  }
`
