import React from 'react'
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  from,
  InMemoryCacheConfig,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { clearSession, getAuth, hasSession } from 'lib/localStorage'
import { hasSessionExpired } from 'lib/errorResolver'
import appConfig from 'config/app'
import { initStore } from 'lib/reactiveVars'

const httpLink = createHttpLink({ uri: appConfig.graphql })
const authLink = setContext(({ variables }, { headers }) => {
  const auth = getAuth()
  return {
    headers: {
      ...headers,
      authorization: auth?.token ? `Bearer ${auth.token}` : '',
    },
  }
})

const errorLink = onError((error) => {
  // eslint-disable-next-line
  const { graphQLErrors, networkError } = error
  if (hasSessionExpired(error) && hasSession()) {
    clearSession()
    client.clearStore()
  }

  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      // eslint-disable-next-line
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    )

  // eslint-disable-next-line
  if (networkError) console.log(`[Network error]: ${networkError}`)
})

const options: InMemoryCacheConfig = {
  // Custom policies were added for the Member related fields bellow because the refId field was being mixed between different linked entities.
  // The change consists in making the refId-id field combination as the identifier for this the cache entry.
  typePolicies: {
    WorkspaceMemberExtended: {
      keyFields: ['refId', 'id'],
      fields: {
        refId(incoming = '-') {
          return incoming
        },
      },
    },
    OrgMembershipExtended: {
      keyFields: ['refId', 'id'],
      fields: {
        refId(incoming = '-') {
          return incoming
        },
      },
    },
    ArchiveFile: {
      keyFields: ['refId', 'id'],
      fields: {
        refId(incoming = '-') {
          return incoming
        },
      },
    },
  },
}

const client = new ApolloClient({
  link: from([authLink, errorLink, httpLink]),
  cache: new InMemoryCache(options),
})

initStore()

interface Props {
  children: React.ReactNode
}
function GraphqlProvider({ children }: Props) {
  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default GraphqlProvider
