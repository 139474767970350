import { omit, sortBy, get, isObject } from 'lodash'
import {
  CreateItemExtrasData,
  CreateItemExtrasInput,
  ItemExtras,
  UpdateItemExtrasData,
  UpdateItemExtrasInput,
} from 'types/item'
import { isValidText } from 'utils/validators'
import { IntlShape } from 'react-intl'
import { Type, InputType, Option } from 'components/input/types'
import { Severity } from 'components/providers/SnackbarHOC'
import { FormDataType } from 'components/input/types'
import {
  defaultSizeUnit,
  defaultWeightUnit,
  sizeUnits,
  weightUnits,
} from 'lib/constants'
import {
  isPositiveNumber,
  isValidDate,
  isValidNumber,
  isValidOption,
} from 'utils/validators'
import { ADD_ITEM_EXTRAS, ITEM_BY_ID, UPDATE_ITEM_EXTRAS } from 'gql/item'
import { useMutation } from '@apollo/client'
import { getDate } from 'utils/datetime'

interface ItemExtrasFieldsType {
  [key: string]: {
    type: Type
    unitKey?: string
    options?: Option[]
    validators?: InputType['validators']
    unitValidators?: InputType['unitValidators']
    defaultUnitValue?: string
  }
}

// must contain all the keys of the ItemExtras type that have custom implementation
// this excludes special fields that don't need definitions: id, itemId, customValues
const ItemExtrasFields: ItemExtrasFieldsType = {
  weight: {
    type: Type.UNIT_SELECT,
    unitKey: 'weightUnit',
    options: weightUnits,
    defaultUnitValue: defaultWeightUnit,
    validators: [
      { method: isValidNumber, label: 'label.required' },
      { method: isPositiveNumber, label: 'label.positiveNumberRequired' },
    ],
    unitValidators: [{ method: isValidOption, label: 'label.required' }],
  },
  length: {
    type: Type.UNIT_SELECT,
    options: sizeUnits,
    unitKey: 'lengthUnit',
    defaultUnitValue: defaultSizeUnit,
    validators: [
      { method: isValidNumber, label: 'label.required' },
      { method: isPositiveNumber, label: 'label.positiveNumberRequired' },
    ],
    unitValidators: [{ method: isValidOption, label: 'label.required' }],
  },
  height: {
    type: Type.UNIT_SELECT,
    unitKey: 'heightUnit',
    options: sizeUnits,
    defaultUnitValue: defaultSizeUnit,
    validators: [
      { method: isValidNumber, label: 'label.required' },
      { method: isPositiveNumber, label: 'label.positiveNumberRequired' },
    ],
    unitValidators: [{ method: isValidOption, label: 'label.required' }],
  },
  width: {
    type: Type.UNIT_SELECT,
    unitKey: 'widthUnit',
    options: sizeUnits,
    defaultUnitValue: defaultSizeUnit,
    validators: [
      { method: isValidNumber, label: 'label.required' },
      { method: isPositiveNumber, label: 'label.positiveNumberRequired' },
    ],
    unitValidators: [{ method: isValidOption, label: 'label.required' }],
  },
  bestBefore: {
    type: Type.DATE,
    validators: [{ method: isValidDate, label: 'label.required' }],
  },
  color: { type: Type.TEXT },
  licensePlate: { type: Type.TEXT },
  operatingSystem: { type: Type.TEXT },
  displayResolution: { type: Type.TEXT },
  refreshRate: { type: Type.TEXT },
}

export default function getDefaultUnits(): FormDataType {
  return Object.keys(ItemExtrasFields)
    .filter((key) => !!ItemExtrasFields[key].unitKey) // filter only entries that have units
    .map((key) => ({
      [ItemExtrasFields[key].unitKey ?? '']:
        ItemExtrasFields[key].defaultUnitValue,
    }))
    .reduce((acc, item) => ({ ...acc, ...item }), {})
}

const getSecondField = (key: string): InputType => {
  const fieldDefinition = get(ItemExtrasFields, key)
  if (fieldDefinition) {
    return {
      key: 'fieldValue',
      label: 'label.value',
      type: fieldDefinition.type,
      autoFocus: false,
      fullWidth: true,
      options: fieldDefinition.options,
      unitKey: fieldDefinition.unitKey,
      unitValidators: fieldDefinition.unitValidators,
      margin: 'none',
      validators:
        //  default text validator applied if no validator is set
        fieldDefinition.validators ?? [
          { method: isValidText, label: 'label.required' },
        ],
    }
  }
  // default return text field
  return {
    key: 'fieldValue',
    label: 'label.value',
    type: Type.TEXT,
    autoFocus: false,
    fullWidth: true,
    margin: 'none',
    validators: [{ method: isValidText, label: 'label.required' }],
  }
}

export const extraInputs = (
  intl: IntlShape,
  { key }: { key: string }
): InputType[] => [
  {
    key: 'fieldKey',
    label: 'label.property',
    type: Type.AUTOCOMPLETE,
    autoFocus: true,
    fullWidth: true,
    freeSolo: true,
    margin: 'none',
    options: Object.keys(ItemExtrasFields).map((k) => ({
      value: k,
      label: intl.formatMessage({
        id: `label.extraInfo.${k}`,
        defaultMessage: k,
      }),
    })),
    validators: [{ method: isValidText, label: 'label.required' }],
  },
  getSecondField(key),
]

type Key = string & keyof ItemExtras
const specialKeys = ['id', 'itemId', 'customValues', '__typename']

export const hasExtraInfo = (extras: ItemExtras) => {
  // @ts-expect-error type casting
  const keys: Key[] = Object.keys(ItemExtrasFields).filter(
    (k) => !specialKeys.includes(k)
  )
  const hasData = keys?.some((key) => !!get(extras, key))
  const customKeys = Object.keys(extras?.customValues ?? {})
  const hasCustomValues = customKeys.some(
    (key) => !!extras?.customValues?.[key]
  )
  return hasData || hasCustomValues
}

export function payloadBuilder(data: FormDataType, extras?: ItemExtras) {
  let payload = {}
  const key = data.fieldKey as string
  const value = data.fieldValue as string
  if (Object.keys(ItemExtrasFields).includes(key)) {
    const fieldInfo = ItemExtrasFields[key]
    const hasUnit = fieldInfo.unitKey
    const unit = data[fieldInfo.unitKey ?? '-']
    // predefined field
    payload = {
      [key]: hasUnit
        ? {
            // todo: change this if needed
            // all unit fields for now have a numeric value type for now.
            value: Number(value),
            unit,
          }
        : value,
    }
  } else {
    payload = {
      customValues: {
        ...(extras?.customValues ?? {}),
        [key]: value,
      },
    }
  }

  return payload
}

export function deleteFieldPayload(fieldKey: string, extras?: ItemExtras) {
  let payload = {}
  if (specialKeys.includes(fieldKey)) return payload
  if (Object.keys(ItemExtrasFields).includes(fieldKey)) {
    payload = { [fieldKey]: null }
  } else {
    payload = { customValues: omit(extras?.customValues ?? {}, fieldKey) }
  }
  return payload
}

export function useMutations(
  itemId: string,
  methods: {
    showSnackbar?: (message: string, severity: Severity) => void
  }
) {
  const { showSnackbar } = methods

  const [createItemExtras, { loading: createLoading }] = useMutation<
    CreateItemExtrasData,
    CreateItemExtrasInput
  >(ADD_ITEM_EXTRAS, {
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: [{ query: ITEM_BY_ID, variables: { itemId } }],
  })

  const [updateItemExtras, { loading: updateLoading }] = useMutation<
    UpdateItemExtrasData,
    UpdateItemExtrasInput
  >(UPDATE_ITEM_EXTRAS, {
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: [{ query: ITEM_BY_ID, variables: { itemId } }],
  })

  const loading = createLoading || updateLoading
  return {
    loading,
    mutations: {
      updateItemExtras,
      createItemExtras,
    },
  }
}

interface Data {
  key: string
  value: string
  label: string
  originalValue: string | { value?: number; unit?: string }
}
interface NestedDataType {
  value: number
  unit: string
}
function getDataValue(extras: ItemExtras, key: string): string | null {
  if (ItemExtrasFields[key]?.type === Type.UNIT_SELECT) {
    const units = ItemExtrasFields[key].options
    const data: NestedDataType = get(extras, key)
    if (!data?.value) return null
    return `${data?.value} ${units?.find((i) => i.value === data?.unit)?.label}`
  }
  if (ItemExtrasFields[key]?.type === Type.DATE) {
    return get(extras, key) ? getDate(get(extras, key)) : ''
  }

  return get(extras, key)
}
export const getData = (intl: IntlShape, extras?: ItemExtras): Data[] => {
  const keys = Object.keys(extras ?? {}).filter((k) => !specialKeys.includes(k))
  const customKeys = Object.keys(extras?.customValues ?? {})
  const customValues = extras?.customValues ?? {}

  if (!extras) return []
  const data = keys.reduce((acc: Data[], key) => {
    const value = getDataValue(extras, key)
    if (!value) return acc
    return acc.concat({
      key,
      value,
      originalValue: get(extras, key),
      label: intl.formatMessage({
        id: `label.extraInfo.${key}`,
        defaultMessage: key,
      }),
    })
  }, [])

  const customData = customKeys.reduce((acc: Data[], key) => {
    const value: string = customValues?.[key]
    if (!value) return acc
    return acc.concat({
      label: key,
      key,
      value,
      originalValue: value,
    })
  }, [])
  return sortBy(data.concat(customData), [(item) => item.label.toLowerCase()])
}

export function getInitialState(
  fieldKey: string,
  inputValue: string | number | { value?: number; unit?: string }
): { [key: string]: string | Date } {
  if (ItemExtrasFields[fieldKey]?.type === Type.DATE) {
    return {
      fieldKey,
      fieldValue: typeof inputValue === 'string' ? new Date(inputValue) : '',
    }
  }
  const unitField = ItemExtrasFields[fieldKey]?.unitKey?.toString() || ''
  if (isObject(inputValue)) {
    return {
      fieldKey,
      fieldValue: inputValue.value?.toString() ?? '',
      [unitField]: inputValue.unit ?? '',
    }
  }

  return {
    fieldKey,
    fieldValue: inputValue.toString() ?? '',
    ...(unitField
      ? { [unitField]: ItemExtrasFields[fieldKey].defaultUnitValue ?? '' }
      : {}),
  }
}
