import React, { useState, useCallback } from 'react'
import { useTheme } from '@mui/material/styles'
import { useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { Container, Content, DangerRowSpaced, Row } from 'components/shared'
import LoadingIndicator from 'components/LoadingIndicator'
import UploadModal from 'components/dropzone/UploadModal'
import { FileType } from 'types/archive'
import { FileEntry, JsonObject } from 'types/shared'
import { uploadDocument, uploadMedia } from 'lib/api'
import Box from '@mui/material/Box'
import {
  DELETE_SERVICE,
  PROVIDER_SERVICES,
  SERVICE_BY_ID,
  SERVICE_TYPES,
  UPDATE_SERVICE,
} from 'gql/service'
import Button from '@mui/material/Button'
import {
  DeleteServiceData,
  DeleteServiceInput,
  ServiceByIdData,
  ServiceTypeData,
  UpdateServiceData,
  UpdateServiceInput,
} from 'types/service'
import { NestTypeData, SpaceAvailableOptionsData } from 'types/nest'
import { NEST_TYPES, SPACE_AVAILABLE_OPTIONS } from 'gql/nest'
import { CategoriesData } from 'types/category'
import { CATEGORIES } from 'gql/category'
import TrashIcon from 'assets/svg/Trash'
import { Severity, withSnackbar } from 'components/providers/SnackbarHOC'
import SuggestionsSection, {
  SuggestionSection,
} from 'components/recordDetails/SuggestionsSection'
import ROUTES from 'lib/routes'
import ContentSection from 'components/recordDetails/ContentSection'
import FormModal from 'components/recordDetails/FormModal'
import {
  ConfirmationData,
  withConfirmationModal,
} from 'components/hoc/ConfirmationModalHoc'
import {
  DangerSectionLabel,
  PageGrid,
  PageGridLeft,
  PageGridRight,
  PageHeader,
  SectionTitleWrapper,
  StickySection,
} from 'components/pages'
import { H3 } from 'components/typography'
import Headline from './Headline'
import {
  getEditInputs,
  getExtraInputs,
  getInitialState,
  getSectionWithId,
  missingDataHints,
  payloadBuilder,
  Section,
} from './constants'
import { SimpleFeatureBadge } from './fragments'

interface Props {
  showSnackbar?: (message: string, severity: Severity) => void
  requestConfirmation?: (data: ConfirmationData) => void
}
/**
 * @deprecated Not used on V1
 * @returns
 */
function ServiceDetails({ showSnackbar, requestConfirmation }: Props) {
  const intl = useIntl()
  const theme = useTheme()
  const navigate = useNavigate()
  const [visibleModal, setVisibleModal] = useState(false)
  const [editing, setEditing] = useState<SuggestionSection>()
  const [editingId, setEditingId] = useState<string>()
  const [fileType, setFileType] = useState<FileType>(FileType.image)
  const params = useParams() as { workspaceId: string; serviceId: string }
  const hideEditingModal = useCallback(() => setEditing(undefined), [])

  const toggleDelete = () =>
    requestConfirmation?.({
      title: intl.formatMessage({ id: 'label.deletingService' }),
      description: intl.formatMessage({
        id: 'label.deletingServiceConfirmation',
      }),
      actions: [
        {
          label: intl.formatMessage({ id: 'label.cancel' }),
        },
        {
          label: intl.formatMessage({ id: 'label.delete' }),
          color: 'error',
          onClick: handleDelete,
        },
      ],
    })

  const toggleVisibility = useCallback(
    () => setVisibleModal(!visibleModal),
    [visibleModal]
  )
  const { data: types, loading: typesLoading } =
    useQuery<ServiceTypeData>(SERVICE_TYPES)

  const { data: service, loading: recordLoading } = useQuery<ServiceByIdData>(
    SERVICE_BY_ID,
    { variables: { serviceId: params.serviceId } }
  )
  const { data: options, loading: optionsLoading } =
    useQuery<NestTypeData>(NEST_TYPES)
  const { data: categoriesData, loading: categoriesLoading } =
    useQuery<CategoriesData>(CATEGORIES)
  const { data: sOptions } = useQuery<SpaceAvailableOptionsData>(
    SPACE_AVAILABLE_OPTIONS
  )

  const record = service?.serviceById
  const [deleteService, { loading: deletingService }] = useMutation<
    DeleteServiceData,
    DeleteServiceInput
  >(DELETE_SERVICE, {
    onCompleted: () => navigate(-1),
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: [
      {
        query: PROVIDER_SERVICES,
        variables: {
          payload: {
            providerId: record?.providerId,
            workspaceId: params.workspaceId,
          },
        },
      },
    ],
  })
  const [updateService, { loading: updateServiceLoading }] = useMutation<
    UpdateServiceData,
    UpdateServiceInput
  >(UPDATE_SERVICE, {
    onCompleted: hideEditingModal,
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: [
      { query: SERVICE_BY_ID, variables: { serviceId: params.serviceId } },
    ],
  })

  const nestTypes = options?.nestTypes.list ?? []
  const categories = categoriesData?.categories ?? []
  const spaceOptions = sOptions?.spaceAvailableOptions
  // const images =
  //   service?.archive?.filter((i) => i.type === FileType.image) ?? []

  const onUpload = async (files: FileEntry[]) => {
    if (!files) return
    const results = await Promise.all(
      files.map(async (object) => {
        const isImageFile = object.file.type.includes('image')
        const uploadFunction = isImageFile ? uploadMedia : uploadDocument
        return uploadFunction(object.file, {
          workspaceId: params.workspaceId,
          type: fileType,
          title: object.title,
          documentType: object.documentType,
        })
          .then((result) => result.json())
          .catch((error) => error)
      })
    )
    const uploadedFileIds = results
      .filter((result) => result.status === 'success')
      // eslint-disable-next-line no-underscore-dangle
      .map((result) => result.data._id)

    uploadedFileIds.forEach((archiveId) => {
      const archives = [...(record?.archiveIds ?? []), ...uploadedFileIds]
      handleRecordUpdate({ archiveIds: archives }, Section.archiveIds)
      // const payload = { serviceId: params.serviceId, archiveId }
      // addFileToNest({ variables: { payload } })
    })
    toggleVisibility()
  }
  const onSubmit = (files: FileEntry[]) => onUpload(files)
  const handleDelete = () =>
    record && deleteService({ variables: { id: record?.id } })
  const handleOnEditWithId = (sectionId: string, recordId?: string) => {
    const section = getSectionWithId(sectionId, intl)
    setEditingId(recordId)
    setEditing(section)
  }
  const handleRecordUpdate = (formData: JsonObject, sectionId?: string) => {
    const payload = payloadBuilder(formData, params.serviceId, sectionId)
    updateService({ variables: { payload } })
  }

  const showMediaUploadModal = useCallback(() => {
    setVisibleModal(true)
    setFileType(FileType.image)
  }, [])

  const isLoading =
    recordLoading ||
    typesLoading ||
    updateServiceLoading ||
    optionsLoading ||
    categoriesLoading ||
    deletingService
  const serviceTypes = types?.serviceTypes.list || []
  const inputs = getEditInputs(
    intl,
    editing?.id ?? '',
    serviceTypes,
    categories,
    nestTypes,
    spaceOptions
  )
  const extraInputs = getExtraInputs(editing?.id ?? '')
  const sections = record ? missingDataHints(intl, record) : []
  const initialState =
    record && editing ? getInitialState(record, editing.id, editingId) : {}
  return (
    <Container>
      <LoadingIndicator visible={isLoading} />
      <PageHeader
        pageTitle={intl.formatMessage({ id: 'label.service' })}
        recordTitle={record?.title ?? ''}
        breadcrumbLink={ROUTES.DASHBOARD_SERVICE_PROVIDERS_ROUTE(
          params.workspaceId
        )}
      />
      <Content>
        <PageGrid container spacing={6}>
          <PageGridLeft item xs={12} sm={12} md={12} lg={7} xl={8}>
            {record && (
              <Headline
                record={record}
                showMediaUploadModal={showMediaUploadModal}
                onEditSection={handleOnEditWithId}
              />
            )}

            {(record?.applicable?.categories?.length || 0) > 0 && (
              <ContentSection
                section={{
                  title: `${intl.formatMessage({
                    id: 'label.categories',
                  })} (${intl.formatMessage({ id: 'label.item' })})`,
                }}
                onEdit={() => handleOnEditWithId(Section.applicable)}
              >
                <Row>
                  {record?.applicable?.categories?.map((cat) => (
                    <SimpleFeatureBadge key={cat.id} label={cat.title} />
                  ))}
                </Row>
              </ContentSection>
            )}

            {(record?.applicable?.nestTypes?.length || 0) > 0 && (
              <ContentSection
                section={{
                  title: `${intl.formatMessage({
                    id: 'label.nestTypes',
                  })} (${intl.formatMessage({ id: 'label.item' })})`,
                }}
                onEdit={() => handleOnEditWithId(Section.applicable)}
              >
                <Row>
                  {record?.applicable?.nestTypes?.map((t) => (
                    <SimpleFeatureBadge key={t.id} label={t.title} />
                  ))}
                </Row>
              </ContentSection>
            )}

            {(record?.applicable?.features?.length || 0) > 0 && (
              <ContentSection
                section={{
                  title: `${intl.formatMessage({
                    id: 'label.features',
                  })} (${intl.formatMessage({ id: 'label.nest' })})`,
                }}
                onEdit={() => handleOnEditWithId(Section.applicable)}
              >
                <Row>
                  {record?.applicable?.features?.map((feature) => (
                    <SimpleFeatureBadge
                      key={feature}
                      label={intl.formatMessage({
                        id: `input.features.${feature}`,
                        defaultMessage: feature,
                      })}
                    />
                  ))}
                </Row>
              </ContentSection>
            )}
            {(record?.applicable?.securityFeatures?.length || 0) > 0 && (
              <ContentSection
                section={{
                  title: `${intl.formatMessage({
                    id: 'label.securityFeatures',
                  })} (${intl.formatMessage({ id: 'label.nest' })})`,
                }}
                onEdit={() => handleOnEditWithId(Section.applicable)}
              >
                <Row>
                  {record?.applicable?.securityFeatures?.map((feature) => (
                    <SimpleFeatureBadge
                      key={feature}
                      label={intl.formatMessage({
                        id: `input.securityFeatures.${feature}`,
                        defaultMessage: feature,
                      })}
                    />
                  ))}
                </Row>
              </ContentSection>
            )}
          </PageGridLeft>
          <PageGridRight item xs={12} sm={12} md={12} lg={5} xl={4}>
            <StickySection>
              {/* Missing information card. Questions and suggestions what data to fill. */}
              {sections.length > 0 && (
                <React.Fragment>
                  <H3>{intl.formatMessage({ id: 'label.addMoreInfo' })}</H3>
                  {sections.map((section) => (
                    <SuggestionsSection
                      key={section.id}
                      section={section}
                      onCtaClick={setEditing}
                    />
                  ))}
                  <Box sx={{ height: '40px' }} />
                </React.Fragment>
              )}
              <Box sx={{ height: '40px' }} />
              <React.Fragment>
                <SectionTitleWrapper>
                  <H3>{intl.formatMessage({ id: 'label.dangerZone' })}</H3>
                </SectionTitleWrapper>
                <DangerRowSpaced>
                  <DangerSectionLabel>
                    {intl.formatMessage({ id: 'label.deleteRecord' })}
                  </DangerSectionLabel>
                  <Button
                    type="submit"
                    variant="outlined"
                    color="error"
                    onClick={toggleDelete}
                  >
                    <TrashIcon color={theme.palette.error.main} />
                    {intl.formatMessage({ id: 'label.delete' })}
                  </Button>
                </DangerRowSpaced>
              </React.Fragment>
            </StickySection>
          </PageGridRight>
        </PageGrid>
        {/* <HeadlineGrid container>
          <HeadlineGridItemLeft
            item
            md={6}
            xs={12}
            order={{ xs: 3, sm: 3, md: 2 }}
          >
            <H1>{service?.title}</H1>
            <DescriptionWrapper>
              <StyledText
                text="label.storageDescription"
                fontSize={isBigScreen ? 24 : 18}
              />
              <Text style={{ marginTop: '20px' }}>{service?.description}</Text>
            </DescriptionWrapper>
          </HeadlineGridItemLeft>
        </HeadlineGrid> */}
      </Content>
      <UploadModal
        fileType={fileType}
        open={visibleModal}
        onSubmit={onSubmit}
        toggleModal={toggleVisibility}
      />
      <FormModal
        toggleModal={hideEditingModal}
        inputs={inputs}
        extraInputs={extraInputs}
        section={editing}
        initialState={initialState}
        onSubmit={handleRecordUpdate}
      />
    </Container>
  )
}

ServiceDetails.defaultProps = {
  showSnackbar: undefined,
  requestConfirmation: undefined,
}

export default withConfirmationModal(withSnackbar(ServiceDetails))
