import React from 'react'
import { IntlShape } from 'react-intl'
import { Severity } from 'components/providers/SnackbarHOC'
import DocumentGrid from 'components/DocumentGrid'
import DocumentIcon from '@mui/icons-material/NoteAdd'
import AddIcon from '@mui/icons-material/Add'
import { TabSection } from 'components/Tabs'
import DeleteIcon from '@mui/icons-material/Delete'
import { FileType, ArchiveFile } from 'types/archive'
import { ContractExtended } from 'types/contracts'
import DangerSectionRender from 'components/content/DangerSectionRender'
import SectionRender from 'components/content/SectionRender'
import TrashIcon from 'assets/svg/Trash'
import { CONTRACT_BY_ID } from 'gql/contracts'
import { Masonry } from 'components/shared'
import { min, max } from 'lodash'
import ItemCard from 'components/ItemCard'
import Pencil from 'assets/svg/Pencil'
import NestCard from 'components/NestCard'
import { sortByDate } from 'utils/sort'
import { EventExtended } from 'types/event'
import { getEventContent } from 'utils/content'
import VisibilityIcon from '@mui/icons-material/Visibility'
import {
  getAccountContent,
  getCancelationContent,
  getCustomerContent,
  getPaymentContent,
  getRenewalContent,
  Section,
} from './constants'

const tabs = (
  workspaceId: string,
  intl: IntlShape,
  record: ContractExtended | undefined,
  methods: {
    handleOnEditWithId: (sectionId: string, recordId?: string) => void
    showSnackbar?: (message: string, severity: Severity) => void
    showDocUploadModal: () => void
    toggleDelete: () => void
    toggleItemSelect: () => void
    toggleNestSelect: () => void
    handleOnDeleteEvent: (recordId: string) => void
    showEventDetails?: (event: EventExtended) => void
    hideEventDetails?: () => void
  }
): TabSection[] => {
  const {
    showDocUploadModal,
    handleOnEditWithId,
    toggleDelete,
    toggleItemSelect,
    toggleNestSelect,
    handleOnDeleteEvent,
    showEventDetails,
    hideEventDetails,
  } = methods
  const documents =
    record?.archive?.filter((i) => i.type === FileType.document) ?? []
  const cancelationContent = record ? getCancelationContent(record, intl) : []
  const renewalContent = record ? getRenewalContent(record, intl) : []
  const customerContent = record ? getCustomerContent(record, intl) : []
  const accountContent = record ? getAccountContent(record, intl) : []
  const paymentContent = record ? getPaymentContent(record, intl) : []
  const items = record?.items ?? []
  const nests = record?.nests ?? []
  const events = sortByDate(record?.events ?? [], 'date')

  const refetchQueries = [
    {
      query: CONTRACT_BY_ID,
      variables: { contractId: record?.id },
    },
  ]

  return [
    {
      key: 'customer',
      label: 'label.customer',
      visible: !!record,
      content: record ? (
        <SectionRender
          header={intl.formatMessage({ id: 'label.customer' })}
          entries={[
            {
              title: intl.formatMessage({
                id: 'label.customerInfo',
              }),
              description: intl.formatMessage({
                id: 'label.contractCustomerDescription',
              }),
              data: customerContent,
              actions: [
                {
                  ariaLabel: 'edit customer',
                  icon: (props) => <Pencil {...props} />,
                  onPress: () => handleOnEditWithId(Section.customer),
                },
              ],
            },
            {
              title: intl.formatMessage({
                id: 'label.accountInformation',
              }),
              description: intl.formatMessage({
                id: 'label.contractAccountDescription',
              }),
              data: accountContent,
              actions: [
                {
                  ariaLabel: 'edit account',
                  icon: (props) => <Pencil {...props} />,
                  onPress: () => handleOnEditWithId(Section.account),
                },
              ],
            },
          ]}
        />
      ) : null,
    },
    {
      key: 'cancelation',
      label: 'label.cancelation',
      visible: !!record,
      content: record ? (
        <SectionRender
          header={intl.formatMessage({ id: 'label.cancelation' })}
          headerActions={[
            {
              ariaLabel: 'edit cancelation',
              icon: (props) => <Pencil {...props} />,
              onPress: () => handleOnEditWithId(Section.cancelation),
            },
          ]}
          entries={[
            {
              description: intl.formatMessage({
                id: 'label.contractCancelationDescription',
              }),
              data: cancelationContent,
            },
          ]}
        />
      ) : null,
    },
    {
      key: 'renewal',
      label: 'label.renewal',
      visible: !!record,
      content: record ? (
        <SectionRender
          header={intl.formatMessage({ id: 'label.renewal' })}
          headerActions={[
            {
              ariaLabel: 'edit renewal',
              icon: (props) => <Pencil {...props} />,
              onPress: () => handleOnEditWithId(Section.renewal),
            },
          ]}
          entries={[
            {
              description: intl.formatMessage({
                id: 'label.contractRenewalDescription',
              }),
              data: renewalContent,
            },
          ]}
        />
      ) : null,
    },
    {
      key: 'payment',
      label: 'label.paymentInfo',
      visible: !!record,
      content: record ? (
        <SectionRender
          header={intl.formatMessage({ id: 'label.paymentInfo' })}
          headerActions={[
            {
              ariaLabel: 'edit payment',
              icon: (props) => <Pencil {...props} />,
              onPress: () => handleOnEditWithId(Section.payment),
            },
          ]}
          entries={[
            {
              description: intl.formatMessage({
                id: 'label.contractPaymentDescription',
              }),
              data: paymentContent,
            },
          ]}
        />
      ) : null,
    },
    {
      key: 'contract-events',
      label: 'label.events',
      visible: true,
      content: (
        <SectionRender
          header={intl.formatMessage({ id: 'label.events' })}
          headerActions={[
            {
              ariaLabel: 'add event',
              icon: () => <AddIcon color="disabled" />,
              onPress: () => handleOnEditWithId(Section.events),
            },
          ]}
          entries={
            events.length > 0
              ? events.map((event: EventExtended) => ({
                  title: event.title,
                  description: event.description,
                  onClick: () => showEventDetails?.(event),
                  data: getEventContent(workspaceId, event, intl),
                  actions: [
                    {
                      ariaLabel: 'see event details',
                      onPress: (
                        e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
                      ) => {
                        showEventDetails?.(event)
                        e?.stopPropagation()
                        e?.preventDefault()
                      },
                      icon: () => (
                        <VisibilityIcon color="disabled" fontSize="small" />
                      ),
                    },
                    {
                      ariaLabel: 'add event document',
                      onPress: (
                        e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
                      ) => {
                        hideEventDetails?.()
                        handleOnEditWithId(Section.eventDocuments, event.id)
                        e?.stopPropagation()
                        e?.preventDefault()
                      },
                      icon: () => (
                        <DocumentIcon color="primary" fontSize="small" />
                      ),
                    },
                    {
                      ariaLabel: 'edit event',
                      onPress: (
                        e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
                      ) => {
                        hideEventDetails?.()
                        handleOnEditWithId(Section.events, event.id)
                        e?.stopPropagation()
                        e?.preventDefault()
                      },
                      //  @ts-ignore
                      icon: (props) => <Pencil {...props} />,
                    },
                    {
                      ariaLabel: 'delete event',
                      onPress: (
                        e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
                      ) => {
                        hideEventDetails?.()
                        handleOnDeleteEvent(event.id)
                        e?.stopPropagation()
                        e?.preventDefault()
                      },
                      icon: () => <DeleteIcon color="error" fontSize="small" />,
                    },
                  ],
                }))
              : [{ description: intl.formatMessage({ id: 'label.noData' }) }]
          }
        />
      ),
    },
    {
      key: 'contract-items',
      label: 'label.items',
      visible: items.length > 0,
      content: (
        <SectionRender
          header={intl.formatMessage({ id: 'label.items' })}
          headerActions={[
            {
              ariaLabel: 'add item',
              icon: (props) => <AddIcon color="disabled" />,
              onPress: toggleItemSelect,
            },
          ]}
        >
          <Masonry
            spacing={2}
            columns={{
              xs: 2,
              sm: min([2, max([items.length, 1])]) as number,
              lg: min([3, max([items.length, 2])]) as number,
            }}
          >
            {items.map((item) => (
              <ItemCard item={item} key={item.id} />
            ))}
          </Masonry>
        </SectionRender>
      ),
    },
    {
      key: 'contract-nests',
      label: 'label.nests',
      visible: nests.length > 0,
      content: (
        <SectionRender
          header={intl.formatMessage({ id: 'label.nests' })}
          headerActions={[
            {
              ariaLabel: 'add nests',
              icon: (props) => <AddIcon color="disabled" />,
              onPress: toggleNestSelect,
            },
          ]}
        >
          <Masonry
            spacing={2}
            columns={{
              xs: 2,
              sm: min([2, max([nests.length, 1])]) as number,
              lg: min([3, max([nests.length, 2])]) as number,
            }}
          >
            {nests.map((nest) => (
              <NestCard nest={nest} key={nest.id} />
            ))}
          </Masonry>
        </SectionRender>
      ),
    },
    {
      key: 'contract-documents',
      label: 'label.documents',
      visible: true,
      content: (
        <SectionRender
          header={intl.formatMessage(
            {
              id:
                documents.length > 0
                  ? 'label.documentsCount'
                  : 'label.documents',
            },
            { count: documents.length ?? 0 }
          )}
          headerActions={[
            {
              ariaLabel: 'add document',
              icon: (props) => <AddIcon color="disabled" />,
              onPress: showDocUploadModal,
            },
          ]}
          entries={[
            {
              description:
                documents.length === 0
                  ? intl.formatMessage({ id: 'label.noDocuments' })
                  : undefined,
            },
          ]}
        >
          {documents.length > 0 && (
            <DocumentGrid
              documents={sortByDate<ArchiveFile>(documents)}
              refetchQueries={refetchQueries}
            />
          )}
        </SectionRender>
      ),
    },
    {
      key: 'danger-zone',
      label: 'label.dangerZone',
      visible: !!record && !record.deletedAt,
      content: record ? (
        <DangerSectionRender
          entries={[
            {
              title: intl.formatMessage({ id: 'label.dangerZone' }),
              actions: [
                {
                  ariaLabel: 'delete contract',
                  icon: (props) => <TrashIcon {...props} />,
                  onPress: toggleDelete,
                  label: intl.formatMessage({ id: 'label.delete' }),
                  infoLabel: intl.formatMessage({ id: 'label.deleteContract' }),
                },
              ],
            },
          ]}
        />
      ) : null,
    },
  ].filter((section) => section.visible)
}

export default tabs
