import React from 'react'
import { styled } from '@mui/material/styles'
import { useIntl } from 'react-intl'
import { Column, Row, RowSpaced } from 'components/shared'
import { StatusChip } from 'components/cards'
import IconButton from '@mui/material/IconButton'
import { BodyText, BodyTextLight, H1 } from 'components/typography'
import { ContractExtended } from 'types/contracts'
import { formatLink } from 'utils/link'
import Grid from '@mui/material/Grid'
import { getDate } from 'utils/datetime'
import Pencil from 'assets/svg/Pencil'
import { Section, statusColor } from './constants'

const Container = styled('div')(({ theme }) => ({
  flexDirection: 'column',
  position: 'relative',
  backgroundColor: theme.palette.background.default,
  padding: '30px 20px',
  borderRadius: '5px',
  marginBottom: '16px',
}))

const Image = styled('img')(({ theme }) => ({
  display: 'flex',
  width: '80px',
  height: '60px',
  objectFit: 'contain',
  borderRadius: '3px',
}))

const Title = styled(H1)({ marginBottom: '4px' })
const DescriptionText = styled(BodyText)<{
  visible?: boolean
}>(({ visible }) => ({
  marginBottom: '18px',
  display: visible ? 'flex' : 'none',
}))

interface Props {
  record: ContractExtended
  onEdit: (section: Section) => void
}
export function Headline({ record, onEdit }: Props) {
  const intl = useIntl()
  const [favError, setFavError] = React.useState(false)
  const handleEdit = () => onEdit(Section.general)
  const onLoadError = () => setFavError(true)

  const { provider, customer } = record
  return (
    <Container>
      <Row>
        {!favError && provider?.link && (
          <Image
            src={`${formatLink(provider?.link)}/favicon.ico`}
            onError={onLoadError}
          />
        )}
        <Column>
          <RowSpaced>
            <Row>
              <StatusChip
                label={intl.formatMessage({
                  id: `contract.status.${record.status}`,
                  defaultMessage: record.status,
                })}
                color={statusColor(record?.status)}
              />
              {record?.deletedAt && (
                <StatusChip
                  color="error"
                  label={intl.formatMessage(
                    { id: 'label.deletedAtDate' },
                    { date: getDate(record.deletedAt) }
                  )}
                />
              )}
            </Row>
            <IconButton onClick={handleEdit}>
              <Pencil />
            </IconButton>
          </RowSpaced>
          <Title>{record.title}</Title>
          <DescriptionText visible={!!record.description}>
            {record.description}
          </DescriptionText>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <BodyText>
                {intl.formatMessage({ id: 'label.contractNumber' })}
              </BodyText>
            </Grid>
            <Grid item xs={6}>
              <BodyTextLight>{record.contractNumber ?? '-'}</BodyTextLight>
            </Grid>
            <Grid item xs={6}>
              <BodyText>
                {intl.formatMessage({ id: 'label.customerNumber' })}
              </BodyText>
            </Grid>
            <Grid item xs={6}>
              <BodyTextLight>{customer?.number ?? '-'}</BodyTextLight>
            </Grid>
            <Grid item xs={6}>
              <BodyText>
                {intl.formatMessage({ id: 'label.startDate' })}
              </BodyText>
            </Grid>
            <Grid item xs={6}>
              <BodyTextLight>
                {record.startDate ? getDate(record.startDate) : '-'}
              </BodyTextLight>
            </Grid>
          </Grid>
        </Column>
      </Row>
    </Container>
  )
}
