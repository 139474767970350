import React, { useState, useCallback, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import LoadingIndicator from 'components/LoadingIndicator'
import FormModal from 'components/recordDetails/FormModal'
import { SuggestionSection } from 'components/recordDetails/SuggestionsSection'
import { Container, Content, Row } from 'components/shared'
import { useTheme } from '@mui/material/styles'
import { Severity, withSnackbar } from 'components/providers/SnackbarHOC'
import { omit } from 'lodash'
import {
  ConfirmationData,
  withConfirmationModal,
} from 'components/hoc/ConfirmationModalHoc'
import SidebarCard from 'components/recordDetails/SidebarCard'
import { Currencies, FileEntry, JsonObject, MemberModels } from 'types/shared'
import Tabs from 'components/Tabs'
import ROUTES from 'lib/routes'
import { FileType } from 'types/archive'
import UploadHandler, {
  UploadHandlerMethods,
} from 'components/dropzone/UploadHandler'
import { useMediaQuery } from '@mui/material'
import ItemSelectDrawer from 'components/selectorDrawers/ItemSelectDrawer'
import NestSelectDrawerMultiple from 'components/selectorDrawers/NestSelectDrawerMultiple'
import { classifyServiceProviders } from 'pages/dashboard/items/show/utils'
import { useSubscriptionStatus } from 'hooks/graphql/workspaces'
import { ErrorType, useFileUpload } from 'hooks/api/fileUpload'
import {
  PageGrid,
  PageGridLeft,
  PageGridRight,
  PageHeader,
  StickySection,
} from 'components/pages'
import { H5 } from 'components/typography'
import { formatLink } from 'utils/link'
import { useData, useMutations } from './hooks'
import { Headline } from './Headline'
import contentTabs from './tabs'
import {
  getInitialState,
  getEditInputs,
  getExtraInputs,
  getSectionWithId,
  payloadBuilder,
  Section,
  relationsContent,
} from './constants'
import EventDetailsDrawer, {
  EventDrawerHandleMethods,
} from 'components/hoc/EventDetailsDrawer'
import { CONTRACT_BY_ID } from 'gql/contracts'
import { EventExtended } from 'types/event'

interface Props {
  showSnackbar?: (message: string, severity: Severity) => void
  requestConfirmation?: (data: ConfirmationData) => void
}
function Page({ showSnackbar, requestConfirmation }: Props) {
  const intl = useIntl()
  const theme = useTheme()
  const navigate = useNavigate()
  const { uploadDocuments } = useFileUpload()
  const [favError, setFavError] = React.useState(false)
  const params = useParams() as { workspaceId: string; contractId: string }
  const { active } = useSubscriptionStatus(params.workspaceId)
  const [showItemSelectDrawer, setShowItemSelectDrawer] = useState(false)
  const [showNestSelectDrawer, setShowNestSelectDrawer] = useState(false)
  const [editing, setEditing] = useState<SuggestionSection>()
  const [fileUploadErrors, setFileUploadErrors] = useState<ErrorType[]>()
  const uploadHandlerRef = useRef<UploadHandlerMethods>(null)
  const isBigScreen = useMediaQuery(theme.breakpoints.up('lg'))
  const eventDetailsRef = useRef<EventDrawerHandleMethods>(null)
  const [focusedEvent, setFocusedEvent] = useState<EventExtended>()

  // optional id of the sub-record to edit
  const [editingId, setEditingId] = useState<string>()
  const hideEditingModal = useCallback(() => setEditing(undefined), [])
  const toggleItemSelect = () => setShowItemSelectDrawer(!showItemSelectDrawer)
  const toggleNestSelect = () => setShowNestSelectDrawer(!showNestSelectDrawer)
  const toggleDelete = () =>
    requestConfirmation?.({
      title: intl.formatMessage({ id: 'label.deletingContract' }),
      description: intl.formatMessage({
        id: 'label.deletingContractConfirmation',
      }),
      actions: [
        {
          label: intl.formatMessage({ id: 'label.cancel' }),
        },
        {
          label: intl.formatMessage({ id: 'label.delete' }),
          color: 'error',
          onClick: handleDelete,
        },
      ],
    })

  const refreshEventDrawer = () => {
    const event = record?.events?.find((e) => e.id === focusedEvent?.id)
    if (event) {
      eventDetailsRef.current?.hideEventDetails()
      eventDetailsRef.current?.showEventDetails(event)
    }
  }
  const {
    loading,
    data: { record, serviceProviders, members, owners },
    refetch: { refetchContract },
  } = useData(params.contractId, params.workspaceId, {
    showSnackbar,
    refreshEventDrawer,
  })

  const {
    loading: mutating,
    mutations: {
      updateContract,
      deleteContract,
      updateContractItemRefs,
      updateContractNestRefs,
      createMemberContractRef,
      deleteMemberContractRef,
      updateEvent,
      addEventRecord,
      deleteEvent,
    },
  } = useMutations(
    { ...params, navigate },
    {
      showSnackbar,
      hideEditingModal,
      toggleItemSelect,
      toggleNestSelect,
    }
  )

  const handleRecordUpdate = (formData: JsonObject, sectionId?: string) => {
    const payload = payloadBuilder(formData, params.contractId, sectionId)
    updateContract({ variables: { payload } })
  }
  const handleDelete = () =>
    record?.id && deleteContract({ variables: { id: record.id } })

  const handleOnEditWithId = (sectionId: string, recordId?: string) => {
    const section = getSectionWithId(sectionId, intl)
    setEditingId(recordId)
    setEditing(section)
  }
  const handleOnItemSelect = (itemIds: string[]) => {
    const payload = { contractId: params.contractId, itemIds }
    updateContractItemRefs({ variables: { payload } })
  }
  const handleOnNestSelect = (nestIds: string[]) => {
    const payload = { contractId: params.contractId, nestIds }
    updateContractNestRefs({ variables: { payload } })
  }

  const isLoading = loading || mutating
  const serviceProvidersLabeled = classifyServiceProviders(
    intl,
    serviceProviders
  )
  const inputs = getEditInputs(editing?.id ?? '', intl, serviceProvidersLabeled)
  const extraInputs = getExtraInputs(editing?.id ?? '')
  const initialState =
    record && editing ? getInitialState(record, editing.id, editingId) : {}

  const handleOnUpdate = (
    formData: JsonObject,
    sectionId?: string,
    files?: FileEntry[]
  ) => {
    switch (sectionId) {
      case Section.events:
      case Section.eventDocuments:
        handleEventRecordUpdate(formData, sectionId, files)
        break
      default:
        handleRecordUpdate(formData, sectionId)
    }
  }

  const handleEventRecordUpdate = async (
    formData: JsonObject,
    sectionId?: string,
    files?: FileEntry[]
  ) => {
    let uploadedFileIds: string[] = []
    if (sectionId === Section.eventDocuments && files) {
      // has files to upload
      await uploadDocuments(files, params.workspaceId, {
        onCompleted: (archiveIds) => {
          uploadedFileIds = archiveIds
        },
        onError: (errors) => {
          if (errors.length > 0)
            showSnackbar?.(
              `File upload failed, (occurrences: ${errors.length}).
                ${errors[0]?.error?.message}
              `,
              Severity.ERROR
            )
        },
      })
    }

    if (editingId) {
      // update record
      const payload = {
        id: editingId,
        data: {
          ...omit(formData ?? {}, ['price', 'currency']),
          amount:
            formData.price && formData.currency
              ? {
                  value: Number(formData.price),
                  currency: formData.currency as Currencies,
                }
              : undefined,
          archiveIds: [
            ...(record?.events?.find((rec) => rec.id === editingId)
              ?.archiveIds ?? []),
            ...uploadedFileIds,
          ],
        },
      }
      updateEvent({ variables: { payload } })
    } else {
      // new record
      const payload = {
        ...omit(formData ?? {}, ['price', 'currency']),
        amount:
          formData.price && formData.currency
            ? {
                value: Number(formData.price),
                currency: formData.currency as Currencies,
              }
            : undefined,
        contractId: params.contractId,
        workspaceId: params.workspaceId,
        date: formData.date as Date,
        archiveIds: uploadedFileIds,
      }
      addEventRecord({ variables: { payload } })
    }
  }

  const onUpload = (files: FileEntry[], fileType: FileType) => {
    uploadDocuments(files, params.workspaceId, {
      onCompleted: (archiveIds, hasErrors) => {
        handleRecordUpdate({
          archiveIds: [...(record?.archiveIds ?? []), ...archiveIds],
        })
        !hasErrors && uploadHandlerRef.current?.closeUploader()
        showSnackbar?.(
          intl.formatMessage({ id: 'label.uploadSuccessful' }),
          Severity.SUCCESS
        )
      },
      onError: (errors) => {
        setFileUploadErrors(errors)
        uploadHandlerRef.current?.stopLoader()
      },
    })
  }

  const createMemberRef = (memberId: string, memberModel: MemberModels) => {
    const payload = {
      memberId,
      workspaceId: params.workspaceId,
      memberModel,
      contractId: params.contractId,
    }
    createMemberContractRef({ variables: { payload } })
  }
  const deleteMemberRef = (refId: string) => {
    deleteMemberContractRef({ variables: { id: refId } })
  }

  const handleOnDeleteEvent = (recordId: string) =>
    requestConfirmation?.({
      title: intl.formatMessage({ id: 'label.deleteEvent' }),
      description: intl.formatMessage({
        id: 'label.deletingEventConfirmation',
      }),
      actions: [
        {
          label: intl.formatMessage({ id: 'label.cancel' }),
        },
        {
          label: intl.formatMessage({ id: 'label.delete' }),
          color: 'error',
          onClick: () => deleteEvent({ variables: { id: recordId } }),
        },
      ],
    })
  const showDocUploadModal = useCallback(() => {
    uploadHandlerRef.current?.openUploader(FileType.document)
  }, [])

  const tabs = contentTabs(params.workspaceId, intl, record, {
    toggleDelete,
    showSnackbar,
    handleOnEditWithId,
    showDocUploadModal,
    toggleItemSelect,
    toggleNestSelect,
    handleOnDeleteEvent,
    showEventDetails: (event: EventExtended) => {
      setFocusedEvent(event)
      eventDetailsRef.current?.showEventDetails(event)
    },
    hideEventDetails: () => {
      setFocusedEvent(undefined)
      eventDetailsRef.current?.hideEventDetails()
    },
  })
  const favLink = record?.provider?.link
    ? `${formatLink(record.provider?.link)}/favicon.ico`
    : undefined
  const onLoadError = () => setFavError(true)
  const showIcon = !favError && favLink

  const sidebar = (
    <React.Fragment>
      <SidebarCard
        title={intl.formatMessage({
          id: 'label.relatedServiceProvider',
        })}
        onEdit={() => handleOnEditWithId(Section.provider)}
        placeholder={intl.formatMessage({
          id: 'label.relatedServiceProviderNothingSelected',
        })}
      >
        {record?.provider && (
          <Row>
            {showIcon && (
              <img
                style={{ width: '26px', height: '26px', objectFit: 'contain' }}
                src={favLink}
                onError={onLoadError}
                alt="Service provider logo"
              />
            )}
            <H5
              color={theme.palette.text.secondary}
              style={showIcon ? { marginLeft: '4px' } : {}}
            >
              {record.provider.title}
            </H5>
          </Row>
        )}
      </SidebarCard>

      <SidebarCard
        title={intl.formatMessage({
          id: 'label.relations',
        })}
        content={
          record
            ? relationsContent(intl, record, [...owners, ...members], {
                toggleItemSelect,
                toggleNestSelect,
                deleteMemberRef,
                createMemberRef,
                addEvent: () => handleOnEditWithId(Section.events),
              })
            : []
        }
      />
    </React.Fragment>
  )

  return (
    <Container detailPage>
      <LoadingIndicator visible={isLoading} />
      <PageHeader
        pageTitle={intl.formatMessage({ id: 'label.contract' })}
        recordTitle={record?.title ?? ''}
        breadcrumbLink={ROUTES.DASHBOARD_CONTRACTS_ROUTE(params.workspaceId)}
      />
      <Content detailPage>
        <PageGrid container spacing={6}>
          <PageGridLeft item xs={12} sm={12} md={12} lg={7} xl={8}>
            {record && <Headline record={record} onEdit={handleOnEditWithId} />}
            {!isBigScreen && sidebar}
            <Tabs tabs={tabs} locked={!active} />
          </PageGridLeft>

          {isBigScreen && (
            <PageGridRight item xs={12} sm={12} md={12} lg={5} xl={4}>
              <StickySection>{sidebar}</StickySection>
            </PageGridRight>
          )}
        </PageGrid>
      </Content>

      <FormModal
        toggleModal={hideEditingModal}
        inputs={inputs}
        extraInputs={extraInputs}
        section={editing}
        initialState={initialState}
        onSubmit={handleOnUpdate}
      />
      <ItemSelectDrawer
        open={showItemSelectDrawer}
        toggleDrawer={toggleItemSelect}
        preSelectedRecords={record?.items ?? []}
        onSubmit={handleOnItemSelect}
      />
      <NestSelectDrawerMultiple
        open={showNestSelectDrawer}
        toggleDrawer={toggleNestSelect}
        preSelectedRecords={record?.nests ?? []}
        onSubmit={handleOnNestSelect}
      />

      <UploadHandler
        ref={uploadHandlerRef}
        onSubmit={onUpload}
        errors={fileUploadErrors}
      />

      <EventDetailsDrawer
        ref={eventDetailsRef}
        refetchQueries={[
          {
            query: CONTRACT_BY_ID,
            variables: { itemId: record?.id },
          },
        ]}
        onRefresh={() => refetchContract()}
      />
    </Container>
  )
}
Page.defaultProps = {
  showSnackbar: undefined,
  requestConfirmation: undefined,
}

export default withConfirmationModal(withSnackbar(Page))
