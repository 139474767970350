import React from 'react'
import { IntlShape } from 'react-intl'
import { TabSection } from 'components/Tabs'
import DangerSectionRender from 'components/content/DangerSectionRender'
import SectionRender from 'components/content/SectionRender'
import TrashIcon from 'assets/svg/Trash'
import { Masonry } from 'components/shared'
import { min, max } from 'lodash'
import ItemCard from 'components/ItemCard'
import AddIcon from '@mui/icons-material/Add'
import {
  OrganizationMemberDetails,
  WorkspaceMemberDetails,
  isOrganizationMember,
} from 'types/user'
import NestCard from 'components/NestCard'
import ContractCard from 'components/ContractCard'
import ContainerCard from 'components/ContainerCard'
import DocumentGrid from 'components/DocumentGrid'
import { ArchiveFile } from 'types/archive'
import { sortByDate } from 'utils/sort'
import { MEMBER_BY_ID } from 'gql/workspace'
import { MemberModels } from 'types/shared'

const tabs = (
  intl: IntlShape,
  data: {
    record?: OrganizationMemberDetails | WorkspaceMemberDetails
    workspaceId: string
    memberModel: MemberModels
  },
  methods: {
    toggleDelete: () => void
    showDocUploadModal: () => void
  }
): TabSection[] => {
  const { toggleDelete, showDocUploadModal } = methods
  const { record, workspaceId, memberModel } = data

  const items = record?.items ?? []
  const nests = record?.nests ?? []
  const contracts = record?.contracts ?? []
  const containers = record?.containers ?? []
  const documents = record?.archive ?? []

  return [
    {
      key: 'related-items',
      label: 'label.items',
      visible: !!record?.items?.length,
      content: (
        <SectionRender header={intl.formatMessage({ id: 'label.items' })}>
          <Masonry
            spacing={2}
            columns={{
              xs: 2,
              sm: min([2, max([items.length, 1])]) as number,
              lg: min([3, max([items.length, 2])]) as number,
            }}
          >
            {items.map((item) => (
              <ItemCard item={item} key={item.id} />
            ))}
          </Masonry>
        </SectionRender>
      ),
    },
    {
      key: 'related-nests',
      label: 'label.spaces',
      visible: !!record?.nests?.length,
      content: (
        <SectionRender header={intl.formatMessage({ id: 'label.spaces' })}>
          <Masonry
            spacing={2}
            columns={{
              xs: 2,
              sm: min([2, max([nests.length, 1])]) as number,
              lg: min([3, max([nests.length, 2])]) as number,
            }}
          >
            {nests.map((item) => (
              <NestCard nest={item} key={item.id} />
            ))}
          </Masonry>
        </SectionRender>
      ),
    },
    {
      key: 'related-contracts',
      label: 'label.contracts',
      visible: !!record?.contracts?.length,
      content: (
        <SectionRender header={intl.formatMessage({ id: 'label.contracts' })}>
          <Masonry
            spacing={2}
            columns={{
              xs: 2,
              sm: min([2, max([contracts.length, 1])]) as number,
              lg: min([3, max([contracts.length, 2])]) as number,
            }}
          >
            {contracts.map((item) => (
              <ContractCard record={item} key={item.id} />
            ))}
          </Masonry>
        </SectionRender>
      ),
    },
    {
      key: 'related-containers',
      label: 'label.containers',
      visible: !!record?.containers?.length,
      content: (
        <SectionRender header={intl.formatMessage({ id: 'label.containers' })}>
          <Masonry
            spacing={2}
            columns={{
              xs: 2,
              sm: min([2, max([containers.length, 1])]) as number,
              lg: min([3, max([containers.length, 2])]) as number,
            }}
          >
            {containers.map((item) => (
              <ContainerCard record={item} key={item.id} />
            ))}
          </Masonry>
        </SectionRender>
      ),
    },
    {
      key: 'member-documents',
      label: 'label.documents',
      visible: true,
      content: (
        <SectionRender
          header={intl.formatMessage(
            {
              id:
                documents.length > 0
                  ? 'label.documentsCount'
                  : 'label.documents',
            },
            { count: documents.length ?? 0 }
          )}
          headerActions={[
            {
              ariaLabel: 'add document',
              icon: (props) => <AddIcon color="disabled" />,
              onPress: showDocUploadModal,
            },
          ]}
          entries={[
            {
              description:
                documents.length === 0
                  ? intl.formatMessage({ id: 'label.noDocuments' })
                  : undefined,
            },
          ]}
        >
          {documents.length > 0 && (
            <DocumentGrid
              documents={sortByDate<ArchiveFile>(documents)}
              refetchQueries={[
                {
                  query: MEMBER_BY_ID,
                  variables: {
                    payload: { memberId: record?.id, memberModel, workspaceId },
                  },
                },
              ]}
            />
          )}
        </SectionRender>
      ),
    },
    {
      key: 'danger-zone',
      label: 'label.dangerZone',
      visible: record && !isOrganizationMember(record) && record.canEdit,
      content: record ? (
        <DangerSectionRender
          entries={[
            {
              title: intl.formatMessage({ id: 'label.dangerZone' }),
              actions: [
                {
                  ariaLabel: 'delete member',
                  icon: (props) => <TrashIcon {...props} />,
                  onPress: toggleDelete,
                  label: intl.formatMessage({ id: 'label.delete' }),
                  infoLabel: intl.formatMessage({ id: 'label.deleteMember' }),
                },
              ],
            },
          ]}
        />
      ) : null,
    },
  ].filter((section) => section.visible)
}

export default tabs
