import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { styled } from '@mui/material/styles'
import { useNavigate, useParams } from 'react-router-dom'
import { H1, H3 } from 'components/typography'
import { Severity, withSnackbar } from 'components/providers/SnackbarHOC'
import {
  Container,
  Content,
  Button,
  RowSpaced,
  SectionCTA,
  SectionWrapper,
} from 'components/shared'
import ROUTES from 'lib/routes'
import InputRender, { formHasErrors } from 'components/input/InputRender'
import { FormDataType } from 'components/input/types'
import { useMutation, useQuery } from '@apollo/client'
import LoadingIndicator from 'components/LoadingIndicator'
import {
  CreateWorkspaceData,
  CreateWorkspaceFromTypesData,
  CreateWorkspaceFromTypesInput,
  CreateWorkspaceInput,
  UpdateWorkspaceData,
  UpdateWorkspaceInput,
  WorkspaceType,
  WorkspaceTypeData,
} from 'types/workspace'
import {
  CREATE_WORKSPACE,
  CREATE_WORKSPACE_FROM_TYPES,
  UPDATE_WORKSPACE,
  GROUPED_WORKSPACES,
  WORKSPACE_TYPES,
} from 'gql/workspace'
import { saveActiveWorkspaceId } from 'lib/sessionStorage'
import DropZone from 'components/dropzone/Dropzone'
import { FileEntry } from 'types/shared'
import GridSelectionModal from 'components/GridSelectionModal'
import { uploadMedia } from 'lib/api'
import { FileType } from 'types/archive'
import BoardCard from 'components/BoardCard'
import Grid from '@mui/material/Grid'
import { isGermanLocale } from 'utils/language'
import { inputs } from './constants'

const Form = styled('form')(({ theme }) => ({
  justifyContent: 'center',
  alignSelf: 'center',
  alignItems: 'center',
  width: '100%',
}))

const ButtonWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: theme.spacing(2),
}))

const ActionBarWrapper = styled('div')(({ theme }) => ({
  bottom: 40,
  display: 'flex',
  position: 'sticky',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    bottom: 20,
  },
}))

interface Props {
  showSnackbar?: (message: string, severity: Severity) => void
}
function CreateWorkspace(props: Props) {
  const { showSnackbar } = props
  const intl = useIntl()
  const navigate = useNavigate()
  const params = useParams() as { organizationId: string }
  const [showErrors, setShowErrors] = useState(false)
  const [creationFlow, setCreationFlow] = useState<'custom' | 'board'>('board')
  const [selectedBoards, setSelectedBoards] = useState<string[]>([])
  const [data, setData] = useState<FormDataType>({ title: '', description: '' })
  const [files, setFiles] = useState<FileEntry[]>()

  const toggleCreationFlow = () =>
    setCreationFlow(creationFlow === 'custom' ? 'board' : 'custom')
  // dropzone adds multiple files, so we only use the last one
  useEffect(() => {
    if (files && files?.length > 1) {
      setFiles([files[files.length - 1]])
    }
  }, [files])

  const [addWorkspaceCover] = useMutation<
    UpdateWorkspaceData,
    UpdateWorkspaceInput
  >(UPDATE_WORKSPACE, {
    onCompleted: (response) => {
      saveActiveWorkspaceId(response.updateWorkspace.id)
      navigate(`${ROUTES.DASHBOARD}/${response.updateWorkspace.id}`, {
        replace: true,
      })
    },
    refetchQueries: [{ query: GROUPED_WORKSPACES }],
  })

  const [createWorkspaceFromTypes] = useMutation<
    CreateWorkspaceFromTypesData,
    CreateWorkspaceFromTypesInput
  >(CREATE_WORKSPACE_FROM_TYPES, {
    onCompleted: () => navigate(ROUTES.DASHBOARD, { replace: true }),
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: [{ query: GROUPED_WORKSPACES }],
  })

  const [createWorkspace, { loading }] = useMutation<
    CreateWorkspaceData,
    CreateWorkspaceInput
  >(CREATE_WORKSPACE, {
    onCompleted: (response) => {
      const cover = files?.[0]
      if (cover) {
        // upload cover first
        uploadCoverImage(response.createWorkspace.id, cover)
      } else {
        saveActiveWorkspaceId(response.createWorkspace.id)
        navigate(`${ROUTES.DASHBOARD}/${response.createWorkspace.id}`, {
          replace: true,
        })
      }
    },
    onError: (error) => {
      showSnackbar?.(error.message, Severity.ERROR)
    },
    refetchQueries: [{ query: GROUPED_WORKSPACES }],
  })

  const { data: wsTypes, loading: loadingTypes } = useQuery<WorkspaceTypeData>(
    WORKSPACE_TYPES,
    { variables: { organizationId: params.organizationId } }
  )
  const types = wsTypes?.workspaceTypes.list || []
  const featuredTypes = types
    .filter((t) => t.featured)
    .sort((a, b) => (a.priority ?? 0) - (b.priority ?? 0))
  const otherTypes = types
    .filter((t) => !t.featured)
    .sort((a, b) => (a.priority ?? 0) - (b.priority ?? 0))

  const uploadCoverImage = async (workspaceId: string, cover: FileEntry) => {
    const result = await uploadMedia(cover.file, {
      workspaceId,
      type: FileType.image,
    })
      .then((response) => response.json())
      .catch((error) => error)

    // eslint-disable-next-line no-underscore-dangle
    const archiveId = result?.data?._id
    if (archiveId) {
      const payload = { id: workspaceId, data: { coverImageId: archiveId } }
      addWorkspaceCover({ variables: { payload } })
    } else {
      // todo: add error message, the following does not work
      // showSnackbar(
      //   intl.formatMessage({ id: 'error.coverUploadFailed' }),
      //   Severity.ERROR
      // )

      // smth went wrong with the upload
      // go to workspace dashboard anyway
      saveActiveWorkspaceId(workspaceId)
      navigate(`${ROUTES.DASHBOARD}/${workspaceId}`, { replace: true })
    }
  }

  const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (formHasErrors(inputs, data)) {
      setShowErrors(true)
      return
    }
    const payload = {
      title: data.title as string,
      description: (data.description as string) || undefined,
      organizationId: params.organizationId,
    }
    createWorkspace({ variables: { payload } })
  }

  const onTypeSelected = (type: WorkspaceType) => {
    const selected = selectedBoards.includes(type.id)
    if (selected)
      setSelectedBoards(selectedBoards.filter((id) => id !== type.id))
    else setSelectedBoards([...selectedBoards, type.id])
  }

  const clearSelection = () => setSelectedBoards([])
  const createFromTypes = () => {
    const payload = {
      organizationId: params.organizationId,
      workspaceTypes: selectedBoards,
      languageCode: isGermanLocale(intl.locale) ? 'DE' : 'EN',
    }
    createWorkspaceFromTypes({ variables: { payload } })
  }

  const isLoading = loading || loadingTypes
  const toggleLabel =
    creationFlow === 'custom' ? 'label.selectBoard' : 'label.customBoard'
  return (
    <Container>
      <LoadingIndicator visible={isLoading} />
      <Content>
        <RowSpaced>
          <H1>{intl.formatMessage({ id: 'label.createBoard' })}</H1>
          <SectionCTA color="primary" onClick={toggleCreationFlow}>
            {intl.formatMessage({ id: toggleLabel })}
          </SectionCTA>
        </RowSpaced>
        {creationFlow === 'board' && featuredTypes.length > 0 && (
          <SectionWrapper>
            <H3>{intl.formatMessage({ id: 'label.featured' })}</H3>
            <br />
            <Grid container spacing={2}>
              {featuredTypes.map((type) => (
                <Grid item xs={12} sm={6} md={4} key={type.id}>
                  <BoardCard
                    workspaceType={type}
                    selected={selectedBoards.includes(type.id)}
                    onClick={onTypeSelected}
                  />
                </Grid>
              ))}
            </Grid>
          </SectionWrapper>
        )}

        {creationFlow === 'board' && otherTypes.length > 0 && (
          <SectionWrapper>
            <H3>{intl.formatMessage({ id: 'label.others' })}</H3>
            <br />{' '}
            <Grid container spacing={2}>
              {otherTypes.map((type) => (
                <Grid item xs={12} sm={6} md={4} key={type.id}>
                  <BoardCard
                    workspaceType={type}
                    selected={selectedBoards.includes(type.id)}
                    onClick={onTypeSelected}
                  />
                </Grid>
              ))}
            </Grid>
          </SectionWrapper>
        )}

        {creationFlow === 'board' && selectedBoards.length > 0 && (
          <ActionBarWrapper>
            <GridSelectionModal
              selectedRows={selectedBoards}
              actions={[
                {
                  label: 'label.clearSelection',
                  onClick: clearSelection,
                  variant: 'outlined',
                },
                {
                  label: 'label.save',
                  onClick: createFromTypes,
                  color: 'primary',
                },
              ]}
            />
          </ActionBarWrapper>
        )}

        {creationFlow === 'custom' && (
          <Form noValidate onSubmit={handleOnSubmit}>
            {inputs.map((input) => {
              return (
                <InputRender
                  key={input.key}
                  data={data}
                  input={input}
                  inputs={inputs}
                  setData={setData}
                  showErrors={showErrors}
                />
              )
            })}

            <DropZone
              files={files ?? []}
              setFiles={setFiles}
              showThumbnails
              dropzoneTitle={intl.formatMessage({ id: 'label.addBoardCover' })}
              fileType={FileType.image}
              clipboardEnabled={false}
            />

            <ButtonWrapper>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={formHasErrors(inputs, data)}
              >
                {intl.formatMessage({ id: 'label.createBoard' })}
              </Button>
            </ButtonWrapper>
          </Form>
        )}
      </Content>
    </Container>
  )
}

CreateWorkspace.defaultProps = {
  showSnackbar: undefined,
}
export default withSnackbar(CreateWorkspace)
