import React, { useState, useCallback } from 'react'
import { Container, Content } from 'components/shared'
import { useIntl } from 'react-intl'
import {
  PageGrid,
  PageGridLeft,
  PageGridRight,
  PageHeader,
  StickySection,
} from 'components/pages'
import PageLinksCard from 'components/pages/PageLinksCard'
import { USER_REQUESTS } from 'gql/userRequest'
import LoadingIndicator from 'components/LoadingIndicator'
import FormModal from 'components/recordDetails/FormModal'
import { JsonObject } from 'types/shared'
import { Severity, withSnackbar } from 'components/providers/SnackbarHOC'
import { AddressInput, AddressReferences } from 'types/address'
import UserReportCard from 'components/pages/UserReportCard'
import { SuggestionSection } from 'components/recordDetails/SuggestionsSection'
import { BodyText, H4 } from 'components/typography'
import { getActiveWorkspaceId } from 'lib/sessionStorage'
import { UserRequestReferenceModel, UserRequestType } from 'types/userRequest'
import Tabs from 'components/Tabs'
import { useTheme } from '@mui/material/styles'
import { useUserRequests } from 'hooks/graphql/userRequest'
// import PaymentMethods from './PaymentMethods'
// import PaymentModal from './PaymentModal'
import {
  getInitialState,
  getEditInputs,
  getSectionWithId,
  Section,
  payloadBuilder,
} from './constants'
import { useData, useMutations } from './hooks'
import contentTabs from './tabs'

interface Props {
  showSnackbar?: (message: string, severity: Severity) => void
}

function AccountPreferences({ showSnackbar }: Props) {
  const intl = useIntl()
  const theme = useTheme()
  const workspaceId = getActiveWorkspaceId()
  const [editingId, setEditingId] = useState<string>()
  const [editing, setEditing] = useState<SuggestionSection>()
  // const [openPaymentModal, setOpenPaymentModal] = useState(false)
  // const togglePaymentModal = () => setOpenPaymentModal(!openPaymentModal)
  const hideEditingModal = useCallback(() => setEditing(undefined), [])
  const {
    data: { organization, countries },
    loading: loadingData,
  } = useData(showSnackbar)

  const {
    loading: mutating,
    mutations: {
      updateOrganization,
      createAddress,
      updateAddress,
      createUserRequest,
    },
  } = useMutations(organization?.id ?? '', { showSnackbar, hideEditingModal })
  const { data: userRequests, loading: userRequestsLoading } = useUserRequests({
    referenceId: organization?.id,
    referenceModel: UserRequestReferenceModel.ORGANIZATION,
  })

  const handleRecordUpdate = (formData: JsonObject, sectionId?: string) => {
    if (organization?.id) {
      const payload = payloadBuilder(formData, organization?.id, sectionId)
      updateOrganization({ variables: { payload } })
    }
  }
  const handleOnEditWithId = (sectionId: string, recordId?: string) => {
    const section = getSectionWithId(sectionId, intl)
    setEditingId(recordId)
    setEditing(section)
  }

  // TODO: not used on V1
  const handleAddressUpdate = (formData: JsonObject) => {
    if (editingId) {
      // update record
      const data: AddressInput = {
        houseNumber: formData.houseNumber as string,
        street: formData.street as string,
        city: formData.city as string,
        countryCode: formData.countryCode as string,
        zipCode: Number(formData.zipCode),
      }
      const payload = {
        id: editingId,
        reference: AddressReferences.ORGANIZATION,
        data,
      }
      updateAddress({ variables: { payload } })
    } else {
      const payload: AddressInput = {
        houseNumber: formData.houseNumber as string,
        street: formData.street as string,
        city: formData.city as string,
        countryCode: formData.countryCode as string,
        zipCode: Number(formData.zipCode),
      }
      // new record
      createAddress({
        variables: { payload },
      }).then((response) => {
        if (response.data?.createAddress.id && organization?.id)
          updateOrganization({
            variables: {
              payload: {
                id: organization?.id,
                data: { addressId: response.data.createAddress.id },
              },
            },
          })
      })
    }
  }

  const handleUserRequestUpdate = (formData: JsonObject) => {
    // the user only uses this for refund claims.
    // the editing id is subscriptionId in this case.
    if (editingId) {
      const payload = {
        message: formData.message as string,
        type: UserRequestType.CLAIM,
        referenceModel: UserRequestReferenceModel.ORGANIZATION,
        referenceId: organization?.id,
      }
      createUserRequest({ variables: { payload } })
    }
  }

  const handleOnUpdate = (
    formData: JsonObject,
    sectionId?: string
    // files?: FileEntry[]
  ) => {
    switch (sectionId) {
      case Section.address:
        handleAddressUpdate(formData)
        break
      case Section.userRequest:
        handleUserRequestUpdate(formData)
        break
      default:
        handleRecordUpdate(formData, sectionId)
    }
  }

  const userRequestData = editingId
    ? {
        type: UserRequestType.CLAIM,
        infoLabel: 'label.userRequest.subscriptionRefundInfoLabel',
      }
    : {
        type: UserRequestType.FEEDBACK,
      }

  const inputs = getEditInputs(
    editing?.id ?? '',
    intl,
    countries,
    userRequestData
  )
  const isLoading = loadingData || mutating || userRequestsLoading
  const initialState =
    organization && editing
      ? getInitialState(intl, organization, editing.id, editingId, {
          organization,
        })
      : {}

  const tabs = contentTabs(intl, theme, organization, countries, userRequests, {
    showSnackbar,
    handleOnEditWithId,
  })
  const referenceData = organization
    ? {
        referenceId: organization?.id,
        referenceModel: UserRequestReferenceModel.ORGANIZATION,
      }
    : undefined

  return (
    <Container detailPage>
      <LoadingIndicator visible={isLoading} />
      <Content detailPage>
        <PageGrid container spacing={6}>
          <PageGridLeft item xs={12} sm={12} md={12} lg={7} xl={8}>
            <PageHeader
              pageTitle={intl.formatMessage({ id: 'label.management' })}
              recordTitle={intl.formatMessage({ id: 'label.accountCenter' })}
              breadcrumbLink=""
            />

            {workspaceId ? (
              <Tabs tabs={tabs} />
            ) : (
              <React.Fragment>
                <H4>{intl.formatMessage({ id: 'label.unauthorized' })}</H4>
                <br />
                <BodyText>
                  {intl.formatMessage({ id: 'label.unauthorizedOrganization' })}
                </BodyText>
              </React.Fragment>
            )}

            {/* <SectionTitleWrapper>
              <H3>{intl.formatMessage({ id: 'label.paymentMethods' })}</H3>
              <IconButton onClick={togglePaymentModal}>
                <AddIcon fontSize="small" color="primary" />
              </IconButton>
            </SectionTitleWrapper>
            <PaymentMethods /> */}
          </PageGridLeft>
          <PageGridRight item xs={12} sm={12} md={12} lg={5} xl={4}>
            <StickySection>
              <PageLinksCard />
              <UserReportCard
                showSnackbar={showSnackbar}
                refetchQueries={[
                  {
                    query: USER_REQUESTS,
                    variables: { payload: referenceData },
                  },
                ]}
                referenceData={referenceData}
              />
            </StickySection>
          </PageGridRight>
        </PageGrid>
      </Content>
      <br />

      {/* <PaymentModal
        open={openPaymentModal}
        onClose={togglePaymentModal}
        onSubmit={togglePaymentModal}
      /> */}
      <FormModal
        toggleModal={hideEditingModal}
        inputs={inputs}
        extraInputs={[]}
        section={editing}
        initialState={initialState}
        onSubmit={handleOnUpdate}
      />
    </Container>
  )
}

AccountPreferences.defaultProps = {
  showSnackbar: undefined,
}
export default withSnackbar(AccountPreferences)
