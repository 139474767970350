import { WorkspaceMember } from 'types/user'
import UserCard from './UserCard'

interface Props {
  member: WorkspaceMember
  canEdit?: boolean
}
class MemberCard extends UserCard<Props, {}> {
  get editable() {
    return !!this.props.canEdit
  }
}

export default MemberCard
