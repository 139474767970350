import { Status } from './shared'

export enum Gender {
  male = 'MALE',
  female = 'FEMALE',
  other = 'OTHER',
}

export interface Profile {
  id: string
  firstName: string
  lastName: string
  email: string
  birthday: Date
  gender?: Gender
  createdAt?: Date
  updatedAt?: Date
  confirmed: boolean
  registrationCountry: string
  avatar?: {
    avatar?: string
  }
}

export interface User {
  id: string
  firstName: string
  lastName: string
  email: string
}

export interface ProfileData {
  profile: Profile
}

export interface UpdateProfileInput {
  payload: {
    firstName?: string
    lastName?: string
    birthday?: string
    gender?: Gender
  }
}

export interface UpdateProfileData {
  updateProfile: Status
}
