import { isValidText } from 'utils/validators'
import { InputType, Type } from 'components/input/types'

export const inputs: InputType[] = [
  {
    key: 'title',
    label: 'label.title',
    type: Type.TEXT,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    validators: [
      {
        method: isValidText,
        label: 'label.required',
      },
    ],
  },

  {
    key: 'description',
    label: 'label.description',
    type: Type.TEXT,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    multiline: true,
    validators: [],
  },
]
