import React, { useState, useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import ROUTES from 'lib/routes'
import { GridWrapper } from 'components/shared'
import ListingActions from 'components/pages/ListingActions'
import { DataGridComp } from 'components/table/datagrid'
import { uniqBy } from 'lodash'
import { useIntl } from 'react-intl'
import { useMutation, useQuery } from '@apollo/client'
import LoadingIndicator from 'components/LoadingIndicator'
import { Container, Content } from 'components/shared'
import { Severity, withSnackbar } from 'components/providers/SnackbarHOC'
import ListingHeadline from 'components/pages/ListingHeadline'
import { useScrollPercentage } from 'utils/hooks'
import {
  ConfirmationData,
  withConfirmationModal,
} from 'components/hoc/ConfirmationModalHoc'
import {
  DeleteGroupsData,
  DeleteGroupsInput,
  GroupExtended,
  WorkspaceGroupsData,
} from 'types/group'
import { DELETE_GROUPS, GROUP_TYPES_COUNTER, WORKSPACE_GROUPS } from 'gql/group'
import { GridRowId } from '@mui/x-data-grid'
import GridSelectionModal from 'components/GridSelectionModal'
import GroupCard from 'components/GroupCard'
import { columns } from './fragments'
import EmptyList from 'components/EmptyList'

interface Props {
  showSnackbar?: (message: string, severity: Severity) => void
  requestConfirmation?: (data: ConfirmationData) => void
}

function Groups({ showSnackbar, requestConfirmation }: Props) {
  const intl = useIntl()
  const params = useParams() as { workspaceId: string }
  const [scrollPercent] = useScrollPercentage()
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(25)
  const [filters, setFilters] = React.useState<{ search: string }>()
  const [groups, setGroups] = useState<GroupExtended[]>([])
  const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([])
  const [total, setTotal] = useState(0)
  const [mode, setMode] = useState<'grid' | 'list'>('grid')
  const payload = {
    workspaceId: params.workspaceId,
    limit: pageSize,
    page,
    filters,
  }
  const { loading } = useQuery<WorkspaceGroupsData>(WORKSPACE_GROUPS, {
    variables: { payload },
    fetchPolicy: 'cache-and-network',
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    onCompleted: ({ workspaceGroups }) => {
      mode === 'grid' && total === workspaceGroups.total
        ? setGroups(uniqBy([...groups, ...workspaceGroups.list], 'id'))
        : setGroups(workspaceGroups.list)
      setTotal(workspaceGroups.total)
    },
  })
  const [deleteGroups, { loading: deleting }] = useMutation<
    DeleteGroupsData,
    DeleteGroupsInput
  >(DELETE_GROUPS, {
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: [
      { query: WORKSPACE_GROUPS, variables: { payload } },
      {
        query: GROUP_TYPES_COUNTER,
        variables: { workspaceId: payload.workspaceId },
      },
    ],
  })

  useEffect(() => {
    if (scrollPercent > 90 && !loading && groups.length < total) {
      setPage(page + 1)
    }
  }, [scrollPercent])

  const onPageChange = useCallback((_page: number) => setPage(_page), [setPage])
  const onPageSizeChange = useCallback(
    (_pageSize: number) => setPageSize(_pageSize),
    [setPageSize]
  )

  const toGridView = () => {
    if (mode === 'grid') return
    setMode('grid')
    if (page > 0) {
      setGroups([])
      setPage(0)
    }
  }
  const toListView = () => {
    if (mode === 'list') return
    setMode('list')
    if (page > 0) {
      setGroups([])
      setPage(0)
    }
  }
  const handleDelete = () =>
    requestConfirmation?.({
      title: intl.formatMessage({ id: 'label.deleteGroups' }),
      description: intl.formatMessage({
        id: 'label.deletingGroupConfirmation',
      }),
      actions: [
        {
          label: intl.formatMessage({ id: 'label.cancel' }),
        },
        {
          label: intl.formatMessage({ id: 'label.delete' }),
          color: 'error',
          onClick: () =>
            deleteGroups({
              variables: { groupIds: selectionModel as string[] },
            }),
        },
      ],
    })

  const selectAllRecords = () =>
    setSelectionModel(groups.map((record) => record.id))
  const clearSelection = () => setSelectionModel([])
  const handleOnSearch = (text: string) => {
    setFilters({ ...filters, search: text })
    setPage(0) // reset the pagination page
  }
  const handleClearFilters = () => {
    setFilters(undefined)
    setPage(0) // reset the pagination page
  }
  const selectedAll = selectionModel.length === groups.length
  const isLoading = loading || deleting

  const renderActions = () => (
    <ListingActions
      mode={mode}
      toListView={toListView}
      toGridView={toGridView}
      onSearch={handleOnSearch}
      onResetSearch={handleClearFilters}
    />
  )
  return (
    <Container>
      <LoadingIndicator visible={isLoading} />
      <Content>
        <ListingHeadline
          title="label.groups"
          total={total}
          renderActions={renderActions}
          cta={{
            label: 'label.createNewGroup',
            route: ROUTES.DASHBOARD_CREATE_GROUP_ROUTE(params.workspaceId),
          }}
        />

        {mode === 'list' ? (
          <GridWrapper>
            <DataGridComp
              initialState={{ pagination: { page, pageSize } }}
              onPageChange={onPageChange}
              onPageSizeChange={onPageSizeChange}
              rows={groups}
              columns={columns(intl)}
              checkboxSelection
              disableSelectionOnClick
              rowsPerPageOptions={[25, 50, 100]}
              rowCount={total || 0}
              experimentalFeatures={{ newEditingApi: true }}
              paginationMode="server"
              onSelectionModelChange={setSelectionModel}
              selectionModel={selectionModel}
            />
            {selectionModel.length > 0 && (
              <GridSelectionModal
                selectedRows={selectionModel}
                actions={[
                  {
                    label: selectedAll
                      ? 'label.clearSelection'
                      : 'label.selectAll',
                    onClick: selectedAll ? clearSelection : selectAllRecords,
                    variant: 'outlined',
                  },
                  {
                    label: 'label.delete',
                    onClick: handleDelete,
                    color: 'error',
                  },
                ]}
              />
            )}
          </GridWrapper>
        ) : (
          <Grid container spacing={2} marginTop={0.1}>
            {!isLoading && groups.length === 0 && (
              <Grid item xs={12}>
                <EmptyList />
              </Grid>
            )}
            {groups?.map((group) => (
              <Grid item xs={6} sm={4} md={3} xl={12 / 5} key={group.id}>
                <GroupCard record={group} />
              </Grid>
            ))}
          </Grid>
        )}
      </Content>
    </Container>
  )
}

Groups.defaultProps = {
  showSnackbar: undefined,
  requestConfirmation: undefined,
}

export default withConfirmationModal(withSnackbar(Groups))
