import React, { Fragment } from 'react'
import { styled, Theme } from '@mui/material/styles'
import appConfig from 'config/app'
import { useIntl } from 'react-intl'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import { Button } from '@mui/material'
import { ArchiveFile } from 'types/archive'
import { getAuth } from 'lib/localStorage'
import { cardBoxShadow, cardBoxShadowHover } from 'assets/styles/theme'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import DocumentPreview from 'components/DocumentPreview'
import { InternalRefetchQueriesInclude } from '@apollo/client'
import { RowSpaced } from './shared'

const Img = styled('img')<{ theme?: Theme; height?: number }>(
  ({ theme, height }) => ({
    objectFit: 'cover',
    display: 'flex',
    flex: 1,
    maxHeight: `${height ?? '200'}px`,
  })
)

const DocumentCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  margin: 0,
  boxShadow: cardBoxShadow,
  ':hover': {
    cursor: 'pointer',
    boxShadow: cardBoxShadowHover,
  },
}))

const GridContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  backgroundColor: theme.palette.background.default,
  borderRadius: '5px 5px 0 0',
}))

const ItemGrid = styled(Grid)(({ theme }) => ({}))

const ShowButton = styled(Button)(({ theme }) => ({
  margin: '20px 20px 0 0',
}))

const CtaWrapper = styled(RowSpaced)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: '0 0 5px 5px',
}))

interface CardProps {
  file: ArchiveFile
  onClick: () => void
  elementSize?: number
}
function Image({ file, onClick, elementSize }: CardProps) {
  const auth = getAuth()
  const path = `${appConfig.documentPreview}/${file.id}?token=${auth?.token}`
  return (
    <DocumentCard>
      <Img src={path} onClick={onClick} height={elementSize} />
    </DocumentCard>
  )
}
Image.defaultProps = {
  elementSize: 200,
}

function PdfDoc({ file, onClick, elementSize = 200 }: CardProps) {
  const auth = getAuth()
  const path = `${appConfig.documentPreview}/${file.id}?token=${auth?.token}`
  return (
    <DocumentCard onClick={onClick}>
      {/* @ts-ignore */}
      <Document file={path} renderMode="svg">
        {/* @ts-ignore */}
        <Page pageNumber={1} height={elementSize} />
      </Document>
    </DocumentCard>
  )
}
PdfDoc.defaultProps = {
  elementSize: 200,
}

interface Props {
  documents: ArchiveFile[]
  elementSize?: number
  refetchQueries?: InternalRefetchQueriesInclude
  onDelete?: () => void
}
function DocumentGrid({
  documents,
  elementSize,
  refetchQueries,
  onDelete,
}: Props) {
  const intl = useIntl()
  const breakpoint = 3
  const [showAll, setShowAll] = React.useState(false)
  const [previewDoc, setPreviewDoc] = React.useState<ArchiveFile>()
  const list = showAll ? documents : documents.slice(0, breakpoint)
  const toggleShowAll = () => setShowAll(!showAll)
  const onClosePreview = () => setPreviewDoc(undefined)

  return (
    <Fragment>
      <GridContainer container>
        {list.map((item) => {
          const isPdfDocument = item?.path?.toLowerCase().endsWith('.pdf')
          const onPreview = () => setPreviewDoc(item)
          return (
            <ItemGrid key={item.id} item m={1}>
              {isPdfDocument ? (
                <PdfDoc
                  onClick={onPreview}
                  file={item}
                  elementSize={elementSize}
                />
              ) : (
                <Image
                  onClick={onPreview}
                  file={item}
                  elementSize={elementSize}
                />
              )}
            </ItemGrid>
          )
        })}
      </GridContainer>
      <CtaWrapper>
        <div />
        {documents.length > breakpoint && (
          <ShowButton onClick={toggleShowAll}>
            {intl.formatMessage({
              id: showAll ? 'label.showLess' : 'label.showAll',
            })}
          </ShowButton>
        )}
      </CtaWrapper>
      <DocumentPreview
        refetchQueries={refetchQueries}
        document={previewDoc}
        onClose={onClosePreview}
        onDelete={onDelete}
      />
    </Fragment>
  )
}

DocumentGrid.defaultProps = {
  elementSize: undefined,
  refetchQueries: undefined,
}

export default DocumentGrid
