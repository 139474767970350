export const quotes = [
  {
    quote: 'label.login.quote1',
    author: 'label.login.quote1.author',
  },
  {
    quote: 'label.login.quote2',
    author: 'label.login.quote2.author',
  },
  {
    quote: 'label.login.quote3',
    author: 'label.login.quote3.author',
  },
]
