import React, { useCallback, useState } from 'react'
import SidebarCard from 'components/recordDetails/SidebarCard'
import { useIntl } from 'react-intl'
import { styled } from '@mui/material/styles'
import { Column, Row } from 'components/shared'
import MuiButton from '@mui/material/Button'
import { cardBoxShadow } from 'assets/styles/theme'
import FormModal from 'components/recordDetails/FormModal'
import { userRequestInputs } from 'utils/input-forms'
import {
  CreateUserRequestData,
  CreateUserRequestInput,
  UserRequestReferenceModel,
  UserRequestType,
} from 'types/userRequest'
import { JsonObject } from 'types/shared'
import { useMutation } from '@apollo/client'
import { CREATE_USER_REQUEST } from 'gql/userRequest'
import { Severity } from 'components/providers/SnackbarHOC'
import { MutationBaseOptions } from '@apollo/client/core/watchQueryOptions'

const ActionButton = styled(MuiButton)(({ theme }) => ({
  padding: '2px',
}))

interface Props {
  withShadow?: boolean
  showSnackbar?: (
    message: string,
    severity: Severity,
    duration?: number
  ) => void
  refetchQueries: MutationBaseOptions['refetchQueries']
  // Data to link the user request to a entity
  referenceData?: {
    referenceModel: UserRequestReferenceModel
    referenceId: string
  }
}
function ReportCard({
  withShadow,
  showSnackbar,
  refetchQueries,
  referenceData,
}: Props) {
  const intl = useIntl()
  // when type is set, show the modal
  const [type, setType] = useState<UserRequestType>()
  const [template, setTemplate] = useState<string | undefined>()
  const hideEditingModal = useCallback(() => setType(undefined), [])
  const inputs = type ? userRequestInputs(type) : []
  const initialState = { message: template ?? '' }
  // in case a template text is passed the input must be prefilled with the text
  const startRequest = (t: UserRequestType, templateText?: string) => {
    setType(t)
    setTemplate(templateText)
  }

  const [createUserRequest] = useMutation<
    CreateUserRequestData,
    CreateUserRequestInput
  >(CREATE_USER_REQUEST, {
    onCompleted: () => {
      showSnackbar?.(
        intl.formatMessage({ id: `label.userRequest.success.${type}` }),
        Severity.SUCCESS
      )
      hideEditingModal()
    },
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries,
  })

  const onSubmit = (formData: JsonObject) => {
    if (!type) return
    const payload = {
      ...referenceData,
      message: formData.message as string,
      type,
    }
    createUserRequest({ variables: { payload } })
  }

  return (
    <SidebarCard
      containerStyle={withShadow ? { boxShadow: cardBoxShadow } : {}}
      title={intl.formatMessage({ id: 'label.userRequest.cardTitle' })}
    >
      <Column>
        <Row>
          <ActionButton onClick={() => startRequest(UserRequestType.CLAIM)}>
            •{intl.formatMessage({ id: 'label.userRequest.CLAIM' })}
          </ActionButton>
        </Row>
        <Row>
          <ActionButton onClick={() => startRequest(UserRequestType.INQUIRY)}>
            •{intl.formatMessage({ id: 'label.userRequest.INQUIRY' })}
          </ActionButton>
        </Row>
        <Row>
          <ActionButton onClick={() => startRequest(UserRequestType.FEEDBACK)}>
            •{intl.formatMessage({ id: 'label.userRequest.FEEDBACK' })}
          </ActionButton>
        </Row>
        <Row>
          <ActionButton
            onClick={() =>
              startRequest(
                UserRequestType.FEEDBACK,
                intl.formatMessage({
                  id: 'label.userRequest.FEATURE_REQUEST.template',
                })
              )
            }
          >
            •{intl.formatMessage({ id: 'label.userRequest.FEATURE_REQUEST' })}
          </ActionButton>
        </Row>
      </Column>
      {type && (
        <FormModal
          toggleModal={hideEditingModal}
          inputs={inputs}
          extraInputs={[]}
          section={{
            id: 'user-request',
            title: intl.formatMessage({ id: 'label.userRequest' }),
            description: '',
            cta: '',
          }}
          initialState={initialState}
          onSubmit={onSubmit}
        />
      )}
    </SidebarCard>
  )
}

ReportCard.defaultProps = {
  withShadow: false,
  showSnackbar: undefined,
  referenceData: {},
}
export default ReportCard
