import React from 'react'
import { useIntl } from 'react-intl'
import { Theme, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useNavigate } from 'react-router-dom'
import { styled, lighten } from '@mui/material/styles'
import { getActiveWorkspaceId, saveActiveWorkspaceId } from 'lib/sessionStorage'
import { Workspace, WorkspaceExtended } from 'types/workspace'
import List from '@mui/material/List'
import MuiItemButton from '@mui/material/ListItemButton'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import ROUTES from 'lib/routes'
import { useGroupedWorkspaces } from 'hooks/graphql/workspaces'
import placeholder from 'assets/images/workspacePlaceholder.jpg'
import appConfig from 'config/app'
import { BodyTextLight, H4 } from 'components/typography'
import SwipeDrawerHoc from 'components/SwipeDrawerHoc'
import CheckIcon from '@mui/icons-material/Check'
import { Row, RowSpaced } from 'components/shared'
import { useQuery } from '@apollo/client'
import { ProfileData } from 'types/profile'
import { PROFILE } from 'gql/user'
import { Divider } from '@mui/material'
// import { ListSubheader } from './fragments'

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
}))
const ListItemButton = styled(MuiItemButton, {
  shouldForwardProp: (prop) => prop !== 'withSpacing',
})<{ withSpacing?: boolean }>(({ theme, withSpacing }) => ({
  ...(withSpacing ? { margin: '0 20px' } : {}),
  flexGrow: 1,
  display: 'flex',
  borderRadius: '3px',
  '&.Mui-selected': {
    backgroundColor: theme.palette.action.active,
  },
  '&:hover': {
    backgroundColor: lighten(theme.palette.action.active, 0.7),
  },
}))

const UnfoldIcon = styled(UnfoldMoreIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginLeft: 'auto',
}))

const WorkspaceCardWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'hasBorder',
})<{ hasBorder?: boolean }>(({ theme, hasBorder }) => ({
  display: 'flex',
  backgroundColor: lighten(theme.palette.primary.main, 0.2),
  border: hasBorder ? `2px solid ${theme.palette.primary.main}` : 'none',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  marginRight: '8px',
}))

const Media = styled('img')(({ theme }) => ({
  width: '40px',
  height: '40px',
  objectFit: 'cover',
}))

export const WSTitle = styled(H4, {
  shouldForwardProp: (prop) => prop !== 'numberOfLines',
})<{
  theme?: Theme
  numberOfLines?: number
}>(({ numberOfLines }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: numberOfLines ?? 1,
  lineClamp: numberOfLines ?? 1,
  WebkitBoxOrient: 'vertical',
}))
interface WorkspaceCardProps {
  workspace: Workspace
  onClick?: (workspace: Workspace) => void
  hideIcon?: boolean
}
WorkspaceCard.defaultProps = { onClick: null, hideIcon: false }

function WorkspaceCard({ workspace, onClick, hideIcon }: WorkspaceCardProps) {
  const theme = useTheme()
  const handleOnClick = () => onClick?.(workspace)
  const workspaceId = getActiveWorkspaceId()
  const cover = workspace.coverImage?.medium
    ? `${appConfig.media}${workspace.coverImage.medium}`
    : placeholder
  const isSelectedWs = workspace.id === workspaceId

  const titleColor = isSelectedWs
    ? theme.palette.text.primary
    : theme.palette.text.secondary
  return (
    <RowSpaced flex>
      <Row>
        <WorkspaceCardWrapper
          onClick={handleOnClick}
          // hasBorder={workspace.id === workspaceId}
        >
          <Media src={cover} />
        </WorkspaceCardWrapper>
        <Row flex>
          <WSTitle numberOfLines={1} color={titleColor}>
            {workspace.title}
          </WSTitle>
        </Row>
      </Row>
      {!hideIcon && isSelectedWs && <CheckIcon />}
    </RowSpaced>
  )
}

interface Props {
  toggleDrawer?: () => void
  withSpacing?: boolean
}
function WorkspaceSelection({ toggleDrawer, withSpacing }: Props) {
  const intl = useIntl()
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)
  const navigate = useNavigate()
  const workspaceId = getActiveWorkspaceId()
  const { data: sections } = useGroupedWorkspaces()
  const { data } = useQuery<ProfileData>(PROFILE)
  const profile = data?.profile
  const workspaces = sections.reduce(
    (acc, section) => [...acc, ...section.workspaces],
    [] as WorkspaceExtended[]
  )
  const focusedWorkspace = workspaces?.find((w) => w.id === workspaceId)
  const toggleVisibility = () => setOpen(!open)
  const isBigScreen = useMediaQuery(theme.breakpoints.up('md'))
  const onSelectWorkspace = (workspace: Workspace) => {
    saveActiveWorkspaceId(workspace.id)
    navigate(`${ROUTES.DASHBOARD}/${workspace.id}`, { replace: true })
    isBigScreen && toggleDrawer?.() // only applies to desktop version
  }

  return (
    <Container>
      <ListItemButton withSpacing={withSpacing} onClick={toggleVisibility}>
        {focusedWorkspace && (
          <WorkspaceCard hideIcon workspace={focusedWorkspace} />
        )}
        {isBigScreen && <UnfoldIcon />}
      </ListItemButton>
      <SwipeDrawerHoc open={open} toggleVisibility={toggleVisibility}>
        {/* {isBigScreen && (
          <ListSubheader>
            {intl.formatMessage({ id: 'label.selectBoard' })}
          </ListSubheader>
        )} */}
        <List component="div" disablePadding>
          {sections.map((section) => (
            <React.Fragment key={section.organizationId}>
              <BodyTextLight style={{ paddingLeft: '10px' }}>
                {profile?.id === section.ownerId
                  ? intl.formatMessage({ id: 'label.yourBoards' })
                  : intl.formatMessage(
                      { id: 'label.boardsFrom' },
                      { name: section.title }
                    )}
              </BodyTextLight>
              {section.workspaces.map((w) => {
                const onClick = () => {
                  onSelectWorkspace(w)
                  toggleVisibility()
                }
                return (
                  <ListItemButton
                    key={`workspace-selector-${w.id}`}
                    onClick={onClick}
                  >
                    <WorkspaceCard workspace={w} />
                  </ListItemButton>
                )
              })}
              <Divider style={{ margin: '10px 0' }} />
            </React.Fragment>
          ))}
        </List>
      </SwipeDrawerHoc>
    </Container>
  )
}

WorkspaceSelection.defaultProps = {
  toggleDrawer: null,
  withSpacing: false,
}

export default WorkspaceSelection
