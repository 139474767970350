import React, { forwardRef } from 'react'
import { BodyTextLight, H2 } from 'components/typography'
import Button from '@mui/material/Button'
import { useIntl } from 'react-intl'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import organize from 'assets/organize.json'
import Lottie from 'react-lottie'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

type Props = {}
export interface PurchaseSuccessfulModalMethods {
  openModal: () => void
}

const PurchaseSuccessfulModal = forwardRef(
  (props: Props, ref: React.ForwardedRef<PurchaseSuccessfulModalMethods>) => {
    const intl = useIntl()
    const [open, setOpen] = React.useState(false)
    const openModal = () => setOpen(true)
    const handleClose = () => setOpen(false)
    React.useImperativeHandle(ref, () => ({ openModal }))
    return (
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
      >
        <DialogTitle>
          <H2>{intl.formatMessage({ id: 'label.purchaseSuccessful' })}</H2>
        </DialogTitle>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: organize,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
          isClickToPauseDisabled
          height="200px"
          width="300px"
        />
        <DialogContent>
          <BodyTextLight>
            {intl.formatMessage({ id: 'label.purchaseSuccessfulMessage' })}
          </BodyTextLight>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            {intl.formatMessage({ id: 'label.close' })}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
)

export default PurchaseSuccessfulModal
