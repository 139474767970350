import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useTheme } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import { useMutation } from '@apollo/client'
import LoadingIndicator from 'components/LoadingIndicator'
import { Button, Column, Row } from 'components/shared'
import { Severity, withSnackbar } from 'components/providers/SnackbarHOC'
import { saveAuth } from 'lib/localStorage'
import StyledText from 'components/StyledIntlText'
import ROUTES from 'lib/routes'
import { REGISTER } from 'gql/auth'
import { RegisterData, RegisterInput } from 'types/auth'
import InputRender, { formHasErrors } from 'components/input/InputRender'
import { FormDataType } from 'components/input/types'
import { H6 } from 'components/typography'
import { inputs, nameInputs } from './constants'
import { Box, Checkbox, FormHelperText, Toolbar } from '@mui/material'
import {
  Container,
  LinkButton,
  RightGrid,
  Form,
  ScreenGrid,
} from '../fragments'
import QuotesGrid from '../QuotesGrid'
import MuiHeader from 'pages/landing/MuiHeader'

function SignUp(props: {
  showSnackbar?: (message: string, severity: Severity) => void
}) {
  const { showSnackbar } = props
  const navigate = useNavigate()
  const intl = useIntl()
  const theme = useTheme()
  const location = useLocation()
  const isDashboard = location.pathname.includes(ROUTES.DASHBOARD)
  const [showErrors, setShowErrors] = useState(false)
  const [agreedTos, setAgreedTos] = useState(false)
  const toggleAgreement = () => setAgreedTos(!agreedTos)
  const [data, setData] = useState<FormDataType>({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    registrationCountry: 'DE',
  })

  const [registerUser, { loading }] = useMutation<RegisterData, RegisterInput>(
    REGISTER,
    {
      onCompleted: (response) => {
        saveAuth(response.register)
        navigate(ROUTES.INDEX, { replace: true })
      },
      onError: (error) => {
        showSnackbar?.(error.message, Severity.ERROR)
      },
    }
  )
  const nameFormInputs = nameInputs(intl)
  const formInputs = inputs(intl)
  // const classes = useStyles()
  const handleOnsubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (formHasErrors(formInputs, data) || !agreedTos) {
      setShowErrors(true)
      return
    }
    const payload = {
      firstName: data.firstName as string,
      lastName: data.lastName as string,
      email: data.email as string,
      password: data.password as string,
      registrationCountry: data.registrationCountry as string,
    }
    registerUser({ variables: { payload } })
  }

  return (
    <Container>
      {!isDashboard && (
        <React.Fragment>
          <MuiHeader />
          <Toolbar />
        </React.Fragment>
      )}
      <LoadingIndicator visible={loading} />
      <ScreenGrid container>
        <QuotesGrid />
        <RightGrid item md={6} xs={12}>
          <Form noValidate onSubmit={handleOnsubmit}>
            <StyledText text="label.register.headline" fontSize={30} />
            <Box height="15px" />
            <Grid container spacing={1}>
              {nameFormInputs.map((input) => {
                return (
                  <Grid xs={6} item key={input.key}>
                    <InputRender
                      data={data}
                      input={input}
                      inputs={nameFormInputs}
                      setData={setData}
                      showErrors={showErrors}
                    />
                  </Grid>
                )
              })}
            </Grid>
            {formInputs.map((input) => {
              return (
                <InputRender
                  key={input.key}
                  data={data}
                  input={input}
                  inputs={formInputs}
                  setData={setData}
                  showErrors={showErrors}
                />
              )
            })}
            <br />
            <Column>
              <Row style={{ flexWrap: 'nowrap' }}>
                <Checkbox
                  checked={agreedTos}
                  style={{ color: theme.palette.action.active }}
                  onClick={toggleAgreement}
                />
                <H6>
                  <StyledText
                    text="label.register.aggrement.part1"
                    fontSize={14}
                    injectedValues={{ link: ROUTES.TOS }}
                  />{' '}
                  <StyledText
                    text="label.register.aggrement.part2"
                    fontSize={14}
                    injectedValues={{ link: ROUTES.WITHDRAWAL }}
                  />
                </H6>
              </Row>
              {showErrors && !agreedTos && (
                <FormHelperText error sx={{ marginLeft: '20px' }}>
                  {intl.formatMessage({ id: 'label.required' })}
                </FormHelperText>
              )}
            </Column>
            <br />
            <Button type="submit" fullWidth variant="contained" color="primary">
              {intl.formatMessage({ id: 'label.register' })}
            </Button>
            <Grid container justifyContent="center">
              <Grid item>
                <LinkButton to={ROUTES.LOGIN}>
                  {intl.formatMessage({ id: 'label.login' })}
                </LinkButton>
              </Grid>
            </Grid>
          </Form>
        </RightGrid>
      </ScreenGrid>
    </Container>
  )
}

SignUp.defaultProps = {
  showSnackbar: undefined,
}

export default withSnackbar(SignUp)
