import React from 'react'
import { useIntl } from 'react-intl'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { quotes } from './constants'
import { rocketMan } from 'assets/images'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { H5 } from 'components/typography'
import { FontFamily } from 'assets'
import { LeftGrid, RocketMan } from './fragments'

function QuotesGrid() {
  const intl = useIntl()
  const theme = useTheme()
  const isBigScreen = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <React.Fragment>
      {isBigScreen && (
        <LeftGrid item md={6}>
          <RocketMan src={rocketMan} style={{}} alt="Login image" />

          <div style={{ maxWidth: '400px' }}>
            <Carousel
              showThumbs={false}
              showStatus={false}
              autoPlay
              showIndicators={false}
              showArrows={false}
              infiniteLoop
              interval={5000}
              transitionTime={1000}
            >
              {quotes.map((item) => (
                <div
                  key={item.quote}
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                  }}
                >
                  <H5
                    fontFamily={FontFamily.NoirPro}
                    style={{ textAlign: 'left', marginBottom: '20px' }}
                  >
                    "{intl.formatMessage({ id: item.quote })}"
                  </H5>
                  <H5
                    fontFamily={FontFamily.NoirProMedium}
                    style={{ fontStyle: 'italic' }}
                  >
                    - {intl.formatMessage({ id: item.author })}
                  </H5>
                </div>
              ))}
            </Carousel>
          </div>
        </LeftGrid>
      )}
    </React.Fragment>
  )
}
export default QuotesGrid
