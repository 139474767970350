import React from 'react'
import { useIntl } from 'react-intl'
import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import ListIcon from '@mui/icons-material/FormatListBulletedSharp'
import GridViewIcon from '@mui/icons-material/GridView'
import SearchIcon from '@mui/icons-material/SearchOutlined'
import CloseIcon from '@mui/icons-material/CloseOutlined'
import SearchInput from '../SearchInput'
import { Row } from '../shared'
import { H6 } from '../typography'

const Separator = styled('span')(({ theme }) => ({
  border: `1px solid ${theme.palette.text.primary}`,
}))

const Wrapper = styled(Row)({ flex: 1, justifyContent: 'flex-end' })
interface ListingActionsProps {
  mode: 'list' | 'grid'
  toGridView: () => void
  toListView: () => void
  onSearch?: (text: string) => void
  onResetSearch?: () => void
}
function ListingActions({
  mode,
  toListView,
  toGridView,
  onSearch,
  onResetSearch,
}: ListingActionsProps) {
  const intl = useIntl()
  const [visible, setVisible] = React.useState(false)
  const toggleVisibility = () => {
    setVisible(!visible)
    onResetSearch?.()
  }
  const SIcon = visible ? CloseIcon : SearchIcon
  return (
    <Wrapper>
      <SearchInput visible={visible} onSubmit={onSearch} autoFocus />
      <div>
        <IconButton onClick={toggleVisibility} sx={{ borderRadius: 200 }}>
          <SIcon color="action" />
          {!visible && <H6>{intl.formatMessage({ id: 'label.search' })}</H6>}
        </IconButton>
        <Separator />
        <IconButton onClick={toGridView}>
          <GridViewIcon color={mode === 'grid' ? 'inherit' : 'disabled'} />
        </IconButton>
        <IconButton onClick={toListView}>
          <ListIcon color={mode === 'list' ? 'inherit' : 'disabled'} />
        </IconButton>
      </div>
    </Wrapper>
  )
}
ListingActions.defaultProps = {
  onSearch: null,
  onResetSearch: null,
}
export default ListingActions
