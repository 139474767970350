import { useMutation, useQuery } from '@apollo/client'
import { Severity } from 'components/providers/SnackbarHOC'
import { NavigateFunction } from 'react-router-dom'
import {
  MEMBER_BY_ID,
  REMOVE_WORKSPACE_MEMBER,
  UPDATE_WORKSPACE_MEMBER,
  WORKSPACE_MEMBERS,
} from 'gql/workspace'
import { MemberByIdData } from 'types/memberRefs'
import { MemberModels } from 'types/shared'
import { ProfileData } from 'types/profile'
import { PROFILE } from 'gql/user'
import {
  RemoveWorkspaceMemberData,
  RemoveWorkspaceMemberInput,
  UpdateWorkspaceMemberData,
  UpdateWorkspaceMemberInput,
} from 'types/workspace'
import { ADD_FILE_TO_MEMBER } from 'gql/archive'
import { AddFileToMemberData, AddFileToMemberInput } from 'types/archive'

export function useData(
  memberId: string,
  memberModel: MemberModels,
  workspaceId: string
) {
  const { data: memberData, loading: memberLoading } = useQuery<MemberByIdData>(
    MEMBER_BY_ID,
    {
      fetchPolicy: 'cache-and-network',
      variables: { payload: { memberId, memberModel, workspaceId } },
    }
  )

  const { data: profileData, loading: profileLoading } =
    useQuery<ProfileData>(PROFILE)

  const record = memberData?.memberById
  const profile = profileData?.profile
  const loading = memberLoading || profileLoading
  return {
    loading,
    data: {
      record,
      profile,
    },
  }
}

export function useMutations(
  payload: {
    memberId: string
    workspaceId: string
    memberModel: MemberModels
    navigate: NavigateFunction
  },
  methods: {
    showSnackbar?: (message: string, severity: Severity) => void
    hideEditingModal: () => void
  }
) {
  const { showSnackbar, hideEditingModal } = methods
  const { memberId, workspaceId, memberModel, navigate } = payload
  const refetchListing = {
    query: WORKSPACE_MEMBERS,
    variables: { workspaceId },
  }
  const refetchQueries = [
    {
      query: MEMBER_BY_ID,
      variables: {
        payload: {
          memberId,
          workspaceId,
          memberModel,
        },
      },
    },
    {
      query: WORKSPACE_MEMBERS,
      variables: { workspaceId },
    },
  ]

  const [deleteMember, { loading: deleting }] = useMutation<
    RemoveWorkspaceMemberData,
    RemoveWorkspaceMemberInput
  >(REMOVE_WORKSPACE_MEMBER, {
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    onCompleted: () => navigate(-1),
    refetchQueries: [refetchListing],
  })

  const [updateWorkspaceMember, { loading: updating }] = useMutation<
    UpdateWorkspaceMemberData,
    UpdateWorkspaceMemberInput
  >(UPDATE_WORKSPACE_MEMBER, {
    onCompleted: hideEditingModal,
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries,
  })

  const [addFileToMember] = useMutation<
    AddFileToMemberData,
    AddFileToMemberInput
  >(ADD_FILE_TO_MEMBER, {
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries,
  })

  const loading = updating || deleting
  return {
    loading,
    mutations: {
      updateWorkspaceMember,
      deleteMember,
      addFileToMember,
    },
  }
}
