import React, { CSSProperties } from 'react'
import { useIntl } from 'react-intl'
import { styled, lighten } from '@mui/material/styles'
import { Link } from 'react-router-dom'
import { FontFamily } from 'assets'

const Text = styled('span')<{
  fontSize: number
  fontFamily?: FontFamily
  color?: string
  lineHeight?: CSSProperties['lineHeight']
}>(({ theme, fontSize, fontFamily, color, lineHeight }) => ({
  display: 'inline-block',
  fontFamily: fontFamily ?? FontFamily.NoirPro,
  fontSize: `${fontSize}px`,
  fontWeight: 400,
  lineHeight: lineHeight ?? `${fontSize - 2}px`,
  letterSpacing: '0em',
  color: color ?? theme.palette.text.primary,
}))

const SpanText = styled('span')<{ fontSize: number }>(
  ({ theme, fontSize }) => ({
    display: 'inline-block',
    ':after': {
      margin: `-${Math.round(fontSize / 5)}px -${Math.round(fontSize / 5)}px 0 -${Math.round(fontSize / 6) - 3}px`,
      content: '""',
      background: lighten(theme.palette.primary.main, 0.7),
      display: 'block',
      borderRadius: `${fontSize}px`,
      height: `${Math.round(fontSize / 4)}px`,
    },
  })
)

const LinkComp = styled(Link)(({ theme }) => ({
  color: 'inherit',
  // ':hover span': {
  //   textDecoration: 'none',
  //   color: theme.palette.text.primary,
  // },
}))

interface Props {
  text: string
  fontSize: number
  fontFamily?: FontFamily
  color?: string
  paintColor?: string
  lineHeight?: CSSProperties['lineHeight']
  // in case of links, one link at a time can be supported, and a `link` injectedValue is expected.
  injectedValues?: { [key: string]: string }
}

function StyledText(props: Props) {
  const {
    text,
    injectedValues,
    fontSize,
    fontFamily,
    color,
    lineHeight,
    paintColor,
  } = props
  const intl = useIntl()
  return (
    <Text
      fontSize={fontSize}
      color={color}
      fontFamily={fontFamily}
      lineHeight={lineHeight}
    >
      {/* @ts-ignore */}
      {intl.formatMessage(
        { id: text, defaultMessage: text },
        {
          ...injectedValues,
          underline: (str) => <SpanText fontSize={fontSize}>{str}</SpanText>,
          link: (str) => (
            <LinkComp to={injectedValues?.link ?? ''}>{str}</LinkComp>
          ),
          strong: (str) => (
            <Text
              color={paintColor}
              fontFamily={fontFamily}
              fontSize={fontSize}
              style={{ fontWeight: 'bold' }}
            >
              {str}
            </Text>
          ),
          paint: (str) => (
            <Text
              color={paintColor}
              fontFamily={fontFamily}
              fontSize={fontSize}
            >
              {str}
            </Text>
          ),
        }
      )}
    </Text>
  )
}

StyledText.defaultProps = {
  injectedValues: {},
  fontFamily: null,
}

export default StyledText
