import { useMutation, useQuery } from '@apollo/client'
import { Severity } from 'components/providers/SnackbarHOC'
import { getActiveWorkspaceId } from 'lib/sessionStorage'
import { getErrorCode } from 'lib/errorResolver'
import {
  OrganizationDataQuery,
  UpdateOrganizationData,
  UpdateOrganizationInput,
} from 'types/organization'
import { ORGANIZATION_DATA, UPDATE_ORGANIZATION } from 'gql/organization'
import errorCodes from 'lib/errorCodes'
import { useMutations as addressMutations } from 'hooks/graphql/address'
import { COUNTRIES } from 'gql/static'
import { CountriesData } from 'types/static'
import { CREATE_USER_REQUEST, USER_REQUESTS } from 'gql/userRequest'
import {
  CreateUserRequestData,
  CreateUserRequestInput,
  UserRequestReferenceModel,
} from 'types/userRequest'

export function useData(
  showSnackbar?: (message: string, severity: Severity) => void
) {
  const workspaceId = getActiveWorkspaceId()
  const { data: orgData, loading: orgLoading } =
    useQuery<OrganizationDataQuery>(ORGANIZATION_DATA, {
      variables: { workspaceId },
      onError: (error) => {
        const code = getErrorCode(error)
        const errors = [
          errorCodes.UNAUTHORIZED,
          errorCodes.NOT_FOUND,
          errorCodes.INVALID_REQUEST,
        ]
        if (code && errors.includes(code)) {
          showSnackbar?.(error.message, Severity.ERROR)
        }
      },
    })

  const { data: countriesData, loading: countriesLoading } =
    useQuery<CountriesData>(COUNTRIES)

  const organization = orgData?.organizationData
  const loading = orgLoading || countriesLoading
  return {
    loading,
    data: { organization, countries: countriesData?.countries ?? {} },
  }
}

export function useMutations(
  organizationId: string,
  methods: {
    showSnackbar?: (message: string, severity: Severity) => void
    hideEditingModal: () => void
  }
) {
  const workspaceId = getActiveWorkspaceId()
  const refetchQueries = [
    { query: ORGANIZATION_DATA, variables: { workspaceId } },
  ]
  const { showSnackbar, hideEditingModal } = methods
  const {
    loading: addressLoading,
    mutations: { createAddress, updateAddress },
  } = addressMutations(
    { showSnackbar, onCompleted: hideEditingModal },
    refetchQueries
  )

  const [updateOrganization, { loading: updateLoading }] = useMutation<
    UpdateOrganizationData,
    UpdateOrganizationInput
  >(UPDATE_ORGANIZATION, {
    onCompleted: hideEditingModal,
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries,
  })

  const [createUserRequest, { loading: createLoading }] = useMutation<
    CreateUserRequestData,
    CreateUserRequestInput
  >(CREATE_USER_REQUEST, {
    onCompleted: hideEditingModal,
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: [
      { query: ORGANIZATION_DATA, variables: { workspaceId } },
      {
        query: USER_REQUESTS,
        variables: {
          payload: {
            referenceId: organizationId,
            referenceModel: UserRequestReferenceModel.ORGANIZATION,
          },
        },
      },
    ],
  })
  const loading = updateLoading || addressLoading || createLoading
  return {
    loading,
    mutations: {
      updateOrganization,
      createAddress,
      updateAddress,
      createUserRequest,
    },
  }
}
