import React from 'react'
import { styled, lighten } from '@mui/material/styles'
import { Row } from 'components/shared'
import { useQuery } from '@apollo/client'
import { PROFILE } from 'gql/user'
import { ProfileData } from 'types/profile'
// import VerifiedIcon from '@mui/icons-material/Verified'
import appConfig from 'config/app'
import { H6 } from 'components/typography'
import { Avatar } from 'components/user'

const InfoWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  color: lighten('#000', 0.13),
  margin: '0 30px 0 15px',
}))

// const VerifiedWrapper = styled('div')(({ theme }) => ({
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',
//   padding: '6px',
//   borderRadius: '40px',
//   backgroundColor: lighten(theme.palette.primary.main, 0.8),
// }))
// const Icon = styled(VerifiedIcon)({
//   fontSize: '16px',
// })

// function VerifiedBadge() {
//   return (
//     <VerifiedWrapper>
//       <Icon color="primary" />
//     </VerifiedWrapper>
//   )
// }

function AccountEntry() {
  const { data } = useQuery<ProfileData>(PROFILE)
  const profile = data?.profile
  const avatar = profile?.avatar?.avatar
    ? `${appConfig.media}${profile?.avatar?.avatar}`
    : ''
  const placeholder = `${profile?.firstName.substring(0, 1) ?? ''}${
    profile?.lastName.substring(0, 1) ?? ''
  }`
  return (
    <Row>
      <Avatar
        placeholder={placeholder}
        src={avatar}
        alt={`${profile?.firstName ?? ''} ${profile?.lastName ?? ''}`}
      />
      <InfoWrapper>
        <H6>
          {profile?.firstName} {profile?.lastName}
        </H6>
      </InfoWrapper>
      {/* <VerifiedBadge /> */}
    </Row>
  )
}
export default AccountEntry
