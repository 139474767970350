import { isValidPassword, isValidText } from 'utils/validators'
import { FormDataType, InputType, Type } from 'components/input/types'

const doPasswordsMatch = (
  password: string,
  confirmPassword: string
): boolean => {
  return password === confirmPassword
}

export const inputs = (data: FormDataType): InputType[] => [
  {
    key: 'password',
    label: 'label.newPassword',
    type: Type.PASSWORD,
    autoFocus: false,
    fullWidth: true,
    margin: 'normal',
    validators: [
      {
        method: isValidText,
        label: 'label.required',
      },
      {
        method: isValidPassword,
        label: 'label.invalidPassword',
      },
    ],
  },
  {
    key: 'confirmPassword',
    label: 'label.confirmPassword',
    type: Type.PASSWORD,
    autoFocus: false,
    fullWidth: true,
    margin: 'normal',
    validators: [
      {
        method: isValidText,
        label: 'label.required',
      },
      {
        method: <T = string>(confirmPassword: T) =>
          typeof confirmPassword === 'string' &&
          doPasswordsMatch(confirmPassword, data?.password as string),
        label: 'label.passwordMismatch',
      },
    ],
  },
]
