import React from 'react'
import { useIntl } from 'react-intl'
import MuiTab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import MuiTabList from '@mui/lab/TabList'
import Box from '@mui/material/Box'
import { alpha, lighten, styled, Theme, useTheme } from '@mui/material/styles'
import { debounce } from 'lodash'
import { getViewPercentage } from 'utils/html'
import lock from 'assets/images/lock.svg'
import { Column } from './shared'
import { H5 } from './typography'

const Container = styled(Column)(({ theme }) => ({
  // must render column revered so the tabs are rendered over the content
  flexDirection: 'column-reverse',
}))

export const TabComp = styled(MuiTab)<{ selected: boolean; theme?: Theme }>(
  ({ theme, selected }) => ({
    '&.MuiTab-root': {
      opacity: 1,
      borderRadius: '60px',
    },
    ':hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.4),
      h5: {
        color: theme.palette.background.default,
      },
    },
    ...(selected && {
      backgroundColor: theme.palette.primary.main,
      h5: {
        color: theme.palette.background.default,
      },
    }),
  })
)
export const TabList = styled(MuiTabList)(({ theme }) => ({
  '&.MuiTabs-root .MuiTabs-indicator': {
    height: 0,
  },
  flex: 1,
  padding: '4px',
  borderRadius: '60px',
  backgroundColor: theme.palette.shared.darkGray,
}))

export const TabContainer = styled('div')(({ theme }) => ({
  width: '100%',
  position: 'sticky',
  top: theme.mixins.toolbar.height || theme.mixins.toolbar.minHeight,
  marginBottom: '16px',
}))

export const TabWrapper = styled(Box)(({ theme }) => ({
  position: 'sticky',
  display: 'flex',
  [theme.breakpoints.up('sm')]: {
    padding: '10px 0',
  },
  borderRadius: '5px',
}))

const LockOverlay = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  backdropFilter: 'blur(3px)',
  backgroundColor: alpha(theme.palette.action.active, 0.2),
  justifyContent: 'center',
  borderRadius: '5px',
}))

const Image = styled('img')(({ theme }) => ({
  height: '80px',
  objectFit: 'contain',
  width: 'auto',
  opacity: '.8',
  borderRadius: '40px',
  margin: '50px 0',
  boxShadow:
    'inset 5px 5px 30px 0px rgba(255,255,255,1),  5px 5px 30px 0px rgba(255,255,255,1);;',
}))

const ScrollableTabsWrapper = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  overflow: 'auto',
  scrollbarWidth: 'none',
  '::-webkit-scrollbar': {
    width: 0,
    height: 0,
  },
}))

export interface TabSection {
  key: string
  label: string
  content: JSX.Element | null
  visible?: boolean
}
interface Props {
  tabs: TabSection[]
  locked?: boolean
}

function TabBar({ tabs, locked }: Props) {
  const intl = useIntl()
  const theme = useTheme()
  const [value, setValue] = React.useState(tabs?.[0]?.key ?? 0)
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }
  React.useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [tabs])
  const handleScroll = debounce(() => {
    const elements = tabs.map((_, index) =>
      document.getElementById(`tab-section-${index}`)
    )
    const percentages = elements.map(getViewPercentage)
    const indexInViewport = percentages.indexOf(Math.max(...percentages))
    setValue(tabs[indexInViewport]?.key)
    scrollToTab(tabs[indexInViewport]?.key)
  })

  const scrollToTab = debounce((tab: string) => {
    const wrapperTabs = document.getElementById('tab-bar-wrapper')
    const tabIndex = tabs.findIndex((i) => i.key === tab)
    const tabElement = document.getElementById(`tab-label-${tabIndex}`)
    if (wrapperTabs && tabElement) {
      const x =
        tabElement.getBoundingClientRect().left -
        wrapperTabs.getBoundingClientRect().left
      wrapperTabs.scrollTo({ left: x, behavior: 'smooth' })
    }
  })

  const handleOnTabClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    // @ts-expect-error property might not exist
    const tabIndex = event.target?.tabIndex ?? -1
    const element = document.getElementById(`tab-section-${tabIndex}`)
    if (element) {
      const yOffset = -150
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset
      window.scrollTo({ top: y, behavior: 'smooth' })
    }
  }

  return (
    <Container>
      <div style={{ position: 'relative' }}>
        {tabs.map((item, index) => (
          <div key={item.key} id={`tab-section-${index}`}>
            {item.content}
          </div>
        ))}
        {locked && (
          <LockOverlay>
            <Image src={lock} />
          </LockOverlay>
        )}
      </div>
      <TabContainer>
        <TabContext value={value}>
          <TabWrapper>
            <TabList onChange={handleChange} indicatorColor="primary">
              <TabsWrapper>
                {tabs.map((item, index) => (
                  <TabComp
                    id={`tab-label-${index}`}
                    key={item.key}
                    value={item.key}
                    selected={item.key === value}
                    tabIndex={index}
                    onClick={handleOnTabClick}
                    label={
                      <H5 tabIndex={index} color={theme.palette.text.secondary}>
                        {intl.formatMessage({
                          id: item.label,
                          defaultMessage: item.label,
                        })}
                      </H5>
                    }
                  />
                ))}
              </TabsWrapper>
            </TabList>
          </TabWrapper>
        </TabContext>
      </TabContainer>
    </Container>
  )
}

function TabsWrapper({ children }: { children: JSX.Element[] }) {
  const ele = document.getElementById('tab-bar-wrapper')
  const contentLoad = () => {
    if (!ele) return
    ele.style.cursor = 'grab'
    let pos = { top: 0, left: 0, x: 0, y: 0 }
    const mouseDownHandler = function (e: MouseEvent) {
      ele.style.cursor = 'grabbing'
      ele.style.userSelect = 'none'

      pos = {
        left: ele.scrollLeft,
        top: ele.scrollTop,
        // Get the current mouse position
        x: e.clientX,
        y: e.clientY,
      }

      document.addEventListener('mousemove', mouseMoveHandler)
      document.addEventListener('mouseup', mouseUpHandler)
    }

    const mouseMoveHandler = function (e: MouseEvent) {
      // How far the mouse has been moved
      const dx = e.clientX - pos.x
      const dy = e.clientY - pos.y

      // Scroll the element
      ele.scrollTop = pos.top - dy
      ele.scrollLeft = pos.left - dx
    }

    const mouseUpHandler = function () {
      ele.style.cursor = 'grab'
      ele.style.removeProperty('user-select')

      document.removeEventListener('mousemove', mouseMoveHandler)
      document.removeEventListener('mouseup', mouseUpHandler)
    }

    // Attach the handler
    ele.addEventListener('mousedown', mouseDownHandler)
  }

  React.useLayoutEffect(contentLoad, [ele])

  return (
    <ScrollableTabsWrapper id="tab-bar-wrapper">
      {children}
    </ScrollableTabsWrapper>
  )
}

TabBar.defaultProps = {
  locked: false,
}

export default TabBar
