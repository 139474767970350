import React from 'react'
import { CircularProgress, Divider, styled } from '@mui/material'
import { Column, Row, RowSpaced } from 'components/shared'
import { BodyText, H5, H6 } from 'components/typography'
import { cardBoxShadowHover } from 'assets/styles/theme'
import { useIntl } from 'react-intl'
import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js'
import paymentConfig, { CheckoutData } from 'lib/payment/subscribe'
import { Severity } from 'components/providers/SnackbarHOC'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined'
import { getActiveWorkspaceId } from 'lib/sessionStorage'
import { useNavigate } from 'react-router-dom'
import ROUTES from 'lib/routes'
import { useQuery } from '@apollo/client'
import { OrganizationDataQuery } from 'types/organization'
import { ORGANIZATION_DATA } from 'gql/organization'
import { useSubscriptionStatus } from 'hooks/graphql/workspaces'
// eslint-disable-next-line
import { FUNDING_SOURCE } from '@paypal/paypal-js/types'
import { purchaseSuccessfulVar } from 'lib/reactiveVars'

const Container = styled(Column)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: '16px',
  marginBottom: '26px',
  ':hover': {
    cursor: 'pointer',
    boxShadow: cardBoxShadowHover,
  },
}))

const Content = styled(Column)(({ theme }) => ({
  padding: '20px',
}))
const RefundContent = styled(Row)(({ theme }) => ({
  padding: '20px',
  backgroundColor: theme.palette.shared.bgGray,
  borderRadius: '16px',
  marginTop: '20px',
  flexWrap: 'nowrap',
}))

const Image = styled('img')(({ theme }) => ({
  height: '15px',
}))

function isValidForm(formData: CheckoutData) {
  const { countryCode, subscriptionPlanId, organizationId } = formData
  return !!organizationId && !!countryCode && !!subscriptionPlanId
}

interface Props {
  checkoutData: CheckoutData
  showSnackbar?: (message: string, severity: Severity) => void
  showMoneyBack: boolean
  fundingSource: FUNDING_SOURCE
  title: string
  logo: string
}

function PaymentCard({
  showSnackbar,
  checkoutData,
  showMoneyBack,
  fundingSource,
  title,
  logo,
}: Props) {
  const intl = useIntl()
  const navigate = useNavigate()
  const [{ isPending }] = usePayPalScriptReducer()
  const workspaceId = getActiveWorkspaceId()
  const { refetch: refetchSubscriptionStatus } =
    useSubscriptionStatus(workspaceId)

  const { refetch } = useQuery<OrganizationDataQuery>(ORGANIZATION_DATA, {
    variables: { workspaceId },
  })

  const onSuccessfulPurchase = () => {
    refetch()
    refetchSubscriptionStatus?.()
    purchaseSuccessfulVar(true)
    navigate(
      workspaceId
        ? // if there is a focused workspace then navigate to its overview
          ROUTES.DASHBOARD_OVERVIEW_ROUTE(workspaceId)
        : ROUTES.DASHBOARD
    )
  }

  return (
    <Container>
      <RowSpaced sx={{ padding: '12px 16px' }}>
        <H5>{title}</H5>
        <Image src={logo} alt={`${fundingSource} logo`} />
      </RowSpaced>
      <Divider style={{ flex: 1 }} />
      <Content>
        <RowSpaced
          style={{
            alignItems: 'flex-start',
            ...(fundingSource === 'card'
              ? { flexDirection: 'column', display: 'flex' }
              : {}),
          }}
        >
          <Row style={{ marginRight: '30px' }}>
            <LockOutlinedIcon sx={{ mr: 1 }} />
            <H6>{intl.formatMessage({ id: 'label.encryptedPayment' })}</H6>
          </Row>
          <div
            style={
              fundingSource === 'card'
                ? {
                    marginTop: '20px',
                    flex: 1,
                    alignSelf: 'center',
                    width: 'min(100%, 400px)',
                  }
                : {}
            }
          >
            {isPending && <CircularProgress size={30} />}
            <PayPalButtons
              key={`${title}-button`}
              disabled={!isValidForm(checkoutData)}
              fundingSource={fundingSource}
              {...paymentConfig(
                checkoutData,
                intl,
                onSuccessfulPurchase,
                showSnackbar
              )}
            />
          </div>
        </RowSpaced>
        {showMoneyBack && (
          <RefundContent>
            <ReplayOutlinedIcon sx={{ mr: 1 }} />
            <BodyText>
              {intl.formatMessage({ id: 'label.moneyBackReturnMessage' })}
            </BodyText>
          </RefundContent>
        )}
      </Content>
    </Container>
  )
}

PaymentCard.defaultProps = {
  showSnackbar: null,
}
export default PaymentCard
