import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { Severity, withSnackbar } from 'components/providers/SnackbarHOC'
import { useIntl } from 'react-intl'
import { Container, Content, Row } from 'components/shared'
import { FormDataType } from 'components/input/types'
import InputStepper from 'components/input/InputStepper'
import LoadingIndicator from 'components/LoadingIndicator'
import { H1 } from 'components/typography'
import { classifyServiceProviders } from 'pages/dashboard/items/show/utils'
import { step1Inputs, step2Inputs } from './constants'
import { useData, useMutations } from './hooks'

const Wrapper = styled('form')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
}))

interface Props {
  showSnackbar?: (message: string, severity: Severity) => void
}

const initialState = {
  title: '',
  description: '',
  categoryId: null,
  link: '',
  email: '',
  phoneNumber: '',
  // nestTypes: [],
  // categories: [],
  // features: [],
  // securityFeatures: [],
}

function CreateServiceProvider(props: Props) {
  const { showSnackbar } = props
  const params = useParams() as { workspaceId: string }
  const intl = useIntl()
  // const [showErrors, setShowErrors] = useState(false)
  const [data, setData] = useState<FormDataType>(initialState)

  const {
    loading,
    data: { serviceProviders, categories },
  } = useData(params.workspaceId)

  const {
    loading: mutating,
    mutations: { createServiceProvider },
  } = useMutations(params.workspaceId, { showSnackbar })

  const handleOnSubmit = () => {
    const payload = {
      title: data.title as string,
      description: data.description as string,
      workspaceId: params.workspaceId,
      categoryId: data.categoryId as string,
      link: data.link as string,
      contact: {
        email: data.email as string,
        phoneNumber: data.phoneNumber as string,
      },
      // applicable: {
      //   nestTypes: data.nestTypes as string[],
      //   categories: data.categories as string[],
      //   features: data.features as string[],
      //   securityFeatures: data.securityFeatures as string[],
      // },
      // openingHours: {
      //   monday: {
      //     from: (data.monday_from as string) || undefined,
      //     to: (data.monday_to as string) || undefined,
      //   },
      //   tuesday: {
      //     from: (data.tuesday_from as string) || undefined,
      //     to: (data.tuesday_to as string) || undefined,
      //   },
      // },
    }

    createServiceProvider({ variables: { payload } })
  }

  const isLoading = loading || mutating
  const steps = [
    step1Inputs(categories, classifyServiceProviders(intl, serviceProviders)),
    step2Inputs,
  ]
  return (
    <Container>
      <LoadingIndicator visible={isLoading} />
      <Content>
        <Row>
          <H1>{intl.formatMessage({ id: 'label.createServiceProvider' })}</H1>
        </Row>
        <Wrapper>
          <InputStepper
            steps={steps}
            data={data}
            setData={setData}
            // showErrors={showErrors}
            showErrors={false} // step transition is disabled while invalid data is entered
            onSubmit={handleOnSubmit}
          />
        </Wrapper>
      </Content>
    </Container>
  )
}

CreateServiceProvider.defaultProps = {
  showSnackbar: undefined,
}

export default withSnackbar(CreateServiceProvider)
