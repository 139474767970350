import React from 'react'
import Grid from '@mui/material/Grid'
import { FileType } from 'types/archive'
import useMediaQuery from '@mui/material/useMediaQuery'
import ImageHeadlineGrid from 'components/ImageHeadlineGrid'
import { PageDescription } from 'components/pages'
import { styled, useTheme } from '@mui/material/styles'
import { Row, RowSpaced } from 'components/shared'
import { useIntl } from 'react-intl'
import MuiIconButton from '@mui/material/IconButton'
import Card from '@mui/material/Card'
import { useQuery } from '@apollo/client'
import HouseSimpleIcon from 'assets/svg/HouseSimple'
import { NestExtended, NestTypeData } from 'types/nest'
import { H1 } from 'components/typography'
import { NEST_BY_ID, NEST_TYPES } from 'gql/nest'
import Pencil from 'assets/svg/Pencil'
import placeholder from 'assets/images/placeholderNestHeadline.png'
import { StatusChip } from 'components/cards'
import { getDate } from 'utils/datetime'
import { getLocaleTitle } from 'utils/content'
import { Section } from './constants'

const Container = styled('div')(({ theme }) => ({
  flexDirection: 'column',
  position: 'relative',
  backgroundColor: theme.palette.background.default,
  padding: '30px 20px',
  borderRadius: '5px',
  marginBottom: '16px',
}))

const IconButton = styled(MuiIconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
}))

const HeadlineCard = styled(Card)({
  display: 'flex',
  flex: 1,
  flexGrow: 1,
  flexDirection: 'row',
  boxShadow: 'none',
  flexWrap: 'nowrap',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const HeadlineContainer = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
})

const StorageCard = styled(Card)({
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  boxShadow: 'none',
  flexWrap: 'nowrap',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '12px',
})

const StorageGridContainer = styled(Grid)({
  marginTop: '8px',
})

const StorageLabel = styled('span')(({ theme }) => ({
  fontFamily: 'Open Sans',
  fontSize: '14px',
  lineHeight: '145%',
  color: theme.palette.text.secondary,
}))

const SectionLabel = styled(StorageLabel)(({ theme }) => ({
  fontWeight: 600,
}))

const SectionChip = styled(Row)(({ theme }) => ({
  cursor: 'pointer',
  userSelect: 'none',
  padding: '0 6px',
  borderRadius: '4px',
  backgroundColor: '#F7F7F7',
  marginRight: '4px',
  transition: theme.transitions.create(['padding', 'transform'], {
    duration: theme.transitions.duration.standard,
  }),
  ':hover': {
    backgroundColor: theme.palette.action.active,
    padding: '0 8px',
  },
  ':hover svg :where(path, line)': {
    stroke: theme.palette.primary.contrastText,
  },
  ':hover span': {
    display: 'flex',
    color: theme.palette.primary.contrastText,
  },
}))

const ChipText = styled('span')(({ theme }) => ({
  color: theme.palette.action.active,
  fontFamily: 'Open Sans',
  fontSize: '14px',
  lineHeight: '150%',
  marginLeft: '2px',
  fontWeight: 600,
}))

interface Props {
  record: NestExtended
  showMediaUploadModal: () => void
  onEditSection: (sectionId: Section) => void
}
function Headline({ record, showMediaUploadModal, onEditSection }: Props) {
  const intl = useIntl()
  const theme = useTheme()
  const isBigScreen = useMediaQuery(theme.breakpoints.up('md'))
  const { data: options } = useQuery<NestTypeData>(NEST_TYPES)
  const images = record?.archive?.filter((i) => i.type === FileType.image) ?? []
  const onEdit = () => onEditSection(Section.general)

  const nestTypes = options?.nestTypes.list ?? []
  const nestType = nestTypes.find(
    (type) => type.id === record?.spaceOptions.spaceType
  )

  const refetchQueries = [
    { query: NEST_BY_ID, variables: { nestId: record?.id } },
  ]
  const headline = (
    <HeadlineCard>
      <HeadlineContainer>
        <RowSpaced>
          {record.deletedAt ? (
            <StatusChip
              color="error"
              label={intl.formatMessage(
                { id: 'label.deletedAtDate' },
                { date: getDate(record.deletedAt) }
              )}
            />
          ) : (
            <div />
          )}
          <IconButton onClick={onEdit}>
            <Pencil />
          </IconButton>
        </RowSpaced>
        <H1>{record.title}</H1>
        <PageDescription>{record.description}</PageDescription>
      </HeadlineContainer>
    </HeadlineCard>
  )

  const content = (
    <StorageCard>
      <div style={{ flexDirection: 'column', flex: 1 }}>
        {/* Category */}
        {nestType && (
          <StorageGridContainer container>
            <Grid item xs={3} paddingTop={1}>
              <SectionLabel>
                {intl.formatMessage({ id: 'label.type' })}
              </SectionLabel>
            </Grid>
            <Grid item xs={9}>
              <Row>
                <SectionChip onClick={onEdit}>
                  <HouseSimpleIcon
                    color={theme.palette.text.primary}
                    width="22px"
                  />
                  <ChipText>{getLocaleTitle(intl, nestType)}</ChipText>
                </SectionChip>
              </Row>
            </Grid>
          </StorageGridContainer>
        )}
      </div>
    </StorageCard>
  )

  return (
    <Container>
      {isBigScreen ? (
        <Grid container>
          <Grid item xs={6}>
            <ImageHeadlineGrid
              images={images}
              placeholder={placeholder}
              onCtaClick={showMediaUploadModal}
              refetchQueries={refetchQueries}
            />
          </Grid>
          <Grid item xs={6}>
            {headline}
            {content}
          </Grid>
        </Grid>
      ) : (
        <Grid container mb={4} spacing={1}>
          <Grid item xs={12}>
            {headline}
          </Grid>
          <Grid item xs={12}>
            <ImageHeadlineGrid
              images={images}
              placeholder={placeholder}
              onCtaClick={showMediaUploadModal}
              refetchQueries={refetchQueries}
            />
          </Grid>
          <Grid item xs={12}>
            {content}
          </Grid>
        </Grid>
      )}
    </Container>
  )
}

export default Headline
