import React, { useCallback, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import { useIntl } from 'react-intl'
import { omit } from 'lodash'
import { Severity, withSnackbar } from 'components/providers/SnackbarHOC'
import LoadingIndicator from 'components/LoadingIndicator'
import { FileType } from 'types/archive'
import SidebarCard from 'components/recordDetails/SidebarCard'
import { useFileUpload } from 'hooks/api/fileUpload'
import ROUTES from 'lib/routes'
import { OrderModels } from 'types/order'
import {
  PageHeader,
  PageGrid,
  PageGridLeft,
  PageGridRight,
} from 'components/pages'
import UploadHandler, {
  UploadHandlerMethods,
} from 'components/dropzone/UploadHandler'
import { Currencies, FileEntry, JsonObject, MemberModels } from 'types/shared'
import FormModal from 'components/recordDetails/FormModal'
import { SuggestionSection } from 'components/recordDetails/SuggestionsSection'
import {
  ConfirmationData,
  withConfirmationModal,
} from 'components/hoc/ConfirmationModalHoc'
import { Container, Content } from 'components/shared'
import { useSubscriptionStatus } from 'hooks/graphql/workspaces'
import Tabs from 'components/Tabs'
import { useMediaQuery } from '@mui/material'
import {
  getInitialState,
  getEditInputs,
  getSectionWithId,
  Section,
  getExtraInputs,
  payloadBuilder,
  relationsContent,
  getDetailsChips,
  orderPayloadBuilder,
} from './constants'
import Headline from './Headline'
import { useData, useMutations } from './hooks'
import contentTabs from './tabs'
import ModalHandler, { ModalHandleMethods } from './ModalHandler'
import { classifyServiceProviders } from './utils'
import { EventExtended } from 'types/event'
import EventDetailsDrawer, {
  EventDrawerHandleMethods,
} from 'components/hoc/EventDetailsDrawer'
import { ITEM_BY_ID } from 'gql/item'

interface Props {
  showSnackbar?: (message: string, severity: Severity) => void
  requestConfirmation?: (data: ConfirmationData) => void
}
function ItemDetails({ showSnackbar, requestConfirmation }: Props) {
  const intl = useIntl()
  const theme = useTheme()
  const navigate = useNavigate()
  const [editing, setEditing] = useState<SuggestionSection>()
  // optional id of the sub-record to edit, example (saleInfoId)
  const isBigScreen = useMediaQuery(theme.breakpoints.up('lg'))
  const [focusedEvent, setFocusedEvent] = useState<EventExtended>()
  const [editingId, setEditingId] = useState<string>()
  const [editingExtra, setEditingExtra] = useState<{ refId: string }>()
  const modalHandlerRef = useRef<ModalHandleMethods>(null)
  const uploadHandlerRef = useRef<UploadHandlerMethods>(null)
  const eventDetailsRef = useRef<EventDrawerHandleMethods>(null)
  const toggleDelete = () =>
    requestConfirmation?.({
      title: intl.formatMessage({ id: 'label.deletingItem' }),
      description: intl.formatMessage({
        id: 'label.deletingItemConfirmation',
      }),
      actions: [
        {
          label: intl.formatMessage({ id: 'label.cancel' }),
        },
        {
          label: intl.formatMessage({ id: 'label.delete' }),
          color: 'error',
          onClick: handleDelete,
        },
      ],
    })

  const hideEditingModal = useCallback(() => setEditing(undefined), [])
  const showDocUploadModal = useCallback(() => {
    uploadHandlerRef.current?.openUploader(FileType.document)
  }, [])

  const showMediaUploadModal = useCallback(() => {
    uploadHandlerRef.current?.openUploader(FileType.image)
  }, [])
  const { uploadDocuments, uploadFiles } = useFileUpload()

  const refreshEventDrawer = () => {
    const event = record?.events?.find((e) => e.id === focusedEvent?.id)
    if (event) {
      eventDetailsRef.current?.hideEventDetails()
      eventDetailsRef.current?.showEventDetails(event)
    }
  }
  const params = useParams() as { workspaceId: string; itemId: string }
  const { active } = useSubscriptionStatus(params.workspaceId)
  const {
    loading,
    refetch: { refetchItem },
    data: {
      record,
      serviceProviders,
      orgWorkspaces,
      categories,
      groups,
      members,
      owners,
      contracts,
    },
  } = useData(params.workspaceId, params.itemId, {
    showSnackbar,
    refreshEventDrawer,
  })

  const {
    loading: mutating,
    mutations: {
      deleteItems,
      addFileToItem,
      addItemSaleInfo,
      updateEvent,
      addEventRecord,
      updateItem,
      updateItemSalesInfo,
      deleteEvent,
      deleteItemListing,
      updateItemManufacture,
      addItemManufacture,
      updateOrderRecord,
      deleteOrderRecord,
      createOrderRecord,
      updateOrderItemRefRecord,
      createMemberItemRef,
      deleteMemberItemRef,
    },
  } = useMutations(
    params.workspaceId,
    params.itemId,
    record?.category?.id ?? '',
    { showSnackbar, hideEditingModal },
    { intl }
  )

  const handleDelete = () =>
    deleteItems({ variables: { itemIds: [record?.id ?? ''] } })

  const createMemberRef = (memberId: string, memberModel: MemberModels) => {
    const payload = {
      memberId,
      workspaceId: params.workspaceId,
      memberModel,
      itemId: params.itemId,
    }
    createMemberItemRef({ variables: { payload } })
  }
  const deleteMemberRef = (refId: string) => {
    deleteMemberItemRef({ variables: { id: refId } })
  }

  const isLoading = loading || mutating

  const onUpload = async (files: FileEntry[], fileType: FileType) => {
    await uploadFiles(files, fileType, params.workspaceId, {
      onCompleted: (archiveIds, hasErrors) => {
        archiveIds.forEach((archiveId) => {
          const payload = { itemId: params.itemId, archiveId }
          addFileToItem({ variables: { payload } })
        })
        if (!hasErrors) {
          uploadHandlerRef.current?.closeUploader()
          showSnackbar?.(
            intl.formatMessage({ id: 'label.uploadSuccessful' }),
            Severity.SUCCESS
          )
        }
      },
      onError: (errors) => {
        if (errors.length > 0) {
          showSnackbar?.(
            `File upload failed, (occurrences: ${errors.length}).
              ${errors[0]?.error?.message}
            `,
            Severity.ERROR
          )
        }
        uploadHandlerRef.current?.stopLoader()
      },
    })
  }

  const serviceProvidersLabeled = classifyServiceProviders(
    intl,
    serviceProviders
  )
  const inputs = getEditInputs(
    intl,
    editing?.id ?? '',
    categories,
    orgWorkspaces,
    serviceProvidersLabeled,
    contracts
  )
  const extraInputs = getExtraInputs(editing?.id ?? '')
  const initialState =
    record && editing
      ? getInitialState(record, editing.id as Section, editingId, editingExtra)
      : {}

  const handleRecordUpdate = (formData: JsonObject, sectionId?: string) => {
    const payload = payloadBuilder(formData, params.itemId, sectionId)
    updateItem({ variables: { payload } })
  }

  const handleSalesListingUpdate = (formData: JsonObject) => {
    if (editingId) {
      // update record
      const payload = { id: editingId, data: formData }
      updateItemSalesInfo({ variables: { payload } })
    } else {
      // new record
      const payload = { itemId: params.itemId, ...formData }
      addItemSaleInfo({ variables: { payload } })
    }
  }
  const handleOrderItemRefRecordUpdate = async (formData: JsonObject) => {
    if (editingId && editingExtra?.refId) {
      // update record
      const payload = {
        orderId: editingId,
        refId: editingExtra.refId,
        data: {
          quantity: formData.quantity ? Number(formData.quantity) : undefined,
          productLink: formData.productLink as string,
          amount:
            formData.value && formData.currency
              ? {
                  value: Number(formData.value),
                  currency: formData.currency as Currencies,
                }
              : undefined,
        },
      }
      updateOrderItemRefRecord({ variables: { payload } })
    } else {
      showSnackbar?.(
        intl.formatMessage({ id: 'error.failedToEditRecord' }),
        Severity.ERROR
      )
    }
  }

  const handleOrderRecordUpdate = async (
    formData: JsonObject,
    sectionId: Section,
    files?: FileEntry[]
  ) => {
    let uploadedFileIds: string[] = []
    if (sectionId === Section.orderDocuments && files) {
      // has files to upload
      await uploadDocuments(files, params.workspaceId, {
        onCompleted: (archiveIds) => {
          uploadedFileIds = archiveIds
        },
        onError: (errors) => {
          if (errors.length > 0)
            showSnackbar?.(
              intl.formatMessage(
                { id: 'error.failedToUpload' },
                { numerator: errors.length, denominator: files.length }
              ),
              Severity.ERROR
            )
        },
      })
    }

    const order = record?.orders?.find((rec) => rec.id === editingId)
    const updatedFormData = orderPayloadBuilder(formData, sectionId, order)

    if (editingId) {
      // update record
      const payload = {
        id: editingId,
        data: {
          ...updatedFormData,
          archiveIds: [
            ...(record?.orders?.find((rec) => rec.id === editingId)
              ?.archiveIds ?? []),
            ...uploadedFileIds,
          ],
        },
      }
      updateOrderRecord({ variables: { payload } })
    } else {
      let transactionData = {}
      if (sectionId === Section.sale) {
        transactionData = {
          sellerId: params.workspaceId,
          sellerModel: OrderModels.WORKSPACE,
        }
      }
      if (sectionId === Section.purchase) {
        transactionData = {
          buyerId: params.workspaceId,
          buyerModel: OrderModels.WORKSPACE,
        }
      }

      // new record
      const payload = {
        ...updatedFormData,
        ...transactionData,
        items: [{ itemId: params.itemId }],
      }
      createOrderRecord({ variables: { payload } })
    }
  }

  const handleEventRecordUpdate = async (
    formData: JsonObject,
    sectionId?: string,
    files?: FileEntry[]
  ) => {
    let uploadedFileIds: string[] = []
    if (sectionId === Section.eventDocuments && files) {
      // has files to upload
      await uploadDocuments(files, params.workspaceId, {
        onCompleted: (archiveIds) => {
          uploadedFileIds = archiveIds
        },
        onError: (errors) => {
          if (errors.length > 0)
            showSnackbar?.(
              `File upload failed, (occurrences: ${errors.length}).
                ${errors[0]?.error?.message}
              `,
              Severity.ERROR
            )
        },
      })
    }

    if (editingId) {
      // update record
      const payload = {
        id: editingId,
        data: {
          ...omit(formData ?? {}, ['price', 'currency']),
          ...(formData.currency
            ? {
                amount: {
                  value: Number(formData.price) ?? null,
                  currency: formData.currency as Currencies,
                },
              }
            : {}),
          archiveIds: [
            ...(record?.events?.find((rec) => rec.id === editingId)
              ?.archiveIds ?? []),
            ...uploadedFileIds,
          ],
        },
      }
      updateEvent({ variables: { payload } })
    } else {
      // new record
      const payload = {
        ...omit(formData ?? {}, ['price', 'currency']),
        amount:
          formData.price && formData.currency
            ? {
                value: Number(formData.price),
                currency: formData.currency as Currencies,
              }
            : undefined,
        itemId: params.itemId,
        workspaceId: params.workspaceId,
        date: formData.date as Date,
        archiveIds: uploadedFileIds,
      }
      addEventRecord({ variables: { payload } })
    }
  }

  const handleManufactureUpdate = async (
    formData: JsonObject,
    sectionId?: string,
    files?: FileEntry[]
  ) => {
    let uploadedFileIds: string[] = []
    if (sectionId === Section.manufactureDocuments && files) {
      // has files to upload
      await uploadDocuments(files, params.workspaceId, {
        onCompleted: (archiveIds) => {
          uploadedFileIds = archiveIds
        },
        onError: (errors) => {
          if (errors.length > 0)
            showSnackbar?.(
              `File upload failed, (occurrences: ${errors.length}).
                ${errors[0]?.error?.message}
              `,
              Severity.ERROR
            )
        },
      })
    }

    if (editingId) {
      // update record
      const payload = {
        id: editingId,
        data: {
          ...formData,
          archiveIds: [
            ...(record?.manufacture?.archiveIds ?? []),
            ...uploadedFileIds,
          ],
        },
      }
      updateItemManufacture({ variables: { payload } })
    } else {
      // new record
      const payload = {
        itemId: params.itemId,
        manufactureId: formData.manufactureId as string, // just to avoid ts issues
        ...formData,
        archiveIds: uploadedFileIds,
      }
      addItemManufacture({ variables: { payload } })
    }
  }

  const handleOnUpdate = (
    formData: JsonObject,
    sectionId?: string,
    files?: FileEntry[]
  ) => {
    switch (sectionId) {
      case Section.saleListing:
        handleSalesListingUpdate(formData)
        break
      case Section.manufacture:
      case Section.manufactureDocuments:
        handleManufactureUpdate(formData, sectionId, files)
        break
      case Section.events:
      case Section.eventDocuments:
        handleEventRecordUpdate(formData, sectionId, files)
        break

      case Section.purchase:
      case Section.sale:
      case Section.orderDocuments:
        handleOrderRecordUpdate(formData, sectionId, files)
        break

      case Section.orderItemRef:
        handleOrderItemRefRecordUpdate(formData)
        break

      // case Section.transferItem:
      //   transferItems({
      //     variables: {
      //       payload: {
      //         itemIds: [params.itemId],
      //         workspaceId: formData.workspaceId as string,
      //       },
      //     },
      //   })
      //   break
      default:
        handleRecordUpdate(formData, sectionId)
    }
  }

  const handleOnEditWithId = (sectionId: string, recordId?: string) => {
    const section = getSectionWithId(sectionId, intl)
    setEditingId(recordId)
    setEditing(section)
  }

  const handleOnDeleteSalesListing = (recordId: string) =>
    deleteItemListing({ variables: { id: recordId } })
  const handleOnDeleteOrder = (recordId: string) =>
    requestConfirmation?.({
      title: intl.formatMessage({ id: 'label.deleteRecordTitle' }),
      description: intl.formatMessage({
        id: 'label.deleteRecordConfirmation',
      }),
      actions: [
        {
          label: intl.formatMessage({ id: 'label.cancel' }),
        },
        {
          label: intl.formatMessage({ id: 'label.delete' }),
          color: 'error',
          onClick: () => deleteOrderRecord({ variables: { id: recordId } }),
        },
      ],
    })

  const handleOnDeleteEvent = (recordId: string) =>
    requestConfirmation?.({
      title: intl.formatMessage({ id: 'label.deleteEvent' }),
      description: intl.formatMessage({
        id: 'label.deletingEventConfirmation',
      }),
      actions: [
        {
          label: intl.formatMessage({ id: 'label.cancel' }),
        },
        {
          label: intl.formatMessage({ id: 'label.delete' }),
          color: 'error',
          onClick: () => deleteEvent({ variables: { id: recordId } }),
        },
      ],
    })

  const onEditOrderItemRef = (orderId: string, refId: string) => {
    handleOnEditWithId(Section.orderItemRef, orderId)
    setEditingExtra({ refId })
  }

  const tabs = contentTabs(intl, record, params.workspaceId, navigate, {
    showSnackbar,
    handleOnEditWithId,
    handleOnDeleteEvent,
    handleOnDeleteSalesListing,
    showDocUploadModal,
    handleOnDeleteOrder,
    toggleDelete,
    onEditOrderItemRef,
    showEventDetails: (event: EventExtended) => {
      setFocusedEvent(event)
      eventDetailsRef.current?.showEventDetails(event)
    },
    hideEventDetails: () => {
      setFocusedEvent(undefined)
      eventDetailsRef.current?.hideEventDetails()
    },
  })

  const sidebar = (
    <React.Fragment>
      <SidebarCard
        title={intl.formatMessage({
          id: 'label.details',
        })}
        content={
          record
            ? getDetailsChips(
                intl,
                {
                  record,
                  groups,
                  workspaceId: params.workspaceId,
                  members: [...owners, ...members],
                },
                theme,
                {
                  handleOnEditWithId,
                  navigate,
                  toggleNestSelect: modalHandlerRef.current?.toggleNestSelect,
                  toggleContainerSelect:
                    modalHandlerRef.current?.toggleBoxSelect,
                  toggleGroupSelect: modalHandlerRef.current?.toggleGroupSelect,
                  deleteMemberRef,
                  createMemberRef,
                }
              )
            : []
        }
      />
      <SidebarCard
        title={intl.formatMessage({
          id: 'label.relations',
        })}
        content={
          record
            ? relationsContent(
                intl,
                { record, workspaceId: params.workspaceId },
                {
                  addSale: () => handleOnEditWithId(Section.sale),
                  addPurchase: () => handleOnEditWithId(Section.purchase),
                  addListing: () => handleOnEditWithId(Section.saleListing),
                  addEvent: () => handleOnEditWithId(Section.events),
                }
              )
            : []
        }
      />
    </React.Fragment>
  )

  return (
    <Container detailPage>
      <LoadingIndicator visible={isLoading} />
      <PageHeader
        pageTitle={intl.formatMessage({ id: 'label.items' })}
        recordTitle={record?.title ?? ''}
        breadcrumbLink={ROUTES.DASHBOARD_ITEMS_ROUTE(params.workspaceId)}
      />
      <Content detailPage>
        <PageGrid container spacing={6}>
          <PageGridLeft item xs={12} sm={12} md={12} lg={7} xl={8}>
            {record && (
              <Headline
                record={record}
                showMediaUploadModal={showMediaUploadModal}
                onEditSection={handleOnEditWithId}
                handleRecordUpdate={handleRecordUpdate}
              />
            )}
            {!isBigScreen && sidebar}

            <Tabs tabs={tabs} locked={!active} />
          </PageGridLeft>

          {isBigScreen && (
            <PageGridRight item xs={12} sm={12} md={12} lg={5} xl={4}>
              {sidebar}
            </PageGridRight>
          )}
        </PageGrid>
      </Content>

      <UploadHandler ref={uploadHandlerRef} onSubmit={onUpload} />
      <FormModal
        toggleModal={hideEditingModal}
        inputs={inputs}
        extraInputs={extraInputs}
        section={editing}
        initialState={initialState}
        onSubmit={handleOnUpdate}
      />
      <ModalHandler record={record} groups={groups} ref={modalHandlerRef} />
      <EventDetailsDrawer
        ref={eventDetailsRef}
        refetchQueries={[
          {
            query: ITEM_BY_ID,
            variables: { itemId: record?.id },
          },
        ]}
        onRefresh={() => refetchItem()}
      />
    </Container>
  )
}

ItemDetails.defaultProps = {
  showSnackbar: undefined,
  requestConfirmation: undefined,
}

export default withConfirmationModal(withSnackbar(ItemDetails))
