import {
  Button,
  Chip,
  Grid,
  Theme,
  alpha,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { FontFamily } from 'assets'
import { Column, Row, RowSpaced } from 'components/shared'
import {
  BodyText,
  BodyTextLight,
  CaptionText,
  H2,
  H3,
  H4,
  H6,
} from 'components/typography'
import React from 'react'
import { useIntl } from 'react-intl'
import { Limitations, Subscription, SubscriptionPlan } from 'types/subscription'
import CurrencyExchangeIcon from '@mui/icons-material/AttachMoney'
import { getCurrencyLabel } from 'utils/monetary'
import { useLocation, useNavigate } from 'react-router-dom'
import ROUTES from 'lib/routes'
import {
  calculateDiffInPercentage,
  calculatePriceDiffFromBase,
  calculatePricePerMonth,
  getCurrencyUnit,
} from './constants'

const DesktopGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))

const LockOverlay = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  backdropFilter: 'blur(3px)',
  backgroundColor: alpha(theme.palette.action.active, 0.2),
  justifyContent: 'center',
  alignItems: 'center',
}))

const border = (theme: Theme) => `1px solid ${theme.palette.shared.darkGray}`
const LimitationWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'grayed' && prop !== 'center',
})<{ grayed?: boolean; center?: boolean; theme?: Theme }>(
  ({ theme, grayed, center }) => ({
    padding: '10px 0',
    flex: 1,
    display: 'flex',
    borderTop: border(theme),
    ...(center && { justifyContent: 'center', alignItems: 'center' }),
    ...(grayed && { backgroundColor: theme.palette.shared.lightGray }),
  })
)

interface Props {
  subscriptions: SubscriptionPlan[]
  // the minimum duration plans based on which the calculations will be based. Example: 1 month plans
  basePlans: SubscriptionPlan[]
  countryCode: string
  upgradeMap: { [id: string]: boolean } | null
  // the subscription plan that the user wants to upgrade from
  subscription?: Subscription
}
function SubscriptionGrid({
  subscriptions,
  countryCode,
  basePlans,
  upgradeMap,
  subscription,
}: Props) {
  const intl = useIntl()
  const theme = useTheme()
  const location = useLocation()
  const navigate = useNavigate()
  const isBigScreen = useMediaQuery(theme.breakpoints.up('md'))
  const isDashboard = location.pathname.includes(ROUTES.DASHBOARD)

  const navigateToCheckout = (subscriptionPlanId: string) => {
    // if there is a subscriptionId the user has requested a subscription upgrade.
    if (subscription?.id) {
      navigate(
        ROUTES.CHECKOUT_UPGRADE_ROUTE(subscription?.id, subscriptionPlanId)
      )
      return
    }

    navigate(
      isDashboard
        ? ROUTES.DASHBOARD_CHECKOUT_ROUTE(subscriptionPlanId, countryCode)
        : ROUTES.CHECKOUT_ROUTE(subscriptionPlanId, countryCode)
    )
  }

  const isCurrentPlan = (plan: SubscriptionPlan) =>
    subscription?.subscriptionPlanId === plan.id && !!upgradeMap
  // check if the subscription plan can me upgraded to. if not the ui will be locked
  const isLockedPlan = (plan: SubscriptionPlan) =>
    !!upgradeMap && !upgradeMap[plan.id]
  return (
    <Grid container>
      <DesktopGrid
        item
        md={3}
        sm={0}
        xs={0}
        sx={{
          justifyContent: 'flex-end',
          flexDirection: 'column',
        }}
      >
        <H4 style={{ paddingLeft: '8px' }}>
          {intl.formatMessage({ id: 'label.subscriptionPlanBenefits' })}
        </H4>
      </DesktopGrid>
      {/* headline grids */}
      {subscriptions.map((plan, planIndex) => {
        const countryPrice = plan.pricing.find(
          (price) => price.countryCode === countryCode
        )
        const relatedBasePlan = basePlans.find((p) => p.type === plan.type)
        const diffFromBase = relatedBasePlan
          ? calculatePriceDiffFromBase(plan, relatedBasePlan, countryCode)
          : null
        const totalDuration = plan.duration + (plan.extraDuration ?? 0)
        return (
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            key={plan.id}
            sx={{
              position: 'relative',
              border: border(theme),
              padding: '20px',
              borderTopLeftRadius: '20px',
              borderTopRightRadius: '20px',
              ...(isFeatured(planIndex) && {
                borderColor: theme.palette.primary.main,
                borderWidth: '4px',
                borderTopWidth: '12px',
                marginTop: '-12px',
                borderBottomWidth: 0,
              }),
              ...(!isBigScreen && {
                borderRadius: '20px',
                marginBottom: '10px',
                borderBottomWidth: '4px',
                marginTop: 0,
              }),
            }}
          >
            <Column>
              <RowSpaced>
                {isCurrentPlan(plan) ? (
                  <H6>{intl.formatMessage({ id: 'label.currentPlan' })}</H6>
                ) : (
                  <div />
                )}

                <Chip
                  label={intl.formatMessage(
                    { id: `label.xPercentSave` },
                    {
                      number:
                        diffFromBase && countryPrice?.value
                          ? calculateDiffInPercentage(
                              diffFromBase,
                              countryPrice?.value
                            )
                          : 0,
                    }
                  )}
                  style={{
                    fontSize: '12px',
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.shared.lightRed,
                    fontWeight: 'bold',
                    fontFamily: FontFamily.NoirPro,
                  }}
                />
              </RowSpaced>
              <H3>{plan.title}</H3>
              <H2>
                {intl.formatMessage(
                  { id: 'label.valuePerMonth' },
                  {
                    value: `${calculatePricePerMonth(
                      plan,
                      countryCode
                    )}${getCurrencyUnit(plan, countryCode)}`,
                  }
                )}
              </H2>
              {plan.extraDuration ? (
                <H4 color={theme.palette.shared.red}>
                  {intl.formatMessage(
                    { id: 'label.xExtraMonths' },
                    { number: plan.extraDuration }
                  )}
                </H4>
              ) : (
                <React.Fragment />
              )}
              <br />
              <BodyTextLight>{plan.description}</BodyTextLight>

              <CaptionText
                color={theme.palette.text.secondary}
                style={{ marginBottom: '4px' }}
              >
                {intl.formatMessage({ id: 'label.totalPrice' })}:{' '}
                {diffFromBase && (
                  <CaptionText
                    style={{ textDecoration: 'line-through' }}
                    color={theme.palette.text.secondary}
                  >
                    {diffFromBase.toFixed(2)}
                    {getCurrencyLabel(countryPrice?.currency)}
                  </CaptionText>
                )}
                {` ${countryPrice?.value.toFixed(2)}${getCurrencyLabel(
                  countryPrice?.currency
                )} ${
                  totalDuration > 1
                    ? intl.formatMessage(
                        { id: 'label.forTheFirstXMonths' },
                        { number: totalDuration }
                      )
                    : ''
                } (${intl.formatMessage({
                  id: 'label.vatMayApply',
                })}).`}
              </CaptionText>

              <br />
              <Button
                disabled={isCurrentPlan(plan) || isLockedPlan(plan)}
                onClick={() => navigateToCheckout(plan.id)}
                fullWidth
                variant="outlined"
                color="primary"
                style={{
                  borderRadius: '200px',
                  ...(isFeatured(planIndex) && {
                    backgroundColor:
                      isCurrentPlan(plan) || isLockedPlan(plan)
                        ? theme.palette.action.disabled
                        : theme.palette.primary.main,
                    color: theme.palette.background.default,
                  }),
                }}
              >
                {upgradeMap
                  ? intl.formatMessage({ id: 'label.upgrade' })
                  : `${intl.formatMessage({ id: 'label.get' })} ${plan.title}`}
              </Button>
              <br />
              {countryPrice?.refundDeadline ? (
                <Row
                  style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexWrap: 'nowrap',
                    marginTop: '4px',
                  }}
                >
                  <CurrencyExchangeIcon fontSize="small" />
                  <CaptionText style={{ fontWeight: 'bold' }}>
                    {intl.formatMessage(
                      { id: 'label.dayMoneyBackGuarantee' },
                      { number: countryPrice?.refundDeadline }
                    )}
                  </CaptionText>
                </Row>
              ) : (
                <React.Fragment />
              )}
            </Column>
            {isLockedPlan(plan) && <LockOverlay />}
          </Grid>
        )
      })}

      {/* Limitations */}
      {Object.keys(Limitations).map((limitation, index) => (
        <React.Fragment key={limitation}>
          <DesktopGrid
            item
            md={3}
            sm={0}
            xs={0}
            style={{
              ...(index === Object.keys(Limitations).length - 1 && {
                borderBottom: border(theme),
              }),
            }}
          >
            {/* if needed  grayed={!(index % 2)} */}
            <LimitationWrapper>
              <BodyText style={{ paddingLeft: '8px' }}>
                {intl.formatMessage({
                  id: `subscription.limitation.${limitation}`,
                })}
              </BodyText>
            </LimitationWrapper>
          </DesktopGrid>
          {subscriptions.map((plan, idx) => (
            <DesktopGrid
              key={`limitation-${plan.id}`}
              item
              md={3}
              sm={0}
              xs={0}
              style={{
                borderRight: border(theme),
                borderLeft: border(theme),
                // ...(index === Object.keys(Limitations).length - 1 && {
                //   borderBottom: border(theme),
                // }),
                ...(isFeatured(idx) && {
                  borderColor: theme.palette.primary.main,
                  borderWidth: '4px',
                }),
              }}
            >
              {/* if needed  grayed={!(index % 2)} */}
              <LimitationWrapper center>
                <BodyTextLight>
                  {plan?.limitations?.[limitation as Limitations] ?? '-'}
                </BodyTextLight>
              </LimitationWrapper>
              {isLockedPlan(plan) && <LockOverlay />}
            </DesktopGrid>
          ))}
        </React.Fragment>
      ))}

      {/* Footer grids */}
      <DesktopGrid item md={3} sm={0} xs={0} />

      {/* headline grids */}
      {subscriptions.map((plan, planIndex) => (
        <DesktopGrid
          item
          xs={12}
          sm={12}
          md={3}
          key={plan.id}
          sx={{
            border: border(theme),
            padding: '16px',
            borderBottomLeftRadius: '20px',
            borderBottomRightRadius: '20px',
            ...(isFeatured(planIndex) && {
              borderColor: theme.palette.primary.main,
              borderTopColor: theme.palette.shared.darkGray,
              borderWidth: '4px',
              borderTopWidth: '1px',
              marginBottom: '-15px',
            }),
          }}
        >
          <Column>
            <H2>{plan.title}</H2>
            <H4 style={{ marginBottom: '4px' }}>
              {intl.formatMessage(
                { id: 'label.valuePerMonth' },
                {
                  value: `${calculatePricePerMonth(
                    plan,
                    countryCode
                  )}${getCurrencyUnit(plan, countryCode)}`,
                }
              )}
            </H4>
            <br />
            <Button
              disabled={isCurrentPlan(plan) || isLockedPlan(plan)}
              fullWidth
              onClick={() => navigateToCheckout(plan.id)}
              variant="outlined"
              color="primary"
              style={{
                borderRadius: '200px',
                ...(isFeatured(planIndex) && {
                  backgroundColor:
                    isCurrentPlan(plan) || isLockedPlan(plan)
                      ? theme.palette.action.disabled
                      : theme.palette.primary.main,
                  color: theme.palette.background.default,
                }),
              }}
            >
              {upgradeMap
                ? intl.formatMessage({ id: 'label.upgrade' })
                : `${intl.formatMessage({ id: 'label.get' })} ${plan.title}`}
            </Button>
          </Column>
          {isLockedPlan(plan) && <LockOverlay />}
        </DesktopGrid>
      ))}
    </Grid>
  )
}

function isFeatured(index: number) {
  return index % 2 === 1
}

SubscriptionGrid.defaultProps = {
  subscription: null,
}
export default SubscriptionGrid
