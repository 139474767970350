import { useMutation, useQuery } from '@apollo/client'
import { NavigateFunction } from 'react-router-dom'
import { Severity } from 'components/providers/SnackbarHOC'
import { ADD_FILE_TO_NEST } from 'gql/archive'
import { UPDATE_NEST_ITEM_REFERENCE } from 'gql/item'
import { AddFileToNestData } from 'types/archive'
import { UpdateNestItemRefsData } from 'types/item'
import {
  DELETE_NESTS,
  NEST_BY_ID,
  NEST_CONTAINERS,
  NEST_TYPES,
  UPDATE_NEST,
  WORKSPACE_NESTS,
} from 'gql/nest'
import {
  DeleteNestsData,
  DeleteNestsInput,
  NestByIdData,
  NestContainersData,
  NestTypeData,
  UpdateNestData,
  UpdateNestInput,
} from 'types/nest'
import ROUTES from 'lib/routes'
import { UPDATE_NEST_CONTAINER_REFERENCE } from 'gql/container'
import { UpdateNestContainerRefsData } from 'types/container'
import { WORKSPACE_MEMBERS } from 'gql/workspace'
import { WorkspaceMembersData } from 'types/workspace'
import {
  CreateMemberNestRefData,
  CreateMemberNestRefInput,
  DeleteMemberNestRefData,
  DeleteMemberRefInput,
} from 'types/memberRefs'
import { CREATE_MEMBER_NEST_REF, DELETE_MEMBER_NEST_REF } from 'gql/memberRefs'

export function useData(
  nestId: string,
  workspaceId: string,
  methods: {
    showSnackbar?: (message: string, severity: Severity) => void
  }
) {
  const {
    data: nestData,
    loading: nestLoading,
    refetch: refreshRecord,
  } = useQuery<NestByIdData>(NEST_BY_ID, {
    variables: { nestId },
    onError: (error) => methods.showSnackbar?.(error.message, Severity.ERROR),
  })
  const { data: options, loading: optionsLoading } =
    useQuery<NestTypeData>(NEST_TYPES)

  const {
    data: containersData,
    loading: containersLoading,
    refetch: refetchContainers,
  } = useQuery<NestContainersData>(NEST_CONTAINERS, {
    variables: { nestId },
    fetchPolicy: 'network-only',
  })

  const { data: wsMembers, loading: membersLoading } =
    useQuery<WorkspaceMembersData>(WORKSPACE_MEMBERS, {
      variables: { workspaceId },
    })
  const record = nestData?.nestById
  const spaceOptions = record?.spaceOptions
  const nestTypes = options?.nestTypes.list ?? []
  const loading =
    nestLoading || optionsLoading || containersLoading || membersLoading

  const nestContainers = containersData?.nestContainers ?? []
  const members = wsMembers?.workspaceMembers.members || []
  const owners = wsMembers?.workspaceMembers.owners || []

  return {
    loading,
    data: {
      record,
      nestTypes,
      spaceOptions,
      nestContainers,
      members,
      owners,
    },
    refetch: { refreshRecord, refetchContainers },
  }
}

export function useMutations(
  payload: {
    nestId: string
    workspaceId: string
    navigate: NavigateFunction
  },
  methods: {
    showSnackbar?: (message: string, severity: Severity) => void
    hideEditingModal: () => void
    toggleItemSelect: () => void
    toggleBoxSelect: () => void
  }
) {
  const { showSnackbar, hideEditingModal, toggleItemSelect, toggleBoxSelect } =
    methods
  const refetchListing = {
    query: WORKSPACE_NESTS,
    variables: {
      payload: { workspaceId: payload.workspaceId, limit: 25, page: 0 },
    },
  }
  const [deleteNests, { loading: deletingRecord }] = useMutation<
    DeleteNestsData,
    DeleteNestsInput
  >(DELETE_NESTS, {
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    onCompleted: () =>
      payload.navigate(ROUTES.DASHBOARD_NESTS_ROUTE(payload.workspaceId), {
        replace: true,
      }),
    refetchQueries: [refetchListing],
  })
  const [updateNest, { loading: updateLoading }] = useMutation<
    UpdateNestData,
    UpdateNestInput
  >(UPDATE_NEST, {
    onCompleted: hideEditingModal,
    refetchQueries: [
      { query: NEST_BY_ID, variables: { nestId: payload.nestId } },
      refetchListing,
    ],
  })
  const [addFileToNest] = useMutation<AddFileToNestData>(ADD_FILE_TO_NEST, {
    refetchQueries: [
      { query: NEST_BY_ID, variables: { nestId: payload.nestId } },
      refetchListing,
    ],
  })

  const [updateNestItemRefs] = useMutation<UpdateNestItemRefsData>(
    UPDATE_NEST_ITEM_REFERENCE,
    {
      onCompleted: toggleItemSelect,
      refetchQueries: [
        { query: NEST_BY_ID, variables: { nestId: payload.nestId } },
      ],
    }
  )

  const [updateNestContainerRefs] = useMutation<UpdateNestContainerRefsData>(
    UPDATE_NEST_CONTAINER_REFERENCE,
    {
      onCompleted: toggleBoxSelect,
      refetchQueries: [
        { query: NEST_CONTAINERS, variables: { nestId: payload.nestId } },
        { query: NEST_BY_ID, variables: { nestId: payload.nestId } },
      ],
    }
  )
  const [createMemberNestRef, { loading: createRefLoading }] = useMutation<
    CreateMemberNestRefData,
    CreateMemberNestRefInput
  >(CREATE_MEMBER_NEST_REF, {
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: [
      { query: NEST_BY_ID, variables: { nestId: payload.nestId } },
    ],
  })
  const [deleteMemberNestRef, { loading: deleteRefLoading }] = useMutation<
    DeleteMemberNestRefData,
    DeleteMemberRefInput
  >(DELETE_MEMBER_NEST_REF, {
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: [
      { query: NEST_BY_ID, variables: { nestId: payload.nestId } },
    ],
  })

  const loading =
    deletingRecord || updateLoading || createRefLoading || deleteRefLoading
  return {
    loading,
    mutations: {
      deleteNests,
      updateNest,
      addFileToNest,
      updateNestItemRefs,
      updateNestContainerRefs,
      createMemberNestRef,
      deleteMemberNestRef,
    },
  }
}
