import * as React from 'react'
import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent'
import TimelineDot from '@mui/lab/TimelineDot'
import EventPast from '@mui/icons-material/EventAvailableSharp'
import { Event } from 'types/event'
import { isPastDate } from 'utils/datetime'
import { BodyTextLight, CaptionText, H5 } from './typography'
import { formatDistanceToNow, isToday } from 'date-fns'
import { getMonetaryValue } from 'utils/monetary'
import { useIntl } from 'react-intl'

interface Props {
  records: Event[]
}

export default function EventsTimeline({ records }: Props) {
  const intl = useIntl()
  return (
    <Timeline position="alternate">
      {records.map((record) => {
        return (
          <TimelineItem key={record.id}>
            <TimelineOppositeContent
              sx={{ m: 'auto 0' }}
              align="right"
              variant="body2"
              color="text.primary"
            >
              {record.date && isToday(new Date(record.date)) ? (
                <CaptionText>
                  {intl.formatMessage({ id: 'label.today' })}
                </CaptionText>
              ) : (
                <CaptionText>
                  {record.date
                    ? formatDistanceToNow(new Date(record.date), {
                        addSuffix: true,
                      })
                    : ''}
                </CaptionText>
              )}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot color="primary" variant="outlined">
                {record.date &&
                isPastDate(record.date) &&
                !isToday(new Date(record.date)) ? (
                  <EventPast />
                ) : (
                  <React.Fragment />
                )}
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
              <H5 color="primary.main">{record.title}</H5>
              {record.amount?.value ? (
                <CaptionText>{getMonetaryValue(record.amount)}</CaptionText>
              ) : (
                <React.Fragment />
              )}
              {record.description && (
                <BodyTextLight numberOfLines={1}>
                  {record.description}
                </BodyTextLight>
              )}
            </TimelineContent>
          </TimelineItem>
        )
      })}
    </Timeline>
  )
}
