import React from 'react'
import { IntlShape } from 'react-intl'
import { FormDataType, InputType, Type } from 'components/input/types'
import {
  JsonObject,
  WorkspaceAccessRoles,
  WorkspaceAccessRolesBase,
} from 'types/shared'
import { SuggestionSection } from 'components/recordDetails/SuggestionsSection'
import { ContentSection } from 'components/recordDetails/SidebarCard'
import { Theme } from '@mui/material/styles'
import {
  OrganizationMemberDetails,
  WorkspaceMember,
  WorkspaceMemberDetails,
  isOrganizationMember,
} from 'types/user'
import { NavigateFunction } from 'react-router-dom'
import ROUTES from 'lib/routes'
import LinkIcon from '@mui/icons-material/Link'
import MailIcon from '@mui/icons-material/MailOutline'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'
import { isValidOption } from 'utils/validators'
import { ConfirmationData } from 'components/hoc/ConfirmationModalHoc'
import { generalInitialState, generalSectionInputs } from '../list/constants'

export enum Section {
  general = 'general',
  avatar = 'avatar',
  contactToMember = 'contactToMember',
}

const profilePhotoInputs: InputType[] = [
  {
    key: 'avatarId',
    label: 'label.addProfilePicture',
    type: Type.DROPZONE,
    margin: 'dense',
    fullWidth: true,
    validators: [],
  },
]

export const editingSections = (intl: IntlShape): SuggestionSection[] => [
  {
    id: Section.general,
    title: intl.formatMessage({ id: 'label.general' }),
    description: '',
    cta: '',
  },
  {
    id: Section.avatar,
    title: intl.formatMessage({ id: 'label.selectImage' }),
    description: '',
    cta: '',
  },
  {
    id: Section.contactToMember,
    title: intl.formatMessage({ id: 'label.inviteToBoard' }),
    description: '',
    cta: '',
  },
]

const contactToMemberSectionInputs = (intl: IntlShape): InputType[] => [
  {
    key: 'email',
    label: 'label.emailAddress',
    type: Type.EMAIL,
    autoComplete: 'email',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    disabled: true,
    infoLabel: 'label.sendInvitationEmailInfo',
  },
  {
    key: 'role',
    label: 'label.role',
    type: Type.SINGLE_SELECT,
    options: Object.values(WorkspaceAccessRolesBase).map((role) => ({
      value: role,
      label: intl.formatMessage({
        id: `role.${role}`,
        defaultMessage: role.toLocaleLowerCase(),
      }),
    })),
    margin: 'dense',
    fullWidth: true,
    validators: [
      {
        method: isValidOption,
        label: 'label.required',
      },
    ],
  },
]

export const getSectionWithId = (id: string, intl: IntlShape) => {
  return editingSections(intl).find((section) => section.id === id)
}

export const getEditInputs = (
  editId: string,
  intl: IntlShape,
  editingRecord?: WorkspaceMember
) => {
  switch (editId) {
    case Section.general:
      return generalSectionInputs(intl, false, editingRecord)
    case Section.avatar:
      return profilePhotoInputs
    case Section.contactToMember:
      return contactToMemberSectionInputs(intl)
    default:
      return []
  }
}

export const getInitialState = (
  record: WorkspaceMember,
  sectionId: string
): FormDataType => {
  switch (sectionId) {
    case Section.general:
    case Section.contactToMember:
      return generalInitialState(record)
    case Section.avatar:
      return { avatarId: undefined }
    default:
      return {}
  }
}

export const getExtraInputs = (editId: string) => {
  return []
}

export const payloadBuilder = (
  formData: JsonObject,
  recordId: string,
  sectionId?: string
) => {
  let payloadData: JsonObject = {}
  if (sectionId)
    switch (sectionId) {
      case Section.general:
      case Section.contactToMember:
        payloadData = {
          firstName: formData.firstName,
          lastName: formData.lastName,
          role: formData.role,
          email: formData.email,
          // birthday: formData.birthday,
          // gender: formData.gender,
        }
        break
      case Section.avatar:
        payloadData = {
          avatarId: formData.avatarId,
        }
        break
      default:
        payloadData = formData
    }

  const payload = {
    id: recordId,
    data: payloadData,
  }
  return payload
}

export const getDetailsChips = (
  intl: IntlShape,
  theme: Theme,
  data: {
    record?: OrganizationMemberDetails | WorkspaceMemberDetails
  }
): ContentSection[] => {
  const { record } = data

  return [
    {
      title: intl.formatMessage({ id: 'label.items' }),
      description: intl.formatMessage(
        { id: 'label.relatedItemsCount' },
        { number: record?.items.length ?? 0 }
      ),
    },
    {
      title: intl.formatMessage({ id: 'label.spaces' }),
      description: intl.formatMessage(
        { id: 'label.relatedNestsCount' },
        { number: record?.nests.length ?? 0 }
      ),
    },
    {
      title: intl.formatMessage({ id: 'label.contracts' }),
      description: intl.formatMessage(
        { id: 'label.contractsCount' },
        { number: record?.contracts.length ?? 0 }
      ),
    },
    {
      title: intl.formatMessage({ id: 'label.containers' }),
      description: intl.formatMessage(
        { id: 'label.relatedContainerCount' },
        { number: record?.containers.length ?? 0 }
      ),
    },
  ]
}

export const hasContent = (
  record?: OrganizationMemberDetails | WorkspaceMemberDetails
) => {
  // workspace members always have content
  if (record && !isOrganizationMember(record)) return true

  return (
    !!record?.items.length ||
    !!record?.nests.length ||
    !!record?.contracts.length ||
    !!record?.containers.length
  )
}

export const profileSidebarContent = (
  intl: IntlShape,
  navigate: NavigateFunction,
  record: OrganizationMemberDetails | WorkspaceMemberDetails,
  isLinkedToProfile: boolean,
  handleOnEditWithId: (sectionId: Section) => void,
  convertFromMemberToContact: () => void,
  requestConfirmation?: (data: ConfirmationData) => void
) => {
  let content: ContentSection[] = []
  if (isLinkedToProfile) {
    content = [
      {
        description: intl.formatMessage({
          id: 'label.memberLinkedProfile',
        }),
      },
      {
        description: intl.formatMessage({
          id: 'label.goToProfile',
        }),
        action: {
          ariaLabel: 'go to profile',
          icon: (props) => <LinkIcon {...props} />,
          onPress: () =>
            navigate(`${ROUTES.DASHBOARD}${ROUTES.DASHBOARD_PROFILE}`),
        },
      },
    ]
  }

  if (
    record &&
    !isOrganizationMember(record) &&
    record?.role === WorkspaceAccessRoles.contact &&
    record.email
  ) {
    // convert the contact to a workspace member
    content = [
      ...content,
      {
        description: intl.formatMessage({
          id: 'label.sentBoardInvitation',
        }),
      },
      {
        description: intl.formatMessage({
          id: 'label.sentInvite',
        }),
        action: {
          ariaLabel: 'sent invitation email',
          icon: (props) => <MailIcon {...props} />,
          onPress: () => handleOnEditWithId(Section.contactToMember),
        },
      },
    ]
  } else if (
    record &&
    !isOrganizationMember(record) &&
    record?.role !== WorkspaceAccessRoles.contact
  ) {
    // convert a workspace member to a contact
    content = [
      ...content,
      {
        description: intl.formatMessage({
          id: 'label.changeMemberToContactInformation',
        }),
      },
      {
        description: intl.formatMessage({
          id: 'label.changeMemberToContact',
        }),
        action: {
          ariaLabel: 'change role to contact',
          icon: (props) => <SwapHorizIcon {...props} />,
          onPress: () =>
            requestConfirmation?.({
              title: `${intl.formatMessage({
                id: 'label.changeMemberToContact',
              })}?`,
              description: intl.formatMessage({
                id: 'label.changeMemberToContactConfirmation',
              }),
              actions: [
                {
                  label: intl.formatMessage({ id: 'label.cancel' }),
                },
                {
                  label: intl.formatMessage({ id: 'label.proceed' }),
                  color: 'error',
                  onClick: convertFromMemberToContact,
                },
              ],
            }),
        },
      },
    ]
  }

  return content
}
