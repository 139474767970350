import React from 'react'
import { Container, Content } from 'components/shared'
import { H1 } from 'components/typography'
import { useIntl } from 'react-intl'
import ROUTES from 'lib/routes'
import MuiHeader from 'pages/landing/MuiHeader'
import { Toolbar } from '@mui/material'
import { useLocation } from 'react-router-dom'

function Page() {
  const intl = useIntl()
  const location = useLocation()
  return (
    <Container>
      {!location.pathname.includes(ROUTES.DASHBOARD) && (
        <React.Fragment>
          <MuiHeader />
          <Toolbar />
        </React.Fragment>
      )}
      <Content>
        <H1>{intl.formatMessage({ id: 'label.faq' })}</H1>
      </Content>
    </Container>
  )
}

export default Page
