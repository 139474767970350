import React from 'react'
import { GridColDef } from '@mui/x-data-grid'
import { styled } from '@mui/material/styles'
import colors from 'assets/colors'
import { isValidText } from 'utils/validators'
import { TitleCell } from 'components/table/datagrid'

export const GridWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
}))

const Text = styled('span')(({ theme }) => ({
  fontSize: '14px',
  fontFamily: 'NoirPro',
  lineHeight: '15px',
  color: colors.textSecondary,
}))

// interface OptionsProps {
//   rowData: GridRenderCellParams
// }
// function SpaceOptionCell({ rowData }: OptionsProps) {
//   const { row } = rowData
//   if (row.spaceOptions.spaceType) console.log('ROO', row)
//   return <Text>{row.spaceOptions.spaceType}</Text>
// }

export const columns = (updateNest: Function): GridColDef[] => [
  {
    field: 'id',
    headerName: '#',
    width: 0,
    sortable: false,
    headerAlign: 'center',
    hide: true,
  },
  {
    field: 'title',
    headerName: 'Title',
    width: 250,
    headerAlign: 'center',
    renderCell: (row) => <TitleCell rowData={row} entity="nest" />,
    hideable: false,
    editable: true,
    preProcessEditCellProps: (params) => {
      const { props, row } = params
      const hasError = !isValidText(props.value)
      if (!hasError) {
        const payload = { id: row.id, data: { title: props.value } }
        updateNest({ variables: { payload } })
      }
      return { ...params.props, error: hasError }
    },
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 250,
    headerAlign: 'center',
    renderCell: (row) => <Text>{row.value}</Text>,
    hideable: false,
    editable: true,
    preProcessEditCellProps: (params) => {
      const { props, row } = params
      const hasError = !isValidText(props.value)
      if (!hasError) {
        const payload = { id: row.id, data: { description: props.value } }
        updateNest({ variables: { payload } })
      }
      return { ...params.props, error: hasError }
    },
  },
  // {
  //   field: 'spaceOptions.spaceType',
  //   headerName: 'Type',
  //   width: 250,
  //   headerAlign: 'center',
  //   renderCell: (row) => <SpaceOptionCell rowData={row} />,
  //   hideable: false,
  // },
]
