import * as Sentry from '@sentry/react'
import appConfig from 'config/app'

export default class Logger {
  static init() {
    Sentry.init({
      dsn: appConfig.sentryDns,
      integrations: [
        new Sentry.BrowserTracing({
          // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: [
            'localhost',
            new RegExp(`^${appConfig.graphql}`, 'i'),
            new RegExp(`^${appConfig.api}`, 'i'),
            new RegExp(`^${appConfig.media}`, 'i'),
            new RegExp(`^${appConfig.documents}`, 'i'),
          ],
        }),
        new Sentry.Replay(),
      ],
      environment: appConfig.environment,
      // Performance Monitoring
      tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
  }

  static logException(error: unknown) {
    Sentry.captureException(error)
  }

  static logMessage(message: string) {
    Sentry.captureMessage(message)
  }
}
