import React from 'react'
import { BodyText, BodyTextLight } from 'components/typography'
import { useIntl } from 'react-intl'
import ROUTES from 'lib/routes'
import MuiHeader from 'pages/landing/MuiHeader'
import {
  Grid,
  Toolbar,
  useTheme,
  styled,
  useMediaQuery,
  Card,
  Chip,
  Theme,
  lighten,
} from '@mui/material'
import { Link, useLocation } from 'react-router-dom'
import AppFooter from 'components/AppFooter'
import { Container } from 'pages/landing/fragments/fragments'
import { itemsnestWhite } from 'assets/images'
import StyledText from 'components/StyledIntlText'
import { Button, Column, RowSpaced } from 'components/shared'
import { FontFamily } from 'assets'
import { aboutUsContent } from './content/about'
import { cardBoxShadow, cardBoxShadowHover } from 'assets/styles/theme'

const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  alignItems: 'center',
}))

const HeaderContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.action.active,
  padding: '40px 70px',
  borderBottomLeftRadius: '20px',
  borderBottomRightRadius: '20px',
  [theme.breakpoints.down('sm')]: {
    padding: '30px 30px',
  },
}))

const ItemsnestLogo = styled('img')(({ theme }) => ({
  height: '60px',
  objectFit: 'contain',
}))

const ProductsContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  width: '100%',
  padding: '0',
  justifyContent: 'center',
  maxWidth: theme.breakpoints.values.lg,
  flexDirection: 'column',
  alignItems: 'flex-start',
  margin: '50px 0',
  [theme.breakpoints.down('md')]: {
    margin: '40px 20px',
    width: 'calc(100% - 40px)',
  },
}))

const ContainerCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  borderRadius: '20px',
  padding: '12px 24px',
  height: '100%',
  flexGrow: 1,
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  boxShadow: cardBoxShadow,
  ':hover': {
    cursor: 'pointer',
    boxShadow: cardBoxShadowHover,
  },
}))

const Image = styled('img')(({ theme }) => ({
  width: '175px',
  height: '60px',
  objectFit: 'contain',
  marginTop: '20px',
}))

const Cta = styled(Button)(({ theme, disabled }) => ({
  borderRadius: '5px',
  backgroundColor: theme.palette.action.active,
  marginBottom: '20px',
  fontSize: '14px',
  width: 'calc(100% - 90px)',
  ...(disabled
    ? {
        opacity: '.3',
      }
    : {}),
}))

const StatusChip = styled(Chip)<{ theme?: Theme; chipColor: string }>(
  ({ theme, chipColor }) => ({
    borderRadius: '5px',
    color: chipColor,
    backgroundColor: lighten(chipColor, 0.8),
    fontSize: '12px',
    fontFamily: FontFamily.Blinker,
    height: '20px',
  })
)

function Page() {
  const intl = useIntl()
  const theme = useTheme()
  const location = useLocation()
  const isDashboard = location.pathname.includes(ROUTES.DASHBOARD)
  const isBigScreen = useMediaQuery(theme.breakpoints.up('md'))
  const isBigMediumScreen = useMediaQuery(theme.breakpoints.up('sm'))
  const titleSize = isBigScreen ? 28 : 22
  return (
    <Container>
      {!isDashboard && (
        <React.Fragment>
          <MuiHeader />
          <Toolbar />
        </React.Fragment>
      )}
      <Content>
        {/* HEADER */}
        <HeaderContainer container maxWidth="lg" alignItems="center">
          <Grid item md={6}>
            <ItemsnestLogo alt="Itemsnest" src={itemsnestWhite} />
          </Grid>
          <Grid item md={6}>
            <Column>
              <StyledText
                lineHeight="45px"
                text="label.about.title"
                fontSize={30}
                fontFamily={FontFamily.NoirProMedium}
                paintColor={theme.palette.primary.main}
                color={theme.palette.primary.contrastText}
              />
              <BodyText
                color={theme.palette.primary.contrastText}
                style={{ marginTop: '12px' }}
                fontFamily={FontFamily.Blinker}
              >
                {intl.formatMessage({ id: 'label.about.description' })}
              </BodyText>
            </Column>
          </Grid>
        </HeaderContainer>

        {/* CONTENT */}

        <ProductsContent>
          <StyledText
            fontFamily={FontFamily.NoirProMedium}
            text={aboutUsContent.title}
            fontSize={titleSize}
          />

          <Grid
            container
            maxWidth="lg"
            spacing={2}
            mt={isBigMediumScreen ? '30px' : '16px'}
          >
            {aboutUsContent.products.map((product) => (
              <Grid item key={product.color} xs={12} md={4} mb={2}>
                <ContainerCard>
                  <RowSpaced style={{ width: '100%' }}>
                    <StatusChip
                      chipColor={product.color}
                      label={intl.formatMessage({ id: product.chip })}
                    />
                    <BodyTextLight opacity=".2" style={{ fontSize: '12px' }}>
                      {intl.formatMessage({ id: 'label.poweredByItemsnest' })}
                    </BodyTextLight>
                  </RowSpaced>
                  <Image src={product.logo} />

                  <Column style={{ marginTop: '20px', width: '80%' }}>
                    {product.points.map((point) => (
                      <StyledText
                        lineHeight={'20px'}
                        key={point}
                        text={point}
                        fontSize={12}
                        fontFamily={FontFamily.Blinker}
                      />
                    ))}
                  </Column>

                  <Cta
                    component={Link}
                    to="/"
                    disabled={product.disabled}
                    style={{ color: theme.palette.primary.contrastText }}
                  >
                    {intl.formatMessage({ id: product.cta })}
                  </Cta>
                </ContainerCard>
              </Grid>
            ))}
          </Grid>
        </ProductsContent>
      </Content>
      <AppFooter />
    </Container>
  )
}

export default Page
