import { gql } from '@apollo/client'

export const CREATE_ORGANIZATION = gql`
  mutation CREATE_ORGANIZATION($payload: CreateOrganizationInput!) {
    createOrganization(payload: $payload) {
      id
    }
  }
`

export const ORGANIZATION_DATA = gql`
  query ORGANIZATION_DATA($workspaceId: ID) {
    organizationData(workspaceId: $workspaceId) {
      id
      type
      country
      defaultOptions {
        currency
        measurementSystem
      }
      addressId
      company {
        name
        description
      }
      referenceNumber
      address {
        id
        houseNumber
        street
        city
        zipCode
        countryCode
        geolocation {
          latitude
          longitude
        }
      }
      createdAt
      updatedAt
      subscriptions {
        id
        subscriptionPlanId
        organizationId
        startDate
        endDate
        cancelationDate
        status
        createdAt
        updatedAt
        subscriptionPlan {
          id
          titleDE
          title
          description
          type
          descriptionDE
          duration
          extraDuration
          active
          defaultPlan
          applicableTo
          limitations
          createdAt
          updatedAt
          pricing {
            value
            currency
            countryCode
            tax
            refundDeadline
          }
        }
        orders {
          id
          orderNumber
          subscriptionIds
          organizationId
          date
          createdAt
          updatedAt
          invoices {
            id
            invoiceNumber
            orderId
            date
            items {
              id
              productId
              productReference
              productDescription
              invoiceReference
              fromDate
              toDate
              price {
                value
                currency
              }
            }
            pricing {
              value
              currency
              countryCode
              tax
              totalAmount
            }
            isRefunded
            archiveIds
            status
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`

export const UPDATE_ORGANIZATION = gql`
  mutation UPDATE_ORGANIZATION($payload: UpdateOrganizationInput!) {
    updateOrganization(payload: $payload) {
      id
    }
  }
`
