import * as React from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import useMediaQuery from '@mui/material/useMediaQuery'
import Collapse from '@mui/material/Collapse'
import { useTheme } from '@mui/material/styles'
import { IntlShape, useIntl } from 'react-intl'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { OrderExtended, OrderItemRefExtended } from 'types/order'
import { getCurrencyLabel, getMonetaryValue } from 'utils/monetary'
import { CaptionText, H5 } from 'components/typography'
import Pencil from 'assets/svg/Pencil'
import { Link } from 'react-router-dom'
import ROUTES from 'lib/routes'
import { FontFamily } from 'assets'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.shared.darkGray,
    color: theme.palette.text.primary,
    fontFamily: FontFamily.NoirProMedium,
  },
  padding: 8,
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
  },
}))

const EditIconWrapper = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
}))

function createData(item: OrderItemRefExtended, intl: IntlShape) {
  return {
    id: item.id,
    itemId: item.itemId,
    title: item?.item?.title ?? '',
    deletedAt: item?.item?.deletedAt,
    quantity: item.quantity,
    price: getMonetaryValue(item.amount),
    history: [
      {
        label: intl.formatMessage({ id: 'label.productLink' }),
        value: item.productLink ?? '-',
      },
      {
        label: intl.formatMessage({ id: 'label.serialNumbers' }),
        value:
          (item.serialNumbers?.length ?? 0) > 0
            ? item.serialNumbers?.join(', ')
            : '-',
      },
      {
        label: intl.formatMessage({ id: 'label.totalPrice' }),
        value: getMonetaryValue(item.amount),
      },
      {
        label: intl.formatMessage({ id: 'label.pricePerUnit' }),
        value:
          item.amount?.value && item.quantity
            ? `${item.amount.value / item.quantity} ${
                getCurrencyLabel(item.amount.currency) ?? '-'
              }`
            : '-',
      },
    ],
  }
}

interface RowProps {
  row: ReturnType<typeof createData>
  onEdit: (refId: string) => void
  editing: boolean
  workspaceId: string
}

function Row({ row, onEdit, editing, workspaceId }: RowProps) {
  const intl = useIntl()
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)
  const toggleRow = () => setOpen(!open)
  const handleOnPress = () => onEdit(row.id)

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <StyledTableCell size="small">
          <IconButton aria-label="expand row" size="small" onClick={toggleRow}>
            {open ? (
              <KeyboardArrowUpIcon fontSize="small" />
            ) : (
              <KeyboardArrowDownIcon fontSize="small" />
            )}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          <Link
            style={{
              textDecoration: 'none',
              color: theme.palette.text.primary,
              ...(row.deletedAt && {
                textDecoration: 'line-through',
                color: theme.palette.error.main,
              }),
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 1,
              lineClamp: 1,
              WebkitBoxOrient: 'vertical',
            }}
            // target="_blank"
            to={
              row.deletedAt
                ? ''
                : ROUTES.DASHBOARD_ITEM_DETAILS_ROUTE(workspaceId, row.itemId)
            }
          >
            {row.title}
          </Link>
        </StyledTableCell>
        <StyledTableCell align="center">{row.quantity}</StyledTableCell>
        <StyledTableCell align="right">{row.price}</StyledTableCell>
        {editing && (
          <StyledTableCell align="right">
            <EditIconWrapper>
              <IconButton onClick={handleOnPress}>
                <Pencil size="20px" />
              </IconButton>
            </EditIconWrapper>
          </StyledTableCell>
        )}
      </TableRow>

      <TableRow>
        <StyledTableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <H5>{intl.formatMessage({ id: 'label.details' })}</H5>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    {/* <TableCell>
                      <BodyText>{intl.formatMessage({ id: 'label' })}</BodyText>
                    </TableCell> */}
                    <TableCell />
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.history.map((historyRow) => (
                    <TableRow key={historyRow.label}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        style={{ fontWeight: 'bold' }}
                      >
                        {historyRow.label}
                      </StyledTableCell>
                      <StyledTableCell>
                        <CaptionText>{historyRow.value}</CaptionText>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </StyledTableCell>
      </TableRow>
    </React.Fragment>
  )
}

interface Props {
  order: OrderExtended
  onEditItem?: (orderId: string, refId: string) => void
  workspaceId: string
}
export default function CollapsibleTable({
  order,
  onEditItem,
  workspaceId,
}: Props) {
  const intl = useIntl()
  const theme = useTheme()
  const isBigScreen = useMediaQuery(theme.breakpoints.up('md'))
  const [open, setOpen] = React.useState(false)
  const toggleRow = () => setOpen(!open)
  const head = [
    { key: 'title', label: intl.formatMessage({ id: 'label.items' }) },
    { key: 'quantity', label: intl.formatMessage({ id: 'label.quantity' }) },
    {
      key: 'price',
      label: intl.formatMessage({
        id: isBigScreen ? 'label.totalPrice' : 'label.price',
      }),
    },
    { key: 'edit', label: intl.formatMessage({ id: 'label.edit' }) },
  ].filter((i) => (i.key === 'edit' ? !!onEditItem : true))
  const rows = order.items.map((i) => createData(i, intl))
  const onEditItemRef = (refId: string) => onEditItem?.(order.id, refId)

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead onClick={toggleRow}>
          <TableRow>
            <StyledTableCell size="small" />
            {head.map((item, index) => (
              <StyledTableCell
                key={item.key}
                align={index > 0 ? 'right' : 'inherit'}
              >
                {item.label}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        {/* <Collapse in={open} timeout="auto" unmountOnExit> */}
        <TableBody>
          {rows.map((row) => (
            <Row
              workspaceId={workspaceId}
              key={row.title}
              row={row}
              editing={!!onEditItem}
              onEdit={onEditItemRef}
            />
          ))}
        </TableBody>
        {/* </Collapse> */}
      </Table>
    </TableContainer>
  )
}

CollapsibleTable.defaultProps = {
  onEditItem: null,
}
