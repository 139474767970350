import { MemberModels } from 'types/shared'

const dashboard = '/dashboard'

const ROUTES = {
  INDEX: '/',
  CITY_LANDING: '/city/:city',
  LOGIN: '/login',
  REGISTER: '/register',
  DASHBOARD: dashboard,

  // Email confirmation routes
  CONFIRM_EMAIL: '/confirmation/:token',
  WORKSPACE_INVITATION: '/:workspaceId/invitation/:token',

  PASSWORD_RESET: '/password-reset',
  PASSWORD_RESET_CONFIRMATION: '/password-reset/confirm/:token',

  /** --------------- All routes bellow are rendered under `/dashboard` --------------- */
  // dashboard routes
  DASHBOARD_PROFILE: '/profile',
  DASHBOARD_ACCOUNT_PREFERENCES: '/account-preferences',

  // workspace routes
  DASHBOARD_OVERVIEW: '/:workspaceId',
  DASHBOARD_MEMBERS: '/:workspaceId/members',
  DASHBOARD_MEMBERS_DETAILS: '/:workspaceId/members/:memberModel/:memberId',
  DASHBOARD_SERVICE_PROVIDERS: '/:workspaceId/service-providers',
  DASHBOARD_SETTINGS: '/:workspaceId/settings',
  // DASHBOARD_INVENTORY: '/:workspaceId/inventory',
  DASHBOARD_ITEMS: '/:workspaceId/inventory/items',
  DASHBOARD_NESTS: '/:workspaceId/inventory/nests',
  DASHBOARD_CONTRACTS: '/:workspaceId/inventory/contracts',
  DASHBOARD_CONTAINERS: '/:workspaceId/inventory/containers',
  DASHBOARD_GROUPS: '/:workspaceId/inventory/groups', // item groups
  DASHBOARD_ARCHIVE: '/:workspaceId/archive',

  DASHBOARD_CREATE_WORKSPACE: '/create-board/:organizationId',
  DASHBOARD_CREATE_SERVICE_PROVIDER: '/:workspaceId/create-service-provider',
  DASHBOARD_CREATE_NEST: '/:workspaceId/inventory/create-nest',
  DASHBOARD_CREATE_ITEM: '/:workspaceId/inventory/create-item',
  DASHBOARD_CREATE_ITEM_SCANNER: '/:workspaceId/inventory/create-item-scanner',
  DASHBOARD_CREATE_CONTAINER: '/:workspaceId/inventory/create-container',
  DASHBOARD_CREATE_GROUP: '/:workspaceId/inventory/create-group',
  DASHBOARD_CREATE_CONTRACT:
    '/:workspaceId/inventory/create-contract/:providerId?',
  DASHBOARD_NEST_DETAILS: '/:workspaceId/inventory/nests/:nestId',
  DASHBOARD_ITEM_DETAILS: '/:workspaceId/inventory/items/:itemId',
  DASHBOARD_CONTRACT_DETAILS: '/:workspaceId/inventory/contracts/:contractId',
  DASHBOARD_CONTAINER_DETAILS:
    '/:workspaceId/inventory/containers/:containerId',
  DASHBOARD_GROUP_DETAILS: '/:workspaceId/inventory/groups/:groupId',
  DASHBOARD_SERVICE_DETAILS: '/:workspaceId/service/:serviceId',
  DASHBOARD_SERVICE_PROVIDER_DETAILS:
    '/:workspaceId/service-providers/:providerId',

  // static pages
  TOS: '/tos',
  WITHDRAWAL: '/withdrawal',
  FAQ: '/faq',
  IMPRINT: '/imprint',
  PRIVACY: '/privacy',
  ABOUT_US: '/about-us',
  // subscriptionPlanId, of provided then the user has started the upgrade flow
  PRICING: '/pricing/:subscriptionId?',
  PRICING_EXTEND: '/pricing/extend/:subscriptionId',
  CHECKOUT: '/checkout/:subscriptionPlanId/:countryCode/:subscriptionId?',
  CHECKOUT_UPGRADE: '/checkout/upgrade/:subscriptionId/:subscriptionPlanId',

  // route builder functions
  DASHBOARD_OVERVIEW_ROUTE: (workspaceId: string) =>
    `${dashboard}/${workspaceId}`,
  DASHBOARD_CREATE_SERVICE_PROVIDER_ROUTE: (workspaceId: string) =>
    `${dashboard}/${workspaceId}/create-service-provider`,
  DASHBOARD_CREATE_WORKSPACE_ROUTE: (organizationId: string) =>
    `${dashboard}/create-board/${organizationId}`,
  DASHBOARD_CREATE_NEST_ROUTE: (workspaceId: string) =>
    `${dashboard}/${workspaceId}/inventory/create-nest`,
  DASHBOARD_CREATE_ITEM_ROUTE: (workspaceId: string) =>
    `${dashboard}/${workspaceId}/inventory/create-item`,
  DASHBOARD_CREATE_ITEM_SCANNER_ROUTE: (workspaceId: string) =>
    `${dashboard}/${workspaceId}/inventory/create-item-scanner`,
  DASHBOARD_CREATE_CONTAINER_ROUTE: (workspaceId: string) =>
    `${dashboard}/${workspaceId}/inventory/create-container`,
  DASHBOARD_CREATE_GROUP_ROUTE: (workspaceId: string) =>
    `${dashboard}/${workspaceId}/inventory/create-group`,
  DASHBOARD_CREATE_CONTRACT_ROUTE: (workspaceId: string, providerId?: string) =>
    `${dashboard}/${workspaceId}/inventory/create-contract/${providerId ?? ''}`,
  DASHBOARD_MEMBERS_ROUTE: (workspaceId: string) =>
    `${dashboard}/${workspaceId}/members`,
  DASHBOARD_MEMBERS_DETAILS_ROUTE: (
    workspaceId: string,
    memberId: string,
    memberModel: MemberModels
  ) => `${dashboard}/${workspaceId}/members/${memberModel}/${memberId}`,
  DASHBOARD_SERVICE_PROVIDERS_ROUTE: (workspaceId: string) =>
    `${dashboard}/${workspaceId}/service-providers`,
  DASHBOARD_SETTINGS_ROUTE: (workspaceId: string) =>
    `${dashboard}/${workspaceId}/settings`,
  DASHBOARD_NEST_DETAILS_ROUTE: (workspaceId: string, nestId: string) =>
    `${dashboard}/${workspaceId}/inventory/nests/${nestId}`,
  DASHBOARD_CONTAINER_DETAILS_ROUTE: (
    workspaceId: string,
    containerId: string
  ) => `${dashboard}/${workspaceId}/inventory/containers/${containerId}`,
  DASHBOARD_SERVICE_DETAILS_ROUTE: (workspaceId: string, serviceId: string) =>
    `${dashboard}/${workspaceId}/service/${serviceId}`,
  DASHBOARD_SERVICE_PROVIDER_DETAILS_ROUTE: (
    workspaceId: string,
    providerId: string
  ) => `${dashboard}/${workspaceId}/service-providers/${providerId}`,
  DASHBOARD_GROUP_DETAILS_ROUTE: (workspaceId: string, groupId: string) =>
    `${dashboard}/${workspaceId}/inventory/groups/${groupId}`,
  DASHBOARD_ITEM_DETAILS_ROUTE: (workspaceId: string, itemId: string) =>
    `${dashboard}/${workspaceId}/inventory/items/${itemId}`,
  DASHBOARD_CONTRACT_DETAILS_ROUTE: (workspaceId: string, recordId: string) =>
    `${dashboard}/${workspaceId}/inventory/contracts/${recordId}`,
  // DASHBOARD_INVENTORY_ROUTE: (workspaceId: string) =>
  //   `${dashboard}/${workspaceId}/inventory`,
  DASHBOARD_ITEMS_ROUTE: (workspaceId: string) =>
    `${dashboard}/${workspaceId}/inventory/items`,
  DASHBOARD_NESTS_ROUTE: (workspaceId: string) =>
    `${dashboard}/${workspaceId}/inventory/nests`,
  DASHBOARD_CONTRACTS_ROUTE: (workspaceId: string) =>
    `${dashboard}/${workspaceId}/inventory/contracts`,
  DASHBOARD_CONTAINERS_ROUTE: (workspaceId: string) =>
    `${dashboard}/${workspaceId}/inventory/containers`,
  DASHBOARD_GROUPS_ROUTE: (workspaceId: string) =>
    `${dashboard}/${workspaceId}/inventory/groups`,
  DASHBOARD_ARCHIVE_ROUTE: (workspaceId: string) =>
    `${dashboard}/${workspaceId}/archive`,
  CHECKOUT_ROUTE: (
    subscriptionPlanId: string,
    countryCode: string,
    upgradeSubscriptionId?: string
  ) =>
    `/checkout/${subscriptionPlanId}/${countryCode}/${
      upgradeSubscriptionId ?? ''
    }`,
  CHECKOUT_UPGRADE_ROUTE: (
    // current active subscription
    subscriptionId: string,
    subscriptionPlanId: string
  ) => `${dashboard}/checkout/upgrade/${subscriptionId}/${subscriptionPlanId}`,
  DASHBOARD_CHECKOUT_ROUTE: (
    subscriptionPlanId: string,
    countryCode: string,
    upgradeSubscriptionId?: string
  ) =>
    `${dashboard}${ROUTES.CHECKOUT_ROUTE(
      subscriptionPlanId,
      countryCode,
      upgradeSubscriptionId
    )}`,
  PRICING_ROUTE: (subscriptionId?: string) =>
    `/pricing/${subscriptionId ?? ''}`,
  PRICING_EXTEND_ROUTE: (subscriptionId: string) =>
    `/pricing/extend/${subscriptionId ?? ''}`,
}

export default ROUTES
