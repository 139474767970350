import React from 'react'
import { Grid, useMediaQuery } from '@mui/material'
import { useIntl } from 'react-intl'
import { styled, useTheme } from '@mui/material/styles'
import { parentLaptop, logoFull } from 'assets/images'
import ROUTES from 'lib/routes'
import { Link } from 'react-router-dom'
import { Button, Column } from 'components/shared'
import StyledText from 'components/StyledIntlText'
import { FontFamily } from 'assets'

const Container = styled(Grid)(({ theme }) => ({
  padding: '130px 50px',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    padding: '40px 20px',
  },
}))

const Image = styled('img')(({ theme }) => ({
  width: '230px',
  height: '140px',
  borderRadius: '40px',
}))

const Logo = styled('img')(({ theme }) => ({
  height: '40px',
  borderRadius: '40px',
  objectFit: 'contain',
  marginBottom: '12px',
}))
const Content = styled(Column)(({ theme }) => ({
  alignItems: 'flex-start',
}))

function CtaBanner() {
  const theme = useTheme()
  const intl = useIntl()
  const isBigScreen = useMediaQuery(theme.breakpoints.up('md'))

  const titleSize = isBigScreen ? 28 : 22
  return (
    <Container container maxWidth="lg" spacing={2}>
      {isBigScreen && (
        <Grid item md={3} sm={0}>
          <Image src={parentLaptop} alt="Parent laptop" />
        </Grid>
      )}
      <Grid item md={6}>
        <Content>
          <Logo src={logoFull} alt="Itemzz logo" />
          <StyledText
            fontFamily={FontFamily.NoirProMedium}
            text="label.landing.getOrganized"
            fontSize={titleSize}
          />
        </Content>
      </Grid>
      <Grid item md={3} sm={6}>
        <Button
          component={Link}
          to={ROUTES.REGISTER}
          style={{
            backgroundColor: theme.palette.shared.red,
            borderRadius: '500px',
          }}
        >
          {intl.formatMessage({ id: 'label.landing.startNow' })}
        </Button>
      </Grid>
    </Container>
  )
}

export default CtaBanner
