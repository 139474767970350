import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { styled } from '@mui/material/styles'
import { useParams, useNavigate } from 'react-router-dom'
import { Severity, withSnackbar } from 'components/providers/SnackbarHOC'
import { Container, Content, Row, Button } from 'components/shared'
import ROUTES from 'lib/routes'
import InputRender, { formHasErrors } from 'components/input/InputRender'
import { FormDataType } from 'components/input/types'
import { useMutation } from '@apollo/client'
import { CREATE_CONTAINER, WORKSPACE_CONTAINERS } from 'gql/container'
import { CreateContainerData, CreateContainerInput } from 'types/container'
import LoadingIndicator from 'components/LoadingIndicator'
import { H1 } from 'components/typography'
import { inputs } from './constants'

const Form = styled('form')(({ theme }) => ({
  justifyContent: 'center',
  alignSelf: 'center',
  alignItems: 'center',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  marginBottom: '80px',
  maxWidth: '600px',
  width: '100%',
  minHeight: '60vh',
}))

const ButtonWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

interface Props {
  showSnackbar?: (message: string, severity: Severity) => void
}
function CreateBox(props: Props) {
  const { showSnackbar } = props
  const intl = useIntl()
  const params = useParams() as { workspaceId: string }
  const navigate = useNavigate()

  const [createContainer, { loading }] = useMutation<
    CreateContainerData,
    CreateContainerInput
  >(CREATE_CONTAINER, {
    onCompleted: (response) =>
      navigate(
        ROUTES.DASHBOARD_CONTAINER_DETAILS_ROUTE(
          params.workspaceId,
          response.createContainer.id
        ),
        { replace: true }
      ),
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: [
      {
        query: WORKSPACE_CONTAINERS,
        variables: {
          payload: { workspaceId: params.workspaceId, limit: 25, page: 0 },
        },
      },
    ],
  })

  const [showErrors, setShowErrors] = useState(false)
  const [data, setData] = useState<FormDataType>({
    title: '',
    description: '',
  })

  const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (formHasErrors(inputs, data)) {
      setShowErrors(true)
      return
    }
    const payload = {
      title: data.title as string,
      description: (data.description as string) || undefined,
      workspaceId: params.workspaceId,
    }
    createContainer({ variables: { payload } })
  }

  return (
    <Container>
      <LoadingIndicator visible={loading} />
      <Content>
        <Row>
          <H1>{intl.formatMessage({ id: 'label.createContainer' })}</H1>
        </Row>
        <Form noValidate onSubmit={handleOnSubmit}>
          {inputs.map((input) => {
            return (
              <InputRender
                key={input.key}
                data={data}
                input={input}
                inputs={inputs}
                setData={setData}
                showErrors={showErrors}
              />
            )
          })}

          <ButtonWrapper>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={formHasErrors(inputs, data)}
            >
              {intl.formatMessage({ id: 'label.createContainer' })}
            </Button>
          </ButtonWrapper>
        </Form>
      </Content>
    </Container>
  )
}
CreateBox.defaultProps = {
  showSnackbar: undefined,
}

export default withSnackbar(CreateBox)
