import { IntlShape } from 'react-intl'
import { Order, OrderExtended } from 'types/order'
import { ServiceProviderExtended } from 'types/serviceProvider'

export function classifyOrders<T extends Order | OrderExtended>(
  workspaceId: string,
  orders?: T[]
) {
  return (orders ?? [])?.reduce(
    (acc, order) => {
      const isSale = order.sellerId === workspaceId
      if (isSale) return { ...acc, sales: [...acc.sales, order] }
      return { ...acc, purchases: [...acc.purchases, order] }
    },
    { purchases: [] as T[], sales: [] as T[] }
  )
}

function applyLabel(title: string, label: string) {
  return `${title}  (${label})`
}

export function classifyServiceProviders(
  intl: IntlShape,
  providers?: {
    workspaceProviders: ServiceProviderExtended[]
    publicProviders: ServiceProviderExtended[]
    publicRelatedProviders: ServiceProviderExtended[]
  }
): ServiceProviderExtended[] {
  const { workspaceProviders, publicProviders, publicRelatedProviders } =
    providers ?? {}

  const serviceProvidersArray = [
    ...(workspaceProviders ?? []).map((rec) => ({
      ...rec,
      title: applyLabel(
        rec.title,
        `🔐 ${intl.formatMessage({ id: 'label.inBoard' })}`
      ),
    })),
    ...(publicRelatedProviders ?? []).map((rec) => ({
      ...rec,
      title: applyLabel(
        rec.title,
        `🌍 ${intl.formatMessage({ id: 'label.public' })}`
      ),
    })),
    ...(publicProviders ?? []).map((rec) => ({
      ...rec,
      title: applyLabel(
        rec.title,
        `🌍 ${intl.formatMessage({ id: 'label.public' })}`
      ),
    })),
  ]
  return serviceProvidersArray
}
