import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { useIntl } from 'react-intl'
import { useTheme, styled } from '@mui/material/styles'
import { ModalTitle, RowSpaced, Spacer } from 'components/shared'
import { EventExtended } from 'types/event'
import { Drawer, Toolbar, useMediaQuery } from '@mui/material'
import { cardBoxShadowHover } from 'assets/styles/theme'
import Close from '@mui/icons-material/Close'
import { HeaderButton } from 'pages/landing/MuiHeader'
import { BodyTextLight } from 'components/typography'
import DocumentGrid from 'components/DocumentGrid'
import { sortByDate } from 'utils/sort'
import { ArchiveFile } from 'types/archive'
import { ContentDataGrid } from 'components/content/fragments'
import { getEventContent } from 'utils/content'
import { getActiveWorkspaceId } from 'lib/sessionStorage'
import { InternalRefetchQueriesInclude } from '@apollo/client'

const ModalDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: '35vw',
    padding: '10px 20px',
    boxShadow: cardBoxShadowHover,
    [theme.breakpoints.down('md')]: {
      width: '100vW',
      boxShadow: 'none',
    },
  },
  // zIndex: '9999',
}))

const CloseIcon = styled(Close)(({ theme }) => ({
  color: theme.palette.primary.main,
  width: '20px',
  height: '20px',
}))

interface Props {
  // callback to request a UI rerender
  onRefresh: (recordId: string) => void
  refetchQueries: InternalRefetchQueriesInclude
}

export interface EventDrawerHandleMethods {
  showEventDetails: (event: EventExtended) => void
  hideEventDetails: () => void
}

export default forwardRef(
  (
    { onRefresh, refetchQueries }: Props,
    ref: React.ForwardedRef<EventDrawerHandleMethods>
  ) => {
    const intl = useIntl()
    const theme = useTheme()
    const workspaceId = getActiveWorkspaceId() ?? '' // must be present
    const isBigScreen = useMediaQuery(theme.breakpoints.up('md'))
    const [visible, setVisible] = useState<boolean>(false)
    const [record, setRecord] = useState<EventExtended>()
    const closeDrawer = () => {
      setVisible(false)
      setRecord(undefined)
    }

    const onEscapePress = (event: KeyboardEvent) => {
      if (event.code === 'Escape') closeDrawer()
    }

    useEffect(() => {
      document.addEventListener('keydown', onEscapePress, false)
      return () => {
        document.removeEventListener('keydown', onEscapePress, false)
      }
    }, [onEscapePress])

    const showEventDetails = (event: EventExtended) => {
      if (!event) return
      setRecord(event)
      setVisible(true)
    }

    useImperativeHandle(ref, () => ({
      showEventDetails,
      hideEventDetails: closeDrawer,
    }))

    const onDocumentDelete = () => (record ? onRefresh(record?.id) : null)
    const contentData = record ? getEventContent(workspaceId, record, intl) : []

    return (
      <React.Fragment>
        <ModalDrawer
          anchor="right"
          open={visible}
          onClose={closeDrawer}
          keepMounted={false}
          variant="persistent"
        >
          {isBigScreen && <Toolbar />}
          <RowSpaced flexWrap="nowrap" style={{ alignItems: 'flex-start' }}>
            <ModalTitle>{record?.title}</ModalTitle>
            <HeaderButton onClick={closeDrawer}>
              <CloseIcon />
            </HeaderButton>
          </RowSpaced>
          <BodyTextLight color={theme.palette.text.secondary}>
            {record?.description}
          </BodyTextLight>

          <Spacer size="small" />

          <ContentDataGrid entry={{ data: contentData }} />

          {record?.archive ? (
            <React.Fragment>
              <Spacer size="small" />
              <DocumentGrid
                onDelete={onDocumentDelete}
                documents={sortByDate<ArchiveFile>(record.archive)}
                elementSize={100}
                refetchQueries={refetchQueries}
              />
            </React.Fragment>
          ) : (
            <React.Fragment />
          )}
          {/* used for spacing */}
          {isBigScreen && <Toolbar />}
        </ModalDrawer>
      </React.Fragment>
    )
  }
)
