import { IntlShape } from 'react-intl'
import { isValidText } from 'utils/validators'
import { InputType, Type } from 'components/input/types'
import { SuggestionSection } from 'components/recordDetails/SuggestionsSection'
import { Workspace } from 'types/workspace'

export enum Section {
  transferRecords = 'transferRecords',
}

export const editingSections = (intl: IntlShape): SuggestionSection[] => [
  {
    id: Section.transferRecords,
    title: intl.formatMessage({ id: 'label.transfer' }),
    description: '',
    cta: '',
  },
]

export const getSectionWithId = (id: string, intl: IntlShape) => {
  return editingSections(intl).find((section) => section.id === id)
}

const transferInputs = (workspaces: Workspace[]): InputType[] => [
  {
    key: 'workspaceId',
    label: 'label.board',
    type: Type.SINGLE_SELECT,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    options:
      workspaces.map((item) => ({
        value: item.id,
        label: item.title,
      })) || [],
    validators: [
      {
        method: isValidText,
        label: 'label.required',
      },
    ],
  },
]

const transferInitialState = () => ({
  workspaceId: '',
})

export const getEditInputs = (editId: string, orgWorkspaces?: Workspace[]) => {
  switch (editId) {
    case Section.transferRecords:
      return orgWorkspaces ? transferInputs(orgWorkspaces) : []
    default:
      return []
  }
}

export const getExtraInputs = (editId: string) => {
  return []
}

export const getInitialState = (sectionId: string) => {
  switch (sectionId) {
    case Section.transferRecords:
      return transferInitialState()
    default:
      return {}
  }
}
