import React, { useState, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useQuery } from '@apollo/client'
import { uniqBy } from 'lodash'
import { Severity, withSnackbar } from 'components/providers/SnackbarHOC'
import ROUTES from 'lib/routes'
import LoadingIndicator from 'components/LoadingIndicator'
import { Container, Content, GridWrapper } from 'components/shared'
import ContractCard from 'components/ContractCard'
import Grid from '@mui/material/Grid'
import ListingActions from 'components/pages/ListingActions'
import { DataGridComp } from 'components/table/datagrid'
import { useScrollPercentage } from 'utils/hooks'
import { GridRowId } from '@mui/x-data-grid'
import { JsonObject } from 'types/shared'
import GridSelectionModal from 'components/GridSelectionModal'
import FormModal from 'components/recordDetails/FormModal'
import ListingHeadline from 'components/pages/ListingHeadline'
import { SuggestionSection } from 'components/recordDetails/SuggestionsSection'
import { WorkspaceContractsData, ContractExtended } from 'types/contracts'
import { WORKSPACE_CONTRACTS } from 'gql/contracts'
import { columns } from './fragments'
import {
  getInitialState,
  getEditInputs,
  // getSectionWithId,
  Section,
  getExtraInputs,
} from './constants'
import { useData } from './hooks'
import EmptyList from 'components/EmptyList'

interface Props {
  showSnackbar?: (message: string, severity: Severity) => void
}
function Contracts({ showSnackbar }: Props) {
  const intl = useIntl()
  const params = useParams() as { workspaceId: string }
  const { workspaceId } = params
  const [scrollPercent] = useScrollPercentage()
  const [editing, setEditing] = useState<SuggestionSection>()
  const hideEditingModal = useCallback(() => setEditing(undefined), [])
  const [page, setPage] = React.useState(0)
  const [pageSize, setPageSize] = React.useState(25)
  const [filters, setFilters] = React.useState<{ search: string }>()
  const [records, setRecords] = React.useState<ContractExtended[]>([])
  const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([])
  const [total, setTotal] = React.useState(0)
  const [mode, setMode] = React.useState<'grid' | 'list'>('grid')
  const payload = {
    workspaceId,
    limit: pageSize,
    page,
    filters,
  }
  const { loading: recordsLoading } = useQuery<WorkspaceContractsData>(
    WORKSPACE_CONTRACTS,
    {
      variables: { payload },
      onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
      onCompleted: ({ workspaceContracts }) => {
        mode === 'grid' && total === workspaceContracts.total
          ? setRecords(uniqBy([...records, ...workspaceContracts.list], 'id'))
          : setRecords(workspaceContracts.list)
        setTotal(workspaceContracts.total)
      },
    }
  )
  const {
    data: { orgWorkspaces },
    loading,
  } = useData(workspaceId)

  const onPageChange = useCallback((_page: number) => setPage(_page), [setPage])
  const onPageSizeChange = useCallback(
    (_pageSize: number) => setPageSize(_pageSize),
    [setPageSize]
  )

  React.useEffect(() => {
    if (
      mode === 'grid' &&
      scrollPercent > 90 &&
      !loading &&
      records.length < total
    ) {
      setPage(page + 1)
    }
  }, [scrollPercent])

  const toGridView = () => {
    if (mode === 'grid') return
    setMode('grid')
    if (page > 0) {
      setRecords([])
      setPage(0)
    }
  }
  const toListView = () => {
    if (mode === 'list') return
    setMode('list')
    if (page > 0) {
      setRecords([])
      setPage(0)
    }
  }

  const selectAllRecords = () =>
    setSelectionModel(records.map((item) => item.id))
  const clearSelection = () => setSelectionModel([])

  const handleOnUpdate = (formData: JsonObject, sectionId?: string) => {
    if (sectionId === Section.transferRecords) {
      // const transferPayload = {
      //   itemIds: selectionModel as string[],
      //   workspaceId: formData.workspaceId as string,
      // }
      // transferItems({ variables: { payload: transferPayload } })
    }
  }

  const handleOnSearch = (text: string) => {
    setFilters({ ...filters, search: text })
    setPage(0) // reset the pagination page
  }
  const handleClearFilters = () => {
    setFilters(undefined)
    setPage(0) // reset the pagination page
  }
  const isLoading = loading || recordsLoading
  const selectedAll = selectionModel.length === records.length
  const inputs = getEditInputs(editing?.id ?? '', orgWorkspaces)
  const extraInputs = getExtraInputs(editing?.id ?? '')
  const initialState = editing ? getInitialState(editing.id) : {}

  const renderActions = () => (
    <ListingActions
      mode={mode}
      toListView={toListView}
      toGridView={toGridView}
      onSearch={handleOnSearch}
      onResetSearch={handleClearFilters}
    />
  )
  return (
    <Container>
      <LoadingIndicator visible={isLoading} />
      <Content>
        <ListingHeadline
          title="label.contracts"
          renderActions={renderActions}
          total={total}
          cta={{
            label: 'label.createNewContract',
            route: ROUTES.DASHBOARD_CREATE_CONTRACT_ROUTE(workspaceId),
          }}
        />
        {mode === 'list' ? (
          <GridWrapper>
            <DataGridComp
              initialState={{ pagination: { page, pageSize } }}
              onPageChange={onPageChange}
              onPageSizeChange={onPageSizeChange}
              rows={records}
              columns={columns(intl, workspaceId)}
              // checkboxSelection
              disableSelectionOnClick
              rowsPerPageOptions={[25, 50, 100]}
              rowCount={total || 0}
              experimentalFeatures={{ newEditingApi: true }}
              paginationMode="server"
              onSelectionModelChange={setSelectionModel}
              selectionModel={selectionModel}
            />
            {selectionModel.length > 0 && (
              <GridSelectionModal
                selectedRows={selectionModel}
                actions={[
                  {
                    label: selectedAll
                      ? 'label.clearSelection'
                      : 'label.selectAll',
                    onClick: selectedAll ? clearSelection : selectAllRecords,
                    variant: 'outlined',
                  },
                  // {
                  //   label: 'label.delete',
                  //   onClick: handleDelete,
                  //   color: 'error',
                  // },
                  // {
                  //   label: 'label.transfer',
                  //   onClick: () =>
                  //     handleOnEditWithId(Section.transferRecords),
                  //   color: 'error',
                  // },
                ]}
              />
            )}
          </GridWrapper>
        ) : (
          <Grid container spacing={2} marginTop={0.1}>
            {!isLoading && records.length === 0 && (
              <Grid item xs={12}>
                <EmptyList />
              </Grid>
            )}
            {records.map((record) => (
              <Grid item xs={12} sm={6} md={4} xl={12 / 5} key={record.id}>
                <ContractCard record={record} />
              </Grid>
            ))}
          </Grid>
        )}
      </Content>
      <FormModal
        toggleModal={hideEditingModal}
        inputs={inputs}
        extraInputs={extraInputs}
        section={editing}
        initialState={initialState}
        onSubmit={handleOnUpdate}
      />
    </Container>
  )
}

Contracts.defaultProps = {
  showSnackbar: undefined,
}
export default withSnackbar(Contracts)
