import * as React from 'react'
import Menu from '@mui/material/Menu'
import { Button, lighten, useMediaQuery } from '@mui/material'
import { Column, Row } from './shared'
import { deFlag } from 'assets/images'
import { BodyText, BodyTextLight, H5 } from './typography'
import { FontFamily } from 'assets'
import { useIntl } from 'react-intl'
import { getOptimalLanguage, isGermanLocale } from 'utils/language'
import { changeLanguage } from 'lib/localStorage'
import theme from 'assets/styles/theme'
import { ExpandMoreSharp } from '@mui/icons-material'

interface Option {
  code: 'en' | 'de'
  country: string
  language: string
  onClick: () => void
}
const options: Option[] = [
  {
    code: 'de',
    country: 'country.germanyDE',
    language: 'language.german',
    onClick: () => changeLanguage('de'),
  },
  {
    code: 'en',
    country: 'country.germany',
    language: 'language.english',
    onClick: () => changeLanguage('en'),
  },
]

function LanguageSelector() {
  const intl = useIntl()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const isBigScreen = useMediaQuery(theme.breakpoints.up('md'))
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const language = getOptimalLanguage()
  const code = isGermanLocale(language) ? 'de' : 'en'
  const option = options.find((o) => o.code === code)

  return (
    <React.Fragment>
      <Button
        onClick={handleClick}
        size="small"
        aria-controls={open ? 'language-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <Row flexWrap="nowrap">
          <FlagComponent />
          {isBigScreen && (
            <React.Fragment>
              <BodyTextLight fontFamily={FontFamily.NoirPro} opacity=".5">
                {`${intl.formatMessage({ id: option?.country })} - ${intl.formatMessage({ id: option?.language })}`}
              </BodyTextLight>
              <ExpandMoreSharp fontSize="small" />
            </React.Fragment>
          )}
        </Row>
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="language-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              filter: 'drop-shadow(0px 4px 14px #00000026)',
              mt: 3,
              ml: 6,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Column style={{ padding: '10px 20px' }}>
          <H5>{intl.formatMessage({ id: 'label.selectLanguageCountry' })}</H5>
          <Row style={{ marginTop: '6px' }}>
            {options.map((_option, index) => (
              <LanguageChip option={_option} index={index} key={_option.code} />
            ))}
          </Row>
        </Column>
      </Menu>
    </React.Fragment>
  )
}

function LanguageChip({ option, index }: { option: Option; index: number }) {
  const intl = useIntl()
  // keep the style inline here. styled components styles are overridden

  const language = getOptimalLanguage()
  const code = isGermanLocale(language) ? 'de' : 'en'
  const isActive = code === option.code
  const borderColor = isActive
    ? theme.palette.primary.main
    : lighten(theme.palette.secondary.contrastText, 0.8)

  return (
    <Button
      onClick={option.onClick}
      style={{
        borderRadius: '10px',
        border: `1px solid ${borderColor}`,
        width: '130px',
        ...(index === 0 ? { marginRight: '8px' } : {}),
      }}
    >
      <Row style={{ margin: '6px', display: 'flex', flexWrap: 'nowrap' }}>
        <FlagComponent />
        <Column>
          <BodyText fontFamily={FontFamily.NoirPro}>
            {intl.formatMessage({ id: option.country })}
          </BodyText>
          <BodyTextLight>
            {intl.formatMessage({ id: option.language })}
          </BodyTextLight>
        </Column>
      </Row>
    </Button>
  )
}

function FlagComponent() {
  // keep the style inline here. styled components styles are overridden
  return (
    <div
      style={{
        width: '22px',
        height: '22px',
        overflow: 'hidden',
        borderRadius: '44px',
        marginRight: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img
        style={{ display: 'flex', flex: 1 }}
        src={deFlag}
        alt="Germany flag"
      />
    </div>
  )
}

export default LanguageSelector
