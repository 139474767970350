import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import { ArchiveFile } from 'types/archive'
import AddImageIcon from '@mui/icons-material/AddPhotoAlternate'
import appConfig from 'config/app'
import ImagePreviewSlider from 'components/ImagePreviewSlider'
import { cardGradient } from 'assets/styles/theme'
import MuiIconButton from '@mui/material/IconButton'
import defaultPlaceholder from 'assets/images/imagePlaceholder.png'
import { InternalRefetchQueriesInclude } from '@apollo/client'
import { Row } from './shared'

const Image = styled('img')(({ theme }) => ({
  margin: '0 4px 4px 0',
  objectFit: 'cover',
  width: '250px',
  height: '250px',
  border: '1px solid #E0E0E0',
  [theme.breakpoints.down('sm')]: {
    width: '200px',
    height: '200px',
  },
}))

const ThumbnailImage = styled(Image)(({ theme }) => ({
  width: '78px',
  height: '78px',
  [theme.breakpoints.down('sm')]: {
    width: '50px',
    height: '50px',
  },
}))

const ThumbnailOverLay = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '0px',
  left: '0px',
  background: cardGradient,
  width: '100%',
  height: '100%',
}))

const ImageWrapper = styled('span')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  ':hover button': {
    display: 'flex',
  },
}))

const ExtraLabel = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
  position: 'absolute',
  fontFamily: 'NoirPro',
  fontSize: '16px',
  fontWeight: 700,
  bottom: 8,
  right: 8,
}))

const ContainerGrid = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  maxWidth: '400px', // double of the width of the image
  width: '100%',
}))

const IconButton = styled(MuiIconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  position: 'absolute',
  bottom: 12,
  right: 12,
  display: 'none',
}))

interface Props {
  images: ArchiveFile[]
  focusedId?: string // id of the focused image from the images list
  onCtaClick: () => void
  placeholder?: string
  refetchQueries?: InternalRefetchQueriesInclude
  menuActions?: {
    label: string
    onClick: (archiveId: string) => void
  }[]
}
function ImageGrid({
  images: unsortedImages,
  onCtaClick,
  refetchQueries,
  menuActions,
  focusedId,
  placeholder,
}: Props) {
  const images = [
    ...unsortedImages.filter((rec) => rec.id === focusedId), // cover first
    ...unsortedImages.filter((rec) => rec.id !== focusedId), // everything else
  ]
  const [previewIndex, setPreviewIndex] = useState<number>()
  const closePreview = () => setPreviewIndex(undefined)
  const extraLabel = images.length > 4 ? `+${images.length - 4}` : ''
  const hasImages = images.length > 0
  return (
    <ContainerGrid>
      <ImagePreviewSlider
        visible={previewIndex !== undefined}
        images={images}
        activeIndex={previewIndex}
        onClose={closePreview}
        refetchQueries={refetchQueries}
        menuActions={menuActions}
      />
      <ImageWrapper>
        <Image
          src={
            hasImages
              ? `${appConfig.media}${images[0]?.media?.medium}`
              : placeholder || defaultPlaceholder
          }
          onClick={() => images.length > 0 && setPreviewIndex(0)}
        />
        <IconButton onClick={onCtaClick}>
          <AddImageIcon />
        </IconButton>
      </ImageWrapper>
      <Row style={{ flexWrap: 'nowrap' }}>
        {images.slice(1, 4).map((image, index) => (
          <ImageWrapper
            key={image.id}
            onClick={() => setPreviewIndex(index + 1)}
          >
            <ThumbnailImage
              src={`${appConfig.media}${image.media?.thumbnail}`}
            />
            {index === 2 && extraLabel.length > 0 && (
              <React.Fragment>
                <ThumbnailOverLay />
                <ExtraLabel>{extraLabel}</ExtraLabel>
              </React.Fragment>
            )}
          </ImageWrapper>
        ))}
      </Row>
    </ContainerGrid>
  )
}

ImageGrid.defaultProps = {
  refetchQueries: undefined,
  menuActions: [],
  focusedId: undefined,
  placeholder: undefined,
}
export default ImageGrid
