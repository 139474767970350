export function isInViewport(el: HTMLElement | null): boolean {
  if (!el) return false
  const partiallyVisible = true
  const { top, left, bottom, right } = el.getBoundingClientRect()
  const { innerHeight, innerWidth } = window
  return partiallyVisible
    ? ((top > 0 && top < innerHeight) ||
        (bottom > 0 && bottom < innerHeight)) &&
        ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth))
    : top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth
}

export function getViewPercentage(element: HTMLElement | null) {
  if (!element) return 0
  const viewport = {
    top: window.pageYOffset,
    bottom: window.pageYOffset + window.innerHeight,
  }

  const elementBoundingRect = element.getBoundingClientRect()
  const elementPos = {
    top: elementBoundingRect.y + window.pageYOffset,
    bottom:
      elementBoundingRect.y + elementBoundingRect.height + window.pageYOffset,
  }

  if (viewport.top > elementPos.bottom || viewport.bottom < elementPos.top) {
    return 0
  }

  // Element is fully within viewport
  if (viewport.top < elementPos.top && viewport.bottom > elementPos.bottom) {
    return 100
  }

  // Element is bigger than the viewport
  if (elementPos.top < viewport.top && elementPos.bottom > viewport.bottom) {
    return 100
  }

  const elementHeight = elementBoundingRect.height
  let elementHeightInView = elementHeight

  if (elementPos.top < viewport.top) {
    elementHeightInView = elementHeight - (window.pageYOffset - elementPos.top)
  }

  if (elementPos.bottom > viewport.bottom) {
    elementHeightInView -= elementPos.bottom - viewport.bottom
  }

  const percentageInView = (elementHeightInView / window.innerHeight) * 100

  return Math.round(percentageInView)
}
