import React, { useCallback } from 'react'
import { Button, RowSpaced } from 'components/shared'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Theme } from '@mui/material/styles'
import { Severity, withSnackbar } from 'components/providers/SnackbarHOC'
import { IntlShape, useIntl } from 'react-intl'
import { H5, BodyText } from 'components/typography'
import { useQuery } from '@apollo/client'
import { ORGANIZATION_WORKSPACES } from 'gql/workspace'
import { OrganizationWorkspacesData } from 'types/organization'
import { DataGridComp, TitleCell } from 'components/table/datagrid'
import { GridColDef } from '@mui/x-data-grid'
import {
  ConfirmationData,
  withConfirmationModal,
} from 'components/hoc/ConfirmationModalHoc'
import { useWsMutations } from 'pages/dashboard/settings/hooks'

const columns = (
  intl: IntlShape,
  theme: Theme,
  onBtnClick: (workspaceId: string) => void
): GridColDef[] => [
  {
    field: 'title',
    headerName: intl.formatMessage({ id: 'label.title' }),
    width: 150,
    headerAlign: 'center',
    renderCell: (row) => <TitleCell rowData={row} entity={null} />,
    hideable: false,
  },
  {
    field: 'description',
    headerName: intl.formatMessage({ id: 'label.description' }),
    width: 200,
    headerAlign: 'center',
    renderCell: (row) => <BodyText>{row.value}</BodyText>,
  },
  {
    field: 'archived',
    headerName: intl.formatMessage({ id: 'label.archived' }),
    width: 150,
    headerAlign: 'center',
    renderCell: ({ row }) => (
      <BodyText style={{ textAlign: 'center', flex: 1 }}>
        {row.archived
          ? `${intl.formatMessage({ id: 'label.archived' })} ⛔️`
          : `${intl.formatMessage({ id: 'label.active' })} ✅`}
      </BodyText>
    ),
    hideable: false,
  },

  {
    field: 'cta',
    headerName: intl.formatMessage({ id: 'label.archived' }),
    width: 120,
    headerAlign: 'center',
    renderCell: ({ row }) => (
      <Button
        onClick={() => onBtnClick(row.id)}
        bgColor={
          row.archived ? theme.palette.action.active : theme.palette.error.main
        }
      >
        {row.archived
          ? intl.formatMessage({ id: 'label.restore' })
          : intl.formatMessage({ id: 'label.archive' })}
      </Button>
    ),
    hideable: false,
  },
]

interface Props {
  showSnackbar?: (message: string, severity: Severity) => void
  requestConfirmation?: (data: ConfirmationData) => void
  organizationId: string
}
function BoardsSection({
  organizationId,
  showSnackbar,
  requestConfirmation,
}: Props) {
  const theme = useTheme()
  const intl = useIntl()
  // to keep the workspace id to toggle archive flag
  const [workspaceId, setWorkspaceId] = React.useState<string>('')
  const [page, setPage] = React.useState(0)
  const isBigScreen = useMediaQuery(theme.breakpoints.up('md'))
  const [pageSize, setPageSize] = React.useState(8)
  const { data: orgWorkspacesData } = useQuery<OrganizationWorkspacesData>(
    ORGANIZATION_WORKSPACES,
    { variables: { organizationId } }
  )
  const length = orgWorkspacesData?.organizationWorkspaces.length || 0
  const records = orgWorkspacesData?.organizationWorkspaces ?? []

  const onPageChange = useCallback((_page: number) => setPage(_page), [setPage])
  const onPageSizeChange = useCallback(
    (_pageSize: number) => setPageSize(_pageSize),
    [setPageSize]
  )

  const {
    mutations: { archiveWorkspace, unarchiveWorkspace },
  } = useWsMutations(workspaceId, {
    showSnackbar,
    refetchQueries: [
      { query: ORGANIZATION_WORKSPACES, variables: { organizationId } },
    ],
  })

  const toggleArchiveStatus = (wsId: string) => {
    setWorkspaceId(wsId)
    requestConfirmation?.({
      title: intl.formatMessage({ id: 'label.toggleArchiveStatus' }),
      description: intl.formatMessage({
        id: 'label.toggleArchiveStatusConfirmation',
      }),
      actions: [
        {
          label: intl.formatMessage({ id: 'label.cancel' }),
          variant: 'outlined',
          onClick: cancelToggle,
        },
        {
          label: intl.formatMessage({ id: 'label.confirm' }),
          color: 'error',
          onClick: () => confirmToggle(wsId),
        },
      ],
    })
  }

  const confirmToggle = (wsId: string) => {
    const workspace = records.find((w) => w.id === wsId)
    const variables = { workspaceId: wsId }
    if (workspace?.archived) {
      unarchiveWorkspace({ variables })
    } else {
      archiveWorkspace({ variables })
    }
    // close the modal
    cancelToggle()
  }
  const cancelToggle = () => {
    setWorkspaceId('')
  }

  return (
    <React.Fragment>
      <DataGridComp
        initialState={{ pagination: { page, pageSize } }}
        columnVisibilityModel={{
          archived: isBigScreen,
          description: isBigScreen,
        }}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        rows={records}
        columns={columns(intl, theme, toggleArchiveStatus)}
        disableSelectionOnClick
        rowsPerPageOptions={[8, 16, 50]}
        rowCount={length}
        experimentalFeatures={{ newEditingApi: true }}
      />
      {!length && (
        <RowSpaced>
          <div />
          <H5> {intl.formatMessage({ id: 'label.noBoards' })}</H5>
          <div />
        </RowSpaced>
      )}
    </React.Fragment>
  )
}

BoardsSection.defaultProps = {
  showSnackbar: undefined,
  requestConfirmation: undefined,
}

export default withConfirmationModal(withSnackbar(BoardsSection))
