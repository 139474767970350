import React from 'react'
import Grid from '@mui/material/Grid'
import { FileType } from 'types/archive'
import { ItemExtended } from 'types/item'
import ImageHeadlineGrid from 'components/ImageHeadlineGrid'
import { PageDescription } from 'components/pages'
import { styled } from '@mui/material/styles'
import { RowSpaced } from 'components/shared'
import { useIntl } from 'react-intl'
import MuiIconButton from '@mui/material/IconButton'
import Pencil from 'assets/svg/Pencil'
import { H1 } from 'components/typography'
import { JsonObject } from 'types/shared'
import { StatusChip } from 'components/cards'
import { getDate } from 'utils/datetime'
import { Section } from './constants'

const Container = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: '18px',
  borderRadius: '5px',
  marginBottom: '16px',
}))

const IconButton = styled(MuiIconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
}))

const HeadlineContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    marginLeft: '20px',
  },
}))

interface Props {
  record: ItemExtended
  showMediaUploadModal: () => void
  onEditSection: (sectionId: Section) => void
  handleRecordUpdate: (formData: JsonObject, sectionId?: string) => void
}
function Headline({
  record,
  showMediaUploadModal,
  onEditSection,
  handleRecordUpdate,
}: Props) {
  const intl = useIntl()
  const images = record?.archive?.filter((i) => i.type === FileType.image) ?? []
  const onEdit = () => onEditSection(Section.general)

  const onSetAsCover = (archiveId: string) => {
    handleRecordUpdate({ coverId: archiveId })
  }

  const headline = (
    <HeadlineContainer>
      <RowSpaced>
        {record.deletedAt ? (
          <StatusChip
            color="error"
            label={intl.formatMessage(
              { id: 'label.deletedAtDate' },
              { date: getDate(record.deletedAt) }
            )}
          />
        ) : (
          <div />
        )}

        <IconButton onClick={onEdit}>
          <Pencil />
        </IconButton>
      </RowSpaced>
      <H1>{record.title}</H1>
      <PageDescription>{record.description}</PageDescription>
      {record.quantity && (
        <PageDescription>
          <br />
          {intl.formatMessage(
            { id: 'label.quantityCount' },
            { number: record.quantity }
          )}
        </PageDescription>
      )}
    </HeadlineContainer>
  )

  return (
    <Container>
      <Grid container mb={4} spacing={1}>
        <Grid item md={5}>
          <ImageHeadlineGrid
            images={images}
            focusedId={record.coverId}
            onCtaClick={showMediaUploadModal}
            menuActions={[
              {
                label: intl.formatMessage({ id: 'label.setCover' }),
                onClick: onSetAsCover,
              },
            ]}
          />
        </Grid>
        <Grid item md={7}>
          {headline}
        </Grid>
      </Grid>
    </Container>
  )
}

export default Headline
