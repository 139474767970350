import React, { useState } from 'react'
import { alpha, Theme, styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Button, Content, Row, RowSpaced } from 'components/shared'
import { Severity } from 'components/providers/SnackbarHOC'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import StyledText from 'components/StyledIntlText'
import { useMutation } from '@apollo/client'
import { GROUPED_WORKSPACES } from 'gql/workspace'
import { CREATE_ORGANIZATION } from 'gql/organization'
import {
  CreateOrganizationData,
  CreateOrganizationInput,
  OrganizationType,
} from 'types/organization'
import ROUTES from 'lib/routes'
import { Card, CardContent, Grid, Radio } from '@mui/material'
import { cardBoxShadow, cardBoxShadowHover } from 'assets/styles/theme'
import personal from 'assets/images/organizations/personal.png'
import family from 'assets/images/organizations/family.png'
import business from 'assets/images/organizations/business.png'
import { BodyText, H2, H4, H6 } from 'components/typography'
import lock from 'assets/images/lock.svg'
import { WorkspaceSection } from 'types/workspace'
import emptyImage from 'assets/images/howItWorks4.png'
import { getSubscriptionStatusCheck } from './constants'

export const ContentElement = styled(Content)(({ theme }) => ({
  padding: `${theme.spacing(6)} 0px`,
}))

export const RowWrapper = styled(Row)(({ theme }) => ({
  marginTop: '40px',
  [theme.breakpoints.down('md')]: {
    marginTop: '20px',
  },
}))

const EmptyContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
}))

const LockImage = styled('img')(({ theme }) => ({
  height: '40px',
  objectFit: 'contain',
  position: 'absolute',
  top: 10,
  right: 10,
  width: 'auto',
  opacity: '.8',
  borderRadius: '40px',
  boxShadow:
    'inset 5px 5px 30px 0px rgba(255,255,255,1),  5px 5px 30px 0px rgba(255,255,255,1);;',
}))

interface Type {
  key: OrganizationType
  title: string
  description: string
  image: string
  disabled: boolean
}

export function EmptyDashboard({
  showSnackbar,
}: {
  showSnackbar?: (message: string, severity: Severity) => void
}) {
  const theme = useTheme()
  const intl = useIntl()
  const navigate = useNavigate()
  const isBigScreen = useMediaQuery(theme.breakpoints.up('md'))
  const orgTypes: Type[] = [
    {
      key: OrganizationType.personal,
      title: intl.formatMessage({ id: 'label.personal' }),
      description: intl.formatMessage({
        id: 'label.personalAccountDescription',
      }),
      image: personal,
      disabled: false,
    },
    {
      key: OrganizationType.family,
      title: intl.formatMessage({ id: 'label.family' }),
      description: intl.formatMessage({
        id: 'label.familyAccountDescription',
      }),
      image: family,
      disabled: false,
    },
    {
      key: OrganizationType.business,
      title: intl.formatMessage({ id: 'label.business' }),
      description: intl.formatMessage({
        id: 'label.businessAccountDescription',
      }),
      image: business,
      disabled: true,
    },
  ]
  const [selectedType, setType] = useState<OrganizationType>()
  const handleSelect = (type: OrganizationType) => setType(type)

  const [createOrganization] = useMutation<
    CreateOrganizationData,
    CreateOrganizationInput
  >(CREATE_ORGANIZATION, {
    onCompleted: (response) => {
      const organizationId = response.createOrganization.id
      navigate(ROUTES.DASHBOARD_CREATE_WORKSPACE_ROUTE(organizationId))
    },
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: [{ query: GROUPED_WORKSPACES }],
  })

  const onSetupOrganization = () => {
    if (!selectedType) return
    const payload = {
      type: selectedType,
    }
    createOrganization({ variables: { payload } })
  }

  return (
    <EmptyContainer>
      <Row
        style={{
          width: '100%',
          marginTop: '20px',
        }}
      >
        <StyledText
          text="label.initialOrgSetup"
          fontSize={isBigScreen ? 18 : 16}
        />
      </Row>
      <Grid container spacing={2} marginTop={2} justifyContent="center">
        {orgTypes.map((type) => (
          <Grid
            item
            key={type.key}
            style={{ flex: 1, flexGrow: 1 }}
            xs={12}
            sm={12}
            md={6}
          >
            <OrgTypeCard
              selected={selectedType}
              type={type}
              onSelect={handleSelect}
              onSetupOrganization={onSetupOrganization}
            />
          </Grid>
        ))}
      </Grid>
    </EmptyContainer>
  )
}

EmptyDashboard.defaultProps = {
  showSnackbar: undefined,
}

interface OrgTypeCardProps {
  selected?: OrganizationType
  type: Type
  onSelect: (type: OrganizationType) => void
  onSetupOrganization: () => void
}

function OrgTypeCard({
  selected,
  type,
  onSelect,
  onSetupOrganization,
}: OrgTypeCardProps) {
  const intl = useIntl()
  const handlePress = () => (type.disabled ? null : onSelect(type.key))
  const active = type.key === selected
  return (
    <CardContentWrapper onClick={handlePress}>
      <CardBgImage src={type.image} />

      <TypeContent selected={active}>
        <RowSpaced style={{ width: '100%' }}>
          <Row>
            <Radio checked={active} />
            <H2>{type.title}</H2>
          </Row>
          {active && (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={onSetupOrganization}
            >
              {intl.formatMessage({ id: 'label.start' })}
            </Button>
          )}
        </RowSpaced>
        <div style={{ width: '70%' }}>
          <BodyText>{type.description}</BodyText>
        </div>
      </TypeContent>

      {type.disabled && (
        <LockOverlay>
          <H4>{intl.formatMessage({ id: 'label.comingSoon' })}</H4>
          <LockImage src={lock} />
        </LockOverlay>
      )}
    </CardContentWrapper>
  )
}

OrgTypeCard.defaultProps = {
  selected: undefined,
}

const CardContentWrapper = styled(Card)(({ theme }) => ({
  borderRadius: '5px',
  padding: 0,
  minHeight: '170px',
  boxShadow: cardBoxShadow,
  minWidth: '350px',
  position: 'relative',
  flex: 1,
  alignItems: 'center',
  overflow: 'hidden',
  ':hover': {
    cursor: 'pointer',
    boxShadow: cardBoxShadowHover,
  },
}))

const TypeContent = styled(CardContent)<{
  theme?: Theme
  selected?: boolean
}>(({ theme, selected }) => ({
  borderRadius: '5px',
  minHeight: '170px',
  alignItems: 'flex-start',
  backdropFilter: 'blur(3px)',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'space-between',
  backgroundColor: alpha(theme.palette.background.default, 0.2),
  ...(selected && {
    border: `1px solid ${theme.palette.primary.light}`,
  }),
}))

const CardBgImage = styled('img')(({ theme }) => ({
  position: 'absolute',
  objectFit: 'cover',
  width: '100%',
  alignSelf: 'center',
  alignItems: 'center',
}))

const LockOverlay = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  backdropFilter: 'blur(1px)',
  backgroundColor: alpha(theme.palette.action.active, 0.2),
  justifyContent: 'center',
  alignItems: 'center',
}))

const NoBoardsImage = styled('img')(({ theme }) => ({
  height: '250px',
  alignSelf: 'center',
  alignItems: 'center',
}))

export function NoWorkspacesCard() {
  const intl = useIntl()
  return (
    <Row style={{ justifyContent: 'center' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '-30px',
        }}
      >
        <NoBoardsImage src={emptyImage} />
        <BodyText style={{ marginTop: '-30px' }}>
          {intl.formatMessage({ id: 'label.noActiveBoards' })}
        </BodyText>
      </div>
    </Row>
  )
}

const PlanContainer = styled(Row)(({ theme }) => ({
  backgroundColor: theme.palette.shared.red,
  padding: '8px 4px',
}))

const PlanText = styled(H6)(({ theme }) => ({
  color: theme.palette.background.paper,
}))

/**
 * Banner to show that there is on active subscription plan.
 */
export function OrganizationPlanBanner({
  section,
  userId,
}: {
  section: WorkspaceSection
  userId: string // logged in user id
}) {
  const intl = useIntl()
  const status = getSubscriptionStatusCheck(intl, section, userId)
  if (status.active) return null
  return (
    <PlanContainer>
      <PlanText>{status.actionLabel}</PlanText>
    </PlanContainer>
  )
}
