import React from 'react'
import { Theme, useTheme } from '@mui/material/styles'
import { styled } from '@mui/material/styles'
import { CaptionText, H1, H5 } from 'components/typography'
import { Column } from 'components/shared'
import { cardBoxShadow, cardBoxShadowHover } from 'assets/styles/theme'
import { FontFamily } from 'assets'
import { useIntl } from 'react-intl'

export const Container = styled('div', {
  shouldForwardProp: (prop) => prop !== 'center',
})<{
  theme?: Theme
  center?: boolean
  backgroundColor?: string
}>(({ theme, center, backgroundColor }) => ({
  display: 'flex',
  overflow: 'hidden',
  flexDirection: 'column',
  backgroundColor: backgroundColor ?? theme.palette.shared.bgGray,
  minHeight: '100vh',
  ...(center && { justifyContent: 'center', alignItems: 'center' }),
}))

export const SectionContainer = styled('div')<{
  theme?: Theme
}>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  width: `min(${theme.breakpoints.values.lg}px, calc(100vw - 40px))`,
  margin: '100px 20px 0 20px',
  [theme.breakpoints.down('md')]: {
    margin: '30px 10px 0 10px',
  },
}))

export const SectionBodyText = styled(H5)(({ theme }) => ({
  marginBottom: '6px',
  fontFamily: FontFamily.Blinker,
}))

export const H1Focused = styled(H1)(({ theme }) => ({
  fontSize: '40px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '35px',
  },
}))

export const ImgWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  ':hover': {
    cursor: 'pointer',
  },
}))

const TagWrapper = styled(Column)(({ theme }) => ({
  padding: '5px',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '5px',
  position: 'absolute',
  width: '100px',
  margin: '-80px 0 0 -50px',
  boxShadow: cardBoxShadow,
  animation: 'slide-in 1s',
  ':hover': {
    cursor: 'pointer',
    boxShadow: cardBoxShadowHover,
  },
}))

export function SpaceTag() {
  const intl = useIntl()
  const theme = useTheme()
  return (
    <TagWrapper>
      <CaptionText bold fontSize="8px" fontFamily={FontFamily.NoirProMedium}>
        {intl.formatMessage({ id: 'landing.spaceTag.space' })}
      </CaptionText>
      <CaptionText fontSize="8px">
        {intl.formatMessage({ id: 'label.containers' })}:{' '}
        <CaptionText color={theme.palette.primary.main} fontSize="8px">
          5 {intl.formatMessage({ id: 'label.boxes' })}
        </CaptionText>
      </CaptionText>
      <CaptionText fontSize="8px">
        {intl.formatMessage({ id: 'label.items' })}:{' '}
        <CaptionText color={theme.palette.primary.main} fontSize="8px">
          {' '}
          18 {intl.formatMessage({ id: 'label.items' })}
        </CaptionText>
      </CaptionText>
      <CaptionText fontSize="8px">
        {intl.formatMessage({ id: 'label.value' })}:{' '}
        <CaptionText color={theme.palette.shared.red} fontSize="8px">
          3.400 €
        </CaptionText>
      </CaptionText>
    </TagWrapper>
  )
}

const HouseTagWrapper = styled(TagWrapper)(({ theme }) => ({
  margin: '-20px 0 0 30px',
}))

export function HouseTag() {
  const intl = useIntl()
  return (
    <HouseTagWrapper>
      <CaptionText bold fontSize="8px" fontFamily={FontFamily.NoirProMedium}>
        {intl.formatMessage({ id: 'landing.spaceTag.homeCredit' })}:
      </CaptionText>
      <CaptionText bold fontSize="8px" fontFamily={FontFamily.NoirProMedium}>
        750.000 €
      </CaptionText>
      <CaptionText bold fontSize="8px" fontFamily={FontFamily.NoirProMedium}>
        5 Documents
      </CaptionText>
    </HouseTagWrapper>
  )
}
