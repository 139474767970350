export const bytesToMb = (bytes: number) => (bytes / 1024 / 1024).toFixed(2)

/**
 * Download documents from the ItemsNest api.
 * Steps:
 * 1. Fetch blob response from the api. This will return the redirect link of the document with an access token.
 * 2. Extract the filename from the redirected link and download this document with the extracted name.
 * @param path Link to document in the api. 
 */
export const downloadDocument = (path: string) => {
  fetch(path).then((response) => {
    response.blob().then((blob) => {
      const url = window.URL.createObjectURL(blob)
      let fileName = url.substring(0, url.indexOf('?token='))
      fileName = fileName.substring(fileName.lastIndexOf('/'))
      const link = document.createElement('a')
      link.download = fileName
      link.href = url
      link.target = '_blank'
      link.click()
    })
  })
}
