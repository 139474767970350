import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { useIntl } from 'react-intl'
import useMediaQuery from '@mui/material/useMediaQuery'
import { styled, useTheme } from '@mui/material/styles'
import {
  Button,
  SimpleButton,
  ModalTransition,
  RowSpaced,
} from 'components/shared'
import Dialog from '@mui/material/Dialog'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { FileType } from 'types/archive'
import { FileEntry } from 'types/shared'
import DropZone from 'components/dropzone/Dropzone'
import { ErrorType } from 'hooks/api/fileUpload'
import { H2 } from 'components/typography'
import LoadingIndicator from 'components/LoadingIndicator'

const Container = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  padding: '10px 20px',
}))

const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  marginTop: '10px',
  [theme.breakpoints.up('md')]: {
    minWidth: '80vw',
    minHeight: '80vh',
  },
}))

const RightWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 6,
  flexDirection: 'column',
}))

const ButtonWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: '10px',
}))

export interface UploadHandlerMethods {
  openUploader: (fileType: FileType) => void
  closeUploader: () => void
  stopLoader: () => void
}

interface Props {
  onSubmit: (files: FileEntry[], fileType: FileType) => void
  errors?: ErrorType[] // upload errors
}
const HandlerComponent = forwardRef(
  (props: Props, ref: React.ForwardedRef<UploadHandlerMethods>) => {
    const theme = useTheme()
    const intl = useIntl()
    const { errors, onSubmit } = props
    const [open, setOpen] = useState<boolean>(false)
    const [loading, setLoading] = useState(false)
    const [fileType, setFileType] = useState<FileType>()
    const [files, setFiles] = useState<FileEntry[]>()
    const handleOnsubmit = () => {
      if (files && fileType) {
        onSubmit(files, fileType)
        setLoading(true)
      }
    }
    const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'))
    useEffect(() => setLoading(false), [errors])

    const closeUploader = () => {
      setOpen(false)
      setFiles(undefined)
      setLoading(false)
    }

    const openUploader = (type: FileType) => {
      setOpen(true)
      setFiles(undefined)
      setFileType(type)
    }

    useImperativeHandle(ref, () => ({
      openUploader,
      closeUploader,
      stopLoader: () => setLoading(false),
    }))

    return (
      <Dialog
        open={open}
        onClose={closeUploader}
        TransitionComponent={ModalTransition}
        maxWidth="lg"
        fullScreen={isMobileScreen}
      >
        <Container>
          <RowSpaced>
            <H2>{intl.formatMessage({ id: 'label.archive' })}</H2>
            {isMobileScreen && (
              <IconButton onClick={closeUploader}>
                <CloseIcon />
              </IconButton>
            )}
          </RowSpaced>
          <div style={{ position: 'relative' }}>
            <LoadingIndicator visible={loading} />
          </div>
          <Content>
            <RightWrapper>
              {fileType && (
                <DropZone
                  files={files ?? []}
                  setFiles={setFiles}
                  fileType={fileType}
                  errors={errors}
                />
              )}
              <ButtonWrapper>
                {!isMobileScreen && (
                  <SimpleButton
                    variant="outlined"
                    color="primary"
                    onClick={closeUploader}
                  >
                    {intl.formatMessage({ id: 'label.cancel' })}
                  </SimpleButton>
                )}
                <Box ml={1} />
                <Button
                  onClick={handleOnsubmit}
                  variant="contained"
                  disabled={(files?.length ?? 0) === 0}
                >
                  {intl.formatMessage({ id: 'label.upload' })}
                </Button>
              </ButtonWrapper>
            </RightWrapper>
          </Content>
        </Container>
      </Dialog>
    )
  }
)

HandlerComponent.defaultProps = {
  errors: undefined,
}
export default HandlerComponent
