import { ArchiveFile } from './archive'
import { ContainerExtended } from './container'
import { ContractExtended } from './contracts'
import { ItemExtended } from './item'
import { NestExtended } from './nest'
import { OrganizationRoles } from './organization'
import { WorkspaceAccessRoles, ConfirmationStatus, Status } from './shared'

export interface BasicUserStruct {
  id?: string
  refId?: string
  createdAt: Date
  updatedAt: Date
  role?: string
  email?: string
  firstName?: string
  lastName?: string
  status?: string
  avatar?: {
    avatar?: string
  }
}

export interface WorkspaceMember extends BasicUserStruct {
  id: string
  firstName: string
  lastName?: string
  userId?: string
  email?: string
  workspaceId: string
  role: WorkspaceAccessRoles
  status: ConfirmationStatus
  createdAt: Date
  updatedAt: Date
  avatar?: {
    avatar?: string
  }
}

export interface WorkspaceMemberExtended extends WorkspaceMember {
  refId: string
}

export interface OrganizationMember extends BasicUserStruct {
  id: string
  userId?: string
  email?: string
  organizationId: string
  role: OrganizationRoles
  status?: ConfirmationStatus
}

export interface OrganizationMemberExtended extends OrganizationMember {
  refId: string
  user: {
    id: string
    firstName: string
    lastName?: string
    email?: string
    avatar?: {
      avatar?: string
    }
  }
}

interface MemberRefs {
  items: ItemExtended[]
  nests: NestExtended[]
  containers: ContainerExtended[]
  contracts: ContractExtended[]
}

export interface OrganizationMemberDetails
  extends OrganizationMemberExtended,
    MemberRefs {
  canEdit: boolean
  archive?: ArchiveFile[]
}

export interface WorkspaceMemberDetails extends WorkspaceMember, MemberRefs {
  // linked user account to the membership
  user?: {
    id: string
    email: string
    firstName: string
    lastName?: string
  }
  canEdit: boolean
  archive?: ArchiveFile[]
}

type UserType =
  | BasicUserStruct
  | OrganizationMemberExtended
  | WorkspaceMemberExtended

export const isOrganizationMember = (
  member: UserType
): member is OrganizationMemberExtended =>
  Object.keys(member).includes('organizationId')

export interface WorkspaceRoleData {
  workspaceRole: {
    organizationRole?: OrganizationRoles
    workspaceRole?: WorkspaceAccessRoles
    workspaceMembership?: WorkspaceMember
  }
}

export interface WorkspaceRoleInput {
  workspaceId: string
}

export interface LeaveWorkspaceData {
  leaveWorkspace: {
    status: Status
  }
}

export interface LeaveWorkspaceInput {
  membershipId: string
}
