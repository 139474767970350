import { gql } from '@apollo/client'

export const CREATE_MEMBER_ITEM_REF = gql`
  mutation CREATE_MEMBER_ITEM_REF($payload: MemberItemRefInput!) {
    createMemberItemRef(payload: $payload) {
      id
    }
  }
`

export const DELETE_MEMBER_ITEM_REF = gql`
  mutation DELETE_MEMBER_ITEM_REF($id: ID!) {
    deleteMemberItemRef(id: $id) {
      status
    }
  }
`

export const CREATE_MEMBER_NEST_REF = gql`
  mutation CREATE_MEMBER_NEST_REF($payload: MemberNestRefInput!) {
    createMemberNestRef(payload: $payload) {
      id
    }
  }
`

export const DELETE_MEMBER_NEST_REF = gql`
  mutation DELETE_MEMBER_NEST_REF($id: ID!) {
    deleteMemberNestRef(id: $id) {
      status
    }
  }
`

export const CREATE_MEMBER_CONTAINER_REF = gql`
  mutation CREATE_MEMBER_CONTAINER_REF($payload: MemberContainerRefInput!) {
    createMemberContainerRef(payload: $payload) {
      id
    }
  }
`

export const DELETE_MEMBER_CONTAINER_REF = gql`
  mutation DELETE_MEMBER_CONTAINER_REF($id: ID!) {
    deleteMemberContainerRef(id: $id) {
      status
    }
  }
`

export const CREATE_MEMBER_CONTRACT_REF = gql`
  mutation CREATE_MEMBER_CONTRACT_REF($payload: MemberContractRefInput!) {
    createMemberContractRef(payload: $payload) {
      id
    }
  }
`

export const DELETE_MEMBER_CONTRACT_REF = gql`
  mutation DELETE_MEMBER_CONTRACT_REF($id: ID!) {
    deleteMemberContractRef(id: $id) {
      status
    }
  }
`
