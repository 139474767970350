import { gql } from '@apollo/client'

export const CREATE_CONTRACT = gql`
  mutation CREATE_CONTRACT($payload: ContractInput!) {
    createContract(payload: $payload) {
      id
    }
  }
`

export const UPDATE_CONTRACT = gql`
  mutation UPDATE_CONTRACT($payload: UpdateContractInput!) {
    updateContract(payload: $payload) {
      id
    }
  }
`

export const DELETE_CONTRACT = gql`
  mutation DELETE_CONTRACT($id: ID!) {
    deleteContract(id: $id) {
      status
    }
  }
`

export const WORKSPACE_CONTRACTS = gql`
  query WORKSPACE_CONTRACTS($payload: ContractsAggregateInput!) {
    workspaceContracts(payload: $payload) {
      total
      list {
        id
        title
        description
        workspaceId
        providerId
        contractNumber
        startDate
        endDate
        status
        customer {
          name
          number
        }
        payment {
          type
          method
          hasMandate
          frequency
          amount {
            value
            currency
          }
        }
        provider {
          id
          title
          description
          link
          category {
            id
            title
            titleDE
          }
        }
        cancelation {
          link
          notes
          date
        }
        createdAt
        updatedAt
        members {
          ... on WorkspaceMemberExtended {
            id
            refId
            userId
            email
            workspaceId
            firstName
            lastName
            avatarId
            avatar {
              avatar
            }
            createdAt
            updatedAt
          }

          ... on OrgMembershipExtended {
            id
            refId
            userId
            email
            organizationId
            createdAt
            updatedAt
            user {
              id
              firstName
              lastName
              avatar {
                avatar
              }
            }
          }
        }
      }
    }
  }
`

export const CONTRACT_BY_ID = gql`
  query CONTRACT_BY_ID($contractId: ID!) {
    contractById(contractId: $contractId) {
      id
      workspaceId
      title
      description
      createdAt
      updatedAt
      deletedAt
      providerId
      archiveIds
      contractNumber
      startDate
      endDate
      status
      cancelation {
        link
        notes
        date
      }
      renewal {
        autoRenewal
        frequency
        noticePeriod
      }
      customer {
        name
        number
      }
      account {
        email
        username
        passwordHint
      }
      archive {
        id
        type
        path
        createdAt
        updatedAt
        media {
          thumbnail
          medium
          full
        }
      }
      nests {
        id
        title
        description
        itemCount
        containerCount
        archive {
          id
          type
          title
          documentType
          path
          createdAt
          updatedAt
          media {
            thumbnail
            medium
            full
          }
        }
      }
      items {
        id
        title
        description
        archive {
          id
          type
          title
          documentType
          path
          createdAt
          updatedAt
          media {
            thumbnail
            medium
            full
          }
        }
      }
      provider {
        id
        title
        description
        link
        deletedAt
        category {
          id
          title
          titleDE
        }
      }
      payment {
        type
        method
        hasMandate
        frequency
        amount {
          value
          currency
        }
      }
      members {
        ... on WorkspaceMemberExtended {
          id
          refId
          userId
          email
          workspaceId
          firstName
          lastName
          avatarId
          avatar {
            avatar
          }
          createdAt
          updatedAt
        }

        ... on OrgMembershipExtended {
          id
          refId
          userId
          email
          organizationId
          createdAt
          updatedAt
          user {
            id
            firstName
            lastName
            avatar {
              avatar
            }
          }
        }
      }
      events {
        id
        itemId
        providerId
        contractId
        workspaceId
        date
        description
        title
        archiveIds
        createdAt
        updatedAt
        contract {
          id
          title
          description
          contractNumber
          deletedAt
        }
        providerData {
          id
          title
          description
          link
          deletedAt
        }
        amount {
          value
          currency
        }
        archive {
          id
          type
          title
          documentType
          path
          media {
            thumbnail
            medium
            full
          }
        }
      }
    }
  }
`

export const UPDATE_CONTRACT_ITEM_REFERENCE = gql`
  mutation UPDATE_CONTRACT_ITEM_REFERENCE(
    $payload: UpdateContractReferencesInput!
  ) {
    updateContractItemReferences(payload: $payload) {
      status
    }
  }
`

export const UPDATE_CONTRACT_NEST_REFERENCE = gql`
  mutation UPDATE_CONTRACT_NEST_REFERENCE(
    $payload: UpdateContractNestReferencesInput!
  ) {
    updateContractNestReferences(payload: $payload) {
      status
    }
  }
`
