import React, { useState } from 'react'
import { styled, useTheme } from '@mui/material/styles'
import { PageSubTitle } from 'components/pages'
import image from 'assets/images/maintenance.svg'
import { H1 } from 'components/typography'
import { isValidText } from 'utils/validators'
import { FormDataType, InputType, Type } from 'components/input/types'
import InputRender, { formHasErrors } from 'components/input/InputRender'
import { localStorageKeys, save } from 'lib/localStorage'

export const inputs: InputType[] = [
  {
    key: 'password',
    label: 'label.password',
    type: Type.PASSWORD,
    autoComplete: 'current-password',
    autoFocus: false,
    fullWidth: true,
    margin: 'normal',
    validators: [
      {
        method: isValidText,
        label: 'label.required',
      },
    ],
  },
]

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  minHeight: '100vh',
  justifyContent: 'center',
  alignItems: 'center',
}))
const Content = styled('div')(({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  maxWidth: '600px',
}))

const Img = styled('img')(({ theme }) => ({
  height: '100px',
  objectFit: 'contain',
  marginBottom: '30px',
}))

function AppLock() {
  const theme = useTheme()
  const [data, setData] = useState<FormDataType>({ password: '' })
  const [showErrors, setShowErrors] = useState(false)

  const textStyle = {
    textAlign: 'center',
    color: theme.palette.text.primary,
  }

  const handleOnsubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (formHasErrors(inputs, data)) {
      setShowErrors(true)
      return
    }
    const lockPass =
      '$2y$10$rFNsIifO2MkrbI2H9nj90.SAhZNqMxEzmMjAK8Z7GHrHgLLF7rL0C'

    const { password } = data ?? {}

    if (password === lockPass) {
      const now = new Date()
      now.setHours(now.getHours() + 4)
      save(localStorageKeys.lock, { expiration: now.getTime() })
      window.location.reload()
    }
  }

  return (
    <Root>
      <Content>
        <Img src={image} />
        <H1 sx={textStyle}>Product under development</H1>
        <PageSubTitle sx={textStyle}>Security pass</PageSubTitle>
        <form
          onSubmit={handleOnsubmit}
          style={{ flex: 1, display: 'flex', width: '100%' }}
        >
          {inputs.map((input) => {
            return (
              <InputRender
                key={input.key}
                data={data}
                input={input}
                inputs={inputs}
                setData={setData}
                showErrors={showErrors}
              />
            )
          })}
        </form>
      </Content>
    </Root>
  )
}
export default AppLock
