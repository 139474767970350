import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import {
  clearSession, // getAuth
} from 'lib/localStorage'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useIntl } from 'react-intl'
// import Badge from '@mui/material/Badge'
// import NotificationsIcon from '@mui/icons-material/NotificationsNoneOutlined'
// import CalendarIcon from '@mui/icons-material/EventNoteRounded'
// import ChatIcon from '@mui/icons-material/ChatBubbleOutline'
import FaqIcon from '@mui/icons-material/LiveHelpOutlined'
import MoreIcon from '@mui/icons-material/MoreVertSharp'
import { Conversation } from 'types/communication'
import Divider from '@mui/material/Divider'
import Tooltip from '@mui/material/Tooltip'
// import { getUnreadMessagesCount } from 'utils/communication'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import Settings from '@mui/icons-material/Settings'
import Logout from '@mui/icons-material/Logout'
import { getActiveWorkspaceId, saveActiveWorkspaceId } from 'lib/sessionStorage'
import { Link, useNavigate } from 'react-router-dom'
import appConfig from 'config/app'
import ROUTES from 'lib/routes'
import { useApolloClient, useLazyQuery, useQuery } from '@apollo/client'
import { H6 } from 'components/typography'
import { ProfileData } from 'types/profile'
import { PROFILE } from 'gql/user'
import { Avatar } from 'components/user'
import { ORGANIZATION_DATA } from 'gql/organization'
import { OrganizationDataQuery } from 'types/organization'
import { Button, useTheme } from '@mui/material'
import InsightsIcon from '@mui/icons-material/Insights'
import AccountEntry from './AccountEntry'
import LanguageSelector from 'components/LanguageSelector'
import { hasActiveSubscription } from 'utils/subscription'

interface Props {
  conversations: Conversation[] | null
  // onIconClick?: () => void
  // onChatClick?: () => void
}

function ActionBar({ conversations }: Props) {
  const intl = useIntl()
  const theme = useTheme()
  // const auth = getAuth()
  const navigate = useNavigate()
  const workspaceId = getActiveWorkspaceId()
  const client = useApolloClient()
  const isBigScreen = useMediaQuery(theme.breakpoints.up('sm'))
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClose = () => setAnchorEl(null)
  const handleClick = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget)
  const onProfileClick = () =>
    navigate(`${ROUTES.DASHBOARD}${ROUTES.DASHBOARD_PROFILE}`)
  const onPrefClick = () =>
    navigate(`${ROUTES.DASHBOARD}${ROUTES.DASHBOARD_ACCOUNT_PREFERENCES}`)
  // const unreadCount =
  //   conversations && auth?.userId
  //     ? getUnreadMessagesCount(conversations, auth.userId)
  //     : 0

  const [fetchOrganizationData, { data: orgData }] =
    useLazyQuery<OrganizationDataQuery>(ORGANIZATION_DATA, {
      variables: { workspaceId },
    })

  useEffect(() => {
    workspaceId && fetchOrganizationData()
  }, [workspaceId])

  const { data } = useQuery<ProfileData>(PROFILE)
  const profile = data?.profile
  const organization = orgData?.organizationData
  const avatar = profile?.avatar?.avatar
    ? `${appConfig.media}${profile?.avatar?.avatar}`
    : ''
  const handleSignOut = () => {
    clearSession()
    saveActiveWorkspaceId(null)
    client.clearStore()
    navigate(ROUTES.INDEX, { replace: true })
  }

  const placeholder = `${profile?.firstName.substring(0, 1) ?? ''}${
    profile?.lastName?.substring(0, 1) ?? ''
  }`
  return (
    <Box sx={{ display: 'flex' }}>
      {/* <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
        <Tooltip title={intl.formatMessage({ id: 'label.calendar' })}>
          <IconButton size="large" edge="end" onClick={onIconClick}>
            <Badge color="error">
              <CalendarIcon />
            </Badge>
          </IconButton>
        </Tooltip>
        <Tooltip title={intl.formatMessage({ id: 'label.messages' })}>
          <IconButton size="large" edge="end" onClick={onChatClick}>
            <Badge badgeContent={unreadCount} color="error">
              <ChatIcon />
            </Badge>
          </IconButton>
        </Tooltip>
        <Tooltip title={intl.formatMessage({ id: 'label.notifications' })}>
          <IconButton size="large" onClick={onIconClick}>
            <NotificationsIcon />
          </IconButton>
        </Tooltip>
      </Box> */}
      <LanguageSelector />

      {/* Show only pricing if the user has not purchased a plan or there are no active plans */}
      {!hasActiveSubscription(organization) && (
        <Button
          sx={isBigScreen ? { marginRight: '20px' } : {}}
          component={Link}
          to={`${ROUTES.DASHBOARD}${ROUTES.PRICING_ROUTE()}`}
        >
          <InsightsIcon
            color="info"
            fontSize="small"
            sx={isBigScreen ? { marginRight: '4px' } : {}}
          />
          {isBigScreen && (
            <H6> {intl.formatMessage({ id: 'label.pricing' })}</H6>
          )}
        </Button>
      )}

      <Box sx={{ display: 'flex' }}>
        {/* <Divider
          orientation="vertical"
          flexItem
          style={{
            backgroundColor: theme.palette.shared.lightGray,
          }}
        /> */}
        <Avatar
          onClick={onProfileClick}
          tooltip={intl.formatMessage({ id: 'label.profile' })}
          src={avatar}
          placeholder={placeholder}
          alt={`${profile?.firstName ?? ''} ${profile?.lastName ?? ''}`}
        />
      </Box>

      <Tooltip title={intl.formatMessage({ id: 'label.settings' })}>
        <IconButton
          size="large"
          onClick={handleClick}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <MoreIcon />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 4px 14px #00000026)',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={onProfileClick}>
          <AccountEntry />
        </MenuItem>

        {organization?.id && (
          <MenuItem onClick={onPrefClick}>
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            <H6>{intl.formatMessage({ id: 'label.accountCenter' })}</H6>
          </MenuItem>
        )}
        <Divider />
        {/* Hide pricing on the menu if there is an active subscription. */}
        {hasActiveSubscription(organization) && (
          <MenuItem
            onClick={() =>
              navigate(`${ROUTES.DASHBOARD}${ROUTES.PRICING_ROUTE()}`)
            }
          >
            <ListItemIcon>
              <InsightsIcon  fontSize="small" />
            </ListItemIcon>
            <H6>{intl.formatMessage({ id: 'label.pricing' })}</H6>
          </MenuItem>
        )}
        <MenuItem onClick={() => navigate(ROUTES.FAQ)}>
          <ListItemIcon>
            <FaqIcon fontSize="small" />
          </ListItemIcon>
          <H6>{intl.formatMessage({ id: 'label.faq' })}</H6>
        </MenuItem>
        <MenuItem onClick={handleSignOut}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          <H6>{intl.formatMessage({ id: 'label.logout' })}</H6>
        </MenuItem>
      </Menu>
    </Box>
  )
}
ActionBar.defaultProps = {
  // onIconClick: null,
  // onChatClick: null,
}
export default ActionBar
