import * as React from 'react'
import MuiAppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import { styled } from '@mui/material/styles'
import { useTheme } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import { useIntl } from 'react-intl'
import useMediaQuery from '@mui/material/useMediaQuery'
import ROUTES from 'lib/routes'
import Logo from 'assets/images/logo.svg'
import { Workspace } from 'types/workspace'
import { getActiveWorkspaceId, saveActiveWorkspaceId } from 'lib/sessionStorage'
import { drawerWidth } from 'components/drawer/constants'
import { Conversation } from 'types/communication'
import {
  Drawer,
  DrawerHeader,
  LogoImg,
  ListContainer,
  HeaderSeparator,
} from './fragments'
import DrawerContent from './DrawerContent'
import ActionBar from './ActionBar'
import { logoFull } from 'assets/images'

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  elevation: 0,
  borderBottom: `1px solid ${theme.palette.action.active}33`,
  zIndex: theme.zIndex.drawer + 1,
  boxShadow: '0px 1px 8px 0px #00000014',
  [theme.breakpoints.up('md')]: {
    paddingLeft: `${drawerWidth}px`,
  },
}))

const DrawerAppBar = styled(MuiAppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  boxShadow: 'none',
  width: `${drawerWidth}px`,
  left: 0,
}))

interface Props {
  workspaces: Workspace[] | null
  conversations: Conversation[] | null
  // onIconClick?: () => void
  // onChatClick?: () => void
}

function AppDrawer(props: Props) {
  const { workspaces, conversations } = props
  const workspaceId = getActiveWorkspaceId()
  const intl = useIntl()
  const theme = useTheme()
  const navigate = useNavigate()
  // const location = useLocation()

  const onLogoClick = () => {
    saveActiveWorkspaceId(null)
    navigate(ROUTES.DASHBOARD, { replace: true })
  }

  // open only on large screen
  const fullSize = useMediaQuery(theme.breakpoints.up('sm'))
  const hasSelectedWorkspace = !!workspaceId
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed">
        <Toolbar>
          <HeaderSeparator />
          <DrawerAppBar position="fixed">
            <DrawerHeader>
              <Button
                onClick={onLogoClick}
                // component={Link}
                // to={`${ROUTES.DASHBOARD}/${workspaceId ?? ''}`}
              >
                <LogoImg
                  src={fullSize ? logoFull : Logo}
                  alt={intl.formatMessage({ id: 'alt.appLogo' })}
                />
              </Button>
            </DrawerHeader>
          </DrawerAppBar>
          <Box sx={{ flexGrow: 1 }} />
          <ActionBar
            conversations={conversations}
            // onIconClick={onIconClick}
            // onChatClick={onChatClick}
          />
        </Toolbar>
      </AppBar>
      {hasSelectedWorkspace && (
        <Drawer
          variant="permanent"
          anchor="left"
          open={fullSize}
          hasSelectedWorkspace={hasSelectedWorkspace} // used on workspace selection screen
          color={theme.palette.background.paper}
        >
          {workspaceId && (
            <ListContainer>
              <DrawerContent workspaces={workspaces} isMobile={false} />
            </ListContainer>
          )}
        </Drawer>
      )}
    </Box>
  )
}

AppDrawer.defaultProps = {
  // onIconClick: null,
  // onChatClick: null,
}

export default AppDrawer
