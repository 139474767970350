import { TextField } from '@mui/material'
import { Theme, styled } from '@mui/material/styles'
import IconButtonMui from '@mui/material/IconButton'
import { H6 } from 'components/typography'
import ClearIcon from '@mui/icons-material/Clear'

export const AutocompleteTextField = styled(TextField)(({ theme }) => ({
  minWidth: '180px',
  marginLeft: '10px',
}))

export const OptionWrapper = styled('li')(({ theme }) => ({}))

export const OptionLabel = styled(H6)(({ theme }) => ({
  marginLeft: '6px',
}))

export const IconButton = styled(IconButtonMui, {
  shouldForwardProp: (prop) => prop !== 'disableSpacing',
})<{
  theme?: Theme
  disableSpacing: boolean
}>(({ theme, disableSpacing }) => ({
  ...(disableSpacing ? {} : { marginLeft: '10px' }),
}))

export const BadgeWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  ':hover button': {
    display: 'flex',
  },
}))

export const RemoveButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: 2,
  top: 2,
  width: '12px',
  height: '12px',
  backgroundColor: theme.palette.error.light,
  display: 'none',
}))

export const RemoveIcon = styled(ClearIcon)(({ theme }) => ({
  width: '12px',
  height: '12px',
}))
