import React, { useEffect, useState } from 'react'
import { SectionTitleWrapper } from 'components/pages'
import { useTheme } from '@mui/material/styles'
import { useIntl } from 'react-intl'
import { ItemExtended } from 'types/item'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import CheckMarkIcon from '@mui/icons-material/Check'
import RemoveIcon from '@mui/icons-material/RemoveCircleOutline'
import Pencil from 'assets/svg/Pencil'
import { Content } from 'components/shared'
import { BodyTextLight, H2, H5 } from 'components/typography'
import { FormDataType } from 'components/input/types'
import { Severity } from 'components/providers/SnackbarHOC'
import InputRender, { formHasErrors } from 'components/input/InputRender'
import Grid from '@mui/material/Grid'
import { omit } from 'lodash'
import getDefaultUnits, {
  hasExtraInfo,
  extraInputs,
  payloadBuilder,
  useMutations,
  getData,
  deleteFieldPayload,
  getInitialState,
} from './extraInfo'

interface Props {
  record?: ItemExtended
  showSnackbar?: (message: string, severity: Severity) => void
}
function ExtraInfoSection({ record, showSnackbar }: Props) {
  const intl = useIntl()
  const theme = useTheme()
  const [addingField, setAddingField] = useState(false)
  const [editingState, setEditingState] = useState<{
    [key: string]: string | Date
  }>()
  const { extras } = record ?? {}
  const hasData = extras ? hasExtraInfo(extras) : false
  const extraData = getData(intl, extras)
  const toggleAddingField = () => setAddingField(!addingField)
  const {
    mutations: { createItemExtras, updateItemExtras },
  } = useMutations(record?.id ?? '', { showSnackbar })

  const handleSubmitForm = (data: FormDataType) => {
    if (extras?.id) {
      // update record
      const payloadData = payloadBuilder(data, extras)
      const payload = {
        id: extras.id,
        data: payloadData,
      }
      updateItemExtras({ variables: { payload } })
    } else {
      const payload = payloadBuilder(data, extras)
      // new record
      if (record?.id)
        createItemExtras({
          variables: { payload: { ...payload, itemId: record?.id } },
        })
    }
    toggleAddingField()
  }

  return (
    <React.Fragment>
      <SectionTitleWrapper>
        <H2>{intl.formatMessage({ id: 'label.extraInfo' })}</H2>
        <IconButton onClick={toggleAddingField}>
          <AddIcon color="disabled" />
        </IconButton>
      </SectionTitleWrapper>
      <Content>
        {!hasData && !addingField && (
          <BodyTextLight color={theme.palette.text.secondary}>
            {intl.formatMessage({ id: 'label.noData' })}
          </BodyTextLight>
        )}
        {extraData?.map((item) => {
          const handleOnDeleteField = () => {
            if (!extras?.id) return
            const payloadData = deleteFieldPayload(item.key, extras)
            const payload = {
              id: extras.id,
              data: payloadData,
            }
            updateItemExtras({ variables: { payload } })
          }

          const handleOnUpdate = () => {
            setAddingField(true)
            setTimeout(() => {
              setEditingState(getInitialState(item.key, item.originalValue))
            }, 10)
          }
          return (
            <Grid container key={`extra-info-entry-${item.key}`}>
              <Grid item xs={4}>
                <H5>{item.label}</H5>
              </Grid>
              <Grid item xs={5}>
                <BodyTextLight>{item.value}</BodyTextLight>
              </Grid>
              <Grid item xs={3}>
                <IconButton onClick={handleOnUpdate}>
                  <Pencil size="20px" />
                </IconButton>
                <IconButton onClick={handleOnDeleteField}>
                  <RemoveIcon fontSize="small" color="error" />
                </IconButton>
              </Grid>
            </Grid>
          )
        })}
        <NewPropertyForm
          visible={addingField}
          onSubmitForm={handleSubmitForm}
          initialState={editingState}
        />
      </Content>
    </React.Fragment>
  )
}

ExtraInfoSection.defaultProps = {
  record: null,
  showSnackbar: undefined,
}

interface NewPropertyFormProps {
  visible: boolean
  onSubmitForm: (data: FormDataType) => void
  initialState?: FormDataType
}
function NewPropertyForm({
  visible,
  onSubmitForm,
  initialState,
}: NewPropertyFormProps) {
  const baseState = {
    fieldKey: '',
    fieldValue: '',
    ...getDefaultUnits(),
  }

  const intl = useIntl()
  const [data, setData] = useState<FormDataType>(initialState || baseState)
  const [showErrors, setShowErrors] = useState(false)
  const inputs = extraInputs(intl, { key: data.fieldKey as string })

  useEffect(
    () =>
      setData(
        {
          ...initialState, // there is an initial state, reset all the units except the one coming from initial state
          ...omit(
            getDefaultUnits(),
            Object.keys(initialState ?? {}).filter(
              (key) => key !== 'fieldKey' && key !== 'fieldValue'
            )
          ),
        } ?? baseState
      ),
    [initialState, visible]
  )

  const handleOnsubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (formHasErrors(inputs, data)) {
      setShowErrors(true)
      return
    }

    onSubmitForm(data)
  }

  useEffect(() => {
    // reset the state of toggle visibility
    setData(baseState)
    setShowErrors(false)
  }, [visible])

  if (!visible) return null
  return (
    <form noValidate onSubmit={handleOnsubmit}>
      <Grid container spacing={1} alignItems="end">
        <Grid item sm={5} xs={12}>
          <InputRender
            key={inputs[0].key}
            data={data}
            input={inputs[0]}
            inputs={inputs}
            setData={setData}
            showErrors={showErrors}
          />
        </Grid>
        <Grid item sm={5} xs={12}>
          <InputRender
            key={inputs[1].key}
            data={data}
            input={inputs[1]}
            inputs={inputs}
            setData={setData}
            showErrors={showErrors}
          />
        </Grid>
        <Grid item sm={2} xs={12}>
          <React.Fragment>
            <IconButton type="submit" size="large">
              <CheckMarkIcon color="primary" fontSize="large" />
            </IconButton>
          </React.Fragment>
        </Grid>
      </Grid>
      <input type="submit" hidden />
    </form>
  )
}

NewPropertyForm.defaultProps = {
  initialState: null,
}

export default React.memo(ExtraInfoSection)
