import { FileType } from 'types/archive'

export type FormDataValue =
  | string
  | string[]
  | boolean
  | Date
  | null
  | undefined

export interface Suggestion {
  // dependentField?: string
  value?: Date
  // addedValue?: number
  label: string
}

export enum Type {
  TEXT = 'text', // default
  SINGLE_SELECT = 'single-select',
  MULTI_SELECT = 'multi-select',
  CHECKBOX = 'checkbox',
  DATE = 'date',
  TIME = 'time',
  // must have a default unit value
  UNIT_SELECT = 'unit-select',
  // must have a default unit value
  PRICE = 'price',
  NUMBER = 'number',
  AUTOCOMPLETE = 'autocomplete',
  DROPZONE = 'dropzone',
  EMAIL = 'email',
  PASSWORD = 'password',
  COUNTRY = 'country',
}

export interface Option<ValueType = string> {
  value: ValueType
  label: string
  disabled?: boolean
}

type ValidatorFunctionType = <ValueType>(
  value?: ValueType,
  formData?: FormDataType,
  options?: Option<ValueType>[]
) => boolean

interface Validator {
  method: ValidatorFunctionType
  label: string
}

export interface InputType {
  margin: 'dense' | 'normal' | 'none'
  key: string
  unitKey?: string
  label: string
  infoLabel?: string // used to show additional input information text, under the input
  type: Type // input type of text fields, or custom input types like single-select, multi-select, etc.
  autoComplete?: string
  autoFocus?: boolean
  validators?: Validator[]
  unitValidators?: Validator[]
  fullWidth: boolean
  multiline?: boolean
  options?: Option[]
  suggestions?: Suggestion[] // prefill suggestions
  useTranslation?: boolean // use translation file for label
  fileType?: FileType // @default images
  disableFuture?: boolean // date input
  disablePast?: boolean // date input
  maxDate?: Date // date input
  minDate?: Date // date input
  noOptionsText?: string
  freeSolo?: boolean
  action?: { label: string; onClick: () => void }
  visible?: (formData: FormDataType) => boolean
  disabled?: boolean // disabled input field
}

export interface FormDataType {
  [key: string]: FormDataValue
}

export function isString(x: undefined | FormDataValue): x is string {
  return typeof x === 'string'
}

export function isDate(x: undefined | FormDataValue): x is Date {
  return x instanceof Date
}

export const isOption = (option: Option | string): option is Option =>
  Object.keys(option).includes('value')
