import React from 'react'
import { useIntl } from 'react-intl'
import { darken, styled } from '@mui/material/styles'
import InputBase from '@mui/material/InputBase'
import SearchIcon from '@mui/icons-material/Search'
import IconButton from '@mui/material/IconButton'

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: 'transparent',
  display: 'flex',
  flex: 1,
  justifyContent: 'flex-start',
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  '& .MuiInputBase-input': {
    backgroundColor: theme.palette.shared.bgGray,
    '&:hover': {
      backgroundColor: darken(theme.palette.shared.bgGray, 0.04),
    },
    '&::placeholder': {
      fontSize: '14px',
      lineHeight: '145%',
      fontFamily: 'Open Sans',
      color: theme.palette.text.primary,
      fontWeight: 400,
    },
    overflow: 'hidden',
    padding: theme.spacing(1, 0, 1, 5),
    border: `1px solid ${theme.palette.shared.bgGray}`,
    paddingRight: `calc(1em + ${theme.spacing(1)})`,
    borderRadius: '104px',
  },
  '&	.MuiInputBase-hiddenLabel': {
    fontSize: '12px',
  },
}))

const SearchIconButton = styled(IconButton)(({ theme }) => ({
  // padding: theme.spacing(0, 2),
  position: 'absolute',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  // border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: '104px',
  // backgroundColor: theme.palette.background.paper,
  height: '100%',
  color: theme.palette.text.secondary,
}))

interface Props {
  onTextChange?: (value: string) => void
  onSubmit?: (value: string) => void
  visible?: boolean
  autoFocus?: boolean
}
function SearchInput({ onTextChange, onSubmit, visible, autoFocus }: Props) {
  const intl = useIntl()
  const [value, setValue] = React.useState('')
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
    onTextChange?.(event.target.value)
  }
  const handleSubmit = () => onSubmit?.(value)
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') handleSubmit()
  }
  if (!visible) return null

  return (
    <Search>
      <StyledInputBase
        placeholder={`${intl.formatMessage({ id: 'label.search' })}...`}
        inputProps={{ 'aria-label': 'search' }}
        onKeyPress={handleKeyPress}
        onChange={handleChange}
        value={value}
        autoFocus={autoFocus}
      />
      <SearchIconButton onClick={handleSubmit}>
        <SearchIcon />
      </SearchIconButton>
    </Search>
  )
}

SearchInput.defaultProps = {
  onTextChange: null,
  onSubmit: null,
  visible: true,
  autoFocus: false,
}

export default SearchInput
