import React, { useState, useCallback } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import LoadingIndicator from 'components/LoadingIndicator'
import ItemSelectDrawer from 'components/selectorDrawers/ItemSelectDrawer'
import { useTheme } from '@mui/material/styles'
import FormModal from 'components/recordDetails/FormModal'
import { SuggestionSection } from 'components/recordDetails/SuggestionsSection'
import { ItemCreateDrawer } from 'components/selectorDrawers'
import { Container, Content } from 'components/shared'
import { useMediaQuery } from '@mui/material'
import SidebarCard from 'components/recordDetails/SidebarCard'
import Tabs from 'components/Tabs'
import {
  ConfirmationData,
  withConfirmationModal,
} from 'components/hoc/ConfirmationModalHoc'
import { Severity, withSnackbar } from 'components/providers/SnackbarHOC'
import { useSubscriptionStatus } from 'hooks/graphql/workspaces'
import { FileEntry, JsonObject } from 'types/shared'
import { FileType } from 'types/archive'
import ROUTES from 'lib/routes'
import {
  PageGrid,
  PageGridLeft,
  PageGridRight,
  PageHeader,
  StickySection,
} from 'components/pages'
import { uploadMedia } from 'lib/api'
import { GroupDetailsHeadline } from './fragments'
import {
  getInitialState,
  getEditInputs,
  getSectionWithId,
  getExtraInputs,
  payloadBuilder,
  getDetailsChips,
} from './constants'
import { useData, useMutations } from './hooks'
import contentTabs from './tabs'

interface Props {
  showSnackbar?: (message: string, severity: Severity) => void
  requestConfirmation?: (data: ConfirmationData) => void
}
function GroupDetails({ showSnackbar, requestConfirmation }: Props) {
  const intl = useIntl()
  const theme = useTheme()
  const navigate = useNavigate()
  const params = useParams() as { workspaceId: string; groupId: string }
  const isBigScreen = useMediaQuery(theme.breakpoints.up('lg'))
  const { active } = useSubscriptionStatus(params.workspaceId)
  const [showItemSelectDrawer, setShowItemSelectDrawer] = useState(false)
  const [showItemCreateDrawer, setShowItemCreateDrawer] = useState(false)
  const [editing, setEditing] = useState<SuggestionSection>()
  // optional id of the sub-record to edit, example (saleInfoId)
  // const [editingId, setEditingId] = useState<string>()
  const hideEditingModal = useCallback(() => setEditing(undefined), [])
  const toggleItemSelect = () => setShowItemSelectDrawer(!showItemSelectDrawer)
  const toggleItemCreate = () => setShowItemCreateDrawer(!showItemCreateDrawer)
  const toggleDelete = () =>
    requestConfirmation?.({
      title: intl.formatMessage({ id: 'label.deletingGroup' }),
      description: intl.formatMessage({
        id: 'label.deletingGroupConfirmation',
      }),
      actions: [
        {
          label: intl.formatMessage({ id: 'label.cancel' }),
        },
        {
          label: intl.formatMessage({ id: 'label.delete' }),
          color: 'error',
          onClick: handleDelete,
        },
      ],
    })

  const {
    loading,
    data: { record, groupItems },
    refetch: { refetchItems },
  } = useData(params.groupId, { showSnackbar })

  const {
    loading: mutating,
    mutations: { updateGroupItemRefs, updateGroup, deleteGroups },
  } = useMutations(
    { ...params, navigate },
    {
      showSnackbar,
      hideEditingModal,
      toggleItemSelect,
    }
  )

  const handleRecordUpdate = (formData: JsonObject, sectionId?: string) => {
    const payload = payloadBuilder(formData, params.groupId, sectionId)
    updateGroup({ variables: { payload } })
  }
  const handleDelete = () =>
    deleteGroups({ variables: { groupIds: [record?.id ?? ''] } })

  const handleOnEditWithId = (sectionId: string, recordId?: string) => {
    const section = getSectionWithId(sectionId, intl)
    // setEditingId(recordId)
    setEditing(section)
  }

  const handleOnItemSelect = (itemIds: string[]) => {
    const payload = { groupId: params.groupId, itemIds }
    updateGroupItemRefs({ variables: { payload } })
  }
  const isLoading = loading || mutating
  const inputs = getEditInputs(editing?.id ?? '')
  const extraInputs = getExtraInputs(editing?.id ?? '')
  const initialState =
    record && editing ? getInitialState(record, editing.id) : {}

  const uploadCover = async (cover: FileEntry): Promise<string> => {
    const result = await uploadMedia(
      cover.file,
      { workspaceId: params.workspaceId, type: FileType.image },
      true
    )
      .then((response) => response.json())
      .catch((error) => showSnackbar?.(error.message, Severity.ERROR))

    // eslint-disable-next-line no-underscore-dangle
    const archiveId = result?.data?._id
    return archiveId
  }

  const handleOnUpdate = (
    formData: JsonObject,
    sectionId?: string,
    files?: FileEntry[]
  ) => {
    const dropzoneKey =
      inputs.find((input) => input.type === 'dropzone')?.key ?? ''
    const cover = files?.[0]
    if (cover) {
      uploadCover(cover).then((coverId) =>
        handleRecordUpdate({ ...formData, [dropzoneKey]: coverId }, sectionId)
      )
    } else {
      handleRecordUpdate(formData, sectionId)
    }
  }

  const tabs = contentTabs(
    intl,
    { record, groupItems },
    {
      toggleDelete,
      showSnackbar,
      handleOnEditWithId,
      toggleItemSelect,
    }
  )
  const sidebar = (
    <SidebarCard
      title={intl.formatMessage({
        id: 'label.details',
      })}
      content={getDetailsChips(intl, { groupItems }, { toggleItemSelect })}
    />
  )

  return (
    <Container detailPage>
      <LoadingIndicator visible={isLoading} />
      <PageHeader
        pageTitle={intl.formatMessage({ id: 'label.group' })}
        recordTitle={record?.title ?? ''}
        breadcrumbLink={ROUTES.DASHBOARD_GROUPS_ROUTE(params.workspaceId)}
      />
      <Content detailPage>
        <PageGrid container spacing={6}>
          <PageGridLeft item xs={12} sm={12} md={12} lg={7} xl={8}>
            {record && (
              <GroupDetailsHeadline
                group={record}
                onEdit={handleOnEditWithId}
              />
            )}
            {!isBigScreen && sidebar}
            <Tabs tabs={tabs} locked={!active} />
          </PageGridLeft>

          {isBigScreen && (
            <PageGridRight item xs={12} sm={12} md={12} lg={5} xl={4}>
              <StickySection>{sidebar}</StickySection>
            </PageGridRight>
          )}
        </PageGrid>
      </Content>

      <ItemSelectDrawer
        open={showItemSelectDrawer}
        toggleDrawer={toggleItemSelect}
        groupId={params.groupId}
        preSelectedRecords={groupItems}
        onSubmit={handleOnItemSelect}
        actions={[
          {
            label: intl.formatMessage({ id: 'label.createNewItem' }),
            onClick: () => {
              toggleItemSelect()
              toggleItemCreate()
            },
          },
        ]}
      />
      <ItemCreateDrawer
        open={showItemCreateDrawer}
        toggleDrawer={toggleItemCreate}
        groupId={params.groupId}
        workspaceId={params.workspaceId}
        onComplete={() => {
          toggleItemCreate()
          refetchItems()
        }}
      />
      <FormModal
        toggleModal={hideEditingModal}
        inputs={inputs}
        extraInputs={extraInputs}
        section={editing}
        initialState={initialState}
        onSubmit={handleOnUpdate}
        singleFileSelect
      />
    </Container>
  )
}
GroupDetails.defaultProps = {
  showSnackbar: undefined,
  requestConfirmation: undefined,
}

export default withConfirmationModal(withSnackbar(GroupDetails))
