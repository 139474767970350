import { gql } from '@apollo/client'

export const LOGIN = gql`
  query LOGIN($payload: LoginInput!) {
    login(payload: $payload) {
      token
      userId
      expiresAt
    }
  }
`

export const REGISTER = gql`
  mutation REGISTER($payload: RegisterInput!) {
    register(payload: $payload) {
      token
      userId
      expiresAt
    }
  }
`

export const CONFIRM_EMAIL = gql`
  mutation CONFIRM_EMAIL($token: String!) {
    confirmEmail(token: $token) {
      status
    }
  }
`

export const DELETE_ACCOUNT = gql`
  mutation DELETE_ACCOUNT {
    deleteAccount {
      status
    }
  }
`
