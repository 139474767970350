import React, { useState, useCallback, useRef } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useTheme } from '@mui/material/styles'
import { Severity, withSnackbar } from 'components/providers/SnackbarHOC'
import { Container, Content } from 'components/shared'
import LoadingIndicator from 'components/LoadingIndicator'
import FormModal from 'components/recordDetails/FormModal'
import ItemSelectDrawer from 'components/selectorDrawers/ItemSelectDrawer'
import { ItemCreateDrawer } from 'components/selectorDrawers'
import { SuggestionSection } from 'components/recordDetails/SuggestionsSection'
import { useFileUpload } from 'hooks/api/fileUpload'
import ROUTES from 'lib/routes'
import Tabs from 'components/Tabs'
import NestSelectDrawer from 'components/selectorDrawers/NestSelectDrawer'
import SidebarCard from 'components/recordDetails/SidebarCard'
import { FileEntry, JsonObject, MemberModels } from 'types/shared'
import { useSubscriptionStatus } from 'hooks/graphql/workspaces'
import UploadHandler, {
  UploadHandlerMethods,
} from 'components/dropzone/UploadHandler'
import {
  ConfirmationData,
  withConfirmationModal,
} from 'components/hoc/ConfirmationModalHoc'
import {
  PageGrid,
  PageGridLeft,
  PageGridRight,
  PageHeader,
  StickySection,
} from 'components/pages'
import { FileType } from 'types/archive'
import {
  getDetailsChips,
  getEditInputs,
  getExtraInputs,
  getInitialState,
  getSectionWithId,
  payloadBuilder,
} from './constants'
import Headline from './Headline'
import { useData, useMutations } from './hooks'
import contentTabs from './tabs'
import { useMediaQuery } from '@mui/material'

interface Props {
  showSnackbar?: (message: string, severity: Severity) => void
  requestConfirmation?: (data: ConfirmationData) => void
}
function BoxDetails({ showSnackbar, requestConfirmation }: Props) {
  const intl = useIntl()
  const theme = useTheme()
  const navigate = useNavigate()
  const { uploadFiles } = useFileUpload()
  const params = useParams() as { workspaceId: string; containerId: string }
  const { active } = useSubscriptionStatus(params.workspaceId)
  const uploadHandlerRef = useRef<UploadHandlerMethods>(null)
  const [showItemSelectDrawer, setShowItemSelectDrawer] = useState(false)
  const [showItemCreateDrawer, setShowItemCreateDrawer] = useState(false)
  const [editing, setEditing] = useState<SuggestionSection>()
  // optional id of the sub-record to edit, example (saleInfoId)
  const [editingId, setEditingId] = useState<string>()
  const [showNestSelectDrawer, setShowNestSelectDrawer] = useState(false)
  const isBigScreen = useMediaQuery(theme.breakpoints.up('lg'))
  const hideEditingModal = useCallback(() => setEditing(undefined), [])
  const toggleNestSelect = () => setShowNestSelectDrawer(!showNestSelectDrawer)
  const toggleItemSelect = () => setShowItemSelectDrawer(!showItemSelectDrawer)
  const toggleItemCreate = () => setShowItemCreateDrawer(!showItemCreateDrawer)
  const toggleDelete = () =>
    requestConfirmation?.({
      title: intl.formatMessage({ id: 'label.deletingContainer' }),
      description: intl.formatMessage({
        id: 'label.deletingContainerConfirmation',
      }),
      actions: [
        {
          label: intl.formatMessage({ id: 'label.cancel' }),
        },
        {
          label: intl.formatMessage({ id: 'label.delete' }),
          color: 'error',
          onClick: handleDelete,
        },
      ],
    })

  const {
    loading,
    data: { record, members, owners },
    refetch: { refreshRecord },
  } = useData(params.containerId, params.workspaceId, { showSnackbar })

  const {
    loading: mutating,
    mutations: {
      updateBoxItemRefs,
      addFileToBox,
      updateContainer,
      deleteContainer,
      createMemberContainerRef,
      deleteMemberContainerRef,
    },
  } = useMutations(
    { ...params, navigate },
    { showSnackbar, hideEditingModal, toggleItemSelect }
  )

  const handleDelete = () =>
    record?.id && deleteContainer({ variables: { id: record.id } })

  const handleOnItemSelect = (itemIds: string[]) => {
    const payload = { containerId: params.containerId, itemIds }
    updateBoxItemRefs({ variables: { payload } })
  }

  const showMediaUploadModal = useCallback(() => {
    uploadHandlerRef.current?.openUploader(FileType.image)
  }, [])

  const handleOnEditWithId = (sectionId: string, recordId?: string) => {
    const section = getSectionWithId(sectionId, intl)
    setEditingId(recordId)
    setEditing(section)
  }

  const handleRecordUpdate = (formData: JsonObject, sectionId?: string) => {
    if (!record) return
    const payload = payloadBuilder(
      record,
      formData,
      params.containerId,
      sectionId
    )
    updateContainer({ variables: { payload } })
  }

  const handleOnUpdateNestRef = (nestId: string | null) => {
    handleRecordUpdate({ nestId })
    toggleNestSelect()
  }
  const handleOnUpdate = (formData: JsonObject, sectionId?: string) => {
    handleRecordUpdate(formData, sectionId)
  }
  const createMemberRef = (memberId: string, memberModel: MemberModels) => {
    const payload = {
      memberId,
      workspaceId: params.workspaceId,
      memberModel,
      containerId: params.containerId,
    }
    createMemberContainerRef({ variables: { payload } })
  }
  const deleteMemberRef = (refId: string) => {
    deleteMemberContainerRef({ variables: { id: refId } })
  }

  const onUpload = async (files: FileEntry[], fileType: FileType) => {
    await uploadFiles(files, fileType, params.workspaceId, {
      onCompleted: (archiveIds, hasErrors) => {
        archiveIds.forEach((archiveId) => {
          const payload = { containerId: params.containerId, archiveId }
          addFileToBox({ variables: { payload } })
        })
        if (!hasErrors) {
          uploadHandlerRef.current?.closeUploader()
          showSnackbar?.(
            intl.formatMessage({ id: 'label.uploadSuccessful' }),
            Severity.SUCCESS
          )
        }
      },
      onError: (errors) => {
        if (errors.length > 0) {
          showSnackbar?.(
            `File upload failed, (occurrences: ${errors.length}).
              ${errors[0]?.error?.message}
            `,
            Severity.ERROR
          )
        }
        uploadHandlerRef.current?.stopLoader()
      },
    })
  }

  const tabs = contentTabs(
    intl,
    { record },
    {
      toggleDelete,
      showSnackbar,
      handleOnEditWithId,
      toggleItemSelect,
    }
  )

  const isLoading = loading || mutating
  const inputs = record ? getEditInputs(editing?.id ?? '') : []
  const extraInputs = getExtraInputs(editing?.id ?? '')
  const initialState =
    record && editing ? getInitialState(record, editing.id, editingId) : {}

  const sidebar = (
    <SidebarCard
      title={intl.formatMessage({
        id: 'label.details',
      })}
      content={getDetailsChips(
        intl,
        theme,
        {
          record,
          members: [...owners, ...members],
        },
        {
          toggleItemSelect,
          toggleNestSelect,
          deleteMemberRef,
          createMemberRef,
        }
      )}
    />
  )
  return (
    <Container detailPage>
      <LoadingIndicator visible={isLoading} />
      <PageHeader
        pageTitle={intl.formatMessage({ id: 'label.container' })}
        recordTitle={record?.title ?? ''}
        breadcrumbLink={ROUTES.DASHBOARD_CONTAINERS_ROUTE(params.workspaceId)}
      />
      <Content detailPage>
        <PageGrid container spacing={6}>
          <PageGridLeft item xs={12} sm={12} md={12} lg={7} xl={8}>
            {record && (
              <Headline
                record={record}
                showMediaUploadModal={showMediaUploadModal}
                onEditSection={handleOnEditWithId}
              />
            )}
            {!isBigScreen && sidebar}
            <Tabs tabs={tabs} locked={!active} />
          </PageGridLeft>

          {isBigScreen && (
            <PageGridRight item xs={12} sm={12} md={12} lg={5} xl={4}>
              <StickySection>{sidebar}</StickySection>
            </PageGridRight>
          )}
        </PageGrid>
      </Content>
      <UploadHandler ref={uploadHandlerRef} onSubmit={onUpload} />
      <FormModal
        toggleModal={hideEditingModal}
        inputs={inputs}
        extraInputs={extraInputs}
        section={editing}
        initialState={initialState}
        onSubmit={handleOnUpdate}
      />
      <ItemSelectDrawer
        open={showItemSelectDrawer}
        toggleDrawer={toggleItemSelect}
        containerId={params.containerId}
        preSelectedRecords={record?.items ?? []}
        onSubmit={handleOnItemSelect}
        actions={[
          {
            label: intl.formatMessage({ id: 'label.createNewItem' }),
            onClick: () => {
              toggleItemSelect()
              toggleItemCreate()
            },
          },
        ]}
      />
      <NestSelectDrawer
        open={showNestSelectDrawer}
        toggleDrawer={toggleNestSelect}
        preSelectedRecord={record?.nest}
        onSelect={handleOnUpdateNestRef}
      />
      <ItemCreateDrawer
        open={showItemCreateDrawer}
        toggleDrawer={toggleItemCreate}
        containerId={params.containerId}
        workspaceId={params.workspaceId}
        onComplete={() => {
          toggleItemCreate()
          refreshRecord()
        }}
      />
    </Container>
  )
}

BoxDetails.defaultProps = {
  showSnackbar: undefined,
  requestConfirmation: undefined,
}
export default withConfirmationModal(withSnackbar(BoxDetails))
