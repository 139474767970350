import appConfig from 'config/app'
import { isOrganizationMember } from 'types/user'
import { MemberModels } from 'types/shared'
import { UserType } from './UserBadge'

function getFirstName(user: UserType) {
  if (isOrganizationMember(user)) return user?.user.firstName
  return user.firstName
}

function getLastName(user: UserType) {
  if (isOrganizationMember(user)) return user?.user.lastName
  return user.lastName
}

export function getFullName(user: UserType) {
  return `${getFirstName(user)} ${getLastName(user) ?? ''}`
}

export function getPlaceholder(user: UserType) {
  return `${getFirstName(user)?.substring(0, 1).toUpperCase() ?? ''}${
    getLastName(user)?.substring(0, 1).toUpperCase() ?? ''
  }`
}

export function getAvatar(user: UserType) {
  let serverPath = null
  if (isOrganizationMember(user)) serverPath = user.user.avatar?.avatar
  else serverPath = user?.avatar?.avatar
  const src = serverPath ? `${appConfig.media}${serverPath}` : undefined
  return src
}

export function getMemberModel(user: UserType): MemberModels {
  if (isOrganizationMember(user)) return MemberModels.ORGANIZATION_MEMBER
  return MemberModels.WORKSPACE_MEMBER
}

export function getMemberEmail(user: UserType) {
  if (isOrganizationMember(user)) return user.user.email
  return user.email
}
