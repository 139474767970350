import Inventory from 'pages/dashboard/inventory/Inventory'
import EmailConfirm from 'pages/authentication/confirmEmail/ConfirmEmail'
import Archive from 'pages/dashboard/archive/Archive'
import Landing from 'pages/landing/Landing'
import Login from 'pages/authentication/login/Login'
import Register from 'pages/authentication/register/Register'
import Dashboard from 'pages/dashboard/Dashboard'
import WorkspaceSettings from 'pages/dashboard/settings/WorkspaceSettings'
import WorkspaceMembers from 'pages/dashboard/members/list/Page'
import MemberDetails from 'pages/dashboard/members/show/Page'
import Profile from 'pages/profile/Profile'
// import WorkspaceOverview from 'pages/dashboard/overview/Overview'
import ItemDetails from 'pages/dashboard/items/show/Page'
import CreateItem from 'pages/dashboard/items/create/Page'
import Groups from 'pages/dashboard/groups/list/Page'
import CreateGroup from 'pages/dashboard/groups/create/Page'
import GroupDetails from 'pages/dashboard/groups/show/Page'
import Nests from 'pages/dashboard/nests/list/Page'
import CreateNest from 'pages/dashboard/nests/create/Page'
import NestDetails from 'pages/dashboard/nests/show/Page'
import Items from 'pages/dashboard/items/list/Page'
import Containers from 'pages/dashboard/containers/list/Page'
import ContainerDetails from 'pages/dashboard/containers/show/Page'
import CreateContainer from 'pages/dashboard/containers/create/Page'
import CreateWorkspace from 'pages/dashboard/createWorkspace/CreateWorkspace'
import WorkspaceServiceProvides from 'pages/dashboard/service-providers/list/Page'
import ServiceProviderDetails from 'pages/dashboard/service-providers/show/Page'
import CreateServiceProvider from 'pages/dashboard/service-providers/create/Page'
import AccountPreferences from 'pages/account-preferences/AccountPreferences'
import Pricing from 'pages/pricing/Page'
import PricingExtend from 'pages/pricingExtend/Page'
import Checkout from 'pages/checkout/Page'
import CheckoutUpgrade from 'pages/checkoutUpgrade/Page'
import DashboardLanding from 'pages/dashboard/landing/DashboardLanding'
import CreateItemScanner from 'pages/dashboard/items/create/CreateItemScanner'
import ServiceDetails from 'pages/dashboard/serviceDetails/ServiceDetails'
import Contracts from 'pages/dashboard/contracts/list/Page'
import CreateContractPage from 'pages/dashboard/contracts/create/Page'
import ContractDetails from 'pages/dashboard/contracts/show/Page'
import PasswordReset from 'pages/authentication/password-reset/Page'
import PasswordResetConfirmation from 'pages/authentication/password-reset-confirm/Page'
import ToS from 'pages/static/ToS'
import Imprint from 'pages/static/Imprint'
import Privacy from 'pages/static/Privacy'
import FAQ from 'pages/static/FAQ'
import AboutUs from 'pages/static/AboutUs'
import Withdrawal from 'pages/static/Withdrawal'
import ROUTES from './routes'

const staticRoutes = [
  // Static pages
  {
    route: ROUTES.TOS,
    component: ToS,
  },
  {
    route: ROUTES.WITHDRAWAL,
    component: Withdrawal,
  },
  {
    route: ROUTES.PRIVACY,
    component: Privacy,
  },
  {
    route: ROUTES.IMPRINT,
    component: Imprint,
  },
  {
    route: ROUTES.FAQ,
    component: FAQ,
  },
  {
    route: ROUTES.ABOUT_US,
    component: AboutUs,
  },
  {
    route: ROUTES.PRICING,
    component: Pricing,
  },
  {
    route: ROUTES.CHECKOUT,
    component: Checkout,
  },
]

export const MAIN_ROUTES = [
  {
    route: ROUTES.INDEX,
    component: Landing,
  },
  {
    route: ROUTES.LOGIN,
    component: Login,
  },
  {
    route: ROUTES.PASSWORD_RESET,
    component: PasswordReset,
  },
  {
    route: ROUTES.PASSWORD_RESET_CONFIRMATION,
    component: PasswordResetConfirmation,
  },
  {
    route: ROUTES.REGISTER,
    component: Register,
  },
  {
    route: `${ROUTES.DASHBOARD}/*`,
    component: Dashboard,
  },
  {
    route: ROUTES.CONFIRM_EMAIL,
    component: EmailConfirm,
  },

  ...staticRoutes,
]

const WORKSPACE_ROUTES = [
  {
    route: ROUTES.DASHBOARD_OVERVIEW,
    component: Inventory,
  },
  {
    route: ROUTES.WORKSPACE_INVITATION,
    component: Inventory, // workspace invitation route
  },
  {
    route: ROUTES.DASHBOARD_CREATE_SERVICE_PROVIDER,
    component: CreateServiceProvider,
  },
  {
    route: ROUTES.DASHBOARD_SETTINGS,
    component: WorkspaceSettings,
  },
  {
    route: ROUTES.DASHBOARD_CREATE_NEST,
    component: CreateNest,
  },
  {
    route: ROUTES.DASHBOARD_CREATE_ITEM,
    component: CreateItem,
  },
  {
    route: ROUTES.DASHBOARD_CREATE_ITEM_SCANNER,
    component: CreateItemScanner,
  },
  {
    route: ROUTES.DASHBOARD_CREATE_CONTAINER,
    component: CreateContainer,
  },
  {
    route: ROUTES.DASHBOARD_CREATE_GROUP,
    component: CreateGroup,
  },
  {
    route: ROUTES.DASHBOARD_CREATE_CONTRACT,
    component: CreateContractPage,
  },
  {
    route: ROUTES.DASHBOARD_CONTRACT_DETAILS,
    component: ContractDetails,
  },
  {
    route: ROUTES.DASHBOARD_ITEM_DETAILS,
    component: ItemDetails,
  },
  {
    route: ROUTES.DASHBOARD_CONTAINER_DETAILS,
    component: ContainerDetails,
  },
  {
    route: ROUTES.DASHBOARD_GROUP_DETAILS,
    component: GroupDetails,
  },
  {
    route: ROUTES.DASHBOARD_NEST_DETAILS,
    component: NestDetails,
  },
  {
    route: ROUTES.DASHBOARD_SERVICE_DETAILS,
    component: ServiceDetails,
  },
  {
    route: ROUTES.DASHBOARD_SERVICE_PROVIDER_DETAILS,
    component: ServiceProviderDetails,
  },
  // {
  //   route: ROUTES.DASHBOARD_INVENTORY,
  //   component: Inventory,
  // },
  {
    route: ROUTES.DASHBOARD_NESTS,
    component: Nests,
  },
  {
    route: ROUTES.DASHBOARD_CONTRACTS,
    component: Contracts,
  },
  {
    route: ROUTES.DASHBOARD_ITEMS,
    component: Items,
  },
  {
    route: ROUTES.DASHBOARD_CONTAINERS,
    component: Containers,
  },

  {
    route: ROUTES.DASHBOARD_GROUPS,
    component: Groups,
  },
  {
    route: ROUTES.DASHBOARD_MEMBERS,
    component: WorkspaceMembers,
  },
  {
    route: ROUTES.DASHBOARD_MEMBERS_DETAILS,
    component: MemberDetails,
  },
  {
    route: ROUTES.DASHBOARD_SERVICE_PROVIDERS,
    component: WorkspaceServiceProvides,
  },
  {
    route: ROUTES.DASHBOARD_ARCHIVE,
    component: Archive,
  },
]

// place all dashboard routes that start with /dashboard/ and don't have a param of workspaceId on top
export const DASHBOARD_ROUTES = [
  {
    route: ROUTES.INDEX, // dashboard index
    component: DashboardLanding,
  },

  {
    route: ROUTES.DASHBOARD_PROFILE,
    component: Profile,
  },
  {
    route: ROUTES.DASHBOARD_ACCOUNT_PREFERENCES,
    component: AccountPreferences,
  },
  {
    route: ROUTES.DASHBOARD_CREATE_WORKSPACE,
    component: CreateWorkspace,
  },

  {
    route: ROUTES.CHECKOUT_UPGRADE,
    component: CheckoutUpgrade,
  },

  {
    route: ROUTES.PRICING_EXTEND,
    component: PricingExtend,
  },

  ...staticRoutes,

  ...WORKSPACE_ROUTES,
]
