import { gql } from '@apollo/client'

export const WORKSPACE_ARCHIVE = gql`
  query WORKSPACE_ARCHIVE($payload: ArchiveFilesInput!) {
    workspaceArchiveFiles(payload: $payload) {
      total
      list {
        id
        path
        media {
          thumbnail
          medium
          full
          avatar
        }
        workspaceId
        type
        userId
        isPublic
        createdAt
        updatedAt
      }
    }
  }
`

export const ADD_FILE_TO_ITEM = gql`
  mutation ADD_FILE_TO_ITEM($payload: ArchiveItemInput!) {
    addFileToItem(payload: $payload) {
      status
    }
  }
`

export const ADD_FILE_TO_MEMBER = gql`
  mutation ADD_FILE_TO_MEMBER($payload: ArchiveMemberInput!) {
    addFileToMember(payload: $payload) {
      status
    }
  }
`

export const ADD_FILE_TO_NEST = gql`
  mutation ADD_FILE_TO_NEST($payload: ArchiveNestInput!) {
    addFileToNest(payload: $payload) {
      status
    }
  }
`

export const ADD_FILE_TO_BOX = gql`
  mutation ADD_FILE_TO_BOX($payload: ArchiveContainerInput!) {
    addFileToContainer(payload: $payload) {
      status
    }
  }
`

export const ADD_FILE_TO_SERVICE_PROVIDER = gql`
  mutation ADD_FILE_TO_SERVICE_PROVIDER(
    $payload: ArchiveServiceProviderInput!
  ) {
    addFileToServiceProvider(payload: $payload) {
      status
    }
  }
`
