import { ContentData } from 'components/recordDetails/ContentSection'
import { IntlShape } from 'react-intl'
import { getDate } from 'utils/datetime'
import { currencies } from 'lib/constants'
import { EventExtended } from 'types/event'
import { Contract } from 'types/contracts'
import ROUTES from 'lib/routes'
import { isGermanLocale } from './language'

export const getEventContent = (
  workspaceId: string,
  record: EventExtended,
  intl: IntlShape
) => {
  const currency =
    currencies?.find((i) => i.value === record?.amount?.currency)?.label || ''

  const data: ContentData[] = [
    {
      label: intl.formatMessage({ id: 'label.serviceProvider' }),
      value: record.providerData?.title ?? '',
      visible: !!record.providerData?.title,
      deletedAt: record.providerData?.deletedAt,
      to: record.providerData?.id
        ? ROUTES.DASHBOARD_SERVICE_PROVIDER_DETAILS_ROUTE(
            workspaceId,
            record.providerData?.id
          )
        : undefined,
    },
    {
      label: intl.formatMessage({ id: 'label.item' }),
      value: record.item?.title ?? '',
      visible: !!record.item?.title,
      deletedAt: record.item?.deletedAt,
    },
    {
      label: intl.formatMessage({ id: 'label.contract' }),
      value: record.contract?.title ?? '',
      visible: !!record.contract?.title,
      deletedAt: record.contract?.deletedAt,
    },
    {
      label: intl.formatMessage({ id: 'label.price' }),
      value: record.amount?.value ? `${record.amount?.value} ${currency}` : '-',
      visible: !!record.amount?.value,
    },
    // {
    //   label: intl.formatMessage({ id: 'label.eventType' }),
    //   value: record.type?.toString() ?? '-',
    // },
    {
      label: intl.formatMessage({ id: 'label.date' }),
      value: record.date ? getDate(record.date) : '-',
      visible: !!record.date,
    },
    {
      label: intl.formatMessage({ id: 'label.relatedDocument' }),
      value: (record?.archiveIds?.length || '-').toString(),
      visible: (record?.archiveIds?.length ?? 0) > 0,
    },
  ]

  // if (record?.serialNumbers && record?.serialNumbers.length > 0) {
  //   data.push({
  //     label: intl.formatMessage({ id: 'label.serialNumbers' }),
  //     value: record?.serialNumbers.join(', '),
  //   })
  // }

  return data
}

export const getContractContent = (record: Contract, intl: IntlShape) => {
  const data: ContentData[] = [
    {
      label: intl.formatMessage({ id: 'label.contractNumber' }),
      value: record.contractNumber ?? '',
    },
    {
      label: intl.formatMessage({ id: 'label.startDate' }),
      value: record.startDate ? getDate(record.startDate) : '-',
    },
    // {
    //   label: intl.formatMessage({ id: 'label.endDate' }),
    //   value: record.endDate ? getDate(record.endDate) : '-',
    // },
  ]
  return data
}

export function getLocaleTitle(
  intl: IntlShape,
  object: { title: string; titleDE?: string }
) {
  return isGermanLocale(intl.locale) && object.titleDE
    ? object.titleDE
    : object.title
}

export function getLocaleDescription(
  intl: IntlShape,
  object: { description?: string; descriptionDE?: string }
) {
  return (
    (isGermanLocale(intl.locale) ? object.descriptionDE : object.description) ??
    ''
  )
}
