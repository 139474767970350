import React, { useEffect } from 'react'
import { Container, Content, RowSpaced } from 'components/shared'
import { useIntl } from 'react-intl'
import ROUTES from 'lib/routes'
import { H1, H5 } from 'components/typography'
import MuiHeader from 'pages/landing/MuiHeader'
import { Toolbar, useMediaQuery, useTheme } from '@mui/material'
import { useLocation, useParams } from 'react-router-dom'
import { useLazyQuery, useQuery } from '@apollo/client'
import { Severity, withSnackbar } from 'components/providers/SnackbarHOC'
import { SUBSCRIPTION_BY_ID, SUBSCRIPTION_PLANS } from 'gql/subscription'
import {
  SubscriptionByIdData,
  SubscriptionByIdInput,
  SubscriptionPlanData,
  SubscriptionPlanInput,
} from 'types/subscription'
import LoadingIndicator from 'components/LoadingIndicator'
import { TabComp, TabContainer, TabList, TabWrapper } from 'components/Tabs'
import { TabContext, TabPanel } from '@mui/lab'
import { getDurationLabel, getGroupedPlans, getUpgradeMap } from './constants'
import SubscriptionGrid from './SubscriptionGrid'

interface Props {
  showSnackbar?: (message: string, severity: Severity) => void
}
// TODO: change later when other countries are introduced
const countryCode = 'DE'
function Subscription({ showSnackbar }: Props) {
  const theme = useTheme()
  const intl = useIntl()
  const location = useLocation()
  const isBigScreen = useMediaQuery(theme.breakpoints.up('md'))
  const params = useParams() as {
    // the subscription that the user wants upgraded
    subscriptionId?: string
  }

  const { data, loading } = useQuery<
    SubscriptionPlanData,
    SubscriptionPlanInput
  >(SUBSCRIPTION_PLANS, { variables: { payload: { countryCode } } })

  const [
    fetchActiveSubscription,
    { data: activeSubscriptionData, loading: subscriptionLoading },
  ] = useLazyQuery<SubscriptionByIdData, SubscriptionByIdInput>(
    SUBSCRIPTION_BY_ID,
    { variables: { id: params.subscriptionId ?? '' } }
  )
  useEffect(() => {
    params?.subscriptionId && fetchActiveSubscription()
  }, [params?.subscriptionId])

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue.toString())
  }

  const groupedPlans = getGroupedPlans(data?.subscriptionPlans?.list ?? [])
  const activeSubscription = activeSubscriptionData?.subscriptionById
  const upgradeMap =
    // TODO: in case there are new subscription-plans added and current ones are disabled
    // the ID check wont work and a plan type check is required to be implemented here.
    !!data?.subscriptionPlans?.list && !!activeSubscription?.subscriptionPlanId
      ? getUpgradeMap(
          data?.subscriptionPlans?.list ?? [],
          activeSubscription?.subscriptionPlanId
        )
      : null

  // different durations in moths
  const tabs =
    Object.keys(groupedPlans).sort((a, b) => Number(b) - Number(a)) ?? []
  const [value, setValue] = React.useState(tabs[0]?.toString())
  useEffect(() => {
    tabs[0] && setValue(tabs[0]?.toString())
  }, [data?.subscriptionPlans?.list])

  const handleOnTabClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    // @ts-expect-error property might not exist
    const tabIndex = event.target?.tabIndex ?? -1
    setValue(tabs[tabIndex].toString())
  }

  const isDashboard = location.pathname.includes(ROUTES.DASHBOARD)
  const isLoading = loading || subscriptionLoading
  return (
    <Container>
      {!isDashboard && (
        <React.Fragment>
          <MuiHeader />
          <Toolbar />
        </React.Fragment>
      )}
      <LoadingIndicator visible={isLoading} />
      <Content>
        <RowSpaced>
          <div />
          <H1 style={{ textAlign: 'center' }}>
            {intl.formatMessage({ id: 'label.selectPricingPlan' })}
          </H1>
          <div />
        </RowSpaced>
        <br />

        <TabContainer
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <TabContext value={value}>
            <TabWrapper>
              <TabList
                onChange={handleChange}
                indicatorColor="primary"
                centered
              >
                <div>
                  {tabs.map((item, index) => (
                    <TabComp
                      id={`tab-label-${index}`}
                      key={item}
                      value={item}
                      selected={value ? item === value : item === tabs[0]}
                      tabIndex={index}
                      onClick={handleOnTabClick}
                      label={
                        <H5
                          style={{ padding: isBigScreen ? '0 30px' : '0 10px' }}
                          tabIndex={index}
                          color={theme.palette.text.secondary}
                        >
                          {getDurationLabel(intl, Number(item))}
                        </H5>
                      }
                    />
                  ))}
                </div>
              </TabList>
            </TabWrapper>

            <br />
            {tabs.map((item, index) => (
              <TabPanel key={`tab-panel-${item}`} value={item} tabIndex={index}>
                <div style={{ display: 'flex', flex: 1 }}>
                  <SubscriptionGrid
                    subscription={activeSubscription}
                    upgradeMap={upgradeMap}
                    countryCode={countryCode}
                    subscriptions={groupedPlans[Number(item)]}
                    basePlans={
                      groupedPlans[Math.min(...tabs.map((tab) => Number(tab)))]
                    }
                  />
                </div>
              </TabPanel>
            ))}
          </TabContext>
        </TabContainer>
      </Content>
    </Container>
  )
}

Subscription.defaultProps = {
  showSnackbar: undefined,
}
export default withSnackbar(Subscription)
