import React from 'react'
import { IntlShape } from 'react-intl'
import { Severity } from 'components/providers/SnackbarHOC'
import AddIcon from '@mui/icons-material/Add'
import { TabSection } from 'components/Tabs'
import DangerSectionRender from 'components/content/DangerSectionRender'
import SectionRender from 'components/content/SectionRender'
import TrashIcon from 'assets/svg/Trash'
import { Masonry, Row } from 'components/shared'
import { min, max } from 'lodash'
import ItemCard from 'components/ItemCard'
import { NestExtended, SpaceOption } from 'types/nest'
// import Pencil from 'assets/svg/Pencil'
import { ContainerExtended } from 'types/container'
import ContainerCard from 'components/ContainerCard'
import { Pencil } from 'assets/svg'
import { Section } from './constants'
import { FeatureBadge } from './fragments'

const tabs = (
  intl: IntlShape,
  data: {
    record: NestExtended | undefined
    containers?: ContainerExtended[]
    spaceOptions?: SpaceOption
  },
  methods: {
    handleOnEditWithId: (sectionId: Section, recordId?: string) => void
    showSnackbar?: (message: string, severity: Severity) => void
    // showDocUploadModal: () => void
    toggleDelete: () => void
    toggleItemSelect: () => void
    toggleContainerSelect: () => void
  }
): TabSection[] => {
  const { record, containers, spaceOptions } = data
  const {
    // showDocUploadModal,
    handleOnEditWithId,
    toggleDelete,
    toggleItemSelect,
    toggleContainerSelect,
  } = methods
  const nestItems = record?.items ?? []
  const nestContainers = containers ?? []
  return [
    {
      key: 'nest-info',
      label: 'label.spaceInfo',
      visible: true,
      content: (
        <SectionRender
          header={intl.formatMessage({ id: 'label.spaceInfo' })}
          subHeadline={intl.formatMessage({ id: 'label.spaceInfoDescription' })}
          headerActions={[
            {
              ariaLabel: 'edit nest type',
              icon: (props) => <Pencil {...props} />,
              onPress: () => handleOnEditWithId(Section.nestType),
            },
          ]}
          entries={[
            {
              title: intl.formatMessage({ id: 'label.features' }),
              description: intl.formatMessage({ id: 'label.spaceFeatures' }),
              actions: [
                {
                  ariaLabel: 'edit nest features',
                  icon: (props) => <Pencil {...props} />,
                  onPress: () => handleOnEditWithId(Section.features),
                },
              ],
              content: (
                <Row style={{ marginTop: '10px' }}>
                  {spaceOptions?.features?.map((feature) => (
                    <FeatureBadge
                      key={feature}
                      label={intl.formatMessage({
                        id: `input.features.${feature}`,
                        defaultMessage: feature,
                      })}
                    />
                  ))}
                </Row>
              ),
            },
            {
              title: intl.formatMessage({ id: 'label.securityFeatures' }),
              description: intl.formatMessage({
                id: 'label.spaceSecurityFeatures',
              }),
              actions: [
                {
                  ariaLabel: 'edit nest securityFeatures',
                  icon: (props) => <Pencil {...props} />,
                  onPress: () => handleOnEditWithId(Section.securityFeatures),
                },
              ],
              content: (
                <Row style={{ marginTop: '10px' }}>
                  {spaceOptions?.securityFeatures?.map((feature) => (
                    <FeatureBadge
                      key={feature}
                      label={intl.formatMessage({
                        id: `input.securityFeatures.${feature}`,
                        defaultMessage: feature,
                      })}
                    />
                  ))}
                </Row>
              ),
            },
            {
              title: intl.formatMessage({ id: 'label.spaceUsage' }),
              description: intl.formatMessage({
                id: 'label.spaceUsageDescription',
              }),
              actions: [
                {
                  ariaLabel: 'edit nest spaceUsage',
                  icon: (props) => <Pencil {...props} />,
                  onPress: () => handleOnEditWithId(Section.spaceOptions),
                },
              ],
              content: (
                <Row style={{ marginTop: '10px' }}>
                  {spaceOptions?.spaceUsage?.map((item) => (
                    <FeatureBadge
                      key={item}
                      label={intl.formatMessage({
                        id: `input.spaceUsage.${item}`,
                        defaultMessage: item,
                      })}
                    />
                  ))}
                </Row>
              ),
            },
            {
              title: intl.formatMessage({ id: 'label.spaceElevation' }),
              description: intl.formatMessage({
                id: 'label.spaceElevationDescription',
              }),
              actions: [
                {
                  ariaLabel: 'edit nest elevation',
                  icon: (props) => <Pencil {...props} />,
                  onPress: () => handleOnEditWithId(Section.spaceOptions),
                },
              ],
              data: [
                {
                  label: intl.formatMessage({
                    id: 'label.elevation',
                  }),
                  value: spaceOptions?.elevation
                    ? intl.formatMessage({
                        id: `input.elevation.${spaceOptions?.elevation}`,
                        defaultMessage: spaceOptions?.elevation,
                      })
                    : '-',
                },
              ],
              // content: (
              //   <Row style={{ marginTop: '10px' }}>
              //     {spaceOptions?.spaceUsage?.map((item) => (
              //       <FeatureBadge
              //         key={item}
              //         label={intl.formatMessage({
              //           id: `input.spaceUsage.${item}`,
              //           defaultMessage: item,
              //         })}
              //       />
              //     ))}
              //   </Row>
              // ),
            },
          ]}
        />
      ),
    },
    {
      key: 'nest-items',
      label: 'label.items',
      visible: nestItems.length > 0,
      content: (
        <SectionRender
          header={intl.formatMessage({ id: 'label.items' })}
          headerActions={[
            {
              ariaLabel: 'add nest item',
              icon: (props) => <AddIcon color="disabled" />,
              onPress: toggleItemSelect,
            },
          ]}
        >
          <Masonry
            spacing={2}
            columns={{
              xs: 2,
              sm: min([2, max([nestItems.length, 1])]) as number,
              lg: min([2, max([nestItems.length, 1])]) as number,
              xl: min([3, max([nestItems.length, 2])]) as number,
            }}
          >
            {nestItems.map((item) => (
              <ItemCard item={item} key={item.id} />
            ))}
          </Masonry>
        </SectionRender>
      ),
    },
    {
      key: 'nest-containers',
      label: 'label.containers',
      visible: nestContainers.length > 0,
      content: (
        <SectionRender
          header={intl.formatMessage({ id: 'label.containers' })}
          headerActions={[
            {
              ariaLabel: 'add nest container',
              icon: (props) => <AddIcon color="disabled" />,
              onPress: toggleContainerSelect,
            },
          ]}
        >
          <Masonry
            spacing={2}
            columns={{
              xs: 2,
              sm: min([2, max([nestContainers.length, 1])]) as number,
              lg: min([3, max([nestContainers.length, 2])]) as number,
            }}
          >
            {nestContainers.map((rec) => (
              <ContainerCard record={rec} key={rec.id} />
            ))}
          </Masonry>
        </SectionRender>
      ),
    },

    {
      key: 'danger-zone',
      label: 'label.dangerZone',
      visible: !!record && !record.deletedAt,
      content: record ? (
        <DangerSectionRender
          entries={[
            {
              title: intl.formatMessage({ id: 'label.dangerZone' }),
              actions: [
                {
                  ariaLabel: 'delete nest',
                  icon: (props) => <TrashIcon {...props} />,
                  onPress: toggleDelete,
                  label: intl.formatMessage({ id: 'label.delete' }),
                  infoLabel: intl.formatMessage({ id: 'label.deleteSpace' }),
                },
              ],
            },
          ]}
        />
      ) : null,
    },
  ].filter((section) => section.visible)
}

export default tabs
