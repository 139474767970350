import React from 'react'
import { styled, darken, lighten } from '@mui/material/styles'
import MuiTextField from '@mui/material/TextField'
import MuiFormControl from '@mui/material/FormControl'
import MuiSelect from '@mui/material/Select'
import MuiMenuItem from '@mui/material/MenuItem'
import Box from '@mui/material/Box'
import MuiStepper from '@mui/material/Stepper'
import MuiStepLabel from '@mui/material/StepLabel'
import MuiStep from '@mui/material/Step'
import MuiFormGroup from '@mui/material/FormGroup'
import MuiFormControlLabel from '@mui/material/FormControlLabel'
import Chip from '@mui/material/Chip'
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker'
import { useIntl } from 'react-intl'
import { Button, Row } from 'components/shared'
import { Suggestion, FormDataType } from './types'
import { FontFamily } from 'assets'

export const LabelWrapper = styled(Row)(({ theme }) => ({
  justifyContent: 'space-between',
}))

export const Form = styled('form')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  maxWidth: '600px',
  minHeight: '60vh',
}))

export const FieldColumn = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  width: '100%',
  marginTop: '16px',
}))

export const FormBackButton = styled(Button)(({ theme, disabled }) => ({
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.action.active,
  border: `1px solid ${
    disabled
      ? lighten(theme.palette.action.active, 0.8)
      : theme.palette.action.active
  }`,
}))

export const InputWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: '80px',
}))

export const TextField = styled(MuiTextField)(({ theme, multiline }) => ({
  backgroundColor: theme.palette.background.default,
  marginTop: '8px',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.action.active,
  },
  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.action.active,
  },
  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.background.default,
    fontFamily: FontFamily.NoirPro,
    ...(multiline ? { minHeight: '85px' } : {}),

    '&:hover': {
      backgroundColor: `${
        theme.palette.mode === 'dark'
          ? null
          : darken(theme.palette.background.default, 0.03)
      }`,
    },
    '&:before, :after, :hover:not(.Mui-disabled):before': {
      borderBottom: 0,
    },
  },
}))

export const DatePicker = styled(MuiDatePicker)(({ theme }) => ({
  marginTop: '8px',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.action.active,
  },
  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.action.active,
  },
  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.background.default,
    fontFamily: FontFamily.NoirPro,
    '&:hover': {
      backgroundColor: `${
        theme.palette.mode === 'dark'
          ? null
          : darken(theme.palette.background.default, 0.03)
      }`,
    },
    '&:before, :after, :hover:not(.Mui-disabled):before': {
      borderBottom: 0,
    },
  },
}))

export const FormControl = styled(MuiFormControl)(({ theme }) => ({
  borderRadius: '4px',
  backgroundColor: theme.palette.background.default,
  overflow: 'hidden',
}))

export const UnitTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    borderTopRightRadius: '0',
    borderBottomRightRadius: '0',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderRight: 0,
  },
}))

export const UnitFormControl = styled(FormControl)(({ theme }) => ({
  margin: 0,
  '& .MuiOutlinedInput-notchedOutline': {
    borderLeft: 0,
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0',
  },
}))

export const Select = styled(MuiSelect)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  marginTop: '8px',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.action.active,
  },
  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.action.active,
  },
  '&:hover': {
    backgroundColor: `${
      theme.palette.mode === 'dark'
        ? null
        : darken(theme.palette.background.default, 0.03)
    }`,
  },
  '&:before, :after, :hover:not(.Mui-disabled):before': {
    borderBottom: 0,
  },
}))

export const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
  fontFamily: 'NoirPro',
  fontSize: '15px',
}))

export const MultiSelectBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 0.5,
}))

export const Stepper = styled(MuiStepper)(({ theme }) => ({
  marginBottom: '20px',
}))

export const StepLabel = styled(MuiStepLabel)(({ theme }) => ({
  '& .Mui-active,.Mui-completed': {
    fontWeight: 'bold',
  },
  '& .MuiStepLabel-label': {
    fontFamily: 'NoirPro',
    fontSize: '13px',
  },
}))

export const Step = styled(MuiStep)(({ theme }) => ({}))

export const FormGroup = styled(MuiFormGroup)(({ theme }) => ({
  marginTop: '16px',
  padding: '10px 20px',
  borderRadius: '4px',
  border: `1px solid ${theme.palette.action.active}`,
}))

export const FormControlLabel = styled(MuiFormControlLabel)(({ theme }) => ({}))

export const CountryFlag = styled('img')(({ theme }) => ({
  width: '20px',
}))

const SuggestionWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  padding: '0 10px',
  overflowX: 'auto',
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
}))

const SuggestionChip = styled(Chip)(({ theme }) => ({
  fontFamily: 'NoirPro',
  fontSize: '12px',
  marginRight: '2px',
  ':hover': {
    backgroundColor: lighten(theme.palette.action.active, 0.2),
  },
}))

interface SuggestionSectionProps {
  data: FormDataType
  suggestions?: Suggestion[]
  onSubmit?: (date: Date) => void
  visible: boolean
}
export function DateSuggestionsSection({
  suggestions,
  data,
  onSubmit,
  visible,
}: SuggestionSectionProps) {
  const intl = useIntl()
  if (!suggestions || !visible) return null

  const renderChip = (suggestion: Suggestion) => {
    // const dependentValue = suggestion.dependentField
    //   ? data[suggestion.dependentField]
    //   : null

    // const dateValue = isDate(dependentValue)
    //   ? new Date(dependentValue)
    //   : undefined
    // dateValue?.setMonth(
    //   dateValue.getMonth() + 12 * (suggestion.addedValue || 0)
    // )
    // dateValue?.setDate(dateValue.getDate() - 1)
    const { value } = suggestion // || dateValue
    const handleOnSubmit = () => value && onSubmit?.(value)
    return (
      <SuggestionChip
        label={intl.formatMessage({ id: suggestion.label })}
        key={suggestion.label}
        variant="outlined"
        onClick={handleOnSubmit}
      />
    )
  }

  return (
    <SuggestionWrapper>
      {suggestions?.map((item) => {
        return renderChip(item)
        // item.dependentField
        //   ? data?.[item.dependentField] && renderChip(item)
        //   :
      })}
    </SuggestionWrapper>
  )
}

DateSuggestionsSection.defaultProps = {
  onSubmit: null,
  suggestions: null,
}
