import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { SERVER_STATUS } from 'gql/app'
import LoadingIndicator from 'components/LoadingIndicator'
import UnderMaintenance from 'pages/static/UnderMaintenance'
import ScrollToTop from 'components/hoc/ScrollToTop'
import appConfig, { Environment } from 'config/app'
import AppLock from 'pages/static/AppLock'
import { load, localStorageKeys } from 'lib/localStorage'
import { MAIN_ROUTES } from './lib/navigation'
import { IntlProvider } from 'react-intl'
import { Events, subscribe, unsubscribe } from 'lib/events'
import { getLanguageConfig } from 'utils/language'
import CelebrationProvider from 'components/hoc/CelebrationHoc'

// NOTE configure tracking if applicable?!
if (appConfig.environment === Environment.production) {
  // firebase.analytics();
}

interface ServerStatus {
  serverStatus: { status: string }
}
function App() {
  const { loading, error } = useQuery<ServerStatus>(SERVER_STATUS, {
    fetchPolicy: 'network-only',
  })
  const [langConfig, setLangConfig] = useState(getLanguageConfig())
  useEffect(() => {
    subscribe(Events.languageChange, () => setLangConfig(getLanguageConfig()))
    return () => unsubscribe(Events.languageChange, () => {})
  })

  // TODO: lock is temporary solution for pre-release status - remove
  const lock = load(localStorageKeys.lock)
  const expiration = lock?.expiration
  const domain = window.location.host
  if (
    domain.includes('itemzz.com') &&
    (!expiration || expiration < new Date().getTime())
  )
    return (
      <IntlProvider
        defaultLocale="en"
        locale={langConfig.locale}
        messages={langConfig.messages}
      >
        <AppLock />
      </IntlProvider>
    )

  if (loading) return <LoadingIndicator visible={loading} />
  if (error) return <UnderMaintenance />
  return (
    <div>
      <IntlProvider
        defaultLocale="en"
        locale={langConfig.locale}
        messages={langConfig.messages}
      >
        <CelebrationProvider>
          <Router>
            <ScrollToTop>
              <Routes>
                {MAIN_ROUTES.map((item) => {
                  const Element = item.component
                  return (
                    <Route
                      key={`route_${item.route}`}
                      path={item.route}
                      element={<Element />}
                    />
                  )
                })}
              </Routes>
            </ScrollToTop>
          </Router>
        </CelebrationProvider>
      </IntlProvider>
    </div>
  )
}

export default App
