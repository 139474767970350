import * as React from 'react'
import { styled, useTheme, Theme, alpha } from '@mui/material/styles'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import { Workspace, WorkspaceExtended } from 'types/workspace'
import { Row, RowSpaced } from 'components/shared'
import placeholder from 'assets/images/workspacePlaceholder.jpg'
import appConfig from 'config/app'
import ArchiveIcon from 'assets/svg/Archive'
import CardholderIcon from 'assets/svg/Cardholder'
import HouseSimpleIcon from 'assets/svg/HouseSimple'
import CircleWavyIcon from 'assets/svg/CircleWavy'
import { cardBoxShadow, cardBoxShadowHover } from 'assets/styles/theme'
import { Severity, withSnackbar } from 'components/providers/SnackbarHOC'
import CellSignalHighIcon from 'assets/svg/CellSignalHigh'
// import BellRingingIcon from 'assets/svg/BellRinging'
import Button from '@mui/material/Button'
import ROUTES from 'lib/routes'
import lock from 'assets/images/lock.svg'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import { IconButton, Tooltip } from '@mui/material'
import { ConfirmationStatus } from 'types/shared'
import { useLazyQuery } from '@apollo/client'
import { REQUEST_WORKSPACE_MEMBERSHIP_EMAIL } from 'gql/email'
import {
  RequestWorkspaceMembershipEmailData,
  RequestWorkspaceMembershipEmailInput,
} from 'types/email'
import { Users } from 'assets/svg'
import { CardActionBar } from './cards'

const CardWrapper = styled(Card)(({ theme }) => ({
  position: 'relative',
  padding: 0,
  borderRadius: '5px',
  boxShadow: cardBoxShadow,
  ':hover': {
    cursor: 'pointer',
    boxShadow: cardBoxShadowHover,
  },
}))

const Media = styled('img')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  objectFit: 'cover',
  height: '350px',
  [theme.breakpoints.down('lg')]: {
    height: '300px',
  },
}))

const Content = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  justifyContent: 'flex-end',
  flexDirection: 'column',
  position: 'absolute',
  bottom: '0',
  width: '95%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}))

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  fontFamily: 'NoirPro-Medium',
  fontSize: '28px',
  fontWeight: 700,
  lineHeight: '33px',
  letterSpacing: '0em',
  textAlign: 'left',
  userSelect: 'none',
}))

const Overlay = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  height: '45%',
  background:
    'linear-gradient(180deg, rgba(0, 0, 0, 0) 7.19%, rgba(0, 0, 0, 0.5) 100%);',
}))

const LockOverlay = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  backdropFilter: 'blur(3px)',
  backgroundColor: alpha(theme.palette.action.active, 0.2),
  justifyContent: 'center',
  alignItems: 'center',
}))

const Image = styled('img')(({ theme }) => ({
  height: '80px',
  objectFit: 'contain',
  width: 'auto',
  opacity: '.8',
  borderRadius: '40px',
  boxShadow:
    'inset 5px 5px 30px 0px rgba(255,255,255,1),  5px 5px 30px 0px rgba(255,255,255,1);;',
}))
const ContentText = styled('span')<{
  theme?: Theme
  color?: string
}>(({ theme, color }) => ({
  fontFamily: 'NoirPro',
  fontSize: '14px',
  lineHeight: '17px',
  color: color ?? theme.palette.text.secondary,
  marginLeft: '2px',
}))

const MailIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: 10,
  right: 10,
  color: theme.palette.background.default,
}))

const ContentTextLabel = styled(ContentText)(({ theme, color }) => ({
  color: theme.palette.primary.contrastText,
  display: 'none',
}))

const CountChip = styled(Row)(({ theme }) => ({
  cursor: 'pointer',
  userSelect: 'none',
  transition: theme.transitions.create(['padding', 'transform'], {
    duration: theme.transitions.duration.standard,
  }),
  marginRight: '14px',
  ':hover': {
    backgroundColor: theme.palette.action.active,
    borderRadius: '3px',
    padding: '0 4px',
  },
  ':hover svg path,line': {
    stroke: theme.palette.primary.contrastText,
  },
  ':hover span': {
    display: 'flex',
  },
}))

// const NotificationChip = styled(Row)(({ theme }) => ({
//   borderRadius: '3px',
//   padding: '0 6px',
//   backgroundColor: theme.palette.primary.contrastText,
//   cursor: 'pointer',
//   userSelect: 'none',
//   transition: theme.transitions.create(['padding', 'transform'], {
//     duration: theme.transitions.duration.standard,
//   }),
//   ':hover': {
//     backgroundColor: theme.palette.action.active,
//     borderRadius: '3px',
//     padding: '0 8px',
//   },
//   ':hover svg :where(path, line)': {
//     stroke: theme.palette.primary.contrastText,
//   },
//   ':hover span': {
//     color: theme.palette.primary.contrastText,
//   },
// }))

const ActionBar = styled(CardActionBar)(({ theme }) => ({
  padding: '10px 8px',
}))

interface Props {
  workspace: WorkspaceExtended
  onClick: (workspace: Workspace) => void
  showSnackbar?: (message: string, severity: Severity) => void
}
function WorkspaceCard({ workspace, onClick, showSnackbar }: Props) {
  const intl = useIntl()
  const navigate = useNavigate()
  const theme = useTheme()
  const isLocked =
    !!workspace.membership?.status && // owned workspaces in managed organizations do not have membership entries
    workspace.membership?.status !== ConfirmationStatus.confirmed
  const handleClick = () =>
    !isLocked
      ? onClick(workspace)
      : showSnackbar?.(
          intl.formatMessage({ id: 'label.boardInvitationNotAccepted' }),
          Severity.WARNING
        )

  const cover = workspace.coverImage?.medium
    ? `${appConfig.media}${workspace.coverImage.medium}`
    : placeholder

  const [requestMembershipEmailVerification] = useLazyQuery<
    RequestWorkspaceMembershipEmailData,
    RequestWorkspaceMembershipEmailInput
  >(REQUEST_WORKSPACE_MEMBERSHIP_EMAIL, {
    onCompleted: () =>
      showSnackbar?.(
        intl.formatMessage({ id: 'label.emailConfirmationRequested' }),
        Severity.SUCCESS
      ),
    onError: () =>
      showSnackbar?.(
        intl.formatMessage({ id: 'label.emailBoardInvitationFailed' }),
        Severity.ERROR
      ),
  })
  const onEmailConfirmation = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault()
    event?.stopPropagation()
    if (workspace.membership?.id)
      requestMembershipEmailVerification({
        variables: { membershipId: workspace.membership?.id },
      })
  }

  // const hasPendingStatus =
  //   workspace.membership?.status === ConfirmationStatus.pending

  const cardContent = [
    {
      label: 'label.groups',
      icon: CardholderIcon,
      count: workspace.groupCount,
      to: (workspaceId: string) => ROUTES.DASHBOARD_GROUPS_ROUTE(workspaceId),
    },
    {
      label: 'label.spaces',
      icon: HouseSimpleIcon,
      count: workspace.nestCount,
      to: (workspaceId: string) => ROUTES.DASHBOARD_NESTS_ROUTE(workspaceId),
    },
    {
      label: 'label.containers',
      icon: ArchiveIcon,
      count: workspace.containerCount,
      to: (workspaceId: string) =>
        ROUTES.DASHBOARD_CONTAINERS_ROUTE(workspaceId),
    },
    {
      label: 'label.items',
      icon: CircleWavyIcon,
      count: workspace.itemCount,
      to: (workspaceId: string) => ROUTES.DASHBOARD_ITEMS_ROUTE(workspaceId),
    },
    {
      label: 'label.providers',
      icon: CellSignalHighIcon,
      count: workspace.serviceProviderCount,
      to: (workspaceId: string) =>
        ROUTES.DASHBOARD_SERVICE_PROVIDERS_ROUTE(workspaceId),
    },
    {
      label: 'label.members',
      icon: Users,
      count: workspace.memberCount,
      to: (workspaceId: string) => ROUTES.DASHBOARD_MEMBERS_ROUTE(workspaceId),
    },
  ].filter((entry) => !!entry.count && entry?.count > 0)

  return (
    <CardWrapper onClick={handleClick}>
      <Media src={cover} alt="workspace cover" />
      <Overlay />
      <Content>
        <RowSpaced>
          <div>
            <Title variant="h5">{workspace.title}</Title>
            <Row style={{ flexWrap: 'nowrap' }}>
              {cardContent.map((Item) => (
                <Button
                  style={{ margin: 0, minWidth: 0, minHeight: 0, padding: 0 }}
                  onMouseDown={(e) => e.preventDefault()}
                  key={Item.label}
                  onClick={(event) => {
                    event.stopPropagation()
                    event.preventDefault()
                    navigate(Item.to(workspace.id))
                  }}
                >
                  <CountChip style={{ flexWrap: 'nowrap' }}>
                    {/* eslint-disable-next-line */}
                    <Item.icon
                      color={theme.palette.primary.contrastText}
                      width="20px"
                    />
                    <ContentText color={theme.palette.primary.contrastText}>
                      {Item.count}
                    </ContentText>
                    <ContentTextLabel>
                      {intl.formatMessage({ id: Item.label }).toLowerCase()}
                    </ContentTextLabel>
                  </CountChip>
                </Button>
              ))}
            </Row>
          </div>
        </RowSpaced>
      </Content>
      <ActionBar>
        <div />
        {/* <NotificationChip>
          <BellRingingIcon color={theme.palette.text.primary} width="20px" />
          <ContentText color={theme.palette.text.primary}>3</ContentText>
        </NotificationChip> */}
      </ActionBar>
      {isLocked && (
        <LockOverlay>
          <Image src={lock} />
          {workspace.membership?.id && (
            <Tooltip
              title={intl.formatMessage({ id: 'label.resendInvitation' })}
            >
              <MailIconButton onClick={onEmailConfirmation}>
                <MailOutlineIcon />
              </MailIconButton>
            </Tooltip>
          )}
        </LockOverlay>
      )}
    </CardWrapper>
  )
}

export default withSnackbar(WorkspaceCard)

WorkspaceCard.defaultProps = {
  showSnackbar: undefined,
}
