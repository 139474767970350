import { gql } from '@apollo/client'

export const WORKSPACE_CONTAINERS = gql`
  query WORKSPACE_CONTAINERS($payload: ContainersAggregateInput!) {
    workspaceContainers(payload: $payload) {
      total
      list {
        id
        title
        description
        workspaceId
        archive {
          id
          type
          media {
            thumbnail
            medium
            full
          }
        }
        createdAt
        updatedAt
        itemCount
        serviceCount
        members {
          ... on WorkspaceMemberExtended {
            id
            refId
            userId
            email
            workspaceId
            firstName
            lastName
            avatarId
            avatar {
              avatar
            }
            createdAt
            updatedAt
          }

          ... on OrgMembershipExtended {
            id
            refId
            userId
            email
            organizationId
            createdAt
            updatedAt
            user {
              id
              firstName
              lastName
              avatar {
                avatar
              }
            }
          }
        }
      }
    }
  }
`

export const CONTAINER_BY_ID = gql`
  query CONTAINER_BY_ID($containerId: ID!) {
    containerById(containerId: $containerId) {
      id
      title
      description
      workspaceId
      createdAt
      updatedAt
      deletedAt
      itemCount
      items {
        id
        title
        categoryId
        workspaceId
        description
        createdAt
        updatedAt
        archive {
          id
          type
          media {
            thumbnail
            medium
            full
          }
        }
      }
      nest {
        id
        title
        description
      }
      archive {
        id
        type
        path
        media {
          thumbnail
          medium
          full
        }
      }
      members {
        ... on WorkspaceMemberExtended {
          id
          refId
          userId
          email
          workspaceId
          firstName
          lastName
          avatarId
          avatar {
            avatar
          }
          createdAt
          updatedAt
        }

        ... on OrgMembershipExtended {
          id
          refId
          userId
          email
          organizationId
          createdAt
          updatedAt
          user {
            id
            firstName
            lastName
            avatar {
              avatar
            }
          }
        }
      }
    }
  }
`

export const CREATE_CONTAINER = gql`
  mutation CREATE_CONTAINER($payload: CreateContainerInput!) {
    createContainer(payload: $payload) {
      id
    }
  }
`

export const UPDATE_CONTAINER = gql`
  mutation UPDATE_CONTAINER($payload: UpdateContainerInput!) {
    updateContainer(payload: $payload) {
      id
    }
  }
`

export const TRANSFER_CONTAINERS = gql`
  mutation TRANSFER_CONTAINERS($payload: TransferContainersInput!) {
    transferContainers(payload: $payload) {
      status
    }
  }
`

export const UPDATE_NEST_CONTAINER_REFERENCE = gql`
  mutation UPDATE_NEST_CONTAINER_REFERENCE(
    $payload: UpdateNestContainerReferencesInput!
  ) {
    updateNestContainerReferences(payload: $payload) {
      status
    }
  }
`

export const DELETE_CONTAINER = gql`
  mutation DELETE_CONTAINER($id: ID!) {
    deleteContainer(id: $id) {
      status
    }
  }
`
