import React, { Component } from 'react'
import { BasicUserStruct, isOrganizationMember } from 'types/user'
import appConfig from 'config/app'
import theme from 'assets/styles/theme'
import { CaptionText, H4 } from 'components/typography'
import {
  ConfirmationStatus,
  MemberModels,
  WorkspaceAccessRoles,
} from 'types/shared'
import { Row } from 'components/shared'
import IconButton from '@mui/material/IconButton'
import RemoveIcon from '@mui/icons-material/PersonRemove'
import ROUTES from 'lib/routes'
import Pencil from 'assets/svg/Pencil'
import { getActiveWorkspaceId } from 'lib/sessionStorage'
import {
  AvatarWrapper,
  Container,
  Image,
  AvatarIcon,
  PhotoChange,
  Content,
  LinkButton,
} from '../fragments'

interface Actions {
  onDelete?: () => void
  onEdit?: () => void
}
type UserProps<T> = T & {
  member: BasicUserStruct
  onAvatarChange?: () => void
  actions?: Actions
}
type UserState<T> = T & {}

class UserCard<P, S> extends Component<UserProps<P>, UserState<S>> {
  get editable() {
    return false
  }

  get memberModel() {
    if (isOrganizationMember(this.props.member))
      return MemberModels.ORGANIZATION_MEMBER
    return MemberModels.WORKSPACE_MEMBER
  }

  get to() {
    const workspaceId = getActiveWorkspaceId()
    const memberId = this.props.member.id
    if (workspaceId && memberId)
      return ROUTES.DASHBOARD_MEMBERS_DETAILS_ROUTE(
        workspaceId,
        memberId,
        this.memberModel
      )
    return '#'
  }

  get avatarLink() {
    const { member } = this.props
    return member?.avatar?.avatar
      ? `${appConfig.media}${member?.avatar?.avatar}`
      : null
  }

  avatar() {
    const onAvatarClick = () =>
      this.editable ? this.props.onAvatarChange?.() : null
    return (
      <AvatarWrapper onClick={onAvatarClick}>
        {this.avatarLink ? (
          <Image src={this.avatarLink} />
        ) : (
          <AvatarIcon color="primary" />
        )}
        {this.editable && <PhotoChange fontSize="medium" />}
      </AvatarWrapper>
    )
  }

  role() {
    const { member } = this.props
    if (!member?.role || member.role === WorkspaceAccessRoles.contact)
      return null
    return (
      <CaptionText color={theme.palette.primary.main}>
        {member?.role?.toLowerCase()}
      </CaptionText>
    )
  }

  name() {
    const { member } = this.props
    return (
      <H4>
        {member.firstName} {member.lastName ?? ''}
      </H4>
    )
  }

  statusChip() {
    const { member } = this.props
    const visible = member.status === ConfirmationStatus.pending
    if (!visible) return null
    return (
      <CaptionText color={theme.palette.warning.main}>
        {member?.status?.toLocaleLowerCase()}
      </CaptionText>
    )
  }

  actionButtons() {
    if (!this.editable) return null
    const handleOnEdit = (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      event.stopPropagation()
      event.preventDefault()
      this.props.actions?.onEdit?.()
      // handleOnEditWithId(Section.general)
    }
    const handleDelete = (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      event.stopPropagation()
      event.preventDefault()
      this.props.actions?.onDelete?.()
    }

    return (
      <Row>
        <IconButton
          onMouseDown={(e) => e.preventDefault()}
          onClick={handleOnEdit}
        >
          <Pencil size="22px" color={theme.palette.primary.main} />
        </IconButton>
        <IconButton
          onMouseDown={(e) => e.preventDefault()}
          onClick={handleDelete}
        >
          <RemoveIcon fontSize="small" color="error" />
        </IconButton>
      </Row>
    )
  }

  render() {
    return (
      <LinkButton to={this.to}>
        <Container>
          {this.avatar()}
          <Content>
            <div>
              {this.role()}
              {this.name()}
              {this.statusChip()}
            </div>
            {this.actionButtons()}
          </Content>
        </Container>
      </LinkButton>
    )
  }
}

export default UserCard
