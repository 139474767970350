import { gql } from '@apollo/client'

export const WORKSPACE_GROUPS = gql`
  query WORKSPACE_GROUPS($payload: GroupsAggregateInput!) {
    workspaceGroups(payload: $payload) {
      total
      list {
        id
        title
        description
        workspaceId
        createdAt
        updatedAt
        itemCount
        coverImage {
          full
          medium
          thumbnail
        }
      }
    }
  }
`

export const ITEM_GROUPS = gql`
  query ITEM_GROUPS($itemId: ID!) {
    itemGroups(itemId: $itemId) {
      total
      list {
        id
        title
        description
        workspaceId
        createdAt
        updatedAt
        itemCount
        coverImage {
          full
          medium
          thumbnail
        }
      }
    }
  }
`

export const GROUP_BY_ID = gql`
  query GROUP_BY_ID($groupId: ID!) {
    groupById(groupId: $groupId) {
      id
      title
      description
      workspaceId
      createdAt
      updatedAt
      deletedAt
      coverImage {
        full
        medium
        thumbnail
      }
    }
  }
`

export const CREATE_GROUP = gql`
  mutation CREATE_GROUP($payload: CreateGroupInput!) {
    createGroup(payload: $payload) {
      id
    }
  }
`

export const CREATE_GROUP_FROM_TYPES = gql`
  mutation CREATE_GROUP_FROM_TYPES($payload: CreateGroupFromTypesInput!) {
    createGroupFromTypes(payload: $payload) {
      status
    }
  }
`

export const GROUP_TYPES = gql`
  query GROUP_TYPES($organizationId: ID!) {
    groupTypes(organizationId: $organizationId) {
      total
      list {
        id
        title
        description
        titleDE
        descriptionDE
        published
        priority
        featured
        applicable {
          organizationTypes
        }
        coverImage {
          full
          medium
          thumbnail
        }
        createdAt
        updatedAt
      }
    }
  }
`

export const GROUP_TYPES_COUNTER = gql`
  query GROUP_TYPES_COUNTER($workspaceId: ID!) {
    groupTypesCounter(workspaceId: $workspaceId)
  }
`

export const GROUP_ITEMS = gql`
  query GROUP_ITEMS($groupId: ID!) {
    groupItems(groupId: $groupId) {
      id
      title
      categoryId
      workspaceId
      description
      createdAt
      updatedAt
      archive {
        id
        type
        media {
          thumbnail
          medium
          full
        }
      }
    }
  }
`

export const DELETE_GROUPS = gql`
  mutation DELETE_GROUPS($groupIds: [ID]!) {
    deleteGroups(groupIds: $groupIds) {
      status
    }
  }
`

export const UPDATE_GROUP = gql`
  mutation UPDATE_GROUP($payload: UpdateGroupInput!) {
    updateGroup(payload: $payload) {
      id
    }
  }
`
