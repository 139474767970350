import React from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
// import AddIcon from '@mui/icons-material/AddSharp'
import { useIntl } from 'react-intl'
// import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import {
  Content,
  Container,
  // SectionCTA,
  // GridWrapper,
  SectionWrapper,
} from 'components/shared'
import { H1, H2 } from 'components/typography'
// import { USER_REQUESTS } from 'gql/userRequest'
// import { DataGridComp } from 'components/table/datagrid'
// import { WORKSPACE_INVENTORY } from 'gql/inventory'
// import { WorkspaceInventoryData } from 'types/inventory'
import LoadingIndicator from 'components/LoadingIndicator'
// import GroupCard from 'components/GroupCard'
import {
  PageGrid,
  PageGridLeft,
  PageGridRight,
  StickySection,
} from 'components/pages'
import { ProfileData } from 'types/profile'
import { WORKSPACE_STATS } from 'gql/workspace'
import { WorkspaceStatsData } from 'types/workspace'
import { Severity, withSnackbar } from 'components/providers/SnackbarHOC'
// import { UserRequestReferenceModel } from 'types/userRequest'
import { PROFILE } from 'gql/user'
import { InventoryEntityCard } from './fragments'
import {
  //  columns,
  inventoryCards,
} from './constants'
import InventoryHelper from './InventoryHelper'
import { EVENTS_TIMELINE } from 'gql/event'
import { EventsTimelineData, EventsTimelineInput } from 'types/event'
import EventTimeline from 'components/EventTimeline'
// import InfoCard from '../overview/InfoCard'

interface Props {
  showSnackbar?: (message: string, severity: Severity) => void
}
function Inventory({ showSnackbar }: Props) {
  const intl = useIntl()
  const params = useParams() as { workspaceId: string }
  // const payload = { workspaceId: params.workspaceId }
  // const { data: inventoryData, loading } = useQuery<WorkspaceInventoryData>(
  //   WORKSPACE_INVENTORY,
  //   { variables: { payload } }
  // )
  const { data, loading: profileLoading } = useQuery<ProfileData>(PROFILE)
  const profile = data?.profile
  const { data: stats, loading: statsLoading } = useQuery<WorkspaceStatsData>(
    WORKSPACE_STATS,
    { variables: { workspaceId: params.workspaceId } }
  )
  const statsData = stats?.workspaceDashboardStats
  const { data: timeline, loading } = useQuery<
    EventsTimelineData,
    EventsTimelineInput
  >(EVENTS_TIMELINE, { variables: { workspaceId: params.workspaceId } })
  // const groups = inventoryData?.workspaceInventory.groups.list.slice(0, 4)
  // const items = inventoryData?.workspaceInventory.items.list
  // const isBigScreen = useMediaQuery(theme.breakpoints.up('md'))

  // const toItems = () =>
  //   navigate(ROUTES.DASHBOARD_ITEMS_ROUTE(params.workspaceId))
  // const toGroups = () =>
  //   navigate(ROUTES.DASHBOARD_GROUPS_ROUTE(params.workspaceId))

  // const referenceData = params.workspaceId
  //   ? {
  //       referenceId: params.workspaceId,
  //       referenceModel: UserRequestReferenceModel.WORKSPACE,
  //     }
  //   : undefined

  const events = timeline?.eventsTimeline ?? []
  const isLoading = statsLoading || profileLoading || loading
  return (
    <Container>
      <LoadingIndicator visible={isLoading} />
      <InventoryHelper />
      <Content>
        <PageGrid container spacing={6}>
          <PageGridLeft item xs={12} sm={12} md={12} lg={7} xl={8}>
            <SectionWrapper>
              <H1>
                {intl.formatMessage(
                  { id: 'label.welcomeUser' },
                  { name: profile?.firstName }
                )}
              </H1>
              {/* <Row style={{ marginTop: '20px' }}>
                <InfoCard
                  title={intl.formatMessage({ id: 'label.items' })}
                  stats={statsData?.items}
                  to={ROUTES.DASHBOARD_ITEMS_ROUTE(params.workspaceId)}
                />
                <InfoCard
                  title={intl.formatMessage({ id: 'label.spaces' })}
                  stats={statsData?.nests}
                  to={ROUTES.DASHBOARD_NESTS_ROUTE(params.workspaceId)}
                />
                <InfoCard
                  title={intl.formatMessage({ id: 'label.containers' })}
                  stats={statsData?.containers}
                  to={ROUTES.DASHBOARD_CONTAINERS_ROUTE(params.workspaceId)}
                />
              </Row> */}
            </SectionWrapper>

            <SectionWrapper>
              <H2>{intl.formatMessage({ id: 'label.yourInventory' })}</H2>

              <Grid container spacing={2} mt={1}>
                {inventoryCards(intl, params.workspaceId, statsData).map(
                  (card) => (
                    <Grid key={card.label} item xs={6} sm={6} md={4}>
                      <InventoryEntityCard {...card} />
                    </Grid>
                  )
                )}
              </Grid>
            </SectionWrapper>
            {/* {isBigScreen && (
              <React.Fragment>
                <SectionWrapper>
                  <Row style={{ justifyContent: 'space-between' }}>
                    <Row>
                      <H3>{intl.formatMessage({ id: 'label.groups' })}</H3>
                      <Button
                        component={Link}
                        to={ROUTES.DASHBOARD_CREATE_GROUP_ROUTE(
                          params.workspaceId
                        )}
                      >
                        <AddIcon />
                      </Button>
                    </Row>

                    <SectionCTA color="primary" onClick={toGroups}>
                      {intl.formatMessage({ id: 'label.seeAll' })}
                    </SectionCTA>
                  </Row>
                  <Grid container spacing={2} marginBottom={4}>
                    {groups?.map((record) => (
                      <Grid item xs={12} sm={6} md={3} key={record.id}>
                        <GroupCard record={record} />
                      </Grid>
                    ))}
                  </Grid>
                </SectionWrapper>

                <SectionWrapper>
                  <Row style={{ justifyContent: 'space-between' }}>
                    <H3>
                      {intl.formatMessage({ id: 'label.ungroupedItems' })}
                    </H3>

                    <SectionCTA color="primary" onClick={toItems}>
                      {intl.formatMessage({ id: 'label.seeAll' })}
                    </SectionCTA>
                  </Row>
                  <GridWrapper>
                    <DataGridComp
                      rows={items ?? []}
                      columns={columns(intl, () => {})}
                      disableSelectionOnClick
                      rowsPerPageOptions={[25, 50, 100]}
                      rowCount={
                        inventoryData?.workspaceInventory.items.total ?? 0
                      }
                      experimentalFeatures={{ newEditingApi: true }}
                      // checkboxSelection
                      // onSelectionModelChange={setSelectionModel}
                      // selectionModel={selectionModel}
                    />
                  </GridWrapper>
                </SectionWrapper>
              </React.Fragment>
            )} */}
          </PageGridLeft>

          <PageGridRight item xs={12} sm={12} md={12} lg={5} xl={4}>
            <StickySection>
              {events.length > 0 && (
                <EventTimeline records={events} />
              )}
            </StickySection>
          </PageGridRight>
        </PageGrid>
      </Content>
    </Container>
  )
}

Inventory.defaultProps = {
  showSnackbar: undefined,
}
export default withSnackbar(Inventory)
