import React from 'react'
import { useIntl } from 'react-intl'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { Link } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import AddIcon from '@mui/icons-material/AddSharp'
import Grid from '@mui/material/Grid'
import { Button, Row } from '../shared'
import { H1 } from '../typography'

const CtaGrid = styled(Grid)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'flex-end',
  },
}))

interface Props {
  title: string
  renderActions: () => JSX.Element
  total?: number
  cta: {
    route: string
    label: string
  }
}
function ListingHeadline({
  title,
  renderActions,
  total,
  cta: { route, label },
}: Props) {
  const intl = useIntl()
  const theme = useTheme()
  const isBigScreen = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Grid container padding={2} paddingTop={0}>
      <Grid item md={12} sm={6} mb={2}>
        <H1>
          {intl.formatMessage({ id: title })}{' '}
          {total ?? -1 > 0 ? `( ${total} )` : ''}
        </H1>
      </Grid>
      <CtaGrid item md={6}>
        <Row>
          {isBigScreen && (
            <Button component={Link} to={route}>
              <AddIcon color="secondary" />
              {intl.formatMessage({ id: label })}
            </Button>
          )}
          {/* <Button
              style={{ marginLeft: '10px' }}
              component={Link}
              to={ROUTES.DASHBOARD_CREATE_ITEM_SCANNER_ROUTE(
                params.workspaceId
              )}
            >
              <AddIcon color="secondary" />
              QR scan
            </Button> */}
        </Row>
      </CtaGrid>
      <Grid item md={6} sm={12} xs={12}>
        {renderActions()}
      </Grid>
    </Grid>
  )
}

ListingHeadline.defaultProps = {
  total: 0,
}
export default ListingHeadline
