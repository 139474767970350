import { useNavigate } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import {
  ADD_CONTACT_PERSON,
  DELETE_SERVICE_PROVIDER,
  SERVICE_PROVIDERS_LIST,
  SERVICE_PROVIDER_BY_ID,
  SERVICE_PROVIDER_CATEGORIES,
  SERVICE_PROVIDER_RELATIONS,
  UPDATE_SERVICE_PROVIDER,
} from 'gql/serviceProvider'
import {
  AddContactPersonData,
  AddContactPersonInput,
  DeleteServiceProviderData,
  DeleteServiceProviderInput,
  ServiceProviderByIdData,
  ServiceProviderCategoriesData,
  ServiceProviderRelationsData,
  UpdateServiceProviderData,
  UpdateServiceProviderInput,
} from 'types/serviceProvider'
import ROUTES from 'lib/routes'
import { Severity } from 'components/providers/SnackbarHOC'
import {
  CREATE_SERVICE,
  DELETE_SERVICE,
  PROVIDER_SERVICES,
  SERVICE_TYPES,
  UPDATE_SERVICE,
} from 'gql/service'
import {
  CreateServiceData,
  CreateServiceInput,
  DeleteServiceData,
  DeleteServiceInput,
  ProviderServicesData,
  ServiceTypeData,
  UpdateServiceData,
  UpdateServiceInput,
} from 'types/service'
import { AddFileToServiceProviderData } from 'types/archive'
import { ADD_FILE_TO_SERVICE_PROVIDER } from 'gql/archive'
import { OrganizationDataQuery } from 'types/organization'
import { ORGANIZATION_DATA } from 'gql/organization'
import { useMutations as eventMutations } from 'hooks/graphql/event'
import { WorkspaceContractsData } from 'types/contracts'
import { WORKSPACE_CONTRACTS } from 'gql/contracts'

export function useData(
  workspaceId: string,
  providerId: string,
  methods: {
    showSnackbar?: (message: string, severity: Severity) => void
    refreshEventDrawer: () => void
  }
) {
  const { data: types, loading: typesLoading } =
    useQuery<ServiceTypeData>(SERVICE_TYPES)

  const {
    data: recordData,
    loading: recordLoading,
    refetch,
  } = useQuery<ServiceProviderByIdData>(SERVICE_PROVIDER_BY_ID, {
    variables: { payload: { providerId, workspaceId } },
    fetchPolicy: 'cache-and-network',
    onCompleted: methods.refreshEventDrawer,
    onError: (error) => methods.showSnackbar?.(error.message, Severity.ERROR),
  })

  const { data: spRelations, loading: relationsLoading } =
    useQuery<ServiceProviderRelationsData>(SERVICE_PROVIDER_RELATIONS, {
      variables: { payload: { providerId, workspaceId } },
    })

  const { data: servicesData, loading: servicesLoading } =
    useQuery<ProviderServicesData>(PROVIDER_SERVICES, {
      variables: { payload: { providerId, workspaceId } },
    })

  const { data: categoriesData, loading: categoriesLoading } =
    useQuery<ServiceProviderCategoriesData>(SERVICE_PROVIDER_CATEGORIES)
  const categories = categoriesData?.providerCategories ?? []

  const { data: wsContractsData, loading: contractsLoading } =
    useQuery<WorkspaceContractsData>(WORKSPACE_CONTRACTS, {
      variables: { payload: { workspaceId } },
    })

  const contracts = wsContractsData?.workspaceContracts.list || []
  const { data: orgData, loading: orgLoading } =
    useQuery<OrganizationDataQuery>(ORGANIZATION_DATA, {
      variables: { workspaceId },
    })
  const serviceTypes = types?.serviceTypes.list || []
  const record = recordData?.serviceProviderById
  const relations = spRelations?.serviceProviderRelations
  const services = servicesData?.providerServices
  const organization = orgData?.organizationData

  const loading =
    typesLoading ||
    recordLoading ||
    servicesLoading ||
    relationsLoading ||
    categoriesLoading ||
    orgLoading ||
    contractsLoading

  const data = {
    serviceTypes,
    record,
    relations,
    services,
    categories,
    organization,
    contracts,
  }

  return {
    loading,
    data,
    refetch: {
      refetchServiceProvider: refetch,
    },
  }
}

export function useMutations(
  workspaceId: string,
  providerId: string,
  methods: {
    showSnackbar?: (message: string, severity: Severity) => void
    hideEditingModal: () => void
  }
) {
  const navigate = useNavigate()
  const { showSnackbar, hideEditingModal } = methods

  const refetchListing = {
    query: SERVICE_PROVIDERS_LIST,
    variables: { payload: { workspaceId } },
  }

  const refetchQueries = [
    {
      query: SERVICE_PROVIDER_BY_ID,
      variables: { payload: { providerId, workspaceId } },
    },
  ]

  const [updateRecord, { loading: updateLoading }] = useMutation<
    UpdateServiceProviderData,
    UpdateServiceProviderInput
  >(UPDATE_SERVICE_PROVIDER, {
    onCompleted: hideEditingModal,
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: [...refetchQueries, refetchListing],
  })

  const [deleteServiceProvider, { loading: deletingServiceProvider }] =
    useMutation<DeleteServiceProviderData, DeleteServiceProviderInput>(
      DELETE_SERVICE_PROVIDER,
      {
        onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
        onCompleted: () => navigate(-1),
        refetchQueries: [refetchListing],
      }
    )

  const [addContactPerson, { loading: createLoading }] = useMutation<
    AddContactPersonData,
    AddContactPersonInput
  >(ADD_CONTACT_PERSON, {
    onCompleted: hideEditingModal,
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries,
  })

  const [createService, { loading: createServiceLoading }] = useMutation<
    CreateServiceData,
    CreateServiceInput
  >(CREATE_SERVICE, {
    onCompleted: (response) =>
      navigate(
        ROUTES.DASHBOARD_SERVICE_DETAILS_ROUTE(
          workspaceId,
          response.createService.id
        )
      ),
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: [
      {
        query: PROVIDER_SERVICES,
        variables: { payload: { providerId, workspaceId } },
      },
    ],
  })

  const [deleteService, { loading: deletingService }] = useMutation<
    DeleteServiceData,
    DeleteServiceInput
  >(DELETE_SERVICE, {
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: [
      {
        query: PROVIDER_SERVICES,
        variables: { payload: { providerId, workspaceId } },
      },
    ],
  })

  const [updateService, { loading: updateServiceLoading }] = useMutation<
    UpdateServiceData,
    UpdateServiceInput
  >(UPDATE_SERVICE, {
    onCompleted: hideEditingModal,
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: [
      {
        query: PROVIDER_SERVICES,
        variables: { payload: { providerId, workspaceId } },
      },
    ],
  })
  const [addFileToServiceProvider] = useMutation<AddFileToServiceProviderData>(
    ADD_FILE_TO_SERVICE_PROVIDER,
    {
      onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
      refetchQueries,
    }
  )

  const {
    loading: eventsLoading,
    mutations: { updateEvent, addEventRecord, deleteEvent },
  } = eventMutations(
    { showSnackbar, onCompleted: hideEditingModal },
    refetchQueries
  )

  const loading =
    eventsLoading ||
    updateLoading ||
    createLoading ||
    deletingService ||
    createServiceLoading ||
    updateServiceLoading ||
    deletingServiceProvider

  return {
    loading,
    mutations: {
      updateRecord,
      createService,
      deleteService,
      updateService,
      addContactPerson,
      deleteServiceProvider,
      addEventRecord,
      addFileToServiceProvider,
      deleteEvent,
      updateEvent,
    },
  }
}
