import React from 'react'
import { useTheme } from '@mui/material/styles'
import { useIntl } from 'react-intl'
import useMediaQuery from '@mui/material/useMediaQuery'
import { styled } from '@mui/material/styles'
import { BodyTextLight, H3 } from 'components/typography'
import { Card, Grid } from '@mui/material'
import { SectionContainer } from './fragments'
import StyledText from 'components/StyledIntlText'
import { FontFamily } from 'assets'
import { cardBoxShadow, cardBoxShadowHover } from 'assets/styles/theme'
import arrow from 'assets/images/dashedArrow.svg'
import {
  managementSection,
  inventorySection,
  commandControlSection,
} from 'assets/images/landing'

const Image = styled('img')(({ theme }) => ({
  width: '150px',
  height: '100px',
  objectFit: 'contain',
  borderRadius: '40px',
}))

const ContainerCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  borderRadius: '60px',
  padding: '40px 60px',
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  minWidth: '200px',
  height: '180px',
  boxShadow: cardBoxShadow,
  ':hover': {
    cursor: 'pointer',
    boxShadow: cardBoxShadowHover,
  },
  [theme.breakpoints.down('md')]: {
    minWidth: '200px',
  },
}))

const Gradient = styled('img')(({ theme }) => ({
  position: 'absolute',
  bottom: '-40px',
  width: '100%',
  height: '80px',
  padding: '1px',
  background:
    'linear-gradient(90deg, #E9EAFF 0%, #E8F2E7 33.5%, #FFEFE9 67.5%, #C4EDFF 100%)',
  borderRadius: '100% / 90%',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))

const cards = [
  {
    key: 'inventory',
    title: 'label.inventory',
    description: 'label.landing.howItWorks.inventory.description',
    image: inventorySection,
  },
  {
    key: 'events-management',
    title: 'label.landing.howItWorks.events',
    description: 'label.landing.howItWorks.events.description',
    image: managementSection,
  },
  {
    key: 'command-control',
    title: 'label.landing.howItWorks.commandControl',
    description: 'label.landing.howItWorks.commandControl.description',
    image: commandControlSection,
  },
]

function HowItWorks() {
  const intl = useIntl()
  const theme = useTheme()
  const isBigScreen = useMediaQuery(theme.breakpoints.up('md'))
  const isBigMediumScreen = useMediaQuery(theme.breakpoints.up('sm'))
  const titleSize = isBigScreen ? 28 : 22

  if (!isBigScreen) return <React.Fragment />
  return (
    <SectionContainer>
      <StyledText
        fontFamily={FontFamily.NoirProMedium}
        text="label.landing.howItWorks"
        fontSize={titleSize}
      />
      <Gradient />
      <Grid
        container
        alignItems="center"
        justifyContent="space-evenly"
        mt={isBigMediumScreen ? '30px' : '16px'}
      >
        {cards.map((item, index) => (
          <React.Fragment key={item.key}>
            <Grid item key={item.key} xs={12} md={3}>
              <ContainerCard>
                <Image src={item.image} />
                <H3
                  fontFamily={FontFamily.NoirProMedium}
                  style={{ textAlign: 'center', margin: '10px 0 20px 0' }}
                >
                  {index + 1}. {intl.formatMessage({ id: item.title })}
                </H3>
                <BodyTextLight
                  style={{
                    textAlign: 'center',
                    marginTop: 'auto',
                    opacity: '.5',
                  }}
                >
                  {intl.formatMessage({ id: item.description })}
                </BodyTextLight>
              </ContainerCard>
            </Grid>
            {index !== 2 && isBigScreen && (
              <Grid
                item
                key={`${item.key}-arrow`}
                xs={1}
                justifyContent="center"
                display="flex"
              >
                <img alt={intl.formatMessage({ id: item.title })} src={arrow} />
              </Grid>
            )}
          </React.Fragment>
        ))}
      </Grid>
    </SectionContainer>
  )
}

export default HowItWorks
