import React from 'react'
import { Link } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import appTheme from 'assets/styles/theme'
import { Grid } from '@mui/material'
import { Column } from 'components/shared'

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  backgroundColor: theme.palette.background.default,
  justifyContent: 'flex-start',
  minHeight: '100vh',
  overflow: 'hidden',
  flexDirection: 'column',
}))

export const Form = styled('form')(({ theme }) => ({
  width: '100%', // Fix IE 11 issue.
  marginTop: theme.spacing(4),
  maxWidth: '400px',
}))

const linkStyle: React.CSSProperties = {
  marginTop: '7px',
  fontSize: '16px',
  textAlign: 'center',
  textTransform: 'none',
  alignSelf: 'center',
  color: appTheme.palette.text.primary,
}

interface LinkProps {
  to: string
  children: React.ReactNode
}

export function LinkButton(props: LinkProps) {
  const { to, children } = props
  return (
    <Button component={Link} style={linkStyle} to={to}>
      {children}
    </Button>
  )
}

export const ScreenGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  [theme.breakpoints.down('sm')]: {
    margin: '30px 0',
  },
}))

export const LeftGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: '#EAD2D2',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
}))

export const RightGrid = styled(Grid)(({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
}))

export const RocketMan = styled('img')(({ theme }) => ({
  height: '330px',
  objectFit: 'contain',
}))

export const SuccessMessageWrapper = styled(Column)(({ theme }) => ({
  width: '100%',
  maxWidth: '400px',
  alignSelf: 'center',
  justifyContent: 'center',
}))
