import React from 'react'
import { useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { styled, useTheme } from '@mui/material/styles'
import { Severity, withSnackbar } from 'components/providers/SnackbarHOC'
import {
  Column,
  Container,
  Content,
  RowSpaced,
  SectionWrapper,
} from 'components/shared'
import LoadingIndicator from 'components/LoadingIndicator'
import {
  PageGrid,
  PageGridLeft,
  PageGridRight,
  StickySection,
} from 'components/pages'
import { Divider, useMediaQuery } from '@mui/material'
import { BodyText, CaptionText } from 'components/typography'
import { useQuery } from '@apollo/client'
import { CHECKOUT_UPGRADE_DETAILS } from 'gql/subscription'
import {
  CheckoutUpgradeDetailsData,
  CheckoutUpgradeDetailsInput,
} from 'types/subscription'
import StyledText from 'components/StyledIntlText'
import SectionRender from 'components/content/SectionRender'
import { FontFamily } from 'assets'
import { getCurrencyLabel } from 'utils/monetary'
import OrderSummary from './OrderSummary'
import InvoiceItem from './InvoiceItem'

const DesktopGridRight = styled(PageGridRight)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}))

interface Props {
  showSnackbar?: (message: string, severity: Severity) => void
}

function Checkout({ showSnackbar }: Props) {
  const intl = useIntl()
  const theme = useTheme()
  const isBigScreen = useMediaQuery(theme.breakpoints.up('lg'))
  const params = useParams() as {
    subscriptionId: string
    subscriptionPlanId: string
  }

  const { subscriptionId, subscriptionPlanId } = params ?? {}
  const { data, loading } = useQuery<
    CheckoutUpgradeDetailsData,
    CheckoutUpgradeDetailsInput
  >(CHECKOUT_UPGRADE_DETAILS, {
    fetchPolicy: 'network-only',
    variables: { payload: { subscriptionId, subscriptionPlanId } },
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
  })

  const checkoutData = data?.checkoutUpgradeDetails
  const subscriptionPlan = checkoutData?.subscriptionPlan
  const items = checkoutData?.items
  const isLoading = loading
  const refundItems = items?.filter((item) => !!item?.invoiceReference)
  const purchaseItems = items?.filter((item) => !item?.invoiceReference)
  const refundAmount = refundItems?.reduce(
    (sum, item) => item.price.value + sum,
    0
  )
  const purchaseAmount = purchaseItems?.reduce(
    (sum, item) => item.price.value + sum,
    0
  )
  return (
    <Container detailPage>
      <LoadingIndicator visible={isLoading} />
      <Content>
        <PageGrid container spacing={6}>
          <PageGridLeft item xs={12} sm={12} md={12} lg={7} xl={8}>
            <StyledText
              text="label.upgradeToPlan"
              fontSize={20}
              injectedValues={{
                plan: `${subscriptionPlan?.title ?? '-'} ${
                  subscriptionPlan?.duration ?? ''
                }`.trim(),
              }}
            />
            <SectionWrapper>
              <SectionRender
                header={intl.formatMessage({ id: 'label.upgradeInformation' })}
                subHeadline={intl.formatMessage({
                  id: 'label.upgradeBillingReport',
                })}
                entries={[
                  {
                    title: intl.formatMessage({
                      id: 'label.refundedPlan',
                    }),
                    description: intl.formatMessage({
                      id: 'label.upgradeApplicableRefund',
                    }),
                    content: (
                      <Column>
                        {refundItems?.map((item) => (
                          <InvoiceItem
                            relatedPlans={checkoutData?.relatedPlans ?? []}
                            key={`${item?.productReference}-${item?.fromDate}`}
                            item={item}
                          />
                        ))}

                        <Divider style={{ marginTop: '4px' }} />
                        <RowSpaced>
                          <CaptionText>
                            {intl.formatMessage({ id: 'label.total' })}
                          </CaptionText>
                          <BodyText fontFamily={FontFamily.NoirProMedium}>
                            {refundAmount}{' '}
                            {getCurrencyLabel(refundItems?.[0].price.currency)}
                          </BodyText>
                        </RowSpaced>
                      </Column>
                    ),
                  },
                  {
                    title: intl.formatMessage({
                      id: 'label.newSubscriptionPlan',
                    }),
                    description: intl.formatMessage({
                      id: 'label.upgradeNewPurchaseCosts',
                    }),
                    content: (
                      <Column>
                        {purchaseItems?.map((item) => (
                          <InvoiceItem
                            relatedPlans={checkoutData?.relatedPlans ?? []}
                            key={`${item?.productReference}-${item?.fromDate}`}
                            item={item}
                          />
                        ))}

                        <Divider style={{ marginTop: '4px' }} />
                        <RowSpaced>
                          <CaptionText>
                            {intl.formatMessage({ id: 'label.total' })}
                          </CaptionText>
                          <BodyText fontFamily={FontFamily.NoirProMedium}>
                            {purchaseAmount}{' '}
                            {getCurrencyLabel(
                              purchaseItems?.[0].price.currency
                            )}
                          </BodyText>
                        </RowSpaced>
                      </Column>
                    ),
                  },
                ]}
              />

              {!isBigScreen && (
                <SectionWrapper>
                  {checkoutData && (
                    <OrderSummary
                      {...params}
                      checkoutData={checkoutData}
                      showSnackbar={showSnackbar}
                    />
                  )}
                </SectionWrapper>
              )}
            </SectionWrapper>
          </PageGridLeft>
          <DesktopGridRight item xs={12} sm={12} md={12} lg={5} xl={4}>
            <StickySection>
              {checkoutData && (
                <OrderSummary
                  {...params}
                  checkoutData={checkoutData}
                  showSnackbar={showSnackbar}
                />
              )}
            </StickySection>
          </DesktopGridRight>
        </PageGrid>
      </Content>
    </Container>
  )
}

Checkout.defaultProps = {
  showSnackbar: undefined,
}
export default withSnackbar(Checkout)
