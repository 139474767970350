import { SubscriptionExtended, SubscriptionStatus } from 'types/subscription'
import { intervalInDays } from './datetime'
import { OrganizationExtended } from 'types/organization'

/**
 * Method to verify if the provided subscription is eligible to be refunded
 * @param subscription
 * @returns
 */
export const isEligibleForRefund = (
  subscription: SubscriptionExtended,
  countryCode: string
) => {
  const plan = subscription.subscriptionPlan
  const countryPrice = plan.pricing.find(
    (price) => price.countryCode === countryCode
  )

  return (
    subscription.status === SubscriptionStatus.ACTIVE &&
    intervalInDays(subscription.startDate, new Date()) <=
      (countryPrice?.refundDeadline ?? 0)
  )
}

/**
 * Method to check if an organization has active subscriptions that are not the free plan (default plan).
 * @param organization
 * @returns
 */
export const hasActiveSubscription = (organization?: OrganizationExtended) => {
  return organization?.subscriptions.some(
    (sub) =>
      sub.status === SubscriptionStatus.ACTIVE &&
      !sub.subscriptionPlan.defaultPlan
  )
}
