import React from 'react'
import { IntlShape } from 'react-intl'
import { isValidText, isValidEmail } from 'utils/validators'
import { FormDataType, InputType, Type } from 'components/input/types'
import {
  JsonObject, // PublicationStatus
} from 'types/shared'
import ROUTES from 'lib/routes'
import AddIcon from '@mui/icons-material/Add'
import { Link } from 'react-router-dom'
import { SuggestionSection } from 'components/recordDetails/SuggestionsSection'
import {
  ServiceContactPerson,
  ServiceProviderCategory,
  ServiceProviderExtended,
  ServiceProviderRelations,
  ServiceType,
} from 'types/serviceProvider'
import { ContentData } from 'components/recordDetails/ContentSection'
import { Service } from 'types/service'
import { FileType } from 'types/archive'
import { defaultCurrency } from 'lib/constants'
import { SectionEntry } from 'components/content/SectionRender'
import { BodyText } from 'components/typography'
import { ContentSection } from 'components/recordDetails/SidebarCard'
import { Contract } from 'types/contracts'
import { eventsExtraInputs, eventsInputs } from 'utils/input-forms'
import { FontFamily } from 'assets'
import { Theme, styled } from '@mui/material/styles'

export enum Section {
  general = 'general', 
  contact = 'contact',
  // contactPersons = 'contactPersons',
  // contact person fields
  // contactPersonEdit = 'contactPersonEdit',
  // avatar = 'avatar',
  // services = 'services',
  events = 'events',
  eventDocuments = 'eventDocuments',
}

const editingSections = (intl: IntlShape): SuggestionSection[] => [
  {
    id: Section.general,
    title: intl.formatMessage({ id: 'label.general' }),
    description: '',
    cta: '',
  },
  {
    id: Section.contact,
    title: intl.formatMessage({ id: 'label.contactInfo' }),
    description: intl.formatMessage({ id: 'label.addContactInfo' }),
    cta: intl.formatMessage({ id: 'label.add' }),
  },

  // {
  //   id: Section.contactPersons,
  //   title: intl.formatMessage({ id: 'label.contactPersons' }),
  //   description: intl.formatMessage({ id: 'label.addContactPerson' }),
  //   cta: intl.formatMessage({ id: 'label.add' }),
  // },
  // {
  //   id: Section.contactPersonEdit,
  //   title: intl.formatMessage({ id: 'label.contactPerson' }),
  //   description: '',
  //   cta: '',
  // },
  // {
  //   id: Section.services,
  //   title: intl.formatMessage({ id: 'label.services' }),
  //   description: intl.formatMessage({ id: 'label.createService' }),
  //   cta: intl.formatMessage({ id: 'label.add' }),
  // },
  // {
  //   id: Section.avatar,
  //   title: intl.formatMessage({ id: 'label.addProfilePicture' }),
  //   description: '',
  //   cta: '',
  // },
  {
    id: Section.events,
    title: intl.formatMessage({ id: 'label.events' }),
    description: intl.formatMessage({ id: 'label.eventsDescription' }),
    cta: intl.formatMessage({ id: 'label.add' }),
  },
  {
    id: Section.eventDocuments,
    title: intl.formatMessage({ id: 'label.addDocuments' }),
    description: '',
    cta: '',
  },
]

export const hasContactInfo = (record: ServiceProviderExtended) =>
  record.contact?.email || record.contact?.phoneNumber

export const hasEvents = (record: ServiceProviderExtended) => {
  const { events } = record
  return events && events.length > 0
}

export const getSectionWithId = (id: string, intl: IntlShape) => {
  return editingSections(intl).find((section) => section.id === id)
}

// const createContactPersonInputs: InputType[] = [
//   {
//     key: 'firstName',
//     label: 'label.firstName',
//     type: Type.TEXT,
//     autoComplete: 'given-name',
//     autoFocus: false,
//     margin: 'dense',
//     fullWidth: true,
//     validators: [
//       {
//         method: isValidText,
//         label: 'label.required',
//       },
//     ],
//   },
//   {
//     key: 'lastName',
//     label: 'label.lastName',
//     type: Type.TEXT,
//     autoComplete: 'family-name',
//     autoFocus: false,
//     margin: 'dense',
//     fullWidth: true,
//     validators: [],
//   },
//   {
//     key: 'email',
//     label: 'label.emailAddress',
//     type: Type.EMAIL,
//     autoComplete: 'email',
//     autoFocus: false,
//     margin: 'dense',
//     fullWidth: true,
//     validators: [
//       {
//         method: isValidEmail,
//         label: 'label.invalidEmail',
//       },
//     ],
//   },
//   {
//     key: 'phoneNumber',
//     label: 'label.phone',
//     type: Type.TEXT,
//     autoComplete: '',
//     autoFocus: false,
//     margin: 'dense',
//     fullWidth: true,
//     validators: [],
//   },
// ]

// const avatarPhotoInputs: InputType[] = [
//   {
//     key: 'avatarId',
//     label: 'label.addProfilePicture',
//     type: Type.DROPZONE,
//     margin: 'dense',
//     fullWidth: true,
//     validators: [],
//   },
// ]

const generalSectionInputs = (
  categories: ServiceProviderCategory[],
  isBusinessAccount: boolean
): InputType[] => [
  {
    key: 'title',
    label: 'label.title',
    type: Type.TEXT,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    validators: [
      {
        method: isValidText,
        label: 'label.required',
      },
    ],
  },

  {
    key: 'description',
    label: 'label.description',
    type: Type.TEXT,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    multiline: true,
    validators: [],
  },
  {
    key: 'link',
    label: 'label.website',
    type: Type.TEXT,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    validators: [],
  },
  {
    key: 'categoryId',
    label: 'label.category',
    type: Type.AUTOCOMPLETE,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    options:
      categories.map((input) => ({
        value: input.id,
        label: input.title,
      })) || [],
    validators: [],
  },
  ...extraGeneralInput(isBusinessAccount),
]

const extraGeneralInput = (isBusinessAccount: boolean): InputType[] =>
  isBusinessAccount
    ? [
        {
          key: 'public',
          label: 'label.isPublic',
          type: Type.CHECKBOX,
          autoComplete: '',
          autoFocus: false,
          margin: 'dense',
          fullWidth: true,
          validators: [],
        },
      ]
    : []

const contactInputs: InputType[] = [
  {
    key: 'email',
    label: 'label.email',
    type: Type.EMAIL,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    validators: [
      {
        method: isValidEmail,
        label: 'label.invalidEmail',
      },
    ],
  },
  {
    key: 'phoneNumber',
    label: 'label.phone',
    type: Type.TEXT,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    validators: [],
  },
]

// const serviceSectionInputs = (types: ServiceType[]): InputType[] => [
//   {
//     key: 'title',
//     label: 'label.title',
//     type: Type.TEXT,
//     autoComplete: '',
//     autoFocus: false,
//     margin: 'dense',
//     fullWidth: true,
//     validators: [
//       {
//         method: isValidText,
//         label: 'label.required',
//       },
//     ],
//   },
//   {
//     key: 'description',
//     label: 'label.description',
//     type: Type.TEXT,
//     autoComplete: '',
//     autoFocus: false,
//     margin: 'dense',
//     fullWidth: true,
//     multiline: true,
//     validators: [],
//   },
//   {
//     key: 'typeId',
//     label: 'label.types',
//     type: Type.AUTOCOMPLETE,
//     autoComplete: '',
//     autoFocus: false,
//     margin: 'dense',
//     fullWidth: true,
//     options:
//       types.map((input) => ({
//         value: input.id,
//         label: input.title,
//       })) || [],
//     validators: [],
//   },
//   {
//     key: 'status',
//     label: 'label.status',
//     type: Type.SINGLE_SELECT,
//     autoComplete: '',
//     autoFocus: false,
//     margin: 'dense',
//     fullWidth: true,
//     options:
//       Object.values(PublicationStatus).map((input) => ({
//         value: input,
//         label: input,
//       })) || [],
//     validators: [],
//   },
// ]

// const saleServiceInitialState = (services: Service[], recordID?: string) => {
//   const record = services?.find((i) => i.id === recordID)
//   return {
//     title: record?.title || '',
//     description: record?.description || '',
//     typeId: record?.typeId || null,
//     status: record?.status || PublicationStatus.DRAFT,
//   }
// }
const generalInitialState = (
  record: ServiceProviderExtended
): FormDataType => ({
  title: record.title || '',
  description: record.description || '',
  link: record.link || '',
  public: record.public,
  categoryId: record.categoryId || null,
})
const generalContactState = (
  record: ServiceProviderExtended
): FormDataType => ({
  email: record.contact?.email || '',
  phoneNumber: record.contact?.phoneNumber || '',
})

// const contactPersonInitialState = (
//   record: ServiceContactPerson
// ): FormDataType => ({
//   firstName: record.firstName || '',
//   lastName: record.lastName || '',
//   email: record.email || '',
//   phoneNumber: record.phoneNumber || '',
// })

const eventArchiveFiles = (fileType: FileType): InputType[] => [
  {
    key: 'archive',
    label: 'label.addFiles',
    type: Type.DROPZONE,
    margin: 'dense',
    fullWidth: true,
    validators: [],
    fileType,
  },
]

export const getEditInputs = (
  editId: string,
  types?: ServiceType[],
  categories?: ServiceProviderCategory[],
  isBusinessAccount?: boolean,
  contracts?: Contract[]
) => {
  switch (editId) {
    case Section.general:
      return categories
        ? generalSectionInputs(categories, !!isBusinessAccount)
        : []
    case Section.contact:
      return contactInputs
    // case Section.contactPersons:
    //   return createContactPersonInputs
    // case Section.contactPersonEdit:
    //   return createContactPersonInputs
    // case Section.avatar:
    //   return avatarPhotoInputs
    // case Section.services:
    //   return types && serviceSectionInputs(types)
    case Section.events:
      return eventsInputs(undefined, contracts)
    case Section.eventDocuments:
      return eventArchiveFiles(FileType.document)
    default:
      return []
  }
}

export const getExtraInputs = (editId: string) => {
  switch (editId) {
    case Section.events:
      return eventsExtraInputs
    default:
      return []
  }
}

export const getInitialStateContact = (
  record: ServiceContactPerson,
  sectionId: string,
  recordId?: string // id of the sub-entity being edited, e.g. item sales info
): FormDataType => {
  switch (sectionId) {
    // case Section.contactPersonEdit:
    //   return contactPersonInitialState(record)
    default:
      return {}
  }
}

const eventInitialState = (
  record: ServiceProviderExtended,
  eventId?: string
) => {
  const event = record.events?.find((e) => e.id === eventId)
  return {
    date: event?.date ? new Date(event?.date) : null,
    contractId: event?.contractId || null,
    title: event?.title || '',
    description: event?.description || '',
    price: event?.amount?.value?.toString() || null,
    currency: event?.amount?.currency || defaultCurrency,
  }
}

export const getInitialState = (
  record: ServiceProviderExtended,
  sectionId: string,
  services?: Service[],
  recordId?: string // id of the sub-entity being edited, e.g. item sales info
): FormDataType => {
  switch (sectionId) {
    case Section.general:
      return generalInitialState(record)
    case Section.events:
      return eventInitialState(record, recordId)
    case Section.eventDocuments:
      return {
        archive: undefined,
      }
    // case Section.services:
    //   return (services && saleServiceInitialState(services, recordId)) ?? {}
    case Section.contact:
      return generalContactState(record)
    default:
      return {}
  }
}

export const payloadBuilder = (
  formData: JsonObject,
  recordId: string,
  sectionId?: string
) => {
  let payloadData: JsonObject = {}
  if (sectionId)
    switch (sectionId) {
      case Section.general:
        payloadData = {
          title: formData.title,
          description: formData.description,
          link: formData.link,
          public: formData.public,
          categoryId: formData.categoryId,
        }
        break

      // case Section.contactPersons:
      //   payloadData = formData
      //   break
      // case Section.contactPersonEdit:
      // case Section.avatar:
      //   payloadData = {
      //     ...formData,
      //   }
      //   break

      default:
        payloadData = { [sectionId]: formData } // 1:1 mapping of section to payload
    }

  const payload = {
    id: recordId,
    data: payloadData,
  }
  return payload
}

export const getContactSectionContent = (
  record: ServiceProviderExtended,
  intl: IntlShape
) => {
  const { contact } = record
  const data: ContentData[] = [
    {
      label: intl.formatMessage({ id: 'label.email' }),
      value: contact?.email ?? '-',
    },
    {
      label: intl.formatMessage({ id: 'label.phone' }),
      value: contact?.phoneNumber ?? '-',
    },
  ]

  return data
}

const LinkComp = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  marginBottom: '6px',
  fontSize: '15px',
  ':active': {
    color: theme.palette.primary.main,
  },
}))

export const getRelationsSectionContent = (
  theme: Theme,
  intl: IntlShape,
  workspaceId: string,
  relations: ServiceProviderRelations | undefined
) => {
  const data: SectionEntry[] = []

  if ((relations?.contracts?.length ?? 0) > 0) {
    data.push({
      description: intl.formatMessage({ id: 'label.contracts' }),
      data: relations?.contracts.map((i, index: number) => ({
        label: (
          <BodyText
            fontFamily={FontFamily.NoirPro}
            color={theme.palette.text.secondary}
          >
            <LinkComp
              to={ROUTES.DASHBOARD_CONTRACT_DETAILS_ROUTE(workspaceId, i.id)}
            >
              • {i.title}
            </LinkComp>
          </BodyText>
        ),
        value: '',
      })),
    })
  }

  if ((relations?.itemManufacturers?.length ?? 0) > 0) {
    data.push({
      description: intl.formatMessage({ id: 'label.manufacturerOf' }),
      data: relations?.itemManufacturers.map((i) => ({
        label: (
          <BodyText
            fontFamily={FontFamily.NoirPro}
            color={theme.palette.text.secondary}
          >
            <LinkComp
              to={ROUTES.DASHBOARD_ITEM_DETAILS_ROUTE(workspaceId, i.item.id)}
            >
              • {i.item.title}
            </LinkComp>
          </BodyText>
        ),
        value: '',
      })),
    })
  }

  // NOTE: not accessible on V1
  if ((relations?.itemListings?.length ?? 0) > 0) {
    data.push({
      description: intl.formatMessage({ id: 'label.productsListedHere' }),
      data: relations?.itemListings.map((i) => ({
        label: (
          <BodyText
            fontFamily={FontFamily.NoirPro}
            color={theme.palette.text.secondary}
          >
            <LinkComp
              to={ROUTES.DASHBOARD_ITEM_DETAILS_ROUTE(workspaceId, i.item.id)}
            >
              • {i.item.title}
            </LinkComp>
          </BodyText>
        ),
        value: '',
      })),
    })
  }

  return data
}

export const getServiceContent = (record: Service, intl: IntlShape) => {
  const data: ContentData[] = []

  if (record?.title) {
    data.push({
      label: intl.formatMessage({ id: 'label.title' }),
      value: record.title,
    })
  }

  if (record?.description) {
    data.push({
      label: intl.formatMessage({ id: 'label.description' }),
      value: record.description,
    })
  }

  if (record?.status) {
    data.push({
      label: intl.formatMessage({ id: 'label.status' }),
      value: record.status,
    })
  }
  return data
}

export function hasRelations(relations?: ServiceProviderRelations) {
  return !!(
    // EVENTS and ORDERS are displayed separately
    // (relations?.events.length ?? 0) > 0 ||
    (
      (relations?.itemListings.length ?? 0) ||
      (relations?.itemManufacturers.length ?? 0) ||
      // (relations?.orders.length ?? 0) ||
      (relations?.contracts.length ?? 0)
    )
  )
}

interface Methods {
  addEvent: () => void
  addContract: () => void
}
export const relationsContent = (
  intl: IntlShape,
  data: {
    record?: ServiceProviderExtended
    relatedContracts?: Contract[]
  },
  methods: Methods
): ContentSection[] => {
  const { record, relatedContracts } = data

  return [
    {
      title: intl.formatMessage({ id: 'label.events' }),
      description: intl.formatMessage(
        { id: 'label.eventsCount' },
        { number: record?.events?.length ?? 0 }
      ),
      action: {
        ariaLabel: 'related events',
        icon: () => <AddIcon color="disabled" />,
        onPress: methods.addEvent,
      },
    },
    {
      title: intl.formatMessage({ id: 'label.contracts' }),
      description: intl.formatMessage(
        { id: 'label.contractsCount' },
        { number: relatedContracts?.length ?? 0 }
      ),
      action: {
        ariaLabel: 'related contracts',
        icon: () => <AddIcon color="disabled" />,
        onPress: methods.addContract,
      },
    },
  ]
}
