import { gql } from '@apollo/client'

export const CREATE_ADDRESS = gql`
  mutation CREATE_ADDRESS($payload: AddressInput!) {
    createAddress(payload: $payload) {
      id
    }
  }
`

export const UPDATE_ADDRESS = gql`
  mutation UPDATE_ADDRESS($payload: UpdateAddressInput!) {
    updateAddress(payload: $payload) {
      id
    }
  }
`
