import React, { CSSProperties } from 'react'
import { Link } from 'react-router-dom'
import { styled, Theme } from '@mui/material/styles'
import MuiButton, { ButtonProps } from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { TransitionProps } from '@mui/material/transitions'
import Slide from '@mui/material/Slide'
import MuiMasonry from '@mui/lab/Masonry'
import { Box } from '@mui/material'

type StyledButtonProps = ButtonProps & {
  component?: typeof Link
  to?: string
  bgColor?: string
}

export const Button = styled(MuiButton, {
  shouldForwardProp: (prop) => prop !== 'bgColor',
})<{
  theme?: Theme
  bgColor?: string
}>(({ theme, bgColor }) => ({
  fontSize: '16px',
  lineHeight: '97.5%',
  borderRadius: '3px',
  gap: '6px',
  padding: '16px 36px',
  fontFamily: 'NoirPro',
  color: theme.palette.secondary.main,
  backgroundColor: bgColor ?? theme.palette.action.active,
  ':hover': {
    background: bgColor ?? theme.palette.action.active,
  },
})) as React.ComponentType<StyledButtonProps>

export const SimpleButton = styled(Button)(({ theme }) => ({
  boxShadow: 'none',
}))

export const SectionCTA = styled(MuiButton)(({ theme }) => ({
  fontSize: '18px',
  fontFamily: 'NoirPro',
  lineHeight: '21.6px',
  letterSpacing: '0em',
  fontWeight: 400,
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '16px',
  },
}))

export const ModalTitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'NoirPro',
  fontWeight: 400,
  fontSize: '20px',
  color: `${theme.palette.text.primary}`,
}))

export const ModalSubtitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Open Sans',
  fontWeight: 400,
  fontSize: '14px',
  color: `${theme.palette.text.primary}`,
}))

export const Container = styled('div', {
  shouldForwardProp: (prop) => prop !== 'detailPage',
})<{
  theme?: Theme
  detailPage?: boolean
}>(({ theme, detailPage }) => ({
  display: 'flex',
  minHeight: '100vh',
  padding: '0 40px',
  flexDirection: 'column',
  minWidth: '60vw',
  backgroundColor: detailPage
    ? theme.palette.shared.lightGray
    : theme.palette.background.default,
  [theme.breakpoints.down('md')]: {
    marginBottom: '60px',
    padding: '0 16px',
  },
}))

export const Content = styled('div', {
  shouldForwardProp: (prop) => prop !== 'detailPage',
})<{
  theme?: Theme
  detailPage?: boolean
}>(({ theme, detailPage }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  marginBottom: '64px',
  ...(!detailPage && {
    paddingTop: '54px',
    [theme.breakpoints.down('md')]: {
      paddingTop: '30px',
    },
  }),
}))

export const Row = styled('div', {
  shouldForwardProp: (prop) => prop !== 'flex',
})<{
  flex?: boolean
  theme?: Theme
  flexWrap?: CSSProperties['flexWrap']
}>(({ theme, flex, flexWrap }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  flexWrap: flexWrap ?? 'wrap',
  ...(flex ? { flex: 1 } : {}),
}))

export const Column = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  flex: 1,
}))

export const RowSpaced = styled(Row)(({ theme }) => ({
  justifyContent: 'space-between',
  alignItems: 'center',
}))

export const DangerRowSpaced = styled(RowSpaced)(({ theme }) => ({
  marginTop: '10px',
}))

export const GridWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  position: 'relative',
  justifyContent: 'center',
  marginTop: '40px',
}))

// eslint-disable-next-line
export const ModalTransition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export const SectionLabel = styled('span')(({ theme }) => ({
  fontFamily: 'NoirPro',
  fontSize: '16px',
  color: theme.palette.text.primary,
}))

export const DangerSectionLabel = styled(SectionLabel)(({ theme }) => ({
  color: theme.palette.error.main,
}))

export const Masonry = styled(MuiMasonry)(({ theme }) => ({
  marginTop: '24px',
}))

export const SectionWrapper = styled('div')(({ theme }) => ({
  marginTop: '60px',
  [theme.breakpoints.down('sm')]: {
    marginTop: '30px',
  },
}))

// Spacers
export const Spacer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'flex',
})<{
  size?: 'small' | 'default' | 'large'
  theme?: Theme
}>(({ theme, size = 'default' }) => ({
  marginTop: `${getSpacing(size)}px`,
}))

function getSpacing(size?: 'small' | 'default' | 'large') {
  switch (size) {
    case 'small':
      return 10
    case 'default':
      return 20
    case 'large':
      return 30
    default:
      // normal
      return 20
  }
}
