import { styled, Theme } from '@mui/material/styles'
import MuiIconButton from '@mui/material/IconButton'
import { RowSpaced } from 'components/shared'
import PushPinIcon from '@mui/icons-material/PushPin'
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined'
import Chip from '@mui/material/Chip'
import { BodyText, H4 } from './typography'

export const CardActionBar = styled(RowSpaced)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  left: 0,
}))

export const PinIcon = styled(PushPinIcon)(({ theme }) => ({
  rotate: '45deg',
  color: theme.palette.primary.main,
}))

export const PinOutlinedIcon = styled(PushPinOutlinedIcon)(({ theme }) => ({
  rotate: '45deg',
  color: theme.palette.text.secondary,
}))

export const IconButton = styled(MuiIconButton)(({ theme }) => ({
  margin: '6px',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '2px',
  padding: '5px',
  ':hover': {
    backgroundColor: theme.palette.background.paper,
  },
}))

export const CardTitle = styled(H4, {
  shouldForwardProp: (prop) => prop !== 'numberOfLines',
})<{
  theme?: Theme
  numberOfLines?: number
}>(({ numberOfLines }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: numberOfLines ?? 1,
  lineClamp: numberOfLines ?? 1,
  WebkitBoxOrient: 'vertical',
}))

export const ContentText = styled(BodyText, {
  shouldForwardProp: (prop) => prop !== 'numberOfLines',
})<{
  theme?: Theme
  color?: string
  numberOfLines?: number
}>(({ theme, color, numberOfLines }) => ({
  color: color ?? theme.palette.text.secondary,
  marginTop: '4px',
  ...(numberOfLines && {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: numberOfLines,
    lineClamp: numberOfLines,
    WebkitBoxOrient: 'vertical',
  }),
}))

export const StatusChip = styled(Chip)(({ theme }) => ({
  fontFamily: 'NoirPro-Medium',
  fontSize: '14px',
  lineHeigh: '18px',
  height: '20px',
  '& .MuiChip-label': {
    padding: '0 8px',
  },
}))
