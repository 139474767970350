import React from 'react'
import { styled } from '@mui/material/styles'
import { useParams, useNavigate } from 'react-router-dom'
import Card from '@mui/material/Card'
import { FileType } from 'types/archive'
import ROUTES from 'lib/routes'
import { useIntl } from 'react-intl'
import { ContainerExtended } from 'types/container'
import appConfig from 'config/app'
import placeholder from 'assets/images/placeholderContainer.png'
import placeholderFull from 'assets/images/placeholderContainerFull.png'
import { cardBoxShadow, cardBoxShadowHover } from 'assets/styles/theme'
import { CardTitle, ContentText } from './cards'
import { Row, RowSpaced } from './shared'
import UserBadge from './user/memberSelect/UserBadge'

const ContainerCard = styled(Card)({
  position: 'relative',
  padding: '0px',
  borderRadius: '4px',
  boxShadow: cardBoxShadow,
  ':hover': {
    cursor: 'pointer',
    boxShadow: cardBoxShadowHover,
  },
})

const Content = styled('div')({
  display: 'flex',
  flex: 1,
  justifyContent: 'flex-end',
  flexDirection: 'column',
  pointerEvents: 'none',
  padding: '8px 12px',
})

const Image = styled('img')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '165px',
  objectFit: 'cover',
}))

function getPlaceHolder(filledContainer: boolean) {
  return filledContainer ? placeholderFull : placeholder
}

interface Props {
  record: ContainerExtended
  onPress?: (item: ContainerExtended) => void
}

function BoxCard({ record, onPress }: Props) {
  const navigate = useNavigate()
  const intl = useIntl()
  const params = useParams() as { workspaceId: string }
  const coverMedia = record.archive?.find((i) => i.type === FileType.image)
  const filledContainer = (record?.itemCount ?? 0) > 0
  const cover = coverMedia?.media?.thumbnail
    ? `${appConfig.media}${coverMedia?.media?.thumbnail}`
    : getPlaceHolder(filledContainer)

  const handleClick = () =>
    typeof onPress === 'function'
      ? onPress(record)
      : navigate(
          ROUTES.DASHBOARD_CONTAINER_DETAILS_ROUTE(
            params.workspaceId,
            record.id
          )
        )

  const boxContent = []
  record.itemCount &&
    boxContent.push(
      intl.formatMessage(
        { id: 'label.countItems' },
        { number: record.itemCount }
      )
    )
  // todo: serviceCount will be added in the future
  record.serviceCount &&
    boxContent.push(
      intl.formatMessage(
        { id: 'label.countServices' },
        { number: record.serviceCount }
      )
    )

  return (
    <ContainerCard onClick={handleClick}>
      <Image src={cover} alt={record.title} />
      <Content>
        <CardTitle>{record.title}</CardTitle>
        <RowSpaced>
          <ContentText>
            {boxContent.join(' · ') ||
              intl.formatMessage({ id: 'label.emptyContainer' })}
          </ContentText>
          <Row>
            {record.members?.map((member) => (
              <UserBadge
                key={member.id}
                user={member}
                size={24}
                navigate={navigate}
              />
            ))}
          </Row>
        </RowSpaced>
      </Content>
    </ContainerCard>
  )
}

BoxCard.defaultProps = {
  onPress: undefined,
}
export default BoxCard
