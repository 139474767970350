import React, { useEffect } from 'react'
import { Box, Card, Radio } from '@mui/material'
import { styled } from '@mui/material/styles'
import StyledText from 'components/StyledIntlText'
import { CaptionText, H2, H3, H4 } from 'components/typography'
import { getAuth } from 'lib/localStorage'
import ROUTES from 'lib/routes'
import { useIntl } from 'react-intl'
import { CheckoutDetailsData } from 'types/subscription'
import { cardBoxShadow, cardBoxShadowHover } from 'assets/styles/theme'
import { Column, Row, RowSpaced } from 'components/shared'

export const LogoAppendix = styled(H2)(({ theme }) => ({
  margin: 'auto 0 5px 5px',
  color: theme.palette.text.primary,
}))

interface AccountSectionProps {
  organizations?: CheckoutDetailsData['checkoutDetails']['organizations']
  // initially selected organization id
  selectedRecordId?: string
  onOrganizationChange: (recordId: string) => void
}
export function AccountSection({
  organizations,
  selectedRecordId,
  onOrganizationChange,
}: AccountSectionProps) {
  const intl = useIntl()
  const auth = getAuth()
  const isAuthenticated = !!auth?.token
  const [selectedId, setSelectedId] = React.useState<string | undefined>(
    selectedRecordId
  )
  useEffect(() => setSelectedId(selectedRecordId), [selectedRecordId])
  const onOrganizationSelect = (recordId: string) => {
    setSelectedId(recordId)
    onOrganizationChange(recordId)
  }
  return (
    <div>
      {isAuthenticated ? (
        <React.Fragment>
          <H3>
            1. {intl.formatMessage({ id: 'label.checkoutSelectAccount' })}
          </H3>
          <Row style={{ marginTop: '16px' }}>
            {organizations?.map((record) => (
              <OrganizationCard
                selectedId={selectedId}
                key={record.id}
                record={record}
                onClick={onOrganizationSelect}
              />
            ))}
          </Row>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <H3>
            1. {intl.formatMessage({ id: 'label.checkoutCreateAccount' })}
          </H3>
          <Box height={10} />
          <StyledText
            text="label.checkoutRegisterInfo"
            fontSize={14}
            injectedValues={{ link: ROUTES.REGISTER }}
          />
          <br />
          <StyledText
            text="label.checkoutLoginInfo"
            fontSize={14}
            injectedValues={{ link: ROUTES.LOGIN }}
          />
        </React.Fragment>
      )}
    </div>
  )
}

AccountSection.defaultProps = {
  organizations: [],
  selectedRecordId: undefined,
}

const ContainerCard = styled(Card)({
  padding: '12px',
  gap: '8px',
  borderRadius: '4px',
  margin: '0 16px 16px 0',
  position: 'relative',
  boxShadow: cardBoxShadow,
  ':hover': {
    cursor: 'pointer',
    boxShadow: cardBoxShadowHover,
  },
})

interface OrganizationCardProps {
  selectedId?: string
  record: CheckoutDetailsData['checkoutDetails']['organizations'][number]
  onClick: (recordId: string) => void
}
function OrganizationCard({
  record,
  selectedId,
  onClick,
}: OrganizationCardProps) {
  const ownerName = `${record.owner.firstName} ${record.owner.lastName}`.trim()
  const intl = useIntl()
  const handleClick = () => onClick(record.id)
  return (
    <ContainerCard onClick={handleClick}>
      <RowSpaced style={{ marginRight: '30px' }}>
        <H4>{record.company?.name ?? ownerName ? ownerName : '-'}</H4>
      </RowSpaced>
      <CaptionText>
        {intl.formatMessage({ id: 'label.accountType' })}:{' '}
        <CaptionText bold>
          {intl.formatMessage({ id: `organization.type.${record.type}` })}
        </CaptionText>
      </CaptionText>
      <Radio
        sx={{ position: 'absolute', right: '0', top: '0' }}
        checked={selectedId === record.id}
        value={record.id}
        name="radio-buttons"
        size="small"
      />
    </ContainerCard>
  )
}
OrganizationCard.defaultProps = {
  selectedId: null,
}

export const MoneyBackBadge = styled(Column)(({ theme }) => ({
  flex: 0,
  marginRight: '8px',
  backgroundColor: theme.palette.shared.red,
  padding: '8px 14px',
  borderRadius: '100px',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: 'auto',
}))
