import React from 'react'
import { IntlShape } from 'react-intl'
import { Severity } from 'components/providers/SnackbarHOC'
import AddIcon from '@mui/icons-material/Add'
import { TabSection } from 'components/Tabs'
import DangerSectionRender from 'components/content/DangerSectionRender'
import SectionRender from 'components/content/SectionRender'
import TrashIcon from 'assets/svg/Trash'
import { Masonry } from 'components/shared'
import { min, max } from 'lodash'
import ItemCard from 'components/ItemCard'
import { ContainerExtended } from 'types/container'
import { Section } from './constants'

const tabs = (
  intl: IntlShape,
  data: {
    record?: ContainerExtended
  },
  methods: {
    handleOnEditWithId: (sectionId: Section, recordId?: string) => void
    showSnackbar?: (message: string, severity: Severity) => void
    toggleDelete: () => void
    toggleItemSelect: () => void
  }
): TabSection[] => {
  const { toggleItemSelect, toggleDelete } = methods
  const { record } = data
  const containerItems = record?.items ?? []

  return [
    {
      key: 'container-items',
      label: 'label.items',
      visible: containerItems.length > 0,
      content: (
        <SectionRender
          header={intl.formatMessage({ id: 'label.items' })}
          headerActions={[
            {
              ariaLabel: 'add item',
              icon: (props) => <AddIcon color="disabled" />,
              onPress: toggleItemSelect,
            },
          ]}
        >
          <Masonry
            spacing={2}
            columns={{
              xs: 2,
              sm: min([2, max([containerItems.length, 1])]) as number,
              lg: min([3, max([containerItems.length, 2])]) as number,
            }}
          >
            {containerItems.map((item) => (
              <ItemCard item={item} key={item.id} />
            ))}
          </Masonry>
        </SectionRender>
      ),
    },
    {
      key: 'danger-zone',
      label: 'label.dangerZone',
      visible: !!record && !record.deletedAt,
      content: record ? (
        <DangerSectionRender
          entries={[
            {
              title: intl.formatMessage({ id: 'label.dangerZone' }),
              actions: [
                {
                  ariaLabel: 'delete container',
                  icon: (props) => <TrashIcon {...props} />,
                  onPress: toggleDelete,
                  label: intl.formatMessage({ id: 'label.delete' }),
                  infoLabel: intl.formatMessage({
                    id: 'label.deleteContainer',
                  }),
                },
              ],
            },
          ]}
        />
      ) : null,
    },
  ].filter((section) => section.visible)
}

export default tabs
