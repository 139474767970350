import { useMutation, useQuery } from '@apollo/client'
import { Severity } from 'components/providers/SnackbarHOC'
import {
  DELETE_GROUPS,
  GROUP_BY_ID,
  GROUP_ITEMS,
  GROUP_TYPES_COUNTER,
  UPDATE_GROUP,
} from 'gql/group'
import { WORKSPACE_INVENTORY } from 'gql/inventory'
import { UPDATE_GROUP_ITEM_REFERENCE } from 'gql/item'
import ROUTES from 'lib/routes'
import { NavigateFunction } from 'react-router-dom'
import {
  DeleteGroupsData,
  DeleteGroupsInput,
  GroupByIdData,
  GroupItemsData,
  UpdateGroupData,
  UpdateGroupInput,
} from 'types/group'
import { UpdateGroupItemRefsData } from 'types/item'

export function useData(
  groupId: string,
  methods: {
    showSnackbar?: (message: string, severity: Severity) => void
  }
) {
  const { data: groupData, loading: groupLoading } = useQuery<GroupByIdData>(
    GROUP_BY_ID,
    {
      variables: { groupId },
      fetchPolicy: 'cache-and-network',
      onError: (error) => methods.showSnackbar?.(error.message, Severity.ERROR),
    }
  )

  const {
    data: itemsData,
    loading: itemsLoading,
    refetch: refetchItems,
  } = useQuery<GroupItemsData>(GROUP_ITEMS, {
    variables: { groupId },
    fetchPolicy: 'network-only',
  })
  const groupItems = itemsData?.groupItems ?? []
  const record = groupData?.groupById

  const loading = groupLoading || itemsLoading
  return { loading, data: { record, groupItems }, refetch: { refetchItems } }
}

export function useMutations(
  payload: {
    groupId: string
    workspaceId: string
    navigate: NavigateFunction
  },
  methods: {
    showSnackbar?: (message: string, severity: Severity) => void
    hideEditingModal: () => void
    toggleItemSelect: () => void
  }
) {
  const { showSnackbar, hideEditingModal, toggleItemSelect } = methods
  const [updateGroupItemRefs, { loading: updating }] =
    useMutation<UpdateGroupItemRefsData>(UPDATE_GROUP_ITEM_REFERENCE, {
      onCompleted: toggleItemSelect,
      onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
      refetchQueries: [
        { query: GROUP_ITEMS, variables: { groupId: payload.groupId } },
      ],
    })

  const [updateGroup] = useMutation<UpdateGroupData, UpdateGroupInput>(
    UPDATE_GROUP,
    {
      onCompleted: hideEditingModal,
      onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
      refetchQueries: [
        { query: GROUP_BY_ID, variables: { groupId: payload.groupId } },
      ],
    }
  )

  const [deleteGroups, { loading: deletingGroups }] = useMutation<
    DeleteGroupsData,
    DeleteGroupsInput
  >(DELETE_GROUPS, {
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    onCompleted: () =>
      payload.navigate(ROUTES.DASHBOARD_OVERVIEW_ROUTE(payload.workspaceId), {
        replace: true,
      }),
    refetchQueries: [
      {
        query: WORKSPACE_INVENTORY,
        variables: { payload: { workspaceId: payload.workspaceId } },
      },
      {
        query: GROUP_TYPES_COUNTER,
        variables: { workspaceId: payload.workspaceId },
      },
    ],
  })
  const loading = updating || deletingGroups
  return {
    loading,
    mutations: {
      updateGroup,
      deleteGroups,
      updateGroupItemRefs,
    },
  }
}
