import { gql } from '@apollo/client'

export const REQUEST_EMAIL_CONFIRMATION = gql`
  query REQUEST_EMAIL_CONFIRMATION($email: String!) {
    requestEmailConfirmation(email: $email) {
      status
    }
  }
`

export const REQUEST_WORKSPACE_MEMBERSHIP_EMAIL = gql`
  query REQUEST_WORKSPACE_MEMBERSHIP_EMAIL($membershipId: ID!) {
    requestWorkspaceMembershipEmail(membershipId: $membershipId) {
      status
    }
  }
`

export const CONFIRM_WORKSPACE_INVITATION = gql`
  mutation CONFIRM_WORKSPACE_INVITATION($token: String!) {
    confirmWorkspaceInvitation(token: $token) {
      status
    }
  }
`

export const REQUEST_PASSWORD_RESET = gql`
  query REQUEST_PASSWORD_RESET($email: String!) {
    requestPasswordReset(email: $email) {
      status
    }
  }
`

export const RESET_PASSWORD = gql`
  mutation RESET_PASSWORD($payload: ResetPasswordInput!) {
    resetPassword(payload: $payload) {
      status
    }
  }
`
