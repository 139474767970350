import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Theme, styled } from '@mui/material/styles'
import { useTheme } from '@mui/material/styles'
import Button, { ButtonProps } from '@mui/material/Button'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import { useIntl } from 'react-intl'
import { Avatar } from 'components/user'
import appConfig from 'config/app'
import { useLazyQuery } from '@apollo/client'
import { ProfileData } from 'types/profile'
import { PROFILE } from 'gql/user'
import InsightsIcon from '@mui/icons-material/Insights'
import { getAuth } from 'lib/localStorage'
import { FontFamily } from 'assets'
import { H6 } from 'components/typography'
import { useMediaQuery } from '@mui/material'
import ROUTES from '../../lib/routes'
import { logoFull } from 'assets/images'
import LanguageSelector from 'components/LanguageSelector'

const ToolbarComponent = styled(Toolbar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
}))

const LogoImg = styled('img')(({ theme }) => ({
  height: '44px',
  objectFit: 'contain',
  marginTop: '8px',
}))

const Spacer = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1',
  flexDirection: 'row',
}))

const HeaderAppBar = styled(AppBar)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.action.active}33`,
}))

type StyledButtonProps = ButtonProps & {
  component?: typeof Link
  to?: string
  bgColor?: string
  textColor?: string
}

export const HeaderButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'textColor',
})<{
  theme?: Theme
  textColor?: string
  bgColor?: string
}>(({ theme, textColor, bgColor }) => ({
  borderRadius: '5px',
  fontFamily: FontFamily.Blinker,
  fontWeight: 600,
  marginLeft: '16px',
  borderColor: 'primary.main',
  color: textColor,
  backgroundColor: bgColor,
  ':hover': {
    borderColor: 'primary.main',
    backgroundColor: bgColor,
  },
})) as React.ComponentType<StyledButtonProps>

interface Props {
  LogoAppendix?: React.ElementType
}
function AppHeader({ LogoAppendix }: Props) {
  const intl = useIntl()
  const theme = useTheme()
  const navigate = useNavigate()
  const auth = getAuth()
  const isBigScreen = useMediaQuery(theme.breakpoints.up('sm'))
  const [fetchProfile, { data }] = useLazyQuery<ProfileData>(PROFILE)
  const profile = data?.profile
  const isAuthenticated = !!auth?.token

  useEffect(() => {
    isAuthenticated && fetchProfile()
  }, [])

  const onProfileClick = () =>
    navigate(`${ROUTES.DASHBOARD}${ROUTES.DASHBOARD_PROFILE}`)
  const avatar = profile?.avatar?.avatar
    ? `${appConfig.media}${profile?.avatar?.avatar}`
    : ''
  const placeholder = `${profile?.firstName.substring(0, 1) ?? ''}${
    profile?.lastName?.substring(0, 1) ?? ''
  }`

  return (
    <HeaderAppBar color="default" elevation={0}>
      <ToolbarComponent>
        <Button component={Link} to={ROUTES.INDEX}>
          <LogoImg
            src={logoFull}
            alt={intl.formatMessage({ id: 'alt.appLogo' })}
          />
          {LogoAppendix && <LogoAppendix />}
        </Button>
        <LanguageSelector />
        <Spacer />
        {isAuthenticated ? (
          <Avatar
            onClick={onProfileClick}
            tooltip={intl.formatMessage({ id: 'label.profile' })}
            src={avatar}
            placeholder={placeholder}
            alt={`${profile?.firstName ?? ''} ${profile?.lastName ?? ''}`}
          />
        ) : (
          <>
            <Button
              sx={isBigScreen ? { marginRight: '20px' } : {}}
              component={Link}
              to={ROUTES.PRICING_ROUTE()}
            >
              <InsightsIcon
                color="info"
                fontSize="small"
                sx={isBigScreen ? { marginRight: '4px' } : {}}
              />
              {isBigScreen && (
                <H6> {intl.formatMessage({ id: 'label.pricing' })}</H6>
              )}
            </Button>
            {isBigScreen && (
              <Button
                sx={{ marginRight: '20px' }}
                component={Link}
                to={ROUTES.ABOUT_US}
              >
                <H6> {intl.formatMessage({ id: 'label.aboutUs' })}</H6>
              </Button>
            )}
            <HeaderButton
              component={Link}
              to={ROUTES.LOGIN}
              textColor="primary.main"
              variant="outlined"
            >
              {intl.formatMessage({ id: 'login' })}
            </HeaderButton>
            <HeaderButton
              component={Link}
              to={ROUTES.REGISTER}
              variant="contained"
              bgColor="primary.main"
            >
              {intl.formatMessage({ id: 'register' })}
            </HeaderButton>
          </>
        )}
      </ToolbarComponent>
    </HeaderAppBar>
  )
}
AppHeader.defaultProps = {
  LogoAppendix: null,
}

export default AppHeader
