import { useMutation, useQuery } from '@apollo/client'
import { Severity } from 'components/providers/SnackbarHOC'
import ROUTES from 'lib/routes'
import { NavigateFunction } from 'react-router-dom'
import {
  ContractByIdData,
  DeleteContractData,
  DeleteContractInput,
  UpdateContractData,
  UpdateContractInput,
  UpdateGroupItemRefsData,
  UpdateGroupItemRefsInput,
  UpdateGroupNestRefsData,
  UpdateGroupNestRefsInput,
} from 'types/contracts'
import {
  CONTRACT_BY_ID,
  DELETE_CONTRACT,
  UPDATE_CONTRACT,
  UPDATE_CONTRACT_ITEM_REFERENCE,
  UPDATE_CONTRACT_NEST_REFERENCE,
  WORKSPACE_CONTRACTS,
} from 'gql/contracts'
import { SERVICE_PROVIDERS_LIST } from 'gql/serviceProvider'
import { ServiceProvidersListData } from 'types/serviceProvider'
import { WorkspaceMembersData } from 'types/workspace'
import { WORKSPACE_MEMBERS } from 'gql/workspace'
import { useMutations as eventMutations } from 'hooks/graphql/event'
import {
  CREATE_MEMBER_CONTRACT_REF,
  DELETE_MEMBER_CONTRACT_REF,
} from 'gql/memberRefs'
import {
  CreateMemberContractRefData,
  CreateMemberContractRefInput,
  DeleteMemberContractRefData,
  DeleteMemberRefInput,
} from 'types/memberRefs'

export function useData(
  contractId: string,
  workspaceId: string,
  methods: {
    showSnackbar?: (message: string, severity: Severity) => void
    refreshEventDrawer: () => void
  }
) {
  const {
    data,
    loading: groupLoading,
    refetch,
  } = useQuery<ContractByIdData>(CONTRACT_BY_ID, {
    variables: { contractId },
    fetchPolicy: 'cache-and-network',
    onCompleted: methods.refreshEventDrawer,
    onError: (error) => methods.showSnackbar?.(error.message, Severity.ERROR),
  })

  const { loading: providersLoading, data: serviceProviderList } =
    useQuery<ServiceProvidersListData>(SERVICE_PROVIDERS_LIST, {
      variables: { payload: { workspaceId } },
    })
  const { data: wsMembers, loading: membersLoading } =
    useQuery<WorkspaceMembersData>(WORKSPACE_MEMBERS, {
      variables: { workspaceId },
    })

  const record = data?.contractById
  const members = wsMembers?.workspaceMembers.members || []
  const owners = wsMembers?.workspaceMembers.owners || []

  const loading = groupLoading || providersLoading || membersLoading
  return {
    loading,
    data: {
      record,
      serviceProviders: serviceProviderList?.serviceProviders,
      members,
      owners,
    },
    refetch: { refetchContract: refetch },
  }
}

export function useMutations(
  payload: {
    contractId: string
    workspaceId: string
    navigate: NavigateFunction
  },
  methods: {
    showSnackbar?: (message: string, severity: Severity) => void
    hideEditingModal: () => void
    toggleItemSelect: () => void
    toggleNestSelect: () => void
  }
) {
  const { showSnackbar, hideEditingModal, toggleItemSelect, toggleNestSelect } =
    methods

  const refetchQueries = [
    { query: CONTRACT_BY_ID, variables: { contractId: payload.contractId } },
  ]
  const providerRefetch = {
    query: SERVICE_PROVIDERS_LIST,
    variables: { payload: { workspaceId: payload.workspaceId } },
  }
  const [updateContract, { loading: updating }] = useMutation<
    UpdateContractData,
    UpdateContractInput
  >(UPDATE_CONTRACT, {
    onCompleted: hideEditingModal,
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: [...refetchQueries, providerRefetch],
  })

  const [deleteContract, { loading: deleting }] = useMutation<
    DeleteContractData,
    DeleteContractInput
  >(DELETE_CONTRACT, {
    onCompleted: () =>
      payload.navigate(ROUTES.DASHBOARD_CONTRACTS_ROUTE(payload.workspaceId), {
        replace: true,
      }),
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: [
      {
        query: WORKSPACE_CONTRACTS,
        variables: {
          payload: { workspaceId: payload.workspaceId, limit: 25, page: 0 },
        },
      },
    ],
  })

  const [updateContractItemRefs, { loading: updatingRefs }] = useMutation<
    UpdateGroupItemRefsData,
    UpdateGroupItemRefsInput
  >(UPDATE_CONTRACT_ITEM_REFERENCE, {
    onCompleted: toggleItemSelect,
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries,
  })
  const [updateContractNestRefs, { loading: updatingNestRefs }] = useMutation<
    UpdateGroupNestRefsData,
    UpdateGroupNestRefsInput
  >(UPDATE_CONTRACT_NEST_REFERENCE, {
    onCompleted: toggleNestSelect,
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries,
  })

  const [createMemberContractRef, { loading: createRefLoading }] = useMutation<
    CreateMemberContractRefData,
    CreateMemberContractRefInput
  >(CREATE_MEMBER_CONTRACT_REF, {
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries,
  })
  const [deleteMemberContractRef, { loading: deleteRefLoading }] = useMutation<
    DeleteMemberContractRefData,
    DeleteMemberRefInput
  >(DELETE_MEMBER_CONTRACT_REF, {
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries,
  })
  const {
    loading: eventsLoading,
    mutations: { updateEvent, addEventRecord, deleteEvent },
  } = eventMutations(
    { showSnackbar, onCompleted: hideEditingModal },
    refetchQueries
  )

  const loading =
    updating ||
    deleting ||
    updatingRefs ||
    updatingNestRefs ||
    createRefLoading ||
    deleteRefLoading ||
    eventsLoading

  return {
    loading,
    mutations: {
      updateContract,
      deleteContract,
      updateContractItemRefs,
      updateContractNestRefs,
      createMemberContractRef,
      deleteMemberContractRef,
      updateEvent,
      addEventRecord,
      deleteEvent,
    },
  }
}
