import React from 'react'
import { GridColDef } from '@mui/x-data-grid'
import { isValidText } from 'utils/validators'
import { TitleCell } from 'components/table/datagrid'
import { IntlShape } from 'react-intl'
import { BodyText } from 'components/typography'

export const columns = (
  intl: IntlShape,
  updateItem: Function
): GridColDef[] => [
  {
    field: 'id',
    headerName: '#',
    width: 0,
    sortable: false,
    headerAlign: 'center',
    hide: true,
  },
  {
    field: 'title',
    headerName: intl.formatMessage({ id: 'label.title' }),
    width: 250,
    headerAlign: 'center',
    renderCell: (row) => <TitleCell rowData={row} entity="item" />,
    hideable: false,
    editable: true,
    preProcessEditCellProps: (params) => {
      const { props, row } = params
      const hasError = !isValidText(props.value)
      if (!hasError) {
        const payload = { id: row.id, data: { title: props.value } }
        updateItem({ variables: { payload } })
      }
      return { ...params.props, error: hasError }
    },
  },
  {
    field: 'description',
    headerName: intl.formatMessage({ id: 'label.description' }),
    width: 350,
    headerAlign: 'center',
    renderCell: (row) => <BodyText>{row.value}</BodyText>,
    hideable: false,
    editable: true,
    preProcessEditCellProps: (params) => {
      const { props, row } = params
      const hasError = !isValidText(props.value)
      if (!hasError) {
        const payload = { id: row.id, data: { description: props.value } }
        updateItem({ variables: { payload } })
      }
      return { ...params.props, error: hasError }
    },
  },
]
