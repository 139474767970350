import React, { useCallback, useEffect } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useParams } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import { Severity, withSnackbar } from 'components/providers/SnackbarHOC'
import { GridColDef, GridRowParams } from '@mui/x-data-grid'
import { WORKSPACE_NESTS } from 'gql/nest'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'
import { ModalTitle, RowSpaced, Row, SimpleButton } from 'components/shared'
import { DataGridComp, TitleCell } from 'components/table/datagrid'
import { IntlShape, useIntl } from 'react-intl'
import { useLazyQuery } from '@apollo/client'
import Grid from '@mui/material/Grid'
import { Nest, WorkspaceNestsData } from 'types/nest'
import SearchInput from '../SearchInput'
import { BodyText } from '../typography'
import { Content, Drawer } from './components'

const columns = (
  intl: IntlShape,
  selectedIndex: string | null,
  isMobileScreen: boolean
): GridColDef[] => [
  {
    field: 'id',
    headerName: '#',
    width: 0,
    sortable: false,
    headerAlign: 'center',
    hide: true,
  },
  {
    field: '-',
    headerName: '',
    width: 50,
    headerAlign: 'center',
    renderCell: (data) =>
      data.row.id === selectedIndex ? <CheckIcon color="primary" /> : null,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'title',
    headerName: intl.formatMessage({ id: 'label.title' }),
    width: 200,
    headerAlign: 'center',
    renderCell: (row) => <TitleCell rowData={row} entity={null} />,
    hideable: false,
  },
  {
    field: 'description',
    headerName: intl.formatMessage({ id: 'label.description' }),
    width: 250,
    headerAlign: 'center',
    renderCell: (row) => <BodyText>{row.value}</BodyText>,
    hide: isMobileScreen,
  },
]

interface Props {
  open: boolean
  preSelectedRecord?: Nest
  toggleDrawer: () => void
  onSelect?: (selectedId: string | null) => void
  showSnackbar?: (message: string, severity: Severity) => void
  actions?: {
    label: string
    onClick: () => void
  }[]
}

function NestSelectDrawer(props: Props) {
  const {
    open,
    toggleDrawer,
    preSelectedRecord,
    onSelect,
    actions,
    showSnackbar,
  } = props

  const intl = useIntl()
  const theme = useTheme()
  const params = useParams() as { workspaceId: string }
  const [records, setRecords] = React.useState<Nest[]>([])
  const [search, setSearch] = React.useState('')
  const [total, setTotal] = React.useState(0)
  const [page, setPage] = React.useState(0)
  const [pageSize, setPageSize] = React.useState(25)
  const [selectedId, setSelectedId] = React.useState<string | null>(
    preSelectedRecord?.id || null
  )
  const payload = {
    workspaceId: params.workspaceId,
    limit: pageSize,
    page,
    filters: { search },
  }

  useEffect(() => {
    open && fetchNests()
  }, [open])

  const [fetchNests] = useLazyQuery<WorkspaceNestsData>(WORKSPACE_NESTS, {
    variables: { payload },
    onCompleted: (response) => {
      setRecords(response.workspaceNests.list)
      setTotal(response.workspaceNests.total)
    },
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
  })

  const onPageChange = useCallback((_page: number) => setPage(_page), [setPage])
  const onPageSizeChange = useCallback(
    (_pageSize: number) => setPageSize(_pageSize),
    [setPageSize]
  )
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const handleRowClick = (p: GridRowParams): void => {
    const rowId = p.id as string
    const groupId = rowId === selectedId ? null : rowId
    setSelectedId(groupId)
    onSelect?.(groupId)
  }

  return (
    <Drawer anchor="right" open={open} onClose={toggleDrawer}>
      <Content>
        <Grid container spacing={1} padding={2}>
          <Grid item xs={12} md={6}>
            <RowSpaced>
              <Row>
                <ModalTitle>
                  {intl.formatMessage({ id: 'label.nestSelect' })}
                </ModalTitle>
                {actions?.map((action) => (
                  <SimpleButton onClick={action.onClick} key={action.label}>
                    {action.label}
                  </SimpleButton>
                ))}
              </Row>
              {isMobileScreen && (
                <IconButton onClick={toggleDrawer}>
                  <CloseIcon />
                </IconButton>
              )}
            </RowSpaced>
          </Grid>
          <Grid item xs={12} md={6}>
            <SearchInput onSubmit={setSearch} />
          </Grid>
        </Grid>

        <DataGridComp
          initialState={{ pagination: { page, pageSize } }}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          rows={records}
          columns={columns(intl, selectedId, isMobileScreen)}
          disableSelectionOnClick
          rowsPerPageOptions={[25, 50, 100]}
          rowCount={total || 0}
          paginationMode="server"
          onRowClick={handleRowClick}
        />
      </Content>
    </Drawer>
  )
}

NestSelectDrawer.defaultProps = {
  onSelect: null,
  actions: null,
  showSnackbar: undefined,
  preSelectedRecord: null,
}
// @ts-ignore
export default withSnackbar(NestSelectDrawer)
