import { isValidEmail, isValidPassword, isValidText } from 'utils/validators'
import { InputType, Type } from 'components/input/types'
import countries from 'assets/static/countries.json'
import { IntlShape } from 'react-intl'

type Keys = keyof typeof countries
const countryCodes: Keys[] = ['DE', 'AT', 'CH', 'NL', 'BE', 'FR']

export const nameInputs = (intl: IntlShape): InputType[] => [
  {
    key: 'firstName',
    label: 'label.firstName',
    type: Type.TEXT,
    autoComplete: 'given-name',
    autoFocus: false,
    margin: 'none',
    fullWidth: false,
    validators: [
      {
        method: isValidText,
        label: 'label.required',
      },
    ],
  },

  {
    key: 'lastName',
    label: 'label.lastName',
    type: Type.TEXT,
    autoComplete: 'family-name',
    autoFocus: false,
    margin: 'none',
    fullWidth: false,
    validators: [
      {
        method: isValidText,
        label: 'label.required',
      },
    ],
  },
]

export const inputs = (intl: IntlShape): InputType[] => [
  {
    key: 'email',
    label: 'label.emailAddress',
    type: Type.EMAIL,
    autoComplete: 'email',
    autoFocus: false,
    margin: 'none',
    fullWidth: true,
    validators: [
      {
        method: isValidText,
        label: 'label.required',
      },
      {
        method: isValidEmail,
        label: 'label.invalidEmail',
      },
    ],
  },
  {
    key: 'password',
    label: 'label.password',
    type: Type.PASSWORD,
    autoComplete: 'current-password',
    autoFocus: false,
    margin: 'none',
    fullWidth: true,
    validators: [
      {
        method: isValidText,
        label: 'label.required',
      },
      {
        method: isValidPassword,
        label: 'label.invalidPassword',
      },
    ],
  },
  {
    key: 'registrationCountry',
    label: 'label.countryOfResidence',
    type: Type.COUNTRY,
    autoFocus: false,
    margin: 'none',
    fullWidth: true,
    options: countryCodes.map((code) => ({
      value: code,
      label: `${countries?.[code as unknown as Keys]}${
        code !== 'DE'
          ? ` - ${intl.formatMessage({ id: 'label.comingSoon' })}`
          : ''
      }`,
      disabled: code !== 'DE', // all but germany disabled for now
    })),
    validators: [
      {
        method: isValidText,
        label: 'label.required',
      },
    ],
  },
]
