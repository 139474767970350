import React, { useState } from 'react'
import Snackbar from '@mui/material/Snackbar'
import Slide from '@mui/material/Slide'
import Alert, { AlertColor } from '@mui/material/Alert'

interface SnackbarHOCProps {}

export enum Severity {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
}

export const withSnackbar =
  <P extends object>(
    WrappedComponent: React.ComponentType<P>
  ): React.FC<P & SnackbarHOCProps> =>
  (props) => {
    const [open, setOpen] = useState(false)
    const [messageValue, setMessage] = useState('')
    const [durationValue, setDuration] = useState(2000)
    const [severityValue, setSeverity] = useState('success' as AlertColor)

    const showMessage = (
      message: string,
      severity: Severity,
      duration: number = 3000
    ) => {
      setMessage(message)
      setSeverity(severity)
      setDuration(duration)
      setOpen(true)
    }

    const handleClose = (
      _: React.SyntheticEvent | React.MouseEvent,
      reason?: string
    ) => {
      if (reason === 'clickaway') {
        return
      }
      setOpen(false)
    }

    const handleSnackClose = (
      _: Event | React.SyntheticEvent,
      reason: string
    ) => {
      if (reason === 'clickaway') {
        return
      }
      setOpen(false)
    }

    return (
      <>
        <WrappedComponent {...(props as P)} showSnackbar={showMessage} />
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          autoHideDuration={durationValue}
          open={open}
          onClose={handleSnackClose}
          TransitionComponent={Slide}
        >
          <Alert
            variant="filled"
            onClose={handleClose}
            severity={severityValue}
          >
            {messageValue}
          </Alert>
        </Snackbar>
      </>
    )
  }
