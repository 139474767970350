import React from 'react'
import ReactDOM from 'react-dom/client'
import { ThemeProvider } from '@mui/material/styles'
import GraphqlProvider from 'components/providers/GraphqlProvider'
import Sentry from 'utils/sentry'
import App from './App'
import reportWebVitals from './reportWebVitals'
import theme from './assets/styles/theme'
import './index.css'
import 'react-credit-cards/es/styles-compiled.css'
import './components/markdown-styles.module.css'

// disable console errors for tabs
const consoleError = console.error
// TODO: this is a temporary solution for console silencing.
const SUPPRESSED_WARNINGS = [
  'MUI: The `value` provided to the Tabs component is invalid.',
]

console.error = function filterWarnings(msg: string, ...args: unknown[]) {
  if (!SUPPRESSED_WARNINGS.some((entry) => msg.includes(entry))) {
    consoleError(msg, ...args)
  }
}

Sentry.init()
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <GraphqlProvider>
        <App />
      </GraphqlProvider>
    </ThemeProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
