import { isNotValidOption, isValidEmail, isValidText } from 'utils/validators'
import { InputType, Type } from 'components/input/types'
import {
  ServiceProviderCategory,
  ServiceProviderExtended,
} from 'types/serviceProvider'

export const step1Inputs = (
  categories: ServiceProviderCategory[],
  providers?: ServiceProviderExtended[]
): {
  questions: InputType[]
  label: string
} => ({
  label: 'label.general',
  questions: [
    {
      key: 'title',
      label: 'label.title',
      type: Type.AUTOCOMPLETE,
      autoComplete: '',
      autoFocus: false,
      margin: 'dense',
      fullWidth: true,
      freeSolo: true,
      options:
        providers?.map((item) => ({
          value: item.id,
          label: item.title,
        })) || [],
      validators: [
        {
          method: isValidText,
          label: 'label.required',
        },
        {
          method: isNotValidOption,
          label: 'label.invalid',
        },
      ],
    },
    {
      key: 'description',
      label: 'label.description',
      type: Type.TEXT,
      autoComplete: '',
      autoFocus: false,
      margin: 'dense',
      fullWidth: true,
      multiline: true,
      validators: [],
    },
    {
      key: 'categoryId',
      label: 'label.types',
      type: Type.SINGLE_SELECT,
      autoComplete: '',
      options: categories.map((cat) => ({
        value: cat.id,
        label: cat.title,
      })),
      margin: 'dense',
      fullWidth: true,
      validators: [],
    },
  ],
})

export const step2Inputs: {
  questions: InputType[]
  label: string
} = {
  label: 'label.details',
  questions: [
    {
      key: 'link',
      label: 'label.website',
      type: Type.TEXT,
      autoComplete: '',
      autoFocus: false,
      margin: 'dense',
      fullWidth: true,
      validators: [],
    },
    {
      key: 'email',
      label: 'label.email',
      type: Type.EMAIL,
      autoComplete: '',
      autoFocus: false,
      margin: 'dense',
      fullWidth: true,
      validators: [
        {
          method: isValidEmail,
          label: 'label.invalidEmail',
        },
      ],
    },
    {
      key: 'phoneNumber',
      label: 'label.phone',
      type: Type.EMAIL,
      autoComplete: '',
      autoFocus: false,
      margin: 'dense',
      fullWidth: true,
      validators: [],
    },
  ],
}
