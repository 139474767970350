import React from 'react'
import { IntlShape } from 'react-intl'
import { isValidText } from 'utils/validators'
import { FormDataType, InputType, Type } from 'components/input/types'
import { JsonObject, MemberModels } from 'types/shared'
import { SuggestionSection } from 'components/recordDetails/SuggestionsSection'
import { ContentSection } from 'components/recordDetails/SidebarCard'
import { Container, ContainerExtended } from 'types/container'
import AddIcon from '@mui/icons-material/Add'
import HouseSimple from 'assets/svg/HouseSimple'
import { Theme } from '@mui/material/styles'
import { UserAssign } from 'components/user'
import { UserType } from 'components/user/memberSelect/UserBadge'

export enum Section {
  general = 'general',
}

const generalSectionInputs = (): InputType[] => [
  {
    key: 'title',
    label: 'label.title',
    type: Type.TEXT,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    validators: [
      {
        method: isValidText,
        label: 'label.required',
      },
    ],
  },

  {
    key: 'description',
    label: 'label.description',
    type: Type.TEXT,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    multiline: true,
    validators: [],
  },
]

export const editingSections = (intl: IntlShape): SuggestionSection[] => [
  {
    id: Section.general,
    title: intl.formatMessage({ id: 'label.general' }),
    description: '',
    cta: '',
  },
]

export const getSectionWithId = (id: string, intl: IntlShape) => {
  return editingSections(intl).find((section) => section.id === id)
}

const generalInitialState = (record: Container): FormDataType => ({
  title: record.title || '',
  description: record.description || '',
})

export const getEditInputs = (editId: string) => {
  switch (editId) {
    case Section.general:
      return generalSectionInputs()

    default:
      return []
  }
}

export const getInitialState = (
  record: Container,
  sectionId: string,
  recordId?: string // id of the sub-entity being edited, e.g. item sales info
): FormDataType => {
  switch (sectionId) {
    case Section.general:
      return generalInitialState(record)
    default:
      return {}
  }
}

export const getExtraInputs = (editId: string) => {
  return []
}

export const payloadBuilder = (
  record: Container,
  formData: JsonObject,
  recordId: string,
  sectionId?: string
) => {
  const payloadData: JsonObject = {
    title: formData.title,
    description: formData.description,
    nestId: formData.nestId,
  }

  const payload = {
    id: recordId,
    data: payloadData,
  }
  return payload
}

interface Methods {
  toggleItemSelect: () => void
  toggleNestSelect: () => void
  createMemberRef: (memberId: string, memberModel: MemberModels) => void
  deleteMemberRef: (refId: string) => void
}
const chipIconStyle = { paddingLeft: '4px', marginRight: '-8px' }
export const getDetailsChips = (
  intl: IntlShape,
  theme: Theme,
  data: {
    record?: ContainerExtended
    members: UserType[]
  },
  methods: Methods
): ContentSection[] => {
  const { record } = data

  return [
    {
      title: intl.formatMessage({ id: 'label.items' }),
      description: intl.formatMessage(
        { id: 'label.relatedItemsCount' },
        { number: record?.itemCount ?? 0 }
      ),
      action: {
        ariaLabel: 'related items',
        icon: () => <AddIcon color="disabled" />,
        onPress: methods.toggleItemSelect,
      },
    },
    {
      title: intl.formatMessage({ id: 'label.storedSpace' }),
      chips: record?.nest
        ? [
            {
              label: record.nest.title,
              icon: (
                <HouseSimple
                  color={theme.palette.text.primary}
                  width="20px"
                  style={chipIconStyle}
                />
              ),
            },
          ]
        : undefined,
      action: {
        ariaLabel: 'edit space',
        icon: () => <AddIcon color="disabled" />,
        onPress: methods.toggleNestSelect,
      },
    },
    {
      title: intl.formatMessage({ id: 'label.members' }),
      component: (
        <UserAssign
          users={data.record?.members ?? []}
          members={data.members}
          createMemberRef={methods.createMemberRef}
          deleteMemberRef={methods.deleteMemberRef}
        />
      ),
    },
  ]
}
