import React from 'react'
import { styled, useTheme } from '@mui/material/styles'
import { Button, RowSpaced } from 'components/shared'
import AddIcon from '@mui/icons-material/Add'
import { BodyTextLight, H4 } from 'components/typography'
import { cardBoxShadow } from 'assets/styles/theme'

const ItemContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}))

const ItemWrapper = styled('div')(({ theme }) => ({
  marginTop: '15px',
  boxShadow: cardBoxShadow,
  padding: '16px',
  borderRadius: '4px',
}))

export interface SuggestionSection {
  id: string
  title: string
  description: string
  cta: string
  onClick?: () => void // custom action for cta button
}

interface Props {
  section: SuggestionSection
  onCtaClick: (section: SuggestionSection) => void
}

function SectionItem({ section, onCtaClick }: Props) {
  const handleCtaClick = () => onCtaClick(section)
  const theme = useTheme()
  return (
    <ItemWrapper>
      <RowSpaced style={{ flexWrap: 'nowrap' }}>
        <ItemContent>
          <H4 color={theme.palette.text.secondary}>{section.title}</H4>
          <BodyTextLight color={theme.palette.text.secondary}>
            {section.description}
          </BodyTextLight>
        </ItemContent>
        <Button
          style={{ boxShadow: 'none' }}
          onClick={section.onClick || handleCtaClick}
          bgColor={theme.palette.primary.main}
        >
          <AddIcon />
          {section.cta}
        </Button>
      </RowSpaced>
    </ItemWrapper>
  )
}

export default SectionItem
