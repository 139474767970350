import React, { PropsWithChildren, useState } from 'react'
import Lottie from 'react-lottie'
import confetti from 'assets/confetti.json'

const initialValue = {
  showConfetti: () => {},
  hideConfetti: () => {},
}

export const CelebrationContext = React.createContext(initialValue)

interface Props extends PropsWithChildren {}

function CelebrationProvider({ children }: Props) {
  const [showAnimation, setShowAnimation] = useState(false)
  const showConfetti = () => (!showAnimation ? setShowAnimation(true) : null)
  const hideConfetti = () => setShowAnimation(false)

  return (
    <CelebrationContext.Provider value={{ showConfetti, hideConfetti }}>
      {children}
      <div
        style={{
          position: 'absolute',
          top: 0,
          pointerEvents: 'none',
          zIndex: 9999,
        }}
      >
        {showAnimation && (
          <Lottie
            options={{
              loop: false,
              autoplay: true,
              animationData: confetti,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
            }}
            isClickToPauseDisabled
            height="100vh"
            width="100vw"
            eventListeners={[
              {
                eventName: 'complete',
                callback: hideConfetti,
              },
            ]}
          />
        )}
      </div>
    </CelebrationContext.Provider>
  )
}

export default CelebrationProvider
