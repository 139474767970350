import * as React from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import { Conversation } from 'types/communication'
import CssBaseline from '@mui/material/CssBaseline'
import BrandLogo from 'assets/images/logoFull.svg'
import Button from '@mui/material/Button'
import { getActiveWorkspaceId, saveActiveWorkspaceId } from 'lib/sessionStorage'
import Backdrop from '@mui/material/Backdrop'
import { Workspace } from 'types/workspace'
import ROUTES from 'lib/routes'
import { IconButton } from '@mui/material'
import SwipeDrawerHoc from 'components/SwipeDrawerHoc'
import SortSharpIcon from '@mui/icons-material/SortSharp'
import DrawerContent from './DrawerContent'
import { AppBar, HeaderSeparator, LogoImg, MobileToolbar } from './fragments'
import ActionBar from './ActionBar'
import WorkspaceSelection from './WorkspaceSelection'

interface Props {
  workspaces: Workspace[] | null
  conversations: Conversation[] | null
  // onIconClick?: () => void
  // onChatClick?: () => void
}
function MobileDrawer(props: Props) {
  const { workspaces, conversations } = props
  const intl = useIntl()
  const workspaceId = getActiveWorkspaceId()
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(false)
  const toggleDrawer = () => setOpen(!open)
  const onLogoClick = () => {
    saveActiveWorkspaceId(null)
    navigate(ROUTES.DASHBOARD, { replace: true })
  }

  const headerContent = workspaceId
    ? [
        <IconButton
          onClick={toggleDrawer}
          key="drawer-menu-button"
          style={{ position: 'absolute', zIndex: 4, left: 4 }}
        >
          <SortSharpIcon color="disabled" key="drawer-menu" />
        </IconButton>,
        <Box ml={2} flex={1} key="workspace-selection-wrapper">
          <WorkspaceSelection
            key="workspace-selection"
            toggleDrawer={toggleDrawer}
          />
        </Box>,
      ]
    : [
        <div key="spacer-div" />,
        <Button onClick={onLogoClick} key="itemzz-logo">
          <LogoImg
            src={BrandLogo}
            alt={intl.formatMessage({ id: 'alt.appLogo' })}
          />
        </Button>,
      ]

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed">
        <MobileToolbar>
          <HeaderSeparator />
          {headerContent}
          <ActionBar
            conversations={conversations}
            // onIconClick={onIconClick}
            // onChatClick={onChatClick}
          />
        </MobileToolbar>
      </AppBar>
      <Backdrop
        open={open}
        onClick={toggleDrawer}
        sx={{ backgroundColor: 'transparent' }}
      />
      <SwipeDrawerHoc
        swipeAreaWidth={4}
        anchor="left"
        open={open}
        toggleVisibility={toggleDrawer}
        disableSwipeToOpen={false}
      >
        <DrawerContent
          workspaces={workspaces}
          toggleDrawer={toggleDrawer}
          isMobile
        />
      </SwipeDrawerHoc>
    </Box>
  )
}

MobileDrawer.defaultProps = {
  // onIconClick: null,
  // onChatClick: null,
}

export default MobileDrawer
