import { IntlShape } from 'react-intl'
import { isValidText } from 'utils/validators'
import { InputType, Type } from 'components/input/types'
import { JsonObject } from 'types/shared'
import { SuggestionSection } from 'components/recordDetails/SuggestionsSection'
import { Gender, Profile } from 'types/profile'

export enum Section {
  general = 'general',
  avatar = 'avatar',
}

const generalSectionInputs = (): InputType[] => [
  {
    key: 'firstName',
    label: 'label.firstName',
    type: Type.TEXT,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    validators: [
      {
        method: isValidText,
        label: 'label.required',
      },
    ],
  },
  {
    key: 'lastName',
    label: 'label.lastName',
    type: Type.TEXT,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    validators: [],
  },
  {
    key: 'birthday',
    label: 'label.birthday',
    type: Type.DATE,
    autoComplete: '',
    margin: 'dense',
    fullWidth: true,
    validators: [],
    disableFuture: true,
  },
  {
    key: 'gender',
    label: 'label.gender',
    type: Type.SINGLE_SELECT,
    options: Object.values(Gender).map((type) => ({
      value: type,
      label: type,
    })),
    margin: 'dense',
    useTranslation: true,
    fullWidth: true,
    validators: [],
  },
]

const profilePhotoInputs: InputType[] = [
  {
    key: 'avatarId',
    label: 'label.addProfilePicture',
    type: Type.DROPZONE,
    margin: 'dense',
    fullWidth: true,
    validators: [],
  },
]

const editingSections = (intl: IntlShape): SuggestionSection[] => [
  {
    id: Section.general,
    title: intl.formatMessage({ id: 'label.profile' }),
    description: '',
    cta: '',
  },
  {
    id: Section.avatar,
    title: intl.formatMessage({ id: 'label.profile' }),
    description: '',
    cta: '',
  },
]

export const getSectionWithId = (id: string, intl: IntlShape) => {
  return editingSections(intl).find((section) => section.id === id)
}

const generalInitialState = (record: Profile) => ({
  firstName: record.firstName || '',
  lastName: record.lastName || '',
  birthday: record.birthday ? new Date(record.birthday) : null,
  gender: record.gender || '',
})

export const getEditInputs = (editId: string) => {
  switch (editId) {
    case Section.general:
      return generalSectionInputs()
    case Section.avatar:
      return profilePhotoInputs

    default:
      return []
  }
}

export const getInitialState = (
  record: Profile,
  sectionId: string,
  recordId?: string // id of the sub-entity being edited, e.g. item sales info
) => {
  switch (sectionId) {
    case Section.general:
      return generalInitialState(record)
    case Section.avatar:
      return {
        avatarId: undefined,
      }
    default:
      return {}
  }
}

export const getExtraInputs = (editId: string) => {
  return []
}

export const payloadBuilder = (formData: JsonObject, sectionId?: string) => {
  let payloadData: JsonObject = {}
  if (sectionId)
    switch (sectionId) {
      case Section.general:
        payloadData = {
          firstName: formData.firstName,
          lastName: formData.lastName,
          birthday: formData.birthday,
          gender: formData.gender,
        }
        break
      default:
        payloadData = formData
    }

  return payloadData
}
