import { useQuery } from '@apollo/client'
import { GROUPED_WORKSPACES } from 'gql/workspace'
import { GroupedWorkspacesData, WorkspaceExtended } from 'types/workspace'
import { sortFromEarliest } from 'utils/datetime'

export function useGroupedWorkspaces() {
  const {
    data: groupedWorkspacesData,
    loading,
    refetch,
  } = useQuery<GroupedWorkspacesData>(GROUPED_WORKSPACES)
  const sections = groupedWorkspacesData?.groupedWorkspaces || []
  return { loading, data: sections, refetch }
}

export function useWorkspaces() {
  const { data: groupedWorkspacesData, loading } =
    useQuery<GroupedWorkspacesData>(GROUPED_WORKSPACES)
  const sections = groupedWorkspacesData?.groupedWorkspaces || []
  const workspaces = sections.reduce(
    (acc, section) => [...acc, ...section.workspaces],
    [] as WorkspaceExtended[]
  )

  return { loading, data: workspaces }
}

/**
 * Hook to get the subscription status of a workspace.
 * @param workspaceId
 */
export function useSubscriptionStatus(workspaceId: string | null) {
  const { data: sections, refetch } = useGroupedWorkspaces()
  if (!workspaceId) return { status: { active: false } }
  const section = sections.find((sec) =>
    sec.workspaces.some((w) => w.id === workspaceId)
  )
  const active = (section?.subscriptions?.length ?? 0) > 0
  return { active, section, refetch }
}

/**
 * Hook to get the latest endDate of the subscriptions.
 * @param workspaceId
 * @returns
 */
export function useSubscriptionEndDate(workspaceId: string | null) {
  const { data: sections } = useGroupedWorkspaces()
  if (!workspaceId) return { status: { active: false } }
  const section = sections.find((sec) =>
    sec.workspaces.some((w) => w.id === workspaceId)
  )

  const dates = section?.subscriptions.map((s) => s.endDate) ?? []
  if (!dates || dates.length === 0) return { endDate: null, section }

  const sortedDates = sortFromEarliest(...dates)
  return { endDate: sortedDates[sortedDates.length - 1], section }
}
