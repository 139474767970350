import { gql } from '@apollo/client'

export const SERVICE_BY_ID = gql`
  query SERVICE_BY_ID($serviceId: ID!) {
    serviceById(serviceId: $serviceId) {
      id
      title
      description
      providerId
      typeId
      status
      archiveIds
      applicable {
        nestTypes {
          id
          title
        }
        categories {
          id
          title
        }
        features
        securityFeatures
      }
      type {
        id
        title
        titleDE
      }
      archive {
        id
        type
        title
        documentType
        path
        media {
          thumbnail
          medium
          full
        }
      }
    }
  }
`

export const SERVICE_TYPES = gql`
  query SERVICE_TYPES {
    serviceTypes {
      total
      list {
        id
        title
        description
        titleDE
        descriptionDE
        parentId
        createdAt
        updatedAt
      }
    }
  }
`

export const CREATE_SERVICE = gql`
  mutation CREATE_SERVICE($payload: CreateServiceInput!) {
    createService(payload: $payload) {
      id
    }
  }
`

export const PROVIDER_SERVICES = gql`
  query PROVIDER_SERVICES($payload: ProviderServicesInput!) {
    providerServices(payload: $payload) {
      id
      title
      description
      providerId
      typeId
      status
      applicable {
        nestTypes
        categories
        features
        securityFeatures
      }
    }
  }
`

export const DELETE_SERVICE = gql`
  mutation DELETE_SERVICE($id: ID!) {
    deleteService(id: $id) {
      status
    }
  }
`

export const UPDATE_SERVICE = gql`
  mutation UPDATE_SERVICE($payload: UpdateServiceInput!) {
    updateService(payload: $payload) {
      id
    }
  }
`

export const ITEM_RELATED_SERVICES = gql`
  query ITEM_RELATED_SERVICES($itemId: ID!) {
    itemRelatedServices(itemId: $itemId) {
      id
      title
      description
      providerId
      typeId
      status
      archiveIds
      applicable {
        nestTypes {
          id
          title
        }
        categories {
          id
          title
        }
        features
        securityFeatures
      }
      type {
        id
        title
        titleDE
      }
      archive {
        id
        type
        title
        documentType
        path
        media {
          thumbnail
          medium
          full
        }
      }
    }
  }
`
