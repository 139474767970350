import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useTheme, styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import { ModalSubtitle, ModalTitle, ModalTransition } from 'components/shared'
import Button from '@mui/material/Button'

const ButtonWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '10px',
}))

interface Action {
  label: string
  onClick?: () => void
  variant?: 'text' | 'outlined' | 'contained'
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
}

export interface ConfirmationData {
  title: string
  description: string
  actions?: Action[]
}

interface Props {}

export const withConfirmationModal =
  <P extends object>(
    WrappedComponent: React.ComponentType<P>
  ): React.FC<P & Props> =>
  (props) => {
    const intl = useIntl()
    const theme = useTheme()
    const ctaStyle = { marginLeft: '10px' }
    const [visible, setVisible] = useState<boolean>(false)
    const [data, setData] = useState<ConfirmationData>()
    const closeModal = () => setVisible(false)
    const requestConfirmation = (_data: ConfirmationData) => {
      setData(_data)
      setVisible(true)
    }

    const defaultActions: Action[] = [
      {
        label: intl.formatMessage({ id: 'label.ok' }),
        onClick: closeModal,
      },
    ]

    const actions =
      data?.actions?.map((action) => ({
        ...action,
        onClick: () => {
          action.onClick?.()
          closeModal()
        },
      })) ?? defaultActions

    return (
      <React.Fragment>
        <WrappedComponent
          {...(props as P)}
          requestConfirmation={requestConfirmation}
        />
        <Dialog
          open={visible}
          TransitionComponent={ModalTransition}
          maxWidth="sm"
        >
          <DialogContent
            sx={{ backgroundColor: theme.palette.background.default }}
          >
            <ModalTitle>{data?.title}</ModalTitle>
            <ModalSubtitle>{data?.description}</ModalSubtitle>
            <ButtonWrapper>
              {actions?.map((action) => (
                <Button
                  onClick={action.onClick}
                  style={
                    action.variant === 'outlined'
                      ? { boxShadow: 'none', ...ctaStyle }
                      : ctaStyle
                  }
                  key={action.label}
                  variant={action.variant || 'contained'}
                  color={action.color || 'primary'}
                >
                  {action.label}
                </Button>
              ))}
            </ButtonWrapper>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    )
  }
