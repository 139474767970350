import React from 'react'
import { useParams, Link } from 'react-router-dom'
import AddIcon from '@mui/icons-material/AddSharp'
import { useQuery } from '@apollo/client'
import { useIntl } from 'react-intl'
import { withSnackbar } from 'components/providers/SnackbarHOC'
import { Container, Content, RowSpaced, Button } from 'components/shared'
import LoadingIndicator from 'components/LoadingIndicator'
import { Row } from 'components/shared'
import ROUTES from 'lib/routes'
import { ServiceProvidersListData } from 'types/serviceProvider'
import { SERVICE_PROVIDERS_LIST } from 'gql/serviceProvider'
import ServiceProviderCard from 'components/ServiceProviderCard'
import SearchInput from 'components/SearchInput'
import { H1, H2 } from 'components/typography'
import Grid from '@mui/material/Grid'
import EmptyList from 'components/EmptyList'

interface Props {
  // showSnackbar: Function
}

function WorkspaceServiceProvides(props: Props) {
  const intl = useIntl()
  const params = useParams() as { workspaceId: string }
  const [filters, setFilters] = React.useState<{ search: string }>()

  const onTextChange = (text: string) => {
    setFilters({ ...filters, search: text })
  }

  const payload = {
    workspaceId: params.workspaceId,
    filters,
  }
  const { loading, data } = useQuery<ServiceProvidersListData>(
    SERVICE_PROVIDERS_LIST,
    { variables: { payload } }
  )

  const providers = data?.serviceProviders.workspaceProviders ?? []
  const relatedProviders = data?.serviceProviders.publicRelatedProviders ?? []
  const publicProviders = data?.serviceProviders.publicProviders ?? []

  return (
    <Container>
      <LoadingIndicator visible={loading} />
      <Content>
        <Row>
          <H1>{intl.formatMessage({ id: 'label.serviceProviders' })}</H1>
        </Row>

        <Grid container spacing={2} mt={2}>
          <Grid item sm={6} xs={12} order={{ xs: 3, sm: 2 }}>
            <Row>
              <H2 sx={{ marginRight: 1 }}>
                {intl.formatMessage({ id: 'label.yourProviders' })}{' '}
                {providers.length ?? -1 > 0 ? `( ${providers.length} )` : ''}
              </H2>
              <Button
                component={Link}
                to={ROUTES.DASHBOARD_CREATE_SERVICE_PROVIDER_ROUTE(
                  params.workspaceId
                )}
              >
                <AddIcon color="secondary" />
                {intl.formatMessage({ id: 'label.addNew' })}
              </Button>
            </Row>
          </Grid>
          <Grid item sm={6} xs={12} order={{ xs: 2, sm: 3 }}>
            <SearchInput onSubmit={onTextChange} />
          </Grid>
        </Grid>

        <Grid container spacing={2} marginTop={0.1} mb={4}>
          {providers?.map((record) => (
            <Grid item xs={6} sm={6} md={4} xl={12 / 5} key={record.id}>
              <ServiceProviderCard showChip record={record} key={record.id} />
            </Grid>
          ))}
          {!loading && providers.length === 0 ? (
            <Grid item xs={12}>
              <EmptyList />
            </Grid>
          ) : (
            <React.Fragment />
          )}
        </Grid>

        {relatedProviders.length > 0 && (
          <React.Fragment>
            <RowSpaced>
              <Row>
                <H2 sx={{ marginRight: 1 }}>
                  {intl.formatMessage({ id: 'label.relatedProviders' })}
                </H2>
              </Row>
            </RowSpaced>

            <Grid container spacing={2} marginTop={0.1} mb={4}>
              {relatedProviders?.map((record) => (
                <Grid item xs={6} sm={6} md={4} xl={12 / 5} key={record.id}>
                  <ServiceProviderCard
                    showChip
                    record={record}
                    key={record.id}
                  />
                </Grid>
              ))}
            </Grid>
          </React.Fragment>
        )}
        {publicProviders.length > 0 && (
          <React.Fragment>
            <RowSpaced>
              <Row>
                <H2 sx={{ marginRight: 1 }}>
                  {intl.formatMessage({ id: 'label.publicProviders' })}
                </H2>
              </Row>
            </RowSpaced>

            <Grid container spacing={2} marginTop={0.1} mb={4}>
              {publicProviders?.map((record) => (
                <Grid item xs={6} sm={6} md={4} xl={12 / 5} key={record.id}>
                  <ServiceProviderCard
                    showChip
                    record={record}
                    key={record.id}
                  />
                </Grid>
              ))}
            </Grid>
          </React.Fragment>
        )}
      </Content>
    </Container>
  )
}

export default withSnackbar(WorkspaceServiceProvides)
