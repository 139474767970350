import { useMutation } from '@apollo/client'
import { Severity } from 'components/providers/SnackbarHOC'
import { TRANSFER_CONTAINERS, WORKSPACE_CONTAINERS } from 'gql/container'
import { useWorkspaces } from 'hooks/graphql/workspaces'
import { TransferContainersData, TransferContainersInput } from 'types/container'

export function useData(workspaceId: string) {
  const { data: workspaces, loading } = useWorkspaces()
  const activeWs = workspaces.find((ws) => ws.id === workspaceId)
  const orgWorkspaces =
    workspaces.filter((w) => w.organizationId === activeWs?.organizationId) ||
    []

  return { loading, data: { orgWorkspaces } }
}

export function useMutations(
  payload: {
    workspaceId: string
    limit: number
    page: number
  },
  methods: {
    showSnackbar?: (message: string, severity: Severity) => void
    hideEditingModal: () => void
  }
) {
  const { showSnackbar, hideEditingModal } = methods

  const [transferContainers, { loading: transferLoading }] = useMutation<
  TransferContainersData,
    TransferContainersInput
  >(TRANSFER_CONTAINERS, {
    onCompleted: hideEditingModal,
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: [{ query: WORKSPACE_CONTAINERS, variables: { payload } }],
  })

  const loading = transferLoading
  return {
    loading,
    mutations: {
      transferContainers,
    },
  }
}
