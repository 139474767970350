import { useMutation } from '@apollo/client'
import { Severity } from 'components/providers/SnackbarHOC'
import { CREATE_CONTRACT, WORKSPACE_CONTRACTS } from 'gql/contracts'
import ROUTES from 'lib/routes'
import { useNavigate } from 'react-router-dom'
import { CreateContractData, CreateContractInput } from 'types/contracts'

export function useMutations(
  workspaceId: string,
  methods: {
    showSnackbar?: (message: string, severity: Severity) => void
  }
) {
  const { showSnackbar } = methods
  const navigate = useNavigate()
  const [createContract, { loading: createLoading }] = useMutation<
    CreateContractData,
    CreateContractInput
  >(CREATE_CONTRACT, {
    onCompleted: (response) =>
      navigate(
        ROUTES.DASHBOARD_CONTRACT_DETAILS_ROUTE(
          workspaceId,
          response.createContract.id
        ),
        { replace: true }
      ),
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: [
      {
        query: WORKSPACE_CONTRACTS,
        variables: { payload: { workspaceId, limit: 25, page: 0 } },
      },
    ],
  })

  const loading = createLoading
  return {
    loading,
    mutations: {
      createContract,
    },
  }
}
