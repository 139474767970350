import React, { useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import AddIcon from '@mui/icons-material/AddSharp'
import { useQuery } from '@apollo/client'
import LoadingIndicator from 'components/LoadingIndicator'
import { Container, Content, Row } from 'components/shared'
import { Severity, withSnackbar } from 'components/providers/SnackbarHOC'
import { uploadDocument, uploadMedia } from 'lib/api'
import { FileType, WorkspaceArchiveData } from 'types/archive'
import { IconButton } from '@mui/material'
import { WORKSPACE_ARCHIVE } from 'gql/archive'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import UploadModal from 'components/dropzone/UploadModal'
import { getDateTime } from 'utils/datetime'
import { FileEntry } from 'types/shared'
import { H1 } from 'components/typography'

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'Id',
    width: 150,
    sortable: false,
    headerAlign: 'center',
  },
  {
    field: 'userId',
    headerName: 'Uploaded By',
    width: 150,
    sortable: false,
    headerAlign: 'center',
  },
  {
    field: 'type',
    headerName: 'Type',
    width: 150,
    sortable: false,
    headerAlign: 'center',
  },
  {
    field: 'updatedAt',
    headerName: 'Updated at',
    width: 200,
    renderCell: (row) => getDateTime(row.value),
  },
  {
    field: 'createdAt',
    headerName: 'Created at',
    width: 200,
    renderCell: (row) => getDateTime(row.value),
  },
]

interface Props {
  showSnackbar?: (message: string, severity: Severity) => void
}
/**
 * @deprecated Currently not in use for V1
 * @returns 
 */
function Documents({ showSnackbar }: Props) {
  const intl = useIntl()
  const params = useParams() as { workspaceId: string }
  const [visibleModal, setVisibleModal] = useState(false)
  const [page, setPage] = React.useState(0)
  const [pageSize, setPageSize] = React.useState(25)

  const onPageChange = useCallback((_page: number) => setPage(_page), [setPage])
  const onPageSizeChange = useCallback(
    (_pageSize: number) => setPageSize(_pageSize),
    [setPageSize]
  )

  const toggleVisibility = useCallback(
    () => setVisibleModal(!visibleModal),
    [visibleModal]
  )

  const payload = { workspaceId: params.workspaceId, limit: pageSize, page }
  const { data, loading, refetch } = useQuery<WorkspaceArchiveData>(
    WORKSPACE_ARCHIVE,
    { variables: { payload } }
  )
  const archive = data?.workspaceArchiveFiles.list || []
  const onUpload = async (files: FileEntry[]) => {
    if (!files) return

    // const results =
    await Promise.all(
      files.map(async (object) => {
        const isImageFile = object.file.type.includes('image')
        const uploadFunction = isImageFile ? uploadMedia : uploadDocument
        return uploadFunction(object.file, {
          workspaceId: params.workspaceId,
          type: FileType.document,
        })
          .then((result) => result.json())
          .then((result) => result)
          .catch((error) => error)
      })
    )

    // refresh the archive
    refetch()
  }

  const onSubmit = (files: FileEntry[]) => {
    onUpload(files)
    // TODO: add file failure handling and form cleanup
    toggleVisibility()
  }

  return (
    <Container>
      <LoadingIndicator visible={loading} />
      <Content>
        <Row>
          <H1>{intl.formatMessage({ id: 'label.archive' })}</H1>
          <IconButton sx={{ padding: 2 }} onClick={toggleVisibility}>
            <AddIcon color="primary" />
          </IconButton>
        </Row>
        <div style={{ height: '80vh' }}>
          <DataGrid
            initialState={{ pagination: { page, pageSize } }}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            rows={archive}
            columns={columns}
            rowCount={data?.workspaceArchiveFiles.total || 0}
            disableSelectionOnClick
            rowsPerPageOptions={[25, 50, 100]}
            paginationMode="server"
          />
        </div>
      </Content>
      <UploadModal
        fileType={FileType.document}
        open={visibleModal}
        onSubmit={onSubmit}
        toggleModal={toggleVisibility}
      />
    </Container>
  )
}

Documents.defaultProps = {
  showSnackbar: undefined,
}

export default withSnackbar(Documents)
