import React from 'react'
import Grid from '@mui/material/Grid'
import { FileType } from 'types/archive'
import useMediaQuery from '@mui/material/useMediaQuery'
import ImageHeadlineGrid from 'components/ImageHeadlineGrid'
import { useIntl } from 'react-intl'
import { PageDescription } from 'components/pages'
import { styled, useTheme } from '@mui/material/styles'
import { Row, RowSpaced } from 'components/shared'
import MuiIconButton from '@mui/material/IconButton'
import Card from '@mui/material/Card'
import { H1 } from 'components/typography'
import { ContainerExtended } from 'types/container'
import Pencil from 'assets/svg/Pencil'
import placeholder from 'assets/images/placeholderContainer.png'
import placeholderFull from 'assets/images/placeholderContainerFull.png'
import { CONTAINER_BY_ID } from 'gql/container'
import { StatusChip } from 'components/cards'
import { getDate } from 'utils/datetime'
import { Section } from './constants'

const Container = styled(Row)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: '18px',
  borderRadius: '5px',
  marginBottom: '16px',
}))
const IconButton = styled(MuiIconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
}))

const HeadlineCard = styled(Card)({
  display: 'flex',
  flex: 1,
  flexGrow: 1,
  flexDirection: 'row',
  boxShadow: 'none',
  flexWrap: 'nowrap',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const HeadlineContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    marginLeft: '20px',
  },
}))

interface Props {
  record: ContainerExtended
  showMediaUploadModal: () => void
  onEditSection: (sectionId: Section) => void
}
function Headline({ record, showMediaUploadModal, onEditSection }: Props) {
  const intl = useIntl()
  const theme = useTheme()
  const isBigScreen = useMediaQuery(theme.breakpoints.up('md'))
  const images = record?.archive?.filter((i) => i.type === FileType.image) ?? []
  const onEdit = () => onEditSection(Section.general)
  const refetchQueries = [
    { query: CONTAINER_BY_ID, variables: { containerId: record?.id } },
  ]

  const filledContainer = (record?.itemCount ?? 0) > 0
  const pHolder = filledContainer ? placeholderFull : placeholder

  const headline = (
    <HeadlineCard>
      <HeadlineContainer>
        <RowSpaced>
          {record.deletedAt ? (
            <StatusChip
              color="error"
              label={intl.formatMessage(
                { id: 'label.deletedAtDate' },
                { date: getDate(record.deletedAt) }
              )}
            />
          ) : (
            <div />
          )}
          <IconButton onClick={onEdit}>
            <Pencil />
          </IconButton>
        </RowSpaced>
        <H1>{record.title}</H1>
        <PageDescription>{record.description}</PageDescription>
      </HeadlineContainer>
    </HeadlineCard>
  )

  return (
    <Container>
      {isBigScreen ? (
        <Grid container mb={4} spacing={1}>
          <Grid item md={5}>
            <ImageHeadlineGrid
              images={images}
              placeholder={pHolder}
              onCtaClick={showMediaUploadModal}
              refetchQueries={refetchQueries}
            />
          </Grid>
          <Grid item md={7}>
            {headline}
          </Grid>
        </Grid>
      ) : (
        <Grid container mb={4} spacing={1}>
          <Grid item xs={12}>
            {headline}
          </Grid>
          <Grid item xs={12}>
            <ImageHeadlineGrid
              images={images}
              placeholder={pHolder}
              onCtaClick={showMediaUploadModal}
              refetchQueries={refetchQueries}
            />
          </Grid>
        </Grid>
      )}
    </Container>
  )
}

export default Headline
