import React, { CSSProperties } from 'react'
import { useParams, Link } from 'react-router-dom'
import { GridRenderCellParams } from '@mui/x-data-grid'
import { styled, useTheme } from '@mui/material/styles'
import { DataGrid } from '@mui/x-data-grid'
import appConfig from 'config/app'
// import ShowIcon from '@mui/icons-material/Visibility'
import ROUTES from 'lib/routes'
import { H5 } from 'components/typography'
import { FileType, ArchiveFile } from 'types/archive'
import { FontFamily } from 'assets'

export const DataGridComp = styled(DataGrid)(({ theme }) => ({
  borderWidth: 0,
  minHeight: '80vh',
  // backgroundColor: theme.palette.background.default,
  '& td': { border: 0 },
  '& .MuiDataGrid-cellCheckbox': {
    // backgroundColor: 'red',
  },
  '& .MuiDataGrid-columnHeaderCheckbox': {
    visibility: 'hidden',
  },
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontSize: '16px',
    fontFamily: FontFamily.NoirPro,
    color: theme.palette.text.primary,
    fontWeight: 500,
  },
  '& .MuiDataGrid-cell--editing': {
    backgroundColor: 'rgb(255,215,115, 0.19)',
    '& .MuiInputBase-root': {
      height: '100%',
    },
  },
  '& .Mui-error': {
    backgroundColor: `rgb(126,10,15, ${
      theme.palette.mode === 'dark' ? 0 : 0.1
    })`,
  },
  '& .MuiDataGrid-selectedRowCount': {
    visibility: 'hidden',
  },
}))

const CoverImage = styled('img')(({ theme }) => ({
  height: '24px',
  width: '32px',
  borderRadius: '5px',
  objectFit: 'cover',
  marginRight: '8px',
}))

const TitleWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  ':hover svg': {
    display: 'flex',
  },
}))

const LinkWrapper = styled(Link)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  textDecorationLine: 'none',
  ':hover svg': {
    display: 'flex',
  },
}))

const getToRoute = (
  workspaceId: string,
  recordId: string,
  entity: 'item' | 'nest' | 'box' | 'service-provider' | 'group' | 'contract'
) => {
  let to = '#'
  switch (entity) {
    case 'item':
      to = ROUTES.DASHBOARD_ITEM_DETAILS_ROUTE(workspaceId, recordId)
      break
    case 'nest':
      to = ROUTES.DASHBOARD_NEST_DETAILS_ROUTE(workspaceId, recordId)
      break
    case 'box':
      to = ROUTES.DASHBOARD_CONTAINER_DETAILS_ROUTE(workspaceId, recordId)
      break
    case 'service-provider':
      to = ROUTES.DASHBOARD_SERVICE_PROVIDER_DETAILS_ROUTE(
        workspaceId,
        recordId
      )
      break
    case 'group':
      to = ROUTES.DASHBOARD_GROUP_DETAILS_ROUTE(workspaceId, recordId)
      break
    case 'contract':
      to = ROUTES.DASHBOARD_CONTRACT_DETAILS_ROUTE(workspaceId, recordId)
      break
  }
  return to
}

interface CellProps {
  rowData: GridRenderCellParams
  entity:
    | 'item'
    | 'nest'
    | 'box'
    | 'service-provider'
    | 'group'
    | 'contract'
    | null
}

export function TitleCellCover({ rowData, entity }: CellProps) {
  const theme = useTheme()
  const { row } = rowData
  const recordId = row.id
  const params = useParams() as { workspaceId: string }
  const route = entity ? getToRoute(params.workspaceId, recordId, entity) : ''
  const cover = row?.coverImage?.thumbnail
    ? `${appConfig.media}${row.coverImage.thumbnail}`
    : undefined

  const Wrapper = entity ? LinkWrapper : TitleWrapper
  const style: CSSProperties = entity ? { color: theme.palette.info.main } : {}

  return (
    <Wrapper to={route}>
      {cover && <CoverImage src={cover} />}
      <H5 style={style}>{rowData.value}</H5>
    </Wrapper>
  )
}

export function TitleCell({ rowData, entity }: CellProps) {
  const { row } = rowData
  const recordId = row.id
  const theme = useTheme()
  const params = useParams() as { workspaceId: string }
  const route = entity ? getToRoute(params.workspaceId, recordId, entity) : ''
  const archiveImages =
    row.archive?.filter((f: ArchiveFile) => f.type === FileType.image) || []
  const cover = archiveImages[0]?.media?.thumbnail

  const Wrapper = entity ? LinkWrapper : TitleWrapper
  const style: CSSProperties = entity ? { color: theme.palette.info.main } : {}
  return (
    <Wrapper to={route}>
      {cover && <CoverImage src={`${appConfig.media}${cover}`} />}
      <H5 style={style}>{rowData.value ?? '-'}</H5>
    </Wrapper>
  )
}
