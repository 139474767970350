import React from 'react'
import { IntlShape } from 'react-intl'
import { Severity } from 'components/providers/SnackbarHOC'
import DocumentGrid from 'components/DocumentGrid'
import AddIcon from '@mui/icons-material/Add'
import { TabSection } from 'components/Tabs'
import { FileType } from 'types/archive'
import DangerSectionRender from 'components/content/DangerSectionRender'
import SectionRender from 'components/content/SectionRender'
import TrashIcon from 'assets/svg/Trash'
import DeleteIcon from '@mui/icons-material/Delete'
import DocumentIcon from '@mui/icons-material/NoteAdd'
import Box from '@mui/material/Box'
import Pencil from 'assets/svg/Pencil'
import {
  ServiceProviderExtended,
  ServiceProviderRelations,
} from 'types/serviceProvider'
import { CollapsibleTable } from 'components/table'
import { classifyOrders } from 'pages/dashboard/items/show/utils'
import { getOrderContent } from 'pages/dashboard/items/show/constants'
import { SERVICE_PROVIDER_BY_ID } from 'gql/serviceProvider'
import { sortByDate } from 'utils/sort'
import { EventExtended } from 'types/event'
import { getEventContent } from 'utils/content'
import { Theme } from '@mui/material/styles'
import VisibilityIcon from '@mui/icons-material/Visibility'
import {
  getContactSectionContent,
  getRelationsSectionContent,
  hasRelations,
  Section,
} from './constants'

const tabs = (
  theme: Theme,
  intl: IntlShape,
  data: {
    record: ServiceProviderExtended | undefined
    workspaceId: string
    relations: ServiceProviderRelations | undefined
  },
  methods: {
    handleOnEditWithId: (sectionId: Section, recordId?: string) => void
    showSnackbar?: (message: string, severity: Severity) => void
    showDocUploadModal: () => void
    toggleDelete: () => void
    handleOnDeleteEvent: (recordId: string) => void
    showEventDetails?: (event: EventExtended) => void
    hideEventDetails?: () => void
  }
): TabSection[] => {
  const {
    showDocUploadModal,
    handleOnEditWithId,
    handleOnDeleteEvent,
    toggleDelete,
    showEventDetails,
    hideEventDetails,
  } = methods
  const { record, workspaceId, relations } = data
  const ownerDocuments =
    record?.ownerArchives?.filter((i) => i.type === FileType.document) ?? []
  const workspaceDocuments =
    record?.workspaceArchives?.filter((i) => i.type === FileType.document) ?? []
  const events = sortByDate(record?.events ?? [], 'date')

  const publicDocTitle = record?.isParentWorkspace
    ? 'label.documents'
    : 'label.publicDocuments'

  const refetchQueries = [
    {
      query: SERVICE_PROVIDER_BY_ID,
      variables: { payload: { providerId: record?.id ?? '', workspaceId } },
    },
  ]
  const contactInfo = record ? getContactSectionContent(record, intl) : []
  const relationsContent = getRelationsSectionContent(
    theme,
    intl,
    workspaceId,
    relations
  )
  const classifiedOrders = classifyOrders(workspaceId, relations?.orders)

  return [
    {
      key: 'contact-info',
      label: 'label.contactInfo',
      visible: !!record,
      content: record ? (
        <SectionRender
          header={intl.formatMessage({ id: 'label.contactInfo' })}
          headerActions={
            record?.isParentWorkspace
              ? [
                  {
                    ariaLabel: 'edit info',
                    icon: (props) => <Pencil {...props} />,
                    onPress: () => handleOnEditWithId(Section.contact),
                  },
                ]
              : undefined
          }
          entries={[{ data: contactInfo }]}
        />
      ) : null,
    },
    {
      key: 'relations',
      label: 'label.relations',
      visible: hasRelations(relations),
      content: record ? (
        <SectionRender
          header={intl.formatMessage({ id: 'label.relations' })}
          entries={relationsContent}
        />
      ) : null,
    },
    {
      key: 'purchases',
      label: 'label.purchases',
      visible: classifiedOrders.purchases.length > 0,
      content: (
        <SectionRender
          header={intl.formatMessage({ id: 'label.relatedPurchases' })}
          entries={classifiedOrders.purchases.map((order) => ({
            data: getOrderContent(order, intl, workspaceId, true),
            content: (
              <React.Fragment>
                <Box height={10} />
                <CollapsibleTable order={order} workspaceId={workspaceId} />
              </React.Fragment>
            ),
          }))}
        />
      ),
    },
    {
      key: 'sales',
      label: 'label.sales',
      visible: classifiedOrders.sales.length > 0,
      content: (
        <SectionRender
          header={intl.formatMessage({ id: 'label.relatedSales' })}
          entries={classifiedOrders.sales.map((order) => ({
            data: getOrderContent(order, intl, workspaceId, true),
            content: (
              <React.Fragment>
                <Box height={10} />
                <CollapsibleTable order={order} workspaceId={workspaceId} />
              </React.Fragment>
            ),
          }))}
        />
      ),
    },
    {
      key: 'service-provider-events',
      label: 'label.events',
      visible: true,
      content: (
        <SectionRender
          header={intl.formatMessage({ id: 'label.events' })}
          headerActions={[
            {
              ariaLabel: 'add event',
              icon: () => <AddIcon color="disabled" />,
              onPress: () => handleOnEditWithId(Section.events),
            },
          ]}
          entries={
            events.length > 0
              ? events.map((event: EventExtended) => ({
                  title: event.title,
                  onClick: () => showEventDetails?.(event),
                  description: event.description,
                  data: getEventContent(workspaceId, event, intl),
                  actions: [
                    {
                      ariaLabel: 'see event details',
                      onPress: (
                        e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
                      ) => {
                        showEventDetails?.(event)
                        e?.stopPropagation()
                        e?.preventDefault()
                      },
                      icon: () => (
                        <VisibilityIcon color="disabled" fontSize="small" />
                      ),
                    },
                    {
                      ariaLabel: 'add event document',
                      onPress: (
                        e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
                      ) => {
                        hideEventDetails?.()
                        handleOnEditWithId(Section.eventDocuments, event.id)
                        e?.stopPropagation()
                        e?.preventDefault()
                      },
                      icon: () => (
                        <DocumentIcon color="primary" fontSize="small" />
                      ),
                    },
                    {
                      ariaLabel: 'edit event',
                      onPress: (
                        e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
                      ) => {
                        hideEventDetails?.()
                        handleOnEditWithId(Section.events, event.id)
                        e?.stopPropagation()
                        e?.preventDefault()
                      },
                      //  @ts-ignore
                      icon: (props) => <Pencil {...props} />,
                    },
                    {
                      ariaLabel: 'delete event',
                      onPress: (
                        e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
                      ) => {
                        hideEventDetails?.()
                        handleOnDeleteEvent(event.id)
                        e?.stopPropagation()
                        e?.preventDefault()
                      },
                      icon: () => <DeleteIcon color="error" fontSize="small" />,
                    },
                  ],
                }))
              : [{ description: intl.formatMessage({ id: 'label.noData' }) }]
          }
        />
      ),
    },

    // Owner workspace Document grid
    {
      key: 'public-documents',
      label: publicDocTitle,
      visible: record?.isParentWorkspace || ownerDocuments.length > 0,
      content: (
        <SectionRender
          header={intl.formatMessage({ id: publicDocTitle })}
          headerActions={
            record?.isParentWorkspace
              ? [
                  {
                    ariaLabel: 'add document',
                    icon: (props) => <AddIcon color="disabled" />,
                    onPress: showDocUploadModal,
                  },
                ]
              : undefined
          }
          entries={[
            {
              description:
                ownerDocuments.length === 0
                  ? intl.formatMessage({ id: 'label.noDocuments' })
                  : undefined,
            },
          ]}
        >
          {ownerDocuments.length > 0 && (
            <DocumentGrid
              documents={ownerDocuments}
              refetchQueries={refetchQueries}
            />
          )}
        </SectionRender>
      ),
    },
    // Active workspace Document grid
    {
      key: 'workspace-documents',
      label: 'label.yourDocuments',
      visible: !record?.isParentWorkspace,
      content: (
        <SectionRender
          header={intl.formatMessage({ id: 'label.yourDocuments' })}
          headerActions={[
            {
              ariaLabel: 'add document',
              icon: (props) => <AddIcon color="disabled" />,
              onPress: showDocUploadModal,
            },
          ]}
          entries={[
            {
              description:
                workspaceDocuments.length === 0
                  ? intl.formatMessage({ id: 'label.noDocuments' })
                  : undefined,
            },
          ]}
        >
          {workspaceDocuments.length > 0 && (
            <DocumentGrid
              documents={workspaceDocuments}
              refetchQueries={refetchQueries}
            />
          )}
        </SectionRender>
      ),
    },
    {
      key: 'danger-zone',
      label: 'label.dangerZone',
      visible: record?.isParentWorkspace,
      content: record ? (
        <DangerSectionRender
          entries={[
            {
              title: intl.formatMessage({ id: 'label.dangerZone' }),
              actions: [
                {
                  ariaLabel: 'delete service provider',
                  icon: (props) => <TrashIcon {...props} />,
                  onPress: toggleDelete,
                  label: intl.formatMessage({ id: 'label.delete' }),
                  infoLabel: intl.formatMessage({
                    id: 'label.deleteServiceProvider',
                  }),
                },
              ],
            },
          ]}
        />
      ) : null,
    },
  ].filter((section) => section.visible)
}

export default tabs
