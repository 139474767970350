import React, { Component } from 'react'
import {
  BasicUserStruct,
  OrganizationMemberExtended,
  WorkspaceMemberExtended,
  isOrganizationMember,
} from 'types/user'
import appConfig from 'config/app'
import { NavigateFunction } from 'react-router-dom'
import ROUTES from 'lib/routes'
import { getActiveWorkspaceId } from 'lib/sessionStorage'
import { MemberModels } from 'types/shared'
import Avatar from '../Avatar'
import { BadgeWrapper, RemoveButton, RemoveIcon } from './fragments'

export type UserType =
  | BasicUserStruct
  | OrganizationMemberExtended
  | WorkspaceMemberExtended

interface Props {
  user: UserType
  deleteMemberRef?: (refId: string) => void
  size?: number
  navigate?: NavigateFunction
}

class UserBadge extends Component<Props> {
  get user() {
    return this.props.user
  }

  get refId() {
    // eslint-disable-next-line no-prototype-builtins
    if (this.user.hasOwnProperty('refId')) {
      return this.user.refId
    }
    return undefined
  }

  get avatar() {
    let serverPath = null
    if (isOrganizationMember(this.user))
      serverPath = this.user.user.avatar?.avatar
    else serverPath = this.user?.avatar?.avatar
    const src = serverPath ? `${appConfig.media}${serverPath}` : undefined
    return src
  }

  get firstName() {
    if (isOrganizationMember(this.user)) return this.user?.user.firstName

    return this.user.firstName
  }

  get memberModel() {
    if (isOrganizationMember(this.user)) return MemberModels.ORGANIZATION_MEMBER
    return MemberModels.WORKSPACE_MEMBER
  }

  get lastName() {
    if (isOrganizationMember(this.user)) return this.user?.user.lastName
    return this.user.lastName
  }

  get placeholder() {
    return `${this.firstName?.substring(0, 1).toUpperCase() ?? ''}${
      this.lastName?.substring(0, 1).toUpperCase() ?? ''
    }`
  }

  get tooltip() {
    return `${this.firstName} ${this.lastName}`
  }

  render() {
    const handleDeleteRef = () =>
      this.refId && this.props.deleteMemberRef?.(this.refId)
    const workspaceId = getActiveWorkspaceId()

    const navToMemberPage = (
      event?: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      event?.stopPropagation()
      event?.preventDefault()
      if (!workspaceId || !this.user.id) return
      this.props.navigate?.(
        ROUTES.DASHBOARD_MEMBERS_DETAILS_ROUTE(
          workspaceId,
          this.user.id,
          this.memberModel
        )
      )
    }

    return (
      <BadgeWrapper>
        <Avatar
          size={this.props.size}
          src={this.avatar}
          placeholder={this.placeholder}
          tooltip={this.tooltip}
          alt={this.tooltip}
          onClick={navToMemberPage}
        />
        {this.props.deleteMemberRef && (
          <RemoveButton disableSpacing={false} onClick={handleDeleteRef}>
            <RemoveIcon color="error" />
          </RemoveButton>
        )}
      </BadgeWrapper>
    )
  }
}

export default UserBadge
