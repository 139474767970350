import React from 'react'
import { Box, Chip, ChipTypeMap, Tab, Tabs } from '@mui/material'
import { useIntl } from 'react-intl'
import {
  UserRequest,
  UserRequestStatus,
  UserRequestType,
} from 'types/userRequest'
import { Column } from 'components/shared'
import { TabContext, TabPanel } from '@mui/lab'
import SectionRender, { SectionEntry } from './SectionRender'

interface Props {
  userRequests: UserRequest[]
}
function UserRequestsSection({ userRequests }: Props) {
  const intl = useIntl()
  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  if (!userRequests || userRequests.length === 0) return null

  const claims = userRequests.filter(
    (req) => req.type === UserRequestType.CLAIM
  )
  const inquiries = userRequests.filter(
    (req) => req.type === UserRequestType.INQUIRY
  )
  const feedbacks = userRequests.filter(
    (req) => req.type === UserRequestType.FEEDBACK
  )

  const tabs = [
    {
      title: intl.formatMessage({ id: 'label.userRequest.claims' }),
      data: claims,
    },
    {
      title: intl.formatMessage({ id: 'label.userRequest.inquiries' }),
      data: inquiries,
    },
    {
      title: intl.formatMessage({ id: 'label.userRequest.feedbacks' }),
      data: feedbacks,
    },
  ].filter((tab) => tab.data.length > 0)

  return (
    <Column>
      <SectionRender header={intl.formatMessage({ id: 'label.userRequests' })}>
        <TabContext value={value.toString()}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange}>
              {tabs.map((tab, index) => (
                <Tab
                  label={`${tab.title} (${tab.data.length})`}
                  key={tab.title}
                  {...a11yProps(index)}
                />
              ))}
            </Tabs>
          </Box>
          {tabs.map((tab, index) => (
            <TabPanel
              key={`tab-panel-${tab.title}`}
              value={index.toString()}
              tabIndex={index}
            >
              <SectionRender
                containerStyle={{ padding: 0 }}
                entries={tab.data.map(
                  (request) =>
                    ({
                      description: request.referenceNumber,
                      headlineContent: (
                        <Chip
                          color={getStatusColor(request.status)}
                          label={intl.formatMessage({
                            id: `input.userRequest.status.${request.status}`,
                          })}
                        />
                      ),
                      data: [
                        {
                          label: intl.formatMessage({ id: 'label.message' }),
                          value: request.message,
                          style: {
                            marginBottom: '10px',
                            whiteSpace: 'pre-line',
                          },
                        },
                        // {
                        //   visible: request.replies.length > 0,
                        //   label: intl.formatMessage({ id: 'label.replies' }),
                        //   value: intl.formatMessage(
                        //     { id: 'label.readXReplies' },
                        //     { number: request.replies.length }
                        //   ),
                        //   isLink: true,
                        //   to: ROUTES.PRICING,
                        // },
                      ],
                    } as SectionEntry)
                )}
              />
            </TabPanel>
          ))}
        </TabContext>
      </SectionRender>
    </Column>
  )
}

function a11yProps(index: number) {
  return {
    id: `user-request-tab-${index}`,
    'aria-controls': `user-request-tabpanel-${index}`,
  }
}

function getStatusColor(
  status: UserRequestStatus
): ChipTypeMap['props']['color'] {
  switch (status) {
    case UserRequestStatus.CLOSED:
      return 'default'
    case UserRequestStatus.OPEN:
      return 'primary'
    case UserRequestStatus.REJECTED:
      return 'warning'
    default:
      return 'default'
  }
}

export default UserRequestsSection
