import { gql } from '@apollo/client'

export const CREATE_EVENT = gql`
  mutation CREATE_EVENT($payload: EventInput!) {
    createEventRecord(payload: $payload) {
      id
    }
  }
`

export const UPDATE_EVENT = gql`
  mutation UPDATE_EVENT($payload: UpdateEventInput!) {
    updateEventRecord(payload: $payload) {
      id
    }
  }
`

export const DELETE_EVENT = gql`
  mutation DELETE_EVENT($id: ID!) {
    deleteEventRecord(id: $id) {
      status
    }
  }
`

export const EVENTS_TIMELINE = gql`
  query EVENTS_TIMELINE($workspaceId: ID!) {
    eventsTimeline(workspaceId: $workspaceId) {
      id
      title
      date
      itemId
      providerId
      contractId
      description
      amount {
        value
        currency
      }
      createdAt
      updatedAt
    }
  }
`
