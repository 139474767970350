import React, { useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import ROUTES from 'lib/routes'
import { useMutation } from '@apollo/client'
import { Button } from 'components/shared'
import LoadingIndicator from 'components/LoadingIndicator'
import { Severity, withSnackbar } from 'components/providers/SnackbarHOC'
import InputRender, { formHasErrors } from 'components/input/InputRender'
import { FormDataType } from 'components/input/types'
import { RESET_PASSWORD } from 'gql/email'
import { Toolbar } from '@mui/material'
import { BodyTextLight, H5 } from 'components/typography'
import { ResetPasswordData, ResetPasswordInput } from 'types/email'
import { inputs } from './constants'
import {
  Container,
  Form,
  LinkButton,
  RightGrid,
  ScreenGrid,
  SuccessMessageWrapper,
} from '../fragments'
import MuiHeader from 'pages/landing/MuiHeader'
import QuotesGrid from '../QuotesGrid'
import StyledText from 'components/StyledIntlText'

interface LoginProps {
  showSnackbar?: (message: string, severity: Severity) => void
}

function Page(props: LoginProps) {
  const { showSnackbar } = props
  const intl = useIntl()
  const { token } = useParams() as { token: string }
  const location = useLocation()
  const isDashboard = location.pathname.includes(ROUTES.DASHBOARD)
  const [showErrors, setShowErrors] = useState(false)
  const [success, setSuccess] = useState(false)
  const [data, setData] = useState<FormDataType>({
    password: '',
    confirmPassword: '',
  })

  const [resetPassword, { loading }] = useMutation<
    ResetPasswordData,
    ResetPasswordInput
  >(RESET_PASSWORD, {
    onCompleted: (response) => {
      if (response.resetPassword.status === 'success') {
        showSnackbar?.(
          intl.formatMessage({ id: 'label.updateSuccessful' }),
          Severity.SUCCESS
        )
        setSuccess(true)
      }
    },
    onError: (error) => {
      showSnackbar?.(error.message, Severity.ERROR)
    },
  })
  const formInputs = inputs(data)
  const handleOnsubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (formHasErrors(formInputs, data)) {
      setShowErrors(true)
      return
    }
    if (!token) return
    const payload = { password: data.password as string, token }
    resetPassword({ variables: { payload } })
  }

  return (
    <Container>
      {!isDashboard && (
        <React.Fragment>
          <MuiHeader />
          <Toolbar />
        </React.Fragment>
      )}
      <LoadingIndicator visible={loading} />
      <ScreenGrid container>
        <QuotesGrid />
        <RightGrid item md={6} xs={12}>
          {success ? (
            <SuccessMessageWrapper>
              <StyledText text="label.resetPasswordUnderline" fontSize={30} />
              <BodyTextLight opacity=".5" style={{ marginTop: '20px' }}>
                {intl.formatMessage({
                  id: 'label.resetPasswordSuccessful',
                })}
              </BodyTextLight>
              <LinkButton to={ROUTES.LOGIN}>
                <H5>{intl.formatMessage({ id: 'label.toLogin' })}</H5>
              </LinkButton>
            </SuccessMessageWrapper>
          ) : (
            <React.Fragment>
              <Form noValidate onSubmit={handleOnsubmit}>
                <StyledText text="label.resetPasswordUnderline" fontSize={30} />
                <br />
                <br />
                <BodyTextLight opacity=".5">
                  {intl.formatMessage({
                    id: 'label.resetPasswordConfirmationDescription',
                  })}
                </BodyTextLight>
                <br />
                {formInputs.map((input) => {
                  return (
                    <InputRender
                      key={input.key}
                      data={data}
                      input={input}
                      inputs={formInputs}
                      setData={setData}
                      showErrors={showErrors}
                    />
                  )
                })}
                <br />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  {intl.formatMessage({ id: 'label.resetPassword' })}
                </Button>
              </Form>
            </React.Fragment>
          )}
        </RightGrid>
      </ScreenGrid>
    </Container>
  )
}

Page.defaultProps = {
  showSnackbar: undefined,
}
export default withSnackbar(Page)
