import React from 'react'
import { OrganizationMemberExtended } from 'types/user'
import { H4 } from 'components/typography'
import appConfig from 'config/app'
import UserCard from './UserCard'

interface Props {
  member: OrganizationMemberExtended
}

class OrganizationMemberCard extends UserCard<Props, {}> {
  get editable(): boolean {
    return false
  }

  get avatarLink() {
    const imagePath = this.props.member.user.avatar?.avatar
    return imagePath ? `${appConfig.media}${imagePath}` : null
  }

  name() {
    const { member } = this.props
    const { user } = member
    return (
      <H4>
        {user.firstName} {user.lastName ?? ''}
      </H4>
    )
  }
}

export default OrganizationMemberCard
