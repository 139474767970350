import React from 'react'
import { GridColDef } from '@mui/x-data-grid'
import { TitleCellCover } from 'components/table/datagrid'
import { IntlShape } from 'react-intl'
import { BodyText } from 'components/typography'

export const columns = (intl: IntlShape): GridColDef[] => [
  {
    field: 'id',
    headerName: '#',
    width: 0,
    sortable: false,
    headerAlign: 'center',
    hide: true,
  },
  {
    field: 'title',
    headerName: intl.formatMessage({ id: 'label.title' }),
    width: 250,
    headerAlign: 'center',
    renderCell: (row) => <TitleCellCover rowData={row} entity="group" />,
    hideable: false,
  },
  {
    field: 'description',
    headerName: intl.formatMessage({ id: 'label.description' }),
    width: 250,
    headerAlign: 'center',
    renderCell: (row) => <BodyText>{row.value}</BodyText>,
    hideable: false,
  },
]
