import React, { useCallback, useEffect } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useParams } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import { Container, WorkspaceContainersData } from 'types/container'
import { Severity, withSnackbar } from 'components/providers/SnackbarHOC'
import { GridColDef, GridRowId } from '@mui/x-data-grid'
import { WORKSPACE_CONTAINERS } from 'gql/container'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { ModalTitle, RowSpaced, Row, SimpleButton } from 'components/shared'
import { DataGridComp, TitleCell } from 'components/table/datagrid'
import { IntlShape, useIntl } from 'react-intl'
import { useQuery } from '@apollo/client'
import Grid from '@mui/material/Grid'
import GridSelectionModal from 'components/GridSelectionModal'
import { Action } from 'types/shared'
import SearchInput from '../SearchInput'
import { BodyText } from '../typography'
import { Content, ActionBarWrapper, Drawer } from './components'

const columns = (intl: IntlShape, isMobileScreen: boolean): GridColDef[] => [
  {
    field: 'id',
    headerName: '#',
    width: 0,
    sortable: false,
    headerAlign: 'center',
    hide: true,
  },
  {
    field: 'title',
    headerName: intl.formatMessage({ id: 'label.title' }),
    width: 200,
    headerAlign: 'center',
    renderCell: (row) => <TitleCell rowData={row} entity={null} />,
    hideable: false,
  },
  {
    field: 'description',
    headerName: intl.formatMessage({ id: 'label.description' }),
    width: 250,
    headerAlign: 'center',
    renderCell: (row) => <BodyText>{row.value}</BodyText>,
    hide: isMobileScreen,
  },
]

function hasChanges(
  preSelectedRecords: Container[],
  selectedRecords: GridRowId[]
) {
  const preSelectedIds: GridRowId[] = preSelectedRecords.map((r) => r.id)
  return (
    preSelectedIds.length !== selectedRecords.length ||
    preSelectedIds.some((id) => !selectedRecords.includes(id))
  )
}

// get preselected ids
function idsInRange(
  preSelectedRecords: Container[],
  displayedItems: Container[]
) {
  return preSelectedRecords
    .filter((r) => displayedItems.some((i) => i.id === r.id))
    .map((r) => r.id)
}

// get ids that are not in current displayed page but are in preselected
function idsOutOfRange(
  preSelectedRecords: Container[],
  displayedItems: Container[],
  selected: GridRowId[]
): GridRowId[] {
  return preSelectedRecords
    .filter((r) => {
      return (
        !displayedItems.some((i) => i.id === r.id) && !selected.includes(r.id)
      )
    })
    .map((r) => r.id)
}

interface Props {
  open: boolean
  preSelectedRecords: Container[]
  toggleDrawer: () => void
  showSnackbar?: (message: string, severity: Severity) => void
  onSubmit?: (selectedIds: string[]) => void
  actions?: {
    label: string
    onClick: () => void
  }[]
}

function BoxSelectDrawer(props: Props) {
  const {
    open,
    toggleDrawer,
    preSelectedRecords,
    onSubmit,
    actions,
    showSnackbar,
  } = props

  const intl = useIntl()
  const theme = useTheme()
  const params = useParams() as { workspaceId: string }
  const [records, setRecords] = React.useState<Container[]>([])
  const [search, setSearch] = React.useState('')
  const [total, setTotal] = React.useState(0)
  const [page, setPage] = React.useState(0)
  const [pageSize, setPageSize] = React.useState(25)
  const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([])

  const payload = {
    workspaceId: params.workspaceId,
    limit: pageSize,
    page,
    filters: { search },
  }
  useQuery<WorkspaceContainersData>(WORKSPACE_CONTAINERS, {
    variables: { payload },
    onCompleted: (response) => {
      setSelectionModel(
        idsInRange(preSelectedRecords, response.workspaceContainers.list)
      )
      setRecords(response.workspaceContainers.list)
      setTotal(response.workspaceContainers.total)
    },
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
  })

  const onPageChange = useCallback((_page: number) => setPage(_page), [setPage])
  const onPageSizeChange = useCallback(
    (_pageSize: number) => setPageSize(_pageSize),
    [setPageSize]
  )
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const handleOnClose = () => {
    clearSelection()
    toggleDrawer()
  }

  const selectedAll = selectionModel.length === records.length
  const clearSelection = () => setSelectionModel([])
  const selectAllRecords = () => setSelectionModel(records.map((r) => r.id))

  const actionBarActions: Action[] = [
    {
      label: selectedAll ? 'label.clearSelection' : 'label.selectAll',
      onClick: selectedAll ? clearSelection : selectAllRecords,
      variant: 'outlined',
    },
    {
      label: 'label.save',
      onClick: () => {
        const itemIds = [
          ...idsOutOfRange(preSelectedRecords, records, selectionModel),
          ...selectionModel,
        ] as string[]
        onSubmit?.(itemIds)
      },
      variant: 'contained',
    },
  ]

  useEffect(() => {
    // set the initially selected records
    const selected: GridRowId[] = preSelectedRecords.map((r) => r.id) || []
    setSelectionModel(selected)
  }, [preSelectedRecords, open])

  const preSelectedOnPage = preSelectedRecords.filter((r) =>
    records.some((i) => i.id === r.id)
  )
  const showActionBar = hasChanges(preSelectedOnPage, selectionModel)

  return (
    <Drawer anchor="right" open={open} onClose={handleOnClose}>
      <Content>
        <Grid container spacing={1} padding={2}>
          <Grid item xs={12} md={6}>
            <RowSpaced>
              <Row>
                <ModalTitle>
                  {intl.formatMessage({ id: 'label.containersAtNest' })}
                </ModalTitle>
                {actions?.map((action) => (
                  <SimpleButton onClick={action.onClick} key={action.label}>
                    {action.label}
                  </SimpleButton>
                ))}
              </Row>
              {isMobileScreen && (
                <IconButton onClick={handleOnClose}>
                  <CloseIcon />
                </IconButton>
              )}
            </RowSpaced>
          </Grid>
          <Grid item xs={12} md={6}>
            <SearchInput onSubmit={setSearch} />
          </Grid>
        </Grid>

        <DataGridComp
          initialState={{ pagination: { page, pageSize } }}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          rows={records}
          columns={columns(intl, isMobileScreen)}
          checkboxSelection
          disableSelectionOnClick
          rowsPerPageOptions={[25, 50, 100]}
          rowCount={total || 0}
          paginationMode="server"
          onSelectionModelChange={setSelectionModel}
          selectionModel={selectionModel}
        />
        <ActionBarWrapper>
          {showActionBar && (
            <GridSelectionModal
              selectedRows={selectionModel}
              actions={actionBarActions}
            />
          )}
        </ActionBarWrapper>
      </Content>
    </Drawer>
  )
}

BoxSelectDrawer.defaultProps = {
  onSubmit: null,
  actions: null,
  showSnackbar: undefined,
}
// @ts-ignore
export default withSnackbar(BoxSelectDrawer)
