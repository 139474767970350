import React, { useState } from 'react'
import { getAuth } from 'lib/localStorage'
import appConfig from 'config/app'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import 'react-slideshow-image/dist/styles.css'
import { styled } from '@mui/material/styles'
import { ArchiveFile } from 'types/archive'
import Fade from '@mui/material/Fade'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import More from '@mui/icons-material/MoreVert'
import Close from '@mui/icons-material/Close'
import { Severity, withSnackbar } from 'components/providers/SnackbarHOC'
import { ModalTransition } from 'components/shared'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useIntl } from 'react-intl'
import { InternalRefetchQueriesInclude, useMutation } from '@apollo/client'
import { DELETE_ARCHIVE } from 'gql/assets'
import {
  ConfirmationData,
  withConfirmationModal,
} from 'components/hoc/ConfirmationModalHoc'
import { DeleteArchiveData, DeleteArchiveInput } from 'types/assets'
import { downloadDocument } from 'utils/file'

const Image = styled('img')(({ theme }) => ({
  objectFit: 'contain',
  flex: 1,
  width: '100%',
}))

const HeaderButton = styled(IconButton)(({ theme }) => ({
  border: `2px solid ${theme.palette.primary.main}`,
  borderRadius: '5px',
  p: 0,
  boxShadow: '1px 1px 5px rgba(0, 0, 0, 0.2)',
}))

const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  right: 0,
  left: 0,
  padding: '10px 20px',
  position: 'absolute',
  justifyContent: 'flex-end',
  zIndex: 1,
}))

const CloseIcon = styled(Close)(({ theme }) => ({
  color: theme.palette.primary.main,
  width: '20px',
  height: '20px',
}))

const MoreIcon = styled(More)(({ theme }) => ({
  color: theme.palette.primary.main,
  width: '20px',
  height: '20px',
}))

const SimpleHeaderButton = styled(HeaderButton)(({ theme }) => ({
  boxShadow: 'none',
  border: 'none',
}))

interface Props {
  showSnackbar?: (message: string, severity: Severity) => void
  document?: ArchiveFile
  onClose?: () => void
  refetchQueries?: InternalRefetchQueriesInclude
  requestConfirmation?: (data: ConfirmationData) => void
  // callback to call when document is deleted.
  onDelete?: () => void
}
function DocumentPreview({
  document,
  onClose,
  onDelete,
  showSnackbar,
  refetchQueries,
  requestConfirmation,
}: Props) {
  const intl = useIntl()
  const auth = getAuth()
  const screeWidth = window.innerWidth - 50
  const [pages, setPages] = useState<number | null>(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const path = `${appConfig.documentPreview}/${document?.id}?token=${auth?.token}`

  const [deleteArchive] = useMutation<DeleteArchiveData, DeleteArchiveInput>(
    DELETE_ARCHIVE,
    {
      onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
      onCompleted: () => {
        showSnackbar?.(
          intl.formatMessage({ id: 'label.deleteSuccessful' }),
          Severity.SUCCESS
        )
        onDelete?.()
        onClose?.()
      },
      refetchQueries,
    }
  )

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) =>
    setPages(numPages)
  const openMenu = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget)
  const closeMenu = () => setAnchorEl(null)
  const open = Boolean(anchorEl)
  const toggleDelete = () => {
    closeMenu()
    requestConfirmation?.({
      title: intl.formatMessage({ id: 'label.deletingAssetTitle' }),
      description: intl.formatMessage({
        id: 'label.deletingAssetDescription',
      }),
      actions: [
        {
          label: intl.formatMessage({ id: 'label.cancel' }),
          onClick: closeMenu,
        },
        {
          label: intl.formatMessage({ id: 'label.delete' }),
          color: 'error',
          onClick: handleDelete,
        },
      ],
    })
  }
  const handleDelete = () => {
    if (!document?.id) return
    const variables = { archiveId: document?.id }
    deleteArchive({ variables })
  }
  const handleDownload = () => downloadDocument(path)

  return (
    <Dialog
      open={!!document}
      fullScreen
      TransitionComponent={ModalTransition}
      onClose={onClose}
    >
      <DialogContent sx={{ padding: 0 }}>
        <Header>
          <SimpleHeaderButton onClick={openMenu}>
            <MoreIcon />
          </SimpleHeaderButton>
          <Menu
            id="fade-menu"
            MenuListProps={{ 'aria-labelledby': 'fade-button' }}
            anchorEl={anchorEl}
            open={open}
            onClose={closeMenu}
            TransitionComponent={Fade}
          >
            <MenuItem onClick={handleDownload}>
              {intl.formatMessage({ id: 'label.download' })}
            </MenuItem>
            <MenuItem onClick={toggleDelete}>
              {intl.formatMessage({ id: 'label.delete' })}
            </MenuItem>
          </Menu>
          <HeaderButton onClick={onClose}>
            <CloseIcon />
          </HeaderButton>
        </Header>
        {/* @ts-ignore */}
        <Document
          file={path}
          renderMode="svg"
          onLoadSuccess={onDocumentLoadSuccess}
          error={<Image src={path} />}
        >
          {Array.from(new Array(pages), (el, index) => (
            // @ts-ignore
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              width={screeWidth}
            />
          ))}
        </Document>
      </DialogContent>
    </Dialog>
  )
}

DocumentPreview.defaultProps = {
  onClose: null,
  document: null,
  showSnackbar: undefined,
  refetchQueries: undefined,
  requestConfirmation: undefined,
}
// @ts-ignore
export default withConfirmationModal(withSnackbar(DocumentPreview))
