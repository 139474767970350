import React from 'react'
import { useNavigate } from 'react-router-dom'
import AddIcon from '@mui/icons-material/AddSharp'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useIntl } from 'react-intl'
import Grid from '@mui/material/Grid'
import { max } from 'lodash'
import { useApolloClient, useQuery } from '@apollo/client'
import { Severity, withSnackbar } from 'components/providers/SnackbarHOC'
import { Workspace } from 'types/workspace'
import { Container, SectionCTA } from 'components/shared'
import StyledText from 'components/StyledIntlText'
import { PROFILE } from 'gql/user'
import { ProfileData } from 'types/profile'
import ROUTES from 'lib/routes'
import { clearSession } from 'lib/localStorage'
import WorkspaceCard from 'components/WorkspaceCard'
import { saveActiveWorkspaceId } from 'lib/sessionStorage'
import LoadingIndicator from 'components/LoadingIndicator'
import { useGroupedWorkspaces } from 'hooks/graphql/workspaces'
import {
  ContentElement,
  EmptyDashboard,
  RowWrapper,
  NoWorkspacesCard,
  OrganizationPlanBanner,
} from './fragments'

interface Props {
  showSnackbar?: (message: string, severity: Severity) => void
}
function DashboardLanding({ showSnackbar }: Props) {
  const theme = useTheme()
  const intl = useIntl()
  const client = useApolloClient()
  const navigate = useNavigate()
  const isBigScreen = useMediaQuery(theme.breakpoints.up('md'))

  const { data, loading: profileLoading } = useQuery<ProfileData>(PROFILE)
  const profile = data?.profile
  const { data: sections, loading: wsLoading } = useGroupedWorkspaces()

  const onSelectWorkspace = (workspace: Workspace) => {
    saveActiveWorkspaceId(workspace.id)
    navigate(ROUTES.DASHBOARD_OVERVIEW_ROUTE(workspace.id), { replace: true })
  }

  const toCreateWorkspace = (organizationId: string) => {
    // saveActiveWorkspaceId(null) // remove selected workspace
    navigate(ROUTES.DASHBOARD_CREATE_WORKSPACE_ROUTE(organizationId))
  }

  const handleSignOut = () => {
    clearSession()
    client.clearStore()
    navigate(ROUTES.INDEX, { replace: true })
  }

  const isLoading = profileLoading || wsLoading

  // the user is not associated with any organizations or workspaces
  const isNewUser = !isLoading && sections.length === 0

  return (
    <Container>
      <LoadingIndicator visible={isLoading} />
      <ContentElement>
        <RowWrapper>
          <StyledText
            text={
              isNewUser ? 'dashboard.welcomeToApp' : 'dashboard.welcomeUser'
            }
            fontSize={isBigScreen ? 24 : 18}
            injectedValues={{ name: profile?.firstName ?? '👋' }}
          />
          <SectionCTA color="primary" onClick={handleSignOut}>
            {intl.formatMessage({ id: 'label.logout' })}
          </SectionCTA>
        </RowWrapper>
        {isNewUser && <EmptyDashboard showSnackbar={showSnackbar} />}
        {sections.map((section) => (
          <div key={section.organizationId}>
            <RowWrapper style={{ justifyContent: 'space-between' }}>
              <StyledText
                text={
                  profile?.id === section.ownerId
                    ? 'label.yourBoards'
                    : 'label.boardsFrom'
                }
                injectedValues={{ name: section.title }} // TODO: add org title or owners name
                fontSize={isBigScreen ? 22 : 16}
              />
              <SectionCTA
                color="primary"
                onClick={() => toCreateWorkspace(section.organizationId)}
              >
                <AddIcon /> {intl.formatMessage({ id: 'label.addBoard' })}
              </SectionCTA>
            </RowWrapper>
            <OrganizationPlanBanner
              section={section}
              userId={profile?.id ?? ''}
            />
            {section.workspaces.length === 0 && <NoWorkspacesCard />}

            <Grid container spacing={3} marginTop={0.1}>
              {section.workspaces.map((workspace) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={max([12 / (section.workspaces?.length + 1), 4])}
                  xl={max([12 / (section.workspaces?.length + 1), 4])}
                  key={workspace.id}
                >
                  <WorkspaceCard
                    workspace={workspace}
                    onClick={onSelectWorkspace}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        ))}
      </ContentElement>
    </Container>
  )
}

DashboardLanding.defaultProps = {
  showSnackbar: undefined,
}

export default withSnackbar(DashboardLanding)
