import { styled } from '@mui/material/styles'

export const CoverImage = styled('img')(({ theme }) => ({
  width: '100%',
  height: '200px',
  objectFit: 'cover',
  [theme.breakpoints.down('md')]: {
    height: '150px',
  },
}))

export const Label = styled('span')(({ theme }) => ({
  fontFamily: 'NoirPro',
  fontSize: '16px',
  color: theme.palette.text.primary,
}))

export const DangerLabel = styled(Label)(({ theme }) => ({
  color: theme.palette.error.main,
}))

export const SectionContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: '5px',
  marginTop: '16px',
  overflow: 'hidden',
}))
