import { useNavigate } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { SERVICE_PROVIDERS_LIST } from 'gql/serviceProvider'
import { ServiceProvidersListData } from 'types/serviceProvider'
import {
  CreateItemListingData,
  CreateItemListingInput,
  CreateManufactureData,
  CreateManufactureInput,
  DeleteItemSaleInfoData,
  DeleteItemSaleInfoInput,
  DeleteItemsData,
  DeleteItemsInput,
  ItemByIdData,
  // TransferItemsData,
  // TransferItemsInput,
  UpdateItemData,
  UpdateItemInput,
  UpdateItemSalesInfoData,
  UpdateItemSalesInfoInput,
  UpdateManufactureData,
  UpdateManufactureInput,
} from 'types/item'
import {
  ADD_ITEM_LISTING,
  ADD_ITEM_MANUFACTURE,
  CREATE_ORDER_RECORD,
  DELETE_ITEMS,
  DELETE_ITEM_LISTING,
  DELETE_ORDER_RECORD,
  ITEM_BY_ID,
  // TRANSFER_ITEMS,
  UPDATE_ITEM,
  UPDATE_ITEM_LISTING,
  UPDATE_ITEM_MANUFACTURE,
  UPDATE_ORDER_ITEM_REF_RECORD,
  UPDATE_ORDER_RECORD,
  WORKSPACE_ITEMS,
} from 'gql/item'
import { CATEGORIES } from 'gql/category'
import { CategoriesData } from 'types/category'
import { WORKSPACE_INVENTORY } from 'gql/inventory'
// import ROUTES from 'lib/routes'
import { Severity } from 'components/providers/SnackbarHOC'
import { AddFileToItemData } from 'types/archive'
import { ADD_FILE_TO_ITEM } from 'gql/archive'
import { useWorkspaces } from 'hooks/graphql/workspaces'
import {
  CreateOrderRecordData,
  CreateOrderRecordInput,
  DeleteOrderRecordData,
  DeleteOrderRecordInput,
  UpdateOrderInputData,
  UpdateOrderItemRefInputData,
  UpdateOrderItemRefRecordInput,
  UpdateOrderRecordInput,
} from 'types/order'
import { ItemGroupsData } from 'types/group'
import { ITEM_GROUPS } from 'gql/group'
import { IntlShape } from 'react-intl'
import { CREATE_MEMBER_ITEM_REF, DELETE_MEMBER_ITEM_REF } from 'gql/memberRefs'
import {
  CreateMemberItemRefData,
  CreateMemberItemRefInput,
  DeleteMemberRefInput,
  DeleteMemberItemRefData,
} from 'types/memberRefs'
import { WORKSPACE_MEMBERS } from 'gql/workspace'
import { WorkspaceMembersData } from 'types/workspace'
import { useMutations as eventMutations } from 'hooks/graphql/event'
import { WORKSPACE_CONTRACTS } from 'gql/contracts'
import { WorkspaceContractsData } from 'types/contracts'

export function useData(
  workspaceId: string,
  itemId: string,
  methods: {
    showSnackbar?: (message: string, severity: Severity) => void
    refreshEventDrawer: () => void
  }
) {
  const {
    data: itemData,
    loading: itemLoading,
    refetch,
  } = useQuery<ItemByIdData>(ITEM_BY_ID, {
    variables: { itemId },
    fetchPolicy: 'cache-and-network',
    onCompleted: methods.refreshEventDrawer,
    onError: (error) => methods.showSnackbar?.(error.message, Severity.ERROR),
  })
  const { data: categoriesData, loading: categoriesLoading } =
    useQuery<CategoriesData>(CATEGORIES)

  const { loading: providersLoading, data: serviceProviderList } =
    useQuery<ServiceProvidersListData>(SERVICE_PROVIDERS_LIST, {
      variables: { payload: { workspaceId } },
    })

  const { data: groupsData, loading: groupsLoading } = useQuery<ItemGroupsData>(
    ITEM_GROUPS,
    {
      variables: { itemId },
    }
  )
  const { data: wsMembers, loading: membersLoading } =
    useQuery<WorkspaceMembersData>(WORKSPACE_MEMBERS, {
      variables: { workspaceId },
    })
  const { data: wsContractsData, loading: contractsLoading } =
    useQuery<WorkspaceContractsData>(WORKSPACE_CONTRACTS, {
      variables: { payload: { workspaceId } },
    })

  const contracts = wsContractsData?.workspaceContracts.list || []
  const members = wsMembers?.workspaceMembers.members || []
  const owners = wsMembers?.workspaceMembers.owners || []
  const { data: workspaces, loading: wsLoading } = useWorkspaces()
  const activeWs = workspaces.find((ws) => ws.id === workspaceId)
  const orgWorkspaces =
    workspaces?.filter((w) => w.organizationId === activeWs?.organizationId) ||
    []

  const loading =
    providersLoading ||
    itemLoading ||
    wsLoading ||
    categoriesLoading ||
    groupsLoading ||
    membersLoading ||
    contractsLoading

  const data = {
    record: itemData?.itemById,
    orgWorkspaces,
    categories: categoriesData?.categories,
    serviceProviders: serviceProviderList?.serviceProviders,
    groups: groupsData?.itemGroups?.list ?? [],
    members,
    owners,
    contracts,
  }

  return {
    loading,
    data,
    refetch: {
      refetchItem: refetch,
    },
  }
}

export function useMutations(
  workspaceId: string,
  itemId: string,
  categoryId: string,
  methods: {
    showSnackbar?: (message: string, severity: Severity) => void
    hideEditingModal: () => void
  },
  data: {
    intl: IntlShape
  }
) {
  const navigate = useNavigate()
  const { showSnackbar, hideEditingModal } = methods

  const refetchListing = {
    query: WORKSPACE_ITEMS,
    variables: { payload: { workspaceId, limit: 25, page: 0 } },
  }
  const refetchQueries = [{ query: ITEM_BY_ID, variables: { itemId } }]
  const providerRefetch = {
    query: SERVICE_PROVIDERS_LIST,
    variables: { payload: { workspaceId } },
  }
  const [deleteItems, { loading: deletingItems }] = useMutation<
    DeleteItemsData,
    DeleteItemsInput
  >(DELETE_ITEMS, {
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    onCompleted: () => navigate(-1),
    refetchQueries: [
      { query: WORKSPACE_INVENTORY, variables: { payload: { workspaceId } } },
      refetchListing,
    ],
  })

  const [addFileToItem] = useMutation<AddFileToItemData>(ADD_FILE_TO_ITEM, {
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries,
  })

  const [addItemSaleInfo, { loading: addListingLoading }] = useMutation<
    CreateItemListingData,
    CreateItemListingInput
  >(ADD_ITEM_LISTING, {
    onCompleted: hideEditingModal,
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries,
  })

  const [updateItem, { loading: updateLoading }] = useMutation<
    UpdateItemData,
    UpdateItemInput
  >(UPDATE_ITEM, {
    onCompleted: () => {
      hideEditingModal()
      showSnackbar?.(
        data.intl.formatMessage({ id: 'label.updatedSuccessfully' }),
        Severity.SUCCESS
      )
    },
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: [...refetchQueries, refetchListing],
  })

  // NOTE: this is not used on V1 - fix refetch when putting this back
  // const [transferItems, { loading: transferLoading }] = useMutation<
  //   TransferItemsData,
  //   TransferItemsInput
  // >(TRANSFER_ITEMS, {
  //   onCompleted: () =>
  //     navigate(ROUTES.DASHBOARD_ITEMS_ROUTE(workspaceId), { replace: true }),
  //   onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
  //   refetchQueries: [
  //     {
  //       query: WORKSPACE_ITEMS,
  //       variables: { payload: { workspaceId, limit: 25, page: 0 } },
  //     },
  //   ],
  // })

  const [updateItemSalesInfo, { loading: updateSalesLoading }] = useMutation<
    UpdateItemSalesInfoData,
    UpdateItemSalesInfoInput
  >(UPDATE_ITEM_LISTING, {
    onCompleted: hideEditingModal,
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries,
  })

  const [updateOrderRecord, { loading: updateOrderLoading }] = useMutation<
    UpdateOrderInputData,
    UpdateOrderRecordInput
  >(UPDATE_ORDER_RECORD, {
    onCompleted: hideEditingModal,
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: [...refetchQueries, providerRefetch],
  })

  const [updateOrderItemRefRecord, { loading: updateOrderItemLoading }] =
    useMutation<UpdateOrderItemRefInputData, UpdateOrderItemRefRecordInput>(
      UPDATE_ORDER_ITEM_REF_RECORD,
      {
        onCompleted: hideEditingModal,
        onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
        refetchQueries,
      }
    )
  const [deleteOrderRecord, { loading: deletingOrder }] = useMutation<
    DeleteOrderRecordData,
    DeleteOrderRecordInput
  >(DELETE_ORDER_RECORD, {
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries,
  })

  const [createOrderRecord, { loading: createOrderLoading }] = useMutation<
    CreateOrderRecordData,
    CreateOrderRecordInput
  >(CREATE_ORDER_RECORD, {
    onCompleted: hideEditingModal,
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: [...refetchQueries, providerRefetch],
  })

  const [addItemManufacture, { loading: addManufactureLoading }] = useMutation<
    CreateManufactureData,
    CreateManufactureInput
  >(ADD_ITEM_MANUFACTURE, {
    onCompleted: hideEditingModal,
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: [...refetchQueries, providerRefetch],
  })

  const [updateItemManufacture, { loading: manufactureLoading }] = useMutation<
    UpdateManufactureData,
    UpdateManufactureInput
  >(UPDATE_ITEM_MANUFACTURE, {
    onCompleted: hideEditingModal,
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: [...refetchQueries, providerRefetch],
  })

  const [deleteItemListing, { loading: deletingListing }] = useMutation<
    DeleteItemSaleInfoData,
    DeleteItemSaleInfoInput
  >(DELETE_ITEM_LISTING, {
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries,
  })

  const [createMemberItemRef, { loading: createRefLoading }] = useMutation<
    CreateMemberItemRefData,
    CreateMemberItemRefInput
  >(CREATE_MEMBER_ITEM_REF, {
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries,
  })
  const [deleteMemberItemRef, { loading: deleteRefLoading }] = useMutation<
    DeleteMemberItemRefData,
    DeleteMemberRefInput
  >(DELETE_MEMBER_ITEM_REF, {
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries,
  })

  const {
    loading: eventsLoading,
    mutations: { updateEvent, addEventRecord, deleteEvent },
  } = eventMutations({ showSnackbar, onCompleted: hideEditingModal }, [
    ...refetchQueries,
    refetchListing,
    providerRefetch,
  ])

  const loading =
    eventsLoading ||
    deletingItems ||
    addListingLoading ||
    updateLoading ||
    // transferLoading ||
    updateSalesLoading ||
    addManufactureLoading ||
    manufactureLoading ||
    deletingListing ||
    updateOrderLoading ||
    deletingOrder ||
    createOrderLoading ||
    updateOrderItemLoading ||
    createRefLoading ||
    deleteRefLoading

  return {
    loading,
    mutations: {
      updateItem,
      deleteItems,
      addFileToItem,
      addItemSaleInfo,
      updateEvent,
      addEventRecord,
      // transferItems,
      updateItemSalesInfo,
      deleteEvent,
      deleteItemListing,
      updateItemManufacture,
      addItemManufacture,
      updateOrderRecord,
      deleteOrderRecord,
      createOrderRecord,
      updateOrderItemRefRecord,
      createMemberItemRef,
      deleteMemberItemRef,
    },
  }
}
