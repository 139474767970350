import React, { useState, useCallback, useRef } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Severity, withSnackbar } from 'components/providers/SnackbarHOC'
import { omit } from 'lodash'
import { useIntl } from 'react-intl'
import { Container, Content } from 'components/shared'
import LoadingIndicator from 'components/LoadingIndicator'
import {
  ConfirmationData,
  withConfirmationModal,
} from 'components/hoc/ConfirmationModalHoc'
import { Currencies, FileEntry, JsonObject } from 'types/shared'
import FormModal from 'components/recordDetails/FormModal'
import { useFileUpload } from 'hooks/api/fileUpload'
import SidebarCard from 'components/recordDetails/SidebarCard'
import { SuggestionSection } from 'components/recordDetails/SuggestionsSection'
import { useSubscriptionStatus } from 'hooks/graphql/workspaces'
import UploadHandler, {
  UploadHandlerMethods,
} from 'components/dropzone/UploadHandler'
import { OrganizationType } from 'types/organization'
import { FileType } from 'types/archive'
import { useMediaQuery } from '@mui/material'
import ROUTES from 'lib/routes'
import Box from '@mui/material/Box'
import {
  PageHeader,
  PageGrid,
  PageGridLeft,
  PageGridRight,
  StickySection,
} from 'components/pages'
import Tabs from 'components/Tabs'
import { useTheme } from '@mui/material/styles'
import {
  getEditInputs,
  getExtraInputs,
  getInitialState,
  getSectionWithId,
  payloadBuilder,
  relationsContent,
  Section,
} from './constants'
import Headline from './Headline'
// import ContactPersonCard from './ContactPersonCard'
import { useData, useMutations } from './hooks'
import contentTabs from './tabs'
import EventDetailsDrawer, {
  EventDrawerHandleMethods,
} from 'components/hoc/EventDetailsDrawer'
import { SERVICE_PROVIDER_BY_ID } from 'gql/serviceProvider'
import { EventExtended } from 'types/event'

interface Props {
  showSnackbar?: (message: string, severity: Severity) => void
  requestConfirmation?: (data: ConfirmationData) => void
}

function ServiceDetails({ showSnackbar, requestConfirmation }: Props) {
  const intl = useIntl()
  const theme = useTheme()
  const navigate = useNavigate()
  const params = useParams() as { workspaceId: string; providerId: string }
  const isBigScreen = useMediaQuery(theme.breakpoints.up('lg'))
  const { active } = useSubscriptionStatus(params.workspaceId)
  const { providerId, workspaceId } = params
  const [editing, setEditing] = useState<SuggestionSection>()
  const [focusedEvent, setFocusedEvent] = useState<EventExtended>()
  // optional id of the sub-record to edit, example (saleInfoId)
  const [editingId, setEditingId] = useState<string>()
  const { uploadDocuments, uploadFiles } = useFileUpload()
  const hideEditingModal = useCallback(() => setEditing(undefined), [])
  const uploadHandlerRef = useRef<UploadHandlerMethods>(null)
  const eventDetailsRef = useRef<EventDrawerHandleMethods>(null)

  const showDocUploadModal = useCallback(() => {
    uploadHandlerRef.current?.openUploader(FileType.document)
  }, [])
  const addContract = () =>
    navigate(
      ROUTES.DASHBOARD_CREATE_CONTRACT_ROUTE(params.workspaceId, record?.id)
    )

  const toggleDelete = () =>
    requestConfirmation?.({
      title: intl.formatMessage({ id: 'label.deletingServiceProvider' }),
      description: intl.formatMessage({
        id: 'label.deletingServiceProviderConfirmation',
      }),
      actions: [
        {
          label: intl.formatMessage({ id: 'label.cancel' }),
        },
        {
          label: intl.formatMessage({ id: 'label.delete' }),
          color: 'error',
          onClick: handleOnDelete,
        },
      ],
    })

  const refreshEventDrawer = () => {
    const event = record?.events?.find((e) => e.id === focusedEvent?.id)
    if (event) {
      eventDetailsRef.current?.hideEventDetails()
      eventDetailsRef.current?.showEventDetails(event)
    }
  }
  const {
    loading,
    data: {
      serviceTypes,
      record,
      relations,
      services,
      categories,
      organization,
      contracts,
    },
    refetch: { refetchServiceProvider },
  } = useData(workspaceId, providerId, {
    showSnackbar,
    refreshEventDrawer,
  })
  const {
    loading: mutating,
    mutations: {
      updateRecord,
      deleteServiceProvider,
      // addContactPerson,
      // createService,
      // deleteService,
      // updateService,
      deleteEvent,
      addEventRecord,
      addFileToServiceProvider,
      updateEvent,
    },
  } = useMutations(workspaceId, providerId, {
    showSnackbar,
    hideEditingModal,
  })

  const onUpload = async (files: FileEntry[], fileType: FileType) => {
    await uploadFiles(files, fileType, params.workspaceId, {
      onCompleted: (archiveIds, hasErrors) => {
        archiveIds.forEach((archiveId) => {
          const payload = {
            archiveId,
            workspaceId: params.workspaceId,
            serviceProviderId: params.providerId,
          }
          addFileToServiceProvider({ variables: { payload } })
        })
        if (!hasErrors) {
          uploadHandlerRef.current?.closeUploader()
          showSnackbar?.(
            intl.formatMessage({ id: 'label.uploadSuccessful' }),
            Severity.SUCCESS
          )
        }
      },
      onError: (errors) => {
        if (errors.length > 0)
          showSnackbar?.(
            `File upload failed, (occurrences: ${errors.length}).
              ${errors[0]?.error?.message}
            `,
            Severity.ERROR
          )
        uploadHandlerRef.current?.stopLoader()
      },
    })
  }
  const handleOnEditWithId = (sectionId: Section, recordId?: string) => {
    const section = getSectionWithId(sectionId, intl)
    setEditingId(recordId)
    setEditing(section)
  }

  const handleRecordUpdate = (formData: JsonObject, sectionId?: string) => {
    if (!record) return
    const payload = payloadBuilder(formData, params.providerId, sectionId)
    updateRecord({ variables: { payload } })
  }

  // const createContactPerson = (formData: JsonObject) => {
  //   const { firstName, lastName, email, phoneNumber } = formData
  //   const payload = {
  //     firstName: firstName as string,
  //     lastName: lastName as string,
  //     phoneNumber: phoneNumber as string,
  //     email: email as string,
  //     serviceProviderId: params.providerId,
  //   }
  //   addContactPerson({ variables: { payload } })
  // }

  // const handleServiceUpdate = (formData: JsonObject) => {
  //   if (editingId) {
  //     // update record
  //     const payload = {
  //       id: editingId,
  //       data: formData,
  //     }
  //     updateService({ variables: { payload } })
  //   } else {
  //     // new record
  //     const payload = {
  //       ...formData,
  //       title: formData.title as string,
  //       providerId,
  //     }
  //     createService({ variables: { payload } })
  //   }
  // }

  const handleOnUpdate = (
    formData: JsonObject,
    sectionId?: string,
    files?: FileEntry[]
  ) => {
    switch (sectionId) {
      // case Section.contactPersons:
      //   createContactPerson(formData)
      //   break
      // case Section.services:
      //   handleServiceUpdate(formData)
      //   break
      case Section.events:
      case Section.eventDocuments:
        handleEventRecordUpdate(formData, sectionId, files)
        break
      default:
        handleRecordUpdate(formData, sectionId)
    }
  }

  const handleEventRecordUpdate = async (
    formData: JsonObject,
    sectionId?: string,
    files?: FileEntry[]
  ) => {
    let uploadedFileIds: string[] = []
    if (sectionId === Section.eventDocuments && files) {
      // has files to upload
      await uploadDocuments(files, params.workspaceId, {
        onCompleted: (archiveIds) => {
          uploadedFileIds = archiveIds
        },
        onError: (errors) => {
          if (errors.length > 0)
            showSnackbar?.(
              `File upload failed, (occurrences: ${errors.length}).
                ${errors[0]?.error?.message}
              `,
              Severity.ERROR
            )
        },
      })
    }

    if (editingId) {
      // update record
      const payload = {
        id: editingId,
        data: {
          ...omit(formData ?? {}, ['price', 'currency']),
          amount:
            formData.price && formData.currency
              ? {
                  value: Number(formData.price),
                  currency: formData.currency as Currencies,
                }
              : undefined,
          archiveIds: [
            ...(record?.events?.find((rec) => rec.id === editingId)
              ?.archiveIds ?? []),
            ...uploadedFileIds,
          ],
        },
      }
      updateEvent({ variables: { payload } })
    } else {
      // new record
      const payload = {
        ...omit(formData ?? {}, ['price', 'currency']),
        amount:
          formData.price && formData.currency
            ? {
                value: Number(formData.price),
                currency: formData.currency as Currencies,
              }
            : undefined,
        providerId,
        workspaceId,
        date: formData.date as Date,
        archiveIds: uploadedFileIds,
      }
      addEventRecord({ variables: { payload } })
    }
  }

  // const handleOnDeleteService = (recordId: string) =>
  //   deleteService({ variables: { id: recordId } })
  const handleOnDeleteEvent = (recordId: string) =>
    requestConfirmation?.({
      title: intl.formatMessage({ id: 'label.deleteEvent' }),
      description: intl.formatMessage({
        id: 'label.deletingEventConfirmation',
      }),
      actions: [
        {
          label: intl.formatMessage({ id: 'label.cancel' }),
        },
        {
          label: intl.formatMessage({ id: 'label.delete' }),
          color: 'error',
          onClick: () => deleteEvent({ variables: { id: recordId } }),
        },
      ],
    })

  const handleOnDelete = () =>
    deleteServiceProvider({ variables: { id: providerId } })

  const isBusinessAccount = organization?.type === OrganizationType.business
  const inputs =
    getEditInputs(
      editing?.id ?? '',
      serviceTypes,
      categories,
      isBusinessAccount,
      contracts
    ) || []
  const extraInputs = getExtraInputs(editing?.id ?? '')
  const initialState =
    record && editing
      ? getInitialState(record, editing.id, services, editingId)
      : {}

  const tabs = contentTabs(
    theme,
    intl,
    { record, workspaceId: params.workspaceId, relations },
    {
      toggleDelete,
      showSnackbar,
      handleOnEditWithId,
      showDocUploadModal,
      handleOnDeleteEvent,
      showEventDetails: (event: EventExtended) => {
        setFocusedEvent(event)
        eventDetailsRef.current?.showEventDetails(event)
      },
      hideEventDetails: () => {
        setFocusedEvent(undefined)
        eventDetailsRef.current?.hideEventDetails()
      },
    }
  )
  const isLoading = loading || mutating
  const sidebar = (
    <SidebarCard
      title={intl.formatMessage({
        id: 'label.relations',
      })}
      content={
        record
          ? relationsContent(
              intl,
              { record, relatedContracts: relations?.contracts },
              {
                addEvent: () => handleOnEditWithId(Section.events),
                addContract,
              }
            )
          : []
      }
    />
  )
  return (
    <Container detailPage>
      <LoadingIndicator visible={isLoading} />
      <PageHeader
        pageTitle={intl.formatMessage({ id: 'label.serviceProvider' })}
        recordTitle={record?.title ?? ''}
        breadcrumbLink={ROUTES.DASHBOARD_SERVICE_PROVIDERS_ROUTE(
          params.workspaceId
        )}
      />
      <Content detailPage>
        <PageGrid container spacing={6}>
          <PageGridLeft item xs={12} sm={12} md={12} lg={7} xl={8}>
            {record && (
              <Headline
                record={record}
                onEditSection={handleOnEditWithId}
                canEdit={!!record?.isParentWorkspace}
              />
            )}
            {!isBigScreen && sidebar}
            <Tabs tabs={tabs} locked={!active} />

            {/* todo: deprecated contract person logic .. refactor entity and re-implement */}
            {/* {record && record?.contactPersons.length > 0 && (
              <React.Fragment>
                <SectionTitleWrapper>
                  <SectionTitle>
                    {intl.formatMessage({ id: 'label.contactPersons' })}
                  </SectionTitle>
                  <IconButton
                    size="small"
                    onClick={() => handleOnEditWithId(Section.contactPersons)}
                  >
                    <AddIcon color="primary" fontSize="small" />
                  </IconButton>
                </SectionTitleWrapper>
                <Grid container>
                  {record.contactPersons.map((person) => (
                    <Grid key={person.id} md={6} xs={12}>
                      <ContactPersonCard
                        serviceProviderId={params.providerId}
                        member={person}
                        showSnackbar={showSnackbar}
                        workspaceId={params.workspaceId}
                      />
                    </Grid>
                  ))}
                </Grid>
              </React.Fragment>
            )} */}

            {/* Listed services */}
            {/* todo: NOT FOR V1 */}
            {/* {(services?.length ?? 0) > 0 && (
              <ContentSection
                section={{
                  title: intl.formatMessage({ id: 'label.services' }),
                  content: services?.map((service: Service) => ({
                    recordId: service.id,
                    data: getServiceContent(service, intl),
                    actions: [
                      {
                        key: 'details-screen',
                        onClick: () =>
                          navigate(
                            ROUTES.DASHBOARD_SERVICE_DETAILS_ROUTE(
                              params.workspaceId,
                              service.id
                            )
                          ),
                        Icon: <LinkIcon color="primary" fontSize="small" />,
                      },
                    ],
                  })),
                }}
                onEdit={(_, recordId) =>
                  handleOnEditWithId(Section.services, recordId)
                }
                onDelete={(recordId) =>
                  recordId && handleOnDeleteService(recordId)
                }
                onAdd={() => handleOnEditWithId(Section.services)}
              />
            )} */}
          </PageGridLeft>

          {isBigScreen && (
            <PageGridRight item xs={12} sm={12} md={12} lg={5} xl={4}>
              <StickySection>
                {sidebar}
                <Box sx={{ height: '40px' }} />
              </StickySection>
            </PageGridRight>
          )}
        </PageGrid>
      </Content>
      <FormModal
        toggleModal={hideEditingModal}
        inputs={inputs}
        extraInputs={extraInputs}
        section={editing}
        initialState={initialState}
        onSubmit={handleOnUpdate}
      />

      <UploadHandler ref={uploadHandlerRef} onSubmit={onUpload} />
      <EventDetailsDrawer
        ref={eventDetailsRef}
        refetchQueries={[
          {
            query: SERVICE_PROVIDER_BY_ID,
            variables: { itemId: record?.id },
          },
        ]}
        onRefresh={() => refetchServiceProvider()}
      />
    </Container>
  )
}

ServiceDetails.defaultProps = {
  showSnackbar: undefined,
  requestConfirmation: undefined,
}

export default withConfirmationModal(withSnackbar(ServiceDetails))
