import React from 'react'
import { IntlShape } from 'react-intl'
import { isValidText } from 'utils/validators'
import { InputType, Type } from 'components/input/types'
import { JsonObject } from 'types/shared'
import { SuggestionSection } from 'components/recordDetails/SuggestionsSection'
import { Workspace } from 'types/workspace'
import { ContentSection } from 'components/recordDetails/SidebarCard'
import { Trash } from 'assets/svg'

export enum Section {
  general = 'general',
}

export const editingSections = (intl: IntlShape): SuggestionSection[] => [
  {
    id: Section.general,
    title: intl.formatMessage({ id: 'label.general' }),
    description: '',
    cta: '',
  },
]

export const getSectionWithId = (id: string, intl: IntlShape) => {
  return editingSections(intl).find((section) => section.id === id)
}

const generalSectionInputs = (): InputType[] => [
  {
    key: 'title',
    label: 'label.title',
    type: Type.TEXT,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    validators: [
      {
        method: isValidText,
        label: 'label.required',
      },
    ],
  },
  {
    key: 'description',
    label: 'label.description',
    type: Type.TEXT,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    multiline: true,
    validators: [],
  },
  {
    key: 'coverImageId',
    label: 'label.addBoardCover',
    type: Type.DROPZONE,
    margin: 'dense',
    fullWidth: true,
    validators: [],
  },
]

const generalInitialState = (record: Workspace) => ({
  title: record.title || '',
  description: record.description || '',
})

export const getEditInputs = (editId: string) => {
  switch (editId) {
    case Section.general:
      return generalSectionInputs()
    default:
      return []
  }
}

export const getExtraInputs = (editId: string) => {
  return []
}

export const getInitialState = (record: Workspace, sectionId: string) => {
  switch (sectionId) {
    case Section.general:
      return generalInitialState(record)
    default:
      return {}
  }
}

export const getWorkspaceContent = (record: Workspace, intl: IntlShape) => {
  const data: { label: string; value: string }[] = []
  data.push({
    label: intl.formatMessage({ id: 'label.title' }),
    value: record.title,
  })
  record.description &&
    data.push({
      label: intl.formatMessage({ id: 'label.description' }),
      value: record.description,
    })
  return data
}

export const payloadBuilder = (
  formData: JsonObject,
  recordId: string,
  sectionId?: string
) => {
  let payloadData = formData
  if (sectionId)
    switch (sectionId) {
      case Section.general:
        payloadData = formData
        break

      default:
        payloadData = { [sectionId]: formData } // 1:1 mapping of section to payload
    }

  const payload = {
    id: recordId,
    data: payloadData,
  }
  return payload
}

interface Methods {
  leaveBoard: () => void
}
export const settingsContent = (
  intl: IntlShape,
  methods: Methods
): ContentSection[] => [
  {
    description: intl.formatMessage({ id: 'label.leaveBoard' }),
    action: {
      ariaLabel: 'leave board',
      icon: (props) => <Trash {...props} />,
      onPress: methods.leaveBoard,
    },
  },
]
