import { IntlShape } from 'react-intl'
import { isValidNumber, isValidText } from 'utils/validators'
import { currencies, defaultCurrency, MeasurementSystem } from 'lib/constants'
import { InputType, Type } from 'components/input/types'
import { JsonObject } from 'types/shared'
import { SuggestionSection } from 'components/recordDetails/SuggestionsSection'
import { Organization, OrganizationExtended } from 'types/organization'
import { Countries } from 'types/static'
import { userRequestInputs } from 'utils/input-forms'
import { UserRequestType } from 'types/userRequest'

export enum Section {
  preferences = 'defaultOptions',
  company = 'company',
  address = 'address',
  userRequest = 'userRequest',
}

export const editingSections = (intl: IntlShape): SuggestionSection[] => [
  {
    id: Section.preferences,
    title: intl.formatMessage({ id: 'label.preferences' }),
    description: '',
    cta: '',
  },
  {
    id: Section.company,
    title: intl.formatMessage({ id: 'label.company' }),
    description: '',
    cta: '',
  },
  {
    id: Section.address,
    title: intl.formatMessage({ id: 'label.address' }),
    description: '',
    cta: '',
  },
  {
    id: Section.userRequest,
    title: intl.formatMessage({ id: 'label.userRequest' }),
    description: '',
    cta: '',
  },
]

export const getSectionWithId = (id: string, intl: IntlShape) => {
  return editingSections(intl).find((section) => section.id === id)
}

const preferencesSectionInputs = (intl: IntlShape): InputType[] => [
  {
    key: 'currency',
    label: 'label.currency',
    type: Type.SINGLE_SELECT,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    options: currencies.map(({ value, label }) => ({ value, label })),
    validators: [],
  },
  {
    key: 'measurementSystem',
    label: 'label.measurementSystem',
    type: Type.SINGLE_SELECT,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    options: Object.keys(MeasurementSystem).map((value) => ({
      value,
      label: intl.formatMessage({
        id: `measurement.type.${value}`,
        defaultMessage: value,
      }),
    })),
    validators: [],
  },
]

const companySectionInputs = (intl: IntlShape): InputType[] => [
  {
    key: 'name',
    label: 'label.name',
    type: Type.TEXT,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    validators: [
      {
        method: isValidText,
        label: 'label.required',
      },
    ],
  },
  {
    key: 'description',
    label: 'label.description',
    type: Type.TEXT,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    multiline: true,
    validators: [],
  },
]

const addressSectionInputs = (
  countries: Countries,
  intl: IntlShape
): InputType[] => [
  {
    key: 'houseNumber',
    label: 'label.houseNumber',
    type: Type.TEXT,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    validators: [
      {
        method: isValidText,
        label: 'label.required',
      },
    ],
  },
  {
    key: 'street',
    label: 'label.street',
    type: Type.TEXT,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    validators: [
      {
        method: isValidText,
        label: 'label.required',
      },
    ],
  },
  {
    key: 'city',
    label: 'label.city',
    type: Type.TEXT,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    validators: [
      {
        method: isValidText,
        label: 'label.required',
      },
    ],
  },
  {
    key: 'zipCode',
    label: 'label.zipCode',
    type: Type.NUMBER,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    validators: [
      {
        method: isValidNumber,
        label: 'label.required',
      },
    ],
  },
  {
    key: 'countryCode',
    label: 'label.country',
    type: Type.AUTOCOMPLETE,
    autoComplete: '',
    autoFocus: false,
    margin: 'dense',
    fullWidth: true,
    options: Object.keys(countries).map((value) => ({
      value,
      label: countries[value],
    })),
    validators: [
      {
        method: isValidText,
        label: 'label.required',
      },
    ],
  },
]

const preferencesInitialState = (record: Organization) => ({
  currency: record.defaultOptions?.currency || defaultCurrency,
  measurementSystem:
    record.defaultOptions?.measurementSystem || MeasurementSystem.METRIC,
})

const companyInitialState = (record: Organization) => ({
  name: record.company?.name || '',
  description: record.company?.description || '',
})

const addressInitialState = (record: Organization) => ({
  city: record.address?.city || '',
  houseNumber: record.address?.houseNumber || '',
  street: record.address?.street || '',
  zipCode: record.address?.zipCode.toString() || '',
  countryCode: record.address?.countryCode || '',
})

export const getEditInputs = (
  editId: string,
  intl: IntlShape,
  countries: Countries,
  // data used in case of user request claims
  userRequestData?: {
    type: UserRequestType
    infoLabel?: string
  }
) => {
  switch (editId) {
    case Section.preferences:
      return preferencesSectionInputs(intl)
    case Section.company:
      return companySectionInputs(intl)
    case Section.address:
      return addressSectionInputs(countries, intl)
    case Section.userRequest:
      return userRequestData
        ? userRequestInputs(userRequestData.type, userRequestData.infoLabel)
        : []
    default:
      return []
  }
}

export const getExtraInputs = (editId: string) => {
  switch (editId) {
    default:
      return []
  }
}

const requestInitialState = (
  intl: IntlShape,
  subscriptionId?: string,
  refundPayloadInfo?: RefundPayloadInfo
) =>
  subscriptionId
    ? {
        // get prefilled message from translations with relevant refund data
        message: intl.formatMessage(
          { id: 'label.userRequest.subscriptionRefundTemplate' },
          {
            subscriptionId,
            referenceNumber:
              refundPayloadInfo?.organization.referenceNumber ?? '',
          }
        ),
      }
    : {
        message: '',
      }

interface RefundPayloadInfo {
  organization: OrganizationExtended
}

export const getInitialState = (
  intl: IntlShape,
  record: Organization,
  sectionId: string,
  recordId?: string, // id of the sub-entity being edited, e.g. item sales info
  // object in case the user requests a subscription refund.
  refundPayloadInfo?: RefundPayloadInfo
) => {
  switch (sectionId) {
    case Section.preferences:
      return preferencesInitialState(record)
    case Section.company:
      return companyInitialState(record)
    case Section.address:
      return addressInitialState(record)
    case Section.userRequest:
      return requestInitialState(intl, recordId, refundPayloadInfo)
    default:
      return {}
  }
}

export const payloadBuilder = (
  formData: JsonObject,
  recordId: string,
  sectionId?: string
) => {
  let payloadData = formData
  if (sectionId)
    switch (sectionId) {
      default:
        payloadData = { [sectionId]: formData } // 1:1 mapping of section to payload
    }

  const payload = {
    id: recordId,
    data: payloadData,
  }
  return payload
}

export const inputs: InputType[] = [
  {
    key: 'number',
    label: 'label.ccNumber',
    type: Type.TEXT,
    autoComplete: 'cc-number',
    autoFocus: false,
    margin: 'dense',
    fullWidth: false,
    validators: [
      {
        method: isValidText,
        label: 'label.required',
      },
    ],
  },
  {
    key: 'name',
    label: 'label.ccName',
    type: Type.TEXT,
    autoComplete: 'cc-name',
    autoFocus: false,
    margin: 'dense',
    fullWidth: false,
    validators: [
      {
        method: isValidText,
        label: 'label.required',
      },
    ],
  },
  {
    key: 'expiry',
    label: 'label.ccExpiration',
    type: Type.TEXT,
    autoComplete: 'cc-exp',
    autoFocus: false,
    margin: 'dense',
    fullWidth: false,
    validators: [
      {
        method: isValidText,
        label: 'label.required',
      },
    ],
  },
  {
    key: 'cvc',
    label: 'label.ccCvv',
    type: Type.TEXT,
    autoComplete: 'cc-csc',
    autoFocus: false,
    margin: 'dense',
    fullWidth: false,
    validators: [
      {
        method: isValidText,
        label: 'label.required',
      },
    ],
  },
]
