import React from 'react'
import Grid from '@mui/material/Grid'
import { PageDescription } from 'components/pages'
import { styled, useTheme } from '@mui/material/styles'
import { Row, RowSpaced } from 'components/shared'
import { StatusChip } from 'components/cards'
import { useIntl } from 'react-intl'
import MuiIconButton from '@mui/material/IconButton'
import Card from '@mui/material/Card'
import { ServiceProviderExtended } from 'types/serviceProvider'
import CellSignalHigh from 'assets/svg/CellSignalHigh'
import { formatLink } from 'utils/link'
import { BodyText, BodyTextLight, H1 } from 'components/typography'
import Pencil from 'assets/svg/Pencil'
import { getDate } from 'utils/datetime'
import { Section } from './constants'

const Container = styled(Row)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: '18px',
  borderRadius: '5px',
  marginBottom: '16px',
}))
const IconButton = styled(MuiIconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
}))

const HeadlineCard = styled(Card)({
  display: 'flex',
  flex: 1,
  flexGrow: 1,
  flexDirection: 'row',
  boxShadow: 'none',
  flexWrap: 'nowrap',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const HeadlineContainer = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
})

const StorageCard = styled(Card)({
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  boxShadow: 'none',
  flexWrap: 'nowrap',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '12px',
})

const StorageGridContainer = styled(Grid)({
  marginTop: '8px',
})

const SectionLink = styled('a')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '19px',
  order: 0,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  lineClamp: 1,
  WebkitBoxOrient: 'vertical',
}))

const SectionChip = styled(Row)(({ theme }) => ({
  cursor: 'pointer',
  userSelect: 'none',
  padding: '0 6px',
  borderRadius: '4px',
  backgroundColor: '#F7F7F7',
  marginRight: '4px',
  transition: theme.transitions.create(['padding', 'transform'], {
    duration: theme.transitions.duration.standard,
  }),
  ':hover': {
    backgroundColor: theme.palette.action.active,
    padding: '0 8px',
  },
  ':hover svg :where(path, line)': {
    stroke: theme.palette.primary.contrastText,
  },
  ':hover span': {
    display: 'flex',
    color: theme.palette.primary.contrastText,
  },
}))

const Image = styled('img')(({ theme }) => ({
  display: 'flex',
  width: '156px',
  height: '117px',
  objectFit: 'contain',
  borderRadius: '3px',
  border: `1px solid ${theme.palette.primary.main}`,
  marginRight: '32px',
  [theme.breakpoints.down('sm')]: {
    width: '78px',
    height: '59px',
    marginRight: '12px',
  },
}))

const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
}))

interface Props {
  record: ServiceProviderExtended
  onEditSection: (sectionId: Section) => void
  canEdit: boolean
}
function Headline({ record, onEditSection, canEdit }: Props) {
  const intl = useIntl()
  const theme = useTheme()
  const onEdit = () => (canEdit ? onEditSection(Section.general) : null)
  const [favError, setFavError] = React.useState(false)
  const onLoadError = () => setFavError(true)

  const headline = (
    <HeadlineCard>
      <HeadlineContainer>
        <RowSpaced>
          {record?.public && (
            <StatusChip
              label={intl.formatMessage({
                id: record.public ? 'label.public' : 'label.private',
              })}
              color={record.public ? 'primary' : 'default'}
            />
          )}
          {record?.deletedAt && (
            <StatusChip
              color="error"
              label={intl.formatMessage(
                { id: 'label.deletedAtDate' },
                { date: getDate(record.deletedAt) }
              )}
            />
          )}
          <div />

          {canEdit && (
            <IconButton onClick={onEdit}>
              <Pencil />
            </IconButton>
          )}
        </RowSpaced>
        <H1>{record.title}</H1>
        <PageDescription>{record.description}</PageDescription>
      </HeadlineContainer>
    </HeadlineCard>
  )

  const content = (
    <StorageCard>
      <div style={{ flexDirection: 'column', flex: 1 }}>
        {/* website */}
        {record.link && (
          <StorageGridContainer container>
            <Grid item xs={3} paddingTop={1}>
              <BodyText>{intl.formatMessage({ id: 'label.website' })}</BodyText>
            </Grid>
            <Grid item xs={9}>
              <SectionLink href={formatLink(record.link)} target="_blank">
                {record.link}
              </SectionLink>
            </Grid>
          </StorageGridContainer>
        )}

        {record.category && (
          <StorageGridContainer container>
            <Grid item xs={3} paddingTop={1}>
              <BodyText>
                {intl.formatMessage({ id: 'label.category' })}
              </BodyText>
            </Grid>
            <Grid item xs={9}>
              <Row>
                <SectionChip onClick={onEdit}>
                  <CellSignalHigh
                    color={theme.palette.text.primary}
                    width="22px"
                  />
                  <BodyTextLight>{record.category.title}</BodyTextLight>
                </SectionChip>
              </Row>
            </Grid>
          </StorageGridContainer>
        )}
      </div>
    </StorageCard>
  )

  return (
    <React.Fragment>
      <Container>
        {!favError && record.link && (
          <Image
            src={`${formatLink(record.link)}/favicon.ico`}
            onError={onLoadError}
          />
        )}
        <Content>
          {headline}
          {content}
        </Content>
      </Container>
    </React.Fragment>
  )
}

export default Headline
