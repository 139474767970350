// this file refers to the file archive

import { MemberModels, Status } from './shared'

export enum MediaType {
  image = 'IMAGE',
  document = 'DOCUMENT',
}

// this is an extension of MediaType
export enum FileType {
  image = 'IMAGE',
  document = 'DOCUMENT',
  avatar = 'AVATAR',
}

export enum DocumentType {
  INVOICE = 'INVOICE',
  RECEIPT = 'RECEIPT',
  INSURANCE = 'INSURANCE',
  WARRANTY = 'WARRANTY',
}

export interface ArchiveFile {
  id: string
  path: string
  media?: {
    thumbnail?: string
    medium?: string
    full?: string
    avatar?: string
  }
  workspaceId: string
  type: FileType
  userId?: string
  isPublic: boolean
  createdAt: Date
  updatedAt: Date
  // Field of the reference Id in case the archive is linked to another entity.
  refId?: string
}

export interface WorkspaceArchiveData {
  workspaceArchiveFiles: {
    list: ArchiveFile[]
    total: number
  }
}

export interface AddFileToItemData {
  addFileToItem: Status
}

export interface AddFileToMemberData {
  addFileToMember: Status
}
export interface AddFileToMemberInput {
  payload: {
    archiveId: string
    memberId: string
    memberModel: MemberModels
    workspaceId: string
  }
}

export interface AddFileToNestData {
  addFileToNest: Status
}

export interface AddFileToBoxData {
  addFileToContainer: Status
}

export interface AddFileToServiceProviderData {
  addFileToServiceProvider: Status
}
