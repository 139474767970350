import React from 'react'
import { styled } from '@mui/material/styles'
import { GridRowId } from '@mui/x-data-grid'
import { useIntl } from 'react-intl'
import { Button } from 'components/shared'
import { Action } from 'types/shared'

const Container = styled('div')(({ theme }) => ({
  position: 'fixed',
  bottom: '0',
  marginBottom: '20px',
  padding: '10px 20px',
  boxShadow: '0px 4px 20px 0px #0000000D',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '20px',
  [theme.breakpoints.down('md')]: {
    bottom: '35px',
  },
}))

const SelectionContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

const Text = styled('span')(({ theme }) => ({
  fontSize: '14px',
  fontFamily: 'NoirPro',
}))

const ButtonWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginLeft: '10px',
}))

interface SelectionProps {
  selectedRows: GridRowId[]
  actions: Action[]
}

function GridSelectionModal({ selectedRows, actions }: SelectionProps) {
  const intl = useIntl()
  const ctaStyle = { marginLeft: '10px' }
  return (
    <Container>
      <SelectionContent>
        <Text>
          {intl.formatMessage(
            { id: 'label.gridRowCount' },
            { count: selectedRows.length ?? 0 }
          )}
        </Text>
        <ButtonWrapper>
          {actions?.map((action) => (
            <Button
              onClick={action.onClick}
              style={
                action.variant === 'outlined'
                  ? { boxShadow: 'none', ...ctaStyle }
                  : ctaStyle
              }
              key={action.label}
              variant={action.variant || 'contained'}
              color={action.color || 'primary'}
            >
              {intl.formatMessage({ id: action.label })}
            </Button>
          ))}
        </ButtonWrapper>
      </SelectionContent>
    </Container>
  )
}

export default GridSelectionModal
