import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { styled, useTheme } from '@mui/material/styles'
import { Severity, withSnackbar } from 'components/providers/SnackbarHOC'
import { Container, Content, Row, SectionWrapper } from 'components/shared'
import LoadingIndicator from 'components/LoadingIndicator'
import ROUTES from 'lib/routes'
import {
  PageGrid,
  PageGridLeft,
  PageGridRight,
  StickySection,
} from 'components/pages'
import appConfig from 'config/app'
import MuiHeader from 'pages/landing/MuiHeader'
import { Box, Toolbar, useMediaQuery } from '@mui/material'
import { H3, H6 } from 'components/typography'
import { useQuery } from '@apollo/client'
import { CheckoutData } from 'lib/payment/subscribe'
import { CHECKOUT_DETAILS } from 'gql/subscription'
import { CheckoutDetailsData, CheckoutDetailsInput } from 'types/subscription'
import { PayPalScriptProvider } from '@paypal/react-paypal-js'
import paypalLogo from 'assets/images/PayPal.svg'
import sepaLogo from 'assets/images/Sepa.svg'
import cardLogo from 'assets/images/CreditCard.svg'
import { AccountSection, LogoAppendix, MoneyBackBadge } from './fragments'
import OrderSummary from './OrderSummary'
import PaymentCard from './PaymentCard'

const DesktopGridRight = styled(PageGridRight)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}))

interface Props {
  showSnackbar?: (message: string, severity: Severity) => void
}

function Checkout({ showSnackbar }: Props) {
  const location = useLocation()
  const intl = useIntl()
  const theme = useTheme()
  const isBigScreen = useMediaQuery(theme.breakpoints.up('lg'))
  const params = useParams() as {
    countryCode: string
    subscriptionPlanId: string
    // in case the user passed a subscription to extend
    subscriptionId?: string
  }
  const { countryCode, subscriptionPlanId, subscriptionId } = params ?? {}
  const [checkoutData, setCheckoutData] = useState<CheckoutData>({
    countryCode: params?.countryCode,
    subscriptionPlanId: params?.subscriptionPlanId,
    subscriptionId: params.subscriptionId,
  })

  const { data, loading } = useQuery<CheckoutDetailsData, CheckoutDetailsInput>(
    CHECKOUT_DETAILS,
    {
      fetchPolicy: 'network-only',
      variables: {
        payload: { countryCode, subscriptionPlanId, subscriptionId },
      },
      onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    }
  )
  const checkoutDetails = data?.checkoutDetails
  useEffect(() => {
    setCheckoutData({
      ...checkoutData,
      organizationId: checkoutDetails?.organizations[0]?.id,
    })
  }, [checkoutDetails])
  const onOrganizationChange = (organizationId: string) =>
    setCheckoutData({ ...checkoutData, organizationId })
  const isDashboard = location.pathname.includes(ROUTES.DASHBOARD)
  const subscriptionPlan = checkoutDetails?.subscriptionPlan
  const isLoading = loading
  const isExtensionOfSubscription = !!subscriptionId
  return (
    <Container detailPage>
      {!isDashboard && (
        <React.Fragment>
          <MuiHeader
            LogoAppendix={() => (
              <LogoAppendix>
                {intl.formatMessage({ id: 'label.checkout' })}
              </LogoAppendix>
            )}
          />
          <Toolbar />
        </React.Fragment>
      )}
      <LoadingIndicator visible={isLoading} />
      <Content>
        <PageGrid container spacing={6}>
          <PageGridLeft item xs={12} sm={12} md={12} lg={7} xl={8}>
            <AccountSection
              organizations={checkoutDetails?.organizations}
              selectedRecordId={checkoutData.organizationId}
              onOrganizationChange={onOrganizationChange}
            />

            {!isBigScreen && (
              <SectionWrapper>
                <OrderSummary subscriptionPlan={subscriptionPlan} />
              </SectionWrapper>
            )}

            {/* Payment method! */}
            <SectionWrapper>
              <Row>
                <H3>
                  2.{' '}
                  {intl.formatMessage({
                    id: 'label.checkoutSelectPaymentMethod',
                  })}
                </H3>
              </Row>

              <Box height={10} />

              {subscriptionPlan?.pricing.currency && (
                <PayPalScriptProvider
                  options={{
                    clientId: appConfig.paymentClientId,
                    currency: subscriptionPlan?.pricing.currency,
                    components: 'buttons',
                  }}
                >
                  <PaymentCard
                    logo={paypalLogo}
                    title={intl.formatMessage({ id: 'label.paypal' })}
                    fundingSource="paypal"
                    checkoutData={checkoutData}
                    showSnackbar={showSnackbar}
                    showMoneyBack={
                      !isExtensionOfSubscription &&
                      !!subscriptionPlan?.pricing?.refundDeadline
                    }
                  />
                  <PaymentCard
                    logo={sepaLogo}
                    title={intl.formatMessage({ id: 'label.sepa' })}
                    fundingSource="sepa"
                    checkoutData={checkoutData}
                    showSnackbar={showSnackbar}
                    showMoneyBack={
                      !isExtensionOfSubscription &&
                      !!subscriptionPlan?.pricing?.refundDeadline
                    }
                  />
                  <PaymentCard
                    logo={cardLogo}
                    title={intl.formatMessage({ id: 'label.card' })}
                    fundingSource="card"
                    checkoutData={checkoutData}
                    showSnackbar={showSnackbar}
                    showMoneyBack={
                      !isExtensionOfSubscription &&
                      !!subscriptionPlan?.pricing?.refundDeadline
                    }
                  />
                </PayPalScriptProvider>
              )}
            </SectionWrapper>
          </PageGridLeft>
          <DesktopGridRight item xs={12} sm={12} md={12} lg={5} xl={4}>
            <StickySection>
              {!isExtensionOfSubscription &&
                subscriptionPlan?.pricing?.refundDeadline && (
                  <Row>
                    <MoneyBackBadge>
                      <H6 color={theme.palette.background.default}>
                        {
                          checkoutDetails?.subscriptionPlan.pricing
                            .refundDeadline
                        }
                      </H6>
                      <H6 color={theme.palette.background.default}>
                        {intl.formatMessage({ id: 'label.day' })}
                      </H6>
                    </MoneyBackBadge>
                    <H6>
                      {intl.formatMessage({ id: 'label.moneyBackGuarantee' })}
                    </H6>
                  </Row>
                )}
              <Box height={30} />
              <OrderSummary subscriptionPlan={subscriptionPlan} />
            </StickySection>
          </DesktopGridRight>
        </PageGrid>
      </Content>
    </Container>
  )
}

Checkout.defaultProps = {
  showSnackbar: undefined,
}
export default withSnackbar(Checkout)
