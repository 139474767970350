import { gql } from '@apollo/client'

export const CREATE_SERVICE_PROVIDER = gql`
  mutation CREATE_SERVICE_PROVIDER($payload: CreateServiceProviderInput!) {
    createServiceProvider(payload: $payload) {
      id
    }
  }
`

export const SERVICE_PROVIDERS_LIST = gql`
  query SERVICE_PROVIDERS_LIST($payload: ServiceProviderListInput!) {
    serviceProviders(payload: $payload) {
      workspaceProviders {
        id
        title
        description
        workspaceId
        link
        public
        updatedAt
        deletedAt
        category {
          id
          title
          titleDE
        }
      }
      publicProviders {
        id
        title
        description
        workspaceId
        link
        public
        updatedAt
        deletedAt
        category {
          id
          title
          titleDE
        }
      }
      publicRelatedProviders {
        id
        title
        description
        workspaceId
        link
        public
        updatedAt
        deletedAt
        category {
          id
          title
          titleDE
        }
      }
    }
  }
`

export const SERVICE_PROVIDER_BY_ID = gql`
  query SERVICE_PROVIDER_BY_ID($payload: ServiceProviderInput!) {
    serviceProviderById(payload: $payload) {
      id
      title
      description
      workspaceId
      isParentWorkspace
      categoryId
      link
      public
      createdAt
      updatedAt
      deletedAt
      contact {
        email
        phoneNumber
      }
      contactPersons {
        id
        firstName
        lastName
        userId
        email
        avatarId
        phoneNumber
        serviceProviderId
        avatar {
          avatar
        }
        createdAt
        updatedAt
      }
      category {
        id
        title
        titleDE
      }
      ownerArchives {
        id
        type
        title
        documentType
        path
        isPublic
        media {
          thumbnail
          medium
          full
        }
      }
      workspaceArchives {
        id
        type
        title
        documentType
        path
        isPublic
        media {
          thumbnail
          medium
          full
        }
      }
      events {
        id
        itemId
        providerId
        contractId
        workspaceId
        date
        description
        title
        archiveIds
        createdAt
        updatedAt
        contract {
          id
          title
          description
          contractNumber
          deletedAt
        }
        item {
          id
          title
          description
          deletedAt
        }
        amount {
          value
          currency
        }
        archive {
          id
          type
          title
          documentType
          path
          media {
            thumbnail
            medium
            full
          }
        }
      }
    }
  }
`

export const UPDATE_SERVICE_PROVIDER = gql`
  mutation UPDATE_SERVICE_PROVIDER($payload: UpdateServiceProviderInput!) {
    updateServiceProvider(payload: $payload) {
      id
    }
  }
`

export const ADD_CONTACT_PERSON = gql`
  mutation ADD_CONTACT_PERSON($payload: ProviderContactPersonInput!) {
    addServiceProviderContactPerson(payload: $payload) {
      id
    }
  }
`

export const UPDATE_CONTACT_PERSON = gql`
  mutation UPDATE_CONTACT_PERSON(
    $payload: UpdateServiceProviderContactPersonInput!
  ) {
    updateServiceProviderContactPerson(payload: $payload) {
      status
    }
  }
`

export const SERVICE_PROVIDER_RELATIONS = gql`
  query SERVICE_PROVIDER_RELATIONS($payload: ServiceProviderInput!) {
    serviceProviderRelations(payload: $payload) {
      services {
        id
        title
      }
      itemListings {
        id
        item {
          id
          title
        }
      }
      events {
        id
        item {
          id
          title
          deletedAt
        }
      }
      itemManufacturers {
        id
        item {
          id
          title
        }
      }
      orders {
        id
        orderId
        date
        sellerId
        buyerId
        items {
          itemId
          quantity
          productLink
          serialNumbers
          amount {
            value
            currency
          }
          item {
            id
            title
            categoryId
            description
            deletedAt
          }
        }
      }
      contracts {
        id
        title
        description
        workspaceId
        contractNumber
      }
    }
  }
`

export const DELETE_SERVICE_PROVIDER = gql`
  mutation DELETE_SERVICE_PROVIDER($id: ID!) {
    deleteServiceProvider(id: $id) {
      status
    }
  }
`

export const SERVICE_PROVIDER_CATEGORIES = gql`
  query SERVICE_PROVIDER_CATEGORIES {
    providerCategories {
      id
      title
      description
      titleDE
      descriptionDE
      createdAt
      updatedAt
    }
  }
`
