import * as React from 'react'
import 'react-slideshow-image/dist/styles.css'
import { styled } from '@mui/material/styles'
import { InternalRefetchQueriesInclude, useMutation } from '@apollo/client'
import { ArchiveFile } from 'types/archive'
import appConfig from 'config/app'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Fade from '@mui/material/Fade'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import { Slide } from 'react-slideshow-image'
import IconButton from '@mui/material/IconButton'
import Close from '@mui/icons-material/Close'
import Save from '@mui/icons-material/Save'
import More from '@mui/icons-material/MoreVert'
import Rotate from '@mui/icons-material/Rotate90DegreesCcw'
import { Severity, withSnackbar } from 'components/providers/SnackbarHOC'
import { useIntl } from 'react-intl'
import {
  ConfirmationData,
  withConfirmationModal,
} from 'components/hoc/ConfirmationModalHoc'
import {
  DeleteArchiveData,
  DeleteArchiveInput,
  RotateImageData,
  RotateImageInput,
} from 'types/assets'
import { ROTATE_IMAGE, DELETE_ARCHIVE } from 'gql/assets'
import { ModalTransition, Row } from './shared'

const Image = styled('img')(({ rotation }: { rotation?: number }) => ({
  objectFit: 'contain',
  flex: 1,
  maxWidth: '100%',
  transform: `rotate(${rotation}deg)`,
}))

const SlideContent = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100vh',
  flex: 1,
}))

const HeaderButton = styled(IconButton)(({ theme }) => ({
  border: `2px solid ${theme.palette.primary.main}`,
  borderRadius: '5px',
  p: 0,
  boxShadow: '1px 1px 5px rgba(0, 0, 0, 0.2)',
  marginLeft: '5px',
}))

const SimpleHeaderButton = styled(HeaderButton)(({ theme }) => ({
  boxShadow: 'none',
  border: 'none',
}))

const RotateLeft = styled(Rotate)(({ theme }) => ({
  color: theme.palette.primary.main,
}))

const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  right: 0,
  left: 0,
  padding: '10px 20px',
  position: 'absolute',
  justifyContent: 'flex-end',
  zIndex: 1,
}))

const CloseIcon = styled(Close)(({ theme }) => ({
  color: theme.palette.primary.main,
  width: '20px',
  height: '20px',
}))

const MoreIcon = styled(More)(({ theme }) => ({
  color: theme.palette.primary.main,
  width: '20px',
  height: '20px',
}))

const EditRow = styled(Row)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: '0px 0px 21px 1px #00000012',
  position: 'absolute',
  bottom: 0,
  flex: 1,
  padding: '6px 40px',
  borderRadius: '10px 10px 0 0',
}))

const EditContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
}))

interface EditProps {
  image: ArchiveFile
  rotation: number
  setRotation: (rotation: number) => void
  onCancel: () => void
  onSave: () => void
}

function EditImage({
  image,
  rotation,
  setRotation,
  onCancel,
  onSave,
}: EditProps) {
  const rotateLeft = () => setRotation(rotation - 90)
  const rotateRight = () => setRotation(rotation + 90)

  return (
    <EditContainer>
      <SlideContent>
        <Image
          rotation={rotation}
          src={`${appConfig.media}${image.path}?t=${new Date().getTime()}`}
        />
      </SlideContent>
      <EditRow>
        <HeaderButton onClick={rotateLeft}>
          <RotateLeft />
        </HeaderButton>
        <HeaderButton onClick={rotateRight}>
          <RotateLeft sx={{ transform: 'scaleX(-1)' }} />
        </HeaderButton>
        <HeaderButton onClick={onSave}>
          <Save color="primary" />
        </HeaderButton>
        <HeaderButton onClick={onCancel}>
          <Close color="error" />
        </HeaderButton>
      </EditRow>
    </EditContainer>
  )
}

interface Props {
  showSnackbar?: (message: string, severity: Severity) => void
  images: ArchiveFile[]
  visible: boolean
  onClose?: () => void
  activeIndex?: number
  requestConfirmation?: (data: ConfirmationData) => void
  refetchQueries?: InternalRefetchQueriesInclude
  menuActions?: {
    label: string
    onClick: (archiveId: string) => void
  }[]
}
function Slider({
  images,
  visible,
  onClose,
  activeIndex,
  showSnackbar,
  requestConfirmation,
  refetchQueries,
  menuActions,
}: Props) {
  const intl = useIntl()
  const [rotation, setRotation] = React.useState(0)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [index, setIndex] = React.useState<number>()
  const [editing, setEditing] = React.useState<boolean>(false)
  const open = Boolean(anchorEl)

  const closeEditing = () => {
    setEditing(false)
    setRotation(0)
  }
  const toggleDelete = () =>
    requestConfirmation?.({
      title: intl.formatMessage({ id: 'label.deletingAssetTitle' }),
      description: intl.formatMessage({
        id: 'label.deletingAssetDescription',
      }),
      actions: [
        {
          label: intl.formatMessage({ id: 'label.cancel' }),
        },
        {
          label: intl.formatMessage({ id: 'label.delete' }),
          color: 'error',
          onClick: handleDeleteImage,
        },
      ],
    })

  const handleCloseMenu = () => setAnchorEl(null)
  const toggleEdit = () => {
    handleCloseMenu()
    setEditing(!editing)
  }
  const handleClick = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget)

  const [rotateImage] = useMutation<RotateImageData, RotateImageInput>(
    ROTATE_IMAGE,
    {
      onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
      onCompleted: closeEditing,
    }
  )
  const [deleteArchive] = useMutation<DeleteArchiveData, DeleteArchiveInput>(
    DELETE_ARCHIVE,
    {
      onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
      onCompleted: () => {
        showSnackbar?.(
          intl.formatMessage({ id: 'label.deleteSuccessful' }),
          Severity.SUCCESS
        )
        onClose?.()
        handleCloseMenu()
      },
      refetchQueries,
    }
  )

  const handleOnClose = () => {
    onClose?.()
    handleCloseMenu()
  }
  const handleOnChange = (from: number, to: number) => {
    setEditing(false)
    setRotation(0)
    setIndex(to)
  }
  const handleRotateImage = () => {
    const selectedIndex = (index === undefined ? activeIndex : index) || 0
    const payload = { archiveId: images[selectedIndex].id, rotation }
    rotateImage({ variables: { payload } })
  }
  const handleDeleteImage = () => {
    const selectedIndex = (index === undefined ? activeIndex : index) || 0
    const variables = { archiveId: images[selectedIndex].id }
    deleteArchive({ variables })
  }

  const focusedArchiveId = () => {
    const selectedIndex = (index === undefined ? activeIndex : index) || 0
    return images[selectedIndex].id
  }

  return (
    <Dialog
      open={visible}
      onClose={handleOnClose}
      fullScreen
      TransitionComponent={ModalTransition}
    >
      <DialogContent sx={{ padding: 0 }}>
        <Header>
          <SimpleHeaderButton onClick={handleClick}>
            <MoreIcon />
          </SimpleHeaderButton>
          <Menu
            id="fade-menu"
            MenuListProps={{ 'aria-labelledby': 'fade-button' }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleCloseMenu}
            TransitionComponent={Fade}
          >
            {menuActions?.map((action) => {
              const handleActionClick = () => {
                action.onClick(focusedArchiveId())
                handleOnClose()
              }
              return (
                <MenuItem onClick={handleActionClick} key={action.label}>
                  {action.label}
                </MenuItem>
              )
            })}
            <MenuItem onClick={toggleEdit}>
              {intl.formatMessage({ id: 'label.edit' })}
            </MenuItem>

            <MenuItem onClick={toggleDelete}>
              {intl.formatMessage({ id: 'label.delete' })}
            </MenuItem>
          </Menu>
          <HeaderButton onClick={onClose}>
            <CloseIcon />
          </HeaderButton>
        </Header>
        <div className="slide-container">
          <Slide
            transitionDuration={400}
            autoplay={false}
            canSwipe
            defaultIndex={activeIndex}
            onChange={handleOnChange}
          >
            {images.map((image) => (
              <SlideContent key={image.id} className="each-slide">
                {editing ? (
                  <EditImage
                    image={image}
                    rotation={rotation}
                    setRotation={setRotation}
                    onCancel={closeEditing}
                    onSave={handleRotateImage}
                  />
                ) : (
                  <Image
                    src={`${appConfig.media}${
                      image.path
                    }?t=${new Date().getTime()}`}
                  />
                )}
              </SlideContent>
            ))}
          </Slide>
        </div>
      </DialogContent>
    </Dialog>
  )
}

Slider.defaultProps = {
  onClose: null,
  activeIndex: 0,
  showSnackbar: undefined,
  requestConfirmation: undefined,
  refetchQueries: undefined,
  menuActions: [],
}
// @ts-expect-error ts-migrate(2339) FIXME: Property 'showSnackbar' does not exist on type '{}'.
export default withConfirmationModal(withSnackbar(Slider))
