import { ArchiveFile } from './archive'
import { EventExtended } from './event'
import { Item } from './item'
import { Nest } from './nest'
import { ServiceProviderExtended } from './serviceProvider'
import { Address, Status } from './shared'
import { OrganizationMemberExtended, WorkspaceMemberExtended } from './user'

// Enums must match declaration in the backend
export enum ContractStatus {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  PAUSED = 'PAUSED',
  COMPLETED = 'COMPLETED', // contract finished without renewal
}

export enum PaymentType {
  REVENUE = 'REVENUE', // earning
  EXPENSE = 'EXPENSE',
}

export enum PaymentMethods {
  PAYPAL = 'PAYPAL',
  BANK_TRANSFER = 'BANK_TRANSFER',
  CREDIT_CARD = 'CREDIT_CARD',
  DEBIT_CARD = 'DEBIT_CARD',
  CASH = 'CASH',
  APPLE_PAY = 'APPLE_PAY',
  GOOGLE_PAY = 'GOOGLE_PAY',
}

enum FrequencyBase {
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY', // 3 months
  BIYEARLY = 'BIYEARLY', // 6 months or half year
  YEARLY = 'YEARLY', // 1 year
  BIENNIAL = 'BIENNIAL', // 2 years
}

export const CancelationNotice = {
  IMMEDIATE: 'IMMEDIATE', // no notice needed
  DAY: 'DAY',
  ...FrequencyBase,
}

export const PaymentFrequency = {
  ONETIME: 'ONETIME',
  ...FrequencyBase,
}

export const RenewalFrequency = {
  DAY: 'DAY',
  ...FrequencyBase,
}

interface ContractCancelation {
  link?: string
  date?: Date
  // addressId?: string
  notes?: string
}

interface ContractCancelationExtended extends ContractCancelation {
  address?: Address
}

export interface Contract {
  id: string
  workspaceId: string
  description?: string
  providerId?: string
  title: string
  contractNumber?: string
  startDate?: Date
  endDate?: Date
  createdAt: Date
  updatedAt: Date
  deletedAt?: Date
  status?: ContractStatus
  archiveIds?: string[]
  customer?: {
    number?: string
    name?: string
    // addressId?: string
  }
  cancelation?: ContractCancelation
  renewal?: {
    autoRenewal?: boolean
    frequency?: keyof typeof RenewalFrequency
    noticePeriod?: keyof typeof CancelationNotice
  }
  account?: {
    email?: string
    username?: string
    passwordHint?: string
  }
  payment?: {
    type: PaymentType
    method?: PaymentMethods
    hasMandate?: boolean
    frequency?: FrequencyBase
    amount?: {
      value?: number
      currency?: string
    }
  }
}

export interface ContractExtended extends Contract {
  archive?: ArchiveFile[]
  provider?: ServiceProviderExtended
  cancelation?: ContractCancelationExtended
  nests: Nest[]
  items: Item[]
  events?: EventExtended[]
  members?: (WorkspaceMemberExtended | OrganizationMemberExtended)[]
}

export interface CreateContractData {
  createContract: Contract
}

export interface CreateContractInput {
  payload: {
    workspaceId: string
    title: string
    contractNumber?: string
    providerId?: string
    startDate?: Date
    archiveIds?: string[]
    customer?: {
      number?: string
    }
  }
}

export interface WorkspaceContractsData {
  workspaceContracts: {
    total: number
    list: ContractExtended[]
  }
}

export interface ContractByIdData {
  contractById: ContractExtended
}

export interface UpdateContractInput {
  payload: {
    id: string
    data: {
      title?: string
      description?: string
      contractNumber?: string
      startDate?: Date
      endDate?: Date
      providerId?: string
    }
  }
}
export interface UpdateContractData {
  updateContract: Contract
}

export interface DeleteContractInput {
  id: string
}

export interface DeleteContractData {
  deleteContract: Status
}

export interface UpdateGroupItemRefsInput {
  payload: {
    contractId: string
    itemIds: string[]
  }
}
export interface UpdateGroupItemRefsData {
  updateContractItemReferences: Status
}

export interface UpdateGroupNestRefsInput {
  payload: {
    contractId: string
    nestIds: string[]
  }
}
export interface UpdateGroupNestRefsData {
  updateContractNestReferences: Status
}
