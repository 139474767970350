import React from 'react'
import SidebarCard from 'components/recordDetails/SidebarCard'
import { useIntl } from 'react-intl'
import { useTheme, styled } from '@mui/material/styles'
import { Column } from 'components/shared'
import { Link } from 'react-router-dom'
import ROUTES from 'lib/routes'
import { BodyText } from 'components/typography'
import { FontFamily } from 'assets'

const LinkComp = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  marginBottom: '4px',
}))

function PageLinksCard() {
  const intl = useIntl()
  const theme = useTheme()
  return (
    <SidebarCard title={intl.formatMessage({ id: 'label.helpfulLinks' })}>
      <Column>
        <LinkComp to={`${ROUTES.DASHBOARD}${ROUTES.TOS}`}>
          <BodyText
            fontFamily={FontFamily.NoirPro}
            color={theme.palette.text.secondary}
          >
            •{intl.formatMessage({ id: 'label.tos' })}
          </BodyText>
        </LinkComp>
        <LinkComp to={`${ROUTES.DASHBOARD}${ROUTES.IMPRINT}`}>
          <BodyText
            fontFamily={FontFamily.NoirPro}
            color={theme.palette.text.secondary}
          >
            •{intl.formatMessage({ id: 'label.imprint' })}
          </BodyText>
        </LinkComp>
        <LinkComp to={`${ROUTES.DASHBOARD}${ROUTES.PRIVACY}`}>
          <BodyText
            fontFamily={FontFamily.NoirPro}
            color={theme.palette.text.secondary}
          >
            •{intl.formatMessage({ id: 'label.privacyPolicy' })}
          </BodyText>
        </LinkComp>
        <LinkComp to={`${ROUTES.DASHBOARD}${ROUTES.FAQ}`}>
          <BodyText
            fontFamily={FontFamily.NoirPro}
            color={theme.palette.text.secondary}
          >
            •{intl.formatMessage({ id: 'label.faq' })}
          </BodyText>
        </LinkComp>
      </Column>
    </SidebarCard>
  )
}

export default PageLinksCard
