import * as React from 'react'
import { styled } from '@mui/material/styles'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { CardActionArea, Grid } from '@mui/material'
import {
  cardBoxShadow,
  cardBoxShadowHover,
  cardGradient,
} from 'assets/styles/theme'
import appConfig from 'config/app'
import placeholder from 'assets/images/workspacePlaceholder.jpg'
import { GroupType } from 'types/group'
import { getLocaleDescription, getLocaleTitle } from 'utils/content'
import { useIntl } from 'react-intl'
import { BodyText, H3 } from './typography'

const CardWrapper = styled(Card)(({ theme }) => ({
  position: 'relative',
  padding: '0px',
  borderRadius: '5px',
  boxShadow: cardBoxShadow,
  ':hover': {
    cursor: 'pointer',
    boxShadow: cardBoxShadowHover,
  },
}))

const SelectedCardWrapper = styled(CardWrapper)(({ theme }) => ({
  // border: `1.5px solid ${theme.palette.primary.main}`,
  opacity: 0.95,
}))

const Media = styled('img')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '250px',
  objectFit: 'cover',
}))

const Content = styled(CardContent)({
  display: 'flex',
  flex: 1,
  justifyContent: 'flex-end',
  flexDirection: 'column',
  position: 'absolute',
  bottom: '0',
  width: '100%',
  pointerEvents: 'none',
})

const Overlay = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: '0px',
  left: '0px',
  width: '100%',
  height: '50%',
  background: cardGradient,
  // ':hover': {
  //   background: activeCardGradient,
  // },
}))

const CheckCircle = styled(CheckCircleIcon)(({ theme }) => ({
  color: theme.palette.success.main,
  position: 'absolute',
  top: 5,
  right: 5,
  backgroundColor: theme.palette.background.paper,
  borderRadius: '50%',
}))

const HeaderGrid = styled(Grid)(({ theme }) => ({
  right: '10px',
  top: '10px',
  position: 'absolute',
  ':hover': {
    display: 'flex',
  },
}))

interface Props {
  groupType: GroupType
  selected?: boolean
  /**
   * Number of existing groups on the target workspace.
   */
  count?: number
  onClick?: (groupType: GroupType) => void
}
function GroupCard({ groupType, selected, count, onClick }: Props) {
  const intl = useIntl()
  const [isShown, setIsShown] = React.useState(false)
  const handleClick = () => onClick?.(groupType)
  const cover = groupType.coverImage?.medium
    ? `${appConfig.media}${groupType.coverImage.medium}`
    : placeholder

  const Container = selected ? SelectedCardWrapper : CardWrapper

  return (
    <Container onClick={handleClick}>
      <CardActionArea>
        <Media src={cover} alt="board cover" />
        <Overlay
          onMouseEnter={() => setIsShown(true)}
          onMouseLeave={() => setIsShown(false)}
        />
        <HeaderGrid spacing={2} sx={isShown ? { display: 'flex' } : {}}>
          <Grid item ml={1}>
            {selected && <CheckCircle />}
          </Grid>
        </HeaderGrid>
        <Content>
          <H3>{`${getLocaleTitle(intl, groupType)} ${
            count && count > 0 ? `(${count})` : ''
          }`}</H3>
          <BodyText>{getLocaleDescription(intl, groupType)}</BodyText>
        </Content>
      </CardActionArea>
    </Container>
  )
}

GroupCard.defaultProps = {
  onClick: undefined,
  selected: false,
  count: 0,
}
export default GroupCard
