import { useMutation, useQuery } from '@apollo/client'
import { Severity } from 'components/providers/SnackbarHOC'
import { ADD_FILE_TO_BOX } from 'gql/archive'
import {
  CONTAINER_BY_ID,
  DELETE_CONTAINER,
  UPDATE_CONTAINER,
  WORKSPACE_CONTAINERS,
} from 'gql/container'
import { UPDATE_CONTAINER_ITEM_REFERENCE } from 'gql/item'
import { AddFileToBoxData } from 'types/archive'
import { UpdateContainerItemRefsData } from 'types/item'
import {
  ContainerByIdData,
  DeleteContainerData,
  DeleteContainerInput,
  UpdateContainerData,
  UpdateContainerInput,
} from 'types/container'
import ROUTES from 'lib/routes'
import { NavigateFunction } from 'react-router-dom'
import { WORKSPACE_MEMBERS } from 'gql/workspace'
import { WorkspaceMembersData } from 'types/workspace'
import {
  CREATE_MEMBER_CONTAINER_REF,
  DELETE_MEMBER_CONTAINER_REF,
} from 'gql/memberRefs'
import {
  CreateMemberContainerRefData,
  CreateMemberContainerRefInput,
  DeleteMemberContainerRefData,
  DeleteMemberRefInput,
} from 'types/memberRefs'

export function useData(
  containerId: string,
  workspaceId: string,
  methods: {
    showSnackbar?: (message: string, severity: Severity) => void
  }
) {
  const {
    data: boxData,
    loading: boxLoading,
    refetch: refreshRecord,
  } = useQuery<ContainerByIdData>(CONTAINER_BY_ID, {
    variables: { containerId },
    fetchPolicy: 'cache-and-network',
    onError: (error) => methods.showSnackbar?.(error.message, Severity.ERROR),
  })

  const { data: wsMembers, loading: membersLoading } =
    useQuery<WorkspaceMembersData>(WORKSPACE_MEMBERS, {
      variables: { workspaceId },
    })

  const record = boxData?.containerById
  const members = wsMembers?.workspaceMembers.members || []
  const owners = wsMembers?.workspaceMembers.owners || []
  const loading = boxLoading || membersLoading
  return {
    loading,
    data: {
      record,
      members,
      owners,
    },
    refetch: { refreshRecord },
  }
}

export function useMutations(
  payload: {
    containerId: string
    workspaceId: string
    navigate: NavigateFunction
  },
  methods: {
    showSnackbar?: (message: string, severity: Severity) => void
    hideEditingModal: () => void
    toggleItemSelect: () => void
  }
) {
  const { showSnackbar, hideEditingModal, toggleItemSelect } = methods
  const refetchContainerDetails = [
    {
      query: CONTAINER_BY_ID,
      variables: { containerId: payload.containerId },
    },
  ]
  const refetchListing = {
    query: WORKSPACE_CONTAINERS,
    variables: {
      payload: { workspaceId: payload.workspaceId, limit: 25, page: 0 },
    },
  }
  const [updateBoxItemRefs, { loading: refsLoading }] =
    useMutation<UpdateContainerItemRefsData>(UPDATE_CONTAINER_ITEM_REFERENCE, {
      onCompleted: toggleItemSelect,
      onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
      refetchQueries: refetchContainerDetails,
    })
  const [addFileToBox] = useMutation<AddFileToBoxData>(ADD_FILE_TO_BOX, {
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: refetchContainerDetails,
  })
  const [updateContainer, { loading: updateLoading }] = useMutation<
    UpdateContainerData,
    UpdateContainerInput
  >(UPDATE_CONTAINER, {
    onCompleted: hideEditingModal,
    refetchQueries: [
      {
        query: CONTAINER_BY_ID,
        variables: { containerId: payload.containerId },
      },
      refetchListing,
    ],
  })

  const [deleteContainer, { loading: deleting }] = useMutation<
    DeleteContainerData,
    DeleteContainerInput
  >(DELETE_CONTAINER, {
    onCompleted: () =>
      payload.navigate(ROUTES.DASHBOARD_CONTAINERS_ROUTE(payload.workspaceId), {
        replace: true,
      }),
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: [refetchListing],
  })

  const [createMemberContainerRef, { loading: createRefLoading }] = useMutation<
    CreateMemberContainerRefData,
    CreateMemberContainerRefInput
  >(CREATE_MEMBER_CONTAINER_REF, {
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: refetchContainerDetails,
  })
  const [deleteMemberContainerRef, { loading: deleteRefLoading }] = useMutation<
    DeleteMemberContainerRefData,
    DeleteMemberRefInput
  >(DELETE_MEMBER_CONTAINER_REF, {
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: refetchContainerDetails,
  })

  const loading =
    refsLoading ||
    updateLoading ||
    deleting ||
    createRefLoading ||
    deleteRefLoading
  return {
    loading,
    mutations: {
      updateBoxItemRefs,
      addFileToBox,
      updateContainer,
      deleteContainer,
      createMemberContainerRef,
      deleteMemberContainerRef,
    },
  }
}
