import React from 'react'
import Grid from '@mui/material/Grid'
import { FileType } from 'types/archive'
import useMediaQuery from '@mui/material/useMediaQuery'
import ImageHeadlineGrid from 'components/ImageHeadlineGrid'
import { PageDescription } from 'components/pages'
import { styled, useTheme } from '@mui/material/styles'
import { RowSpaced } from 'components/shared'
import MuiIconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/EditOutlined'
import Card from '@mui/material/Card'
import { ServiceExtended } from 'types/service'
import { StatusChip } from 'components/cards'
import { PublicationStatus } from 'types/shared'
import { useIntl } from 'react-intl'
import { H1 } from 'components/typography'
import { Section } from './constants'

const IconButton = styled(MuiIconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
}))

const HeadlineCard = styled(Card)({
  display: 'flex',
  flex: 1,
  flexGrow: 1,
  flexDirection: 'row',
  boxShadow: 'none',
  flexWrap: 'nowrap',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const HeadlineContainer = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
})

interface Props {
  record: ServiceExtended
  showMediaUploadModal: () => void
  onEditSection: (sectionId: Section) => void
}
function Headline({ record, showMediaUploadModal, onEditSection }: Props) {
  const theme = useTheme()
  const intl = useIntl()
  const isBigScreen = useMediaQuery(theme.breakpoints.up('md'))
  const images = record?.archive?.filter((i) => i.type === FileType.image) ?? []
  const onEdit = () => onEditSection(Section.general)

  const chipColor =
    record.status === PublicationStatus.PUBLIC ? 'primary' : 'default'

  const headline = (
    <HeadlineCard>
      <HeadlineContainer>
        <RowSpaced>
          {record.status ? (
            <StatusChip color={chipColor} label={record.status} />
          ) : (
            <span />
          )}
          <IconButton onClick={onEdit}>
            <EditIcon />
          </IconButton>
        </RowSpaced>
        <H1>{record.title}</H1>
        <PageDescription>{record.description}</PageDescription>
        <br />
        {record.type && (
          <PageDescription>{`${intl.formatMessage({
            id: 'label.type',
          })}: ${record.type?.title}`}</PageDescription>
        )}
      </HeadlineContainer>
    </HeadlineCard>
  )

  return (
    <React.Fragment>
      {isBigScreen ? (
        <Grid container mb={4} spacing={1}>
          <Grid item md={5}>
            <ImageHeadlineGrid
              images={images}
              onCtaClick={showMediaUploadModal}
            />
          </Grid>
          <Grid item md={7}>
            {headline}
          </Grid>
        </Grid>
      ) : (
        <Grid container mb={4} spacing={1}>
          <Grid item xs={12}>
            {headline}
          </Grid>
          <Grid item xs={12}>
            <ImageHeadlineGrid
              images={images}
              onCtaClick={showMediaUploadModal}
            />
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  )
}

export default Headline
