import React from 'react'
import { Column, Row, RowSpaced } from 'components/shared'
import { BodyText, CaptionText, H6 } from 'components/typography'
import { useIntl } from 'react-intl'
import { SubscriptionInvoice, SubscriptionPlan } from 'types/subscription'
import { styled, Theme } from '@mui/material/styles'
import { FontFamily } from 'assets'
import { getMonetaryValue } from 'utils/monetary'
import { getDate } from 'utils/datetime'
import { getLocaleTitle } from 'utils/content'
import { getDurationLabel } from 'pages/pricing/constants'

const Container = styled(Column, {
  shouldForwardProp: (prop) => prop !== 'isRefund',
})<{
  theme?: Theme
  isRefund?: boolean
}>(({ theme, isRefund }) => ({
  backgroundColor: isRefund
    ? theme.palette.shared.darkGray
    : theme.palette.shared.lightBlue,
  marginTop: '8px',
  borderRadius: '4px',
  padding: '4px',
}))

interface Props {
  item: SubscriptionInvoice['items'][number]
  relatedPlans: SubscriptionPlan[]
}
function InvoiceItem({ item, relatedPlans }: Props) {
  const intl = useIntl()
  const isExtraDuration = item?.price?.value === 0
  const plan = relatedPlans.find((p) => p.id === item.productId)
  const duration = plan?.duration ? getDurationLabel(intl, plan?.duration) : ''
  const title = `${plan ? getLocaleTitle(intl, plan) : ''} ${
    isExtraDuration ? '' : duration ?? ''
  } ${
    isExtraDuration
      ? intl.formatMessage(
          { id: 'label.extraMonths' },
          { number: plan?.extraDuration ?? '' }
        )
      : ''
  }`.trim()
  return (
    <Container isRefund={!!item.invoiceReference}>
      <Row>
        <H6>{title}</H6>
      </Row>
      <RowSpaced>
        <CaptionText>
          {intl.formatMessage({ id: 'label.startDate' })}
        </CaptionText>
        <BodyText>{getDate(item?.fromDate)}</BodyText>
      </RowSpaced>
      <RowSpaced>
        <CaptionText>{intl.formatMessage({ id: 'label.endDate' })}</CaptionText>
        <BodyText>{getDate(item?.toDate)}</BodyText>
      </RowSpaced>
      <RowSpaced>
        <CaptionText>{intl.formatMessage({ id: 'label.price' })}</CaptionText>
        <BodyText fontFamily={FontFamily.NoirProMedium}>
          {getMonetaryValue(item?.price)}
        </BodyText>
      </RowSpaced>
    </Container>
  )
}

export default InvoiceItem
