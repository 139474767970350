import React, { useState, useCallback, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Severity, withSnackbar } from 'components/providers/SnackbarHOC'
import { Container, Content } from 'components/shared'
import LoadingIndicator from 'components/LoadingIndicator'
import { FileType } from 'types/archive'
import { useTheme } from '@mui/material/styles'
import { SuggestionSection } from 'components/recordDetails/SuggestionsSection'
import { FileEntry, JsonObject, MemberModels } from 'types/shared'
import FormModal from 'components/recordDetails/FormModal'
import ItemSelectDrawer from 'components/selectorDrawers/ItemSelectDrawer'
import { useSubscriptionStatus } from 'hooks/graphql/workspaces'
import { ItemCreateDrawer } from 'components/selectorDrawers'
import UploadHandler, {
  UploadHandlerMethods,
} from 'components/dropzone/UploadHandler'
import { useFileUpload } from 'hooks/api/fileUpload'
import { useMediaQuery } from '@mui/material'
import ROUTES from 'lib/routes'
import Tabs from 'components/Tabs'
import {
  PageGrid,
  PageGridLeft,
  PageGridRight,
  PageHeader,
  StickySection,
} from 'components/pages'
import Box from '@mui/material/Box'
import ContainerSelectDrawerMultiple from 'components/selectorDrawers/ContainerSelectDrawerMultiple'
import {
  ConfirmationData,
  withConfirmationModal,
} from 'components/hoc/ConfirmationModalHoc'
import SidebarCard from 'components/recordDetails/SidebarCard'
import {
  getEditInputs,
  getExtraInputs,
  getInitialState,
  getSectionWithId,
  payloadBuilder,
  relationsContent,
  Section,
} from './constants'
import Headline from './Headline'
import { useData, useMutations } from './hooks'
import contentTabs from './tabs'

interface Props {
  showSnackbar?: (message: string, severity: Severity) => void
  requestConfirmation?: (data: ConfirmationData) => void
}
function Nest({ showSnackbar, requestConfirmation }: Props) {
  const intl = useIntl()
  const theme = useTheme()
  const navigate = useNavigate()
  const params = useParams() as { workspaceId: string; nestId: string }
  const isBigScreen = useMediaQuery(theme.breakpoints.up('lg'))
  const { active } = useSubscriptionStatus(params.workspaceId)
  const [showItemSelectDrawer, setShowItemSelectDrawer] = useState(false)
  const [showItemCreateDrawer, setShowItemCreateDrawer] = useState(false)
  const [showBoxSelectDrawer, setShowBoxSelectDrawer] = useState(false)
  const [editing, setEditing] = useState<SuggestionSection>()
  // optional id of the sub-record to edit, example (saleInfoId)
  const [editingId, setEditingId] = useState<string>()
  const hideEditingModal = useCallback(() => setEditing(undefined), [])
  const toggleItemSelect = () => setShowItemSelectDrawer(!showItemSelectDrawer)
  const toggleItemCreate = () => setShowItemCreateDrawer(!showItemCreateDrawer)
  const toggleBoxSelect = () => setShowBoxSelectDrawer(!showBoxSelectDrawer)
  const uploadHandlerRef = useRef<UploadHandlerMethods>(null)
  const { uploadFiles } = useFileUpload()

  const toggleDelete = () =>
    requestConfirmation?.({
      title: intl.formatMessage({ id: 'label.deletingSpace' }),
      description: intl.formatMessage({
        id: 'label.deletingSpaceConfirmation',
      }),
      actions: [
        {
          label: intl.formatMessage({ id: 'label.cancel' }),
        },
        {
          label: intl.formatMessage({ id: 'label.delete' }),
          color: 'error',
          onClick: handleDelete,
        },
      ],
    })
  const {
    loading,
    data: { record, nestTypes, nestContainers, spaceOptions, members, owners },
    refetch: { refreshRecord },
  } = useData(params.nestId, params.workspaceId, { showSnackbar })

  const {
    loading: mutating,
    mutations: {
      deleteNests,
      updateNest,
      addFileToNest,
      updateNestItemRefs,
      updateNestContainerRefs,
      createMemberNestRef,
      deleteMemberNestRef,
    },
  } = useMutations(
    { ...params, navigate },
    { showSnackbar, hideEditingModal, toggleItemSelect, toggleBoxSelect }
  )

  const handleDelete = () =>
    deleteNests({ variables: { nestIds: [record?.id ?? ''] } })

  const showMediaUploadModal = useCallback(() => {
    uploadHandlerRef.current?.openUploader(FileType.image)
  }, [])

  const onUpload = async (files: FileEntry[], fileType: FileType) => {
    await uploadFiles(files, fileType, params.workspaceId, {
      onCompleted: (archiveIds, hasErrors) => {
        archiveIds.forEach((archiveId) => {
          const payload = { nestId: params.nestId, archiveId }
          addFileToNest({ variables: { payload } })
        })
        if (!hasErrors) {
          uploadHandlerRef.current?.closeUploader()
          showSnackbar?.(
            intl.formatMessage({ id: 'label.uploadSuccessful' }),
            Severity.SUCCESS
          )
        }
      },
      onError: (errors) => {
        if (errors.length > 0)
          showSnackbar?.(
            `File upload failed, (occurrences: ${errors.length}).
              ${errors[0]?.error?.message}
            `,
            Severity.ERROR
          )
        uploadHandlerRef.current?.stopLoader()
      },
    })
  }

  const handleOnEditWithId = (sectionId: Section, recordId?: string) => {
    const section = getSectionWithId(sectionId, intl)
    setEditingId(recordId)
    setEditing(section)
  }

  const handleRecordUpdate = (formData: JsonObject, sectionId?: string) => {
    if (!record) return
    const payload = payloadBuilder(record, formData, params.nestId, sectionId)
    updateNest({ variables: { payload } })
  }

  const handleOnUpdate = (formData: JsonObject, sectionId?: string) => {
    handleRecordUpdate(formData, sectionId)
  }

  const handleOnItemSelect = (itemIds: string[]) => {
    const payload = { nestId: params.nestId, itemIds }
    updateNestItemRefs({ variables: { payload } })
  }

  const handleOnUpdateBoxRef = (containerIds: string[]) => {
    const payload = { nestId: params.nestId, containerIds }
    updateNestContainerRefs({ variables: { payload } })
  }

  const createMemberRef = (memberId: string, memberModel: MemberModels) => {
    const payload = {
      memberId,
      workspaceId: params.workspaceId,
      memberModel,
      nestId: params.nestId,
    }
    createMemberNestRef({ variables: { payload } })
  }
  const deleteMemberRef = (refId: string) => {
    deleteMemberNestRef({ variables: { id: refId } })
  }

  const inputs = record
    ? getEditInputs(intl, editing?.id ?? '', record, nestTypes)
    : []
  const extraInputs = getExtraInputs(editing?.id ?? '')
  const initialState =
    record && editing ? getInitialState(record, editing.id, editingId) : {}

  const tabs = contentTabs(
    intl,
    { record, containers: nestContainers, spaceOptions },
    {
      toggleDelete,
      showSnackbar,
      handleOnEditWithId,
      toggleItemSelect,
      toggleContainerSelect: toggleBoxSelect,
    }
  )
  const sidebar = (
    <SidebarCard
      title={intl.formatMessage({
        id: 'label.relations',
      })}
      content={
        record
          ? relationsContent(
              intl,
              {
                containers: nestContainers,
                record,
                members: [...owners, ...members],
              },
              {
                toggleItemSelect,
                toggleBoxSelect,
                deleteMemberRef,
                createMemberRef,
              }
            )
          : []
      }
    />
  )

  const isLoading = loading || mutating
  return (
    <Container detailPage>
      <LoadingIndicator visible={isLoading} />
      <PageHeader
        pageTitle={intl.formatMessage({ id: 'label.spaces' })}
        recordTitle={record?.title ?? ''}
        breadcrumbLink={ROUTES.DASHBOARD_NESTS_ROUTE(params.workspaceId)}
      />
      <Content detailPage>
        <PageGrid container spacing={6}>
          <PageGridLeft item xs={12} sm={12} md={12} lg={7} xl={8}>
            {record && (
              <Headline
                record={record}
                showMediaUploadModal={showMediaUploadModal}
                onEditSection={handleOnEditWithId}
              />
            )}

            {!isBigScreen && sidebar}
            <Tabs tabs={tabs} locked={!active} />
          </PageGridLeft>

          {isBigScreen && (
            <PageGridRight item xs={12} sm={12} md={12} lg={5} xl={4}>
              <StickySection>
                {sidebar}
                <Box sx={{ height: '40px' }} />
              </StickySection>
            </PageGridRight>
          )}
        </PageGrid>
      </Content>
      <UploadHandler ref={uploadHandlerRef} onSubmit={onUpload} />
      <FormModal
        toggleModal={hideEditingModal}
        inputs={inputs}
        extraInputs={extraInputs}
        section={editing}
        initialState={initialState}
        onSubmit={handleOnUpdate}
      />
      <ItemSelectDrawer
        open={showItemSelectDrawer}
        toggleDrawer={toggleItemSelect}
        nestId={params.nestId}
        preSelectedRecords={record?.items ?? []}
        onSubmit={handleOnItemSelect}
        actions={[
          {
            label: intl.formatMessage({ id: 'label.createNewItem' }),
            onClick: () => {
              toggleItemSelect()
              toggleItemCreate()
            },
          },
        ]}
      />
      <ContainerSelectDrawerMultiple
        open={showBoxSelectDrawer}
        toggleDrawer={toggleBoxSelect}
        preSelectedRecords={nestContainers}
        onSubmit={handleOnUpdateBoxRef}
      />

      <ItemCreateDrawer
        open={showItemCreateDrawer}
        toggleDrawer={toggleItemCreate}
        nestId={params.nestId}
        workspaceId={params.workspaceId}
        onComplete={() => {
          toggleItemCreate()
          refreshRecord()
        }}
      />
    </Container>
  )
}

Nest.defaultProps = {
  showSnackbar: undefined,
  requestConfirmation: undefined,
}

export default withConfirmationModal(withSnackbar(Nest))
