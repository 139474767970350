import React from 'react'
import { styled } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { BodyText, H4, H6 } from 'components/typography'
import Button from '@mui/material/Button'
import { Profile } from 'types/profile'
import { useIntl } from 'react-intl'
import { useSubscriptionStatus } from 'hooks/graphql/workspaces'
import { getActiveWorkspaceId } from 'lib/sessionStorage'
import { Link, useLocation } from 'react-router-dom'
import ROUTES from 'lib/routes'
import { getSubscriptionStatusCheck } from './landing/constants'

const Container = styled(Toolbar)(({ theme }) => ({
  backgroundColor: theme.palette.shared.red,
  justifyContent: 'space-between',
  borderBottom: `1px solid ${theme.palette.shared.darkGray}`,
}))

interface Props {
  profile?: Profile
}
function NotificationBanner({ profile }: Props) {
  const theme = useTheme()
  const intl = useIntl()
  const location = useLocation()
  const workspaceId = getActiveWorkspaceId()
  const isBigScreen = useMediaQuery(theme.breakpoints.up('sm'))
  const Text = isBigScreen ? H4 : H6
  const { active, section } = useSubscriptionStatus(workspaceId)

  if (active || !section) return <React.Fragment />
  const status = getSubscriptionStatusCheck(intl, section, profile?.id ?? '')
  const isAccountCenter = location.pathname.includes(
    ROUTES.DASHBOARD_ACCOUNT_PREFERENCES
  )

  return (
    <Container>
      <Text color={theme.palette.background.default}>{status.actionLabel}</Text>
      {!isAccountCenter && status.isOwner && (
        <Button
          color="secondary"
          variant="outlined"
          component={Link}
          to={`${ROUTES.DASHBOARD}${ROUTES.DASHBOARD_ACCOUNT_PREFERENCES}`}
        >
          <BodyText color={theme.palette.background.default}>
            {intl.formatMessage({ id: 'label.accountCenter' })}
          </BodyText>
        </Button>
      )}
    </Container>
  )
}

export default NotificationBanner

NotificationBanner.defaultProps = {
  profile: null,
}
