import React from 'react'
import { BodyTextLight, CaptionText, H4 } from 'components/typography'
import { useNavigate } from 'react-router-dom'
import { Column, Row } from 'components/shared'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import { Box } from '@mui/material'
import { cardBoxShadow, cardBoxShadowHover } from 'assets/styles/theme'

interface InventoryEntityCardProps {
  label: string
  description?: string
  description2?: string
  to: string
  Icon: unknown
  count?: number
}

const IconButton = styled(Button)(({ theme }) => ({
  padding: '12px',
  backgroundColor: theme.palette.background.default,
  borderRadius: '5px',
  boxShadow: cardBoxShadow,
  width: '100%',
  height: '100%',
  [theme.breakpoints.down('sm')]: {
    padding: '8px',
  },
  ':hover': {
    cursor: 'pointer',
    boxShadow: cardBoxShadowHover,
  },
}))

export function InventoryEntityCard({
  Icon,
  label,
  description,
  description2,
  count,
  to,
}: InventoryEntityCardProps) {
  const navigate = useNavigate()
  const handleOnPress = () => {
    navigate(to)
  }
  return (
    <IconButton onClick={handleOnPress}>
      <Column>
        {/* @ts-expect-error icon type */}
        <Icon width="32px" />
        <Row>
          <H4>{label}</H4>
        </Row>
        <Box sx={{ height: '12px' }} />

        {typeof count === 'number' && (
          <Row style={{ flexWrap: 'nowrap' }}>
            <H4>{count}</H4>
            <BodyTextLight>&#8192;{label.toLowerCase()}</BodyTextLight>
          </Row>
        )}

        {description && (
          <Row style={{ flexWrap: 'nowrap' }}>
            <CaptionText style={{ textAlign: 'left' }}>
              {description}
            </CaptionText>
          </Row>
        )}
        {description2 && (
          <Row style={{ flexWrap: 'nowrap' }}>
            <CaptionText style={{ textAlign: 'left' }}>
              {description2}
            </CaptionText>
          </Row>
        )}
      </Column>
    </IconButton>
  )
}
InventoryEntityCard.defaultProps = {
  description: null,
  count: null,
  description2: null,
}
