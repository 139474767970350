import React from 'react'
import { styled } from '@mui/material/styles'
import CameraIcon from '@mui/icons-material/CameraAlt'

const Avatar = styled('img')(({ theme }) => ({
  height: '120px',
  width: '120px',
  overflow: 'hidden',
  borderRadius: '60px',
  objectFit: 'cover',
  border: `2px solid ${theme.palette.primary.main}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
}))

const AvatarWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  right: 20,
  top: -50,
  ':hover svg': {
    visibility: 'visible',
  },
}))
const PhotoChange = styled(CameraIcon)(({ theme }) => ({
  position: 'absolute',
  color: theme.palette.primary.main,
  top: 45,
  right: '35%',
  opacity: 0.8,
  visibility: 'hidden',
}))

export function ProfileAvatar({
  src,
  onClick,
}: {
  src: string
  onClick: () => void
}) {
  return (
    <AvatarWrapper onClick={onClick}>
      <Avatar src={src} />
      <PhotoChange fontSize="large" />
    </AvatarWrapper>
  )
}
