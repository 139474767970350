import React from 'react'
import { styled } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { BodyText, H4, H6 } from 'components/typography'
import Button from '@mui/material/Button'
import EmailOutlinedIcon from '@mui/icons-material/MailOutlineSharp'
import { Profile } from 'types/profile'
import { useIntl } from 'react-intl'
import { useLazyQuery } from '@apollo/client'
import {
  RequestEmailConfirmationData,
  RequestEmailConfirmationInput,
} from 'types/email'
import { Severity } from 'components/providers/SnackbarHOC'
import { REQUEST_EMAIL_CONFIRMATION } from 'gql/email'

const Container = styled(Toolbar)(({ theme }) => ({
  backgroundColor: theme.palette.shared.red,
  justifyContent: 'space-between',
  borderBottom:`1px solid ${theme.palette.shared.darkGray}`
}))

interface Props {
  profile?: Profile
  showSnackbar?: (message: string, severity: Severity) => void
}
function NotificationBanner({ profile, showSnackbar }: Props) {
  const theme = useTheme()
  const intl = useIntl()
  const isBigScreen = useMediaQuery(theme.breakpoints.up('sm'))
  const Text = isBigScreen ? H4 : H6
  const [requestEmailVerification] = useLazyQuery<
    RequestEmailConfirmationData,
    RequestEmailConfirmationInput
  >(REQUEST_EMAIL_CONFIRMATION, {
    onCompleted: () =>
      showSnackbar?.(
        intl.formatMessage({ id: 'label.emailConfirmationRequested' }),
        Severity.SUCCESS
      ),
  })

  const verifyEmail = () => {
    if (profile?.email)
      requestEmailVerification({ variables: { email: profile?.email } })
  }

  if (!profile || profile.confirmed) return <React.Fragment />

  return (
    <Container>
      <Text color={theme.palette.background.default}>
        {intl.formatMessage({ id: 'label.emailVerificationWarning' })}
      </Text>
      <Button color="secondary" variant="outlined" onClick={verifyEmail}>
        <EmailOutlinedIcon fontSize="small" sx={{ marginRight: '6px' }} />
        <BodyText color={theme.palette.background.default}>
          {intl.formatMessage({ id: 'label.verify' })}
        </BodyText>
      </Button>
    </Container>
  )
}

export default NotificationBanner

NotificationBanner.defaultProps = {
  profile: null,
  showSnackbar: undefined,
}
