import { IntlShape } from 'react-intl'
import { SubscriptionPlan, SubscriptionPlanType } from 'types/subscription'
import { getCurrencyLabel } from 'utils/monetary'

/**
 * Calculate label of pricing based on the duration
 * @param duration number of months
 */
export function getDurationLabel(intl: IntlShape, duration: number) {
  const isYear = duration % 12 === 0
  const yearLabel = duration / 12 > 1 ? 'label.xYears' : 'label.oneYear'
  const monthsLabel = duration > 1 ? 'label.xMonths' : 'label.oneMonth'
  return intl.formatMessage(
    { id: isYear ? yearLabel : monthsLabel },
    { number: isYear ? duration / 12 : duration }
  )
}

const sortPriority = {
  [SubscriptionPlanType.FREE]: 0,
  [SubscriptionPlanType.STARTER]: 100,
  [SubscriptionPlanType.STANDARD]: 200,
  [SubscriptionPlanType.PREMIUM]: 300,
}

/**
 * group plans by duration
 * @param plans
 * @returns
 */
export const getGroupedPlans = (plans: SubscriptionPlan[]) =>
  plans.reduce(
    (acc: { [key: number]: SubscriptionPlan[] }, plan: SubscriptionPlan) => {
      return {
        ...acc,
        [plan.duration]: [...(acc?.[plan.duration] ?? []), plan].sort(
          (a, b) => sortPriority[a.type] - sortPriority[b.type]
        ),
      }
    },
    {}
  )

/**
 * @deprecated: Looks like this is not needed 
 * Method to calculate the difference if price between the target plan and the base plan.
 * @param plan
 * @param basePlan
 * @param countryCode
 * @returns null or number (savings in price). E.g. saved: 60$
 * 
 * Previous usage:
 *  const discountInPrice = relatedBasePlan
 * ? calculateDiscountInPrice(plan, relatedBasePlan, countryCode)
 * : null
 */
export const calculateDiscountInPrice = (
  plan: SubscriptionPlan,
  basePlan: SubscriptionPlan,
  countryCode: string
): number | null => {
  if (plan.id === basePlan.id) return null

  const countryPrice = plan.pricing.find(
    (price) => price.countryCode === countryCode
  )
  const baseCountryPrice = basePlan?.pricing.find(
    (price) => price.countryCode === countryCode
  )

  if (!countryPrice || !baseCountryPrice) return null

  const discount = calculateFloorPrice(
    // what would be this plan if paid monthly - minus
    (plan.duration + plan.extraDuration ?? 0) * baseCountryPrice?.value -
      // actual value of the plan (times the billing interval of the base plan. usually 1 month)
      (basePlan.duration + basePlan.extraDuration ?? 0) * countryPrice.value
  )
  return discount > 0 ? discount : null
}
/**
 * Method to calculate the price that the plan would have been if the user purchased it as base plans.
 * @param plan
 * @param basePlan
 * @param countryCode
 * @returns null or an evaluated price of the plans.
 */
export const calculatePriceDiffFromBase = (
  plan: SubscriptionPlan,
  basePlan: SubscriptionPlan,
  countryCode: string
): number | null => {
  if (plan.id === basePlan.id) return null

  const countryPrice = plan.pricing.find(
    (price) => price.countryCode === countryCode
  )
  const baseCountryPrice = basePlan?.pricing.find(
    (price) => price.countryCode === countryCode
  )

  if (!countryPrice || !baseCountryPrice) return null

  const evaluatedPrice = calculateFloorPrice(
    ((plan.duration + plan.extraDuration ?? 0) * baseCountryPrice?.value) /
      (basePlan.duration + basePlan.extraDuration ?? 0)
  )
  return evaluatedPrice > 0 ? evaluatedPrice : null
}

export function calculateFloorPrice(price: number) {
  return Number(price.toFixed(2))
}

export function calculateDiffInPercentage(
  evaluatedTotalPay: number,
  actualValue: number
) {
  const percent = ((evaluatedTotalPay - actualValue) / actualValue) * 100
  return Math.floor(percent)
}

export function calculatePricePerMonth(
  plan: SubscriptionPlan,
  countryCode: string
) {
  const countryPrice = plan.pricing.find(
    (price) => price.countryCode === countryCode
  )
  return calculateFloorPrice(
    (countryPrice?.value ?? 0) / (plan.duration + plan.extraDuration)
  ).toFixed(2)
}

/**
 * Method to get the currency unit of the pricing plan for the target country.
 * @param plan
 * @param countryCode
 * @returns Money symbol: $
 */
export function getCurrencyUnit(plan: SubscriptionPlan, countryCode: string) {
  const countryPrice = plan.pricing.find(
    (price) => price.countryCode === countryCode
  )
  return getCurrencyLabel(countryPrice?.currency)
}

/**
 * Method to determine if a subscription can be used as an upgrade from the current active subscription.
 * @param subscriptionPlans
 * @param currentSubscriptionId
 *
 *  // A plan can be considered for upgrade if:
 *  // 1. the duration is the same or longer than the current plans duration.
 *  // 2. the plan type of the new plan is same or better than the current active plan.
 */
export function getUpgradeMap(
  subscriptionPlans: SubscriptionPlan[],
  currentSubscriptionId: string
) {
  const focusedPlan = subscriptionPlans.find(
    (item) => item.id === currentSubscriptionId
  )
  if (!focusedPlan) return null
  return subscriptionPlans.reduce((acc, plan) => {
    // check if the user can upgrade to this plan.

    const canUpgradeTo =
      // plan.id !== focusedPlan.id &&
      plan.duration >= focusedPlan.duration &&
      allowedUpgrades[focusedPlan.type].includes(plan.type)
    return {
      ...acc,
      [plan.id]: canUpgradeTo,
    }
  }, {})
}

const allowedUpgrades: {
  [key in SubscriptionPlanType]: SubscriptionPlanType[]
} = {
  [SubscriptionPlanType.FREE]: [
    SubscriptionPlanType.STARTER,
    SubscriptionPlanType.STANDARD,
    SubscriptionPlanType.PREMIUM,
  ],
  [SubscriptionPlanType.STARTER]: [
    // SubscriptionPlanType.STARTER,
    SubscriptionPlanType.STANDARD,
    SubscriptionPlanType.PREMIUM,
  ],
  [SubscriptionPlanType.STANDARD]: [
    // SubscriptionPlanType.STANDARD,
    SubscriptionPlanType.PREMIUM,
  ],
  [SubscriptionPlanType.PREMIUM]: [
    // SubscriptionPlanType.PREMIUM
  ],
}
