import React from 'react'
import { BodyTextLight, H2 } from 'components/typography'
import { useTheme } from '@mui/material/styles'
import { ContentData } from 'components/recordDetails/ContentSection'
import { Column } from 'components/shared'
import {
  ActionRender,
  Container,
  EntriesContent,
  HeaderWrapper,
} from './fragments'

export interface Action {
  ariaLabel: string
  icon: React.ElementType
  onPress: () => void
}

interface ContentDataType extends Omit<ContentData, 'label'> {
  label: string | JSX.Element
}
export interface SectionEntry {
  title?: string
  description?: string
  actions?: Action[]
  data?: ContentDataType[]
  content?: JSX.Element
  headlineContent?: JSX.Element
  // use preventDefault for all action items in case this prop is used
  onClick?: () => void
}

interface Props {
  header?: string
  subHeadline?: string
  headerActions?: Action[]
  entries?: SectionEntry[]
  children?: React.ReactNode | React.ReactNode[]
  containerStyle?: React.CSSProperties
}
function Section({
  header,
  headerActions,
  entries,
  children,
  subHeadline,
  containerStyle,
}: Props) {
  const theme = useTheme()
  const hasHeader = !!header || !!headerActions
  return (
    <Container style={containerStyle}>
      <HeaderWrapper visible={hasHeader}>
        <Column>
          <H2>{header}</H2>
          {subHeadline && (
            <BodyTextLight color={theme.palette.text.secondary}>
              {subHeadline}
            </BodyTextLight>
          )}
        </Column>
        <ActionRender actions={headerActions} />
      </HeaderWrapper>

      {children}
      <EntriesContent entries={entries} />
    </Container>
  )
}

Section.defaultProps = {
  header: null,
  headerActions: null,
  entries: [],
  children: null,
  subHeadline: null,
  containerStyle: {},
}

export default Section
