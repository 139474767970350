import { Organization, OrganizationType } from './organization'
import { Currencies } from './shared'

// the listed order affect the order which they are presented on the pricing screen
export enum Limitations {
  ACCOUNT_OWNERS = 'ACCOUNT_OWNERS',
  BOARDS = 'BOARDS',
  ITEMS = 'ITEMS',
  SPACES = 'SPACES',
  CONTAINERS = 'CONTAINERS',
  GROUPS = 'GROUPS',
  MEMBERS = 'MEMBERS',
  CONTRACTS = 'CONTRACTS',
  STORAGE = 'STORAGE',
}

export enum SubscriptionPlanType {
  STARTER = 'STARTER',
  STANDARD = 'STANDARD',
  PREMIUM = 'PREMIUM',
  FREE = 'FREE',
}

export interface SubscriptionPlan {
  id: string
  type: SubscriptionPlanType
  title: string
  titleDE: string
  description?: string
  descriptionDE?: string
  // duration in months
  duration: number
  // number of months as a bonus to the user
  extraDuration: number
  // subscription plan is active for the public
  active: boolean
  // The default subscription plan to assign on account creation.
  // Can be only one set to true and must be an active subscription plan
  // Applies as default to TRAIL or FREE plans.
  defaultPlan: boolean
  // Applicable to organization types. If empty applies to all types.
  applicableTo?: OrganizationType[]
  limitations: { [key in Limitations]: number }
  pricing: {
    countryCode: string
    value: number
    currency: Currencies
    // tax percentage 1-100
    tax: number
    // number of days on which the plan can be refunded. E.g 30 days money back guarantee.
    refundDeadline: number
  }[]
}

export interface SubscriptionPlanData {
  subscriptionPlans: { list: SubscriptionPlan[]; total: number }
}

export interface SubscriptionPlanInput {
  payload: {
    includeAll?: boolean
    countryCode?: string
  }
}

export interface SubscriptionByIdData {
  subscriptionById: Subscription
}

export interface SubscriptionByIdInput {
  id: string
}

export interface SubscriptionPlanByIdData {
  subscriptionPlanById: SubscriptionPlan
}

export interface SubscriptionPlanByIdInput {
  id: string
}
interface CheckoutOrganization extends Organization {
  owner: { id: string; firstName: string; lastName: string }
}

interface CheckoutSubscriptionPlan extends Omit<SubscriptionPlan, 'pricing'> {
  pricing: SubscriptionPlan['pricing'][number]
}

export interface CheckoutDetailsData {
  checkoutDetails: {
    organizations: CheckoutOrganization[]
    subscriptionPlan: CheckoutSubscriptionPlan
  }
}

export interface CheckoutDetailsInput {
  payload: {
    subscriptionPlanId: string
    countryCode: string
    subscriptionId?: string
  }
}

export interface CheckoutUpgradeDetailsData {
  checkoutUpgradeDetails: {
    // no id field
    items: Omit<SubscriptionInvoice['items'], 'id'>
    pricing: SubscriptionInvoice['pricing']
    subscriptionPlan: SubscriptionPlan
    relatedPlans: SubscriptionPlan[]
  }
}

export interface CheckoutUpgradeDetailsInput {
  payload: {
    subscriptionPlanId: string
    subscriptionId: string
  }
}

export enum SubscriptionStatus {
  // when the subscription is being purchased and it will be created in a draft status.
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  EXPIRED = 'EXPIRED',
}

export interface Subscription {
  id: string
  subscriptionPlanId: string
  organizationId: string
  startDate: Date
  endDate: Date
  cancelationDate?: Date
  status: SubscriptionStatus
  createdAt: Date
  updatedAt: Date
}

export interface SubscriptionOrder {
  id: string
  orderNumber: string
  subscriptionIds: string[]
  organizationId: string
  date: Date
  createdAt: Date
  updatedAt: Date
}

export enum InvoiceStatus {
  DRAFT = 'DRAFT',
  PAID = 'PAID',
  REFUNDED = 'REFUNDED',
}

export interface SubscriptionInvoice {
  id: string
  // generated number of reference sent to the customer
  invoiceNumber: string
  orderId: string
  // invoice Date
  date: Date
  // the items/subscriptions being ordered. This will be based on the country code selection, in case of subscription plans.
  items: {
    id: string
    productId: string
    productReference: string // product number or title
    productDescription: string
    // subscription plan duration applicable for this purchase
    fromDate: Date
    toDate: Date
    price: {
      value: number
      currency: Currencies
      totalAmount: number
    }
    // in case the specific entry is a refund item
    invoiceReference?: string
  }[]
  // total price of the invoice
  pricing: {
    countryCode: string // relevant for tax applications
    value: number
    totalAmount: number // value + tax
    tax: number
    currency: Currencies
  }
  isRefunded: boolean
  // document related to this transaction - can be invoices or refunds - cancelation documents, etc.
  archiveIds: string[]
  status: InvoiceStatus
  createdAt: Date
  updatedAt: Date
}

export interface SubscriptionOrderExtended extends SubscriptionOrder {
  invoices: SubscriptionInvoice[]
}
export interface SubscriptionExtended extends Subscription {
  orders: SubscriptionOrderExtended[]
  subscriptionPlan: SubscriptionPlan
}
