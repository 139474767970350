import React from 'react'
import { styled, lighten } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import colors from 'assets/colors'
import MuiRating from '@mui/material/Rating'
import MuiIconButton from '@mui/material/IconButton'
import StarIcon from '@mui/icons-material/Star'
import StarIconSample from '@mui/icons-material/FlakySharp'
import { Row } from 'components/shared'
import FilledLogo from 'assets/svg/FilledLogo'
import { useIntl } from 'react-intl'
import MuiChip from '@mui/material/Chip'

export const ProfileImage = styled('img')(({ theme }) => ({
  display: 'flex',
  width: '35px',
  height: '35px',
  objectFit: 'cover',
  borderRadius: '35px',
  marginLeft: '10px',
  boxShadow: '0px 0px 30px 0px #0000003B;',
  backgroundColor: theme.palette.background.default,
}))

export const HeadlineGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: '100px',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  padding: '30px 40px',
  marginTop: '30px',
  [theme.breakpoints.down('md')]: {
    borderRadius: '60px 0 0 60px',
    padding: '20px 10px 20px 30px',
    marginTop: '10px',
  },
}))

const HeadlineGridItem = styled(Grid)(({ theme }) => ({
  flexDirection: 'column',
  justifyContent: 'flex-start',
  display: 'flex',
}))

export const HeadlineGridItemLeft = styled(HeadlineGridItem)(({ theme }) => ({
  padding: '30px 0',
}))

export const HeadlineGridItemRight = styled(HeadlineGridItem)(({ theme }) => ({
  alignItems: 'flex-end',
  [theme.breakpoints.down('md')]: {
    alignItems: 'center',
  },
}))

const RatingComponent = styled(MuiRating)(({ theme }) => ({
  marginRight: '10px',
  '& .MuiRating-iconFilled': {
    color: colors.yellow,
  },
  '& .MuiRating-iconEmpty': {
    color: lighten(theme.palette.text.primary, 0.85),
  },
}))

export const HintText = styled('span')(({ theme }) => ({
  fontSize: '11px',
  fontFamily: 'Open Sans',
  color: theme.palette.text.primary,
}))

export const Text = styled('span')(({ theme }) => ({
  fontSize: '14px',
  fontFamily: 'NoirPro',
  color: theme.palette.text.primary,
}))

export function Rating({ value }: { value: number }) {
  return (
    <RatingComponent
      size="small"
      name="half-rating-read"
      defaultValue={value}
      precision={0.1}
      readOnly
      emptyIcon={<StarIcon fontSize="inherit" />}
    />
  )
}

export const IconButton = styled(MuiIconButton)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  marginLeft: '10px',
  borderRadius: '40px',
}))
const AvailableSpaceContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  margin: '30px 0px 20px 0px',
}))
const AvailableSpaceWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '10px',
}))

const SpaceText = styled(HintText)(({ theme }) => ({
  fontSize: '9px',
}))

export function AvailableSpace({
  value, // percentage
  spaceLeft, // actual size m2
}: {
  value: number
  spaceLeft: number
}) {
  const intl = useIntl()
  return (
    <AvailableSpaceContainer>
      <Row>
        <FilledLogo percent={value} />
        <AvailableSpaceWrapper>
          <Text>
            {intl.formatMessage({ id: 'label.availableSpace' }, { value })}
          </Text>
          <SpaceText>
            {intl.formatMessage(
              { id: 'label.availableSpaceM2' },
              { value: spaceLeft }
            )}
          </SpaceText>
        </AvailableSpaceWrapper>
      </Row>
    </AvailableSpaceContainer>
  )
}

const FeatureIcon = styled(StarIconSample)(({ theme }) => ({
  color: theme.palette.primary.main,
}))

const Chip = styled(MuiChip)(({ theme }) => ({
  backgroundColor: 'transparent',
  ':hover': {
    cursor: 'pointer',
    backgroundColor: lighten(theme.palette.primary.main, 0.85),
  },
  marginRight: '4px',
  '& .MuiChip-icon': {
    color: theme.palette.primary.main,
  },
}))

export function FeatureBadge({ label }: { label: string }) {
  return <Chip icon={<FeatureIcon />} label={label} />
}

export function SimpleFeatureBadge({ label }: { label: string }) {
  return <Chip label={label} color="secondary" />
}

export const ContentGrid = styled(Grid)(({ theme }) => ({
  margin: '30px 0px',
}))

const ContentGridItem = styled(Grid)(({ theme }) => ({
  flexDirection: 'column',
  justifyContent: 'flex-start',
  display: 'flex',
  flex: 1,
}))

export const ContentGridItemLeft = styled(ContentGridItem)(({ theme }) => ({
  padding: '30px 0',
}))

export const ContentGridItemRight = styled(ContentGridItem)(({ theme }) => ({
  alignItems: 'flex-end',
  padding: '20px 50px',
  [theme.breakpoints.down('md')]: {
    alignItems: 'center',
    padding: '0',
  },
}))

export const DescriptionWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  margin: '50px 0',
}))
