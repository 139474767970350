import { ApolloError } from '@apollo/client'
import { ErrorResponse } from '@apollo/client/link/error'
import errorCodes from './errorCodes'

export function getErrorCode({
  graphQLErrors,
}: ErrorResponse | ApolloError): string | undefined {
  const error = graphQLErrors?.[0]
  return error?.extensions.code as string
}

export const hasSessionExpired = (
  error: ErrorResponse | ApolloError
): boolean => {
  const errorCode = getErrorCode(error)
  return errorCode === errorCodes.UNAUTHENTICATED
}
