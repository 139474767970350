const errorCodes = {
  SERVER_ERROR: "SERVER_ERROR",
  UNAUTHENTICATED: "UNAUTHENTICATED",
  UNAUTHORIZED: "UNAUTHORIZED",
  INVALID_CREDENTIALS: "INVALID_CREDENTIALS",
  INVALID_PASSWORD: "INVALID_PASSWORD",
  USER_NOT_FOUND: "USER_NOT_FOUND",
  USER_ALREADY_EXISTS: "USER_ALREADY_EXISTS",
  REFERENCE_ALREADY_EXISTS: "REFERENCE_ALREADY_EXISTS",
  INVALID_TOKEN: "INVALID_TOKEN",
  MEMBER_EXISTS: "MEMBER_EXISTS",
  DUPLICATE: "DUPLICATE",
  CATEGORY_HAS_CHILDREN: "CATEGORY_HAS_CHILDREN",
  INVALID_FILE_TYPE: "INVALID_FILE_TYPE",
  NOT_FOUND: "NOT_FOUND",
  RESTRICTED_ACCESS: "RESTRICTED_ACCESS",

  INVALID_RECORD: "INVALID_RECORD",

  INVALID_FILE_FORMAT: "INVALID_FILE_FORMAT",
  INVALID_FILE_SIZE: "INVALID_FILE_SIZE",
  FILE_NOT_FOUND: "FILE_NOT_FOUND",
  FAILED_TO_UPLOAD_FILE: "FAILED_TO_UPLOAD_FILE",
  FAILED_TO_DELETE_FILE: "FAILED_TO_DELETE_FILE",
  FAILED_TO_CREATE_UPLOAD_DIR: "FAILED_TO_CREATE_UPLOAD_DIR",
  PERMISSION_DENIED: "PERMISSION_DENIED",
  INVALID_REQUEST: "INVALID_REQUEST",
  ACCESS_TOKEN_EXPIRED: "ACCESS_TOKEN_EXPIRED",
  INVALID_ACCESS_TOKEN: "INVALID_ACCESS_TOKEN",
  MEMBER_NOT_FOUND: "MEMBER_NOT_FOUND",
  INVALID_TRANSFER_TARGET: "INVALID_TRANSFER_TARGET",
  VULNERABILITY_SCANNING_FAILED: "VULNERABILITY_SCANNING_FAILED",
  VULNERABILITY_DETECTED: "VULNERABILITY_DETECTED",
};

export default errorCodes;
