import { gql } from '@apollo/client'

export const GROUPED_WORKSPACES = gql`
  query GROUPED_WORKSPACES {
    groupedWorkspaces {
      title
      organizationId
      ownerId
      subscriptions {
        type
        endDate
      }
      workspaces {
        id
        title
        description
        createdAt
        updatedAt
        organizationId
        typeId
        archived
        coverImage {
          full
          medium
          thumbnail
        }
        type {
          id
          title
          description
          titleDE
          descriptionDE
        }
        membership {
          id
          role
          status
        }
        itemCount
        containerCount
        nestCount
        groupCount
        memberCount
        serviceProviderCount
      }
    }
  }
`

export const ORGANIZATION_WORKSPACES = gql`
  query ORGANIZATION_WORKSPACES($organizationId: ID!) {
    organizationWorkspaces(organizationId: $organizationId) {
      id
      title
      description
      createdAt
      updatedAt
      organizationId
      typeId
      archived
    }
  }
`

export const WORKSPACE_BY_ID = gql`
  query WORKSPACE_BY_ID($workspaceId: ID!) {
    workspaceById(workspaceId: $workspaceId) {
      id
      title
      description
      createdAt
      updatedAt
      organizationId
      typeId
      archived
      owner {
        id
        firstName
        lastName
      }
      membership {
        role
        status
      }
      coverImage {
        full
        medium
        thumbnail
      }
      type {
        id
        title
        description
        titleDE
        descriptionDE
      }
    }
  }
`

export const WORKSPACE_STATS = gql`
  query WORKSPACE_STATS($workspaceId: ID!) {
    workspaceDashboardStats(workspaceId: $workspaceId) {
      items {
        total
        data {
          count
          _id {
            year
            month
          }
        }
      }
      nests {
        total
        data {
          count
          _id {
            year
            month
          }
        }
      }
      containers {
        total
        data {
          count
          _id {
            year
            month
          }
        }
      }
      contracts {
        total
        data {
          count
          _id {
            year
            month
          }
        }
      }
      groups {
        total
        data {
          count
          _id {
            year
            month
          }
        }
      }
      events {
        total
      }
      serviceProviders {
        total
        totalPrivate
        totalPublic
      }
      members {
        total
        members
        contacts
      }
      finances {
        sales {
          value
          currency
        }
        purchases {
          value
          currency
        }
      }
    }
  }
`

export const CREATE_WORKSPACE = gql`
  mutation CREATE_WORKSPACE($payload: CreateWorkspaceInput!) {
    createWorkspace(payload: $payload) {
      id
      title
      description
      createdAt
      updatedAt
    }
  }
`

export const CREATE_WORKSPACE_FROM_TYPES = gql`
  mutation CREATE_WORKSPACE_FROM_TYPES(
    $payload: CreateWorkspaceFromTypesInput!
  ) {
    createWorkspaceFromTypes(payload: $payload) {
      status
    }
  }
`

export const UPDATE_WORKSPACE = gql`
  mutation UPDATE_WORKSPACE($payload: UpdateWorkspaceInput!) {
    updateWorkspace(payload: $payload) {
      id
    }
  }
`
export const UPDATE_WORKSPACE_MEMBER = gql`
  mutation UPDATE_WORKSPACE_MEMBER($payload: UpdateWorkspaceMemberInput!) {
    updateWorkspaceMember(payload: $payload) {
      status
    }
  }
`

export const WORKSPACE_MEMBERS = gql`
  query WORKSPACE_MEMBERS($workspaceId: ID!) {
    workspaceMembers(workspaceId: $workspaceId) {
      canEdit
      members {
        id
        userId
        email
        workspaceId
        firstName
        lastName
        role
        status
        avatar {
          avatar
        }
      }
      owners {
        id
        userId
        role
        status
        organizationId
        createdAt
        updatedAt
        user {
          id
          firstName
          lastName
          email
          avatar {
            avatar
          }
        }
      }
    }
  }
`

export const ADD_WORKSPACE_MEMBER = gql`
  mutation ADD_WORKSPACE_MEMBER($payload: WorkspaceMemberInput!) {
    addWorkspaceMember(payload: $payload) {
      id
    }
  }
`

export const REMOVE_WORKSPACE_MEMBER = gql`
  mutation REMOVE_WORKSPACE_MEMBER($payload: RemoveWorkspaceMemberInput!) {
    removeWorkspaceMember(payload: $payload) {
      status
    }
  }
`

export const WORKSPACE_TYPES = gql`
  query WORKSPACE_TYPES($organizationId: ID!) {
    workspaceTypes(organizationId: $organizationId) {
      total
      list {
        id
        title
        description
        titleDE
        descriptionDE
        published
        priority
        featured
        applicable {
          organizationTypes
        }
        coverImage {
          full
          medium
          thumbnail
        }
        createdAt
        updatedAt
      }
    }
  }
`

export const ARCHIVE_WORKSPACE = gql`
  mutation ARCHIVE_WORKSPACE($workspaceId: ID!) {
    archiveWorkspace(workspaceId: $workspaceId) {
      status
    }
  }
`

export const UNARCHIVE_WORKSPACE = gql`
  mutation UNARCHIVE_WORKSPACE($workspaceId: ID!) {
    unarchiveWorkspace(workspaceId: $workspaceId) {
      status
    }
  }
`

export const MEMBER_BY_ID = gql`
  query MEMBER_BY_ID($payload: WorkspaceMemberByIdInput!) {
    memberById(payload: $payload) {
      ... on WsMemberDetailExtended {
        id
        userId
        email
        role
        workspaceId
        firstName
        lastName
        avatarId
        avatar {
          avatar
        }
        createdAt
        updatedAt
        user {
          id
          email
          firstName
          lastName
        }
        items {
          id
          title
          description
          coverId
          archive {
            id
            type
            title
            documentType
            path
            createdAt
            updatedAt
            media {
              thumbnail
              medium
              full
            }
          }
        }
        canEdit
        nests {
          id
          title
          description
          itemCount
          containerCount
          archive {
            id
            type
            title
            documentType
            path
            createdAt
            updatedAt
            media {
              thumbnail
              medium
              full
            }
          }
        }
        contracts {
          id
          title
          description
          workspaceId
          contractNumber
        }
        containers {
          id
          title
          description
          itemCount
          archive {
            id
            type
            title
            documentType
            path
            createdAt
            updatedAt
            media {
              thumbnail
              medium
              full
            }
          }
        }
        archive {
          id
          type
          title
          documentType
          path
          createdAt
          updatedAt
          refId
          media {
            thumbnail
            medium
            full
          }
        }
      }
      ... on OrgMemberDetailExtended {
        id
        userId
        email
        organizationId
        createdAt
        updatedAt
        user {
          id
          firstName
          lastName
          email
          avatar {
            avatar
          }
        }
        items {
          id
          title
          coverId
          description
          archive {
            id
            type
            title
            documentType
            path
            createdAt
            updatedAt
            media {
              thumbnail
              medium
              full
            }
          }
        }
        canEdit
        nests {
          id
          title
          description
          itemCount
          containerCount
          archive {
            id
            type
            title
            documentType
            path
            createdAt
            updatedAt
            media {
              thumbnail
              medium
              full
            }
          }
        }
        contracts {
          id
          title
          description
          workspaceId
          contractNumber
        }
        containers {
          id
          title
          description
          itemCount
          archive {
            id
            type
            title
            documentType
            path
            createdAt
            updatedAt
            media {
              thumbnail
              medium
              full
            }
          }
        }
        archive {
          id
          type
          title
          documentType
          path
          createdAt
          updatedAt
          refId
          media {
            thumbnail
            medium
            full
          }
        }
      }
    }
  }
`
