import { Address, Geolocation } from './shared'

export enum AddressReferences {
  ORGANIZATION = 'ORGANIZATION',
  WORKSPACE = 'WORKSPACE',
}

export interface AddressInput {
  houseNumber: string
  street: string
  city: string
  zipCode: number
  countryCode: string
  geolocation?: Geolocation
}

export interface CreateAddressData {
  createAddress: Address
}

export interface UpdateAddressInput {
  payload: {
    id: string
    reference: AddressReferences
    data: AddressInput
  }
}
export interface UpdateAddressData {
  updateAddress: Address
}
