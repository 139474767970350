import { JsonObject, Session } from '../types/shared'
import { Events, publish } from './events'

export const localStorageKeys = {
  session: 'session',
  lock: 'lock', // todo: the lock is temporary for pre-release access
  language: 'language',
}

export function save(key: string, data: JsonObject) {
  localStorage.setItem(key, JSON.stringify(data))
}

export function load(key: string) {
  return JSON.parse(localStorage.getItem(key) || '{}')
}

export function remove(key: string) {
  localStorage.removeItem(key)
}

export function clearStorage() {
  localStorage.clear()
}

export function saveAuth(data: Session) {
  localStorage.setItem(localStorageKeys.session, JSON.stringify(data))
}

export function changeLanguage(language: 'de' | 'en') {
  localStorage.setItem(localStorageKeys.language, JSON.stringify({ language }))
  publish(Events.languageChange)
}

export function getLanguage(): 'de' | 'en' | null {
  try {
    return load(localStorageKeys.language)?.language
  } catch {
    return null
  }
}

export function clearSession() {
  remove(localStorageKeys.session)
}

export function getAuth(): Session | null {
  try {
    return load(localStorageKeys.session) as Session
  } catch {
    return null
  }
}

/**
 * Method to check if there is a session present on the local storage
 * @returns
 */
export function hasSession(): boolean {
  try {
    const session = load(localStorageKeys.session) ?? {}
    return Object.keys(session).length > 0
  } catch {
    return false
  }
}
