import * as React from 'react'
import { styled } from '@mui/material/styles'
import { useIntl } from 'react-intl'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { cardGradient } from 'assets/styles/theme'
import placeholder from 'assets/images/workspacePlaceholder.jpg'
import appConfig from 'config/app'
import { useNavigate, useParams } from 'react-router-dom'
import { cardBoxShadow, cardBoxShadowHover } from 'assets/styles/theme'
import { GroupExtended } from 'types/group'
import ROUTES from 'lib/routes'
import {
  // CardActionBar,
  // IconButton,
  // PinIcon,
  // PinOutlinedIcon,
  CardTitle,
  ContentText,
} from './cards'

const CardWrapper = styled(Card)(({ theme }) => ({
  position: 'relative',
  padding: '0px',
  borderRadius: '4px',
  boxShadow: cardBoxShadow,
  ':hover': {
    cursor: 'pointer',
    boxShadow: cardBoxShadowHover,
  },
}))

const Media = styled('img')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '250px',
  objectFit: 'cover',
}))

const Content = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  justifyContent: 'flex-end',
  flexDirection: 'column',
  position: 'absolute',
  bottom: '0',
  width: '95%',
  pointerEvents: 'none',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}))

const Overlay = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  height: '50%',
  background: cardGradient,
}))

interface Props {
  record: GroupExtended
}
function WorkspaceCard({ record }: Props) {
  const intl = useIntl()
  const navigate = useNavigate()
  // const [pinned, setPinned] = React.useState(false)
  const params = useParams() as { workspaceId: string }
  const cover = record.coverImage?.medium
    ? `${appConfig.media}${record.coverImage.medium}`
    : placeholder

  const onClick = () =>
    navigate(
      ROUTES.DASHBOARD_GROUP_DETAILS_ROUTE(params.workspaceId, record.id)
    )
  const groupContent = []
  record.itemCount &&
    groupContent.push(
      intl.formatMessage(
        { id: 'label.countItems' },
        { number: record.itemCount }
      )
    )

  return (
    <CardWrapper onClick={onClick}>
      <Media src={cover} alt="workspace cover" />
      <Overlay />

      <Content>
        <CardTitle numberOfLines={2}>{record.title}</CardTitle>
        <ContentText>
          {groupContent.join(' · ') ||
            intl.formatMessage({ id: 'label.emptyGroup' })}
        </ContentText>
      </Content>
      {/* <CardActionBar>
        <span />
        <IconButton
          onMouseDown={(e) => e.preventDefault()}
          onClick={(event) => {
            event.stopPropagation()
            event.preventDefault()
            setPinned(!pinned)
          }}
        >
          {pinned ? <PinIcon /> : <PinOutlinedIcon />}
        </IconButton>
      </CardActionBar> */}
    </CardWrapper>
  )
}

WorkspaceCard.defaultProps = {}
export default WorkspaceCard
