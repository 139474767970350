import React, { useEffect } from 'react'
import { Container, Content, RowSpaced } from 'components/shared'
import { useIntl } from 'react-intl'
import ROUTES from 'lib/routes'
import { H1 } from 'components/typography'
import MuiHeader from 'pages/landing/MuiHeader'
import { Toolbar } from '@mui/material'
import { useLocation, useParams } from 'react-router-dom'
import { useLazyQuery, useQuery } from '@apollo/client'
import { Severity, withSnackbar } from 'components/providers/SnackbarHOC'
import {
  SUBSCRIPTION_BY_ID,
  SUBSCRIPTION_PLANS,
  SUBSCRIPTION_PLAN_BY_ID,
} from 'gql/subscription'
import {
  SubscriptionByIdData,
  SubscriptionByIdInput,
  SubscriptionPlanByIdData,
  SubscriptionPlanByIdInput,
  SubscriptionPlanData,
  SubscriptionPlanInput,
} from 'types/subscription'
import LoadingIndicator from 'components/LoadingIndicator'
import SubscriptionGrid from './SubscriptionGrid'

interface Props {
  showSnackbar?: (message: string, severity: Severity) => void
}
// TODO: change later when other countries are introduced
const countryCode = 'DE'
function Subscription({ showSnackbar }: Props) {
  const intl = useIntl()
  const location = useLocation()
  const params = useParams() as {
    // the subscription that the user wants upgraded
    subscriptionId?: string
  }

  const { data, loading } = useQuery<
    SubscriptionPlanData,
    SubscriptionPlanInput
  >(SUBSCRIPTION_PLANS, { variables: { payload: { countryCode } } })

  const [
    fetchActiveSubscription,
    { data: activeSubscriptionData, loading: subscriptionLoading },
  ] = useLazyQuery<SubscriptionByIdData, SubscriptionByIdInput>(
    SUBSCRIPTION_BY_ID,
    { variables: { id: params.subscriptionId ?? '' } }
  )
  const activeSubscription = activeSubscriptionData?.subscriptionById
  const [
    fetchSubscriptionPlan,
    { data: subscriptionPlanData, loading: subscriptionPlanLoading },
  ] = useLazyQuery<SubscriptionPlanByIdData, SubscriptionPlanByIdInput>(
    SUBSCRIPTION_PLAN_BY_ID,
    { variables: { id: activeSubscription?.subscriptionPlanId ?? '' } }
  )
  useEffect(() => {
    params?.subscriptionId && fetchActiveSubscription()
  }, [params?.subscriptionId])
  useEffect(() => {
    activeSubscription?.subscriptionPlanId && fetchSubscriptionPlan()
  }, [activeSubscription?.subscriptionPlanId])

  // current active subscription plan
  const subscriptionPlan = subscriptionPlanData?.subscriptionPlanById
  const subscriptionsInType =
    data?.subscriptionPlans.list
      .filter((sub) => sub.type === subscriptionPlan?.type)
      .sort((a, b) => b.duration - a.duration) ?? []

  // the last plan is the base plan. smallest duration.
  const basePlans = [subscriptionsInType[subscriptionsInType.length - 1]]
  const isDashboard = location.pathname.includes(ROUTES.DASHBOARD)
  const isLoading = loading || subscriptionLoading || subscriptionPlanLoading
  return (
    <Container>
      {!isDashboard && (
        <React.Fragment>
          <MuiHeader />
          <Toolbar />
        </React.Fragment>
      )}
      <LoadingIndicator visible={isLoading} />
      <Content>
        <RowSpaced>
          <div />
          <H1 style={{ textAlign: 'center' }}>
            {intl.formatMessage({ id: 'label.selectPricingPlan' })}
          </H1>
          <div />
        </RowSpaced>
        <br />

        <div style={{ display: 'flex', flex: 1, marginTop: '30px' }}>
          <SubscriptionGrid
            subscription={activeSubscription}
            countryCode={countryCode}
            subscriptions={subscriptionsInType}
            basePlans={basePlans}
          />
        </div>
      </Content>
    </Container>
  )
}

Subscription.defaultProps = {
  showSnackbar: undefined,
}
export default withSnackbar(Subscription)
