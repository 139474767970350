import React, { PropsWithChildren, useEffect } from 'react'
import { RoutesProps, useLocation } from 'react-router-dom'

interface Props extends PropsWithChildren<RoutesProps> {}

const ScrollToTop = (props: Props) => {
  const location = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return <React.Fragment>{props.children}</React.Fragment>
}

export default ScrollToTop
