import { styled } from '@mui/material/styles'
import MuiDrawer from '@mui/material/Drawer'

export const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
}))

export const Drawer = styled(MuiDrawer)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  '& .MuiDrawer-paperAnchorRight': {
    backgroundColor: theme.palette.background.default,
    minWidth: '70%',
    maxHeight: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}))

export const ActionBarWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'absolute',
  bottom: 40,
  width: '100%',
  justifyContent: 'center',
}))
